import React from "react";
import { Progress, Space } from "antd";
import { useSelector } from "react-redux";
import { useHistory } from "react-router-dom";

const _3Dresume_skillListing = () => {
  const history = useHistory();

  const Detail = useSelector((state) => state.resumeDetailReducer.success);
  const ResumeCompletion =
    Detail && Detail?.user && Detail?.user?.profile?.ResumeCompletion;
  const progress_status = Math.floor(ResumeCompletion);

  return (
    <section className="resumebox-skill">
      <div className="title">3D resume completion (%)</div>
      <div className="progressbar3d">
        <Space wrap>
          <Progress
            type="circle"
            format={() => `${progress_status ? progress_status : 0}%`}
            percent={progress_status ? progress_status : 0}
            strokeColor="#118936"
          />
        </Space>
      </div>
      <div
        className="updateButton3D clickable"
        onClick={() => history.push("/3d_resume")}
      >
        Update 3D resume
      </div>
    </section>
  );
};

export default _3Dresume_skillListing;
