import React, { useEffect, useState } from "react";
import "./Conersations.css";
import chatIcon from "../../assets/img/chatIcon.png";
import filterIcon from "../../assets/icons/cilFilter.svg";
import { debounce, setCookie } from "../../shared/helpers";
import {
  Dropdown,
  Menu,
  Row,
  Col,
  Input,
  Skeleton,
  Button,
  Radio,
  Space,
} from "antd";
import { useHistory } from "react-router-dom";
import { SearchOutlined, CloseOutlined } from "@ant-design/icons";
import ConversationCard from "./components/Messages/ConversationCard";
import FilterConversations from "./components/Filters/Filter";
import { ChatCard } from "./components/ChatCard/ChatCard";
import { useDispatch, useSelector } from "react-redux";
import {
  fetchChatHistory,
  fetchChatsHistory,
  fetchInboxMessages,
  fetchMessagesCounts,
  messagesJobDetails,
} from "../../redux/actions";
import MessageInput from "./components/MessageInput/messageInput";
import ChatHeader from "./components/ChatHeader/chatHeader";
import InfiniteScroll from "react-infinite-scroll-component";
import moment from "moment";
import { useLocation, useParams } from "react-router-dom";
import SkillListingCard from "./../DashBoardFull/components/SkillListingCard";
import { DefaultsRating } from "../WelcomeWizard/helper";
import { GetQueryParams } from "../../assets/helpers";
import { useUpdateLogger } from "../../shared/useUpdateLogger";

export default function Conversation() {
  const [conversationsRadioValue, setConversationsRadioValue] =
    useState("all_conversations");
  const [bidTypeRadioValue, setBidTypeRadioValue] = useState("all_bids");
  const [bidStatusRadioValue, setBidStatusRadioValue] = useState("null");

  // const [localConversationsRadioValue, setLocalConversationsRadioValue] =
  //   useState("all_conversations");
  // const [localBidTypeRadioValue, setLocalBidTypeRadioValue] = useState("all_bids");
  // const [localBidStatusRadioValue, setLocalBidStatusRadioValue] = useState("null");
  const [loadChat, setLoadChat] = useState(false);

  const [dropDownVisible, setDropDownVisible] = useState(false);
  const [searchedValue, setSearchedValue] = useState(false);
  // Filter Object

  const dispatch = useDispatch();
  const location = useLocation();
  const history = useHistory();

  let userProfile = useSelector((state) => state.profileReducer.user.profile);
  let dummyRow = [1, 2, 3, 4, 5, 6];
  const { id } = useParams();
  //hooks***
  const [windowDimensions, setWindowDimensions] = useState(
    getWindowDimensions()
  );
  const counts = useSelector((state) => state.getChatHistoryReducer.counts);
  const [showHistory, setshowHistory] = useState(
    windowDimensions.width > 768 ? false : true
  );
  const [inputWidth, setInputWidth] = useState();
  const [alertMsg, setAlertMsg] = useState();
  const [Recipient, setRecipient] = useState();
  const [title, setTitle] = useState();
  const [conversations, setConversations] = useState([]);
  const [createdTime, setCreatedTime] = useState();
  const [selectedChat, setSelectedChat] = useState();
  const [showHeader, setShowHeader] = useState(false);
  const [rejectModalVisible, setRejectModalVisible] = useState(false);
  const [Count, setCounts] = useState(counts?.data?.totalConversations);
  const [openedChatRecords, setOpenChatsRecord] = useState({});
  const [filterCount, setFilterCount] = useState(2);
  const [appliedFilterCheck, setAppliedFilterCheck] = useState(false);

  const addQuery = (key, value) => {
    let searchParams = new URLSearchParams(window.location.search);
    searchParams.set(key, value);
    history.push({
      search: searchParams.toString(),
    });
  };
  const removeQuery = (key) => {
    let searchParams = new URLSearchParams(window.location.search);
    searchParams.delete(key);
    history.replace({
      search: searchParams.toString(),
    });
  };

  useEffect(() => {
    if (location?.state?.conversationId || id) {
      if(GetQueryParams()?.bid != undefined ){
        dispatch(fetchChatsHistory(location?.state?.conversationId ?? id , GetQueryParams()?.bid));
      }
      else{
        dispatch(fetchChatsHistory(location?.state?.conversationId ?? id));
      }
      setSelectedChat(location?.state?.conversationId ?? id);
      // setOpenChatsRecord(location?.state?.records)
    }
    if((location.state?.openConvo && location.state.conversationId) || id ){
      setshowHistory(true)
    }
    if(location?.state?.loadChat) {
      
      setLoadChat(location?.state?.loadChat)
    }
  }, [location?.state]);


  //REDUCERS***
  const inbox = useSelector((state) => state.getChatHistoryReducer.inbox);
  const jobDetails = useSelector(
    (state) => state.getChatHistoryReducer.jobDetail
  );
  const inboxLoading = useSelector(
    (state) => state.getChatHistoryReducer.inboxLoading
  );
  const chatDetails = useSelector((state) => state.getChatHistoryReducer.chats);
  const newMsgInSocket = useSelector(
    (state) => state.getChatHistoryReducer.newMsgInSocket
  );
  const hasMorechats = useSelector(
    (state) => state.getChatHistoryReducer.hasMorechats
  );
  const page = useSelector((state) => state.getChatHistoryReducer.page);

  function getWindowDimensions() {
    const { innerWidth: width, innerHeight: height } = window;
    return {
      width,
      height,
    };
  }

  useEffect(() => {
    // 
    if (location?.state?.conversationId || id) {
      dispatch(fetchChatsHistory(location?.state?.conversationId ?? id));
      setSelectedChat(location?.state?.conversationId ?? id);
      // setOpenChatsRecord(location?.state?.records)
    }
    if ((location.state?.openConvo && location.state.conversationId) || id) {
      setshowHistory(true);
    }
    if (location?.state?.loadChat) {
      setLoadChat(location?.state?.loadChat);
    }
  }, [location?.state]);

  useEffect(() => {
    removeQuery("bidType");
    removeQuery("bidStatusType");
    removeQuery("ConversationType");
  }, []);

  useEffect(() => {
    function handleResize() {
      setWindowDimensions(getWindowDimensions());
    }

    window.addEventListener("resize", handleResize);
    return () => window.removeEventListener("resize", handleResize);
  }, []);

  useEffect(() => {
    if (appliedFilterCheck) {
      setBidStatusRadioValue(GetQueryParams()?.bidStatusType);
      setBidTypeRadioValue(
        GetQueryParams()?.bidType == "placed_bids"
          ? "received_bids"
          : GetQueryParams()?.bidType == "received_bids"
          ? "placed_bids"
          : GetQueryParams()?.bidType
      );
      setConversationsRadioValue(GetQueryParams()?.ConversationType);
    } else {
      setBidStatusRadioValue("null");
      setBidTypeRadioValue("all_bids");
      setConversationsRadioValue("all_conversations");
    }
  }, [dropDownVisible]);

  useEffect(() => {
    if (chatDetails?.data?.body) {
      dispatch(
        messagesJobDetails(chatDetails?.data?.body[0]?.GigCard ?? jobDetails)
      );
      setShowHeader(true);
    }
  }, [chatDetails]);

  useEffect(() => {
    if (inbox?.data?.data?.body?.conversations) {
      setConversations(inbox?.data?.data?.body?.conversations);
    }
  }, [inbox]);

  useEffect(() => {
    setCounts(counts?.data?.totalConversations);
  }, [counts]);

  useEffect(() => {
    if (location?.state?.openConvo == true) {
      dispatch(
        fetchInboxMessages(
          location?.state?.conversationStatus ?? "null",
          conversationsRadioValue,
          bidStatusRadioValue,
          null,
          null,
          true
        )
      );
      setTimeout(() => {
        dispatch(
          fetchInboxMessages(
            location?.state?.conversationStatus ?? "null",
            conversationsRadioValue,
            bidStatusRadioValue,
            2,
            5
          )
        );
      }, 1000);
    } else {
      dispatch(
        fetchInboxMessages(
          location?.state?.conversationStatus ?? "null",
          conversationsRadioValue,
          bidStatusRadioValue,
          2,
          5,
          false
        )
      );
    }
    // else {
    //   dispatch(
    //     fetchInboxMessages(
    //       location?.state?.conversationStatus,
    //       conversationsRadioValue,
    //       bidStatusRadioValue,
    //       2,
    //       5,
    //       false,
    //     )
    //   );
    // }

    return () =>
      dispatch(
        fetchInboxMessages(
          "null",
          "all_conversations",
          "null",
          null,
          null,
          true
        )
      );
  }, []);

  const applyFilter = () => {
    if (bidTypeRadioValue == "placed_bids") {
      setCounts(counts?.data?.allCount?.PlacedBids);
    } else if (bidTypeRadioValue == "received_bids") {
      setCounts(counts?.data?.allCount?.ReceivedBids);
    } else if (bidTypeRadioValue == "all_bids") {
      setCounts(counts?.data?.totalConversations);
    }
    if (bidStatusRadioValue !== "null") {
      setFilterCount(3);
    }
    setAppliedFilterCheck(true);
    addQuery(
      "bidType",
      bidTypeRadioValue == "placed_bids"
        ? "received_bids"
        : bidTypeRadioValue == "received_bids"
        ? "placed_bids"
        : bidTypeRadioValue
    );
    addQuery("bidStatusType", bidStatusRadioValue);
    addQuery("ConversationType", conversationsRadioValue);

    setshowHistory(false);
    dispatch(fetchMessagesCounts(bidTypeRadioValue));
    dispatch(
      fetchInboxMessages(
        // bidTypeRadioValue,
        conversationsRadioValue,
        bidTypeRadioValue,
        bidStatusRadioValue,
        1,
        5,
        true
      )
    );
    setTimeout(() => {
      dispatch(
        fetchInboxMessages(
          // bidTypeRadioValue,
          conversationsRadioValue,
          bidTypeRadioValue,
          bidStatusRadioValue,
          2,
          5
        )
      );
    }, 1000);
    setDropDownVisible(false);
  };

  const clearFilter = () => {
    setCounts(counts?.data?.totalConversations);
    setBidStatusRadioValue(() => "null");
    setConversationsRadioValue(() => "all_conversations");
    setBidTypeRadioValue("all_bids");
    setshowHistory(false);
    setFilterCount(2);
    removeQuery("bidType");
    removeQuery("bidStatusType");
    removeQuery("ConversationType");
    dispatch(fetchMessagesCounts("all_bids"));
    dispatch(
      fetchInboxMessages("null", "all_conversations", "null", null, null, true)
    );
    setAppliedFilterCheck(false);
    setDropDownVisible(false);
    setTimeout(() => {
      dispatch(fetchInboxMessages("null", "all_conversations", "null", 2, 5));
    }, 1000);
  };

  const Filters = (
    <Menu className="chat-filter-menu">
      <FilterConversations
        conversationsRadioValue={conversationsRadioValue}
        setConversationsRadioValue={setConversationsRadioValue}
        bidTypeRadioValue={bidTypeRadioValue}
        setBidTypeRadioValue={setBidTypeRadioValue}
        bidStatusRadioValue={bidStatusRadioValue}
        setBidStatusRadioValue={setBidStatusRadioValue}
        counts={counts}
      />

      <div className="statusDropDownBtnss">
        <Button className="clearBtnStatus" onClick={clearFilter}>
          Clear
        </Button>

        <Button className="applyBtnStatus" onClick={applyFilter}>
          Apply
        </Button>
      </div>
    </Menu>
  );

  // const onChange = (e) => {
  //   var message_title;
  //   var searchedConversations = conversations.filter((item) => {
  //     if (item.SourceTypeId == 1) {
  //       // skill listing based search
  //       message_title = item?.SkillListing?.Title;
  //     } else {
  //       message_title = item?.JobTitle?.toLowerCase();
  //     }

  //     if (message_title?.toLowerCase().includes(e.target.value.toLowerCase())) {
  //       return item;
  //     }
  //   });

  //   if (e.target.value === "") {
  //     setConversations(inbox?.data?.data?.body?.conversations);
  //   } else {
  //     setConversations(searchedConversations);
  //   }
  // };

  const onChange = (e) => {
    // var message_title;
    // var searchedConversations = inbox?.data?.data?.body?.conversations.filter(
    //   (item) => {
    //     if (item.SourceTypeId == 1) {
    //       message_title = item?.SkillListing?.Title;
    //     } else {
    //       message_title = item?.JobTitle?.toLowerCase();
    //     }

    //     if (
    //       message_title?.toLowerCase().includes(e.target.value.toLowerCase())
    //     ) {
    //       return item;
    //     }
    //   }
    // );

    // if (e.target.value === "") {
    //   setConversations(inbox?.data?.data?.body?.conversations);
    // } else {
    //   setConversations(searchedConversations);
    // }
    dispatch(
      fetchInboxMessages(
        location?.state?.conversationStatus ?? "null",
        conversationsRadioValue,
        bidStatusRadioValue,
        null,
        null,
        true,
        null,
        e.target.value
      )
    );
    setTimeout(() => {
      dispatch(
        fetchInboxMessages(
          location?.state?.conversationStatus ?? "null",
          conversationsRadioValue,
          bidStatusRadioValue,
          2,
          5,
          null,
          null,
          e.target.value
        )
      );
    }, 1000);
    setSearchedValue(e.target.value);
  };

  const fetchMoreData = (value) => {
    if (hasMorechats) {
      dispatch(
        fetchInboxMessages(
          conversationsRadioValue,
          bidTypeRadioValue,
          bidStatusRadioValue,
          page,
          5,
          false,
          true,
          searchedValue
        )
      );
    }
  };

  return (
    <div className="conversations-container">
      {/* <TopAlert type={'warning'} title={' File format not supported. Upload only JPEG, JPG, PNG, & PDF file formats.'} display={alertMsg && 'block'} /> */}
      <Row className="conversation">
        <Col className="conversation-div1" lg={8} md={9} xl={7} sm={0} xs={0}>
          <div className="conversation-header">
            {inputWidth ? null : (
              <div className="conversation-header-title">
                <div className="conversation-header-title-text1">
                  All conversations
                </div>
                {/* <div className="conversation-header-title-text2">({Count})</div> */}
              </div>
            )}

            {inputWidth ? null : (
              <Dropdown
                autoFocus={true}
                trigger="click"
                overlay={Filters}
                visible={dropDownVisible}
                onVisibleChange={() => setDropDownVisible(!dropDownVisible)}
                className="filterDropdown"
              >
                <div
                  className="conversation-header-filter"
                  onClick={() => setDropDownVisible(!dropDownVisible)}
                >
                  <div className="conversation-header-filter-image">
                    <img src={filterIcon} alt="filterIcon" />
                  </div>
                  <div className="conversation-header-filter-count">
                    {/* {bidStatusRadioValue == "null" ? 2 : 3} */}
                    {filterCount}
                  </div>
                </div>
              </Dropdown>
            )}

            <div
              className="conversation-header-filter-search"
              onClick={() => setInputWidth(true)}
              style={{
                width: inputWidth ? "81.3%" : "35px",
                transition: inputWidth && "width 1s",
              }}
            >
              <Input
                type={"input"}
                className="conversation-header-input"
                prefix={
                  <SearchOutlined
                    style={{ color: "#7e7e7e", fontSize: "16px" }}
                  />
                }
                placeholder="Find skill listing title or gig title"
                allowClear = {inputWidth}
                onChange={debounce((e) => onChange(e))}
              />
              {inputWidth && (
                <div className="cross-icon-div">
                  <CloseOutlined
                    onClick={(event) => {
                      setInputWidth(false);
                      event.stopPropagation();
                    }}
                    style={{ color: "#c4c4c4" }}
                  />
                </div>
              )}
            </div>
          </div>

          <Row className="conversationListCont" id="infinite-scroll-parent">
            <InfiniteScroll
              dataLength={conversations?.length}
              next={fetchMoreData}
              hasMore={hasMorechats}
              scrollableTarget={"infinite-scroll-parent"}
              className={"infinite-scroller"}
              loader={
                hasMorechats && conversations?.length > 0 ? (
                  <h4>Loading...</h4>
                ) : inboxLoading ? (
                  <>
                    {dummyRow.map((res) => {
                      return (
                        <div className="shimmer-container">
                          {" "}
                          <Skeleton
                            active
                            avatar
                            paragraph={{ rows: 2 }}
                          />{" "}
                        </div>
                      );
                    })}
                  </>
                ) : (
                  <h4>No conversations</h4>
                )
              }
            >
              {conversations?.map((item, index) => {
                return (
                  <Col span={24}>
                    <ConversationCard
                      key={index}
                      records={item}
                      Index={index}
                      setshowHistory={setshowHistory}
                      setLoadChat={setLoadChat}
                      SourceName={item?.SourceTypeId === 1 ? "Bid" : "Proposal"}
                      setRecipient={setRecipient}
                      SourceId={item?.BidId}
                      setTitle={setTitle}
                      title={title}
                      setCreatedTime={setCreatedTime}
                      selectedChat={selectedChat}
                      setSelectedChat={setSelectedChat}
                      conversationType={item?.ConversationType}
                    />
                  </Col>
                );
              })}
            </InfiniteScroll>
          </Row>
        </Col>

        <Col
          lg={13}
          xl={17}
          md={17}
          sm={24}
          xs={24}
          className={` ${showHistory ? "" : "chat-history-column"} `}
        >
          {showHistory || location.state?.openConvo ? (
            <div className="chat-History-Cont">
              <Col
                className="chatContainer"
                style={{
                  height:
                    windowDimensions.width < 769
                      ? `${windowDimensions.height - 125}px`
                      : null,
                }}
              >
                {showHeader && (
                  <ChatHeader
                    setshowHistory={setshowHistory}
                    chatDetails={chatDetails?.data?.body}
                    conversationType={
                      chatDetails?.data?.body[0]?.ConversationType
                    }
                    Recipient={Recipient}
                    createdTime={createdTime}
                    title={title}
                    jobDetails={jobDetails}
                    facilityName={
                      userProfile?.ProfessionCode == "HC"
                        ? chatDetails?.data?.body[0]?.GigCard?.FacilityName
                        : chatDetails?.data?.body[0]?.GigCard?.WorkplacePolicy
                    }
                    // facilityName = {userProfile}
                    Rating={
                      chatDetails?.data?.body[0]?.SourceTypeId == 1
                        ? parseFloat(
                            chatDetails?.data?.body[0]?.GigCard?.facilityRating
                              ?.rating
                          )
                          ? parseFloat(
                              chatDetails?.data?.body[0]?.GigCard
                                ?.facilityRating?.rating
                            )
                          : parseFloat(
                              chatDetails?.data?.body[0]?.RecipientUser
                                ?.Profiles?.Rating
                            )
                        : parseFloat(DefaultsRating)
                    }
                    Reviews={
                      chatDetails?.data?.body[0]?.SourceTypeId == 1
                        ? chatDetails?.data?.body[0]?.GigCard?.facilityRating
                            ?.reviews !== 0
                          ? ` (${chatDetails?.data?.body[0]?.GigCard?.facilityRating?.reviews}) `
                          : ""
                        : chatDetails?.data?.body[0]?.RecipientUser?.Profiles
                            ?.Review !== 0
                        ? `(${chatDetails?.data?.body[0]?.RecipientUser?.Profiles?.Review}) `
                        : ""
                    }
                  />
                )}
                <ChatCard
                  chatDetails={chatDetails?.data?.body}
                  loadChat={loadChat}
                  setLoadChat={setLoadChat}
                  jobDetails={jobDetails}
                  records={location.state?.records}
                  SourceName={location.state?.SourceName}
                  SourceId={location.state?.SourceId}
                  Index={location.state?.Index}
                />
                <MessageInput
                  setAlertMsg={setAlertMsg}
                  currentUser={userProfile._id}
                  chatDetails={chatDetails?.data?.body}
                  jobTitle={jobDetails?.Title}
                />
              </Col>
              {/* {windowDimensions.width > 1000 && (
                <div className="chat-History-Cont">
                  <div className="detailsTitle">Skill Listing Title</div>
                  <Row className="jobrowflex mr">
                    <div>Location</div>
                    {chatDetails?.data?.body.length > 0 && (
                      <div className="text-Bold">
                        {chatDetails?.data?.body[0].GigCard?.Location?.Location}
                      </div>
                    )}
                  </Row>
                  <Row className="jobrowflex mr">
                    <div>Posted On</div>
                    <div className="text-Bold">
                      {moment(
                        chatDetails?.data?.body[0].GigCard?.Created
                      ).format("ddd, MMM DD, yyyy")}
                    </div>
                  </Row>
                  <Row className="jobrowflex mr">
                    <div>Expires On</div>
                    <div className="text-Bold">
                      {moment(
                        chatDetails?.data?.body[0].GigCard?.ExpiryDate
                      ).format("ddd, MMM DD, yyyy")}
                    </div>
                  </Row>
                  <Row className="jobrowflex mr">
                    <div>Job Type</div>
                    <div className="text-Bold">
                      {chatDetails?.data?.body[0].GigCard?.JobOrderTypeName}
                    </div>
                  </Row>
                  <Row className="jobrowflex mr">
                    <div>Duration</div>
                    <div className="text-Bold">
                      {
                        chatDetails?.data?.body[0].GigCard
                          ?.IdealContractLengthName
                      }
                    </div>
                  </Row>
                  <Row className="jobrowflex mr">
                    <div>Work Authorization</div>
                    <div className="text-Bold">
                      {chatDetails?.data?.body[0].GigCard?.WorkAuthorization}
                    </div>
                  </Row>
                </div>
              )} */}
              {chatDetails?.data?.body[0]?.SourceTypeId === 1 && chatDetails?.data?.body[0]?.ConversationType != "Admin-Talent" &&
                windowDimensions.width > 1000 && (
                  <div className="jobDetailCont">
                    <div className="detailsTitle">
                      {chatDetails?.data?.body[0].SkillListingCard?.Title ??
                        "Skill Listing Title"}
                    </div>
                    <Row className="jobrowflex mr">
                      <div>Gig type</div>
                      {chatDetails?.data?.body.length > 0 && (
                        <div className="text-Bold">
                          {
                            chatDetails?.data?.body[0].GigCard?.JobOrderTypes
                              ?.JobOrderTypeName
                          }
                        </div>
                      )}
                    </Row>
                    <Row className="jobrowflex mr">
                      <div>Willing to relocate</div>
                      <div className="text-Bold">
                        {chatDetails?.data?.body[0].SkillListingCard?.SkillListings_Cities?.map(
                          (data, index) => (
                            <p className="text-Bold" key={index}>
                              {/* {data?.Cities?.CityName} */}
                              {data?.Cities?.LocationWithCountry ??
                                data?.Cities?.Location}
                            </p>
                          )
                        )}
                      </div>
                    </Row>
                    <Row className="jobrowflex mr">
                      <div>Skills</div>
                      <div>
                        {chatDetails?.data?.body[0].SkillListingCard?.SkillListings_Skills?.map(
                          (data, index) => (
                            <p className="text-Bold" key={index}>
                              {data?.SkillName}
                            </p>
                          )
                        )}
                      </div>
                    </Row>
                    <Row className="jobrowflex mr">
                      <div>Available to start</div>
                      <div className="text-Bold">
                        {moment(
                          chatDetails?.data?.body[0].SkillListingCard?.StartDate
                        ).format("MMM DD, YYYY")}
                      </div>
                    </Row>
                    {userProfile?.ProfessionCode === "HC" && (
                      <Row className="jobrowflex mr">
                        <div>Desired salary</div>
                        <div className="text-Bold">
                          $
                          {chatDetails?.data?.body[0].SkillListingCard
                            ?.MinimumBid?.$numberDecimal ??
                            chatDetails?.data?.body[0].SkillListingCard?.MinimumBid?.toLocaleString(
                              "en-US"
                            )}
                          /
                          {chatDetails?.data?.body[0].SkillListingCard
                            ?.MinimumBidType == "Year"
                            ? "YR"
                            : chatDetails?.data?.body[0].SkillListingCard
                                ?.MinimumBidType == "Week"
                            ? "WK"
                            : "HR"}
                        </div>
                      </Row>
                    )}
                  </div>
                )}
            </div>
          ) : (
            <div className="iconChatCont">
              <div className="iconnChat">
                <img
                  src={chatIcon}
                  alt="chatIcon"
                  className="iconConversation"
                />
              </div>

              <div className="iconChatTitle">
                {conversations?.length > 0
                  ? "Choose a conversation"
                  : "No conversation"}
              </div>
            </div>
          )}
        </Col>
      </Row>
    </div>
  );
}
