import React, { useState, useEffect } from "react";
import { DatePicker } from "antd";
import "../../CreateSkillListing.css";
import "./CreateSkillListingScreen.css";
import errorIcon from "../../../../../assets/img/ci_warning.png";
import { useHistory } from "react-router-dom";
import BottomBottons from "../Footer/BottomButtons";
import { useDispatch, useSelector } from "react-redux";
import {
  createSkillListing,
  updateSignupProfile,
} from "../../../../../redux/actions";
import { alertNotification } from "../../../../../components/notifications/alertNotification";
import moment from "moment";

const StartDateSection = (props) => {
  const dispatch = useDispatch();
  const title = "Start date";
  const [selectedDate, setSelectedDate] = useState("");
  const [showError, setShowError] = useState(false);
  const [redirectionFlag, setRedirectionFlag] = useState(false);
  const history = useHistory();
  const profiledata = useSelector((state) => state.profileReducer.user);

  const apiSuccess = useSelector(
    (state) => state.profileReducer.signupUpdateSuccess
  );

  useEffect(() => {
    if (apiSuccess && redirectionFlag) {
      setRedirectionFlag(false);
      history.push("/basic-profile-success");
    }
  }, [apiSuccess]);

  useEffect(() => {
    if (profiledata?.profile?.DraftSkillListing?.StartDate) {
      setSelectedDate(
        moment(profiledata?.profile?.DraftSkillListing?.StartDate)
      );
    }
  }, [profiledata?.profile?.DraftSkillListing?.StartDate]);

  useEffect(() => {
    dispatch(
      updateSignupProfile({
        WizardFlow: 206,
      })
    );
  }, []);
  const handleNext = () => {
    if (selectedDate) {
      let draftSkillListing = {
        ...profiledata?.profile?.DraftSkillListing,
        StartDate: new Date(selectedDate)?.toISOString(),
        fromSignUp: true,
      };
      if (
        draftSkillListing.GigType == "Contract" ||
        draftSkillListing.GigType == "Per Diem"
      ) {
        draftSkillListing.ClientRate = draftSkillListing.MinimumBid * 1.49339;
      } else {
        draftSkillListing.ClientRate = draftSkillListing.MinimumBid;
      }
      dispatch(
        updateSignupProfile({
          "DraftSkillListing.StartDate": new Date(selectedDate)?.toISOString(),
        })
      );
      dispatch(
        createSkillListing({
          ...draftSkillListing,
          "Profiles.Accounts.Discipline":
            profiledata?.profile?.Discipline,
          "Profiles.Accounts.Speciality": [
            ...profiledata?.profile?.Speciality,
          ],
        })
      );
      alertNotification("success", "Skill Listing created successfully");

      // history.push("/basic-profile-success");
      setRedirectionFlag(true);
    } else {
      setShowError(true);
    }
  };

  const handleBack = () => {
    history.push("/create-skilllisting-salary");
  };

  const Skiphandle = () => {
    // if (selectedDate || true) {
      let draftSkillListing = {
        ...profiledata?.profile?.DraftSkillListing,
        // StartDate: new Date(selectedDate)?.toISOString(),
        fromSignUp: true,
      };
      if (
        draftSkillListing.GigType == "Contract" ||
        draftSkillListing.GigType == "Per Diem"
      ) {
        draftSkillListing.ClientRate = draftSkillListing.MinimumBid * 1.49339;
      } else {
        draftSkillListing.ClientRate = draftSkillListing.MinimumBid;
      }
      // dispatch(
      //   updateSignupProfile({
      //     "DraftSkillListing.StartDate": new Date(selectedDate)?.toISOString(),
      //   })
      // );
      dispatch(
        createSkillListing({
          ...draftSkillListing,
          "Profiles.Accounts.Discipline":
            profiledata?.profile?.Discipline,
          "Profiles.Accounts.Speciality": [
            ...profiledata?.profile?.Speciality,
          ],
        })
      );
      alertNotification("success", "Skill Listing created successfully");

      // history.push("/basic-profile-success");
      setRedirectionFlag(true);
    // } 
    history.push("/basic-profile-success");
  };

  return (
    <div className="CreateSkillListingHeaderScreen2">
      <div className="create-skill-listing-title">
        {title} <span className="Mandatory-Text">(Optional)</span>
      </div>
      <div className="create-skill-listing-subheader-other">
        <span>
          When can you start? Let potential employers know your availability.
        </span>
      </div>
      <DatePicker
        disabledDate={(current) => {
          return current && current.valueOf() < Date.now();
        }}
        value={selectedDate ? moment(selectedDate) : null}
        placeholder="MM DD, YYYY"
        format="MMMM DD, YYYY"
        className={`date-container-datepicker${
          showError ? " error-block" : ""
        }`}
        name="availableToStart"
        inputReadOnly={true}
        onChange={(date, dateString) => {
          if (dateString) {
            setSelectedDate(new Date(dateString)?.toISOString());
            setShowError(false);
          } else {
            setSelectedDate("");
          }
        }}
        suffixIcon={null}
      />
      {showError && (
        <div className="error-msg-content">
          <img src={errorIcon} alt="" />
          <span className="error-text">
            Please enter your start date to continue
          </span>
        </div>
      )}
      <BottomBottons
        loading={!apiSuccess}
        handleBack={handleBack}
        handleNext={handleNext}
        Skiphandle={Skiphandle}
        Skip={true}
      />
    </div>
  );
};

export default StartDateSection;
