import React, { useState, useEffect } from 'react';
import './EducationResumeSection.css';

const EducationResumeSection = ({ ResumeViewDetail }) => {
    const [NotDeleteRecords, setNotDeleteRecords] = useState([]);

    useEffect(() => {
        setNotDeleteRecords(ResumeViewDetail("Consultants_AcademicRecords"))
    }, [])

    return (
        <div className='Eduction-resume-cantainer'>
            <div className='ViewResume-heading-section'>
                EDUCATION
            </div>
            {NotDeleteRecords?.length > 0 ? NotDeleteRecords?.map((Courses) => {
                if (Courses?.Deleted === true) return;
                return (
                    <div className="ViewResume-sub-heading-section">
                        <div className="ViewResume-sub-heading-section-Right">
                            <div>{Courses?.Courses?.CourseName}  <span className='ViewResume-CourseTypes'> <span className='ViewResume-CourseTypes-seprate' >-</span> {Courses?.CourseTypes?.CourseTypeName}</span></div>
                            <div className='CourseTypes-SchoolName'>{Courses?.Schools?.SchoolName}</div>
                        </div>
                        <div className="ViewResume-sub-heading-section-Left">
                            {Courses?.YearCompleted}
                        </div>
                    </div>
                )
            }) : <div className='No_Data_Present' >NO DATA</div>}
        </div>
    );
};

export default EducationResumeSection;