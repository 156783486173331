import React from "react";
import UploadDocV2 from "../../UploadDoc";

const Social_security_Doc = () => {
  const docInfo = [
    {
      "Social security card": 88,
    },
  ];
  return (
    <div>
      <UploadDocV2
        docInfo={docInfo}
        backUrl="state-lisence-doc"
        nextUrl="driver-license-doc"
        currentPage={6}
        WizardFlow={115}
        skipUrl={"driver-license-doc"}
      />
    </div>
  );
};

export default Social_security_Doc;
