import React, { useState, useEffect, useRef } from "react";
import "../ChatCard/components/InterviewStatusCard.js/interviewStatusCard.css";
import {
  InterviewScheduleAcceptedInit,
} from "../../../../redux/actions";
import moment from "moment";
import {
  Modal,
  Divider,
  Input,
  Rate,
  Badge,
  Radio,
  Space,
  Button,
  DatePicker,
} from "antd";
import { useDispatch } from "react-redux";

const RescheduleModal = (props) => {

 const dispatch = useDispatch();
 const [ModalVisible, setModalVisible] = useState(false);
 const [isModalVisible, setIsModalVisible] = useState(false);
 const [clicked, setClicked] = useState();
 const [DateValueStage, setDateValueStage] = useState()
 const [DateValueStage1, setDateValueStage1] = useState()
 const [DateValueStage2, setDateValueStage2] = useState()
 const [Endtime, setEndtime] = useState();
 const [Endtime1, setEndtime1] = useState();
 const [Endtime2, setEndtime2] = useState();

// 

 const handleOk = () => {
  setModalVisible(false);
  props.setModalVisible(false)
  };

 const handleCancel = () => {
    // setIsModalVisible(true);
    props.setModalVisible(false)
  };

  const dateHandlerStages = (e) => {
    var ctime = new Date(e._d);
    const date = moment(ctime.toISOString());
    date.add(1, 'h');
    const options = {
      weekday: "long",
      year: "numeric",
      month: "short",
      day: "numeric",
      hour: "numeric",
      hour12: true,
    };
    setDateValueStage(ctime.toISOString());
    setEndtime(date.toISOString())
  };

  const dateHandlerStages1 = (e) => {
    var ctime = new Date(e._d);
    const date = moment(ctime.toISOString());
    date.add(1, 'h');
    const options = {
      weekday: "long",
      year: "numeric",
      month: "short",
      day: "numeric",
      hour: "numeric",
      hour12: true,
    };
    setDateValueStage1(ctime.toISOString());
    setEndtime1(date.toISOString())
  };

  const dateHandlerStages2 = (e) => {
    var ctime = new Date(e._d);
    const date = moment(ctime.toISOString());
    date.add(1, 'h');
    const options = {
      weekday: "long",
      year: "numeric",
      month: "short",
      day: "numeric",
      hour: "numeric",
      hour12: true,
    };
    setDateValueStage2(ctime.toISOString());
    setEndtime2(date.toISOString())
  };

  const rescheduleInterview = () => {
    // 
    dispatch(
      InterviewScheduleAcceptedInit({
        id: props.InterviewCard,
        reschedule: true,
        body: {
          ReScheduledTimings: [
            {
              StartTime: moment.utc(DateValueStage),
              EndTime: moment.utc(Endtime),
            },
            {
              StartTime: moment.utc(DateValueStage1),
              EndTime: moment.utc(Endtime1),
            },
            {
              StartTime: moment.utc(DateValueStage2),
              EndTime: moment.utc(Endtime2),
            },
          ],
          InterviewStatus: "Rescheduled",
          Message: "You have rescheduledthe interview",
        },
      })
    );
    // 
    handleOk();
  };
  


 return(
        <>
        <Modal
        bodyStyle={{ padding: 0 }}
        width={window.innerWidth < 768 ? "20.625rem" : "22.5rem"}
        title={
          'Reschedule Interview'
          // reschedule ? "Reschedule Interview" : "Not Interested in this Bid?"
        }
        className="message-details-model"
        visible={!isModalVisible ? props.ModalVisible : false}
        onOk={handleOk}
        onCancel={handleCancel}
        footer={null}
        centered={true}
        closable={clicked === "retract-bid" ? false : true}
      >
        <div className="Bid-decline-model">
          <>
            <div className="bid-decline-headline reshedule_interview_title">
              Let's find a better time to set up your interview.
            </div>
            <div className="bid-decline-headline bid-decline-headline-rechedule bid-rechedule-text reshedule_interview_content">
              <span style={{ fontWeight: "500" }}>Interview timings</span>(Propose up to 3 of your available interview times).
            </div>
            <div className="bid-decline-options">
              <DatePicker
                placeholder="Choose a date and time"
                // format="dddd, MMMM d, YYYY HH:mm A"
                // suffixIcon={<img src={CalendarIcon} alt="Calender" />}
                className="bid-reschedule-date-picker"
                // style={{
                //   width: "100%",
                //   marginBottom: "0.635rem",
                //   borderRadius: "0.25rem",
                //   border: "1px solid #e4e4e4",
                // }}
                showTime={{
                  defaultValue: moment("00:00:00", "HH:mm:ss"),
                }}
                onOk={(e) => dateHandlerStages(e)}
              />
            </div>
            <div className="bid-decline-options">
              <DatePicker
                placeholder="Choose a date and time"
                // format="dddd, MMMM d, YYYY HH:mm A"
                // suffixIcon={<img src={CalendarIcon} alt="Calender" />}
                className="bid-reschedule-date-picker"
                showTime={{
                  defaultValue: moment("00:00:00", "HH:mm:ss"),
                }}
                onOk={(e) => dateHandlerStages1(e)}
              />
            </div>
            <div className="bid-decline-options">
              <DatePicker
                placeholder="Choose a date and time"
                // format="dddd, MMMM d, YYYY HH:mm A"
                // suffixIcon={<img src={CalendarIcon} alt="Calender" />}
                className="bid-reschedule-date-picker"
                showTime={{
                  defaultValue: moment("00:00:00", "HH:mm:ss"),
                }}
                onOk={(e) => dateHandlerStages2(e)}
              />
            </div>
            <div
              className="bid-decline-button bid-rechedule-button"
              onClick={rescheduleInterview}
            >
              Send reschedule request
            </div>
          </>

        </div>
      </Modal>
        </>
    )
}

export default RescheduleModal;
