import { Modal, Tooltip } from "antd";
import React from "react";
import { AiFillWarning, AiOutlineHome } from "react-icons/ai";
import { IoMdArrowRoundBack } from "react-icons/io";
import { useDispatch, useSelector } from "react-redux";
import { catchErrorFix } from "../../../redux/actions";

const ErrorModel = ({ children }) => {
  const { isError, message } = useSelector((state) => state.catchErrorReducer);
  const WizardFlow =
    useSelector((state) => state.profileReducer.user?.profile?.WizardFlow) ||
    "";
  const authTokenValue =
    useSelector((state) => state.authReducer.user.authToken) || "";
  const dispatch = useDispatch();
  const handlingOnClick = (query) => {
    dispatch(catchErrorFix());
    query === "goBack" ? window.history.back() : window.location.replace("/");
    setTimeout(() => {
      window.scrollTo({ top: 0, left: 0, behavior: "auto" });
    }, 1000);
  };
  return (
    <>
      {children}
      <Modal visible={isError} centered={true} footer={false} closable={false}>
        <div className="errorModel">
          <AiFillWarning size={"30px"} color="#faad14" />
          <h2>Look Like something went wrong</h2>
          <p>
            <b>{message}</b>
          </p>
          <p>Please reload the page and try again</p>
          <Tooltip title={"Go back"}>
            <div className="errorModelGoBackBtn">
              <IoMdArrowRoundBack
                size={"24px"}
                onClick={() => handlingOnClick("goBack")}
              />
            </div>
          </Tooltip>
          {(!authTokenValue || WizardFlow === 6) && (
            <Tooltip title={"Go home"}>
              <div className="errorModelGoHomeBtn">
                <AiOutlineHome
                  size={"24px"}
                  onClick={() => handlingOnClick()}
                />
              </div>
            </Tooltip>
          )}
          <div
            className="errorModelButton"
            onClick={() => window.location.reload()}
          >
            Reload Now
          </div>
        </div>
      </Modal>
    </>
  );
};

export default ErrorModel;
