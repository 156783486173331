import React from "react";
import { useDispatch } from "react-redux";
import { getFileIcon } from "../../../../../../components/fileInformation/index";
import { downloadAttachments } from "../../../../../../shared/downloadAttachments";
import './styles.css'

const AttachmentContainer = ({ fileName, filePath }) => {

    const dispatch = useDispatch();
    const getFileFormat = (fileName) => {
        const behideDot = fileName?.lastIndexOf(".") + 1;
        const fileLength = fileName?.length;
        const fileFormat = fileName?.slice(behideDot, fileLength);
        return fileFormat;
    };

    return (
        <div className="attachment-container-msg" onClick={() => {
        //    downloadAttachments(filePath.substring(15)).then((result)=>{
        //     // 
        //     window.open(result);
        //    })
        window.open(`${process.env.REACT_APP_S3_URL}/${filePath}`)
            // // 
        }}>
            <div className="attachment-content-msg">
                <img
                    src={getFileIcon(getFileFormat(fileName))}
                    // alt={fileName}
                    className="fileIcon-msg"
                />
                <div className="attachment-content-info-msg">
                    <h3>{fileName}</h3>
                    <p>{getFileFormat(fileName)}</p>
                </div>
            </div>
        </div>
    )
}
export default AttachmentContainer