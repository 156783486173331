import React from 'react';
import './SkillListingTips.css'

import GettingStart from '../../../../assets/img/GettingStart.png'

const TipToGettingStart = (props) => {
    const {pageType, ProfessionCode} = props;
    let gigTypePoints = [
        "Completing your Skill Listing is essential",
        "Once it's done, we'll direct Gigs traffic and you'll begin receiving those crucial BIDS"
    ]
    let skillListingTitleHCPoints = [
        "The system provides discipline- and specialty-based suggestions.",
        "The system also enables the input of a customized title.",
        "You may modify your discipline and specialty preferences at any time through your profile settings."
    ]
    let skillListingTitleITPoints = [
        "Employers will place bid on your skill listing to be thorough",
        "You can create more than one skill listing",
        "Give the the title of the gig you want",
        "When it comes to salary requirements, be fair but know your worth and ask for it."
    ]
    let displayPoints = pageType === "gigType" ? gigTypePoints : ProfessionCode === "HC" ? skillListingTitleHCPoints : skillListingTitleITPoints;

    return (
        <>
        
            <div className='Skill-Listing-Tips-Started-Container' >
                <div className='Skill-Listing-Circle-Container'>
                <span className='Skill-Listing-Tips-Started-Cricle' >
                    <img src={GettingStart} />
                </span>
                </div>
                
                <div>
                    <div className='Skill-Listing-Tips-Started-Heading' >
                        {pageType === "gigType" ? "Tips : getting started" : "Tips : Crafting title" }
                    </div>
                    <div className='Skill-Listing-Tips-Started-Sub-Heading-container' >
                        <div className='Skill-Listing-Tips-Started-Sub-Heading' >
                        {pageType === "gigType" ? "BIDS are how candidates are hired on SkillGigs" : 
                        "Craft a skill listing title that enhances your ability to attract bids" }
                        </div>
                    </div>
                    <div className='Skill-Listing-Tips-Started-Important' >
                        Important
                    </div>
                    <div className='Skill-Listing-Tips-Started-points-container' >
                        {
                            displayPoints.map((point,index) => (
                                <div className='Skill-Listing-Tips-Started-points' key={index}>
                            {point}
                        </div>
                            ))
                        }
                    </div>
                </div>
            </div>
        </>
    );
};

export default TipToGettingStart;