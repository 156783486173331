import React from "react";
import { handlingfalsyValue } from "../../../Manage_SkillListing/SkillListingDetailPage/components/BidHistorySkillListing/bhsHandler";
import { Avatar, Badge } from "antd";
import { useState } from "react";

const ProfileAvatar = ({
  isOnline = false,
  name,
  size = 40,
  offset = [-5, 35],
  src,
  dot = true,
  isAvatarBgColor = false,
  bidType
}) => {
  const [onLoadImgErr, setOnLoadImgErr] = useState(false);
  return (
    <>
      <Badge
        color={isOnline ? "#118936" : "#9e9e9e"}
        className={`ProfileAvatar ${bidType == "Others" ? "Admin-Avatar-section" : null}`}
        dot={dot}
        offset={offset}
      >
        <Avatar
          size={size}
          src={handlingfalsyValue(src)}
          className={isAvatarBgColor && onLoadImgErr ? "avatarBgColor" : ""}
          onError={() => setOnLoadImgErr(true)}
        >
          {handlingfalsyValue(name?.slice(0, 1)?.toUpperCase())}
        </Avatar>
      </Badge>
    </>
  );
};

export default ProfileAvatar;
