import React, { useState } from "react";
import { useHistory } from "react-router-dom";
import ProfileCompletion from "./Components/ProfileCompletion/ProfileCompletion";
import "./SignUp.css";
import BottomButtons from "./Components/BottomBottons/BottomButtons";
import RadioInput from "./Components/RadioInput/RadioInput";
import { useDispatch, useSelector } from "react-redux";
import { Alert } from "antd";
import { updateSignupProfile } from "../../redux/actions";
import { useEffect } from "react";
const options = ['On prem', 'Hybrid', "Remote"];

function WorkPlacePolicyScreen() {
  const windowHeight = window.innerHeight-50;
  const history = useHistory();
  const dispatch = useDispatch();
  const [selected, setSelected] = useState(null);
  const [error, setError] = useState(null);
  const [flag, setFlag] = useState(false);
  const profiledata = useSelector
  ((state) => state.profileReducer.user);
  const loading = useSelector((state) => state.profileReducer.signUpLoading);
  const apiError = useSelector((state) => state.profileReducer.error);
  const apiSuccess = useSelector((state) => state.profileReducer.signupUpdateSuccess);
  useEffect(() => {
    if(apiSuccess && flag){
      setFlag(false);
      history.push("/workAuthorization");
    }
  }, [apiSuccess])
  useEffect(() => {
    if(profiledata?.profile?.WorkPlacePolicy){
      setSelected(profiledata.profile.WorkPlacePolicy);
    }
  }, [profiledata])
  const nextHandler = () => {
    if(!selected){
      setError("Please add your workplace policy to continue")
    }else{
      setFlag(true);
      setError(null);
      dispatch(updateSignupProfile({
        "Profiles.WorkPlacePolicy":selected,
        WizardFlow:107
      }))
    }
  }
  return (
    <div className="NameScreenContainer" style={{minHeight:windowHeight}}>
      {typeof apiError === "string" && <Alert message={apiError} type="error" className="alert-error" showIcon closable/>}
      <ProfileCompletion currenPage={7} totalPage={10} />
      <RadioInput
        title={"Add your workplace policy"}
        type={"Mandatory"}
        onChange={(e) => {setSelected(e.target.value); error&&setError(null)}}
        options = {options}
        keyVal = {null}
        value={selected}
        error={error}
      />
      <BottomButtons 
        nextHandler={nextHandler}
        nextBtnLoading={loading}
        backHandler={() => history.push('/explever')} />
    </div>
  );
}

export default WorkPlacePolicyScreen;
