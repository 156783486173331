import React, { useState, useEffect } from "react";
import { useHistory } from "react-router-dom";
import ProfileCompletion from "./Components/ProfileCompletion/ProfileCompletion";
import "./SignUp.css";
import BottomButtons from "./Components/BottomBottons/BottomButtons";
import RadioInput from "./Components/RadioInput/RadioInput";
import { useDispatch, useSelector } from "react-redux";
import { Alert } from "antd";
import { updateSignupProfile } from "../../redux/actions";
import BackNextSignUpButton from "./Components/DocUpload/Signup_gap_screen/BackNextSignUpButton";
const exp = [
  {
    "ExperienceLevelId": 1,
    "ExperienceLevelCode": "less",
    "ExperienceLevelName": "Less than 1 year"
  },
  {
    "ExperienceLevelId": 2,
    "ExperienceLevelCode": "2",
    "ExperienceLevelName": "1-2 years"
  },
  {
    "ExperienceLevelId": 3,
    "ExperienceLevelCode": "3-5",
    "ExperienceLevelName": "3-5 years"
  },
  {
    "ExperienceLevelId": 4,
    "ExperienceLevelCode": "6",
    "ExperienceLevelName": "6-10 years"
  },
  {
    "ExperienceLevelId": 5,
    "ExperienceLevelCode": "more",
    "ExperienceLevelName": "More than 10 years"
  },
  ];

function ExperienceLevelScreen() {
  const windowHeight = window.innerHeight;
  const history = useHistory();
  const dispatch = useDispatch();
  const [selected, setSelected] = useState(null);
  const [error, setError] = useState(null);
  const [flag, setFlag] = useState(false);
  const profiledata = useSelector((state) => state.profileReducer.user);
  const loading = useSelector((state) => state.profileReducer.signUpLoading);
  const apiError = useSelector((state) => state.profileReducer.error);
  const apiSuccess = useSelector((state) => state.profileReducer.signupUpdateSuccess);
  useEffect(() => {
    if(profiledata?.profile?.ExperienceLevels?.ExperienceLevelName){
      setSelected(profiledata.profile.ExperienceLevels?.ExperienceLevelName)
    }
  }, [profiledata])
  useEffect(() => {
    if (apiSuccess && flag && profiledata?.profile?.ProfessionCode === "IT"){
      setFlag(false);
      history.push("/workPlacePolicy");
    } else if (apiSuccess && flag && profiledata?.profile?.ProfessionCode === "HC"){
      setFlag(false);
      history.push("/workAuthorization");
    }
  }, [apiSuccess])
  const nextHandler = () => {
    if(!selected){
      setError("Please add your years of experience to continue")
    }else{
      setFlag(true);
      const res = exp.find(item => item.ExperienceLevelName === selected);
      dispatch(updateSignupProfile({
        "Profiles.ExperienceLevels":res,
        WizardFlow: profiledata?.profile?.ProfessionCode === "IT" ? 106 : 107
      }))
    }
  }
  return (
    <div className="NameScreenContainer Container-ExperienceLevelScreen" style={{minHeight:windowHeight}}>
      {typeof apiError === "string" && <Alert message={apiError} type="error" className="alert-error" showIcon closable/>}
      <ProfileCompletion currenPage={6} totalPage={10} />
      <RadioInput
        title={"Years of experience"}
        type={"Optional"}
        onChange={(e) => {setSelected(e.target.value); error&&setError(null)}}
        options = {exp}
        keyVal = {"ExperienceLevelName"}
        value={selected}
        error = {error}
      />
      <BackNextSignUpButton 
        nextFunction={nextHandler} 
        nextBtnLoading={loading}
        backFunction={() => history.push('/mobileno')}
        skipFunction={() => history.push('/workAuthorization')}
        skip={ true }
        />
    </div>
  );
}

export default ExperienceLevelScreen;
