import React, { useState, useEffect, useRef } from "react";
import "./WorkHistory.css";
import moment from "moment";
import { Form, Select, Input, DatePicker, Button, Space, Spin } from "antd";
import { MinusCircleOutlined, PlusOutlined } from "@ant-design/icons";
import CalendarIcon from "../../../../assets/icons/CalendarIcon.svg";
import Uncheck from "../../../../assets/icons/carbon_checkbox-unchecked.svg";
import Check from "../../../../assets/icons/carbon_checkbox-checked.svg";
import { AiOutlineEdit } from "react-icons/ai";
import { RiDeleteBin5Fill } from "react-icons/ri";
import { AiFillSave } from "react-icons/ai";
import { AiOutlineUndo } from "react-icons/ai";

import { useUpdateLogger } from "../../../../shared/useUpdateLogger";
import {
  fetchIndustryWorkHistory,
  fetchJobTitleWorkHistory,
  fetchLocationWorkHistory,
  fetchSkillsWorkHistory,
  postWorkHistory,
  updateWorkHistory,
} from "../../../../redux/actions";
import { useDispatch, useSelector } from "react-redux";
import { debounce } from "../../../../shared/helpers";

const WorkHistoryForm = (props) => {
  const dispatch = useDispatch();

  const {
    formSubmitRef,
    setAddState,
    setFormVisiblity,
    setUpdateWork,
    totalPercent,
    setTotalPercent,
    error,
    setError,
  } = props;

  const {
    idData,
    TitleData,
    DescriptionData,
    CompanyNameData,
    StartDateData,
    EndDateData,
    CurrentJobData,
    LocationData,
    ResponsibilitiesData,
    IndustryNameData,
    SkillsData,
  } = props;

  const { Option } = Select;
  const [form] = Form.useForm();

  const inputSkill = useRef();

  const EditIcon = <AiOutlineEdit className="iconColorED" />;
  const DeleteIcon = <RiDeleteBin5Fill className="iconColorED" />;

  const profile = useSelector((state) => state.profileReducer.user.profile);

  const loading = useSelector((state) => state.profileReducer.loading);

  const jobTitleWorkHistory = useSelector(
    (state) => state.profileReducer.jobTitleWorkHistory
  );

  const industryWorkHistory = useSelector(
    (state) => state.profileReducer.industryWorkHistory
  );

  const locationWorkHistory = useSelector(
    (state) => state.profileReducer.locationWorkHistory
  );
  // //
  const skillsWorkHistory = useSelector(
    (state) => state.profileReducer.skillsWorkHistory
  );

  const [skillArray, setSkillArray] = useState([]);

  // useUpdateLogger("skillsWorkHistory", skillsWorkHistory);

  const [percentValueInit, setPercentValueInit] = useState(null);
  const [percentValueEdit, setPercentValueEdit] = useState(null);
  // const [totalPercent, setTotalPercent] = useState(0);

  const [skillValue, setSkillValue] = useState(null);
  const [noDataViewSkillValue, setNoDataViewSkillValue] = useState("");

  const [editSkillPercent, setEditSkillPercent] = useState(null);

  const [checkState, setCheckState] = useState(false);

  const [CompanyNameState, setCompanyNameState] = useState(null);

  const [jobTitleState, setJobTitleState] = useState(null);
  const [noDataViewJobTitle, setNoDataViewJobTitle] = useState("");

  const [industryState, setIndustryState] = useState(null);
  const [noDataViewIndustry, setNoDataViewIndustry] = useState("");

  const [locationState, setLocationState] = useState(null);
  const [noDataViewLocation, setNoDataViewLocation] = useState("");

  const [startDateState, setStartDateState] = useState(null);
  const [endDateState, setEndDateState] = useState(null);
  const [roleDescriptionState, setRoleDescriptionState] = useState(null);
  const [responsibilitiesState, setResponsibilitiesState] = useState(null);

  // const [error, setError] = useState(false);
  const [checkUpdate, setCheckUpdate] = useState(false);

  useEffect(() => {
    return () => {
      setTotalPercent(0);
      setError(false);
    };
  }, []);

  useEffect(() => {
    let total = 0;
    skillArray.map((skill) => {
      total = total + parseInt(skill.SkillPercent);
      setTotalPercent(total);
    });

    if (totalPercent + parseInt(percentValueInit) === 100) {
      setError(false);
    }
  }, [totalPercent, percentValueInit, skillArray]);

  const getSortListData = (n, m, t, key) => {
    const a = n[key];
    const b = m[key];
    return t === "ascending"
      ? a.localeCompare(b)
      : t === "descending"
      ? b.localeCompare(a)
      : null;
  };

  useEffect(() => {
    //
    //   CompanyNameState,
    //   jobTitleState,
    //   industryState,
    //   locationState,
    //   startDateState,
    //   endDateState,
    //   roleDescriptionState,
    //   responsibilitiesState,
    //   skillArray,
    //   checkState,
    // });
    if (
      (idData && industryState) ||
      jobTitleState ||
      CompanyNameState ||
      locationState ||
      startDateState ||
      endDateState ||
      checkState ||
      roleDescriptionState ||
      responsibilitiesState
    ) {
      setCheckUpdate(true);
    }

    if (
      idData &&
      (CompanyNameState || CompanyNameData) &&
      (jobTitleState || TitleData) &&
      (industryState || IndustryNameData) &&
      (locationState || LocationData) &&
      (startDateState || StartDateData) &&
      (endDateState || checkState || EndDateData) &&
      (roleDescriptionState || DescriptionData) &&
      (responsibilitiesState || ResponsibilitiesData) &&
      checkUpdate
    ) {
      return setAddState(true);
    }

    if (
      CompanyNameState &&
      jobTitleState &&
      industryState &&
      locationState &&
      startDateState &&
      (endDateState || checkState) &&
      roleDescriptionState &&
      responsibilitiesState &&
      skillArray.length > 0
    ) {
      return setAddState(true);
    }

    return setAddState(false);
  }, [
    CompanyNameState,
    jobTitleState,
    industryState,
    locationState,
    startDateState,
    endDateState,
    roleDescriptionState,
    responsibilitiesState,
    skillArray,
    checkState,
    checkUpdate,
  ]);

  const handleCheck = () => {
    setCheckState(!checkState);
  };

  const handleSkillAdd = (SkillName, SkillPercent) => {
    if (!SkillName && !SkillPercent) return;
    setEditSkillPercent(null);
    setSkillValue(null);
    setPercentValueInit(null);
    setSkillArray([...skillArray, { SkillName, SkillPercent }]);
  };

  const deleteSkill = (skill) => {
    const filterArr = skillArray.filter((obj, i) => i !== skill);

    // //
    let total = 0;
    if (filterArr.length < 1) {
      setTotalPercent(0);
    } else {
      filterArr.map((skill) => {
        total = total + parseInt(skill.SkillPercent);
        setTotalPercent(total);
      });
    }

    setSkillArray(filterArr);
    setSkillValue(null);
    setEditSkillPercent(null);
    setCheckUpdate(true);
    if (filterArr.length === 0) {
      setError(false);
      form.setFields([
        {
          name: "skillInit",
          errors: [],
        },
      ]);
      form.setFields([
        {
          name: "percentInit",
          errors: [],
        },
      ]);
    }
  };

  const handleEditSkillPercent = (skill, percent, i) => {
    const percentInt = parseInt(percent);
    let total = 0;

    for (let skill of skillArray) {
      if (skillArray.indexOf(skill) === i) continue;
      total = total + parseInt(skill.SkillPercent);
    }

    if (total + percentInt <= 100) {
      setTotalPercent(total + percentInt);
      skillArray[i].SkillPercent = percentInt;
      if (skill) skillArray[i].SkillName = skill;
      setEditSkillPercent(null);
      setSkillValue(null);
      setError(false);
      return;
    }

    setError(true);
  };

  useEffect(() => {
    if (idData) {
      if (Array?.isArray(ResponsibilitiesData)) {
        setResponsibilitiesState(
          ResponsibilitiesData?.length === 0 ? null : ResponsibilitiesData
        );
      } else if (ResponsibilitiesData) {
        setResponsibilitiesState(ResponsibilitiesData ?? null);
      }
    }
  }, [DescriptionData, ResponsibilitiesData]);

  const onFinish = (values) => {
    if (totalPercent !== 100) {
      return setError(true);
    }

    const {
      companyName,
      jobTitle,
      industry,
      location,
      startDate,
      endDate,
      Description,
      responsibilities,
    } = values;

    if (idData) {
      const updateData = {
        CompanyName: CompanyNameState || CompanyNameData,
        Title: jobTitleState || TitleData,
        IndustryName: industryState || IndustryNameData, //
        Location: locationState || LocationData,
        StartDate: startDateState || StartDateData,
        EndDate: checkState
          ? null
          : endDateState || (!CurrentJobData ? EndDateData : null),
        CurrentJob: checkState,
        Description: roleDescriptionState
          ? roleDescriptionState
          : !roleDescriptionState && roleDescriptionState != ""
          ? DescriptionData
          : "",
        Skills: skillArray, //
        Responsibilities: responsibilitiesState, //
      };
      dispatch(updateWorkHistory(updateData, idData));
      setUpdateWork(false);
      return;
    }

    const workHistory = {
      CompanyName: companyName,
      Title: jobTitle,
      IndustryName: industry, //
      Location: location,
      StartDate: startDate,
      EndDate: !checkState ? endDate : null,
      CurrentJob: checkState,
      Description: Description,
      Skills: skillArray, //
      Responsibilities: responsibilities, //
    };

    dispatch(postWorkHistory(workHistory));
    setFormVisiblity(false);
  };

  let defaultValues;

  useEffect(() => {
    // maintaining the ratio of skill percentage
    let total = 0;
    skillArray.map((skill) => {
      total = total + parseInt(skill.SkillPercent);
      setTotalPercent(total);
    });

    // adding default skill value
    defaultValues = {
      skillInit: null,
      percentInit: null,
    };
    form.setFieldsValue(defaultValues);
  }, [skillArray]);

  // useEffect(() => {
  //   if (skillArray.length > 0) inputSkill.current.focus();
  // }, [editSkillPercent]);

  useEffect(() => {
    if (idData) {
      //
      //   CompanyNameData,
      //   TitleData,
      //   IndustryNameData,
      //   LocationData,
      //   StartDateData,
      //   EndDateData,
      //   DescriptionData,
      //   ResponsibilitiesData,
      // });
      setCheckState(CurrentJobData);
      setSkillArray(SkillsData);
      defaultValues = {
        companyName: CompanyNameData,
        jobTitle: TitleData,
        industry: IndustryNameData,
        location: LocationData,
        startDate: moment(StartDateData),
        endDate: EndDateData ? moment(EndDateData) : null,
        Description: DescriptionData,
        responsibilities: ResponsibilitiesData,
      };
      form.setFieldsValue(defaultValues);
    }
  }, [idData]);
  // useUpdateLogger("skillArray", skillArray);
  // useUpdateLogger("ResponsibilitiesData", ResponsibilitiesData);

  const onJobTitleSearch = (e) => {
    setNoDataViewJobTitle(e);
    dispatch(fetchJobTitleWorkHistory(e));
  };

  const onIndustrySearch = (e) => {
    setNoDataViewIndustry(e);
    dispatch(fetchIndustryWorkHistory(e));
  };

  const onLocationSearch = (e) => {
    setNoDataViewLocation(e);
    dispatch(fetchLocationWorkHistory(e));
  };

  const onSkillSearch = (e) => {
    // //
    setNoDataViewSkillValue(e);
    dispatch(
      fetchSkillsWorkHistory({ val: e, profCode: profile.ProfessionCode })
    );
  };

  function disableDateRanges() {
    return function disabledDate(current) {
      let startCheck = true;
      let endCheck = true;
      let startDate = new Date(startDateState);
      let endDate = moment();

      if (startDate) {
        startCheck = current && current < moment(startDate, "YYYY-MM-DD");
      }
      if (endDate) {
        endCheck = current && current > moment(endDate, "YYYY-MM-DD");
      }
      return startDate && startCheck;
    };
  }

  return (
    <>
      <Form
        onFinish={onFinish}
        form={form}
        preserve={false}
        initialValues={defaultValues}
      >
        <Form.Item className="twoItemInARow">
          <Form.Item
            rules={[
              {
                required: true,
                message: () => (
                  <div className="error-msg-workhistory">
                    Please add company name
                  </div>
                ),
              },
            ]}
            name="companyName"
          >
            {/* <Select
              placeholder="Company Name"
              className="selectWorkHistory"
              showArrow={false}
              showSearch
            >
              <Option key={1}>1</Option>
              <Option key={2}>2</Option>
              <Option key={3}>3</Option>
            </Select> */}
            <Input
              placeholder="Company name"
              className="inputWorkHistory"
              onChange={(e) => setCompanyNameState(e.target.value)}
            />
          </Form.Item>
          <Form.Item
            rules={[
              {
                required: true,
                message: () => (
                  <div className="error-msg-workhistory">
                    Please add gig title
                  </div>
                ),
              },
            ]}
            name="jobTitle"
          >
            <Select
              notFoundContent={
                noDataViewJobTitle?.length > 0 ? (
                  <div>
                    {/* {loading || jobTitleWorkHistory?.length > 0 ? ( */}
                    {loading ? <Spin size="small" /> : "No data found!"}
                  </div>
                ) : null
              }
              placeholder="Gig Title"
              className="selectWorkHistory"
              dropdownClassName="rightDropdownWorkHistory"
              onSearch={debounce((e) => onJobTitleSearch(e))}
              onChange={(val) => setJobTitleState(val)}
              placement="bottomRight"
              showArrow={false}
              showSearch
            >
              {jobTitleWorkHistory?.map((job) => {
                return <Option key={job}>{job}</Option>;
              })}
            </Select>
          </Form.Item>
        </Form.Item>
        <Form.Item className="twoItemInARow">
          <Form.Item
            rules={[
              {
                required: true,
                message: () => (
                  <div className="error-msg-workhistory">
                    Please add industry
                  </div>
                ),
              },
            ]}
            name="industry"
          >
            <Select
              notFoundContent={
                noDataViewIndustry?.length > 0 ? (
                  <div>
                    {loading || industryWorkHistory?.length > 0 ? (
                      <Spin size="small" />
                    ) : (
                      "No data found!"
                    )}
                  </div>
                ) : null
              }
              placeholder="Industry"
              className="selectWorkHistory"
              onSearch={debounce((e) => onIndustrySearch(e))}
              onChange={(val) => setIndustryState(val)}
              dropdownClassName="leftDropdownWorkHistory"
              // dropdownStyle={{ width: "20rem", backgroundColor: "green" }}
              showSearch
            >
              {industryWorkHistory?.map((ind) => {
                return <Option key={ind}>{ind}</Option>;
              })}
            </Select>
          </Form.Item>
          <Form.Item
            rules={[
              {
                required: true,
                message: () => (
                  <div className="error-msg-workhistory">
                    Please add location
                  </div>
                ),
              },
            ]}
            name="location"
          >
            <Select
              notFoundContent={
                noDataViewLocation?.length > 0 ? (
                  <div>
                    {loading ? (
                      // {loading || locationWorkHistory?.length > 0 ? (
                      <Spin size="small" />
                    ) : (
                      "No data found!"
                    )}
                  </div>
                ) : null
              }
              placeholder="Location"
              className="selectWorkHistory"
              dropdownClassName="rightDropdownWorkHistory"
              showArrow={false}
              onSearch={debounce((e) => onLocationSearch(e))}
              onChange={(val) => setLocationState(val)}
              placement="bottomRight"
              showSearch
            >
              {locationWorkHistory?.map((loc) => {
                return (
                  <Option key={loc?.LocationWithCountry}>
                    {loc?.LocationWithCountry}
                  </Option>
                );
              })}
            </Select>
          </Form.Item>
        </Form.Item>
        <Form.Item className="twoItemInARow">
          <Form.Item
            rules={[
              {
                required: true,
                message: () => (
                  <div className="error-msg-workhistory">
                    Please add start date
                  </div>
                ),
              },
            ]}
            name="startDate"
          >
            <DatePicker
              className="datepickerWorkHistory"
              placeholder="Start date"
              suffixIcon={
                <img src={CalendarIcon} width="16px" alt="calendar" />
              }
              onChange={(date) => setStartDateState(date.toISOString())}
              format={"ll"}
              allowClear={false}
              inputReadOnly={true}
              disabledDate={(current) => {
                let maxDate = moment();
                return current && current.valueOf() > maxDate;
              }}
            />
          </Form.Item>
          <Form.Item
            rules={[
              {
                required: checkState ? false : true,
                message: () => (
                  <div className="error-msg-workhistory">
                    Please add end date
                  </div>
                ),
              },
            ]}
            name="endDate"
          >
            <DatePicker
              format={"ll"}
              className={
                checkState
                  ? "datepickerWorkHistory disable"
                  : "datepickerWorkHistory"
              }
              placeholder="End date"
              suffixIcon={
                <img src={CalendarIcon} width="16px" alt="calendar" />
              }
              onChange={(date) => setEndDateState(date.toISOString())}
              disabled={checkState}
              allowClear={false}
              inputReadOnly={true}
              placement="bottomRight"
              disabledDate={disableDateRanges()}
            />
          </Form.Item>
        </Form.Item>
        <div className="checkbox-WorkHistory">
          <img
            className="clickable"
            src={checkState ? Check : Uncheck}
            alt="checkBox"
            onClick={handleCheck}
          />
          <span>Current job</span>
        </div>
        <Form.Item
          rules={[
            {
              required: true,
              message: () => (
                <div className="error-msg-workhistory">
                  Please add role description
                </div>
              ),
            },
          ]}
          className="roleWorkHistory"
          name="Description"
        >
          <Input.TextArea
            placeholder="Role description"
            className="textArea-workHistory"
            maxLength={600}
            onChange={(e) => setRoleDescriptionState(e.target.value)}
          />
        </Form.Item>
        <Form.Item className="itemWorkHistory">
          {skillArray.map((data, i) => {
            return (
              <Form.Item className="itemWorkHistory-combo">
                <Form.Item>
                  <Select
                    notFoundContent={
                      noDataViewSkillValue?.length > 0 ? (
                        <div>
                          {loading || skillsWorkHistory?.length > 0 ? (
                            <Spin size="small" />
                          ) : (
                            "No data found!"
                          )}
                        </div>
                      ) : null
                    }
                    placeholder="Skills"
                    className="skillSelectWorkHistory"
                    showArrow={false}
                    // defaultValue={data.SkillName}
                    value={
                      editSkillPercent === i && skillValue
                        ? skillValue
                        : data.SkillName
                    }
                    onSearch={debounce((val) => onSkillSearch(val))}
                    onChange={(e) => setSkillValue(e)}
                    showSearch
                    disabled={editSkillPercent !== i}
                  >
                    {skillsWorkHistory
                      ?.sort((a, b) =>
                        getSortListData(a, b, "ascending", "SkillName")
                      )
                      ?.map((skill) => {
                        return (
                          <Option key={skill.SkillName}>
                            {skill.SkillName}
                          </Option>
                        );
                      })}
                  </Select>
                </Form.Item>
                <Form.Item>
                  <Form.Item noStyle>
                    <Input
                      className="inputPercentWorkHistory"
                      placeholder="Percent"
                      value={
                        editSkillPercent === i
                          ? percentValueEdit
                          : data.SkillPercent
                      }
                      onChange={(e) => {
                        const val = e.target.value;
                        if (val >= 0 && val <= 100 && !val.startsWith(0))
                          setPercentValueEdit(val);
                      }}
                      disabled={editSkillPercent !== i}
                      ref={inputSkill}
                    />
                  </Form.Item>
                  <div className="editDeleteWorkHistory skill-add-btn">
                    {editSkillPercent === i ? (
                      <>
                        <span>
                          <AiFillSave
                            className="iconColorED clickable"
                            onClick={() => {
                              //
                              setCheckUpdate(true);
                              handleEditSkillPercent(
                                skillValue,
                                percentValueEdit,
                                i
                              );
                            }}
                          />
                        </span>
                        <span>
                          <AiOutlineUndo
                            className="iconColorED clickable"
                            onClick={() => setEditSkillPercent(null)}
                          />
                        </span>
                      </>
                    ) : (
                      <>
                        <span
                          className="clickable"
                          onClick={() => {
                            setPercentValueEdit(skillArray[i].SkillPercent);
                            setEditSkillPercent(i);
                          }}
                        >
                          {EditIcon}
                        </span>
                        <span
                          className="clickable"
                          onClick={() => deleteSkill(i)}
                        >
                          {DeleteIcon}
                        </span>
                      </>
                    )}
                  </div>
                </Form.Item>
              </Form.Item>
            );
          })}
          {totalPercent < 100 && (
            <Form.Item className="itemWorkHistory-combo">
              <Form.Item
                rules={[
                  {
                    required: true,
                    message: () => (
                      <div className="error-msg-workhistory">
                        Please add skills
                      </div>
                    ),
                  },
                ]}
                name="skillInit"
              >
                <Select
                  notFoundContent={
                    noDataViewSkillValue?.length > 0 ? (
                      <div>
                        {loading || skillsWorkHistory?.length > 0 ? (
                          <Spin size="small" />
                        ) : (
                          "No data found!"
                        )}
                      </div>
                    ) : null
                  }
                  name="skillInit"
                  placeholder="Skills"
                  className="skillSelectWorkHistory"
                  dropdownClassName="leftDropdownWorkHistory"
                  onSearch={debounce((val) => onSkillSearch(val))}
                  onChange={(e) => setSkillValue(e)}
                  showArrow={false}
                  showSearch
                >
                  {skillsWorkHistory
                    ?.sort((a, b) =>
                      getSortListData(a, b, "ascending", "SkillName")
                    )
                    ?.map((skill) => {
                      return (
                        <Option key={skill.SkillName}>{skill.SkillName}</Option>
                      );
                    })}
                </Select>
              </Form.Item>
              <Form.Item>
                <Form.Item
                  name="percentInit"
                  rules={[
                    {
                      required: true,
                      message: () => (
                        <div className="error-msg-workhistory">
                          Add the skill weightage between 0 to 100
                        </div>
                      ),
                    },
                  ]}
                  noStyle
                >
                  <Input
                    className="inputPercentWorkHistory"
                    placeholder="Percent"
                    value={percentValueInit}
                    onChange={(e) => {
                      const val = e.target.value;
                      if (val >= 0 && !val.startsWith(0))
                        setPercentValueInit(val);
                    }}
                  />
                </Form.Item>
                <div
                  style={{ marginLeft: "10px" }}
                  className={`skill-add-btn ${
                    skillValue && percentValueInit && totalPercent < 100
                      ? "add-title"
                      : "add-title click-disable"
                  }`}
                  onClick={() => {
                    if (
                      totalPercent + parseInt(percentValueInit) > 100 ||
                      skillValue === null ||
                      percentValueInit === null
                    )
                      return setError(true);
                    handleSkillAdd(skillValue, percentValueInit);
                    setError(false);
                  }}
                >
                  Add
                </div>
              </Form.Item>
            </Form.Item>
          )}
          <div className={`noteTotal ${error ? "error-red" : null}`}>
            Note : Total skill percent should be 100.
          </div>
        </Form.Item>
        <Form.Item
          rules={[
            {
              required: true,
              message: () => (
                <div className="error-msg-workhistory">
                  Please add responsibilities
                </div>
              ),
            },
          ]}
          name="responsibilities"
        >
          <Input.TextArea
            placeholder="Enter your responsibilities you've used at this job."
            rules={[
              {
                required: true,
                message: () => (
                  <div className="error-msg-workhistory">
                    Please add responsibilities
                  </div>
                ),
              },
            ]}
            className="textArea-workHistory"
            maxLength={600}
            onChange={(e) => setResponsibilitiesState(e.target.value)}
          />
        </Form.Item>
        <Form.Item style={{ display: "none" }}>
          <Button ref={formSubmitRef} htmlType="submit">
            Submit
          </Button>
        </Form.Item>
      </Form>
    </>
  );
};

export default WorkHistoryForm;
