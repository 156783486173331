import React, { useState, useEffect } from "react";
import "./BidDetail.css";

import moment from "moment";

import { Badge, Drawer } from "antd";

import { useHistory } from "react-router-dom";

import { useDispatch, useSelector } from "react-redux";
import { useUpdateLogger } from "../../../shared/useUpdateLogger";

import { Rate } from "antd";
import { FaRegHospital } from "react-icons/fa";
import { FiCheck } from "react-icons/fi";
import { RightOutlined } from "@ant-design/icons";

// import ChatWithRecruitor from "../../../assets/icons/ChatWithRecruitor.svg";
import ChatWithRecruitor from "../../../assets/icons/ChatWithRecruitor11.svg";
import ChatRecruitorWhite from "../../../assets/icons/chatRecruitorWhite.svg";

import { getImageByStateCode, getText } from "../../../shared/helpers";
import StageBar from "../../../components/StageBar/StageBar";
import FavoriteFunction from "../FavoriteFunction/FavoriteFunction";
import { alertNotification } from "../../../components/notifications/alertNotification";
import {
  postFavoriteGigCard,
  removeFavoriteGigCard,
} from "../../../redux/actions";

import defaultImage from "../../../assets/icons/defaultImage.svg";
import { contractOptions } from "../../Conversations/components/ChatCard/components/ReceivedBidsCard/ReceivedBidsCard";
import { DefaultsRating } from "../../WelcomeWizard/helper";
import { HyperlinkParagraph } from "../../../components/HyperlinkPara";

const BidDetail = (props) => {
  const { ProfessionCode } =
    useSelector((state) => state.profileReducer.user.profile) || "HC";
  const history = useHistory();
  const dispatch = useDispatch();
  const {
    StageAboutUI,
    ActionButtonMobile,
    ExtraSection,
    isReceivedBids,
    isPlacedBids,
    stageRanking,
    bidData,
    gigData,
  } = props;
  const [placeholder, showPlaceholder] = useState(false);
  const [Defaultplaceholder, setDefaultplaceholder] = useState(false);
  const [disableState, setDisableState] = useState(false);
  const [chatRecruitorButton, setChatRecruitorButton] = useState(false);
  const [pageType, setPageType] = useState(null);
  const [bidType, setBidType] = useState(null);
  const [seeMore, setseeMore] = useState(false);

  const [dimensions, setDimensions] = useState(window.innerWidth);
  var Space = " ";
  const Message = isReceivedBids
    ? getText(bidData?.Comments)
    : bidData?.Message;
  // const [visible, setVisible] = useState(false);
  // const showDrawer = () => {
  //   setVisible(true);
  // };
  // const onClose = () => {
  //   setVisible(false);
  // };
  var cache = {},
    character,
    e = document.createElement("div");

  const msgtxt = (html) => {
    return html?.replace(/([&][^&; ]+[;])/g, function (entity) {
      character = cache[entity];
      if (!character) {
        e.innerHTML = entity;
        if (e.childNodes[0])
          character = cache[entity] = e.childNodes[0].nodeValue;
        else character = "";
      }
      return character;
    });
  };
  const checkDimensions = () => {
    setDimensions(window.innerWidth);
  };

  useEffect(() => {
    window.addEventListener("resize", checkDimensions);
  }, []);

  useEffect(() => {
    setDefaultplaceholder(false);
  }, [gigData?.Regions?.Code]);

  const user = useSelector((state) => state.profileReducer.user);

  const detailsData = [
    `Guaranteed hours and OT available`,
    `Fast submission`,
    `Guranteed 2 weeks COVID pay (if you get sick while on
    assignment with us)`,
    `Basic life insurance provided by Skillgiggs`,
  ];

  const documentsData = [
    `Updated Resume`,
    `BLS`,
    `2 References`,
    `Skills Checklist`,
  ];

  useEffect(() => {
    if (isReceivedBids) {
      setBidType("Bid");
      setPageType("Received bids");
    }

    if (isPlacedBids) {
      setBidType("Proposal");
      setPageType("Placed bids");
    }
  }, [isReceivedBids, isPlacedBids]);

  useUpdateLogger("bidDataTax", bidData);

  const addFavorite = () => {
    alertNotification("success", "bid added to favorites");
    dispatch(
      postFavoriteGigCard(bidData?.ProposalId ?? bidData?.BidId, bidType)
    );
  };

  const removeFavorite = () => {
    dispatch(removeFavoriteGigCard(bidData?.ProposalId ?? bidData?.BidId));
    alertNotification("success", "bid removed from favorites");
  };

  const detailTitleView = (
    <>
      <div
        className="job-title"
        onClick={() => {
          history.push(`/details/${gigData?.JobOrderId}`);
        }}
      >
        {gigData?.Title}
      </div>
      {ProfessionCode === "IT" ? (
        <div className="Workplace-Policy">{gigData?.WorkplacePolicy}</div>
      ) : (
        <div className="g-details-facility-container start-Rating-talent-stage">
          <div
            className={
              stageRanking?.BidStatusName === "Canceled" ||
              stageRanking?.BidStatusName === "Terminated" ||
              stageRanking?.BidStatusName === "Retracted"
                ? "content-container-bid-disabled-rating g-details-facility-left"
                : "g-details-facility-left"
            }
          >
            <div className="g-details-facility-name">
              <div className="g-hospitadl-icon">
                <FaRegHospital className="g-hospital-icon" />
              </div>
              <div>{gigData?.FacilityName ? gigData?.FacilityName : "N/A"}</div>
            </div>
            <div className="right">
              <div className="g-star-rating">
                <Rate
                  allowHalf
                  defaultValue={
                    gigData?.facilityRating?.rating
                      ? Number(gigData?.facilityRating?.rating)
                      : DefaultsRating
                  }
                  // defaultValue={4}
                  disabled={true}
                />
              </div>
              <div className="rating-text-color">
                {/* 4 */}
                {gigData?.facilityRating?.rating
                  ? Number(gigData?.facilityRating?.rating)
                    : DefaultsRating}
              </div>
              <div className="Review-Rating-bid-detail">
                <div>
                  {gigData?.facilityRating?.reviews &&
                    "(" + gigData?.facilityRating?.reviews + ")"}
                </div>
                {/* <div>{moment({ time }).fromNow()}</div> */}
              </div>
            </div>
          </div>
        </div>
      )}
    </>
  );

  const contentDetailPage = (
    <div className="bid-details-text">
      <div className="text-section">
        <p className="bid-text-title">Details</p>
        {detailsData.map((data) => (
          <div className="subText-icon-bid">
            <span>
              <FiCheck className="check-icon-text" />
            </span>
            <span className="sub-text-bid">{data}</span>
          </div>
        ))}
      </div>
      <div>
        <p className="bid-text-title">Documents</p>
        {documentsData.map((data) => (
          <div className="subText-icon-bid">
            <span>
              <FiCheck className="check-icon-text" />
            </span>
            <span className="sub-text-bid">{data}</span>
          </div>
        ))}
        <div className="bid-text-paragraph">
          By being submitted you will be able to reserve a spot for the hiring
          manager to review your qualifications and make an offer to you - this
          is an incentive rate position and will go fast!
        </div>
      </div>
      <div>
        <p className="bid-text-title">Bonus</p>
        {/* {bonusData.map((data) => ( */}
        <div className="subText-icon-bonus-bid">
          <span>
            <FiCheck className="check-icon-text" />
          </span>
          <span className="sub-text-bid">
            <span className="primaryColor-green">$500 </span>Referral Bonus
            Program Available -- refer another RN and you get paid if they work.
          </span>
        </div>
        <div className="subText-icon-bonus-bid">
          <span>
            <FiCheck className="check-icon-text" />
          </span>
          <span className="sub-text-bid">
            <span className="primaryColor-green">$150 </span>Bonus - Provide
            submission Documents within 2 hours
          </span>
        </div>
      </div>
    </div>
  );
  const bd = bidData?.budgetTypes?.BudgetTypeName
    ? bidData?.budgetTypes?.BudgetTypeName
    : bidData?.BudgetType?.BudgetTypeName;
  const getBidRateType = (type) =>
    type === "Week"
      ? "WK"
      : type === "Hour"
      ? "HRS"
      : type === "Year"
      ? "YR"
      : type;

  // useUpdateLogger("gigDataTa:", gigData);
  // useUpdateLogger("gigDataTa:bid", bidData);

  return (
    <div className="bid-detail-container">
      <section className="header-container">
        <div className="bid-details-header">
          <div
            onClick={() => {
              if (props.isPlacedBids) {
                history.push("/placebids");
              }
              if (props.isReceivedBids) {
                history.push("/receivedbids");
              }
            }}
            className="bid-header-text"
          >
            {pageType}
          </div>
          <div className="g-details-arrow">
            <RightOutlined className="arrow" />
          </div>
          <div
            // onClick={Healthcare}
            className="bid-header-text-grey"
          >
            {gigData?.Title?.length > 23 && dimensions < 720
              ? gigData?.Title?.slice(0, 23) + "..."
              : gigData?.Title}
          </div>
          <div className="g-detail-jb-title"></div>
        </div>
        {dimensions > 768 && detailTitleView}
        <div
          className={
            stageRanking?.BidStatusName === "Canceled" ||
            stageRanking?.BidStatusName === "Terminated" ||
            stageRanking?.BidStatusName === "Retracted"
              ? "content-container-bid-disabled"
              : "content-container-bid"
          }
        >
          {isReceivedBids && (
            <div className="skillListing-title-bid">
              {bidData?.skill_listings?.Title
                ? bidData?.skill_listings?.Title
                : "N/A"}
            </div>
          )}
          <div
            className="ractangle-left"
            // style={{ background: `url(${cardImage})` }}
          >
            <div className="bid-detail-inner-container">
              <div className="bid-details">
                <div>
                  {bidType == "Bid"
                    ? bidData?.ActualRate?.$numberDecimal
                      ? bidData?.ActualRate?.$numberDecimal
                      : bidData?.ActualRate
                      ? bidData?.ActualRate.toFixed(2)
                      : bidData?.Rate?.$numberDecimal
                      ? bidData?.Rate?.$numberDecimal
                      : bidData.Rate?.toLocaleString("en-US")
                      ? bidData.Rate?.toLocaleString("en-US")
                      : 0
                    : bidData?.Rate?.$numberDecimal
                    ? bidData?.Rate?.$numberDecimal
                    : bidData.Rate?.toLocaleString("en-US")
                    ? bidData.Rate?.toLocaleString("en-US")
                    : 0}
                  / {getBidRateType(bd)}
                </div>
                {/* <div>${bidData?.Rate ? Number(bidData?.Rate) : 0}/wk</div> */}
                {/* <div>4000/wk</div> */}
                <div>
                  {/* {ProfessionCode === "HC"
                    ? "Pay Package"
                    : "Estimated pay package"} */}
                  {ProfessionCode === "HC"
                    ? props.isPlacedBids
                      ? "Desired pay"
                      : "Pay package"
                    : props.isPlacedBids
                    ? "Desired pay"
                    : "Estimated pay package"}
                </div>
              </div>
              <div className="location-details">
                {/* <div>Las Vegas</div> */}
                <div>
                  {gigData?.Cities?.CityName}, {gigData?.Regions?.Code}
                </div>
              </div>
            </div>
            <div className="add-favorite-bid">
              <FavoriteFunction
                isFavorite={bidData?.IsFavorite}
                addFavorite={addFavorite}
                removeFavorite={removeFavorite}
              />
            </div>
            {placeholder ? (
              <img
                src={
                  Defaultplaceholder
                    ? getImageByStateCode()
                    : getImageByStateCode(gigData?.Regions?.Code)
                  // gig?.Location?.RegionCode
                }
                className="city-image-bd"
                onError={(e) => setDefaultplaceholder(true)}
              />
            ) : (
              <img
                src={
                  ProfessionCode === "IT" &&
                  gigData?.WorkplacePolicy === "Fully Remote"
                    ? defaultImage
                    : getImageByStateCode(
                        gigData?.Regions?.Code,
                        gigData?.Cities?.CityName
                      )
                  // gig?.Location?.CityName
                }
                className="city-image-bd"
                onError={(e) => showPlaceholder(true)}
              />
            )}
          </div>
          {dimensions < 768 && (
            <div className="detail-title-mobile-view">{detailTitleView}</div>
          )}
          {/* {dimensions > 768 && isPlacedBids ? contentDetailPage : null} */}
        </div>
      </section>
      <section
        className={
          stageRanking?.ranking?.BidStatusName === "Retracted"
            ? "bid-about-job-container-disabled"
            : "bid-about-job-container"
        }
      >
        {dimensions > 768 && (
          <div className="bid-status-bar">
            {isReceivedBids ? (
              <StageBar
                length={16}
                stage={
                  isReceivedBids && stageRanking?.BidStatusName === "New"
                    ? stageRanking?.Rank
                    : stageRanking?.BidStatusName === "Interview Cancelled"
                    ? 7
                    : stageRanking?.Rank - 1
                }
                status={
                  stageRanking?.BidStatusName === "Interview Cancelled"
                    ? "Interview Declined"
                    : stageRanking?.BidStatusName
                }
              />
            ) : (
              <StageBar
                length={17}
                stage={
                  isPlacedBids &&
                  stageRanking?.BidStatusName === "Interview Cancelled"
                    ? 8
                    : stageRanking?.Rank
                }
                status={
                  stageRanking?.BidStatusName === "Interview Cancelled"
                    ? "Interview Declined"
                    : stageRanking?.BidStatusName
                }
              />
            )}
          </div>
        )}
        {dimensions < 768 && <div className="bid-divider-detail"></div>}
        <div
          className={
            stageRanking?.BidStatusName === "Hired" ||
            stageRanking?.BidStatusName === "On Billing" ||
            stageRanking?.BidStatusName === "Off Billing" ||
            stageRanking?.BidStatusName === "Billing Extend" ||
            stageRanking?.BidStatusName === "Canceled" ||
            stageRanking?.BidStatusName === "Terminated"
              ? "bid-about-job bid-about-job-margin"
              : "bid-about-job"
          }
        >
          <section className="bid-about-job-section">
            <div className="title-about-job">About the bid</div>
            {/* <div className="para-about-job">{gigData?.Description}</div> */}
            <div className="details-about-job">
              <span className="details-about-job-left">
                {/* {ProfessionCode === "HC"
                  ? "Pay Package"
                  : "Estimated pay package"} */}
                {ProfessionCode === "HC"
                  ? props.isPlacedBids
                    ? "Desired Pay"
                    : "Pay package"
                  : props.isPlacedBids
                  ? "Desired Pay"
                  : "Estimated pay package"}
              </span>
              <span className="details-about-job-right-green">
                $
                {bidType == "Bid"
                  ? bidData?.ActualRate?.$numberDecimal
                    ? bidData?.ActualRate?.$numberDecimal
                    : bidData?.ActualRate?.toLocaleString("en-US")
                    ? bidData?.ActualRate?.toLocaleString("en-US")
                    : 0
                  : bidData?.Rate?.$numberDecimal
                  ? bidData?.Rate?.$numberDecimal
                  : bidData?.Rate?.toLocaleString("en-US")
                  ? bidData.Rate?.toLocaleString("en-US")
                  : 0}
                / {getBidRateType(bd)}
                {/* ${bidData?.Rate ? bidData?.Rate : 0}/WK */}
              </span>
            </div>
            <div className="details-about-job">
              {isPlacedBids && (
                <>
                  <span className="details-about-job-left">Location</span>
                  <span className="details-about-job-right">
                    {gigData?.Regions?.Code || gigData?.Cities?.CityName
                      ? `${gigData?.Cities?.CityName}, ${
                          gigData?.Regions?.Code && gigData?.Regions?.Code
                        }`
                      : "N/A"}
                  </span>
                </>
              )}
              {isReceivedBids && (
                <>
                  <span className="details-about-job-left">
                    Ideal start date
                  </span>
                  <span className="details-about-job-right">
                    {bidData?.IdealStartDate
                      ? moment(bidData?.IdealStartDate).format("MMM DD, YYYY")
                      : "N/A"}
                  </span>
                </>
              )}
            </div>
            <div className="details-about-job">
              {isPlacedBids && (
                <>
                  <span className="details-about-job-left">Tax term</span>
                  <span className="details-about-job-right">
                    {bidData?.IdealTermsName ? bidData?.IdealTermsName : "N/A"}
                  </span>
                </>
              )}
              {isReceivedBids && bidData?.IdealTerm != null && (
                <>
                  <span className="details-about-job-left">
                    Contract length
                  </span>
                  <span className="details-about-job-right">
                    {/* {bidData?.IdealContractLength?.IdealContractLengthName
                      ? bidData?.IdealContractLength?.IdealContractLengthName
                      : "N/A"} */}
                    {bidData?.IdealTerm
                      ? contractOptions?.find((o) => {
                          return (
                            o?.IdealContractLengthId === bidData?.IdealTerm
                          );
                        })?.IdealContractLengthName
                      : bidData?.IdealTerm
                      ? contractOptions?.find((o) => {
                          return (
                            o?.IdealContractLengthId === bidData?.IdealTerm
                          );
                        })?.IdealContractLengthName
                      : null}
                  </span>
                </>
              )}
            </div>
            {/* <div className="details-about-job">
              <span className="details-about-job-left">Job Rate</span>
              <span className="details-about-job-right">
                ${gigData?.BudgetMin ? Number(gigData?.BudgetMin) * 40 : 0}/wk
              </span>
            </div> */}
          </section>
          <div className="bid-cover-detailPage">
            <div className="bid-cover-letter">Cover letter</div>
            {/* <p className="bid-cover-message">
              {Message?.slice(0, seeMore)}
              {Message?.length > 400 && seeMore === 400 ? (
                <span
                  className="seeMoreButton"
                  onClick={() => {
                    setseeMore();
                  }}
                >
                  {" "}
                  {Space} See More...
                </span>
              ) : null}
            </p> */}
            <div
              className={
                Message?.length < 400
                  ? "CoverLetterHTML_seeless"
                  : seeMore
                  ? "CoverLetterHTML_seeless"
                  : "CoverLetterHTML_seemore"
              }
            >
              <HyperlinkParagraph
                text={isReceivedBids ? bidData?.Comments : bidData?.Message}
              />
            </div>
            {/* <div
            className={Message?.length < 400? 'CoverLetterHTML_seeless': seeMore?"CoverLetterHTML_seeless":'CoverLetterHTML_seemore'}
            // className={"CoverLetterHTML_seeless"}
            dangerouslySetInnerHTML={
              isReceivedBids
                ? { __html: msgtxt(bidData?.Comments) }
                : {
                    __html: msgtxt(bidData?.Message)
                  }
            }
            ></div> */}
            {Message?.length > 400 && (
              <span
                onClick={() => {
                  setseeMore(!seeMore);
                }}
                className="seeMoreButton"
              >
                {!seeMore ? "See more" : "See less"}
              </span>
            )}
          </div>
          {/* {dimensions < 768 && (
            <div className="bid-full-description" onClick={showDrawer}>
              Show full job description
            </div>
          )} */}
          {/* <Drawer
            title="Job Detail"
            placement="bottom"
            height="100%"
            onClose={onClose}
            visible={visible}
            className="drawer-bid-detail"
          >
            {contentDetailPage}
            <div className="Happy-Skillgigsing">Happy Skillgigsing :)</div>
          </Drawer> */}
          {/* {dimensions > 768 && StageAboutUI} */}
          {dimensions > 768 && StageAboutUI}
          {dimensions < 768 && ActionButtonMobile}
          {dimensions < 768 && stageRanking?.BidStatusName === "Hired" ? (
            <div className="bid-chat-with-recruiter-mobile">
              <div
                className="chat-with-recruiter-button-green clickable"
                // onClick={() => history.push(`/messages`)}
                onClick={() =>
                  history.push({
                    pathname: `/messages`,
                    // pathname: `/messages/${bidData?.message?.ConversationId}`,
                    state: {
                      openConvo: true,
                      conversationId: bidData?.message?.ConversationId,
                    },
                  })
                }
              >
                <span>Chat with the employer</span>
                <span>
                  <Badge dot={true}>
                    <img src={ChatRecruitorWhite} alt="chat-icon" />
                  </Badge>
                </span>
              </div>
            </div>
          ) : null}
        </div>
        <div
          className={
            stageRanking?.BidStatusName === "Offer Accepted"
              ? "talent-stages-Offer-Accepted"
              : ""
          }
        >
          {ExtraSection}
          {dimensions > 768 &&
            stageRanking?.BidStatusName !== "On Billing" &&
            stageRanking?.BidStatusName !== "Off Billing" &&
            stageRanking?.BidStatusName !== "Billing Extend" &&
            stageRanking?.BidStatusName !== "Canceled" &&
            stageRanking?.BidStatusName !== "Terminated" && (
              <div className="bid-chat-with-recruiter">
                <div
                  className={
                    // !StageAboutUI?.props.disableState
                    //   ? "chat-with-recruiter-button-white clickable"
                    //   : "chat-with-recruiter-button-green clickable"
                    "chat-with-recruiter-button-white clickable"
                  }
                  onClick={() =>
                    history.push({
                      pathname: `/messages`,
                      // pathname: `/messages/${bidData?.message?.ConversationId}`,
                      state: {
                        openConvo: true,
                        conversationId: bidData?.message?.ConversationId,
                      },
                    })
                  }
                >
                  <span>
                    <img
                      src={
                        // !StageAboutUI?.props.disableState
                        //   ? ChatWithRecruitor
                        //   : ChatRecruitorWhite
                        ChatWithRecruitor
                      }
                      alt="chat-icon"
                    />
                  </span>
                  <span>Let's talk</span>
                </div>
                <div >
                  <p className="bottom-main-text">Want to discuss this opportunity further?</p>
                  <p className="bottom-secondary-text">Chat with the employer directly.</p>
                </div>
              </div>
            )}
        </div>
      </section>
    </div>
  );
};

export default BidDetail;