import { put, call, takeEvery } from "@redux-saga/core/effects";
import {fetchResumeGetviewsAPI,fetchResumeDetailAPI, fetchResumeGetfollowersAPI, ViewResumeAPI } from "../../API/API";
import { ProfileAPIResponseStructure } from "../../assets/helpers";
import { types } from "../action-types";
import {
  fetchResumeGetfollowersSuccess,
  fetchResumeGetfollowersFailure,
  fetchResumeGetviewSuccess,
  fetchResumeGetviewFailure,
  fetchResumeDetaiSuccess,
  fetchResumeDetaiFailure,
  catchError,
  talentProfileApidata,
  fetchResumeViewSuccess, fetchResumeViewFailure} from "../actions";

// GET FOLLOWERS RESUME SAGA START //
function* fetchResumeGetfollowersSaga(action) {
  const { page, perPage } = action.payload;
  // 
  try {
    // 
    const response = yield call(() =>
      fetchResumeGetfollowersAPI(page, perPage)
    );
    // 
    if (response.status == 200) {
      yield put(fetchResumeGetfollowersSuccess(response.data.data.body.data));
    } else {
      yield put(fetchResumeGetfollowersFailure(response));
    }
  } catch (error) {
    yield put(fetchResumeGetfollowersFailure(error));
  }
}

export function* watchResumeGetfollowersSaga() {
  yield takeEvery(
    types.FETCH_RESUME_GET_FOLLOWERS_INIT,
    fetchResumeGetfollowersSaga
  );
}

// GET FOLLOWERS RESUME SAGA END //
// GET VIEW RESUME SAGA START //

function* fetchResumeGetviewSaga(action) {
  const { page, perPage } = action.payload;
  // 
  try {
    // 
    const response = yield call(() => fetchResumeGetviewsAPI(page, perPage));
    // 
    if (response.status === 200) {
      yield put(fetchResumeGetviewSuccess(response.data.data.body.data));
    } else {
      yield put(fetchResumeGetviewFailure(response));
    }
  } catch (error) {
    yield put(fetchResumeGetviewFailure(error));
  }
}

export function* watchResumeGetviewsSaga() {
  yield takeEvery(types.FETCH_RESUME_GET_VIEWS_INIT, fetchResumeGetviewSaga);
}

// GET FOLLOWERS SAGA END //

// GET DETAIL RESUME SAGA START //

// this saga can be reduced as it is calling detail API for skill Listing count and resume
// developer could've used the already existing detail API call used in profile saga
function* fetchResumeDetailSaga() {
  // 
  try {
    // 
    const response = yield call(() => fetchResumeDetailAPI());
    // 
    if (response.status == 200) {
      yield put(talentProfileApidata(response.data.data.body.user));

      // 
      yield put(
        fetchResumeDetaiSuccess(
          ProfileAPIResponseStructure(response.data.data.body.user, {
            EducationHistory: "EducationHistory",
            JobCompletion: "JobCompletion",
          })?.data?.body
        )
      );
    } else {
      yield put(fetchResumeDetaiFailure(response));
    }
  } catch (error) {
    yield put(fetchResumeDetaiFailure(error));
    yield put(catchError(error));
  }
}

export function* watchResumeDetailSaga() {
  yield takeEvery(types.FETCH_RESUME_DETAIL_INIT, fetchResumeDetailSaga);
}
// GET DETAIL RESUME SAGA END //

function* fetchResumeViewSaga(props) {
    try {
        const response = yield call(() => ViewResumeAPI(props?.payload));
        
        if (response.status == 200) {
            yield put(fetchResumeViewSuccess(response))
        } else {
            yield put(fetchResumeViewFailure(response))
        }

    } catch (error) {
        yield put(fetchResumeViewFailure(error))
        yield put(catchError(error));
    }
}

export function* watchResumeViewSaga() {
    yield takeEvery(types.VIEW_RESUME_PAGES_INIT, fetchResumeViewSaga)
}
