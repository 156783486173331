import React, { useEffect, useState } from "react";
import {
  BrowserRouter as Router,
  Route,
  Redirect,
  Switch,
  useHistory,
  useLocation,
} from "react-router-dom";
import Auth from "../pages/Auth/Auth";
import Home from "../pages/Home/Home";
import TalentEmployer from "../pages/WelcomeWizard/TalentEmployer";
import Profession from "../pages/WelcomeWizard/Profession";
import RoleExperience from "../pages/WelcomeWizard/RoleExperience";
import SuccessPage from "../pages/WelcomeWizard/SuccessPage";
import ResumeUpload from "../pages/WelcomeWizard/ResumeUpload";

import Credentialing from "../pages/Credentialing/Credentialing2";
import AuthSuccess from "../pages/Auth/components/AuthSuccess";
import DashBoard from "../pages/DashBoard/DashBoard";
import ForgetPassword from "../pages/Auth/ForgetPassword";
import LinkedInCheck from "../pages/Auth/components/LinkedInCheck";
import SearchResults from "../pages/SearchGigs/SearchResults";
import TermsOfServices from "../pages/Auth/components/TermsOfServices";

import ReceivedBidDetailPage from "../pages/Bid/ReceivedBids/ReceivedBidDetailPage";
import PlacedBidsDetailPage from "../pages/Bid/PlaceBids/PlaceBidsDetailPage";
import Proposals from "../pages/Proposals/Proposals";
import EditBid from "../pages/Bid/components/EditBid";
import BidtoApply from "../pages/Bid/components/BidToApply";
import Messages from "../pages/Messages/Messages";
import Success from "../pages/Bid/components/successBid";
import Chat from "../pages/Chat/Chat";
import ChatHistory from "../pages/Chat/components/ChatHistory";
import ReceivedBids from "../pages/Bid/ReceivedBids/ReceviedBids2";
import ManageSkillListing from "../pages/Manage_SkillListing/ManageSkillListingOne";
import SkillListng from "../pages/SkillListing/SkillListingPage";
import DeclineBids from "../pages/Bid/DeclineBids";
import GuestGidDetailProvider from "../pages/SearchGigs/GuestUser/components/GuestGidDetailProvider";
import TimeSheet from "../pages/TimeSheet/TimeSheet";
import TimeCorrection from "../pages/TimeSheet/TimeCorrection/TimeCorrection";

import Header from "../components/Header/Header";
import NewCreateSkillListing from "../pages/SignUp/NewCreateSkillListing/CreateSkillListing";
import history from "../config/history";
import SearchGigs from "../pages/SearchGigs/SearchGigs";
import Profile from "../pages/Profile/Profile";
import LinkSocial from "../pages/Profile/components/LinkSocial";
import Loader from "../components/Loader/Loader";
import JobDetailsPageProvider from "../pages/DashBoard/components/JobDetailsPageProvider";
import DummyContent from "../pages/DashBoard/components/DummyContent";
import MyFavorites from "../pages/MyFavorites/MyFavorites";
import RetractedBid from "../pages/Bid/components/RetractedBid";
import { retractBid } from "../redux/actions";
import PlaceBids from "../pages/Bid/PlaceBids/PlaceBids";
import Interview from "../pages/Interview/Interview";
import _3D_Resume from "../pages/_3D_Resume/_3D_Resume";
import HomeSearchResults from "../pages/Home/HomeSearchResults";
import HomeSeeAllpage from "../pages/Home/HomeSeeAllpage";
import {
  LoginRoute,
  ProtectedRoute,
  PublicRoute,
  SpecialRoute,
  ValidateRoute,
} from "./routes";
import MessageDetail from "../pages/Messages/components/MessageDetailBid/MessageDetail";
import Footer from "../components/Footer";
import RoleExperienceNew from "../pages/WelcomeWizard/RoleExperienceNew";
import { RatingForm } from "../pages/Profile/components/RatingReviews/RatingForm";
import RatingformTwo from "../pages/Profile/components/RatingReviews/RatingformTwo";
import AllCard from "../pages/Profile/components/RatingReviews/Ratingcards/AllCard";
import ManageAssignment from "../pages/Manage_Assignments/ManageAssignment";
import Conversation from "../pages/Conversations/Conversations";
import ScrollToTop from "../components/ScrollToTop";
import NotificationPage from "../pages/NotificationScreen";
import EmailVerification from "../components/EmailVerification/EmailVerification";

import ViewListing from "../pages/ViewListing/ViewListing";
import SkillListingDetailPage from "../pages/Manage_SkillListing/SkillListingDetailPage/SkillListingDetailPage";
import CompanyProfile from "../pages/CompanyProfile/CompanyProfile";
import Error from "../pages/Error/Error";
import Dashboard from "../pages/NewDashboard/Dashboard";
import axios from "axios";
// import Dashboard from "../pages/DashBoard/DashBoard";
import ReadRules from "../pages/WelcomeWizard/ReadRules";
import SuccessProfileInfo from "../pages/WelcomeWizard/SuccessProfileInfo";
import EffectiveProfileInfo from "../pages/WelcomeWizard/EffectiveProfileInfo";
import NameScreen from "../pages/SignUp/NameScreen";
import LocationScreen from "../pages/SignUp/LocationScreen";
import DisciplineScreen from "../pages/SignUp/Discipline";
import SpecialityScreen from "../pages/SignUp/Speciality";
import MobileNumberScreen from "../pages/SignUp/MobileNumber";
import ExperienceLevelScreen from "../pages/SignUp/ExperienceLevel";
import WorkPlacePolicyScreen from "../pages/SignUp/WorkPlacePolicy";
import WorkAuthorization from "../pages/SignUp/WorkAuthorization";
import LicenseScreen from "../pages/SignUp/License";
import VeteranScreen from "../pages/SignUp/Veteran";
import ACLS_DocV2 from "../pages/SignUp/Components/DocUpload/ACLS_DocV2";
import BLSDocv2 from "../pages/SignUp/Components/DocUpload/BLSDocv2";
import Driver_license_DocV2 from "../pages/SignUp/Components/DocUpload/Driver_license_DocV2";
import Reference1_DocV2 from "../pages/SignUp/Components/DocUpload/Reference1_DocV2";
import Reference2_DocV2 from "../pages/SignUp/Components/DocUpload/Reference2_DocV2";
import Social_security_Doc from "../pages/SignUp/Components/DocUpload/Social_security_Doc";
import StateLisenceV2 from "../pages/SignUp/Components/DocUpload/StateLisenceV2";
import ResumeUploadv2 from "../pages/SignUp/Components/DocUpload/ResumeUploadv2";
import AnyCareerGap from "../pages/SignUp/Components/DocUpload/Signup_gap_screen/AnyCareerGap";
import EducationalScreenOne from "../pages/NewSignUP/EducationalScreenOne";
import EducationalScreenTwo from "../pages/NewSignUP/EducationalScreenTwo";
import EducationalScreenThree from "../pages/NewSignUP/EducationalScreenThree";
import SuccessPageSignUp from "../pages/NewSignUP/SuccessScreen";
import ViewCompanyJobs from "../pages/CompanyProfile/ViewCompanyJobs";
import ViewResume from "../pages/_3D_Resume/ViewResume/ViewResume";
import { ArraySignUpRoute } from "../shared/helpers";
import EmployerLandingPage from "../pages/EmployerLandingPage/EmployerLandingPage";

export const AppRouter = (props) => {
  const emailVerified = props.isEmailVerified;
  const { id } = props.userProfile;
  const { WizardFlow } = props.userProfile.profile;

  const [ipaddress, setIpaddress] = useState("");

  const getIpAddress = async () => {
    const responce = await axios.get("https://api.ipify.org?format=json");
    setIpaddress(responce?.data?.ip);
  };

  const locationUrl = useLocation();
  const currentUrl = locationUrl?.pathname;

  useEffect(() => {
    getIpAddress();
  }, []);

  const { location, width } = props;

  

  // // 

  return (
    // <Router  basename={'/app'}>
    <>
      <ScrollToTop />
      {/* <Suspense fallback={<Loader />}> */}
      <div className="content-wrap">
        <div
          className={`headerSpaceRouter ${ArraySignUpRoute?.includes(currentUrl) === true ? "headerSpaceRouterPadding" : null}`}
          // className={` ${
          //   !id || WizardFlow <= 5 || emailVerified
          //     ? "headerSpaceRouter"
          //     : "headerSpaceRouter-the-drop"
          // }`}
        >
          {/* {!emailVerified && id && WizardFlow > 5 && <EmailVerification />} */}
          <Header
            changeStage={props.shareStage}
            stage={props.stage}
            id={id}
            emailVerified={emailVerified}
            WizardFlow={WizardFlow}
            EmailVerification={EmailVerification}
          />
          <Switch>
            {/* <PublicRoute exact path="/">
            <Home />
          </PublicRoute> */}
            <ValidateRoute exact path="/">
              <Home />
            </ValidateRoute>
            <PublicRoute exact path="/auth">
              <AuthSuccess IPAddress={ipaddress} />
            </PublicRoute>

            <LoginRoute path="/login">
              <Auth />
            </LoginRoute>
            <LoginRoute path="/signup">
              <Auth />
            </LoginRoute>
            <PublicRoute exact path="/linkcheck">
              <LinkedInCheck />
            </PublicRoute>
            <PublicRoute exact path="/search/Pvt">
              <SearchGigs isPrivatePage={true} />
            </PublicRoute>
            <PublicRoute exact path="/Private">
              <SearchGigs />
            </PublicRoute>
            <PublicRoute exact path="/search/:searchValue">
              <SearchGigs />
            </PublicRoute>
            <PublicRoute exact path="/details/:jobid">
              <GuestGidDetailProvider />
            </PublicRoute>
            <PublicRoute path="/create-bid">
              <BidtoApply />
            </PublicRoute>
            <PublicRoute path="/error">
              <Error />
            </PublicRoute>
            <ProtectedRoute path="/receivedBids">
              <ReceivedBids
                emailVerified={emailVerified}
                id={id}
                WizardFlow={WizardFlow}
              />
            </ProtectedRoute>
            <ProtectedRoute
              path="/receivedbid-detail/:bidId"
              component={ReceivedBidDetailPage}
            />
            <ProtectedRoute path="/placebids">
              <PlaceBids
                emailVerified={emailVerified}
                id={id}
                WizardFlow={WizardFlow}
              />
            </ProtectedRoute>
            <ProtectedRoute
              path="/placedbid-detail/:bidId"
              component={PlacedBidsDetailPage}
            />
            <ProtectedRoute path="/name" component={NameScreen} />
            <ProtectedRoute path="/location" component={LocationScreen} />
            <ProtectedRoute path="/discipline" component={DisciplineScreen} />
            <ProtectedRoute path="/speciality" component={SpecialityScreen} />
            <ProtectedRoute path="/mobileno" component={MobileNumberScreen} />
            <ProtectedRoute
              path="/explever"
              component={ExperienceLevelScreen}
            />
            <ProtectedRoute
              path="/workPlacePolicy"
              component={WorkPlacePolicyScreen}
            />
            <ProtectedRoute
              path="/workAuthorization"
              component={WorkAuthorization}
            />
            <ProtectedRoute path="/license" component={LicenseScreen} />
            <ProtectedRoute path="/veteran" component={VeteranScreen} />
            <ProtectedRoute path="/talentemployer" component={TalentEmployer} />
            <ProtectedRoute path="/profession" component={Profession} />
            <ProtectedRoute path="/read-rules" component={ReadRules} />
            <ProtectedRoute
              path="/profile-instruction"
              component={SuccessProfileInfo}
            />
            <ProtectedRoute
              path="/effective-profile-info"
              component={EffectiveProfileInfo}
            />
            <ProtectedRoute exact path="/resume-upload">
              <ResumeUpload />
            </ProtectedRoute>
            <ProtectedRoute exact path="/resume-upload-v2">
              <ResumeUploadv2 />
            </ProtectedRoute>
            <ProtectedRoute exact path="/any-career-gap">
              <AnyCareerGap />
            </ProtectedRoute>
            <ProtectedRoute
              path="/state-lisence-doc"
              component={StateLisenceV2}
            />
            <ProtectedRoute path="/bls-doc" component={BLSDocv2} />
            <ProtectedRoute path="/acls-doc" component={ACLS_DocV2} />
            <ProtectedRoute
              path="/social-security-doc"
              component={Social_security_Doc}
            />
            <ProtectedRoute
              path="/driver-license-doc"
              component={Driver_license_DocV2}
            />
            <ProtectedRoute
              path="/reference1-doc"
              component={Reference1_DocV2}
            />
            <ProtectedRoute
              path="/reference2-doc"
              component={Reference2_DocV2}
            />
            <ProtectedRoute
              path="/profile-instruction"
              component={SuccessProfileInfo}
            />
            <ProtectedRoute
              path="/effective-profile-info"
              component={EffectiveProfileInfo}
            />
            <ProtectedRoute
              path="/basic-profile-success"
              component={SuccessPageSignUp}
            />
            <ProtectedRoute path="/linksocial" component={LinkSocial} />
            <ProtectedRoute
              path="/create-skilllisting"
              component={SkillListng}
            />
            <ProtectedRoute
              path="/create-skilllisting-signup"
              component={SkillListng}
            />
            <ProtectedRoute
              exact
              path="/role-experience"
              component={RoleExperienceNew}
            />
            <ProtectedRoute path="/credentialing" component={Credentialing} />
            <ProtectedRoute path="/manageskilllisting">
              <ManageSkillListing />
            </ProtectedRoute>
            <ProtectedRoute path="/skill-listing-detail-page/:skillId">
              <SkillListingDetailPage />
            </ProtectedRoute>
            <ProtectedRoute path="/view-listing/:id">
              <ViewListing />
            </ProtectedRoute>
            <ProtectedRoute path="/view-listing">
              <ViewListing />
            </ProtectedRoute>
            <ProtectedRoute exact path="/company-jobs/:id">
              <ViewCompanyJobs />
            </ProtectedRoute>
            <PublicRoute path="/company-profile/:id">
              <CompanyProfile />
            </PublicRoute>
            {/* <ProtectedRoute path="/company-profile/:id">
              <CompanyProfile />
            </ProtectedRoute> */}
            <ProtectedRoute path="/dummy">
              <DummyContent />
            </ProtectedRoute>
            <ProtectedRoute path="/myfavorites">
              <MyFavorites />
            </ProtectedRoute>
            <ProtectedRoute path="/dashboard">
              {/* <DashBoard
                shareStage={props.shareStage}
                emailVerified={emailVerified}
              /> */}
              <Dashboard />
            </ProtectedRoute>
            <ProtectedRoute path={"/proposals"}>
              <Proposals />
            </ProtectedRoute>
            <ProtectedRoute path="/bidsuccess">
              <Success />
            </ProtectedRoute>
            <ProtectedRoute path="/edit-bid/:id">
              <EditBid />
            </ProtectedRoute>
            <ProtectedRoute exact path="/messages/:id?">
              <Conversation />
            </ProtectedRoute>
            <ProtectedRoute exact path="/chats">
              <Conversation />
            </ProtectedRoute>
            <ProtectedRoute exact path="/notifications">
              <NotificationPage />
            </ProtectedRoute>

            {
              // commenting this out as it is a dublicate route for another component if it is not useable please remove.
              /* <ProtectedRoute path="/messages/:id">
              {window.innerWidth < 768 ? <MessageDetail /> : <Messages />}
            </ProtectedRoute> */
            }
            <ProtectedRoute
              path="/sign_educational_screen1"
              component={EducationalScreenOne}
            />
            <ProtectedRoute
              path="/sign-educational-screen2"
              component={EducationalScreenTwo}
            />
            <ProtectedRoute
              path="/sign_educational_screen3"
              component={EducationalScreenThree}
            />

            <ProtectedRoute path="/chat/history">
              <ChatHistory />
            </ProtectedRoute>
            <ProtectedRoute path="/DeclineBids">
              <DeclineBids />
            </ProtectedRoute>
            <ProtectedRoute exact path="/timesheet">
              <TimeSheet />
            </ProtectedRoute>
            <ProtectedRoute exact path="/profile">
              <Profile />
            </ProtectedRoute>
            <ProtectedRoute exact path="/requestcorrection">
              <TimeCorrection />
            </ProtectedRoute>
            <ProtectedRoute exact path="/resume-upload">
              <ResumeUpload />
            </ProtectedRoute>
            <ProtectedRoute path="/interview">
              <Interview />
            </ProtectedRoute>
            <ProtectedRoute path="/3d_resume">
              <_3D_Resume />
            </ProtectedRoute>
            <PublicRoute path="/resume/:id">
              <ViewResume />
            </PublicRoute>
            <ProtectedRoute path="/RetractedBids">
              <RetractedBid />
            </ProtectedRoute>
            <ProtectedRoute path="/HomeResult">
              <HomeSearchResults />
            </ProtectedRoute>
            <ProtectedRoute path="/homeSeeAll">
              <HomeSeeAllpage />
            </ProtectedRoute>
            <ProtectedRoute path="/manage_assignments">
              <ManageAssignment />
            </ProtectedRoute>
            <ProtectedRoute path="/rating_form">
              <RatingForm />
            </ProtectedRoute>
            <ProtectedRoute path="/rating_form2">
              <RatingformTwo />
            </ProtectedRoute>
            <ProtectedRoute path="/all_rate_card">
              <AllCard />
            </ProtectedRoute>
            <SpecialRoute path="/forgot">
              <ForgetPassword />
            </SpecialRoute>
            <ProtectedRoute
              path="/create-skilllisting-gig-type"
              component={NewCreateSkillListing}
            />
            <ProtectedRoute
              path="/create-skilllisting-title"
              component={NewCreateSkillListing}
            />
            <ProtectedRoute
              path="/create-skilllisting-location"
              component={NewCreateSkillListing}
            />
            <ProtectedRoute
              path="/create-skilllisting-skill"
              component={NewCreateSkillListing}
            />
            <ProtectedRoute
              path="/create-skilllisting-salary"
              component={NewCreateSkillListing}
            />
            <ProtectedRoute
              path="/create-skilllisting-date"
              component={NewCreateSkillListing}
            />
            <ProtectedRoute
              path="/user-signup-success"
              component={SuccessPageSignUp}
            />
            <PublicRoute path="/employer/SHI">
              <EmployerLandingPage />
            </PublicRoute>

            <SpecialRoute path="/terms">
              <TermsOfServices />
            </SpecialRoute>
            <Redirect to="/" />
            
          </Switch>
        </div>
      </div>
      {location.includes("messages") && width <= 768 ? null : (props.authToken && WizardFlow !== 1000) ? null: <Footer /> }
    </>
  );
};
