import { types } from "../action-types";

const initialState = {
    setJobsInfo:[],
    setPvtEmpList: [],
    loading: false,
};

const searchSuggestionReducer = (state = initialState, action) => {
    // // 
    switch (action.type) {
        case types.SEARCH_SUGGESTION_INPUT:
            state.setJobsInfo = action.payload
            // 
        return state
        case types.PRIVATE_COMPANY_LIST_INIT:
            return {
                ...state,
                loading: true,
            };
        case types.PRIVATE_COMPANY_LIST_SUCCESS:
            return {
                ...state,
                setPvtEmpList: action.payload,
                loading: false,
            };
        case types.PRIVATE_COMPANY_LIST_FAILURE:
            return {
                ...state,
                setPvtEmpList: action.error,
                loading: false,
            }
        default:
            return state;
    }
};

export default searchSuggestionReducer;
