import { call, put, takeEvery } from "redux-saga/effects";
import { types } from "../action-types";
import {
  fetchChatHistoryApi,
  downloadChatFilesApi,
  MessageAttachmentAPi,
  getInboxMessagesAPi,
  getMsgsCountApi,
} from "../../API/API";

function* getChatHistorySaga(action) {
  // try {
  //   const { id } = action.payload;
  //   const response = yield call(() => fetchChatHistoryApi(id));
  //   yield put({ type: types.FETCH_CHAT_SUCCESS, payload: response?.data });
  // } catch (error) {
  //   yield put({ type: types.FETCH_CHAT_FAILURE, error });
  // }
}

function* postAttachmentSaga(action) {
  // 
  try {
    const response = yield call(() => MessageAttachmentAPi(action.payload));
    yield put({
      type: types.SEND_MESSAGE_ATTACHMENT_SUCCESS,
      payload: response.data,
    });
  } catch (error) {
    yield put({ type: types.SEND_MESSAGE_ATTACHMENT_FAILURE, error });
  }
}
function* fetchInboxMessagesSaga(params) {
  try {
    // const {type, results, skip} = action.payload;
    const response = yield call(() => getInboxMessagesAPi(params.payload));
    yield put({
      type: types.GET_MESSAGES_INBOX_SUCCESS_HEADER,
      payload: {
        data: response.data,
        fetchMore: params?.payload?.fetchMore,
        fromSocket: params.payload.perPage == 1 ? true : false,
      },
    });
  } catch (error) {
    // 
    yield put({ type: types.GET_MESSAGES_INBOX_FAILURE_HEADER, error });
  }
}

function* getMsgsCountsSaga(action) {
  try {
    const response = yield call(() => getMsgsCountApi(action.payload));
    yield put({
      type: types.GET_MESSAGES_COUNTS_SUCCESS,
      payload: response.data,
    });
  } catch (error) {
    yield put({ type: types.GET_MESSAGES_COUNTS_FAILURE, error });
  }
}
// function* getChatFilesSaga(action){
//     // 
//     try{
//         const { FilePath } = action.payload;
//         const response = yield call(() => downloadChatFilesApi(FilePath));
//         yield put({ type: types.FETCH_CHAT_FILES_SUCCESS, payload: response.data });
//     }
//     catch(error){
//         yield put({type:types.FETCH_CHAT_FILES_FAILURE,error});
//     }
// }

export function* watchGetChatSagaHeader() {
  yield takeEvery(types.FETCH_CHAT_REQUESTED, getChatHistorySaga);
}
export function* watchPostAttachmentSagaHeader() {
  yield takeEvery(types.SEND_MESSAGE_ATTACHMENT_REQUESTED, postAttachmentSaga);
}

//inbox messages saga
export function* watchInboxMessagesSagaHeader() {
  yield takeEvery(types.GET_MESSAGES_INBOX_REQUESTED_HEADER, fetchInboxMessagesSaga);
}

export function* watchGetMsgCountsSagaHeader() {
  yield takeEvery(types.GET_MESSAGES_COUNTS_INIT, getMsgsCountsSaga);
}
// export function* watchGetChatFilesSaga(){
//     yield takeEvery(types.FETCH_CHAT_FILES_REQUESTED,getChatFilesSaga);
// }
