import React, { useEffect, useState } from "react";
import "./MessageInbox.css";
import { Badge, Rate, Tooltip } from "antd";
import { AiFillStar, AiOutlineStar } from "react-icons/ai";
import { useDispatch, useSelector } from "react-redux";
import {
  fetchDashboardInboxMessages,
  addMessageToFavourite,
  removeMessageFromFavourite,
  fetchAdminInit,
} from "../../../../redux/actions";
import { handlingfalsyValue } from "../../../Manage_SkillListing/SkillListingDetailPage/components/BidHistorySkillListing/bhsHandler";
import Loader from "../../../../components/Loader/Loader";
import { useHistory } from "react-router-dom";
import ProfileAvatar from "../ProfileAvatar/ProfileAvatar";
import {
  getCapitalisationFormat,
  msgtxt,
  getRelativeTime,
} from "../../../../shared/helpers";

import SkillGigslogo from '../../../../assets/img/Skillgigs_Msg_logo.png'

const MessageItem = ({
  index,
  message: {
    _id: conversationId,
    BidId,
    Created,
    IsFavorite,
    MessageText,
    SenderUserName,
    SenderUserPictureUrl,
    countUnRead,
    SourceTypeId,
    Facility,
    BidRate,
    IsOnline,
    Subject,
  },
  bidType,
}) => {
  const [Favorite, setFavorite] = useState(false);
  const dispatch = useDispatch();
  const history = useHistory();

  const addFavorite = () => {
    dispatch(
      addMessageToFavourite({
        SourceName: bidType,
        SourceId: BidId,
        Index: index,
      })
    );
  };
  const removeFavorite = () => {
    dispatch(
      removeMessageFromFavourite({
        SourceName: bidType,
        SourceId: BidId,
        Index: index,
      })
    );
  };
  const handleFavorite = (e) => {
    e.stopPropagation();
    e.preventDefault();
    if (Favorite) removeFavorite();
    else addFavorite();
    setFavorite(!Favorite);
  };
  const handleMessage = () =>
    history.push({
      pathname: `/messages`,
      state: {
        openConvo: true,
        conversationId,
      },
    });

  useEffect(() => {
    setFavorite(IsFavorite);
  }, []);
  const AdminSubject = <span className="AdminSubject-bold">
    {Subject}
  </span>
  return (
    <div className="messageItem" onClick={handleMessage}>
      <ProfileAvatar
        isOnline={IsOnline}
        name={SenderUserName}
        src={bidType == "Others" ? SkillGigslogo : `${process.env.REACT_APP_S3_URL}/${SenderUserPictureUrl}`}
        bidType={bidType}
      />
      <div className="leftMessageItem">
        <div className="leftMessageItemRow">
          <div className="leftMessageInfoFirst">
            <span>
              {bidType == "Others" ? "SkillGigs" : getCapitalisationFormat(handlingfalsyValue(SenderUserName))}
            </span>
            {bidType == "Others" ? <span className="Admin-dashboard-inbox-msg">ADMIN</span> : <Tooltip
              title={`@ ${getCapitalisationFormat(
                handlingfalsyValue(Facility)
              )}`}
            >
              <span>
                {" "}
                @ {getCapitalisationFormat(handlingfalsyValue(Facility))}
              </span>
            </Tooltip>}
            <span>. {handlingfalsyValue(getRelativeTime(Created))}</span>
          </div>
          <div className="messageTimeFav">
            <span onClick={handleFavorite}>
              <Rate
                character={
                  Favorite ? <AiFillStar color="#e39435" /> : <AiOutlineStar />
                }
                count={1}
                value={Favorite}
              />
            </span>
          </div>
        </div>
        <div className="leftMessageItemRow">
          <span className="messageBidRAte">
            {bidType == "Others" && Subject ? <div className="messageText"><span className="AdminSubject-bold">{Subject + " "}</span>{msgtxt(MessageText)}</div>  : <div
              className="messageText"
              dangerouslySetInnerHTML={{
                __html:
                  MessageText === "Bid is placed"
                    ? `You ${
                        SourceTypeId === 1 ? "received" : "placed"
                      } a new bid @ $${BidRate?.toLowerCase()}`
                    : msgtxt(MessageText),
              }}
            ></div>}
          </span>
          <Badge
            count={handlingfalsyValue(countUnRead)}
            className="messageCountBage"
            overflowCount={99}
          ></Badge>
        </div>
      </div>
    </div>
  );
};

const NoMessageReceivedBox = ({ bidType }) => {
  const noMsgText = {
    receivedBid: [
      "Start engaging with potential employers",
      "When an employer sends you a bid, it’s the first step to a meaningful conversation about your career trajectory. It’s an opportunity to connect and discuss potential opportunities.",
    ],
    plecedBid: [
      "Take control - Place your first bid!",
      "Bidding empowers you to take control of your job search. You get to decide which employers and gigs you want to engage with, giving you the freedom to shape your career path.",
    ],
  };
  return (
    <div className="messageItem messageInboxEmpty">
      <span>No messages</span>
      <span>
        {noMsgText[bidType === "Bid" ? "receivedBid" : "plecedBid"][0]}
      </span>
      <span>
        {noMsgText[bidType === "Bid" ? "receivedBid" : "plecedBid"][1]}
      </span>
    </div>
  );
};

const MessageList = ({ messages, isLoading, bidType }) => {
  if (messages?.length == 1 && bidType != "Others") {
    const tabSwitch = bidType === "Bid" ? "received_bids" : "placed_bids";
    messages = messages?.filter((message) => {
      const isSkillListing =
        message?.skillListing === null ? "placed_bids" : "received_bids";
      return tabSwitch === isSkillListing;
    });
  }
  return (
    <div className="messageInbox">
      {isLoading ? (
        <div className="messageItem">
          <Loader height={"0px"} size={"22px"} scrolling={false} />
        </div>
      ) : messages && messages?.length ? (
        messages
          ?.slice(0, 5)
          ?.map((message, index) => (
            <MessageItem
              key={index}
              message={message}
              bidType={bidType}
              index={index}
            />
          ))
      ) : (
        <NoMessageReceivedBox bidType={bidType} />
      )}
    </div>
  );
};

const MessageInbox = () => {
  const [tabSwitch, setTabSwitch] = useState("received_bids");
  const [messages, setMessages] = useState([]);
  const dispatch = useDispatch();
  const { dashboardMsgInboxLoading: inboxLoading } = useSelector(
    (state) => state.getChatHistoryReducer
  );
  const inboxConversations =
    useSelector((state) => state.getChatHistoryReducer?.dashboardMsgInbox) ||
    [];
  const onlineUsers = useSelector(
    (state) => state.getChatHistoryReducer?.onlineUsers
  );
  const userProfile = useSelector(
    (state) => state.profileReducer.user.profile
  );
  const AdminInboxConversations =
    useSelector((state) => state.getChatHistoryReducer?.AdminDashboardInbox?.data?.conversations) ||
    [];
  const AdmininboxLoading =
    useSelector((state) => state.getChatHistoryReducer?.AdminInboxLoading);
  useEffect(() => {
    setMessages(
      inboxConversations?.map((msgList) => ({
        ...msgList,
        IsOnline:
          onlineUsers?.length &&
          onlineUsers?.filter(
            (i) => Number(i?.userId) === msgList?.SenderUserId
          ).length > 0
            ? onlineUsers?.filter(
                (i) => Number(i?.userId) === msgList?.SenderUserId
              )?.[0]?.IsUserOnline
            : msgList.IsOnline,
      }))
    );
  }, [inboxConversations, onlineUsers]);
  const handleApiCall = (tabSwitch) => {
    dispatch(
      fetchDashboardInboxMessages(
        "all",
        // because we are using employer api
        tabSwitch === "received_bids" ? "placed_bids" : "received_bids",
        null,
        1,
        5,
        true
      )
    );
    if (tabSwitch == "Others"){
      dispatch(fetchAdminInit(userProfile?.AccountId))
    }
  };
  useEffect(() => {
    handleApiCall(tabSwitch);
  }, [tabSwitch]);
  return (
    <div className="messageInboxContainer">
      <div className="messageTab">
        <span
          className={`messageBtn ${
            tabSwitch === "received_bids" ? "messageBtnActive" : ""
          }`}
          onClick={() => setTabSwitch("received_bids")}
        >
          Received bids
        </span>
        <span
          className={`messageBtn ${
            tabSwitch === "placed_bids" ? "messageBtnActive" : ""
          }`}
          onClick={() => setTabSwitch("placed_bids")}
        >
          Placed bids
        </span>
        <span
          className={`messageBtn ${tabSwitch === "Others" ? "messageBtnActive" : ""
            }`}
          onClick={() => setTabSwitch("Others")}
        >
          Others
        </span>
      </div>
      <MessageList
        messages={tabSwitch === "Others" ? AdminInboxConversations : messages?.length ? messages : inboxConversations}
        isLoading={inboxLoading || AdmininboxLoading}
        bidType={tabSwitch === "received_bids" ? "Bid" : tabSwitch === "Others" ? "Others" : "Proposal"}
      />
    </div>
  );
};

export default MessageInbox;