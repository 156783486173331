import React, { useState, useEffect } from "react";
import { MdOutlineClose } from "react-icons/md";
import "./CredPDFviewer.css";
import Loader from "../../../components/Loader/Loader";

import { Document, Page, pdfjs } from "react-pdf/dist/esm/entry.webpack";
pdfjs.GlobalWorkerOptions.workerSrc = `//cdnjs.cloudflare.com/ajax/libs/pdf.js/${pdfjs.version}/pdf.worker.js`;

const CredPdfViewer = (props) => {
  const {
    docfileName,
    docFileSize,
    docFileType,
    setDocFileView,
    docFile,
    setDocFile,
    load,
    setLoading,
    styleImage,
    setStyleImage,
    skillListingDetailPage,
  } = props;

  const [numPages, setNumPages] = useState(1);

  function onDocumentLoadSuccess({ numPages }) {
    setNumPages(numPages);
  }

  const onDocLoad = () => {
    setLoading(false);
    setStyleImage({ display: "block" });
  };

  return (
    <section className="pdfViewerCredentialingContainer">
      <div className="pdfViewerCredentialing">
        <div className="docNameSizeClose">
          <span className="nameSize">
            <span className="name">{docfileName}</span>
            <span className="size">{`${docFileSize} ${docFileType}`}</span>
          </span>
          <span
            className="docClose clickable"
            onClick={() => {
              setDocFile(null);
              setDocFileView(false);
            }}
          >
            <MdOutlineClose size="1.13rem" color="#9e9e9e" />
          </span>
        </div>
        <div className="theDocWrap">
          {docFileType === "pdf" ? (
            <Document
              file={`${process.env.REACT_APP_S3_URL}${docFile?.charAt(0)!=='/' ? '/': ""}${docFile}`}
              loading={<Loader color={"#fff"} height="fit-content" />}
              onLoadSuccess={onDocumentLoadSuccess}
            >
              <div className="preview-card">
                {/* <Page  pageNumber={1} width={400} /> */}
                {Array.from(new Array(numPages), (el, index) => (
                  <Page key={`page_${index + 1}`} pageNumber={index + 1} />
                ))}
              </div>
            </Document>
          ) : (docFileType === "docx" || docFileType === "doc") ? (
            <>
              
              <iframe
              src={`https://view.officeapps.live.com/op/embed.aspx?src=${encodeURIComponent(`${process.env.REACT_APP_S3_URL}${docFile?.charAt(0)!=='/' ? '/': ""}${docFile}`)}`}
              title="Document Viewer"
              width="600"
              height="600"
              style={{
                pointerEvents: "auto"
              }}
            />
            </>
          ) :
          (
          <img
            src={`${process.env.REACT_APP_S3_URL}${docFile?.charAt(0)!=='/' ? '/': ""}${docFile}`}
            alt="document"
            style={styleImage}
            onLoad={onDocLoad}
          /> )
          }
        </div>
      </div>
    </section>
  );
};

export default CredPdfViewer;
