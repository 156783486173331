import React from "react";
import UploadDocV2 from "../../UploadDoc";

const BLSDocv2 = () => {
  const docInfo = [
    {
      "BLS - Basic life support - front": 2,
    },
    {
      "BLS - Basic life support - back": 81,
    },
  ];
  return (
    <div>
      <UploadDocV2
        docInfo={docInfo}
        backUrl="resume-upload-v2"
        nextUrl="acls-doc"
        currentPage={2}
        WizardFlow={113}
        IsMandatory={true}
        skipUrl={"acls-doc"}
      />
    </div>
  );
};

export default BLSDocv2;
