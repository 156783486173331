import React from "react";
import "./MyActivity.css";
import MessageInbox from "./MessageInbox";
import { useHistory } from "react-router-dom";
import { useSelector } from "react-redux";

const MyActivity = () => {
  const history = useHistory();
  const inboxCount = useSelector(
    (state) =>
      state.getChatHistoryReducer.inbox?.data?.data?.body?.conversations?.length
  );
  const messagePusher = () => {
    if (window.innerWidth < 1000) {
      history.push("/notifications");
    } else {
      history.push("/messages");
    }
  };
  
  return (
    <div className="myActivityContainer">
      <div className="myActivityHeader">
        <span className="myActivityTitle">Recent messages</span>
        {inboxCount ? (
          <span className="myActivityViewAllBtn" onClick={messagePusher}>
            Go to inbox
          </span>
        ) : (
          <></>
        )}
      </div>
      <MessageInbox />
    </div>
  );
};

export default MyActivity;
