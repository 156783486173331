import React, { useState, useEffect } from "react";
import {
  ApolloClient,
  InMemoryCache,
  ApolloProvider,
  useQuery,
  ApolloLink,
  gql,
} from "@apollo/client";
import { RestLink } from "apollo-link-rest";
import { HttpLink } from "apollo-link-http";
import { setContext } from "@apollo/client/link/context";
import SearchBar from "./SearchBar";
import SearchResult from "./SearchResults";
import { SearchGigsApi } from "../../API/API";
import { connect } from "react-redux";
import { fetchGigs } from "../../redux/actions";
import { useSelector } from "react-redux";
import "./SearchBar.css";
import SearchResults from "./SearchResults";
import GuestGigDetails from "./GuestUser/components/GuestGigDetails";

const SearchGigs = (props) => {
  const BackendApiBaseUrl = process.env.REACT_APP_API_URL;
  const authTokenValue = useSelector(
    (state) => state.authReducer.user.authToken
  );

  const ImpersonationId = useSelector(
    (state) => state.authReducer.impersonationId
  );

  // const GetGigs = props.user.Gigs;
  const [search, setSearch] = useState("");
  const [gigset, setGigSet] = useState();
  const restLink = new RestLink({
    endpoints: {
      openExchangeRate: "https://open.exchangerate-api.com/v6/",
    },
  });

  const httpLink = new HttpLink({
    uri: `${BackendApiBaseUrl}/query`,
  });
  const user = useSelector((state) => state.profileReducer.user);
  const authLink = setContext((_, { headers }) => {
    // get the authentication talentToken if it exists
    // return the headers to the context so httpLink can read them

    const res = {
      headers: {
        ...headers,
        authorization: authTokenValue ? `${authTokenValue}` : "",
        email: user.profile.EmailAddress,
      },
    };
    if (ImpersonationId) res.headers.impersonationId = ImpersonationId;
    // res.headers.impersonationId = ImpersonationId ? `${ImpersonationId}` : null;
    return res;
  });

  const client = new ApolloClient({
    // uri: `${BackendApiBaseUrl}/query`,
    cache: new InMemoryCache(),
    // link: ApolloLink.from([httpLink]),
    link: authLink.concat(httpLink),
  });

  // useEffect(() => {
  //   props.GetGigs();
  //   const GigsResponse = props.user.Gigs;
  //   // 
  //   // setState(data);
  // }, []);

  // useEffect(() => {
  //   const GigsResponse = props.user.Gigs;
  //   // // 
  //   setGigSet(GigsResponse);
  //   // setState(data);
  // }, [props.user.Gigs]);

  const query = gql`
    query {
      gigs {
        id
        JobOrderId
        ProfileId
        Title
        Description
        JobOrderStatusName
        JobOrderTypeName
        CityName
        RegionName
        RegionCode
        ZipCode
        CountryName
        CreatedBy
        Created
        Modified
        ModifiedBy
        IsActive
        BudgetType
        IdealContractLengthId
        IdealContractLengthName
        BudgetMin
        BudgetMax
        SuggestionsFrequency
        SuggestionsSent
        ExperienceLevelName
        InternalGigTypeCode
        ImportSource
        ExternalGigId
        ProfessionCode
        ImportProcessStatus
        FacilityName
        AutoLoaded
        SourceName
        SourceId
        Shift
        Skills
        Location {
          CityId
          CityName
          RegionId
          RegionCode
          LocationLong
          CountryId
          Location
          LocationWithCountry
          Latitude
          Longitude
          RegionName
          CountryName
        }
      }
    }
  `;

  // const { loading, error, data } = useQuery(query);
  const Query = () => {
    // 
    props.GetGigs(query);
    // const GetGigs = props.user.Gigs;
    // // 
    // if (loading) return "Loading...";
    // if (error) return `Error! ${error.message}`;
  };
  // // 
  return (
    <ApolloProvider client={client}>
      <SearchResults isPrivatePage={props?.isPrivatePage} />
    </ApolloProvider>
  );
};

export default SearchGigs;

// Link: link allows you to customize the flow of data from your graphQL queries
// and mutations to your backend and in-app state management.
// This can include: adding custom headers and routing to REST endpoints and graphQL endpoints.
