import React, { useState, useEffect, useCallback, useRef } from 'react';
import { useDispatch, useSelector } from "react-redux";
import { Button, Col, Typography, Input, Row, Menu, Dropdown, notification } from "antd";
import { CaretRightOutlined, PauseOutlined, RightCircleOutlined } from '@ant-design/icons';
import { BsStopBtnFill, BsFillRecordFill } from "react-icons/bs";
import './Timer.css'
import { createTimesheet, updateTimesheetItem, getExistingListItem, setTimerTimeSheet } from '../redux/actions'
import play from '../assets/img/play.png';
import pause from '../assets/img/pause.png';
import stop_logo from '../assets/img/carbondot.png';
import moment from 'moment';
import logo from '../assets/icons/sg.png';

const Timer = (props) => {

  const dispatch = useDispatch();
  const streamDuration = useRef(0);
  const previousTime = useRef(0);
  const requestAnimationFrameId = useRef(null);
  const getTimer = useSelector(state=>state.timeSheetListReducer.timer);
  const [renderedStreamDuration, setRenderedStreamDuration] =  useState("00:00:00");
  const [isStartTimer, setIsStartTimer] = useState(false);
  const [isStopTimer, setIsStopTimer] = useState(false);
  const [isPauseTimer, setIsPauseTimer] = useState(false);
  const [isResumeTimer, setIsResumeTimer] = useState(false);
  const userassignment = useSelector(state => state.timeSheetListReducer.userassignment)
  const userAssignments = useSelector(state=>state.UserAssignmentReducer.success);
  const [key, setKey] = useState(`open${Date.now()}`);
  const [spentTime, setSpentTime] = useState(0);
  
  var date = new Date().toLocaleDateString('en-GB', { day: 'numeric', month: 'short', year: 'numeric' });
  var diff = 0;
  // // 

  var time = new Date().toLocaleTimeString('en-US', {
    hour12: true,
    hour: "numeric",
    minute: "numeric"
  });

  // useEffect(() => {
  //   dispatch(getExistingListItem());
  // },[])

  useEffect(() => {

    if(userassignment[0] && userassignment[0].TimeLogs.length <= 1){
      diff += new Date().getTime() - new Date(userassignment[0].TimeLogs[0]).getTime();
      if(diff){
        setRenderedStreamDuration(moment.utc(diff).format("HH:mm:ss"));
        dispatch(setTimerTimeSheet(diff));
      }
    }

    if( userassignment[0] && userassignment[0].TimeLogs.length > 0){
      for(var i=0;i<=userassignment[0].TimeLogs.length-1 ; i+=2){
        if(userassignment[0].TimeLogs[i]){
          
          diff += new Date(userassignment[0].TimeLogs[i + 1]).getTime() - new Date(userassignment[0].TimeLogs[i]).getTime();
          if(diff){
            setRenderedStreamDuration(moment.utc(diff).format("HH:mm:ss"));
            dispatch(setTimerTimeSheet(diff));
          }
        }
      }
    }
    
    if(userassignment[0] && userassignment[0].TimeLogs.length > 0 && userassignment[0].Status==="pause"){
      setIsPauseTimer(true);
      setIsStartTimer(true);
      // setIsResumeTimer(false);
      cancelAnimationFrame(requestAnimationFrameId.current);
    }

    if( userassignment[0] && userassignment[0].TimeLogs.length > 0 && userassignment[0].Status==="start"){
      setIsStartTimer(true);
      setIsPauseTimer(false);
      // setIsResumeTimer(true);
      startTimer();
    }

    
    
  }, [userassignment.length])


  const updateTimer = useCallback(() => {
    let now = performance.now();
    let dt = now - previousTime.current;

    if (dt >= 1000) {
      streamDuration.current = streamDuration.current + Math.round(dt / 1000);
      let formattedStreamDuration = new Date(streamDuration.current * 1000)
        .toISOString()
        .substr(11, 8);
      if(diff){
        formattedStreamDuration = moment.utc(diff).add(moment.duration(formattedStreamDuration)).format("HH:mm:ss",{trim: false});
      }
      setRenderedStreamDuration(formattedStreamDuration);
      previousTime.current = now;
    }
    requestAnimationFrameId.current = requestAnimationFrame(updateTimer);
  }, []);

  const startTimer = useCallback(() => {
    previousTime.current = performance.now();
    requestAnimationFrameId.current = requestAnimationFrame(updateTimer);
  }, [updateTimer]);

  useEffect(() => {
    if (isStartTimer && !isStopTimer && !isPauseTimer) {
      startTimer();
    }
    if (isStopTimer && !isStartTimer) {
      streamDuration.current = 0;
      cancelAnimationFrame(requestAnimationFrameId.current);
      setRenderedStreamDuration("00:00:00");
    }
  }, [isStartTimer, isStopTimer, startTimer]);

  const startHandler = () => {
    setIsStartTimer(true);
    setIsStopTimer(false);
    dispatch(createTimesheet('start', new Date(), '619e008b3472b764ef5c758d'));
  };

  const stopHandler = () => {
    setIsStopTimer(true);
    setIsStartTimer(false);
    setIsPauseTimer(false);
    setIsResumeTimer(false);
    dispatch(updateTimesheetItem('stop'));
  };

  const pauseHandler = () => {
    setIsPauseTimer(true);
    // setIsStartTimer(false);
    setIsResumeTimer(false);
    cancelAnimationFrame(requestAnimationFrameId.current);
    dispatch(updateTimesheetItem('pause'));
    openNotification(key,(
        <div style={{fontSize:14}}>
            <div> Daily Remainder</div>
            <div>Need to resume your time?</div>
        </div>
    ),'',4);
  };

  const resumeHandler = () => {
    setIsResumeTimer(true);
    setIsPauseTimer(false);
    dispatch(updateTimesheetItem('start'));
    startTimer();
    openNotification(key,(
      <div style={{fontSize:14}}>
          <div> Daily Remainder</div>
          <div>Need to pause your time?</div>
      </div>
    ),'',4);

  };
  
  var beforeTime = 0;
  var currentTime = new Date();

  if(userAssignments.length>0){
    var notTime = new Date(userAssignments[0].Shift.StartTime);
    currentTime = new Date();
    notTime.setDate(currentTime.getDate());
    notTime.setMonth(currentTime.getMonth());
    notTime.setFullYear(currentTime.getFullYear());

    beforeTime= notTime.setMinutes( notTime.getMinutes() - 15 );
  }

  //NOTIFICATION UI:
  const btn = (
    <>
        <div style={{ backgroundColor: "lightgrey", display: "flex", borderRadius: "4px", justifyContent: 'space-between' }}>
            <div style={{ marginTop: '15px', fontSize: 16, paddingLeft: 10, alignItems: "flex-start" }}>
                <div>
                    {time}
                </div>
                <div >
                    {date}
                </div>
            </div>
            <div style={{ float: 'right', display: 'flex', fontSize: 18 }}>
                
                {userassignment.length && userassignment[0].Status == "start" || userassignment.length && userassignment[0].Status == "pause" || userassignment.length && userassignment[0].Status == 'idle' ?
                    <div> 
                         {!isPauseTimer ?
                            <Button type="primary" size="small" style={{ marginLeft: 2, backgroundColor: 'green' }} onClick={resumeHandler}>Resume</Button>
                            :
                            <Button type="primary"  size="small" style={{ marginLeft: 2, backgroundColor: 'green' }} onClick={pauseHandler}>Pause</Button>
                            }
                    </div>
                    : <div>
                        <Button
                            type="primary"
                            size="small"
                            onClick={() => {

                                if (isStartTimer) {
                                    // 
                                    dispatch(updateTimesheetItem('stop'));
                                    notification.close(key)
                                } else {
                                    if (!isStartTimer){
                                      setIsStartTimer(true)
                                    }
                                    if (isStopTimer) {
                                        // 
                                        setIsStopTimer(false)
                                    }
                                    setIsStopTimer(!isStopTimer)
                                    dispatch(createTimesheet('start', new Date(), '619e008b3472b764ef5c758d',0,0));
                                }
                            }}
                            style={{ marginLeft: 0, backgroundColor: 'green' }}
                        >
                            {!isStopTimer ? 'Start my day' : 'End my day'}
                        </Button>
                    </div>
                }
            </div>
        </div>
    </>
  );

  const openNotification = (key, message, description, duration=25) => {
    notification.open({
        message,
        className: 'custom-class',
        //icon: <SmileOutlined style={{ color: "#108ee9" }} />,
        icon: <img
        src={logo}
        className="notification-icon"/>,
        btn,
        key,
        duration: duration,
    });
  };

  return (
    <>
      {
        isStopTimer ?
          null :
          userassignment.length && userassignment[0].Status == 'idle' && beforeTime <= currentTime.getTime() || userassignment.length && userassignment[0].Status == 'start' && beforeTime <= currentTime.getTime() || userassignment.length && userassignment[0].Status == 'pause' && beforeTime <= currentTime.getTime()  ?
            <Row className={'timerContainer'}>

              <Col xs={isStartTimer ? 10 : 5} sm={isStartTimer ? 9 : 5} md={isStartTimer ? 9 : 6} lg={isStartTimer ? 9 : 4} xl={isStartTimer ? 9 : 4} style={{ display: 'flex', justifyContent: 'space-evenly', alignItems: 'center',  }}>
                {
                  isStartTimer ?
                    <Row style={{ display: 'flex', alignItems: 'center', }}>
                      <Col>
                        {
                          isPauseTimer ?
                            // <CaretRightOutlined style={{ fontSize: '40px', color: 'white', background: '#118936', borderRadius: '6px' }} onClick={resumeHandler} />
                            <div className='play_container' onClick={resumeHandler}>
                              <img src={play} alt='play_button' className='play_button' />
                            </div>
                            :
                            // <PauseOutlined style={{ fontSize: '38px', color: 'white', background: '#118936', borderRadius: '6px', width: '45px', height: '38px' }} onClick={pauseHandler} />
                            <div className='pause_container' onClick={pauseHandler}>
                              <img src={pause} className='pause_button' />
                            </div>
                        }

                      </Col>
                      {/* <Col style={{ marginLeft: '6px', background: 'red', borderRadius: '6px', width: '45px', height: '40px', display: 'flex', alignItems: 'center', justifyContent: 'center' }} onClick={stopHandler} >
                        <BsFillRecordFill style={{ fontSize: '25px', color: 'white', }} />
                      </Col> */}
                      <div className='stop_container' onClick={stopHandler}>
                        <img src={stop_logo} className='stop_button' />
                      </div>
                    </Row>
                    :
                    // <CaretRightOutlined style={{ fontSize: '40px', color: 'white', background: '#118936', borderRadius: '6px' }} onClick={startHandler} />
                    <div className='play_container' onClick={startHandler} style={{marginLeft:'7px'}}>
                      <img src={play} alt='play_button' className='play_button' />
                    </div>
                }
              </Col>

              <Col xs={isStartTimer ? 14 : 19} sm={isStartTimer ? 15 : 19} md={isStartTimer ? 15 : 18} lg={isStartTimer ? 15 : 20} xl={isStartTimer ? 15 : 20} style={{ display: 'flex', height: '100%', }}>
                <Row style={{ height: '100%', display: 'flex', paddingLeft: '3%' }}>
                  <Col span={24} style={{ height: '40%' }}>
                    <p className={'clockTitle'}>{userAssignments.length>0 ? "Clock"+`${isStartTimer ? ' Started at '  : ' Starts at '}` + moment.utc(userAssignments[0].Shift.StartTime).format('h:mm A') : ''}</p>
                  </Col>
                  <Col span={24} style={{ height: '60%' }}>
                    <h4 className={'stopWatch'}>{renderedStreamDuration}</h4>
                  </Col>
                </Row>
              </Col>
            </Row> : ''
      }
    </>
  )
}
export default Timer;