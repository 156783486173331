import React, { useRef } from 'react';
import './ViewResume.css';
import ProfessionalExperienceResume from './ProfessionalExperience/ProfessionalExperienceResume';
import EducationResumeSection from './EducationResumeSection/EducationResumeSection';
import ViewResumeDownloadPDF from './ViewResumeDownloadPDF/ViewResumeDownloadPDF';
import { useDispatch, useSelector } from 'react-redux';
import { useEffect } from 'react';
import { fetchResumeView } from '../../../redux/actions';
import Loader from '../../../components/Loader/Loader';
import { useParams } from 'react-router-dom';


export function capitalizeFirstLetter(string) {
    return string?.charAt(0)?.toUpperCase() + string?.slice(1);
}

const ViewResume = () => {
    const dispatch = useDispatch();
    const reportTemplateRef = useRef(null);
    const ResumeViewDetail = useSelector((state) => state.resumeViewReducer.success.data);
    const ResumeLoading = useSelector((state) => state.resumeViewReducer.loading); 
    let { id } = useParams();
    
    useEffect(() => {
        dispatch(fetchResumeView(id));
    }, [id]);

    function undeletedItems(Key) {
        return ResumeViewDetail?.Profiles?.[Key]?.filter((item) => !item.Deleted);
    }

    return (
        <>
            {ResumeLoading ? <Loader /> :
                <div>
                    <div className="ViewResume-container">
                        <div id = "DownloadResumePage" ref = {reportTemplateRef}>
                            <div className="ViewResume-Candidate-Name">
                                {ResumeViewDetail?.Profiles?.FirstName && ResumeViewDetail?.Profiles?.LastName
                                    ? capitalizeFirstLetter(ResumeViewDetail?.Profiles?.FirstName) + " " + capitalizeFirstLetter(ResumeViewDetail?.Profiles?.LastName)
                                    : "Candidate Name"}
                            </div>
                            <div className="ViewResume-link">
                                {window?.location?.href}
                            </div>
                            <ProfessionalExperienceResume ResumeViewDetail={undeletedItems} />
                            <EducationResumeSection ResumeViewDetail={undeletedItems} />
                        </div>
                        <ViewResumeDownloadPDF
                            DownloadResumePage={"DownloadResumePage"}
                            downloadFileName={ResumeViewDetail?.Profiles?.FirstName}
                        />
                    </div>
                </div>
            }
        </>
    );
};

export default ViewResume;