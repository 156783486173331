import moment from "moment";
import React, { useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";
import { fetchInterview } from "../../redux/actions";
import "./Interview.css";
import InterviewCard from "./InterviewCard/InterviewCard";
import NoInterview from "./NoInterview/NoInterview";

// its give to today's date here
class todayDate {
  constructor() {
    this.date = new Date();
    this.dayInWeek = [
      "Sunday",
      "Monday",
      "Tuesday",
      "Wednesday",
      "Thursday",
      "Friday",
      "Saturday",
    ];
    this.monthInYear = [
      "January",
      "February",
      "March",
      "April",
      "May",
      "June",
      "July",
      "August",
      "September",
      "October",
      "November",
      "December",
    ];
    this.numPrefix = ["st", "nd", "rd"];
  }
  getTodaysDate() {
    const curDate = this.date.getDate();
    const curDay = this.dayInWeek[this.date.getDay()];
    const curMonth = this.monthInYear[this.date.getMonth()];
    const curYear = this.date.getFullYear();
    const datePrefix = curDate < 4 ? this.numPrefix[curDate - 1] : "";
    const todaysDate = `${curDay}, ${curMonth} ${
      curDate + datePrefix
    } , ${curYear}`;

    return todaysDate;
  }
}

const Interview = () => {
  const date = new todayDate();
  const totalInterviews = useSelector(
    (state) => state.interviewReducer.success
  );
  const dispatch = useDispatch();

  // 

  useEffect(() => {
    dispatch(fetchInterview());
  }, []);

  return (
    <>
      <div className="interview-container">
        <div className="interview-heading">
          <h3>Upcoming interviews</h3>
          {/* <p>{date.getTodaysDate()}</p> */}
          <p>{moment().format("dddd , MMMM DD, YYYY")}</p>
        </div>
        <div className="interview-details-cantainer">
          {totalInterviews && totalInterviews.length !== 0 ? (
            totalInterviews.map((interviews) => (
              <InterviewCard key={interviews._id} interview={interviews} />
            ))
          ) : (
            <NoInterview />
          )}
        </div>
      </div>
    </>
  );
};

export default Interview;
