import React, { useEffect, useState } from "react";
import OtpInput from "react-otp-input";
import "./MobileNumberOTP.css";
import { Modal, Spin } from "antd";
import Loader from "../../../../components/Loader/Loader";
import { LoadingOutlined } from "@ant-design/icons";

const MobileNumberOTP = ({
    isModalOpen,
    setIsModalOpen,
    phoneNumber,
    countryCode,
    otp,
    setOtp,
    OTPVerification,
    OTPapiSuccess,
    LoadingOtp,
    setLoadingOtp
}) => {

    const [otpMatch, setOtpMatch] = useState(false);
    useEffect(() => {
        if (OTPapiSuccess?.status == "failed") {
            setOtpMatch(true);
            setLoadingOtp(false);
        } else {
            setOtpMatch(false);
        }
    }, [OTPapiSuccess?.status]);

    const handleCancel = () => {
        setIsModalOpen(false);
        setOtpMatch(false);
        setOtp(null);
    };

    useEffect(() => {
        if (otp?.length == 4) {
            OTPVerification();
        }
    }, [otp])

    return (
        <Modal
            title="Verify mobile number"
            visible={isModalOpen}
            onCancel={handleCancel}
            centered
            footer={false}
            className="MobileNumberOTP-Modal"
        >
            <div className="MobileNumberOTP-cantiner">
                <div className="MobileNumberOTP-Text-section">
                    A verification code has been sent to:
                </div>
                <div className="Mobile-otp-number-edit">
                    + {countryCode + " "}
                    {phoneNumber} <div onClick={handleCancel}>Edit</div>
                </div>
                <div className="Mobile-code-verification-enter-text">
                    Please enter the verification code:
                </div>
                <div
                    className={`Mobile-code-verification-enter ${otpMatch ? "OTP-margin-true" : null
                        }`}
                >
                    <OtpInput
                        value={otp}
                        onChange={setOtp}
                        inputType={"tel"}
                        numInputs={4}
                        renderInput={(props) => <input {...props} />}
                        inputStyle={`OtpInput-number ${otpMatch ? "OtpInput-number-failed" : null}`}
                    />
                </div>
                {otpMatch && (
                    <div className="Mobile-otp-error-verification">
                        The code you entered is invalid. Please try again
                    </div>
                )}
                <button onClick={OTPVerification} className="OTP-submit-button">
                    <> Submit code {LoadingOtp && <Spin
                        indicator={
                            <LoadingOutlined
                                style={{
                                    fontSize: 16,
                                    color: "#fff",
                                    marginLeft: "2px"
                                }}
                                spin
                            />
                        }
                    />} </> 
                </button>
                <div className="MobileNumberOTP-Note-text-bottom">
                    If you did not receive the code, click <span onClick={handleCancel}>‘Edit’</span> and check
                    if you have entered the right number and try again.
                </div>
            </div>
        </Modal>
    );
};

export default MobileNumberOTP;
