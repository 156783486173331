import { types } from "../action-types";
const initialState = {
  loading: false,
  HomePageData: [],
  Error: {},
  HomeFav: [],
};

const getStateAfterAddRemoveFavorite = (state, JobOrderId, IsFavorite) => {
  const jobArray = [
    "ContinueBrowsing",
    "MostPopular",
    "Suggested",
    "EditorPicks",
  ];
  let obj = {};
  jobArray.map((job) => {
    if (state.HomePageData?.body?.[job]) {
      let x = null,
        tempHome = [];
      state.HomePageData?.body?.[job]?.map((objItem, i) => {
        if (objItem?.JobOrderId === JobOrderId) {
          return (x = i);
        }
      });
      tempHome = state.HomePageData?.body?.[job];
      if (x !== null)
        tempHome[x] = {
          ...state.HomePageData?.body?.[job]?.[x],
          IsFavorite,
        };
      obj[job] = [...tempHome];
    }
  });
  return obj;
};

const homepageReducer = (state = initialState, action) => {
  switch (action.type) {
    case types.FETCH_HOME_PAGE_DATA_INIT:
      return {
        ...state,
        loading: true,
      };
    case types.FETCH_HOME_PAGE_DATA_SUCCESS:
      // 
      return {
        loading: false,
        ...state,
        HomePageData: action.payload,
        HomeFav: action.payload.body.Favorites,
      };
    case types.FETCH_HOME_PAGE_DATA_FAILURE:
      return {
        ...state,
        loading: false,
        Error: action.error,
      };
    case types.HOME_PAGE_FAVORITE_UPDATE:
      // // 
      const dataupdate = action?.payload?.payload[2];
      // 
      //   dataupdate?.Types,
      //   "HOME_PAGE_FAVORITE_UPDATE",
      //   state.HomeFav
      // );
      return {
        ...state,
        HomeFav: [
          ...state.HomeFav,
          {
            gig: {
              ...dataupdate?.data,
              IsFavorite: true,
              Types: dataupdate?.Types,
            },
          },
        ],
      };
    case types.HOME_PAGE_FAVORITE:
      const addJobOrderId = action?.payload[0];
      const addFavoriteJob = getStateAfterAddRemoveFavorite(
        state,
        addJobOrderId,
        true
      );
      return {
        ...state,
        HomePageData: {
          ...state.HomePageData,
          body: { ...state.HomePageData.body, ...addFavoriteJob },
        },
      };
    case types.HOME_PAGE_FAVORITE_REMOVE:
      const removeJobOrderId = action?.payload.payload[0];
      const removeFavoriteJob = getStateAfterAddRemoveFavorite(
        state,
        removeJobOrderId,
        false
      );
      return {
        ...state,
        HomePageData: {
          ...state.HomePageData,
          body: { ...state.HomePageData.body, ...removeFavoriteJob },
        },
      };
    default:
      return state;
  }
};

export default homepageReducer;
