import React, { useEffect, useRef, useState } from "react";
import { Row, Col, Button, Rate, Modal, Tooltip, Dropdown, Menu } from "antd";
import './styles.css'
import { InfoCircleOutlined } from "@ant-design/icons";

export const BidinfoTooltip = (props) => {
  const [bidInfotip, setBidinfoTip] = useState(true)

  return (
    <Tooltip
      title={
        <div>
          <div style={{ display: 'flex', flexDirection: 'row', justifyContent: 'space-around' }}>
            <div style={{ marginRight: '2%' }}>
              <InfoCircleOutlined  />
            </div>
            <div>
              Clicking on <strong style={{fontWeight:'bolder'}}>‘Interested’</strong> on a bid doesn’t mean a job offer or you've been hired. It simply indicates you would like to consider this job opportunity.
            </div>
          </div>
          <strong style={{ display: 'flex', flexDirection: 'row-reverse', cursor: 'pointer', marginTop:'1rem' }}
            onClick={(e) => { setBidinfoTip(false); e.stopPropagation()  }}
          >Got it</strong>
        </div>
      }
      color="#ddeefe"
      overlayClassName="tooltip-class"
      placement={props.placement}
      defaultVisible={true}
      defaultOpen={true}
      visible={bidInfotip}
    overlayStyle={{zIndex:'999'}}
    >
      {props.children}
    </Tooltip>
  )
}