import React, { useState, useEffect } from "react";

import moment from "moment";
import { useDispatch, useSelector } from "react-redux";

import { BiCalendar } from "react-icons/bi";
import { FiPaperclip } from "react-icons/fi";
import { Input, Radio, Space } from "antd";
import RadioButtonInterview from "./RadioButtonInterview";
import { useUpdateLogger } from "../../../shared/useUpdateLogger";
import { useRef } from "react";
import downloadChatFilesApi from "../../../shared/downloadFiles";

const ScreeningAndSchedule = (props) => {
  const refOffer = useRef();

  const {
    dimensions,
    disableState,
    screeningDate,
    uppertext,
    lowerText,
    firstButton,
    secondButton,
    screeningdisplay,
    ThirdButton,
    Reschedule,
    disableStateScreening,
    no,
    icon,
    CalendraModal,
    Interviewselecter,
    onchangeStage,
    AcceptInterview,
    iconCal,
    OpenModalBoxStages,
    stagename,
    AcceptButtonStages,
    AcceptButton,
    setInterviewTime,
    errorMessage,
    isPlacedBidsStatus,
    disabledAccepted,
    removeExtraspace,
  } = props;

  const BidDetailData = useSelector(
    (state) => state.BidsReducer.detailReceivedBid
  );

  const PlacedBidDetailData = useSelector(
    (state) => state.BidsReducer.detailPlacedBid
  );

  const AccountId = useSelector(
    (state) => state.profileReducer.user.profile.AccountId
  );

  const [DetailPageData, setDetailPageData] = useState();

  useEffect(() => {
    {
      isPlacedBidsStatus
        ? setDetailPageData(PlacedBidDetailData)
        : setDetailPageData(BidDetailData);
    }
  }, [BidDetailData, isPlacedBidsStatus]);

  // // 
  // useUpdateLogger("Interviewselecter", Interviewselecter);
  // useUpdateLogger("DetailPageData", DetailPageData);

  const onChangeRadio = (event) => {
    setInterviewTime(event?.target?.value);
  };

  const handleDownload = async (filePath) => {
    // 
    await downloadChatFilesApi(filePath);
  };

  return (
    <section>
      {Interviewselecter ? (
        <RadioButtonInterview
          onChangeRadio={onChangeRadio}
          BidDetailData={
            isPlacedBidsStatus ? PlacedBidDetailData : BidDetailData
          }
        />
      ) : null}
      <div className="ErrorMessage">{errorMessage}</div>
      {screeningdisplay === "True" ? (
        <div
          className={
            disableStateScreening
              ? "screening-status-disable"
              : "screening-status"
          }
        >
          <a
            ref={refOffer}
            href={`${process.env.REACT_APP_S3_URL}${
              DetailPageData?.offers?.Attachments[0]?.FilePath?.charAt(0) != "/"
                ? "/"
                : ""
            }${DetailPageData?.offers?.Attachments[0]?.FilePath}`}
            target="_self"
            style={{ display: "none" }}
          ></a>
          <span className="spanOne">{uppertext}</span>
          <span
            className={`spanTwo ${icon ? "clickable" : null}`}
            onClick={() => {
              if (icon) refOffer.current.click();
              // handleDownload(
              //   DetailPageData?.offers?.Attachments[0]?.FilePath
              // );
            }}
          >
            {lowerText ? (
              <>
                {icon ? (
                  <FiPaperclip className="iconFontSize-stages1" />
                ) : iconCal ? (
                  <BiCalendar className="iconFontSize-stages" />
                ) : null}{" "}
                {lowerText}
              </>
            ) : (
              <>
                <BiCalendar style={{ fontSize: "1.13rem" }} />
                {moment(screeningDate).format("MMMM DD, dddd, YYYY")}
              </>
            )}
          </span>
        </div>
      ) : null}
      {dimensions > 768 && (
        <div
          className={
            disableState
              ? "interestedSection-about-job-disable"
              : `interestedSection-about-job ${
                  DetailPageData?.interview?.ModifiedBy === AccountId &&
                  DetailPageData?.interview?.InterviewStatuses
                    ?.InterviewStatusName === "Rescheduled" &&
                  "Rescheduled"
                }`
          }
        >
          <div
            className={
              DetailPageData?.interview?.ModifiedBy === AccountId &&
              DetailPageData?.interview?.InterviewStatuses
                ?.InterviewStatusName === "Rescheduled"
                ? "interested-about-job Accepted-Button-Hidden"
                : `interested-about-job ${removeExtraspace}`
            }
            onClick={() => {
              AcceptButton(stagename);
            }}
          >
            {firstButton}
          </div>
          <div
            className={`notInterested-about-job ${disabledAccepted}`}
            onClick={() => {
              OpenModalBoxStages(stagename);
            }}
          >
            {secondButton}
          </div>
          {Reschedule ? (
            <div
              className={
                DetailPageData?.interview?.ModifiedBy === AccountId &&
                DetailPageData?.interview?.InterviewStatuses
                  ?.InterviewStatusName === "Rescheduled"
                  ? "interested-about-job"
                  : `notInterested-about-job notInterested-about-job-margin ${disabledAccepted}`
              }
              onClick={CalendraModal}
            >
              {ThirdButton}
            </div>
          ) : null}
        </div>
      )}
    </section>
  );
};

export default ScreeningAndSchedule;
