import React, { useState, useEffect } from "react";
import { LoadingOutlined } from '@ant-design/icons';

const BottomBottons = (props) => {

    return (
        <div className={`create-skill-listing-buttons${props.isGigType ? "" : " bottom-gap"}${props.isTitlePage ? " isTitlePage": ""}`}>
            <div onClick={!props.loading ? props.handleBack : null} className="back-button">
                Back
            </div>
            <div onClick={!props.loading ? props.handleNext : null} className="next-button">
                { props.loading &&
                    <LoadingOutlined style={{ fontSize: 24, marginRight: "4px" }} spin /> 
                }
                Next
            </div>
            {props?.Skip && <div onClick={props.Skiphandle} className="skip-button">
                Skip
            </div>}
        </div>
    )
}

export default BottomBottons;