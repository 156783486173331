import { call, put, takeEvery, takeLeading } from "redux-saga/effects";
import { types } from "../action-types";
import {
  fetchChatHistoryApi,
  downloadChatFilesApi,
  MessageAttachmentAPi,
  getInboxMessagesAPi,
  getMsgsCountApi,
  getDashboardInboxMessagesAPi,
  fetchNodificationUpdateApi,
  AdminMessageInboxAPI,
} from "../../API/API";
import { fetchMessagesCounts, getNotifications } from "../actions";

function* getChatHistorySaga(action) {
  try {
    const { id, bid } = action.payload;
    const response = yield call(() => fetchChatHistoryApi(id, bid));
    let messages = response?.data?.data?.body;
    const cards = response?.data?.data?.cards;
    let id1,id2,sender,rec;
    if(cards && messages && messages.length){
      for (let index = 0; index < messages.length; index++) {
        id1 = messages[index].SenderProfileId;
        id2 = messages[index].RecipientProfileId;
        sender = cards?.userIds.findIndex((i) => i == id1);
        rec = cards?.userIds.findIndex((i) => i == id2);
        messages[index].SenderUser = cards?.users[sender];
        messages[index].RecipientUser = cards?.users[rec];
        if (messages[index].Status) {
          messages[index].BidCard =
            cards?.msg?.SourceTypeId == 1 ? cards?.bid : null;
          messages[index].ProposalCard =
            cards?.msg?.SourceTypeId == 2 ? cards?.bid : null;
          messages[index].SkillListingCard = cards?.SkillListingCard ?? null;
          messages[index].InterviewCard = cards?.InterviewCard ?? null;
          messages[index].OfferCard = cards?.bid?.offers ?? null;
          messages[index].Ratings =  cards?.bid.rating ?? null;
          messages[index].GigCard = cards?.GigCard ?? null;
        }
      }
    }
    yield put({ type: types.FETCH_CHAT_SUCCESS, payload: response.data });
    yield put(fetchMessagesCounts());
    const responseNodification = yield call(() => fetchNodificationUpdateApi(id));
    if (response?.status == 200 && responseNodification?.status == 200) {
      yield put(getNotifications());
    }
  } catch (error) {
    yield put({ type: types.FETCH_CHAT_FAILURE, error });
  }
}

function* postAttachmentSaga(action) {
  // 
  try {
    const response = yield call(() => MessageAttachmentAPi(action.payload));
    yield put({
      type: types.SEND_MESSAGE_ATTACHMENT_SUCCESS,
      payload: response.data,
    });
  } catch (error) {
    yield put({ type: types.SEND_MESSAGE_ATTACHMENT_FAILURE, error });
  }
}
function* fetchInboxMessagesSaga(params) {
  try {
    // const {type, results, skip} = action.payload;
    const response = yield call(() => getInboxMessagesAPi(params.payload));
    yield put({
      type: types.GET_MESSAGES_INBOX_SUCCESS,
      payload: {
        data: response.data,
        fetchMore: params?.payload?.fetchMore,
        fromSocket: params.payload.perPage == 1 ? true : false,
        bidStatus: params?.payload?.bidStatus,
        bidType: params?.payload?.bidType,
        conversationType: params?.payload?.conversationType,
        page: params?.payload?.page ?? 1
      },
    });
    yield put(fetchMessagesCounts());
  } catch (error) {
    // 
    yield put({ type: types.GET_MESSAGES_INBOX_FAILURE, error });
  }
}

function* fetchDashboardInboxMessagesSaga(params) {
  try {
    const response = yield call(() => getDashboardInboxMessagesAPi(params.payload));
    yield put({
      type: types.DASHBOARD_MESSAGES_INBOX_SUCCESS,
      payload: {
        data: response.data?.data?.body?.conversations,
      },
    });
  } catch (error) {
    yield put({ type: types.DASHBOARD_MESSAGES_INBOX_FAILURE, error });
  }
}

function* fetchDashboardInboxAdminMessagesSaga(params) {
  try {
    const response = yield call(() => AdminMessageInboxAPI(params.payload));
    yield put({
      type: types.ADMIN_MESSAGING_DASHBOARD_SUCCESS,
      payload: {
        data: response?.data?.body,
      },
    });
  } catch (error) {
    yield put({ type: types.ADMIN_MESSAGING_DASHBOARD_FAILURE, error });
  }
}

function* getMsgsCountsSaga(action) {
  try {
    const response = yield call(() => getMsgsCountApi(action.payload));
    yield put({
      type: types.GET_MESSAGES_COUNTS_SUCCESS,
      payload: response.data,
    });
  } catch (error) {
    yield put({ type: types.GET_MESSAGES_COUNTS_FAILURE, error });
  }
}
// function* getChatFilesSaga(action){
//     // 
//     try{
//         const { FilePath } = action.payload;
//         const response = yield call(() => downloadChatFilesApi(FilePath));
//         yield put({ type: types.FETCH_CHAT_FILES_SUCCESS, payload: response.data });
//     }
//     catch(error){
//         yield put({type:types.FETCH_CHAT_FILES_FAILURE,error});
//     }
// }

export function* watchGetChatSaga() {
  yield takeLeading(types.FETCH_CHAT_REQUESTED, getChatHistorySaga);
}
export function* watchPostAttachmentSaga() {
  yield takeEvery(types.SEND_MESSAGE_ATTACHMENT_REQUESTED, postAttachmentSaga);
}

//inbox messages saga
export function* watchInboxMessagesSaga() {
  yield takeEvery(types.GET_MESSAGES_INBOX_REQUESTED, fetchInboxMessagesSaga);
}

//inbox messages saga
export function* watchDashboardInboxMessagesSaga() {
  yield takeEvery(
    types.DASHBOARD_MESSAGES_INBOX_REQUESTED,
    fetchDashboardInboxMessagesSaga
  );
}

export function* watchGetMsgCountsSaga() {
  yield takeEvery(types.GET_MESSAGES_COUNTS_INIT, getMsgsCountsSaga);
}
// export function* watchGetChatFilesSaga(){
//     yield takeEvery(types.FETCH_CHAT_FILES_REQUESTED,getChatFilesSaga);
// }

export function* watchGetAdminMsgDashboardSaga() {
  yield takeEvery(types.ADMIN_MESSAGING_DASHBOARD_INIT, fetchDashboardInboxAdminMessagesSaga);
}