import React from "react";
import "./AssesmentExam.css";
import { BsChevronRight } from "react-icons/bs";

const AssesmentExam = ({ labelName, url, bgColor, hoverBgColor = "" }) => {
  return (
    <div
      onClick={() => {
        window.open(url);
      }}
      className="assessment-link-container"
      style={{
        backgroundColor: bgColor,
        "--assessmentHoverEffect": hoverBgColor,
      }}
    >
      <span className="assessment-link-context">{labelName}</span>
      <span className="assessment-link-icon">
        <BsChevronRight className="right-arrow-icon" />
      </span>
    </div>
  );
};

export default AssesmentExam;
