import React, { useEffect, useState } from 'react'
import { useDispatch, useSelector } from "react-redux";
import { Button, Col, Typography, Input, Row, Menu, Dropdown,Tag } from "antd";
import './TimesheetListing.css'
import { EllipsisOutlined } from '@ant-design/icons';
import moment from 'moment';
import { useHistory } from 'react-router';

export default function TimesheetListing(props) {
    // 

    const history = useHistory();
    // const timeList = useSelector(state => state.timeSheetListReducer.timeList);
    // // 

    const [startDay, setstartDay] = useState(false)
    const [pause, setpause] = useState(false)
    const [status, setStatus] = useState(props.status)
    const [bool,setBool] = useState(false);
    const [tagColor, settagColor] = useState('');
    // // 

    useEffect(()=>{
        if(props.timesheetcorrections && props.timesheetcorrections.length && props.timesheetcorrections[0].Status){
        if(props.timesheetcorrections[0].Status==='Pending'){
            settagColor('rgba(158, 158, 158, 0.2)')
        }
        else if(props.timesheetcorrections[0].Status==='Rejected'){
            settagColor('rgba(219, 40, 40, 0.2)')
        }
        else if(props.timesheetcorrections[0].Status==='Declined'){
            settagColor('rgba(219, 40, 40, 0.2)')
        }
        else if(props.timesheetcorrections[0].Status==='Approved'){
            settagColor('rgba(17, 137, 54, 0.2)')
        }
        else if(props.timesheetcorrections[0].Status==='Accepted'){
            settagColor('rgba(17, 137, 54, 0.2)')
        }
    }
    },[props])

    useEffect(()=>{
        if (props.status != 'Correction') {
            setBool(true);
        }
    },[])

    const toHoursAndMinutes = (totalMinutes) => {
        const minutes = totalMinutes % 60;
        const hours = Math.floor(totalMinutes / 60);

        if(minutes){
            if(hours > 1){
                return `${hours}:${padTo2Digits(minutes)} HRS`;
            }
            return `${padTo2Digits(minutes)} MINS`;
        }
        if(hours){
            return `${hours} HRS`;
        }
        return '0 HR';
      
      }
      
    const padTo2Digits = (num) => {
        return num.toString().padStart(2, '0');
    }

    const menu = (
        <Menu>
            <Menu.Item >
                <div style={{fontSize: '16px', color: '#1e1e1e'}} onClick={() => {
                    if (props.status != 'Correction') {
                        history.push({
                            pathname: '/requestcorrection',
                            state: {
                                id: props.id,
                                date: props.dateTime,
                                startTime: props.startTime,
                                endTime: props.endTime,
                            }
                        })
                    }
                }}>Request correction</div>
            </Menu.Item>
        </Menu>
    )

    return (
        <>
            <Col lg={24} sm={24} id={props.id}>
                <Row className={'timeContainer'} >
                    <Col xs={10} sm={10} lg={11} xl={11} style={{ height: '100%',display:'flex', alignItems:'center' }}>
                        <div className={'dateTimeContainer'}>
                            <Col span={24} ><div className={'date'}>{moment(props.dateTime).format('dddd , MMMM DD, YYYY')}</div></Col>
                            <Col span={24} className={'date'} >{moment.utc(props.startTime).format('h:mm A')} {moment.utc(props.endTime).format('h:mm A')}</Col>
                            
                        </div>
                    </Col>
                    <Col xs={10} sm={10} lg={9} xl={9}  style={{ height: '100%', }}>
                        <Row className={'hoursContainer'}>
                            <Col lg={9} sm={5} className={'hoursText'}><strong>{props.SpentTime ? toHoursAndMinutes(props.SpentTime.toFixed(1) * 60 ) : "0 hr"} </strong><span style={{color:"#d4d4d4"}}>|</span></Col>
                            <Col lg={7} sm={5} className={'hoursText2'}><strong>{props.OverTime  ? toHoursAndMinutes(props.OverTime.toFixed(1) * 60) : '0 hr'}</strong></Col>
                        </Row>
                    </Col>
                    {/* {
                        status === "finish" ? */}
                    <Col xs={4} sm={4} lg={4} xl={4} style={{ display:'flex', alignItems:'center', justifyContent:'flex-end'}}>
                        <Dropdown overlay={props.status === 'Correction'?"":menu} placement="bottomLeft">
                            <EllipsisOutlined style={{ fontSize: '32px', color: "#9e9e9e", cursor:'pointer' }} disabled />
                        </Dropdown>
                    </Col>
                </Row>

                <Row>
                    {props.timesheetcorrections  ? <Col className={'request-tag'}>{props.timesheetcorrections && props.timesheetcorrections.length && props.timesheetcorrections[0].Status? <Tag style={{ border: 0}} color={tagColor}>Request {props.timesheetcorrections[0].Status}</Tag>:null}</Col> : ''}
                </Row>

            </Col>
            <div className='listing-line'>
            </div>
        </>
    )
}
