import React, { useState, useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";

import logo from "../../assets/icons/Logo.svg";
import SGMob from "../../assets/icons/SGMob.svg";
import shiLogo from "../../assets/icons/shi.svg";
import poweredByWeb from "../../assets/icons/poweredByWeb.svg";
import poweredByMob from "../../assets/icons/poweredByMob.svg";
import burgerMenu from "../../assets/icons/ic_round-menu.svg";
import avatar from "../../assets/img/avatar.png";
import SkillGigss from "../../assets/icons/SkillGigss.svg";
import { connect } from "react-redux";

import "./Header.css";
import Sidebar from "../../pages/Credentialing/components/Sidebar";

import {
  ConsoleSqlOutlined,
  LockOutlined,
  MailOutlined,
  StarFilled,
  StarOutlined,
} from "@ant-design/icons";
import { MdEmail } from "react-icons/md";
import { IoMdArrowDropdown } from "react-icons/io";
import { MdOutlineMessage } from "react-icons/md";
import { BsBell } from "react-icons/bs";
import { CgBell } from "react-icons/cg";
import { IoIosStar } from "react-icons/io";

import { GiHamburgerMenu } from "react-icons/gi";
// import Message from "./../../assets/icons/Message.svg"

import {
  Modal,
  Tooltip,
  Popover,
  Badge,
  Drawer,
  Layout,
  Menu,
  Dropdown,
  Alert,
  Row,
  Col,
} from "antd";

import ProfileHeader from "./components/ProfileHeader";
import { Link, Switch, useHistory } from "react-router-dom";
import GuestSearchGigs from "../../pages/SearchGigs/GuestUser/GuestSearchGigs";
import { useLocation, useRouteMatch } from "react-router-dom";
import { sectionHeader } from "@aws-amplify/ui";
import {
  checkEmailFlow,
  fetchMessagesCounts,
  fetchUserProfile,
  getNotifications,
  isModalVisibleAuth,
  SearchValue,
  signUpLoginUpdate,
  fetchInboxMessages,
  getDashboardBidsCounts,
} from "../../redux/actions";
import { getCookie, setCookie } from "../../shared/helpers";
import SignUpLogin from "../../pages/Auth/components/SignUpLogin";
import SearchBar from "../../pages/SearchGigs/SearchBar";
import SearchBarMobile from "../../pages/SearchGigs/components/SearchBarMobile";
import ManageBidsMenu from "./components/ManageBidsMenu";
// import ProfileAvatar from "../ProfileAvatar";
import ProfileAvatar from "../../pages/NewDashboard/Components/ProfileAvatar/ProfileAvatar";
import Timer from "../Timer";
import { useUpdateLogger } from "../../shared/useUpdateLogger";
import Notifications from "../notifications/notifications";
import { BaseUrl } from "../Footer";
import messageIcon from "../../assets/icons/Group 28192.svg";
import notificationIcon from "../../assets/icons/Group 28193.svg";
import { NewProfileHeader } from "./components/NewProfileHeader";
import ModalEdit from "../../pages/Manage_SkillListing/ModalEdit";
import RoleExperienceNew from "../../pages/WelcomeWizard/RoleExperienceNew";
import { DefaultsRating } from "../../pages/WelcomeWizard/helper";
import { getPictureUrl } from "../../assets/helpers";

const Header = (props) => {
  const { id, emailVerified, WizardFlow, EmailVerification } = props;
  const [loggedInSearchBar, setloggedInSearchBar] = useState(false);
  const [employerTitle, setEmployerTitle] = useState(null);
  const dispatch = useDispatch();
  const { path, url } = useRouteMatch();
  const initialPath = useLocation().pathname.split("/")[1];
  const subrout = useLocation().pathname.split("/")[2];
  const [sbarVisible, setsbarVisible] = useState(false);
  const [proImg, setProImg] = useState(null);
  const [userName, setUserName] = useState("");
  const [profession, setProfession] = useState("");
  const isNotificationCheck = localStorage.getItem("isNotificationCheck");
  const [isNotification, setisNotification] = useState(
    isNotificationCheck === "true" ? true : ""
  );


  const employerDetails = useSelector((state) => state.employerReducer.emp);
  const [dropVisibleNotifications, setDropVisibleNotifications] =
    useState(false);

  const signUpLogin = useSelector((state) => state.authReducer.signUpLogin);
  const notificationUnreadCount = useSelector(
    (state) => state.NotificationReducer.notificationUnreadCount
  );
  const isModalVisibleAuthRestPassword = useSelector(
    (state) => state.authReducer.isModalVisibleAuthRestPassword
  );

  const counts = useSelector((state) => state.getChatHistoryReducer.counts);
  const [isCountVisible, setIsCountVisible] = useState({
    message: true,
    notification: true,
  });
  const Detail = useSelector((state) => state.resumeDetailReducer.success);
  const profile = Detail && Detail?.user && Detail?.user?.profile;
  const urlCheck = useLocation();
  const skillListing = useSelector(
    (state) => state.skillListingReducer.skillListings
  );
  const talentPrivate = useSelector(
    (state) => state?.profileReducer?.talentApiData?.talentPrivate
  );

  const history = useHistory();

  // const [isModalVisibleAuth, setIsModalVisibleAuth] = useState(false);
  const { isModalVisibleAuth, setIsModalVisibleAuth } = props;

  const [editModalState, setEditModalState] = useState(false);
  const [oneSkillListing, setOneSkillListing] = useState();
  const [skillId, setSkillId] = useState();

  const { authToken } = props;

  const showModal = () => {
    setIsModalVisibleAuth(true);
  };

  const modalCancel = () => {
    setIsModalVisibleAuth(false);
  };

  const [check, setCheck] = useState("");
  // const [header, setHeader] = useState("");
  let headerType = "";

  const [drawerState, setDrawerState] = useState({
    visible: false,
    placement: "left",
  });

  const [drawerStates, setDrawerStates] = useState({
    visibles: false,
    placements: "left",
  });

  const [sgLogo, setSgLogo] = useState(false);
  const [dimensions, setDimensions] = useState(window.innerWidth);

  const [dropVisible, setDropVisible] = useState(false);
  const [dropDisable, setDropDisable] = useState(false);

  const [dropVisibleBids, setDropVisibleBids] = useState(false);

  const location = useLocation();

  const isEmployerPageByQuery = () => {
    const queryParameters = new URLSearchParams(location.search);
     return queryParameters.get("empTitle")
    }

  const isEmployerSignUp = () => {
    const employer = JSON.parse(localStorage.getItem("employer"));
    return employer != null;
  };
  const isEmployerLandingPage = () =>
    initialPath === "employer" ||
    isEmployerSignUp() ||
    isEmployerPageByQuery();

  const fetchprofileDetails = () => {
    if (
      getCookie("talentToken") !== null &&
      getCookie("talentToken").length > 0
    ) {
      const talentToken = getCookie("talentToken");
      props.profileDetails(talentToken);
    }
  };
  const isEmployerRoute = () => initialPath === "employer" || isEmployerPageByQuery();

  const handleVisibleChange = (visible) => {
    setDropVisible(visible);
  };

  const handleVisibleChangeBids = (visible) => {
    setDropVisibleBids(visible);
  };

  const hideDrop = () => {
    setDropVisible(false);
  };

  const checkDimensions = () => {
    setDimensions(window.innerWidth);
  };
console.log('latest changes updated 22222')
  const [IsOnline, setIsOnline] = useState(true);
  const onlineUsers = useSelector(
    (state) => state.getChatHistoryReducer?.onlineUsers
  );

  useEffect(() => {
    function checkNetworkStatus() {
      // Network is down
      // Set user as offline/online
      setIsOnline(navigator.onLine);
    }

    // Check network status periodically
    setInterval(checkNetworkStatus, 5000);
  }, []);

  // useEffect(() => {
  //   const isUserActive =
  //     onlineUsers?.length &&
  //     onlineUsers?.filter(
  //       (i) => Number(i?.userId) === props?.profileImg?.AccountId
  //     )?.[0]?.IsUserOnline;
  //   setIsOnline(props?.profileImg?.IsOnline || isUserActive);
  // }, [props?.profileImg?.IsOnline, onlineUsers]);

  useEffect(() => {
    window.addEventListener("resize", checkDimensions);
    fetchprofileDetails();
  }, []);

  useEffect(() => {
    if (authToken != null && profile?.WizardFlow > 999) {
      dispatch(getNotifications());
      dispatch(fetchMessagesCounts());
      dispatch(fetchInboxMessages("all_bids", "all_conversations", "null"));
      dispatch(getDashboardBidsCounts());
    }
  }, [authToken, profile?.WizardFlow]);

  useEffect(() => {
    if (dimensions <= 1000) {
      setSgLogo(true);
    } else setSgLogo(false);
  }, [dimensions]);

  const clicked = () => {
    setsbarVisible(!sbarVisible);
  };

  const addQueryParam = () => {
    if (!isEmployerLandingPage()) return;
    // const employer = JSON.parse(localStorage.getItem("employer"));
    setEmployerTitle(employerDetails?.empTitle);
    const urlSearchParams = new URLSearchParams(window.location.search);
    urlSearchParams.set("empTitle", employerDetails?.empTitle);
    const newUrl = window.location.pathname + "?" + urlSearchParams.toString();
    window.history.pushState({ path: newUrl }, "", newUrl);
  };

  const getEmployerQuery = (emp) => {
    return `empTitle=${emp}`;
  };

  const signInUser = () => {
    addQueryParam();
    if (window.innerWidth < 1000) {
      window.location.replace(
        `/login${
          isEmployerLandingPage()
            ? `?${getEmployerQuery(employerDetails?.empTitle)}`
            : ""
        } `
      );
      setDrawerStates({ visibles: false });
    } else {
      showModal();
    }
    dispatch(checkEmailFlow(false));
    dispatch(signUpLoginUpdate(false));
  };

  const joinUser = () => {
    addQueryParam();
    if (window.innerWidth < 1000) {
      dispatch(SearchValue(""));
      history.push(
        `/${initialPath == "signup" ? "login" : "signup"}${
          isEmployerLandingPage()
            ? `?${getEmployerQuery(employerDetails?.empTitle)}`
            : ""
        }`
      );
      setDrawerStates({ visibles: false });
    } else {
      showModal();
    }
    dispatch(signUpLoginUpdate(true));
  };

  useEffect(() => {
    modalCancel();
  }, [urlCheck]);

  useEffect(() => {
    setSkillId(skillListing?.data?.[0]?.SkillListingId);
    setOneSkillListing(skillListing?.data?.[0]?._id);
  }, []);

  let headerContent = (
    <span>
      <LockOutlined /> Secured connection
    </span>
  );

  const showDrawer = () => {
    setDrawerState({ visible: true });
  };

  const onClose = () => {
    setDrawerState({ visible: false });
  };

  const showDrawers = () => {
    setDrawerStates({ visibles: true });
  };

  const onCloses = () => {
    setDrawerStates({ visibles: false });
  };

  useEffect(() => {
    if (props.profileImg) {
      setProImg(props.profileImg.PictureUrl);
    }
    const firstName = props?.profileImg?.FirstName
      ? props.profileImg?.FirstName
      : "";
    const lastName = props?.profileImg?.LastName
      ? props.profileImg?.LastName
      : "";
    const Name = firstName + " " + lastName;
    if (Name) {
      setUserName(Name);
    }
    // Displayed User Name
    // if (props?.profile?.length > 10) {
    //   const name = props?.profile?.split(" ")[0];
    //   setUserName(name);
    // } else {
    //   setUserName(props.profile);
    // }

    const pro = props?.firstUser?.profile?.ProfessionCode;
    if (pro === "IT") {
      setProfession("IT");
    } else if (pro === "FL") {
      setProfession("IT Freelancer");
    } else {
      setProfession("Health Care");
    }

    //
  }, [props.profile, props?.profileImg]);

  const openMessages = () => {
    history.push({
      pathname: "/messages",
      state: {
        user: props.profile,
      },
    });
  };
  localStorage.setItem("userName", props.profile);

  const homepage = () => {
    // window.location.replace("/");
    window.location.replace("https://skillgigs.com/");
    setDrawerStates({ visibles: false });
  };

  const searchedDeta = useSelector(
    (state) => state.onClearSearchReducer.Searchinfo
  );
  //

  const [search, setSearch] = searchedDeta;
  //
  const searchedValueDeta = useSelector(
    (state) => state.onClearSearchReducer.searchValueInfo
  );
  //

  const [isOpened, setisOpened] = searchedValueDeta;
  //

  const searchedCloseIconDeta = useSelector(
    (state) => state.onClearSearchReducer.searchCloseIconInfo
  );
  //

  const [isVisibleCloseIcon, setIsVisibleCloseIcon] = searchedCloseIconDeta;
  const [editModalStateRL, setEditModalStateRL] = useState(false);
  const [theLoading, setTheLoading] = useState(false);

  // useEffect for the Profile Edit Modal
  useEffect(() => {
    setTheLoading(false);
  }, [props.firstUser.profile]);

  useEffect(() => {}, [search, isOpened, isVisibleCloseIcon]);
  const clearSearchdata = () => {
    setSearch("");
    setisOpened(false);
    setIsVisibleCloseIcon(false);
    if (authToken) {
      WizardFlow === 1000 && history.push("/dashboard");
    } else {
      window.location.replace(`${BaseUrl}/`);
    }
  };

  const handleSkillGigLogoClick = () => {
    if (authToken) {
      WizardFlow === 1000 && history.push("/dashboard");
    } else {
      window.location.replace(`${BaseUrl}/`);
    }
  };

  useEffect(() => {
    setIsModalVisibleAuth(
      (initialPath == "login" || initialPath == "signup") &&
        window.innerWidth > 1000
    );
  }, []);

  const noti = (
    <Menu>
      <Menu.Item>hello</Menu.Item>
    </Menu>
  );
  // window.location.reload();

  // const ManageBidsMenu = (
  //   <Menu>
  //     <section className="header-manage-bids-menu">
  //       <div className="header-recieved-bids-text">
  //         <span>Received Bids</span>
  //         <span className="bids-count-header">3</span>
  //       </div>
  //       <div className="placed-bids-header">Placed Bids</div>
  //       <div>Retracted Bids</div>
  //     </section>
  //   </Menu>
  // );
  const handleVisibleNotifications = (visible) => {
    setDropVisibleNotifications(visible);
  };

  const showDisclaimer =
    JSON.parse(localStorage.getItem("showDisclaimer")) === null;
  const [windowDimensions, setWindowDimensions] = useState(
    getWindowDimensions()
  );
  function getWindowDimensions() {
    const { innerWidth: width, innerHeight: height } = window;
    return {
      width,
      height,
    };
  }
  useEffect(() => {
    function handleResize() {
      setWindowDimensions(getWindowDimensions());
    }
    window.addEventListener("resize", handleResize);
    return () => window.removeEventListener("resize", handleResize);
  }, []);

  const showMobileNavHeader = () => {
    if (window.innerWidth < 1000 && !isEmployerRoute()) return true;
    else if (isEmployerRoute() && window.innerWidth <= 1000 && authToken) return true;
    else return false;
  };

  let mobileViewHeader = showMobileNavHeader() && (
    <nav
      className={
        authToken && loggedInSearchBar
          ? "search-header-mobile-view searchHeaderMobileViewLoggedIn"
          : `search-header-mobile-view`
      }
    >
      <SearchBar
      extraClass={'pvt-market-header-style'}
        loggedInSearchBar={loggedInSearchBar}
        setloggedInSearchBar={setloggedInSearchBar}
      />
    </nav>
  );
  const setPath = localStorage.getItem("setPath");
  const historyPath = useHistory();

  // useEffect(() => {
  //   const handleBeforeUnload = () => {
  //     // Remove item from localStorage
  //     localStorage.removeItem('setPath');
  //   };

  //   // Add event listener for beforeunload
  //   window.addEventListener('beforeunload', handleBeforeUnload);

  //   // Cleanup function to remove event listener
  //   return () => {
  //     window.removeEventListener('beforeunload', handleBeforeUnload);
  //   };
  // }, []);

  useEffect(() => {
    const clearLocalStorageOnRouteChange = () => {
      localStorage.removeItem("setPath");
    };

    const unlisten = historyPath.listen(clearLocalStorageOnRouteChange);

    return () => {
      unlisten();
    };
  }, [historyPath]);

  const getMobileLogo = () => {
    return isEmployerLandingPage() ? (
      <Row
        align="flex-start"
        gutter={[2, 0]}
        wrap={false}
      >
        <Col>
          <a
            href={employerDetails?.webUrl || "https://www.shi.com"}
            target="_blank"
            rel="nonreferrer noreferrer"
          >
            <img
              src={getPictureUrl(employerDetails?.logo) || shiLogo}
              alt="emp"
              style={{ width: window.innerWidth < 400 ? "60px" : "70px" }}
            />
          </a>
        </Col>
        {/* {window.innerWidth < 1000 && ( */}
        <Col style={{ borderRight: "2px gray solid" }}> </Col>
        {/* )} */}
        <Col>
          <div
            style={{
              display: "flex",
              flexDirection: "row",
              alignItems: "center",
              height: "100%",
              padding: "0",
              paddingTop: "5px",
            }}
          >
            <img
              src={poweredByMob}
              alt="poweredBy"
              style={{ width: window.innerWidth < 400 ? "31px" : "41px" }}
            />
            <img
              src={SGMob}
              alt="logo"
              className="logo"
              onClick={handleSkillGigLogoClick}
              style={{ width: window.innerWidth < 400 ? "72px" : "82px" }}
            />
          </div>
        </Col>
      </Row>
    ) : (
      <>
        <img src={logo} alt="logo" className="logo" onClick={clearSearchdata} />
      </>
    );
  };

  const commonHeader = (
    <div className="wizard-head-dashboard" style={{ color: "white" }}>
      {showDisclaimer && setPath && talentPrivate ? (
        <div
          className="div-main"
          style={{
            width: "100%",
            position: "fixed",
            zIndex: "1",
            top: "30px",
            marginLeft: "-16px",
          }}
        >
          {windowDimensions.width < 700 ? (
            <Alert
              className={
                windowDimensions.width < 700 ? "disclaimer-mobile" : ""
              }
              message={
                <div>
                  <span className="private-div">Private</span>
                  <span className="candidate-div">
                    {" "}
                    will contain Gigs posted privately for you from private
                    employer
                  </span>
                </div>
              }
              closable
              onClose={() => localStorage.setItem("showDisclaimer", "false")}
            />
          ) : (
            ""
          )}
        </div>
      ) : (
        ""
      )}

      <div className="bars" onClick={showDrawer}>
        {/* <GiHamburgerMenu /> */}

        <img src={burgerMenu} alt="logo" className="logo" />
      </div>
      {/* <div style={{ flex: 1 }}></div> */}
      {window.innerWidth > 1000 && !isEmployerRoute() ? (
        <SearchBar />
      ) : isEmployerLandingPage() &&
        authToken != null &&
        window.innerWidth > 1240 ? (
        <SearchBar />
      ) : isEmployerRoute() &&
        window.innerWidth < 1240 &&
        window.innerWidth > 1000 ? (
        <div></div>
      ) : null}
      {/* <div style={{ flex: 1 }}></div> */}
      {window.innerWidth < 1000 && getMobileLogo()}
      <div className="headerMenuRight">
        <Link className="headerBtn" to="/">
          <div
            className="btn_home btn_home_two"

            // onClick={homepage}
          >
            <a href="https://skillgigs.com/">Home</a>
          </div>
        </Link>
        <Link className="headerBtn" to="/dashboard">
          <div className="dashboard">Dashboard</div>
        </Link>
        <Link className="headerBtn" to="/manageskilllisting">
          <div className="skillListing">
            {profile?.Skilllistings > 1 ? "Skill listings" : "Skill listing"}
          </div>
        </Link>
        <div
          className="headerBtn headerBtnIcon"
          onClick={() => {
            setDropVisibleNotifications(true);
            localStorage.setItem("isNotificationCheck", true);
            setisNotification(true);
            setIsCountVisible((prev) => ({ ...prev, notification: true }));
          }}
        >
          <Dropdown
            overlayClassName={`notification-dropdown ${
              !isNotification ? "notification-dropdown-message" : ""
            }`}
            overlay={
              <Notifications
                visible={setDropVisibleNotifications}
                isTabSwitch={isNotification}
                setIsTabSwitch={setisNotification}
              />
            }
            onVisibleChange={() =>
              handleVisibleNotifications(!dropVisibleNotifications)
            }
            placement="bottom"
            trigger={["click"]}
            visible={dropVisibleNotifications}
            // disabled={dropDisable}
            arrow
            // autoFocus={true}
          >
            <Tooltip title="Notification">
              <Badge
                offset={[-4, 8]}
                size="small"
                count={
                  isCountVisible.notification ? notificationUnreadCount : 0
                }
                color="#fbdf50"
              >
                <img src={notificationIcon} alt="" />
                {/* <CgBell color="white" fontSize={25} /> */}
              </Badge>
            </Tooltip>
          </Dropdown>
        </div>
        <div
          className="headerBtn headerBtnIcon"
          onClick={(e) => {
            setDropVisibleNotifications(true);
            localStorage.setItem("isNotificationCheck", false);
            setisNotification(false);
            setIsCountVisible((prev) => ({ ...prev, message: true }));
          }}
        >
          <Tooltip title="Message">
            <Badge
              offset={[-4, 8]}
              size="small"
              count={
                counts?.Unread && isCountVisible.message ? counts?.Unread : 0
              }
              color="db2828"
              // onClick={() => history.push("/messages")}
            >
              <img src={messageIcon} alt="" />
              {/* <MailOutlined
                style={{ color: "white", fontSize: 25 }}
                fontSize={25}
              /> */}
            </Badge>
          </Tooltip>
        </div>
      </div>
      {window.innerWidth < 1000 && (
        <div
          className={
            isEmployerLandingPage()
              ? "empMobileDefaultButtons"
              : "mobileDefaultButtons"
          }
        >
          <div className="bellIcon">
            <Badge
              offset={[-4, 14]}
              size="small"
              count={isCountVisible.notification ? notificationUnreadCount : 0}
              color="#fbdf50"
              onClick={() => {
                // history.push("/notifications");
                setIsCountVisible((prev) => ({ ...prev, notification: true }));
                history.push({
                  pathname: `/notifications`,
                  state: {
                    fromMobileIsTabSwitch: true,
                  },
                });
              }}
            >
              <img src={notificationIcon} alt="" />
              {/* <BsBell color="white" fontSize={21} /> */}
            </Badge>
          </div>
          <div className="messageIconHeader">
            <Badge
              offset={[-4, 14]}
              size="small"
              count={
                counts?.Unread && isCountVisible.message ? counts?.Unread : 0
              }
              color="db2828"
              onClick={() => {
                // history.push("/notifications");
                setIsCountVisible((prev) => ({ ...prev, message: true }));
                history.push({
                  pathname: `/notifications`,
                  state: {
                    fromMobileIsTabSwitch: false,
                  },
                });
              }}
            >
              <img src={messageIcon} alt="" />
              {/* <MdOutlineMessage color="white" fontSize={23} /> */}
            </Badge>
          </div>
        </div>
      )}
    </div>
  );

  const getWebLogo = () => {
    return isEmployerLandingPage() ? (
      window.innerWidth > 1123 ? (
        <Row
          style={{ display: "flex" }}
          align="end"
          gutter={[20, 0]}
          wrap={false}
        >
          <Col span={7} style={{ cursor: "pointer" }}>
            <a
              href={employerDetails?.webUrl || "https://www.shi.com"}
              target="_blank"
              rel="nonreferrer noreferrer"
            >
              <img
                src={getPictureUrl(employerDetails?.logo) || shiLogo}
                alt="emp"
              />
            </a>
          </Col>
          <Col span={1} style={{ borderRight: "2px gray solid" }}>
            {" "}
          </Col>
          <Col span={15}>
            <div
              style={{
                display: "flex",
                flexDirection: "row",
                alignItems: "center",
                height: "100%",
                padding: "0",
                paddingTop: "4px",
              }}
            >
              <img src={poweredByWeb} alt="poweredBy" />
              <img
                src={logo}
                alt="logo"
                className="logo"
                onClick={handleSkillGigLogoClick}
              />
            </div>
          </Col>
        </Row>
      ) : (
        getMobileLogo()
      )
    ) : (
      <>
        <img src={logo} alt="logo" className="logo" onClick={clearSearchdata} />
      </>
    );
  };

  useEffect(() => {
    if (initialPath == "credentialing") {
      setCheck(subrout);
    } else if (initialPath == "login") {
      setCheck(initialPath);
    } else if (initialPath == "search") {
      setCheck(initialPath);
    } else if (initialPath == "terms") {
      setCheck(initialPath);
    } else if (initialPath == "details") {
      setCheck(initialPath);
    } else if (initialPath == "forgot") {
      setCheck(initialPath);
    } else if (initialPath == "") {
      setCheck(initialPath);
    } else if (initialPath == "dashboard") {
      setCheck(initialPath);
    } else {
      setCheck(props.stage);
    }

    // dropdown disable state on pages
    if (
      initialPath === "talentemployer" ||
      initialPath === "profession" ||
      initialPath === "read-rules" ||
      initialPath === "profile-instruction" ||
      initialPath === "effective-profile-info" ||
      initialPath === "role-experience" ||
      initialPath === "resume-upload" ||
      initialPath === "basic-profile-success" ||
      initialPath === "create-skilllisting-signup"
    ) {
      setDropDisable(true);
    } else setDropDisable(false);
  }, [initialPath, props.stage]);

  if (authToken != null) {
    headerContent = (
      <React.Fragment>
        <div className="wizard-head-dashboard" style={{ color: "white" }}>
          <Link to="/dashboard">
            <img src={logo} alt="logo" className="logo" />
          </Link>
        </div>
        {/* <div className="Avatar">
          <div className="Avat">{userName?.charAt(0).toUpperCase()}</div>
        </div> */}
      </React.Fragment>
    );
  }

  switch (initialPath) {
    case "talentemployer":
    case "profession":
    case "role-experience":
    case "resume-upload":
    case "basic-profile-success":
    case "create-skilllisting-signup":
    case "read-rules":
    case "profile-instruction":
    case "effective-profile-info":
    case "name":
    case "location":
    case "discipline":
    case "speciality":
    case "mobileno":
    case "explever":
    case "workAuthorization":
    case "license":
    case "resume-upload-v2":
    case "bls-doc":
    case "acls-doc":
    case "any-career-gap":
    case "state-lisence-doc":
    case "social-security-doc":
    case "driver-license-doc":
    case "create-skilllisting-gig-type":
    case "create-skilllisting-title":
    case "create-skilllisting-skill":
    case "create-skilllisting-location":
    case "create-skilllisting-salary":
    case "create-skilllisting-date":
    case "workPlacePolicy":
      headerContent = (
        <>
          {window.innerWidth < 1000 && (
            isEmployerLandingPage() ? getWebLogo() :
            <div
              className="wizard-head-dashboard-guest"
              style={{ color: "white" }}
            >
              <img src={SkillGigss} alt="logo" className="logo-guest" />
            </div>
          )}
        </>
      );
      mobileViewHeader = <></>;
      break;

    default:
      headerType = "default";
      if (!authToken) {
        headerContent = (
          <div className="wizard-head-dashboard" style={{ color: "white" }}>
            <div className="bars" onClick={showDrawers}>
              <GiHamburgerMenu />
            </div>
            {window.innerWidth < 1000 ? getMobileLogo() : <div></div>}
            {window.innerWidth > 1000 && !isEmployerLandingPage() ? (
              <SearchBar />
            ) : null}
            <section className="logout-buttons-header">
              {isEmployerLandingPage() ? (
                 <div
                 className="btn_home"
               ></div>
              ):(
                <div
                  className="btn_home"
                  // onClick={homepage}
                >
                  <a href="https://skillgigs.com/">Home</a>
                </div>
               )} 
              {/* <div className="btn_sign_In" onClick={()=>{history.push('/login')}}> */}
              <div className="btn_home btn_home_Hover" onClick={signInUser}>
                Sign in
              </div>
              <div className="theAlign">
                <div
                  className="clickSkillListing Join-Guest-user"
                  onClick={joinUser}
                >
                  {window.innerWidth > 1000
                    ? "Join"
                    : initialPath == "signup"
                    ? "Sign in"
                    : "Join"}
                </div>
              </div>
            </section>
          </div>
        );
      } else if (authToken && (WizardFlow === 1000 || WizardFlow === 6)) {
        headerContent = commonHeader;
      } else {
        headerContent = (
          <>
            {window.innerWidth < 1000 && (
              <div
                className="wizard-head-dashboard-guest"
                style={{ color: "white" }}
              >
                <img src={logo} alt="logo" className="logo-guest" />
              </div>
            )}
          </>
        );
      }
      break;
  }

  const { placement, visible } = drawerState;
  const { placements, visibles } = drawerStates;

  const handleUpdateSkillListing = (state) => {
    setEditModalState(state);
  };

  const titleDrawer = (
    <div style={{ backgroupColor: "rgba(17, 137, 54, 0.2)" }}>
      <div className="title-drawer-mobileView">
        {/* <div className="Avat">{userName.charAt(0).toUpperCase()}</div> */}
        {/* <Badge dot={true} status={"success" || "default"}>
          <div className="circle-wrap-p">
            <ProfileAvatar
              name={userName}
              location="Header"
              // profilePic={props.profileImg.PictureUrl}
              fontSize="1.25rem"
              onClick={() => {
                history.push("/profile");
                setDrawerState(false);
              }}
              headerImage="headerImage"
            />
          </div>
        </Badge> */}
        <div
          className={
            IsOnline ? "profileAvatarDesktop" : "profileAvatarDesktopOffline"
          }
          onClick={() => {
            history.push("/profile");
            setDrawerState({ ...drawerState, visible: false });
          }}
        >
          <ProfileAvatar
            name={userName}
            isOnline={IsOnline}
            size={60}
            offset={[-8, 0]}
            src={`${process.env.REACT_APP_S3_URL}/${props.profileImg.PictureUrl}`}
          />
        </div>
        <div className="title-data">
          <div className="username-header">
            <span>
              {props?.profile
                ? props.profileImg?.FirstName?.charAt(0).toUpperCase() +
                  props.profileImg?.FirstName?.slice(1).toLowerCase() +
                  " " +
                  props.profileImg?.LastName?.charAt(0).toUpperCase() +
                  props.profileImg?.LastName?.slice(1).toLowerCase()
                : ""}
            </span>
            <span className="star-title-BM">
              <IoIosStar color="#ffba08" style={{ fontSize: "1.13rem" }} />
            </span>
            <span className="rating-title-BM">
              {props.firstUser.Rating || DefaultsRating}
            </span>
          </div>
          <div className="hired">{props.profileImg.Discipline}</div>
          <div className="profession-change">
            {/* <span className="profession-title">{profession}</span> */}
            <span
              className="change-title"
              onClick={() => {
                history.push("/profile");
                setDrawerState({ ...drawerState, visible });
              }}
            >
              <u>Change</u>
            </span>
          </div>
        </div>
      </div>
      <Timer />
    </div>
  );

  const titleDrawerlogin = (
    <div>
      <div className="DrowerHeader">
        <img src={avatar} />
        <div>
          <div className="Guestuserfont">Guest User</div>
          <div className="welcomefont">Welcome to SkillGigs</div>
        </div>
      </div>
    </div>
  );

  // useUpdateLogger("props hey", props);

  return (
    <React.Fragment>
      {/* {!emailVerified && id && WizardFlow > 5 && authToken && (
        <EmailVerification />
      )} */}
      {sbarVisible && (
        <Sidebar changeStage={props.changeStage} hide={clicked} />
      )}
      {window.innerWidth > 1000 ? (
        <Modal
          bodyStyle={{ padding: 0 }}
          width="24.37rem"
          // visible={isModalVisibleAuth}
          visible={
            (initialPath == "login" && !isModalVisibleAuthRestPassword) ||
            initialPath == "signup"
              ? true
              : isModalVisibleAuth
          }
          closable={false}
          footer={null}
          // style={{ top: 45, padding: 0 }}
          onCancel={modalCancel}
          maskClosable={
            initialPath == "login" || initialPath == "signup" ? false : true
          }
          centered={true}
          // destroyOnClose={true}
        >
          <SignUpLogin employerTitle={employerTitle} />
        </Modal>
      ) : (
        <></>
      )}
      <div className={`header ${headerType === "default" && "black-header"}`}>
        {/* Logo for web */}
        {!sgLogo ? (
          getWebLogo()
        ) : (
          <>
            <Drawer
              // title={titleDrawer}
              placement="left"
              closable={false}
              onClose={onClose}
              visible={visible}
              className="burgerMenuDrawerMobileView"
              width="18.125rem"
              // key={placement}
            >
              <NewProfileHeader
                hide={onClose}
                setEditModalState={setEditModalState}
                visible={visible}
                setEditModalStateRL={setEditModalStateRL}
              />
            </Drawer>
            {window.innerWidth < 1000 && (
              <ModalEdit
                editModalState={editModalState}
                setEditModalState={setEditModalState}
                handleUpdateSkillListing={handleUpdateSkillListing}
                id={oneSkillListing || profile?.SkillListings?.[0]}
                idSkill={skillId}
              />
            )}
            {!theLoading && (
              <Modal
                centered
                footer={false}
                width="47.50rem"
                visible={editModalStateRL}
                destroyOnClose={true}
                wrapClassName="modalEditSkillListing profileModelRoleExperience"
                onCancel={() => setEditModalStateRL(false)}
                title={
                  <div className="createSkillistingTitle">Edit details</div>
                }
              >
                <RoleExperienceNew
                  setEditModalStateRL={setEditModalStateRL}
                  talentProfile={true}
                  setTheLoading={setTheLoading}
                />
              </Modal>
            )}
          </>
        )}
        {headerContent}
        {authToken &&
          window.innerWidth > 1000 &&
          initialPath !== "effective-profile-info" &&
          initialPath !== "profile-instruction" &&
          initialPath !== "read-rules" &&
          (WizardFlow === 1000 || WizardFlow === 6) && (
            <>
              <Dropdown
                overlayClassName="header-dropdown"
                overlay={
                  <NewProfileHeader
                    hide={hideDrop}
                    visible={dropVisible}
                    setEditModalStateRL={setEditModalStateRL}
                  />
                }
                onVisibleChange={() => handleVisibleChange(!dropVisible)}
                placement="bottomRight"
                trigger={["click"]}
                visible={dropVisible}
                disabled={dropDisable}
                // arrow
              >
                <div
                  className={
                    IsOnline
                      ? "profileAvatarDesktop"
                      : "profileAvatarDesktopOffline"
                  }
                >
                  <ProfileAvatar
                    name={userName}
                    isOnline={IsOnline}
                    src={`${process.env.REACT_APP_S3_URL}/${props.profileImg.PictureUrl}`}
                  />
                </div>
              </Dropdown>
              {!theLoading && (
                <Modal
                  centered
                  footer={false}
                  width="47.50rem"
                  visible={editModalStateRL}
                  destroyOnClose={true}
                  wrapClassName="modalEditSkillListing profileModelRoleExperience"
                  onCancel={() => setEditModalStateRL(false)}
                  title={
                    <div className="createSkillistingTitle">Edit details</div>
                  }
                >
                  <RoleExperienceNew
                    setEditModalStateRL={setEditModalStateRL}
                    talentProfile={true}
                    setTheLoading={setTheLoading}
                  />
                </Modal>
              )}
            </>
          )}

        <Drawer
          title={titleDrawerlogin}
          placement="left"
          closable={false}
          onClose={onCloses}
          visible={visibles}
          key={placements}
          className="burgerMenuDrawerMobileView"
          width="18.125rem"
        >
          <div className="clickSkillListinglogin" onClick={joinUser}>
            Join SkillGigs
          </div>
          <br></br>
          <div className="Mob_Btn">
            <span className="signIN" onClick={signInUser}>
              Sign in
            </span>
            <br></br>
            <span className="General_btn">General</span>
            <div class="Line-109"></div>
            <span
              className="homeBtn"
              // onClick={homepage}
            >
              <a href="https://skillgigs.com/">Home</a>
            </span>
          </div>
        </Drawer>
      </div>
      {mobileViewHeader}
    </React.Fragment>
  );
};

const mapStateToProps = (state) => {
  return {
    profile: state.profileReducer.user.name,
    profileImg: state.profileReducer.user.profile,
    firstUser: state.profileReducer.user,
    error: state.profileReducer.error,
    isModalVisibleAuth: state.authReducer.isModalVisibleAuth,
    authToken: state.authReducer.user.authToken,
  };
};
const mapDispatchToProps = (dispatch) => {
  return {
    profileDetails: (talentToken) => {
      dispatch(fetchUserProfile(talentToken)); // action dispatched
    },
    setIsModalVisibleAuth: (params) => {
      dispatch(isModalVisibleAuth(params));
    },
  };
};

export default connect(mapStateToProps, mapDispatchToProps)(Header);
