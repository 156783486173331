import { put, call, takeEvery, takeLatest, takeLeading } from "@redux-saga/core/effects";
import { fetchInterviewAPI, updateInterviewAPI } from "../../API/API";
import { types } from "../action-types";
import {
  catchError,
  fetchDetailPlaceBid,
  fetchDetailReceivedBid,
  fetchInterviewFailure,
  fetchInterviewSuccess,
  InterviewScheduleAcceptedfail,
  InterviewScheduleAcceptedSuccess,
} from "../actions";
function* fetchInterviewSaga() {
  // 
  try {
    // 
    const response = yield call(() => fetchInterviewAPI()); // API Backend
    // 
    if (response.status == 200) {
      yield put(fetchInterviewSuccess(response.data.data.body.data));
    } else {
      yield put(fetchInterviewFailure(response));
    }
  } catch (error) {
    yield put(fetchInterviewFailure(error));
    yield put(catchError(error));
  }
}

function* InterviewScheduleSaga(params) {
  try {
    const response = yield call(() => updateInterviewAPI(params)); // API Backend

    if (response.status == 200) {
      // yield put(fetchDetailPlaceBid(response.data._id));
      if(params?.payload?.placedBid && params?.payload?.idSearch) {
        yield put(fetchDetailPlaceBid(params.payload.idSearch));
      }
      else if(params?.payload?.receivedBid && params?.payload?.idSearch) {
        yield put(fetchDetailReceivedBid(params.payload.idSearch));
      }


      yield put(InterviewScheduleAcceptedSuccess(response));
    }
  } catch (error) {
    yield put(InterviewScheduleAcceptedfail(error));
  }
}

export function* watchInterviewSaga() {
  yield takeLeading(types.FETCH_INTERVIEW_INIT, fetchInterviewSaga);
}

export function* watchInterviewScheduleSaga() {
  yield takeLeading(types.INTERVIEW_ACCEPTED_INIT, InterviewScheduleSaga);
}
