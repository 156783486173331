import React, { useState, useEffect, useRef } from "react";
// antd
import {
  Menu,
  Select,
  Space,
  Dropdown,
  Button,
  message,
  Radio,
  Tag,
  Switch,
  Pagination,
  Input,
  InputNumber,
  Spin,
  Divider,
  Form,
  Drawer,
} from "antd";
// APPOLO CLIENT
import {
  ApolloClient,
  InMemoryCache,
  ApolloProvider,
  useQuery,
  ApolloLink,
  gql,
  useLazyQuery,
} from "@apollo/client";
import { RestLink } from "apollo-link-rest";
// ACTIONS
import {
  fetchGigs,
  SearchResponse,
  SearchResponseRequested,
  SearchValue,
  fetchSearchSkill,
  saveSearchLocation,
  SearchSuggestions,
  UpdateSearchFilter,
} from "../../redux/actions";
import { HttpLink } from "apollo-link-http";
// ICONS
import {
  DownOutlined,
  SearchOutlined,
  UserAddOutlined,
  UserOutlined,
  PlusOutlined,
} from "@ant-design/icons";


// ICONS
import { ImPlus } from "react-icons/im";
import { FaBolt } from "react-icons/fa";
import { GiRoundStar } from "react-icons/gi";
import { ImDisplay } from "react-icons/im";
import { AiFillFile } from "react-icons/ai";
import { FaCoffee } from "react-icons/fa";
import { AiOutlineDollar } from "react-icons/ai";
import { BiFilterAlt } from "react-icons/bi";
import { BsArrowDownUp } from "react-icons/bs";
import { LoadingOutlined } from "@ant-design/icons";
import { debounce, timeout } from "../../shared/helpers";

// ANTD
import { Progress } from "antd";

import GigsCard from "../SearchGigs/GigsCard";
import SearchGigs from "../SearchGigs/SearchGigs";

import { connect, useSelector } from "react-redux";

// redux Actions
import {
  fetchUserSkillListing,
  saveSearchSkill,
  fetchKeySearchLocation,
} from "../../redux/actions";
import MediaQuery from "react-responsive";
import SearchBar from "../SearchGigs/SearchBar";
import { useHistory } from "react-router-dom";
import { radio } from "aws-amplify";
import MobileFilters from "../SearchGigs/components/MobileFilters";

const SearchResults = (props) => {
  const [gigs, setGigs] = useState([]);
  const [gigSuggestions, setGigSuggestions] = useState({});
  const search = props.value;
  const history = useHistory();

  const [noDataView, setNoDataView] = useState("");
  const [jobValue, setJobValue] = useState();
  const [WorkplacePolicyValue, setWorkplacePolicyValue] = useState();
  const [skillsinput, setSkillsInput] = useState();
  const [locationValue, setLocationValue] = useState();
  const [skillValue, setSkillValue] = useState();
  const [sortValueMobile, setSortValueMobile] = useState("Most Relevant");
  const sortValue = props.sortValue;
  const [budgetMin, setBudgetMin] = useState(props.minbudgetQuery);
  const [budgetMax, setBudgetMax] = useState(props.maxbudgetQuery);
  const [budgetType, setBudgetType] = useState();
  const [dimensions, setDimensions] = useState(window.innerWidth);
  // VISIBLE
  const [visibleJobMenu, setVisibleJobMenu] = useState(false);
  const [visibleSkillMenu, setVisibleSkillMenu] = useState(false);
  const [visibleLocationMenu, setVisibleLocationMenu] = useState(false);
  const [visibleBudgetMenu, setVisibleBudgetMenu] = useState(false);
  const [visible, setVisible] = useState(false);
  // const [filterDrawer, setFilterDrawer] = useState(false);
  // const [sortDrawer, setSortDrawer] = useState(false);

  // FILTER VALUE FOR QUERY
  const [location, setLocation] = useState([]);
  // const [jobQuery, setjobQuery] = useState();
  const { jobQuery } = props;
  const skillQuery = props.skillQuery;
  const locationQuery = props.locationQuery;
  const minbudgetQuery = props.minbudgetQuery;
  const maxbudgetQuery = props.maxbudgetQuery;
  const budgetQuery = props.budgetQuery;
  const budgetTypeQuery = props.budgetTypeQuery;
  const pageNo = props.pageNo;
  const filterDrawer = props.filterDrawer;
  const sortDrawer = props.sortDrawer;
  const activeFilters = props.activeFilters;
  const WorkplacePolicy = props.WorkplacePolicy
  // 
  // const reducerQuery = useSelector((state) => state.searchGigsReducer);
  // const [skillQuery, setskillQuery] = useState();
  // const [locationQuery, setlocationQuery] = useState(null);
  // const [minbudgetQuery, setminbudgetQuery] = useState();
  // const [maxbudgetQuery, setmaxbudgetQuery] = useState();
  const [sortingQuery, setsortingQuery] = useState();
  const [latitude, setLatitude] = useState();
  const [longitude, setLongitude] = useState();
  const [resultCount, setresultCount] = useState(0);
  // const [pageNo, setPageNo] = useState();
  // const [budgetTypeQuery, setBudgetTypeQuery] = useState("Weekly");
  const [isLocation, setIsLocation] = useState(true);
  const [myLocationQuery, setMyLocationQuery] = useState(false);
  const [isFilter, setIsFilter] = useState(false);
  const [validation, setValidation] = useState(false);
  const inputref = useRef(0);
  const [isClear, setIsClear] = useState(0);
  // const [budgetQuery, setBudgetQuery] = useState(false);
  //// Search Gigs Start//////////
  const ProfessionCode = useSelector(
    (state) => state.profileReducer.user.profile.ProfessionCode
  )
  // 
  const BackendApiBaseUrl = process.env.REACT_APP_API_URL;
  let searchquery = {};
  if (jobQuery) {
    searchquery.JobOrderTypeName = jobQuery;
  }
  if (WorkplacePolicy) {
    searchquery.WorkplacePolicy = WorkplacePolicy;
  }
  if (skillQuery) {
    searchquery.Skills = [skillQuery];
  }
  if (locationQuery) {
    searchquery.Location = { Location: locationQuery };
  }
  if (minbudgetQuery) {
    searchquery.BudgetMin = minbudgetQuery;
  }
  if (maxbudgetQuery) {
    searchquery.BudgetMax = maxbudgetQuery;
  }
  if (sortValue === "Most Relevant") {
    searchquery._sort = "RELEVANT";
  }
  if (sortValue === "Most Recent") {
    searchquery._sort = "MOST_RECENT";
  }
  if (sortValue === "Pay Package High to Low") {
    searchquery._sort = "PAY_HI_TO_LOW";
  }
  if (sortValue === "Pay Package Low to High") {
    searchquery._sort = "PAY_LOW_TO_HI";
  }
  if (sortValue === "Ending Soon") {
    searchquery._sort = "ENDING";
  }
  if (myLocationQuery) {
    searchquery.NearBy = {
      longitude: longitude,
      latitude: latitude,
      maxDistance: 50,
    };
  }
  if (budgetTypeQuery === "Hourly") {
    // 
    searchquery.ShowRateIn = "HR";
    searchquery.FilterBudget = "HR";
  }
  if (budgetTypeQuery === "Weekly") {
    searchquery.ShowRateIn = "WK";
    searchquery.FilterBudget = "WK";
    // 
  }
  if (pageNo) {
    searchquery._page = pageNo;
  }
  searchquery._perPage = 24;
  // searchquery.BudgetType = "Hour";

  // if (!props.value) {
  //   searchquery.all = "rn";
  //   hitQuery();
  // }
  // searchquery.all = props.value ? props.value : "rn";
  searchquery.all = props.value;
  // searchquery.ShowRateIn = "WK";
  // searchquery.FilterBudget = "WK";
  // const [hitQuery, { loading, error, data }] = useLazyQuery(query, {
  //   variables: searchquery,
  // });
  const httpLink = new HttpLink({
    uri: `${BackendApiBaseUrl}/query`,
  });

  const client = new ApolloClient({
    // uri: `${BackendApiBaseUrl}/query`,
    cache: new InMemoryCache(),
    link: ApolloLink.from([httpLink]),
  });

  // DIMENTIONS
  const checkDimensions = () => {
    setDimensions(window.innerWidth);
  };
  useEffect(() => {
    window.addEventListener("resize", checkDimensions);
    if (props.value > 0) {
      // debounce(hitQuery());
    }
    // if (props.value.length > 0) {
    //   props.SearchResponseRequested(loading);
    // }
  }, []);
  useEffect(() => {
    if (!visibleJobMenu && !jobQuery) {
      setValidation();
      setJobValue();
    }
    if (!visibleSkillMenu && !skillQuery) {
      setValidation();
      setSkillValue();
    }
    if (!visibleLocationMenu && !locationQuery) {
      setValidation();
      setLocationValue();
    }
    if (
      !visibleBudgetMenu &&
      !budgetTypeQuery &&
      !minbudgetQuery &&
      !maxbudgetQuery
    ) {
      setValidation();
      setBudgetType(null);
      setBudgetMin(null);
      setBudgetMax(null);
    }
  }, [
    visibleBudgetMenu,
    visibleJobMenu,
    visibleSkillMenu,
    visibleLocationMenu,
  ]);

  // useEffect(() => {
  //   props.searchResponse(data);
  //   // // 
  // }, [data, props.value, loading]);

  useEffect(() => {
    // 
    setGigSuggestions(props.gigs?.gigs?.gigs?.suggestion);
    setresultCount(resultCount + 1);
  }, [props.gigs]);

  useEffect(() => {
    // 
  }, [validation]);
  // useEffect(() => {
  //   debounce(hitQuery());
  //   // saving loading state to reducer from graphQL
  //   if (props.value.length > 0) {
  //     props.SearchResponseRequested(loading);
  //   }
  //   // else if (search.length === 0) {
  //   //   history.go(-1);
  //   // }
  //   // 
  // }, [props.value]);
  // useEffect(() => {
  //   hitQuery();
  // }, [props.IsFavoritesValue]);

  useEffect(() => {
    // 
    // debounce(hitQuery());
    if (props.value.length > 0) {
      // props.SearchResponseRequested(loading);
    }
    if (navigator.geolocation) {
      navigator.geolocation.watchPosition(function (position) {
        // 
        // 
        setLatitude(position.coords.latitude);
        setLongitude(position.coords.longitude);
        setIsLocation(false);
      });
    }
    // COUNT APPLIED FILTERS
    if (
      jobQuery ||
      skillQuery ||
      locationQuery ||
      (budgetTypeQuery &&
        (minbudgetQuery > 0 ||
          maxbudgetQuery > 0 ||
          (minbudgetQuery > 0 &&
            maxbudgetQuery > 0 &&
            maxbudgetQuery > minbudgetQuery)))
    ) {
      props.UpdateSearchFilter("activeFilters", 1);
    }
    if (
      (jobQuery &&
        (locationQuery ||
          skillQuery ||
          (budgetTypeQuery && (minbudgetQuery || maxbudgetQuery)) ||
          (budgetTypeQuery && minbudgetQuery && maxbudgetQuery))) ||
      (skillQuery &&
        (locationQuery ||
          jobQuery ||
          (budgetTypeQuery && (minbudgetQuery || maxbudgetQuery)) ||
          (budgetTypeQuery && minbudgetQuery && maxbudgetQuery)))
    ) {
      props.UpdateSearchFilter("activeFilters", 2);
    }
    if (
      (jobQuery && skillQuery && locationQuery) ||
      (jobQuery &&
        skillQuery &&
        budgetQuery &&
        (minbudgetQuery ||
          maxbudgetQuery ||
          (minbudgetQuery && maxbudgetQuery))) ||
      (jobQuery &&
        locationQuery &&
        budgetQuery &&
        (minbudgetQuery ||
          maxbudgetQuery ||
          (minbudgetQuery && maxbudgetQuery)))
    ) {
      props.UpdateSearchFilter("activeFilters", 3);
    }
    if (
      jobQuery &&
      budgetQuery &&
      skillQuery &&
      locationQuery &&
      (minbudgetQuery || maxbudgetQuery || (minbudgetQuery && maxbudgetQuery))
    ) {
      props.UpdateSearchFilter("activeFilters", 4);
    }
  }, [
    props.value,
    locationQuery,
    myLocationQuery,
    skillQuery,
    jobQuery,
    minbudgetQuery,
    maxbudgetQuery,
    budgetTypeQuery,
    sortValue,
    pageNo,
    WorkplacePolicy,
  ]);
  // 
  //// Search Gigs End//////////

  useEffect(() => {
    setGigs(props.gigs?.gigs?.gigs.data);
    // 
  }, [props.gigs]);

  useEffect(() => {
    // 
    // 
    // 
  }, [gigs]);
  // 
  useEffect(() => { }, [props.value]);

  function handleButtonClick(e) {
    message.info("Click on left button.");
    // 
  }

  function handleMenuClick(e) {
    message.info("Click on menu item.");
    // 
  }

  const { Option } = Select;

  function handleChange(value) {
    // 
  }

  const onApply = (value, filter) => {
    if (jobValue && filter === "jobMenu") {
      setVisibleJobMenu(false);
      setVisibleBudgetMenu(false);
      setVisibleLocationMenu(false);
      setVisibleSkillMenu(false);
      setIsFilter(true);
      props.UpdateSearchFilter("jobQuery", value);
      // 
      props.UpdateSearchFilter("pageNo", "1");
      setValidation();
    }
    if (filter === "jobMenu" && !value) {
      setValidation("job");
      // setTimeout(() => setValidation(false), 6000);
    }
    if (filter === "skillMenu" && value) {
      setVisibleSkillMenu(false);
      setVisibleJobMenu(false);
      setVisibleLocationMenu(false);
      setVisibleSkillMenu(false);
      props.UpdateSearchFilter("skillQuery", value);
      setIsFilter(true);
      // 
      props.UpdateSearchFilter("pageNo", "1");
      setValidation();
    }
    if (filter === "skillMenu" && !value) {
      setValidation("skill");
      // setTimeout(() => setValidation(false), 4000);
    }
    if (filter === "locationMenu" && value) {
      setVisibleLocationMenu(false);
      setVisibleJobMenu(false);
      setVisibleBudgetMenu(false);
      setVisibleSkillMenu(false);
      props.UpdateSearchFilter("locationQuery", value);
      setIsFilter(true);
      // 
      props.UpdateSearchFilter("pageNo", "1");
      setValidation();
    }
    if (filter === "locationMenu" && !value) {
      setValidation("location");
      // setTimeout(() => setValidation(false), 4000);
    }
    if (
      (budgetType && budgetMin > 0 && !budgetMax) ||
      (budgetType && budgetMax > 0 && !budgetMin && filter === "budgetMenu")
    ) {
      setVisibleBudgetMenu(false);
      setVisibleJobMenu(false);
      setVisibleLocationMenu(false);
      setVisibleSkillMenu(false);
      props.UpdateSearchFilter("minbudgetQuery", budgetMin);
      props.UpdateSearchFilter("maxbudgetQuery", budgetMax);
      props.UpdateSearchFilter("budgetTypeQuery", budgetType);
      props.UpdateSearchFilter("budgetQuery", true);
      setIsFilter(true);
      // 
      // 
      props.UpdateSearchFilter("pageNo", "1");
      setValidation();
    }
    if (
      budgetType &&
      Number(budgetMin) > 0 &&
      Number(budgetMax) > 0 &&
      Number(budgetMin) < Number(budgetMax) &&
      filter === "budgetMenu"
    ) {
      setVisibleBudgetMenu(false);
      setVisibleJobMenu(false);
      setVisibleLocationMenu(false);
      setVisibleSkillMenu(false);
      props.UpdateSearchFilter("minbudgetQuery", budgetMin);
      props.UpdateSearchFilter("maxbudgetQuery", budgetMax);
      props.UpdateSearchFilter("budgetTypeQuery", budgetType);
      props.UpdateSearchFilter("budgetQuery", true);
      setIsFilter(true);
      // 
      // 
      props.UpdateSearchFilter("pageNo", "1");
      setValidation();
    }
    if (filter === "budgetMenu" && !value) {
      setValidation("budget");
    }
    if (
      (filter === "budgetMenu" &&
        Number(budgetMin) > 0 &&
        Number(budgetMax) > 0 &&
        Number(budgetMin) >= Number(budgetMax)) ||
      (filter === "budgetMenu" &&
        Number(budgetMin) <= 0 &&
        Number(budgetMax) <= 0)
    ) {
      setValidation("budget");
    }
    if (WorkplacePolicyValue && filter === "WorkplaceMenu") {
      setVisibleJobMenu(false);
      setVisibleBudgetMenu(false);
      setVisibleLocationMenu(false);
      setVisibleSkillMenu(false);
      setIsFilter(true);
      props.UpdateSearchFilter("WorkplacePolicy", value);
      setValidation();
    }
    if (filter === "WorkplaceMenu" && !value) {
      setValidation("workplace");
      // setTimeout(() => setValidation(false), 6000);
    }
  };

  const onClear = (filter) => {
    // 
    if (filter === "jobMenu") {
      // setVisibleJobMenu(false);
      setJobValue();
      props.UpdateSearchFilter("jobQuery", null);
      setIsFilter(false);
      setValidation();
    }
    if (filter === "skillMenu") {
      // setVisibleSkillMenu(false);
      props.UpdateSearchFilter("skillQuery", null);
      setSkillValue();
      setIsFilter(false);
      setValidation();
    }
    if (filter === "locationMenu") {
      // setVisibleLocationMenu(false);
      props.UpdateSearchFilter("locationQuery", null);
      setLocationValue();
      setIsFilter(false);
      setValidation();
    }
    if (filter === "budgetMenu") {
      // setVisibleBudgetMenu(false);
      setBudgetType(null);
      setBudgetMin(null);
      setBudgetMax(null);
      props.UpdateSearchFilter("minbudgetQuery", null);
      props.UpdateSearchFilter("maxbudgetQuery", null);
      props.UpdateSearchFilter("budgetTypeQuery", null);
      props.UpdateSearchFilter("budgetQuery", false);
      setIsFilter(false);
      setValidation();
    }
    if (filter === "WorkplaceMenu") {
      setWorkplacePolicyValue();
      props.UpdateSearchFilter("WorkplacePolicy", null);
      setIsFilter(false);
      setValidation();
    }
    props.UpdateSearchFilter("pageNo", "1");
    props.UpdateSearchFilter("sortValue", "Most Relevant");
  };

  const clearAllMobile = () => {
    setIsClear(isClear + 1);
    // document.getElementById("mobile-filter").reset();
    props.UpdateSearchFilter("jobQuery", null);
    props.UpdateSearchFilter("skillQuery", null);
    props.UpdateSearchFilter("locationQuery", null);
    props.UpdateSearchFilter("sortValue", "Most Relevant");
    props.UpdateSearchFilter("minbudgetQuery", null);
    props.UpdateSearchFilter("maxbudgetQuery", null);
    props.UpdateSearchFilter("budgetQuery", false);
    props.UpdateSearchFilter("budgetTypeQuery", null);
    // props.UpdateSearchFilter("filterDrawer", false);
    props.UpdateSearchFilter("sortDrawer", false);
    props.UpdateSearchFilter("isBudgetContract", false);
    props.UpdateSearchFilter("isBudgetPermanent", false);
    props.UpdateSearchFilter("activeFilters", null);
    props.UpdateSearchFilter("WorkplacePolicy", null);
  };

  const ClearAll = ({ filter, value }) => {
    return (
      <div className="clearAll">
        <a href="#" className="clear-text" onClick={() => onClear(filter)}>
          Clear all
        </a>
        <div
          className="clear-all-button"
          onClick={() => onApply(value, filter)}
        >
          Apply
        </div>
      </div>
    );
  };

  const MobileSortResults = () => {
    props.UpdateSearchFilter("sortValue", sortValueMobile);
    props.UpdateSearchFilter("sortDrawer", false);
    props.UpdateSearchFilter("pageNo", "1");
  };

  useEffect(() => {
    props.sendKeyLocation("A");
    props.searchSkills();
  }, []);

  // Filters
  const onChangeSkill = (e) => {
    // 
    setSkillValue(e.target.value);
  };
  const onChangeWorkplace_Policy = (e) => {
    // 
    setWorkplacePolicyValue(e.target.value);
  };
  const WorkplaceStage = ["Fully remote", "Hybrid", "On-premise"];
  const Workplace_Policy = (
    <Menu
      onClick={null}
      className={
        validation === "workplace" ? "workplace-invalid" : "workplace-type-menu"
      }
    >
      {WorkplaceStage.map((data) => {
        return (
          <Menu>
            <Menu.Item onClick={null} onSelect={null}>
              <div className="sortItem-bids">
                <span
                >
                  <Radio.Group
                    onChange={onChangeWorkplace_Policy}
                    value={WorkplacePolicyValue}
                    defaultValue={WorkplacePolicy}
                  >
                    <Radio value={data} className="Search-jobs">
                      {data}
                    </Radio>
                  </Radio.Group>
                </span>
              </div>
            </Menu.Item>
          </Menu>
        );
      })}
      {validation === "workplace" ? (
        <div className="search-results-filter-validation-text">
          Please select Workplace Policy
        </div>
      ) : null}
      <ClearAll value={WorkplacePolicyValue} filter={"WorkplaceMenu"} />
    </Menu>
  )
  const onChangeJob = (e) => {
    // 
    setJobValue(e.target.value);
  };

  const jobs = ["Contract", "Permanent"];

  const JobMenu = (
    <Menu
      onClick={null}
      className={
        validation === "job" ? "job-type-menu-invalid" : "job-type-menu"
      }
    >
      {jobs.map((data) => {
        return (
          <Menu>
            <Menu.Item onClick={null} onSelect={null}>
              <div className="sortItem-bids">
                <span
                // className={skill === receivedBidSort ? "greenTextRB" : null}
                >
                  <Radio.Group
                    onChange={onChangeJob}
                    value={jobValue}
                    defaultValue={jobQuery}
                  >
                    <Radio value={data} className="Search-jobs">
                      {data}
                    </Radio>
                  </Radio.Group>
                </span>
              </div>
            </Menu.Item>
          </Menu>
        );
      })}
      {validation === "job" ? (
        <div className="search-results-filter-validation-text">
          Please select any of the gig type
        </div>
      ) : null}
      <ClearAll value={jobValue} filter={"jobMenu"} />
    </Menu>
  );

  const onChangeBudget = (e) => {
    setBudgetType(e.target.value);
    // 
  };

  const budgetMenu = (
    <Menu
      onClick={handleMenuClick}
      className={
        validation === "budget" ? "budget-menu-invalid" : "budget-menu"
      }
    >
      <div className="options"></div>
      <Radio.Group
        onChange={onChangeBudget}
        value={budgetType}
        defaultValue={budgetTypeQuery}
      >
        <Radio value={"Hourly"} className="Search-jobs">
          Hourly
        </Radio>
        <Radio value={"Weekly"} className="Search-jobs">
          Weekly
        </Radio>
      </Radio.Group>
      <div className="input-container">
        <Input
          prefix="$"
          className="input-left"
          onChange={(e) => setBudgetMin(e.target.value)}
          value={budgetMin}
          placeholder="50"
        />
        <div className="dash">-</div>
        <Input
          prefix="$"
          className="input-right"
          onChange={(e) => setBudgetMax(e.target.value)}
          value={budgetMax}
          placeholder="60"
        />
      </div>
      {validation === "budget" ? (
        <div className="search-results-filter-validation-text-budget">
          Please enter budget
        </div>
      ) : null}
      <ClearAll
        value={budgetMin ? budgetMin : budgetMax}
        filter={"budgetMenu"}
      />
    </Menu>
  );

  const onchangeSkill = (val) => {
    debounce((val) => onSkillSearch(val));
  };

  const onSkillSearch = (val) => {
    // 
    setNoDataView(val);
    props.searchSkills(val);
    setSkillsInput(val);
    // 
  };

  const getSearchSkill = (value) => {
    if (value) {
      props.saveSearchSkill(value);
    }
  };
  const skill = (
    <Menu
      onClick={null}
      className={validation === "skill" ? "s-result-invalid" : "s-result"}
    >
      <Input
        placeholder="Find a skill"
        prefix={<SearchOutlined />}
        // style={{ width: 200 }}
        className="skill-search"
        onChange={debounce((e) => onSkillSearch(e.target.value))}
      />

      <Menu>
        {props.skills &&
          props.skills.map((skill) => {
            return (
              <Menu.Item onClick={(val) => getSearchSkill(val)}>
                <div>
                  <span
                  // className={skill === receivedBidSort ? "greenTextRB" : null}
                  >
                    <Radio.Group onChange={onChangeSkill} value={skillValue}>
                      <Radio value={skill?.SkillName} className="Search-jobs">
                        {skill?.SkillName}
                      </Radio>
                    </Radio.Group>
                  </span>
                </div>
              </Menu.Item>
            );
          })}
      </Menu>
      {validation === "skill" ? (
        <div className="search-results-filter-validation-text-location">
          Please select skill
        </div>
      ) : null}
      <ClearAll value={skillValue} filter={"skillMenu"} />
    </Menu>
  );
  const onChange = (e) => {
    // 
    setLocationValue(e.target.value);
  };

  function onLocationSearch(val) {
    // 
    // 
    // // 
    setNoDataView(val);
    props.sendKeyLocation(val);
  }

  const getSearchLocation = (value) => {
    // 
    if (value) {
      props.saveSearchLocation(value);
    }
  };

  const locationMenu = (
    <Menu
      onClick={null}
      className={
        validation === "location" ? "s-result-invalid-location" : "s-result"
      }
    >
      <Input
        placeholder="Find city / state"
        prefix={<SearchOutlined />}
        // style={{ width: 200 }}
        className="skill-search"
        onChange={debounce((e) => onLocationSearch(e.target.value))}
      />

      <Menu>
        {props.searchedLocation?.length > 0 &&
          props.searchedLocation?.map((city) => {
            return (
              <Menu.Item
                onClick={(e) =>
                  getSearchLocation(e.domEvent.target.defaultValue)
                }
              >
                <div className="sortItem-bids">
                  <span
                  // className={skill === receivedBidSort ? "greenTextRB" : null}
                  >
                    <Radio.Group onChange={onChange} value={locationValue}>
                      <Radio value={city?.Location} className="Search-jobs">
                        {city?.Location}
                      </Radio>
                    </Radio.Group>
                  </span>
                </div>
              </Menu.Item>
            );
          })}
      </Menu>
      {validation === "location" ? (
        <div className="search-results-filter-validation-text-location">
          Please select locations
        </div>
      ) : null}
      <ClearAll value={locationValue} filter={"locationMenu"} />
    </Menu>
  );

  // SORTING
  const sortOnClick = (event) => {
    props.UpdateSearchFilter("sortValue", event);
    props.UpdateSearchFilter("pageNo", "1");
  };
  const sortMenu = (
    <Menu onClick={(e) => sortOnClick(e.domEvent.target.innerText)}>
      <Menu.Item
        key="1"
        className={
          sortValue === "Most Relevant"
            ? "sort-values-style-select"
            : "sort-values-style"
        }
      >
        Most relevant
      </Menu.Item>
      <Menu.Item
        key="2"
        className={
          sortValue === "Most Recent"
            ? "sort-values-style-select"
            : "sort-values-style"
        }
      >
        Most recent
      </Menu.Item>
      {ProfessionCode === "HC" ? (<><Menu.Item
        key="3"
        className={
          sortValue === "Pay Package High to Low"
            ? "sort-values-style-select"
            : "sort-values-style"
        }
      >
        Pay package high to low
      </Menu.Item>
        <Menu.Item
          key="3"
          className={
            sortValue === "Pay Package Low to High"
              ? "sort-values-style-select"
              : "sort-values-style"
          }
        >
          Pay package low to high
        </Menu.Item></>) : null}
      <Menu.Item
        key="3"
        className={
          sortValue === "Ending Soon"
            ? "sort-values-style-select"
            : "sort-values-style"
        }
      >
        Ending soon
      </Menu.Item>
    </Menu>
  );

  const CountDetail = () => {
    return (
      <p style={{ height: "auto", fontSize: "1rem" }}>
        Showing {props.gigs?.gigs?.gigs.aggregate?.totalCount} results for{" "}
        <strong style={{ textTransform: "capitalize" }}>
          {" "}
          "{props.value}"
        </strong>
      </p>
    );
  };
  const { Search } = Input;
  const onSearch = (e) => {
    props.searchValue(e.target.value);
    // if (e.target.value.length > 0) {
    // }
    if (e.target.value.length == 0) {
      history.push("/search");
    }
  };
  const mylocation = () => {
    setMyLocationQuery(!myLocationQuery);
    if (navigator.geolocation) {
      // setIsLocation(false);
      navigator.geolocation.watchPosition(function (position) {
        // 
        // 
        setLatitude(position.coords.latitude);
        setLongitude(position.coords.longitude);
      });
    }
    // if (navigator.geolocation) {
    //   navigator.permissions
    //     .query({ name: "geolocation" })
    //     .then(function (position) {
    //       if (position.state === "granted") {
    //         // 
    //         //If granted then you can directly call your function here
    //         // 
    //         // 
    //         // setLatitude(position.coords.latitude);
    //         // setLongitude(position.coords.longitude);
    //       } else if (position.state === "prompt") {
    //         // 
    //       } else if (position.state === "denied") {
    //         alert("Permission denied can't show nearby location");
    //         //If denied then you have to show instructions to enable location
    //       }
    //       position.onchange = function () {
    //         // 
    //       };
    //     });
    // } else {
    //   alert("Sorry Not available!");
    // }
  };

  // MOBILE FILTER AND SORT
  const mobileFilter = () => {
    if (window.innerWidth < 768) handleFilterDrawer(!filterDrawer);
    setVisible(true);
  };

  const mobileSort = () => {
    if (window.innerWidth < 768) {
      handleSortDrawer(!sortDrawer);
    }
  };

  const onClickSuggestion = (e) => {
    props.searchValue(e);
  };
  const handleFilterDrawer = (state) => {
    props.UpdateSearchFilter("filterDrawer", state);
    setVisible(false);
  };

  const closeFilterDrawer = () => {
    props.UpdateSearchFilter("filterDrawer", false);
    if (activeFilters == 0) {
      activeFilters();
    }
  };
  const handleSortDrawer = (state) => {
    props.UpdateSearchFilter("sortDrawer", state);
  };

  const closeSortDrawer = () => {
    props.UpdateSearchFilter("sortDrawer", false);
    setSortValueMobile("Most Relevant");
  };
  const filterMenu = (
    <Menu id="mobile-filter">
      {/* {receivedBidStatus.map((stage) => { */}
      {/* return ( */}
      <Menu.Item
        // key={stage.BidStatusName}
        placement="bottomLeft"
      // onClick={() => handleFilterStage(stage.BidStatusName)}
      >
        <div className={visible ? "greenTextRB" : null}>
          {/* {stage.BidStatusName} */}
          <MobileFilters onClearAll={isClear} />
        </div>
      </Menu.Item>
      {/* ); */}
      {/* })} */}
    </Menu>
  );
  const sortArrayRB = [
    { "Most Recent": "most_recent" },
    { "Pay Package High to Low": "pay_hi_to_low" },
    { "Pay Package Low to High": "pay_low_to_hi" },
    { "Nearest to you": "nearby_location" },
  ];

  const handleSortRB = (sort) => {
    // 
    // // 
    // setReceivedBidSort(sort);
    // if (sort === "nearby_location") {
    //   setIsLocation(true);
    // } else setIsLocation(false);
  };
  const onChangeSort = (e) => {
    if (window.innerWidth > 768) {
      props.UpdateSearchFilter("sortValue", e.target.value);
    }

    if (window.innerWidth < 768) {
      setSortValueMobile(e.target.value);
    }
    // if (e.target.value === "nearby_location") {
    //   setIsLocation(true);
    // } else setIsLocation(false);
  };

  const sortMenuMobile = (
    <Menu>
      {sortArrayRB.map((sort) => {
        return (
          <Menu.Item onClick={() => handleSortRB(Object.values(sort)[0])}>
            <div className="sortItem-bids">
              <span
                className={
                  Object.values(sort)[0] === sortValue ? "greenTextRB" : null
                }
              >
                {Object.keys(sort)[0]}
              </span>
            </div>
          </Menu.Item>
        );
      })}
    </Menu>
  );

  const filterWrapperClick = (e) => {
    // e.stopPropagation();
    setVisibleJobMenu(false);
    setVisibleLocationMenu(false);
    setVisibleBudgetMenu(false);
    setVisibleSkillMenu(false);
  };

  // RETURN
  return (
    <React.Fragment>
      <MediaQuery maxWidth={768}>
        <div className="search-results-divider">{/* <Divider /> */}</div>
      </MediaQuery>
      <main className="gql-dashBoardFull">
        <div
          className="gql-countCheck"
          onClick={() => {
            setVisibleSkillMenu(false);
            setVisibleLocationMenu(false);
            setVisibleJobMenu(false);
            setVisibleBudgetMenu(false);
          }}
        >
          {resultCount > 0 && props.value.length > 0 ? (
            <CountDetail gigs={gigs?.length} value={props.value} />
          ) : null}
        </div>
        {/* //// Tags//// */}
        {/* https://ant.design/components/tag/ */}
        <div
          className={
            dimensions < 769
              ? "suggestion-wrdapper-mobile"
              : "suggestion-wrdapper"
          }
          onClick={() => {
            setVisibleSkillMenu(false);
            setVisibleLocationMenu(false);
            setVisibleJobMenu(false);
            setVisibleBudgetMenu(false);
          }}
        >
          {gigs && gigs.length > 0 && props.value.length > 0 ? (
            <>
              {gigSuggestions.map((suggestion) => {
                return (
                  <Tag
                    color="default"
                    className="tags"
                    onClick={(e) => onClickSuggestion(suggestion)}
                  >
                    {suggestion}
                  </Tag>
                );
              })}
            </>
          ) : null}
        </div>
        {/* ////// Dropdowns //// */}
        {/* https://ant.design/components/dropdown/ */}
        {dimensions > 769 ? (
          <>
            <div class="filters-wrapper">
              {/* {resultCount < 0 && props.value.length < 0 ? (
                <> */}
                  <Space wrap className="space">
                    <Dropdown
                      className={jobQuery ? "dropdown-job" : "dropdown"}
                      // className="dropdown-job"
                      overlay={JobMenu}
                      trigger="click"
                      onClick={() => {
                        setVisibleJobMenu(!visibleJobMenu);
                        setVisibleLocationMenu(false);
                        setVisibleBudgetMenu(false);
                        setVisibleSkillMenu(false);
                      }}
                      visible={visibleJobMenu}
                    // destroyOnClose={true}
                    >
                      <Button
                        className="button"
                        onVisibleChange={() => onClear("jobMenu")}
                      >
                        Gig type <DownOutlined />
                      </Button>
                    </Dropdown>
                    <Dropdown
                      // className="dropdown"
                      className={skillQuery ? "dropdown-skill" : "dropdown"}
                      overlay={skill}
                      trigger="click"
                      onClick={() => {
                        setVisibleSkillMenu(!visibleSkillMenu);
                        setVisibleLocationMenu(false);
                        setVisibleJobMenu(false);
                        setVisibleBudgetMenu(false);
                      }}
                      visible={visibleSkillMenu}
                    >
                      <Button className="button">
                        Skill <DownOutlined />
                      </Button>
                    </Dropdown>
                    <Dropdown
                      // className="dropdown"
                      className={
                        locationQuery ? "dropdown-location" : "dropdown"
                      }
                      overlay={locationMenu}
                      trigger="click"
                      onClick={() => {
                        setVisibleLocationMenu(!visibleLocationMenu);
                        setVisibleSkillMenu(false);
                        setVisibleJobMenu(false);
                        setVisibleBudgetMenu(false);
                      }}
                      visible={visibleLocationMenu}
                    >
                      <Button className="button">
                        Location <DownOutlined />
                      </Button>
                    </Dropdown>
                    <Dropdown
                      // className="dropdown"
                      className={budgetQuery || WorkplacePolicy ? "dropdown-budget" : "dropdown"}
                      overlay={(ProfessionCode === "HC" ? budgetMenu : Workplace_Policy)}
                      trigger="click"
                      destroyPopupOnHide={true}
                      onClick={() => {
                        setVisibleBudgetMenu(!visibleBudgetMenu);
                        setVisibleSkillMenu(false);
                        setVisibleLocationMenu(false);
                        setVisibleJobMenu(false);
                      }}
                      visible={visibleBudgetMenu}
                    >
                      <Button className="button">
                        {ProfessionCode === "HC" ? "Budget" : "Workplace Policy"} <DownOutlined />
                      </Button>
                    </Dropdown>
                  </Space>
                  <div
                    className="blank-space-div"
                    onClick={(e) => filterWrapperClick(e)}
                  ></div>
                  <div className="location-button">
                    {/* https://ant.design/components/switch/ */}
                    <Switch onChange={mylocation} disabled={isLocation} />
                    <div className="location-text">Nearby location</div>
                  </div>
                {/* </>
              ) : null} */}
            </div>
            <div
              className="applied-filter"
              onClick={() => {
                setVisibleJobMenu(false);
                setVisibleLocationMenu(false);
                setVisibleBudgetMenu(false);
                setVisibleSkillMenu(false);
                setWorkplacePolicyValue(false)
              }}
            >
              {jobQuery ? (
                <>
                  <Tag
                    closable
                    onClose={() => onClear("jobMenu")}
                    className="tag1"
                  >
                    {jobQuery}
                  </Tag>
                </>
              ) : null}
              {skillQuery ? (
                <>
                  <Tag
                    closable
                    onClose={() => onClear("skillMenu")}
                    className="tag1"
                  >
                    {skillQuery}
                  </Tag>
                </>
              ) : null}
              {locationQuery ? (
                <>
                  <Tag
                    closable
                    onClose={() => onClear("locationMenu")}
                    className="tag1"
                  >
                    {locationQuery}
                  </Tag>
                </>
              ) : null}
              {budgetQuery && minbudgetQuery && maxbudgetQuery ? (
                <>
                  <Tag
                    closable
                    onClose={() => onClear("budgetMenu")}
                    className="tag1"
                  >
                    ${minbudgetQuery}-${maxbudgetQuery}
                  </Tag>
                </>
              ) : null}
              {budgetQuery && minbudgetQuery && !maxbudgetQuery ? (
                <>
                  <Tag
                    closable
                    onClose={() => onClear("budgetMenu")}
                    className="tag1"
                  >
                    over ${minbudgetQuery}
                  </Tag>
                </>
              ) : null}
              {budgetQuery && maxbudgetQuery && !minbudgetQuery ? (
                <>
                  <Tag
                    closable
                    onClose={() => onClear("budgetMenu")}
                    className="tag1"
                  >
                    under ${maxbudgetQuery}
                  </Tag>
                </>
              ) : null}
              {WorkplacePolicy ? (
                <>
                  <Tag
                    closable
                    onClose={() => onClear("WorkplaceMenu")}
                    className="tag1"
                  >
                    {WorkplacePolicy}
                  </Tag>
                </>
              ) : null}
            </div>
          </>
        ) : null}
        {dimensions > 769 ? (
          <>
            {resultCount < 0 && props.value.length < 0 ? (
              <>
                <div
                  className="sort-filter"
                  onClick={() => {
                    setVisibleSkillMenu(false);
                    setVisibleLocationMenu(false);
                    setVisibleJobMenu(false);
                    setVisibleBudgetMenu(false);
                  }}
                >
                  <Dropdown
                    overlay={sortMenu}
                    trigger="click"
                    className="sort-filter-dropdown"
                  >
                    <div
                      className="ant-dropdown-link"
                      onClick={(e) => e.preventDefault()}
                    >
                      <span className="sortby-text">{sortValue}</span>{" "}
                      <DownOutlined />
                    </div>
                  </Dropdown>
                  <div className="sortby">Sort by</div>
                </div>
              </>
            ) : null}
          </>
        ) : null}
        {/* MOBILE */}
        {dimensions <= 769 ? (
          <>
            {resultCount > 0 && props.value.length > 0 ? (
              <>
                <div className="search-mobile-filter">
                  <div className="left" onClick={mobileFilter}>
                    <div className="filter-container">
                      <BiFilterAlt className="icon" />

                      <div className="text">
                        Filter{" "}
                        {props.activeFilters
                          ? `(${props.activeFilters})`
                          : null}
                      </div>
                    </div>
                    {/* <BiFilterAlt /> */}
                  </div>
                  <div className="left" onClick={mobileSort}>
                    <div className="filter-container">
                      <BsArrowDownUp className="icon" />

                      <div className="text">Sort</div>
                    </div>
                  </div>
                </div>
                <div className="mobile-location-button">
                  {/* https://ant.design/components/switch/ */}
                  <div className="location-text">Nearby location</div>
                  <div className="mobile-switch">
                    <Switch onChange={mylocation} disabled={isLocation} />
                  </div>
                </div>
              </>
            ) : null}
          </>
        ) : null}

        <div
          className={`${props.value.length == 0 && "gql-receivedGigs"} `}
          onClick={() => {
            setVisibleJobMenu(false);
            setVisibleLocationMenu(false);
            setVisibleBudgetMenu(false);
            setVisibleSkillMenu(false);
          }}
        >
          {props.value.length > 0 && !props.loading ? (
            <div className="gql-receivedGigs" Pagination={true}>
              {gigs && gigs.length > 0
                ? gigs.map((data) => {
                  // // 
                  return (
                    <GigsCard data={data} budgetType={budgetTypeQuery} />
                  );
                })
                : null}
            </div>
          ) : (
            <div className="gql-message">
              {props.value.length === 0 && `Search gigs`}
            </div>
          )}
          {/* <LoadingOutlined /> */}
          {props.value.length > 0 ? (
            <>
              <div className="gql-loader">
                {/* {props.value.length > 0 && props.loading && <LoadingOutlined />} */}
                <LoadingOutlined />
              </div>
            </>
          ) : null}
          {props.value.length > 0 && gigs?.length === 0 ? (
            <div className="gql-receivedGigs">
              <div className="gql-message">No gigs found!</div>
            </div>
          ) : null}
          <div className="gql-pagination">
            {props.value.length > 0 && gigs && (
              <Pagination
                defaultCurrent={pageNo ? pageNo : 1}
                total={props.gigs?.gigs?.gigs.aggregate?.totalCount}
                pageSize={24}
                onChange={(page) => props.UpdateSearchFilter("pageNo", page)}
              />
            )}
          </div>
          {/* DRAWER FOR MOBILE FILTER */}
          <div className="mobile-filter">
            {/* <Space direction="vertical">
                <Space wrap> */}
            <Drawer
              title="Filter"
              placement="top"
              onClose={closeFilterDrawer}
              visible={filterDrawer}
              className="SearchfilterDrawer"
              height="40rem"
              width="100%"
              destroyOnClose={true}
            >
              {filterMenu}
              <div
                style={{
                  color: "#9e9e9e",
                  position: "absolute",
                  top: "15px",
                  left: "15px",
                }}
                onClick={clearAllMobile}
              >
                Clear all
              </div>
            </Drawer>
          </div>
          <Drawer
            title="Sort gigs by"
            placement="top"
            onClose={closeSortDrawer}
            visible={sortDrawer}
            className="sortReceivedBidsDrawer-mobile"
            height="40rem"
            width="100%"
            destroyOnClose={true}
          >
            <Radio.Group
              className="radioSortReceivedBidsGroup"
              onChange={(e) => onChangeSort(e)}
              defaultValue={sortValue}
            >
              <Space direction="vertical">
                <Radio
                  className="radioSortReceivedBids"
                  value={"Most Relevant"}
                >
                  <span style={{ color: "#3e3e3e" }}>Most Relevant</span>
                </Radio>
                <Radio className="radioSortReceivedBids" value={"Most Recent"}>
                  <span style={{ color: "#3e3e3e" }}>Most recent</span>
                </Radio>
                {ProfessionCode === "HC" ? (<><Radio
                  className="radioSortReceivedBids"
                  value={"Pay Package High to Low"}
                >
                  <span style={{ color: "#3e3e3e" }}>
                    Pay package high to low
                  </span>
                </Radio>
                  <Radio
                    className="radioSortReceivedBids"
                    value={"Pay Package Low to High"}
                  >
                    <span style={{ color: "#3e3e3e" }}>
                      Pay package low to high
                    </span>
                  </Radio></>) : null}
                <Radio className="radioSortReceivedBids" value={"Ending Soon"}>
                  <span style={{ color: "#3e3e3e" }}>Ending soon</span>
                </Radio>
              </Space>
            </Radio.Group>
            <div
              style={{
                position: "absolute",
                bottom: 0,
                width: "100%",
                borderTop: "2px solid #e8e8e8",
                padding: "10px 16px",
                textAlign: "right",
                left: 0,
                background: "#fff",
                borderRadius: "0 0 4px 4px",
              }}
            >
              <div className="apply-button-mobile-filter">
                <div className="apply-button-text" onClick={MobileSortResults}>
                  Show results
                </div>
              </div>
            </div>
            {/* <div className="radioSortShowItemsParent">
              <div className="radioSortShowItemsChild" onClick={showItemsSort}>
                Show Items
              </div>
            </div> */}
          </Drawer>
          {/* <div>
            <Drawer
              title="Basic Drawer"
              placement="top"
              closable={false}
              onClose={() => setVisible(false)}
              visible={visible}
              key="top"
              style={{ height: "400px" }}
              height={556}
              extra={
                <div>
                  <p>hey</p>
                </div>
              }
            >
              <p>Some contents...</p>
              <p>Some contents...</p>
              <p>Some contents...</p>
            </Drawer>
          </div> */}
          {/* <LoadingOutlined /> */}
          {/* {gigs && gigs.length > 0
            ? gigs.map((data) => {
                return <GigsCard data={data} />;
              })
            : "no gigs found "} */}
        </div>
      </main>
      {/* </div> */}
    </React.Fragment>
  );
};
const mapStateToProps = (state) => {
  return {
    jobQuery: state.searchGigsReducer.jobQuery,
    skillQuery: state.searchGigsReducer.skillQuery,
    locationQuery: state.searchGigsReducer.locationQuery,
    minbudgetQuery: state.searchGigsReducer.minbudgetQuery,
    budgetQuery: state.searchGigsReducer.budgetQuery,
    maxbudgetQuery: state.searchGigsReducer.maxbudgetQuery,
    budgetTypeQuery: state.searchGigsReducer.budgetTypeQuery,
    sortValue: state.searchGigsReducer.sortValue,
    pageNo: state.searchGigsReducer.pageNo,
    activeFilters: state.searchGigsReducer.activeFilters,
    filterDrawer: state.searchGigsReducer.filterDrawer,
    sortDrawer: state.searchGigsReducer.sortDrawer,
    loading: state.searchGigsReducer.loading,
    skills: state.skillListingReducer.skills,
    currentSkillListing: state.skillListingReducer.currentSkillListing,
    gigs: state.searchGigsReducer.gigs,
    value: state.searchGigsReducer.searchvalue,
    error: state.searchGigsReducer,
    talentToken: state.authReducer.user?.authToken,
    searchedLocation: state.credentialingReducer.searchedLocation,
    WorkplacePolicy: state.searchGigsReducer.WorkplacePolicy,
    IsFavoritesValue: state.searchGigsReducer.IsFavoritesValue,
  };
};

const mapDispatchToProps = (dispatch) => {
  return {
    // GetGigs: (params) => {
    //   dispatch(fetchGigs(params)); // action dispatched
    // },
    // SearchValue
    SearchResponseRequested: (params) => {
      dispatch(SearchResponseRequested(params)); // action dispatched
    },
    searchResponse: (params) => {
      dispatch(SearchResponse(params)); // action dispatched
    },
    searchValue: (params) => {
      dispatch(SearchValue(params)); // action dispatched
    },
    saveSearchSkill: (value) => {
      dispatch(saveSearchSkill(value));
    },
    searchSkills: (params) => {
      dispatch(fetchSearchSkill(params));
    },
    sendKeyLocation: (e) => {
      dispatch(fetchKeySearchLocation(e));
    },
    saveSearchLocation: (value) => {
      dispatch(saveSearchLocation(value));
    },
    UpdateSearchFilter: (key, params) => {
      // 
      dispatch(UpdateSearchFilter(key, params)); // action dispatched
    },
  };
};

export default connect(mapStateToProps, mapDispatchToProps)(SearchResults);
