import React from "react";
import "./BackNextSignUpButton.css";
import { Button } from "antd";

const BackNextSignUpButton = ({
  backFunction,
  nextFunction,
  nextBtnLoading,
  skipFunction,
  skip
}) => {
  return (
    <div className="gap-back-next-signup-buttons">
      <Button className="gap-button-back" onClick={backFunction}>
        Back
      </Button>
      <Button
        className="gap-button-next"
        onClick={nextFunction}
        loading={nextBtnLoading}
        // disabled={nextBtnLoading}
      >
        Next
      </Button>
      {skip ? (
      <Button className="gap-button-skip" onClick={skipFunction}>
        Skip
      </Button>
      ): null}
    </div>
  );
};

export default BackNextSignUpButton;
