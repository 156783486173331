import React, { useState, useEffect } from 'react'
import { useDispatch, useSelector } from "react-redux";
import { Spin, Button, Col,Skeleton, Typography, notification, Upload, Pagination, Row, Menu, Dropdown, Tag } from "antd";
import './TimeSheet.css';
import TimesheetListing from './TimesheetListing/TimesheetListing';
import Chart from './Chart/Chart';
import { fetchTimeSheetList, createTimesheet, updateTimesheetItem,getExistingListItem,userAssignment,getGraph } from '../../redux/actions';
import moment from 'moment';
import {getMessaging, getToken} from "firebase/messaging";
import Emptylist from './Empty/Emptylist';
import MobilePagination from '../../components/Pagination/MobilePagination';
import logo from '../../assets/icons/sg.png';
import { EllipsisOutlined } from '@ant-design/icons';
import { useHistory } from 'react-router';


const TimeSheet = () => {
    const dispatch = useDispatch();
    const history = useHistory();
    const [currentPage, setCurrentPage] = useState(1);
    // const [startTime, setstartTime] = useState('')
    // const [endTime, setendTime] = useState('')
    // const [flag, setflag] = useState(false)
    const [render, setrender] = useState(false)
    const [startDay, setstartDay] = useState(false)
    const [pause, setpause] = useState(false)
    // const [stopDay, setStopDay] = useState(false)
    const [windowDimensions, setWindowDimensions] = useState(getWindowDimensions());

    var date = new Date().toLocaleDateString('en-GB', { day: 'numeric', month: 'short', year: 'numeric' });
    const [startEnd, setStartEnd] = useState(true);
    const [pauseResume, setPauseResume] = useState(false);
    const [started, setstarted] = useState(false)
    const [key, setKey] = useState(`open${Date.now()}`);
    const [buttonText, setButtonText] = useState("Start My Day")
    
    const timeList = useSelector(state => state.timeSheetListReducer.timeList);
    const loading = useSelector(state => state.timeSheetListReducer.loading);
    const error = useSelector(state => state.timeSheetListReducer.error);
    const exist = useSelector(state=>state.timeSheetListReducer.userassignment)
    const [status, setStatus] = useState('');
    const [tagColor, settagColor] = useState('');
    // 

    const userAssignments = useSelector(state=>state.UserAssignmentReducer.success);
    // 
    // // 
    // // 

    const pageSize = 20;
    // 

    var beforeTime = 0;
    var currentTime = new Date();

    if(userAssignments.length>0){
        var notTime = new Date(userAssignments[0].Shift.StartTime);
        currentTime = new Date();
        notTime.setDate(currentTime.getDate());
        notTime.setMonth(currentTime.getMonth());
        notTime.setFullYear(currentTime.getFullYear());
        // 

        // var beforeTime = notTime.getTime()-15*60000;
        beforeTime= notTime.setMinutes( notTime.getMinutes() - 15 );
        // 
    }
    
    const setNotTime=()=>{
        // beforeTime <= currentTime.getTime()
        currentTime = new Date();
        var notificationTime = (beforeTime-currentTime.getTime());

        // // 
        // // 
        // // 
        
        const description = (
            <div style={{fontSize:14}}>
                <div> Daily remainder</div>
                <div>Log your start time today</div>
            </div>
        )
        // 
        if(notificationTime>0){
            
            setTimeout(()=>openNotification(Math.random(),description),notificationTime);
        }
    }
    useEffect(() => {
        // getToken();
        const messaging = getMessaging();
        getToken(messaging, { vapidKey: 'BIXrSZswxEmL7B1dL_MbVaPcveqHpmRocLule2o8kUF4F-NElqxVCqxp_bVa69OEM_vZ5xxSxEYjQ0gaQ30Zq7Y' }).then((currentToken) => {
            if (currentToken) {
                // 
            } else {
                // Show permission request UI
                // 
            }
        }).catch((err) => {
            // 
        });
        getTimeList(0);
    }, [])

    useEffect(()=>{
        dispatch(getExistingListItem());
        if(exist.length){
            window.timesheetId=exist[0]._id
            setStatus(exist[0].Status);
            if(exist[0].Status==="pause"){
                setpause(true);
                setPauseResume(true)
            }
            else if(exist[0].Status==="start"){
                setpause(false);
                setPauseResume(false)
            }
            else if(exist[0].Status==="stop"){
                setStatus("stop");
            }
        }
    },[exist.length])

    useEffect(()=>{
        // 
        dispatch(userAssignment());
        setNotTime();
    },[])

    // useEffect(()=>{

    //     if(exist.length > 0){
    //         setNotTime();
    //     }
    // },[userAssignments.length])

    useEffect(() => {
        function handleResize() {
          setWindowDimensions(getWindowDimensions());
        }

        window.addEventListener('resize', handleResize);
        return () => window.removeEventListener('resize', handleResize);
      }, []);

    function getWindowDimensions() {
        const { innerWidth: width, innerHeight: height } = window;
        return {
          width,
          height
        };
      }


    const getTimeList = (skip) => {

            dispatch(fetchTimeSheetList(pageSize, skip,'tilldate',null,null))
            dispatch(getGraph('tilldate', null, null));
        // // 
    }
    const onChangePage = (page) => {
        // 
        // // 
        getTimeList(pageSize * (page - 1))
        // getTimeList(page * (pageSize - 1));
        setCurrentPage(page);
    }

    const convert24hourTo12HourFormat = (time) => {
        const time_part_array = time.split(":");
        let ampm = 'AM';
        if (time_part_array[0] >= 12) {
            ampm = 'PM';
        }
        if (time_part_array[0] > 12) {
            time_part_array[0] = time_part_array[0] - 12;
        }
        const formatted_time = time_part_array[0] + ':' + time_part_array[1] + ' ' + ampm;
        return formatted_time;
    }

    const StartBtn = () => {
        // 
        // 
        setStatus("start")
        dispatch(createTimesheet('start', new Date(), 0, 0));

        // openNotification(key, "Skillgigs", description)
    }

    const PauseBtn = () => {
        setpause(true);
        setPauseResume(true);
        dispatch(updateTimesheetItem('pause'));
        openNotification(key,(
            <div style={{fontSize:14}}>
                <div> Daily remainder</div>
                <div>Need to resume your time?</div>
            </div>
        ),'',4);

    }

    const onResume = () => {
        setpause(false);
        setPauseResume(false);
        dispatch(updateTimesheetItem('start'));
        openNotification(key,(
            <div style={{fontSize:14}}>
                <div> Daily remainder</div>
                <div>Need to pause your time?</div>
            </div>
        ),'',4);
    }

    const onStop = () => {
        setStatus("stop");
        dispatch(updateTimesheetItem('stop'));
        // dispatch(getExistingListItem());
        // setStopDay(true)
    }

    var time = new Date().toLocaleTimeString('en-US', {
        hour12: true,
        hour: "numeric",
        minute: "numeric"
    });
    

    // useEffect(() => {
        // let description = (
        //     <div style={{fontSize:14}}>
        //         {startEnd ?
        //             <div>Daily Reminder</div >
        //             :
        //             <div> Daily Reminder</div>
        //         }
        //         {
        //             pauseResume ?
        //                 <div>Need to resume your time?</div>
        //                 :
        //                 <div></div>
        //         }
        //     </div>
        // )
        // if (!started) {
        //     description = (
        //         <div style={{fontSize:14}}>
        //             <div> Daily Remainder</div>
        //             <div>Log your start time today</div>
        //         </div>
        //     )
        //     // openNotification(key, "Skillgigs", description)

        // }
        // oneSignal.sendTag('tech','react')
       // oneSignal.openNotification(key, "Skillgigs", description);
        // openNotification(key, "Skillgigs", description)
    // }, [startEnd, pauseResume])
    
    //NOTIFICATION UI:
    const btn = (
        <>
            <div style={{ backgroundColor: "lightgrey", display: "flex", borderRadius: "4px", justifyContent: 'space-between' }}>
                <div style={{ marginTop: '15px', fontSize: 16, paddingLeft: 10, alignItems: "flex-start" }}>
                    <div>
                        {time}
                    </div>
                    <div >
                        {date}
                    </div>
                </div>
                <div style={{ float: 'right', display: 'flex', fontSize: 18 }}>
                    {/* {!startEnd ?
                        <div >
                            <Button
                                type="primary"
                                size="small"
                                onClick={() => {
                                    // // 
                                    if (pauseResume) {
                                        // 
                                        setPauseResume(false)
                                        dispatch(updateTimesheetItem('start'));
                                    }
                                    if(!pauseResume){
                                        // 
                                        dispatch(updateTimesheetItem('pause'));
                                    }
                                    setPauseResume(!pauseResume)
                                }

                                }
                                style={{ marginLeft: 2, backgroundColor: 'green' }}
                            >
                                {!pauseResume ? 'Pause' : 'Resume'}
                            </Button>
                        </div>
                        : ''} */}
                    
                    {status=="start" || status=="pause" ?
                        <div> 
                             {!pauseResume ?
                                <Button type="primary" size="small" style={{ marginLeft: 2, backgroundColor: 'green' }} onClick={onResume}>Resume</Button>
                                :
                                <Button type="primary"  size="small" style={{ marginLeft: 2, backgroundColor: 'green' }} onClick={PauseBtn}>Pause</Button>
                                }
                        </div>
                        : <div>
                            <Button
                                type="primary"
                                size="small"
                                onClick={() => {

                                    if (started) {
                                        // 
                                        dispatch(updateTimesheetItem('stop'));
                                        notification.close(key)
                                    } else {
                                        if (!started){
                                            setstarted(true)
                                        }
                                        if (startEnd) {
                                            // 
                                            setStartEnd(false)
                                        }
                                        setStartEnd(!startEnd)
                                        dispatch(createTimesheet('start', new Date(), '619e008b3472b764ef5c758d',0,0));
                                    }
                                }}
                                style={{ marginLeft: 0, backgroundColor: 'green' }}
                            >
                                {startEnd ? 'Start my day' : 'End my day'}
                            </Button>
                        </div>
                    }
                </div>
            </div>
        </>
    );

    const openNotification = (key, message, description, duration=25) => {
            notification.open({
                message,
                className: 'custom-class',
                //icon: <SmileOutlined style={{ color: "#108ee9" }} />,
                icon: <img
                src={logo}
                className="notification-icon"/>,
                btn,
                key,
                duration: duration,
            });
    };

    const toHoursAndMinutes = (totalMinutes) => {
        const minutes = totalMinutes % 60;
        const hours = Math.floor(totalMinutes / 60);

        if(minutes){
            if(hours > 1){
                return `${hours}:${padTo2Digits(minutes)} HRS`;
            }
            return `${padTo2Digits(minutes)} MINS`;
        }
        if(hours){
            return `${hours} HRS`;
        }
        return '0 HR';
        
      }
      
    const padTo2Digits = (num) => {
        return num.toString().padStart(2, '0');
    }

    const menu = (
        <Menu>
            <Menu.Item >
                <div style={{fontSize: '16px', color: '#1e1e1e'}} onClick={() => {
                    if (exist.length && exist[0].Status != 'Correction') {
                        history.push({
                            pathname: '/requestcorrection',
                            state: {
                                id: exist[0].id,
                                date: exist[0].Date,
                                startTime:userAssignments.length>0?userAssignments[0].Shift.StartTime:'',
                                endTime:userAssignments.length>0?userAssignments[0].Shift.EndTime:''
                            }
                        })
                    }
                }}>Request correction</div>
            </Menu.Item>
        </Menu>
    )

    useEffect(()=>{

        if(exist.length && exist[0].timesheet_corrections && exist[0].timesheet_corrections.Status){
            if(exist[0].timesheet_corrections.Status==='Pending'){
                settagColor('rgba(158, 158, 158, 0.2)')
            }
            else if(exist[0].timesheet_corrections.Status==='Rejected'){
                settagColor('rgba(219, 40, 40, 0.2)')
            }
            else if(exist[0].timesheet_corrections.Status==='Declined'){
                settagColor('rgba(219, 40, 40, 0.2)')
            }
            else if(exist[0].timesheet_corrections.Status==='Approved'){
                settagColor('rgba(17, 137, 54, 0.2)')
            }
            else if(exist[0].timesheet_corrections.Status==='Accepted'){
                settagColor('rgba(17, 137, 54, 0.2)')
            }
        }
    },[exist.length && exist[0].timesheet_corrections])
    
    return (
        <>
        <div className='root'>
        {
            
            timeList && timeList.length || exist && exist.length ?
                 
            <div className="time-sheet-container">

                <div className='heading-timesheet'>Timesheet</div>
                { exist.length > 0 ? 
                    <>
                    <Col lg={24} sm={24} xs={24}>
                        <Row className={'timeContainer'} >
                            {/* <Col lg={10}  sm={12} xs={12} style={{ height: '100%', }}> */}
                            <Col xs={10} sm={10} lg={11} xl={11} style={{ height: '100%',display:'flex', alignItems:'center' }}>
                                <div className={'dateTimeContainer'}>
                                    {/* <div className={'date'}>{moment(new Date()).format('dddd, MMM DD')}</div> */}
                                    {/* changes made here */}
                                    {/* <div className={'date'}>{moment.utc(userAssignments.length>0?userAssignments[0].Shift.StartTime:'').format('hh:mm a')} {moment.utc(userAssignments.length>0?userAssignments[0].Shift.EndTime:'').format('hh:mm a')}</div> */}
                                    <Col span={24} ><div className={'date'}>{moment(new Date()).format('dddd , MMMM DD, YYYY')}</div></Col>
                                    <Col span={24} className={'date'} >{moment.utc(userAssignments.length>0?userAssignments[0].Shift.StartTime:'').format('h:mm a')} {moment.utc(userAssignments.length>0?userAssignments[0].Shift.EndTime:'').format('h:mm A')}</Col>
                                </div>
                            </Col>
                            {/* <Col lg={12} xs={12} style={{ height: '100%', }}> */}
                            {/* <Col xs={14} sm={15} md={12} lg={12} xl={12} style={{ height: '100%',}}> */}
                            <Col xs={10} sm={10} lg={9} xl={9}  style={{ height: '100%', }}>
                        
                                { status=="start"||status=="pause"?
                                    <div className={'buttonContainer'}>
                                        {
                                            pause ?
                                                <Button className={'timeButton pauseResumeButton'} onClick={onResume}>Resume</Button>
                                                :
                                                <Button className={'timeButton pauseResumeButton'} onClick={PauseBtn}>Pause</Button>
                                        }
                                        <Button className={'timeButton endBtn'} onClick={onStop}>End my day</Button>
                                    </div>
                                    :
                                    exist.length&&status=="idle"&& beforeTime <= currentTime.getTime()  ?
                                    <div className="buttonContainer">
                                        <Button className={'timeButton startButton'} onClick={StartBtn}>Start my day</Button>
                                    </div>:
                                
                                    <Row className={'hoursContainer'}>
                                        <Col lg={9} sm={5} className={'hoursText'}><strong>{ exist.length ? toHoursAndMinutes(exist[0].SpentTime.toFixed(1) * 60 ) : '0 hr'} </strong><span style={{color:"#d4d4d4"}}>|</span></Col>
                                        <Col lg={7} sm={5} className={'hoursText2'}><strong>{exist.length ? toHoursAndMinutes(exist[0].OverTime.toFixed(1) * 60) : '0 hr'}</strong></Col>
                                    </Row>
                                }
                            </Col>
                            <Col xs={4} sm={4} lg={4} xl={4} style={{ display:'flex', alignItems:'center', justifyContent:'flex-end'}}>
                                <Dropdown overlay={exist.length&&status == 'idle' ? menu : ""} placement="bottomLeft">
                                    <EllipsisOutlined style={{ fontSize: '32px', color: "#9e9e9e", cursor:'pointer' }} disabled />
                                </Dropdown>
                            </Col>
                        </Row>

                        <Row>
                            {exist.length && exist[0].timesheet_corrections ? <Col className={'request-tag'}>{exist[0].timesheet_corrections && exist[0].timesheet_corrections.Status ? <Tag style={{ border: 0}} color={tagColor}>Request {exist[0].timesheet_corrections.Status}</Tag>:null}</Col> : ''}
                        </Row>
                    </Col>
                    <div className='listing-line'>
                    </div>
                    </>
                : ''}
                {
                    timeList.filter(function (data){
                        // // 
                        // // 
                        return moment(data.Date).format('MMM DD, YYYY') !== moment(new Date()).format('MMM DD, YYYY')
                    }).map((item) => {

                        return (<>

                            <TimesheetListing
                                {...item}
                                mapItems={item}
                                id={item._id}
                                status={item.Status}
                                dateTime={item.Date}
                                spentTime={item.SpentTime}
                                overTime={item.OverTime}
                                BreakTime={item.BreakTime}
                                startTime={userAssignments.length>0?userAssignments[0].Shift.StartTime:''}
                                endTime={userAssignments.length>0?userAssignments[0].Shift.EndTime:''}
                            />
                        </>
                        );

                    })
                } 

                {
                    // timeList.timesheet.length ?
                        windowDimensions.width<650?

                        <Col span={24} className='mobilePagination'>
                            <MobilePagination
                               current={currentPage}
                               defaultPageSize={pageSize}
                               total={timeList && timeList.length > 0 ? timeList[0].TotalRecords : exist.length}
                            //    total={15}
                               onChange={(page) => onChangePage(page)}
                            />
                        </Col>

                        :
                        <Col span={24} className={'paginationBar'}>
                            <Pagination
                                defaultCurrent={1}
                                current={currentPage}
                                total={timeList && timeList.length > 0 ? timeList[0].TotalRecords : exist.length}
                                // total={15}
                                showTitle={true}
                                defaultPageSize={pageSize}
                                onChange={(page) => onChangePage(page)}

                                // className='paginationDestop'
                            />
                        </Col>
                }

                <div className='heading-reports'>Reports</div>
                <Chart />
            </div>
             :<Emptylist/>
             } 
             </div>
        </>
    )
}
export default TimeSheet;
