import React, { useState, useEffect } from "react";
import "./LostPassword.css";
import { Form, Input, Button, Checkbox, Tooltip, message } from "antd";
import "antd/dist/antd.css";
import {
  UserOutlined,
  MailOutlined,
  EyeInvisibleOutlined,
  EyeTwoTone,
} from "@ant-design/icons";
import { useHistory } from "react-router-dom";
import { Auth } from "../../../config/aws-exports";
import { FaCheckCircle } from "react-icons/fa";
import Infomessage from "../../../components/Infomessage";
import { fetchExistingtUser } from "../../../redux/actions";
import { connect } from "react-redux";

const LostPassword = (props) => {
  const [form] = Form.useForm();
  const [state, setState] = useState("sendmail");
  const [emailid, setEmailid] = useState();
  const [count, setCount] = useState(false);
  const [errormessage, setErrorMessage] = useState("");
  const [errorCodemessage, seterrorCodemessage] = useState("");
  const [LengthOTP, setLengthOTP] = useState();
  const [OTPMessageTrue, SetOTPMessageTrue] = useState(false);
  const [messagetype, setMessageType] = useState("container-error");
  const [passwordMisMatch, setpasswordMisMatch] = useState("");
  const [DisableButton, setDisableButton] = useState();
  const history = useHistory();
  const textemail = <span>example: example@mail.com</span>;
  const loginPage = () => {
    history.push("/login");
  };
  const textpass = <span>Minimum 8 characters required</span>;

  useEffect(() => {
    if (LengthOTP < 6) {
      SetOTPMessageTrue(true);
      seterrorCodemessage("");
    } else if (errorCodemessage) {
      SetOTPMessageTrue(true);
    } else {
      SetOTPMessageTrue(false);
    }
  }, [errorCodemessage, LengthOTP]);

  useEffect(() => {
    setCount(false);
    const checkUser = props.user.userExist;
    if (
      checkUser &&
      checkUser.valid &&
      checkUser.cognitoTriggerSource !== "PreSignUp_ExternalProvider"
    ) {
      setState("mailSent");
      onForgot(emailid);
    }
    if (emailid && !checkUser.valid) {
      setDisableButton(false);
      setErrorMessage("User with current email doesn't exist");
      setCount(true);
      setTimeout(() => {
        setCount(false);
      }, 4000);
    }
    if (
      emailid &&
      checkUser.cognitoTriggerSource === "PreSignUp_ExternalProvider"
    ) {
      setMessageType("container-error");
      setErrorMessage("OOPS, can't reset password for social login");
      setCount(true);
      setTimeout(() => {
        setCount(false);
      }, 4000);
    }
    // setState(data);
  }, [props.user.userExist]);
  useEffect(() => {
    setState("sendmail");
  }, [props.backClick]);

  const checkUserEmail = ({ email }) => {
    setDisableButton(true);
    setEmailid(email);
    if (email) {
      props.checkUser(email?.toLowerCase());
    }
  };

  const onForgot = async (email) => {
    //   // Send confirmation code to user's email
    Auth.forgotPassword(email)
      .then((data) => {
      })
      .catch((err) => {});

  };
  const onFinish = async (event) => {
    setCount(false);
    // Collect confirmation code and new password, then
    const username = emailid;
    if (event.confirm_password !== event.new_password) {
      setCount(true);
      setpasswordMisMatch("container-MisMatch");
      setMessageType("container-error");
      setErrorMessage("Please make sure your passwords match");
      setTimeout(() => {
        setpasswordMisMatch(false);
      }, 4000);
    } else {
      Auth.forgotPasswordSubmit(username, event.code, event.new_password)
        .then((data) => {
          setMessageType("container-success");
          setCount(true);
          setErrorMessage("Password changed successfully!");
          setTimeout(() => {
            history.push("/login");
            setState("sendmail");
            form.resetFields();
            setCount(false);
          }, 3000);
          // history.push("/login");
          props.lostPassToLogin("Success");
        })
        .catch((err) => {
          setCount(true);
          // setErrorMessage(err.message);
          if (
            err?.message ===
              "Invalid verification code provided, please try again." ||
            err?.message ===
              "Invalid code provided, please request a code again."
          ) {
            seterrorCodemessage(
              "This verification code didn't work. Try again"
            );
          }
          setTimeout(() => {
            setCount(false);
            form.resetFields();
          }, 3000);
        });
    }
  };

  if (state === "sendmail") {
    return (
      <div className="container">
        <div className="head-text">Reset Password</div>
        <div className="lpass-box-text">
          Just enter your email address below and we'll send you instructions on
          how to reset your password
        </div>
        <Form
          form={form}
          name="normal_login"
          className="login-form-lpass"
          initialValues={{
            remember: false,
          }}
          // onFinish={onForgot}
          onFinish={checkUserEmail}
        >
          {/* <div className="form-label">Email</div> */}
          <Tooltip placement="topRight" title={textemail} trigger="click">
            <Form.Item
              name="email"
              rules={[
                {
                  type: "email",
                  message: "The input is not a valid email",
                },
                {
                  required: true,
                  message: "Please input your email",
                },
              ]}
            >
              <Input placeholder="Email address" />
            </Form.Item>
            {count && (
              <Infomessage message={errormessage} class={messagetype} />
            )}
          </Tooltip>
          <Form.Item>
            <Button
              type="primary"
              htmlType="submit"
              className="login-form-button-lpass"
              // onClick={mailSent}
              disabled={DisableButton}
            >
              <span>Send reset instructions</span>
            </Button>
          </Form.Item>
        </Form>
      </div>
    );
  } else {
    return (
      <div className="container-1">
        <div className="head-text">Reset Password</div>
        {/* <div className="lpass-box-text">
          Just enter your email address below and we'll send you instructions on
          how to reset your password
        </div> */}
        <Form
          name="normal_login"
          className="login-form-lpass"
          initialValues={{
            remember: false,
          }}
          onFinish={onFinish}
        >
          <div className="lpass2-span forget-password-check-icon">
            <div>
              <FaCheckCircle className="lpass-checkbox forget-password-lpass-checkbox" />
            </div>
            <div className="lpass2-span-text forget-password-check-text">
              <b>Success</b> Password reset instructions have
              been sent to {emailid} 
            </div>
            {/* <div className="Email_Send_text">
            <p>
              If the email doesn’t show up ,Check your spam folder. We sent it
              from <span>help@skillgigs.com</span>{" "}
            </p>
          </div> */}
          </div>
          <div className="Email_Send_text">
            <p>
              If the email doesn’t show up, check your spam folder. We sent it
              from <span>no-reply@verificationemail.com</span>{" "}
            </p>
          </div>
          <div className="form-label">Code</div>
          <Form.Item
            name="code"
            rules={[
              {
                // pattern: /^([0-9]{1,4})*$/,
                pattern: /^\d{6}$/,
                message: "Enter valid code",
              },
              {
                required: true,
                message: "Please input code",
              },
            ]}
          >
            <Input
              // type="number"
              maxLength="6"
              autoComplete="new-password"
              placeholder="OTP code"
              onChange={(e) => {
                setLengthOTP(e.target.value.length);
              }}
            />
          </Form.Item>
          {OTPMessageTrue && (
            <Infomessage message={errorCodemessage} class={messagetype} />
          )}
          <div className="form-label">New password</div>
          <Tooltip placement="topRight" title={textpass} trigger="click">
            <Form.Item
              name="new_password"
              rules={[
                {
                  // type: "regexp",
                  pattern: /^(?=.*?[A-Z])(?=.*?[a-z])(?=.*?[0-9]).{8,}$/,
                  message: "The input is not a valid Password",
                },
                {
                  required: true,
                  message: "Please input new password",
                },
              ]}
            >
              <Input.Password
                placeholder="New password"
                autoComplete="new-password"
                iconRender={(visible) => (visible ? "Hide" : "Show")}
              />
            </Form.Item>
          </Tooltip>

          <div className="form-label">Confirm password</div>
          <Form.Item
            name="confirm_password"
            rules={[
              {
                required: true,
                message: "Please confirm new password!",
              },
            ]}
          >
            <Input.Password
              placeholder="Confirm password"
              iconRender={(visible) => (visible ? "Hide" : "Show")}
            />
          </Form.Item>
          {passwordMisMatch === "container-MisMatch" && (
            <Infomessage message={errormessage} class={messagetype} />
          )}
          <Form.Item>
            <Button
              type="primary"
              htmlType="submit"
              className="login-form-button-lpass"
              // onClick={mailSent}
            >
              Submit
            </Button>
          </Form.Item>
        </Form>
        {/* <div className="email_id_field">
          <input value={emailid} className="input_feild" />
        </div> */}
        {/* <div className="lpass2-span">
          <FaCheckCircle className="lpass-checkbox" />
          <div className="lpass2-span-text">
            <b>Success</b> Password reset instructions have
            <br />
            been sent to {emailid}
          </div>
          <div className="Email_Send_text">
            <p>
              If the email doesn’t show up ,Check your spam folder. We sent it
              from <span>help@skillgigs.com</span>{" "}
            </p>
          </div>
        </div> */}
      </div>
    );
  }
};

const mapStateToProps = (state) => {
  return {
    user: state.chekckUserReducer.user,
    error: state.chekckUserReducer,
    // userProfile: state.profileReducer.user.profile,
  };
};

const mapDispatchToProps = (dispatch) => {
  return {
    checkUser: (params) => {
      dispatch(fetchExistingtUser(params)); // action dispatched
    },
  };
};

export default connect(mapStateToProps, mapDispatchToProps)(LostPassword);