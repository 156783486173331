import React from "react";
import UploadDocV2 from "../../UploadDoc";

const Reference1_DocV2 = () => {
  const docInfo = [
    {
      "Reference 1": 86,
    },
  ];
  return (
    <div>
      <UploadDocV2
        docInfo={docInfo}
        backUrl="driver-license-doc"
        nextUrl="reference2-doc"
        currentPage={8}
        WizardFlow={117}
      />
    </div>
  );
};

export default Reference1_DocV2;
