import React from "react";
import "./PhoneNumberInput.css";
import { Input } from "antd";
import { WarningFilled } from "@ant-design/icons";
function PhoneNumberInput({ title, type, placeholder, onChange, value, error, maxLength, countryCode }) {
  return (
    <div className="TextInputParentContainer">
      <div className="textInputContainer">
        <div className="TextInputTitleContainer">
          <div className="TextInputTitle">{title}</div>
          <div className="TextInputType">({type})</div>
        </div>
        <div className="inputContainer">
        <div className={`cotryCodeContainer${error ? 'Error' : ''}`}>
          <div className={`cotryCodeContainerinner${error ? 'Error' : ''}`}>
            <div className="country-code">+{countryCode}</div>
            <div className={value?.length<1?"border-class" : 'border-class-withnumber'} />
          </div>
        </div>
        <Input
          defaultValue={null}
          onChange={(e) => onChange(e)}
          className={`MobileInputStyle${error ? 'Error' : ''}`}
          placeholder={placeholder}
          value={value}
          status={error ? 'error' : ''}
          help={error}
          maxLength={15}
        />
        </div>
        {error && 
          <div className="errorMsg">
          <WarningFilled className="errorIcon"/>{error}
          </div>
        }
      </div>
    </div>
  );
}
export default PhoneNumberInput;
