import React, { useEffect } from "react";
import { CheckCircleTwoTone } from "@ant-design/icons";
import { Col } from "antd";
import { Link, useHistory, useLocation } from "react-router-dom";
import Panel from "./Panel";
import BidHeader from "./BidHeader";
import checkIcon from "../../../assets/img/check.png";
import "./bidSuccess.css";
import { useDispatch } from "react-redux";

import { createBidReset } from "../../../redux/actions";
import { BsCheckCircleFill } from "react-icons/bs";

const Success = () => {
  let {state}  = useLocation();
  if(state) {
    localStorage.setItem("bid-success-data",JSON.stringify(state));
  }
  else {
    let bidSuccessData = localStorage.getItem("bid-success-data");
    state = bidSuccessData && JSON.parse(bidSuccessData)|| {};
  }
  const history = useHistory();
  const { taxTerm } = state;
  const { message } = state;
  const { rate, rateBy, title, RegionName, budget } = state;
  // // 
  // // 
  // 

  const dispatch = useDispatch();

  useEffect(() => {
    dispatch(createBidReset());
  });
  const clickHandler = () => {
    history.push(`/details/${state.id}`);
  };

  return (
    <div className="bidSuccessDiv">
      <div className="bidcsuccess-box">
        <div className="bidsuccess-check">
          <BsCheckCircleFill className="primaryColor-green bidsuccess-icon" />
        </div>
        <div className="bidsuccess-title">
          Bid successfully {state?.Updated ? "updated" : "placed"} on gig
        </div>
        <div className="bidsuccess-detail">
          <div className="bidsuccess-item">
            <span className="bidsuccess-detail-left">Gig</span>
            <span className="bidsuccess-detail-right primaryColor-blue title-text-style">
              <Link
                to={{
                  pathname: `/details/${state.id}`,
                }}
                className="bidsuccess-detail-right primaryColor-blue title-text-style"
              >
                <u>{title}</u>
              </Link>
            </span>
          </div>
          {/* <div className="bidsuccess-item">
            <span className="bidsuccess-detail-left">Job rate</span>
            <span className="bidsuccess-detail-right">
              ${budget ? budget : 0}/wk
            </span>
          </div> */}
          {RegionName && <div className="bidsuccess-item">
            <span className="bidsuccess-detail-left">Location</span>
            <span className="bidsuccess-detail-right">
              {RegionName?.Location
                ? RegionName?.Location
                : RegionName
                ? RegionName
                : "N/A"}
            </span>
          </div>
          }
          <div className="bidsuccess-item">
            <span className="bidsuccess-detail-left">Tax term</span>
            <span className="bidsuccess-detail-right title-text-style">
              {taxTerm ? taxTerm : "N/A"}
            </span>
          </div>
          <div className="bidsuccess-item">
            <span className="bidsuccess-detail-left">
              Estimated pay package
            </span>
            <span className="bidsuccess-detail-right">
              ${rate ? parseFloat(rate)?.toLocaleString('en-US') : 0}{" "}
              {rateBy === "Hourly" || rateBy === "Hour" ? "/HR" : rateBy === "Yearly" || rateBy === "Year" ? "/YR" : "/WK"}
            </span>
          </div>

          <section className="bidsuccess-coverletter">
            <div className="cover-bs">Cover letter</div>
            <div className="letter-bs">{message ? message : "N/A"}</div>
          </section>
        </div>
      </div>
    </div>
  );
};
export default Success;
