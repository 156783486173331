import { call, put, takeEvery, select } from "redux-saga/effects";
import { types } from "../action-types";
import { PrivateEmpSuggestionAPI, fetchSearchSuggestionsAPI } from "../../API/API";
import { fetchPvtCompanyFailure, fetchSearchSuggestionsFailure } from "../actions";


function* SearchSuggestionsSaga(params) {
  // 
  try {
    const response = yield call(fetchSearchSuggestionsAPI, params); // API Backend
    // 
    yield put({
      type: types.FETCH_SEARCH_SUGGESTIONS_SUCCESS,
      payload: response.data,
    });
  } catch (error) {
    // 
    yield put(fetchSearchSuggestionsFailure(error));
  }
}

function* PrivateEmpListSuggestion(params){
  try {
    const response = yield call(PrivateEmpSuggestionAPI, params); // API Backend
    yield put({
      type: types.PRIVATE_COMPANY_LIST_SUCCESS,
      payload: response.data,
    });
  } catch (error) {
    yield put(fetchPvtCompanyFailure(error));
  }
}

export function* watchSearchSuggestionsSaga() {
  yield takeEvery(types.FETCH_SEARCH_SUGGESTIONS_INIT, SearchSuggestionsSaga);
}

export function* watchPrivateEmpListSuggestionSaga() {
  yield takeEvery(types.PRIVATE_COMPANY_LIST_INIT, PrivateEmpListSuggestion);
}
