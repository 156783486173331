import { Button, Col, Row } from "antd";
import React from "react";
import "./BottomButtons.css";

function BottomButtons({ backHandler, nextHandler, bottom, nextBtnLoading }) {
  const dimension = window.innerWidth;
  return (
      <div className="BottomButtonsParentContainer"
        style={ dimension > 768 ? { bottom: bottom ? "-4rem" : 0, position: "absolute" } : {}}>
      <div className="buttonsRowContainer">
        <Row className={"Btns-row"}>
          {dimension > 768 && (
            <Col lg={11} xs={24} md={10} className={"Back-btn-div"}>
              <Button className="Back-btn" onClick={backHandler}>
                Back
              </Button>
            </Col>
          )}
          {dimension > 768 && (
            <div className="temp-gap-div" ></div>
          )}
          <Col lg={11} xs={24} md={10} className={"Next-btn-div"}>
            <Button className="Next-btn" onClick={nextHandler} loading={nextBtnLoading}>
              Next
            </Button>
          </Col>
          {dimension <= 768 && (
            <Col lg={11} xs={24} md={11} className={"Back-btn-div"}>
              <Button className="Back-btn" onClick={backHandler}>
                Back
              </Button>
            </Col>
          )}
        </Row>
      </div>
    </div>
  );
}

export default BottomButtons;
