import React, { useEffect, useRef, useState } from "react";
import { Row, Col } from "antd";
import "./NotificationItem.css";
import moment from "moment";
import { getImage } from "../../shared/downloadAttachments";
import { useDispatch, useSelector } from "react-redux";
import { useHistory } from "react-router-dom";
import skillgig_log_msg from '../../assets/img/Skillgigs_Msg_logo.png'
import enums from "./Enum";
import { getNotifications, readNotification } from "../../redux/actions";

export default function NotificationItem(props) {
  const notificationCard = useRef();
  const [notificationId, setNotificationId] = useState("");
  const [imageUrl, setimageUrl] = useState("");
  const [imageError, setImageError] = useState(false);
  const history = useHistory();
  let userProfile = useSelector((state) => state.profileReducer.user);
  const dispatch = useDispatch();
  useEffect(() => {
    getPicture(props?.img);
  }, []);

  //   useEffect(() => {
  //     // if (notificationId) {
  //     //   dispatch(readNotification(notificationId));
  //     // }
  // }, []);
  const getPicture = (Key) => {
    const  url = Key;
    if (url !== null) {
      // var key = imageURLSplit(url);
      getImage(url)
        .then((result) => {
          setimageUrl(result);
        })
        .catch(() => {
        });
    } 
  };
  const AdminMessage = ["Admin-Talent NoSubject", "Admin-Talent Subject"];
  return (
    <Row
      ref={notificationCard}
      key={props.index}
      style={{ backgroundColor: props?.item?.Read === 1 ? "white" : "#f0f4ff" }}
      className="notificationItem"
      onClick={(e) => {
        e.stopPropagation();
        dispatch(readNotification(props?.item?.NotificationId));
        setNotificationId(props.item.NotificationId);
        notificationCard.current.style.backgroundColor = "white";
        if (props.message == "Welcome to SkillGigs") {
          // window.open(
          //   "https://www.youtube.com/watch?v=rla6vJsIvtY",
          //   "_blank",
          //   "noreferrer"
          // );
          dispatch(readNotification(props?.item?.NotificationId));
          history.push({
            pathname: "/3d_resume",
          });

          props.visible(false);
          props.setisNotification(false);
          return;
        }
        if (props.message == "Resume Uploaded") {
          return;
        }
        dispatch(readNotification(props?.item?.NotificationId));
        setTimeout(() => {
          dispatch(getNotifications());
        }, 2000);
        setNotificationId(props.item.NotificationId);
        notificationCard.current.style.backgroundColor = "white";
        if (props?.message == "Moved to Rating") {
          history.push({
            pathname: "/rating_form",
            state: {
              bidId: props?.item?.SecondarySourceId,
              image: props?.item?.senderprofile[0]?.Profiles?.PictureUrl,
              candidate_name: props?.item?.senderprofile[0]?.Profiles?.CompanyName,
              Reviewee: props?.item?.senderprofile[0]?.AccountId,
              job_title: props?.item?.SourceName,
              previousScreen: "Notifications",
              conversationId: props?.item?.conversationId,
              SourceTypeId: props?.item?.SourceTypeId,
              sourceName: props?.item?.SourceTypeId == 1 ? "Bid" : "Proposal",
            },
          });
        }
        if ((props?.item?.conversationId || props?.item?.ConversationId || props?.message === "1st bid follow up" || props?.message === "2nd bid follow up" || props?.message === "3rd bid follow up" || props?.message === "4th bid follow up" || props?.message === "5th bid follow up") && props?.message != "Moved to Rating") {
          history.push({
            pathname: "/messages",
            state: {
              openConvo: true,
              conversationId: props.item.conversationId ? props?.item?.conversationId : props?.item?.ConversationId,
              notification: true,
              loadChat: true
            },
          });
        }
        if ((props?.message === "1st onBilling follow up" || props?.message === "2nd onBilling follow up" || props?.message === "1st offBilling follow up") && props?.message != "Moved to Rating") {
          history.push({
            pathname: "/search",
          });
        }
        if (props?.message == "New Gig Alert") {
          history.push({
            pathname: `/details/${props?.item?.SourceId}`
          });
        }
        if (
          props?.message == "Skilllisting Viewed by employer" ||
          props?.message == "Resume Viewed by employer"
        ) {
          history.push(
            `/company-profile/${props?.item?.senderprofile[0]?._id}`
          );
        }
        props.visible(false);
        props.setisNotification(false);
      }}
    >
      <Col span={4} className="imageCont">
        <div className={`${AdminMessage?.includes(props?.message) && "AdminMessage-Cantainer-nod"}`} >
          {props?.img && !imageError || AdminMessage?.includes(props?.message) ? (
            // <img src={imageUrl} alt={props?.item?.senderprofile[0]?.UserName?.charAt(0)} className="noti-img"/>
            <img
              onError={() => {
                setImageError(true);
              }}
              style={{ height: "100%", borderRadius: "50%" }} //width: "100%",
              src={AdminMessage?.includes(props?.message) ? skillgig_log_msg : `${process.env.REACT_APP_S3_URL}${
                props?.img?.charAt(0) !== "/" ? "/" : ""
              }${props?.img}`}
            />
          ) : (
            <div className="image-notifi-container">
              <div className="image-notifi-text">
                { props?.item?.senderprofile[0]?.Profiles?.CompanyName ?
                  props?.item?.senderprofile[0]?.Profiles?.CompanyName?.charAt(
                  0
                ).toUpperCase() : props?.item?.senderprofile[0]?.FirstName ?
                props?.item?.senderprofile[0]?.FirstName?.charAt(
                  0
                ).toUpperCase() : props?.item?.senderprofile[0]?.UserName?.charAt(
                  0
                ).toUpperCase()
                }
              </div>
            </div>
          )}
        </div>
      </Col>
      <Col span={18}>
        <div className="notificationTitle">
          {/* {props.message != "Welcome to SkillGigs" ||
            (props.message != "skilllisting added to matches" && ( */}
          {/* <span>
                {userProfile?.profile?.FirstName +
                  " " +
                  userProfile?.profile?.LastName} ,
              </span> */}
          {/* ))} */}
          {/* {" you recieved new message from "} */}
          {/* {enums[props?.message]
            ? enums[props?.message]
            : " you recieved new message from "}
          {
          props.message == "skilllisting added to matches" ? (
            <span>
              {` for ${props?.item?.senderprofile[0]?.Profiles?.CompanyName}`} */}

          {<span>
            {
            // props?.message == "Bid is placed" || 
            props?.message == "Profile is submitted"
            // || props?.message == "Offer is made"
            // || props?.message == "Candidate is hired"
            // || props?.message == "Interview is canceled"
            // || props?.message == "Skilllisting Viewed by employer"
            || props?.message == "Resume Viewed by employer"
            || props?.message == "Resume Uploaded" 
            // || props?.message == "Rating is created"
            || props?.message == "Candidate is cancelled"
            || props?.message == "Candidate is terminated"
            || props?.message == "Offer is accepted" ? enums[props?.message] ? enums[props?.message] + props?.item?.senderprofile[0]?.Profiles?.CompanyName   : " You've got a new message from " 
            : props?.message == "Bid is placed" ? enums[props?.message] ? enums[props?.message] + (props?.item?.SourceName ? "for " + props?.item?.SourceName : "") + " from " + props?.item?.senderprofile[0]?.Profiles?.CompanyName + ". Act fast, you have 24 hours to respond." : " You've got a new message from "
            : props?.message == "Bid is accepted" ? enums[props?.message] ? enums[props?.message] + (props?.item?.SourceName ? "for " + props?.item?.SourceName : "")+ " was accepted by " + props?.item?.senderprofile[0]?.Profiles?.CompanyName : " You've got a new message from "
            : props?.message == "Offer is made" ? enums[props?.message] ? enums[props?.message] + (props?.item?.SourceName ? "for " + props?.item?.SourceName : "") + " from " + props?.item?.senderprofile[0]?.Profiles?.CompanyName : " You've got a new message from "
            : props?.message == "Interview is rescheduled" ? enums[props?.message] ? props?.item?.senderprofile[0]?.Profiles?.CompanyName  + enums[props?.message] + (props?.item?.SourceName ? " for gig " + props?.item?.SourceName : "")  : " You've got a new message from "

            : props?.message == "Interview is canceled" ? enums[props?.message] ? enums[props?.message] + (props?.item?.SourceName ? " for "+  props?.item?.SourceName : "")  + " was Declined by " + props?.item?.senderprofile[0]?.Profiles?.FirstName + " " + props?.item?.senderprofile[0]?.Profiles?.LastName : " You've got a new message from "
            : props?.message == "Interview is accepted" ? enums[props?.message] ? enums[props?.message] + (props?.item?.SourceName ? " for "+ props?.item?.SourceName : "") + " by " + props?.item?.senderprofile[0]?.Profiles?.FirstName + " " + props?.item?.senderprofile[0]?.Profiles?.LastName  : " You've got a new message from "
            : props?.message == "bid updated by employer" || props?.message == "bid placed notify employer" ? enums[props?.message] ? enums[props?.message] + (props?.item?.SourceName ? "for " + props?.item?.SourceName : "") + " from " + props?.item?.senderprofile[0]?.Profiles?.FirstName + " " + props?.item?.senderprofile[0]?.Profiles?.LastName + "!"  : " You've got a new message from "

            : props?.message == "Candidate is hired" ? enums[props?.message] ? enums[props?.message] + (props?.item?.SourceName ? "for " + props?.item?.SourceName : "") + " by " + props?.item?.senderprofile[0]?.Profiles?.CompanyName  : " You've got a new message from "

            : props?.message == "Candidate is moved to on billing" ? enums[props?.message] ? enums[props?.message] + props?.item?.senderprofile[0]?.Profiles?.CompanyName + (props?.item?.SourceName ? " for " + props?.item?.SourceName : "") + " has officially started " + (props?.item?.SourceOfferDate ? moment(props?.item?.SourceOfferDate?.StartTime).format('MM/DD/YYYY') + " and ends on " + moment(props?.item?.SourceOfferDate?.EndTime).format('MM/DD/YYYY')  : "")  : " You've got a new message from "
            : props?.message == "Your bid has been retracted" ? enums[props?.message] ? enums[props?.message] + props?.item?.senderprofile[0]?.Profiles?.CompanyName + " canceled their bid on your listing " + props?.item?.SourceName   : " You've got a new message from "
            : props?.message == "Job is completed" ? enums[props?.message] ? enums[props?.message] + props?.item?.senderprofile[0]?.Profiles?.CompanyName + (props?.item?.SourceName ? " for " + props?.item?.SourceName : "") + " has ended " + (props?.item?.SourceOfferDate ? "on " + moment(props?.item?.SourceOfferDate?.EndTime).format('MM/DD/YYYY')  : "")  : " You've got a new message from "
            : props?.message == "Moved to Rating" ? enums[props?.message] ? enums[props?.message] + (props?.item?.SourceName ? " "+ props?.item?.SourceName : "") + "! Give "+ props?.item?.senderprofile[0]?.Profiles?.FirstName + " " + props?.item?.senderprofile[0]?.Profiles?.LastName + " a 5-star rating "  : " You've got a new message from "
            : props?.message == "New Gig Alert" ? enums[props?.message] ? enums[props?.message] : " You've got a new message from "

            : props?.message == "Rating is created" ? enums[props?.message] ? enums[props?.message]  + props?.item?.senderprofile[0]?.Profiles?.CompanyName + " for the gig " + (props?.item?.SourceName ? props?.item?.SourceName+"!" : "")   : " You've got a new message from "
            : props?.message == "Bid is declined" ? enums[props?.message] ? enums[props?.message] + props?.item?.senderprofile[0]?.Profiles?.CompanyName + " declined your bid " + (props?.item?.SourceName ? "for " + props?.item?.SourceName : "") : " You've got a new message from "
            : props?.message == "1st bid follow up" ? enums[props?.message] ? enums[props?.message] + (props?.item?.SourceName ? "for " + props?.item?.SourceName : "") + " from " + props?.item?.senderprofile[0]?.Profiles?.CompanyName + " is still pending. Don't miss out on this opportunity. 18 hours left!"  : " You've got a new message from "
            : props?.message == "2nd bid follow up" ? enums[props?.message] ? enums[props?.message] + (props?.item?.SourceName ? "for gig " + props?.item?.SourceName : "") + ` from ${props?.item?.senderprofile[0]?.Profiles?.CompanyName}.` : " You've got a new message from "
            : props?.message == "3rd bid follow up" ? enums[props?.message] ? enums[props?.message] + (props?.item?.SourceName ? "for " + props?.item?.SourceName : "") + ` from ${props?.item?.senderprofile[0]?.Profiles?.CompanyName}.` : " You've got a new message from "
            : props?.message == "4th bid follow up" ? enums[props?.message] ? enums[props?.message] + (props?.item?.SourceName ? "for " + props?.item?.SourceName : "") + " from " + props?.item?.senderprofile[0]?.Profiles?.CompanyName + " before it expires."  : " You've got a new message from "
            : props?.message == "5th bid follow up" ? enums[props?.message] ? enums[props?.message] + (props?.item?.SourceName ? "for " + props?.item?.SourceName : "") + " from " + props?.item?.senderprofile[0]?.Profiles?.CompanyName + " has expired. Be sure to act faster next time to seize opportunities."  : " You've got a new message from "
            : props?.message == "1st onBilling follow up" ? enums[props?.message] ? enums[props?.message] : " You've got a new message from "
            : props?.message == "2nd onBilling follow up" ? enums[props?.message] ? enums[props?.message] : " You've got a new message from "
            : props?.message == "1st offBilling follow up" ? enums[props?.message] ? enums[props?.message] : " You've got a new message from "
            : props?.message == "Interview is proposed" || props?.message == "Interview is scheduled" ? enums[props?.message] ? enums[props?.message] + props?.item?.senderprofile[0]?.Profiles?.CompanyName + " sent you an interview request " + (props?.item?.SourceName ? "for " + props?.item?.SourceName+ "!" : "")  : " You've got a new message from "
            : props?.message == "Skilllisting Viewed by employer" ? enums[props?.message] ? enums[props?.message] + (props?.item?.SourceName ? props?.item?.SourceName : "")  + " was Viewed by " + props?.item?.senderprofile[0]?.Profiles?.CompanyName : " You've got a new message from "
            : props?.message == "Admin-Talent Subject" ? enums[props?.message] + " " + props?.item?.Subject : props?.message == "Admin-Talent NoSubject" ? enums[props?.message] 
            : enums[props?.message] ? enums[props?.message] : " You've got a new message from " +props?.item?.senderprofile[0]?.Profiles?.CompanyName + (props?.item?.SourceName ? " for "+ props?.item?.SourceName : "")     
            } 
              </span>}
        </div>
        {props.message === "Interview" ? (
          <>
            <div className="interviewerName">
              Interview with {props?.interviewer}
            </div>
            <div className="interviewTime">
              {moment(props.scheduleTimings).endOf("day").fromNow()}{" "}
              {moment(props.startTime).format("HH:MM A")} -{" "}
              {moment(props.endTime).format("HH:MM A")}
            </div>
            <div className="attendInterview">Attend Interview</div>
          </>
        ) : null}
        <div className="notificationTime">
          {moment(props.created).fromNow()}
        </div>
      </Col>
    </Row>
  );
}
