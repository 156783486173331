import { call, put, takeEvery, takeLatest, takeLeading } from "redux-saga/effects";
import { types } from "../action-types";
import { fetchProposalsApi } from "../../API/API";

function* fetchProposalsSaga(action) {
  // 
  try {
    const { id, limit, skip, status } = action.payload;
    const response = yield call(() =>
      fetchProposalsApi(id, limit, skip, status)
    );
    // 
    yield put({ type: types.FETCH_PROPOSALS_SUCCESS, payload: response.data });
  } catch (error) {
    yield put({ type: types.FETCH_PROPOSALS_FAILURE, error });
  }
}

export function* watchFetchProposalsSaga() {
  yield takeLeading(types.FETCH_PROPOSALS_REQUESTED, fetchProposalsSaga);
}
