import React, { useState } from "react";
import "./CustomTooltip.css"; // Create a CSS file for styling
import { InfoCircleOutlined } from "@ant-design/icons";

const CustomTooltip = ({ text, children, alignmentClass }) => {
    const [showTooltip, setShowTooltip] = useState(true);

    const handleMouseEnter = () => {
        setShowTooltip(true);
    };

    const handleMouseLeave = () => {
        setShowTooltip(false);
    };

    return (
        <div
            className={`tooltip-container ${alignmentClass}`}
        // onMouseEnter={handleMouseEnter}
        // onMouseLeave={handleMouseLeave}
        >
            {children}
            {showTooltip && (
                <div className="tooltip">
                    <div className="custom-tooltip-info-box">
                        <span className="custom-tooltip-icon">
                            <InfoCircleOutlined />
                        </span>
                        <div>
                            Clicking on <strong>‘Accept’</strong> on a bid doesn’t mean a
                            job offer or you've been hired. It simply indicates you would like
                            to consider this job opportunity.
                        </div>
                    </div>
                    <div
                        onClick={() => {
                            setShowTooltip(false);
                        }}
                        className="custom-tooltip-close-button"
                    >
                        <strong>Got it</strong>
                    </div>
                </div>
            )}
        </div>
    );
};

export default CustomTooltip;
