import React, { useEffect, useState } from "react";
import { IoLocationSharp, IoBriefcase } from "react-icons/io5";
import { BsCurrencyDollar, BsStarFill } from "react-icons/bs";
import { HiClock } from "react-icons/hi";
import { useHistory } from "react-router-dom";
import { HeartFilled, StarOutlined } from "@ant-design/icons";
import { Rate } from "antd";

import "./GuestGigsCard.css";
import {
  createBidReset,
  ResetBidData,
  retractBidReset,
  updateBidReset,
} from "../../../redux/actions";
import { useDispatch } from "react-redux";
import { getImageByStateCode } from "../../../shared/helpers";
import moment from "moment";

const bidsData = [
  {
    bidReceived: "4100/wk",
    title: "RN - ICU Nurse",
    facility: "Kindred Hospital, Central Tampa, ...",
    gigTitle: "Del Sol Medical Center El Paso",
    location: "Pittsburgh, PA",
    gigRate: "4000/wk",
  },
  {
    bidReceived: "4200/wk",
    title: "RN - ICU Nurse",
    facility: "Kindred Hospital, Central Tampa, ...",
    gigTitle: "Del Sol Medical Center El Paso",
    location: "Pittsburgh, PA",
    gigRate: "4000/wk",
  },
  {
    bidReceived: "4300/wk",
    title: "RN - ICU Nurse",
    facility: "Kindred Hospital, Central Tampa, ...",
    gigTitle: "Del Sol Medical Center El Paso",
    location: "Pittsburgh, PA",
    gigRate: "4000/wk",
  },
];

// const screenWidth = window.screen.width;

const GuestGigsCard = (props) => {
  const gig = props.data;
  const history = useHistory();
  const dispatch = useDispatch();
  const [placeholder, showPlaceholder] = useState(false);

  const PlacedBid = () => {
    dispatch(createBidReset());
    dispatch(updateBidReset());
    dispatch(ResetBidData());
    // localStorage.setItem("hi", "hi");
    // localStorage.setItem("image",getImageByStateCode(
    //   gig?.Location?.RegionCode,
    //   gig?.Location?.CityName
    // ));
    history.push({
      pathname: `/create-bid`,
      state: {
        title: gig.Title,
        FacilityName: gig.FacilityName,
        RegionName: gig.RegionName,
        budget: gig.BudgetMin,
        id: gig._id,
        AccountRole: gig?.AccountRole
      },
    });
  };
  // const [dimensions, setDimensions] = useState(window.innerWidth);

  // const checkDimensions = () => {
  //   setDimensions(window.innerWidth);
  // };

  // useEffect(() => {
  //   window.addEventListener("resize", checkDimensions);
  // }, []);

  // const dataToBeRendered = () => {
  //   if (dimensions <= 600) {
  //     return bidsData.filter((data, i) => i === 0);
  //   } else return bidsData;
  // };

  // // 
  // Gig created on
  // let msec = Number(gig.Created);
  // let time = new Date(msec);
  let time = new Date(gig?.Created.toString());

  let year = time.getFullYear();
  let months = [
    "January",
    "February",
    "March",
    "April",
    "May",
    "June",
    "July",
    "August",
    "September",
    "October",
    "November",
    "December",
  ];
  let month = months[time.getMonth()];
  let date = time.getDate();
  let days = [
    "Sunday",
    "Monday",
    "Tuesday",
    "Wednesday",
    "Thursday",
    "Friday",
    "Saturday",
  ];
  let day = days[time.getDay()];
  const dates = { day, date, month, year }.toString();

  // Gig Expires on
  let expmsec = Number(gig.ExpiryDate);
  let exptime = new Date(expmsec);
  let expyear = exptime.getFullYear();
  let expmonth = months[exptime.getMonth()];
  let expdate = exptime.getDate();
  let expday = days[exptime.getDay()];
  const expdates = { expday, expdate, expmonth, expyear }.toString();

  // // 

  return (
    <React.Fragment>
      <div className="g-sgigs-thecard">
        {placeholder ? (
          <img
            src={getImageByStateCode(
              gig?.Regions?.Code
              // gig?.Location?.CityName
            )}
            className="g-sgigs-image"
          />
        ) : (
          <img
            src={getImageByStateCode(gig?.Regions?.Code, gig?.Cities?.CityName)}
            className="g-sgigs-image"
            onError={(e) => showPlaceholder(true)}
          />
        )}
        <div className="g-sgigs-upAbove">
          <div className="g-sgigs-theTitle">{gig.Title ? gig.Title : "NA"}</div>
          <div className="g-sgigs-theFacility">
            {gig.FacilityName ? gig.FacilityName : "NA"}
          </div>
          <div className="g-sgigs-information">
            {/* <Rate count={1} disabled={true} value={1} /> */}
            <BsStarFill className="g-sgigs-star" />
            <div className="s-sgigs-rating">
              {gig?.facilityRating?.rating ? gig?.facilityRating?.rating : 0}
            </div>
            <div className="g-sgigs-review">
              <div>
                (
                {gig?.facilityRating?.reviews
                  ? gig?.facilityRating?.reviews
                  : 0}
                )
              </div>
              <div>{moment(time).fromNow()}</div>
            </div>
          </div>

          <div
            style={{
              width: "390px",
              height: "1px",
              backgroundColor: "#eee",
              margin: "1rem -.9rem",
            }}
          ></div>
          <div className="g-sgigs-gigcard-interestedBtn">
            <div className="g-sgigs-Budgets">
              <span style={{ fontWeight: "600" }}>
                <BsCurrencyDollar />
              </span>
              <span
                style={{
                  fontSize: "22px",
                  margin: "-2px 0px 0px -3px",
                }}
              >
                {gig.BudgetMin ? gig.BudgetMin : 0}/WK
              </span>
            </div>
            {/* <div className="g-sgigs-div1">Pay Package</div> */}
            {/* <div className="g-sgigs-div2" onClick={PlacedBid}>
                $ Place a Bid
              </div> */}
            <Rate character={<HeartFilled />} count={1} allowClear />
          </div>
          <div className="g-sgigs-div1">Pay package</div>
        </div>
      </div>
      {/* ); */}
    </React.Fragment>
  );
};

export default GuestGigsCard;
