import { types } from "../action-types";

const initialState = {
  Searchinfo: [],
  searchValueInfo: [],
  searchCloseIconInfo: [],
};

const onClearSearchReducer = (state = initialState, action) => {
  // // 
  switch (action.type) {
    case types.CLEAR_SEARCH_INIT:
      state.Searchinfo = action.payload;
      // 
      return state;
    case types.CLEAR_SEARCH_VALUE_INIT:
      state.searchValueInfo = action.payload;
      // 
      return state;
    case types.CLEAR_SEARCH_ICON_INIT:
      state.searchCloseIconInfo = action.payload;
      // 
      return state;

    default:
      return state;
  }
};

export default onClearSearchReducer;
