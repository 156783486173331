import { Progress } from "antd";
import React from "react";
import { useSelector } from "react-redux";
import { useHistory } from "react-router-dom";

function ProfileDetailsFirst(props) {
  const talentInfo = useSelector((state) => state.profileReducer.talentInfo);
  const history = useHistory();
  const time = talentInfo?.ResponseRate;
  var Hours = Math.floor(time / 60);
  var minutes = time % 60;
  // 
  return (
    <React.Fragment>
      <div
        className="Dashboard-Profile-Card-main-container-tunior"
        onClick={() => history.push("/dummy")}
      >
        <div className="Dashboard-Profile-Card-main-container-continor">
          <div className="Dashboard-Profile-Card-main-container-itemn1">
            Gig completion
          </div>

          <div className="Dashboard-Profile-Card-main-container-itemn2">
            <div>
              <Progress
                percent={talentInfo?.JobCompletion}
                status="active"
                format={() => ""}
              />
            </div>
          </div>

          <div className="dashboard-profile-percentage">
            {talentInfo?.JobCompletion} %
          </div>
        </div>
        <div className="Dashboard-Profile-Card-main-container-continor2">
          <div className="Dashboard-Profile-Card-main-container-itemn4">
            Average response time
          </div>
          <div className="Dashboard-Profile-Card-main-container-itemn3">
              {Hours > 0 ? Hours : <span className="notapllicable">N/A</span>}
              {Hours ? " hours " : null} {minutes ? minutes : null}
              {minutes ? " min" : null}
          </div>
        </div>
      </div>

      <div
        className="Dashboard-Profile-Card-main-container-rtyu"
        onClick={() => history.push("/dummy")}
      >
        <div className="Dashboard-Profile-Card-main-container-rhys">
          Earned in {talentInfo?.Earned?.month}
        </div>
        <div className="Dashboard-Profile-Card-main-container-kjys">
          {/* $ {talentInfo?.Earned?.amount} */}
          $0
        </div>
      </div>
    </React.Fragment>
  );
}

export default ProfileDetailsFirst;
