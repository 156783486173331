import { types } from "../action-types";
import moment from "moment";
const initialState = {
  loading: false,
  // chats: [],
  // chat:{},
  error: false,

  //new states
  inboxLoading: false,
  sentItemsLoading: false,
  sendMessageLoading: false,
  inbox: { data: { data: { body: { conversations: [] } } } },
  inboxHeader: { data: { data: { body: { conversations: [] } } } },
  sentItems: [],
  inboxError: {},
  sentItemsError: {},
  sendMessageError: {},
  historyLoading: false,
  chats: [],
  bidStatus: [],
  error: false,
  uploadFileRes: "",
  editOfferRes: false,
  editOfferLoading: false,
  counts: {},
  newMsgInSocket: false,
  hasMorechats: true,
  page: 3,
  jobDetail: [],
  dashboardMsgInboxLoading: true,
  dashboardMsgInbox: [],
  dashboardMsgInboxError: "",
  onlineUsers: [],
  AdminInboxLoading: false,
  AdminDashboardInbox: [],
};

const getChatHistoryReducer = (state = initialState, action) => {
  switch (action.type) {
    case types.FETCH_CHAT_REQUESTED:
      return {
        ...state,
        loading: true,
      };

    case types.FETCH_CHAT_SUCCESS:
      return {
        ...state,
        loading: false,
        chats: action.payload,
        error: false,
        bidStatus: action.payload.data.body.map((items) => {
          return items.Status;
        }),
      };
    case types.FETCH_CHAT_FAILURE:
      return {
        ...state,
        loading: false,
        chats: [],
        error: true,
      };
    case types.MESSAGES_RECEIVED: {
      // 
      // 
      // if (action.payload?.edited) {

      //     let revMessages = [...state.chats.data.body];

      //     revMessages = revMessages.reverse();

      //     const index = revMessages.findIndex(object => { return object.MessageText === action.payload.message?.data.MessageText });

      //     const lastIndex = revMessages.length - index - 1;

      //     var temp = state.chats.data.body;

      //     temp.splice(lastIndex, 1, action.payload.message.data);

      // }
      if (
        state.chats?.data?.body[0]?.ConversationId ===
        action?.payload?.message?.data?.ConversationId
      )
        if (action.payload.message.edited) {
          let messages = [...state.chats.data.body];
          const index = messages.findIndex(
            (obj) => obj._id == action.payload.message.data._id
          );
          // 
          messages[index] = action.payload.message.data;
          // 
          return {
            ...state,
            chats: {
              data: {
                body: messages,
              },
            },
          };
        } else {
          return {
            ...state,
            chats: {
              data: {
                body: [
                  ...state?.chats?.data?.body,
                  action.payload.message.data,
                  // filterData,
                ],
              },
            },
            bidStatus: [...state.bidStatus, action.payload.message.data.Status],
            notification: action.payload,
          };
        }
      else {
        return { ...state };
      }
    }

    case types.FAVOURITE_SUCCESS: {
      // 
      let favIndex = action.payload;
      let oldChat = state?.inbox?.data?.data?.body?.conversations;

      // 
      // let updatedChat = oldChat?.map((item,index)=>{
      //   if(index == favIndex){
      //     item.IsFavorite = true;
      //   }
      // })
      if (oldChat[favIndex]) {
        oldChat[favIndex].IsFavorite = true;
      }
      let updatedChat = oldChat;
      // 
      // oldChat.IsFavorite = true;
      // const
      return {
        ...state,
        inbox: {
          ...state?.inbox,
          data: {
            ...state?.inbox?.data,
            data: {
              ...state?.inbox?.data?.data,
              body: {
                ...state?.inbox?.data?.data?.body,
                conversations: updatedChat,
              },
            },
          },
        },
      };
    }

    case types.REMOVE_FAV_SUCCESS: {
      // 
      let favIndex = action.payload;
      let oldChat = state?.inbox?.data?.data?.body?.conversations;
      // let updatedChat = oldChat?.map((item,index)=>{
      //   if(index == favIndex){
      //     item.IsFavorite = true;
      //   }
      // })
      oldChat[favIndex].IsFavorite = false;

      let updatedChat = oldChat;
      // 
      // oldChat.IsFavorite = true;
      // const
      return {
        ...state,
        inbox: {
          ...state?.inbox,
          data: {
            ...state?.inbox?.data,
            data: {
              ...state?.inbox?.data?.data,
              body: {
                ...state?.inbox?.data?.data?.body,
                conversations: updatedChat,
              },
            },
          },
        },
      };
    }

    case types.SEND_MESSAGE_SUCCESS: {
      // 
      return {
        ...state,
        chats: [
          {
            ...action.payload.data,
            SenderUser: [
              {
                name: action.payload.name,
                profile: { PictureUrl: action.payload.ProfilePic },
              },
            ],
          },
          ...state.chats,
        ],
      };
    }
    case types.GET_MESSAGES_INBOX_REQUESTED:
      if (action.payload.filter) {
        return {
          ...state,
          inboxLoading: true,
          inbox: { data: { data: { body: { conversations: [] } } } },
          page: 3,
        };
      }
      return {
        ...state,
        inboxLoading: true,
      };
    case types.GET_MESSAGES_INBOX_SUCCESS:
      let conversationData = action?.payload?.data?.data?.body?.conversations;
      let inboxData = state?.inbox?.data?.data?.body?.conversations;
      let inboxDataHeader = state?.inboxHeader?.data?.data?.body?.conversations;
      let conversations = [...inboxData, ...conversationData].reverse();
      conversations = [...new Set(conversations.map((item) => item._id))].map(
        (id) => {
          return conversations.find((item) => item._id === id);
        }
      );
      let tempPage = state.page;
      if (action?.payload?.fetchMore) tempPage = tempPage + 1;
      conversations.sort((a, b) => moment(b.Created) - moment(a.Created));
      if (action?.payload?.bidStatus == 'null' && action?.payload?.bidType == 'all_conversations' && action?.payload?.conversationType == 'all_bids' && action?.payload?.page == 1) {
        let conversationsHeader = [...inboxDataHeader, ...conversationData].reverse();
        conversationsHeader = [...new Set(conversationsHeader.map((item) => item._id))].map(
        (id) => {
          return conversationsHeader.find((item) => item._id === id);
        }
      );
      conversationsHeader.sort((a, b) => moment(b.Created) - moment(a.Created));
        return {
          ...state,
          inbox: {
            ...state?.inbox,
            data: {
              ...state?.inbox?.data,
              data: {
                ...state?.inbox?.data?.data,
                body: {
                  ...state?.inbox?.data?.data?.body,
                  conversations: conversations,
                },
              },
            },
          },
          inboxHeader: {
            ...state?.inboxHeader,
            data: {
              ...state?.inboxHeader?.data,
              data: {
                ...state?.inboxHeader?.data?.data,
                body: {
                  ...state?.inboxHeader?.data?.data?.body,
                  conversations: conversationsHeader,
                },
              },
            },
          },
          inboxLoading: false,
          hasMorechats:
            action.payload.data.data.body.conversations.length > 0 ? true : false,
          page: tempPage,
          newMsgInSocket: action.payload.fromSocket,
        };
      }

      let dashboardMsgInbox = state.dashboardMsgInbox;
      const messageReceived = action.payload?.data?.data?.body?.conversations;
      let newMessage =
        messageReceived?.length === 1 ? messageReceived?.[0] : null;
      if (dashboardMsgInbox.length == 0 && newMessage?.countUnRead) {
        dashboardMsgInbox = [newMessage];
      } else if (newMessage) {
        const newMsgType = newMessage?.skillListing
          ? "received_bids"
          : "placed_bids";
        const existingMsgType = state.dashboardMsgInbox?.[
          state.dashboardMsgInbox.length - 1
        ]?.skillListing
          ? "received_bids"
          : "placed_bids";

        if (newMsgType === existingMsgType) {
          dashboardMsgInbox = state.dashboardMsgInbox.filter(
            (item) => item?._id != newMessage?._id
          );
          dashboardMsgInbox = [newMessage, ...dashboardMsgInbox];
        }
      }

      return {
        ...state,
        inbox: {
          ...state?.inbox,
          data: {
            ...state?.inbox?.data,
            data: {
              ...state?.inbox?.data?.data,
              body: {
                ...state?.inbox?.data?.data?.body,
                conversations: conversations,
              },
            },
          },
        },
        inboxLoading: false,
        hasMorechats:
          action.payload.data.data.body.conversations.length > 0 ? true : false,
        page: tempPage,
        newMsgInSocket: action.payload.fromSocket,
        dashboardMsgInbox,
      };
    case types.GET_MESSAGES_INBOX_FAILURE:
      return {
        ...state,
        inbox: "no messages found",
        inboxLoading: false,
      };
    case types.GET_INBOX_JOB_DETAILS:
      return {
        ...state,
        jobDetail: action.payload,
      };
    case types.GET_MESSAGES_COUNTS_SUCCESS:
      // 
      return {
        ...state,
        counts: action.payload?.data?.body,
      };

    case types.DASHBOARD_MESSAGES_INBOX_REQUESTED:
      return {
        ...state,
        dashboardMsgInboxLoading: true
      };
    case types.DASHBOARD_MESSAGES_INBOX_SUCCESS:
      return {
        ...state,
        dashboardMsgInboxLoading: false,
        dashboardMsgInbox: action.payload?.data,
      };
    case types.FAVORITE_GIG_CARD_FAILURE:
      return {
        ...state,
        dashboardMsgInboxLoading: false,
        dashboardMsgInboxError: action?.error,
      };

    case types.UPDATE_USER_ONLINE_STATUS:
      const { userId, IsUserOnline } = action.payload;
      const updatedUsers = state.onlineUsers.map((user) =>
        user.userId === userId ? { ...user, IsUserOnline } : user
      );
      return { ...state, onlineUsers: updatedUsers };

    case types.ADD_USERS_ONLINE_STATUS:
      const newUsers = action.payload;
      const allUsers = [...state.onlineUsers, ...newUsers];
      return { ...state, onlineUsers: allUsers };
    
    case types.ADMIN_MESSAGING_DASHBOARD_INIT:
      return{
        ...state,
        AdminInboxLoading: true,
      }
    
    case types.ADMIN_MESSAGING_DASHBOARD_SUCCESS:
      return{
        ...state,
        AdminInboxLoading: false,
        AdminDashboardInbox: action.payload
      }

    default:
      return state;
  }
};

export default getChatHistoryReducer;
