import { RightOutlined } from "@ant-design/icons";
import { Avatar, Rate, Button, Form, Divider } from "antd";
import React, { useState, useEffect } from "react";
import "./RatingForm.css";
import { useSelector, useDispatch } from "react-redux";
import { Input } from "antd";
import { getImage } from "../../../../shared/downloadAttachments";
import { useLocation } from "react-router-dom";
import { AiFillStar, AiOutlineStar } from "react-icons/ai";
import Header from "../../../../components/Header/Header";
import { LoadingOutlined } from "@ant-design/icons";
import greenTick from "../../../../assets/img/GreenTick.png";
import { useHistory } from "react-router-dom";
import { CreateRatingInit, UpdateRatingInit, getRating } from "../../../../redux/actions";
import Loader from "../../../../components/Loader/Loader";
const { TextArea } = Input;
export const RatingForm = () => {
  const location = useLocation();
  const dispatch = useDispatch();
  const history = useHistory();
  const ratingData = useSelector((state) => state.getRatingReducer.data);

  const ratings_loading = useSelector((state) => state.ratingReducer.loading);
  const rating_page_loading = useSelector((state) => state.getRatingReducer.loading);
  const ratings_success = useSelector(
    (state) => state.ratingReducer.ratingSuccess
  );
  const {candidate_name, companyName} = location.state;
  const isEdit_ = location.state.isEdit;

  const [value, setValue] = useState("");
  const [countCom, setCountCom] = useState(0);
  const [showExample, setShowExample] = useState(false);
  const [ratings_Id, setRatings_Id] = useState(false);
  const [countWork, setCountWork] = useState(0);
  const [countRec, setCountRec] = useState(0);
  const [wordCount, setWordCount] = useState(0);
  const [dimensions, setDimensions] = useState(getWindowDimensions());
  const [ratingError, setRatingError] = useState(false);
  const [feedbackError, setFeedbackError] = useState(false);
  const [review, setReview] = useState("");
  const [isEdit, setIsEdit] = useState(false);
  const [profilePic, setProfilePic] = useState("");
  const [ratingSubmissionSuccess, setRatingSubmissionSuccess] = useState(false);
  const SourceId = location.state.SourceTypeId === 1 ? "Bid" : "Proposal"
  // const [dimensions, setDimensions] = useState(window.innerWidth);

  const rating = location.state.rating ? location.state.rating : ratingData[0]?.rating ? ratingData[0]?.rating : null;
  const bidId = location.state.bidId;

  function getWindowDimensions() {
    const { innerWidth: width, innerHeight: height } = window;
    return {
      width,
      height,
    };
  }

  useEffect(() => {
    dispatch(getRating({
      id: bidId,
      type: SourceId
    }));
  }, []);

  useEffect(() => {
    if (ratings_success) {
      setRatingSubmissionSuccess(true);
    }
    if (location.state.isEdit && !ratings_success) {
      setRatingSubmissionSuccess(false);
    }
  }, [ratings_success, location.state.isEdit]);

  useEffect(() => {
    setRatingSubmissionSuccess(false);
  }, [history])
  
  

  useEffect(async () => {
    let profilePic = "";
    if (location.state.image) {
      profilePic = await getImage(location.state.image);
    }

    setProfilePic(profilePic);
  }, [location.state.image]);

  useEffect(() => {
    setTimeout(() => {
      setRatingError(false);
    }, 3000);
  }, [ratingError]);

  useEffect(() => {
    if (isEdit_ || rating?.ClientRating) {
      setIsEdit(true);
      setRatingSubmissionSuccess(false)
      setFeedbackError(false)
      if (rating) {
        setCountCom(rating?.ClientRating);
        setReview(rating?.ClientComment ?? "");
        setWordCount(rating?.ClientComment?.length ?? 0);
        setRatings_Id(rating?._id);
      }
    }
  }, [isEdit_, ratingData])

  const handleReviewChange = (event) => {
    setReview(event.target.value);
  };

  const handleSubmitRatings = () => {
    if (countCom == 0 ) {
      setRatingError(true);
    }

    if (countCom != 0) {
      setRatingError(false);
    }

    if (review?.length != 0) {
      setFeedbackError(false);
    } else {
      setFeedbackError(true);
    }

    if (
      countCom != 0 &&
      review?.length != 0
    ) {
      setRatingError(false);
      setFeedbackError(false);
      if (isEdit) {
        dispatch(
          UpdateRatingInit({
            // Ratings_id: ratings_Id,
            BidId: location.state.bidId,
            ClientComment: review,
            ClientRating: countCom,
            Reviewee: location?.state?.Reviewee,
            type: location?.state?.sourceName,
          })
        );
        // dispatch(clearRatingCheck())
      } else {
        dispatch(
          CreateRatingInit({
            BidId: location.state.bidId,
            type: location?.state?.sourceName,
            ClientComment: review,
            Reviewee: location.state.Reviewee,
            ClientRating: countCom,
            // ConsultantRating: {
            //   Communication: countCom,
            // },
          })
        );
      }
    }
  };
  return (
    (
      rating_page_loading ? 
      <Loader />
    :
    <div className="Ratings-main-container">
        <>
          {dimensions < 768 ? <Header /> : null}
          <div className="g-details-header3">
            {/* <div className="message-back">
              <div className="header-textss">Messages</div>
              <div className="g-details-arrow2">
                <RightOutlined className="arrow" />
              </div>
              <div className="header-texts">Rate</div>
            </div> */}
            <div className="Ratings-Breadcrum">
              <span
                className="Ratings-previousScreen"
                onClick={() =>
                  history.push({
                    pathname:
                      location?.state?.previousScreen === "Messages"
                        ? "/messages"
                        : location?.state?.previousScreen ===
                            "Manage Candidates" ? "/managecandidate" : location?.state?.previousScreen === "Notifications" ? "/dashboard" : "/",
                    state: {
                      openConvo: true,
                      conversationId: location.state.conversationId,
                    },
                  })
                }
              >
                {location?.state?.previousScreen}
              </span>
              <span style={{ marginLeft: "0.7rem" }}>{`>`}</span>
              <span style={{ marginLeft: "0.7rem" }}>{`Rate`}</span>
            </div>
            <div className="g-detail-jb-title"></div>
            {!ratingSubmissionSuccess ? (
              <div>
                {dimensions.width > 769 && !ratingSubmissionSuccess ? (
                  <div className="Ratings-heading">Provide Feedback</div>
                ) : null}
                <div className="Ratings-imageRow">
                  <div
                    className="Ratings-picContainer"
                    style={{
                      width:
                        profilePic == null || (profilePic == "" && "2.5rem"),
                      height:
                        profilePic == null || (profilePic == "" && "2.5rem"),
                    }}
                  >
                    {profilePic == null || profilePic == "" ? (
                      candidate_name?.charAt(0)?.toUpperCase()
                    ) : (
                      <img
                        className="Ratings-recipent_image"
                        src={profilePic}
                        alt="recipent image"
                      />
                    )}
                  </div>

                  <div className="Ratings-Job-CandidateName-Container">
                    <div className="Ratings-Job-Title">
                      {location?.state?.job_title}
                    </div>
                    <div className="Ratings-Candidate-Name">
                      {candidate_name}
                    </div>
                  </div>
                </div>

                {/* {dimensions > 768 ?  */}
                <Divider className="rate_divider" />
                  {/* : null} */}
                {/* {dimensions > 768 ? ( */}
                  <div className="rating_tagg">Ratings</div>
                {/* ) : null} */}

                  <Rate
                    defaultValue={countCom}
                    allowHalf
                    onChange={(value) => {
                      setCountCom(value);
                    }}
                    value={countCom}
                    style={{ color: "#ffba08" }}
                    fill="#ffba08"
                    className="ratings-stars"
                  />

                {/* <div className="Ratings-WorkKnowledge-Row">
                  <div className="Ratings-WorkKnowledge-Text">
                    Work knowledge
                  </div>

                  <Rate
                    defaultValue={countWork}
                    allowHalf
                    onChange={(value) => {
                      setCountWork(value);
                    }}
                    value={countWork}
                    style={{ color: "#ffba08" }}
                    className="ratings-stars"
                  />
                </div>

                <div className="Ratings-Recommend-Row">
                  <div className="Ratings-Recommend-Text">Would recommend</div>
                  <Rate
                    defaultValue={countRec}
                    allowHalf
                    onChange={(value) => {
                      setCountRec(value);
                    }}
                    value={countRec}
                    style={{ color: "#ffba08" }}
                    className="ratings-stars"
                  />
                </div> */}

                {ratingError ? (
                  <div className="Ratings-rating-error">
                    Please provide rating
                  </div>
                ) : null}

                {dimensions.width > 769 ? (
                  <div className="Ratings-line-divider"></div>
                ) : null}

                <div className="reviewDiv">
                  <div className="Ratings-share-experience-text">
                  Tell us about your working experience with {companyName}
                  </div>
                  <br />
                  <textarea
                    value={review}
                    onChange={(e) => {
                      setWordCount(e.target.value.length);
                      handleReviewChange(e);
                      setFeedbackError(false);
                    }}
                    maxlength={5000}
                    className={
                      wordCount < 5000 && !feedbackError
                        ? "Ratings-normal-drawer"
                        : "Ratings-full-drawer"
                    }
                    placeholder="What did you like or dislike?"
                  />

                  <div
                    className={
                      wordCount < 5000
                        ? "Ratings-count-drawer"
                        : "Ratings-count-finish-drawer"
                    }
                  >
                    <span id="Ratings-current-word-count">{wordCount}</span>
                    <span id="Ratings-maximum-word-count"> / 5000</span>
                  </div>

                  {feedbackError ? (
                    <div className="Ratings-feedback-error">
                      Please provide your feedback
                    </div>
                  ) : null}

                  <div className="Ratings-appropriate-feedback">
                    See an example of{" "}
                    <span
                      onClick={() => setShowExample((prevState) => !prevState)}
                      style={{ color: "#118936", cursor: "pointer" }}
                    >
                      appropriate feedback
                    </span>
                  </div>

                  <div
                    className={
                      showExample
                        ? "Ratings-example-text"
                        : "Ratings-example-text-hide"
                    }
                  >
                    The employer is professional.I look forward to working with
                    them again.
                  </div>

                  <div className="Ratings-line-divider"></div>

                  <div className="Ratings-Submit-Btn-Container">
                    <Button
                      className="Ratings-Submit-Btn"
                      onClick={() => handleSubmitRatings()}
                    >
                      <div className="Ratings-Submit-Text">
                        {ratings_loading ? (
                          <LoadingOutlined />
                        ) : isEdit ? (
                          "Update"
                        ) : (
                          "Submit"
                        )}
                      </div>
                    </Button>
                  </div>
                </div>
              </div>
            ): (
              <div className="Ratings-thankyou-container">
                <div className="Ratings-greenTickContainer">
                  <img src={greenTick} className={"greenTick-pic"} />
                </div>
                <div className="Ratings-thankyou-heading">Thank you !</div>
                <div className="Ratings-thankyou-text">
                  We are processing your review. Your reviews will appear within 14
                  days, so we appreciate your patience. We will notify you when this
                  is complete.
                </div>
              </div>
            )}
          </div>
        </>
    </div>)
  );
};
