import { types } from "../action-types";

const initialState = {
  loading: false,
  success: [],
  error: {},
};

//  RESUME GET FOLLOWERS REDUCER //

export const resumeGetfollowersReducer = (state = initialState, action) => {
  switch (action.type) {
    case types.FETCH_RESUME_GET_FOLLOWERS_INIT:
      return {
        ...state,
        loading: true,
      };

    case types.FETCH_RESUME_GET_FOLLOWERS_SUCCESS:
      return {
        ...state,
        loading: false,
        success: action.payload,
        error: {},
      };
    case types.FETCH_RESUME_GET_FOLLOWERS_FAILURE:
      return {
        ...state,
        loading: false,
        success: [],
        error: action.error,
      };
    default:
      return state;
  }
};

//  RESUMR GET VIEWS REDUCER //

export const resumeGetviewReducer = (state = initialState, action) => {
  switch (action.type) {
    case types.FETCH_RESUME_GET_VIEWS_INIT:
      return {
        ...state,
        loading: true,
      };
    case types.FETCH_RESUME_GET_VIEWS_SUCCESS:
      return {
        ...state,
        loading: false,
        success: action.payload,
        error: action.error,
      };
    case types.FETCH_RESUME_GET_VIEWS_FAILURE:
      return {
        ...state,
        loading: false,
        success: [],
        error: action.error,
      };
    default:
      return state;
  }
};

//RESUME DETAIL REDUCER //

export const resumeDetailReducer = (state = initialState, action) => {
  switch (action.type) {
    case types.FETCH_RESUME_DETAIL_INIT:
      return {
        ...state,
        loading: true,
      };

    case types.FETCH_RESUME_DETAIL_SUCCESS:
      return {
        ...state,
        loading: false,
        success: action.payload,
        error: {},
      };

    case types.FETCH_RESUME_DETAIL_FAILURE:
      return {
        ...state,
        loading: false,
        success: [],
        error: action.error,
      };

    default:
      return state;
  }
};

export const resumeViewReducer = (state = initialState, action) => {
  switch (action.type) {
    case types.VIEW_RESUME_PAGES_INIT:
      return {
        ...state,
        loading: true,
      };

    case types.VIEW_RESUME_SUCCESS:
      return {
        ...state,
        loading: false,
        success: action.payload,
        error: {},
      };

    case types.VIEW_RESUME_FAILURE:
      return {
        ...state,
        loading: false,
        success: [],
        error: action.error,
      };

    default:
      return state;
  }
};