import React from "react";
import UploadDocV2 from "../../UploadDoc";

const StateLisenceV2 = () => {
  const docInfo = [
    {
      "State licence - front": 89,
    },
    {
      "State licence - back": 90,
    },
  ];
  return (
    <div>
      <UploadDocV2
        docInfo={docInfo}
        backUrl="acls-doc"
        nextUrl="social-security-doc"
        currentPage={5}
        WizardFlow={112}
        skipUrl={"social-security-doc"}
      />
    </div>
  );
};

export default StateLisenceV2;
