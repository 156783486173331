import React, { useState, useEffect, useRef } from "react";
import BidDetail from "../BidDetail/BidDetail";

import { Modal, DatePicker, Radio, Space } from "antd";
import moment from "moment";

import { useParams } from "react-router-dom";
import { useDispatch, useSelector } from "react-redux";
import {
  acceptOffer,
  fetchDeclinedReasonsBids,
  fetchDetailReceivedBid,
  InterviewScheduleAcceptedInit,
  updateDeclinedReasonsReceivedBids,
} from "../../../redux/actions";
import UpperIcon from "../../../assets/icons/UpperIcon.png";
import upperIconDesktop from "../../../assets/icons/upperIconDesktop.png";
import DownIcon from "../../../assets/icons/DownIcon.png";

import CalendarIcon from "../../../assets/icons/CalendarIcon.svg";
import Interested_NotInterested from "./components/Interested_NotInterested";
import AcceptDeclineMobile from "../components/ActionButtonMobile";
import ScreeningAndSchedule from "../components/ScreeningAndSchedule";
import OfferLetter from "../components/OfferLetter";
import CompleteCredentialing from "../components/CompleteCredentialing";
import WelcomeOnboard from "../components/WelcomeOnboard";
import { FiPaperclip } from "react-icons/fi";
import Loader from "../../../components/Loader/Loader";
import { useUpdateLogger } from "../../../shared/useUpdateLogger";
import DeclineStatus from "../components/DeclineStatus";
import UploadFile from "../components/UploadFile";
import { alertNotification } from "../../../components/notifications/alertNotification";
import { useHistory } from "react-router-dom";
import { getDeclineReason } from "../../Manage_SkillListing/SkillListingDetailPage/components/BidHistorySkillListing/bhsHandler";
import InterviewDrawer from "../../../components/InterviewDrawer/interviewDrawer";

const ReceivedBidDetailPage = () => {
  const dispatch = useDispatch();
  const history = useHistory();

  const { bidId } = useParams();
  const [SendRequest, setSendRequest] = useState(false);
  const [aboutUI, setAboutUI] = useState(null);
  const [actionButtonMobile, setActionButtonMobile] = useState(null);
  const [extraSection, setExtraSection] = useState(null);
  const [DateValueStage, setDateValueStage] = useState();
  const [DateValueStage1, setDateValueStage1] = useState();
  const [DateValueStage2, setDateValueStage2] = useState();
  const [Endtime, setEndtime] = useState();
  const [Endtime1, setEndtime1] = useState();
  const [Endtime2, setEndtime2] = useState();
  const [declinedReason, setDeclinedReason] = useState(null);
  const [isModalVisibleBidDeclined, setModalVisibleBidDeclined] =
    useState(false);
  const [isModalVisibleBidCalender, setModalVisibleBidCalender] =
    useState(false);

  const [dimensions, setDimensions] = useState(window.innerWidth);
  const [talentstagemodal, settalentstagemodal] = useState();
  const [disableStatevalue, setdisableStatevalue] = useState(false);
  const [AcceptButtonModal, setAcceptButtonModal] = useState();
  const [Modaltitle, setModaltitle] = useState("");
  const [starttime, setstarttime] = useState();
  const [InterviewMessage, setInterviewMessage] = useState("error Message");
  const [interviewTime, setInterviewTime] = useState(null);
  const [InterviewSendRequest, setInterviewSendRequest] = useState(false);
  const [errorMessage, seterrorMessage] = useState();
  const [ReasonToDecline, setReasonToDecline] = useState(null);
  const [BidDeclineId, setBidDeclineId] = useState(null);
  const [validateError, setvalidateError] = useState(false);

  function messageRoute() {
    history.push({
      pathname: `/messages`,
      state: {
        openConvo: true,
        conversationId: BidDetailData?.message?.ConversationId,
      },
    });
  }

  useEffect(() => {
    // 
    dispatch(fetchDetailReceivedBid(bidId));
  }, []);

  const checkDimensions = () => {
    setDimensions(window.innerWidth);
  };

  const modalCancel = () => {
    setModalVisibleBidDeclined(false);
  };
  const modalCancelCalender = () => {
    setModalVisibleBidCalender(false);
  };
  function OpenModalBoxStages(data) {
    setdisableStatevalue(true)
    if (data === "Interview") {
      // 
      settalentstagemodal(data);
      setModalVisibleBidDeclined(true);
      setModaltitle("interview");
    } else if (data === "Interview Accepted") {
      settalentstagemodal(data);
      setModalVisibleBidDeclined(true);
      setModaltitle("interview");
    } else if (data === "New") {
      settalentstagemodal(data);
      setModalVisibleBidDeclined(true);
      setModaltitle("bid");
    } else if (data === "Offer") {
      settalentstagemodal(data);
      setModalVisibleBidDeclined(true);
      setModaltitle("Offer");
      setAcceptButtonModal("");
    }
  }

  function AcceptButton(data) {
    if (data === "New") {
      // 
      submitDeclinedReason(true);
    } else if (data === "Interview") {
      // 
      AcceptInterview(interviewTime);
    } else if (data === "Offer") {
      setAcceptButtonModal("Offer");
      settalentstagemodal("");
      setModalVisibleBidDeclined(true);
    }
  }

  useEffect(() =>{
    if(declinedReason){
      setvalidateError(false)
    }
  },[declinedReason])

  useEffect(() => {
    // { interviewTime ? setInterviewMessage(true)  : setInterviewMessage(false) }
    if (interviewTime) {
      setInterviewMessage(true);
      seterrorMessage("");
    } else {
      setInterviewMessage(false);
    }
  }, [interviewTime]);

  useEffect(() => {
    window.addEventListener("resize", checkDimensions);
  }, [dimensions]);

  const SendRequestDetail = () => {
    dispatch(fetchDetailReceivedBid(bidId));
  };

  const BidDetailData = useSelector(
    (state) => state.BidsReducer.detailReceivedBid
  );
  // 

  const loading = useSelector((state) => state.BidsReducer.loading);
  const interviewloader = useSelector((state) => state.interviewReducer.loading)

  const declinedReasonsBid = useSelector(
    (state) => state.BidsReducer.declinedReasonsBid
  );

  // const successInterviewRespose = useSelector(
  //   (state) => state.interviewReducer.ScheduleInterview
  // );

  // useEffect(() => {
  //   dispatch(fetchDetailReceivedBid(bidId));
  //   // 
  // }, [successInterviewRespose])

  const dateHandlerStages = (e) => {
    var ctime = new Date(e._d);
    const date = moment(ctime.toISOString());
    date.add(1, "h");
    const options = {
      weekday: "long",
      year: "numeric",
      month: "short",
      day: "numeric",
      hour: "numeric",
      hour12: true,
    };
    setDateValueStage(ctime.toISOString());
    setEndtime(date.toISOString());
  };

  const dateHandlerStages1 = (e) => {
    var ctime = new Date(e._d);
    const date = moment(ctime.toISOString());
    date.add(1, "h");
    const options = {
      weekday: "long",
      year: "numeric",
      month: "short",
      day: "numeric",
      hour: "numeric",
      hour12: true,
    };
    setDateValueStage1(ctime.toISOString());
    setEndtime1(date.toISOString());
  };

  const dateHandlerStages2 = (e) => {
    var ctime = new Date(e._d);
    const date = moment(ctime.toISOString());
    date.add(1, "h");
    const options = {
      weekday: "long",
      year: "numeric",
      month: "short",
      day: "numeric",
      hour: "numeric",
      hour12: true,
    };
    setDateValueStage2(ctime.toISOString());
    setEndtime2(date.toISOString());
  };

  //  
  //   DateValueStage,
  //   DateValueStage1,
  //   DateValueStage2,
  //   "DateValueStage"
  // );
  // useUpdateLogger("BidDetailData01", BidDetailData);

  useEffect(() => {
    dispatch(fetchDeclinedReasonsBids("interview"));
  }, [Modaltitle === "interview"]);

  useEffect(() => {
    dispatch(fetchDeclinedReasonsBids("bid"));
  }, [Modaltitle === "bid"]);

  useEffect(() => {
    if (InterviewSendRequest === true && InterviewMessage === true) {
      dispatch(
        InterviewScheduleAcceptedInit({
          id: BidDetailData?.interview?.InterviewId,
          idSearch: bidId,
          receivedBid: true,
          reschedule: true,
          body: {
            ApprovedScheduledTiming: {
              StartTime: interviewTime?.StartTime,
              EndTime: interviewTime?.EndTime,
            },
            InterviewStatus: "Accepted",
            Message: "You have accepted the interview",
          },
        })
      );
    } else if (InterviewMessage === false) {
      seterrorMessage(
        "Choose an available interview date or request to reschedule another time"
      );
      // 
    }

    setInterviewSendRequest(false);
    // 
  }, [InterviewSendRequest]);

  useEffect(() => {
    setAboutUI(
      <Interested_NotInterested
        no={() => setModalVisibleBidDeclined(true)}
        yesText="Interested"
        noText="Not Interested"
        submitDeclinedReason={submitDeclinedReason}
        stagename="New"
        AcceptButton={AcceptButton}
        OpenModalBoxStages={OpenModalBoxStages}
        disableState={disableStatevalue}
      />
    );
    setActionButtonMobile(
      <AcceptDeclineMobile
        yesText="Interested"
        noText="Not Interested"
        no={() => setModalVisibleBidDeclined(true)}
        submitDeclinedReason={submitDeclinedReason}
        stagename="New"
        OpenModalBoxStages={OpenModalBoxStages}
        AcceptButton={AcceptButton}
      />
    );

    if (BidDetailData?.ranking?.BidStatusName === "Retracted") {
      setAboutUI(
        <Interested_NotInterested
          no={() => setModalVisibleBidDeclined(true)}
          yesText="Interested"
          noText="Not Interested"
          submitDeclinedReason={submitDeclinedReason}
          stagename="New"
          // AcceptButton={AcceptButton}
          // OpenModalBoxStages={OpenModalBoxStages}
          disableState={true}
        />
      );
      setActionButtonMobile(
        <AcceptDeclineMobile
          yesText="Interested"
          noText="Not Interested"
          no={() => setModalVisibleBidDeclined(true)}
          submitDeclinedReason={submitDeclinedReason}
          stagename="New"
          // OpenModalBoxStages={OpenModalBoxStages}
          // AcceptButton={AcceptButton}
          disabled={true}
        />
      );
    }

    if (BidDetailData?.ranking?.BidStatusName === "Declined") {
      setAboutUI(
        <ScreeningAndSchedule
          screeningdisplay="True"
          uppertext="Not Interested"
          lowerText={getDeclineReason("Bid", BidDetailData?.DeclineReasonId)}
          disableState="True"
          dimensions={dimensions}
          firstButton="Interested"
          secondButton="Not Interested"
          disableStateScreening
        />
      );
      setActionButtonMobile(
        <AcceptDeclineMobile
          message1="Not Interested"
          lowerText={getDeclineReason("Bid", BidDetailData?.DeclineReasonId)}
          screeningdisplay
          disabledyellow
          yesText="Interested"
          noText="Not Interested"
          disabled
        />
      );
    }

    if (BidDetailData?.ranking?.BidStatusName === "Profile Submitted") {
      setAboutUI(
        <ScreeningAndSchedule
          screeningdisplay="True"
          uppertext="Profile Submitted On"
          lowerText={moment(BidDetailData?.Modified).format(
            "dddd , MMMM DD, YYYY"
          )}
          disableState="True"
          dimensions={dimensions}
          disableStateScreening
          firstButton="Interested"
          secondButton="Not Interested"
          iconCal
        />
      );
      setActionButtonMobile(
        <AcceptDeclineMobile
          message1="Profile Submitted On"
          message2={BidDetailData?.Modified}
          screeningdisplay
          disabledyellow
          yesText="Interested"
          noText="Not Interested"
          disabled
          iconcal
          no={() => setModalVisibleBidDeclined(true)}
        />
      );
    }

    if (BidDetailData?.ranking?.BidStatusName === "Interview") {
      setAboutUI(
        <ScreeningAndSchedule
          // screeningdisplay="True"
          uppertext="Interview Accepted On"
          lowerText={BidDetailData?.DeclineReason}
          // disableState
          dimensions={dimensions}
          firstButton="Accept"
          secondButton="Decline"
          ThirdButton="Request to reschedule"
          Reschedule
          Interviewselecter
          setInterviewTime={setInterviewTime}
          CalendraModal={() => setModalVisibleBidCalender(true)}
          setModalVisibleBidCalender={setModalVisibleBidCalender}
          AcceptButton={AcceptButton}
          stagename="Interview"
          OpenModalBoxStages={OpenModalBoxStages}
          errorMessage={errorMessage}
        />
      );
      setActionButtonMobile(
        <AcceptDeclineMobile
          yesText="Accept"
          noText="Decline"
          Interviewselecter
          setInterviewTime={setInterviewTime}
          AcceptInterview={AcceptInterview}
          stagename="Interview"
          OpenModalBoxStages={OpenModalBoxStages}
          CalendraModal={() => setModalVisibleBidCalender(true)}
          AcceptButton={AcceptButton}
          errorMessage={errorMessage}
          Reschedule
        />
      );
    }
    if (BidDetailData?.ranking?.BidStatusName === "Interview Accepted") {
      const event = new Date(
        BidDetailData?.interview?.ApprovedScheduledTiming?.StartTime
      );
      const time = event.toLocaleDateString(undefined, {
        weekday: "long",
        month: "long",
        day: "numeric",
        year: "numeric",
      });
      setAboutUI(
        <ScreeningAndSchedule
          screeningdisplay="True"
          uppertext="Interview Accepted On"
          lowerText={time}
          disableState
          disabledAccepted="disabledAccepted"
          dimensions={dimensions}
          firstButton="Accepted"
          secondButton="Decline"
          ThirdButton="Request to reschedule"
          Reschedule
          iconCal
          stagename="Interview Accepted"
          CalendraModal={() => setModalVisibleBidCalender(true)}
          OpenModalBoxStages={OpenModalBoxStages}
        />
      );
      setActionButtonMobile(
        <AcceptDeclineMobile
          message1="Interview Accepted On"
          screeningdisplay
          message2={time}
          yesText="Accepted"
          noText="Decline"
          disabledAccepted
          RescheduledAccepted
          Reschedule
          iconcal
          CalendraModal={() => setModalVisibleBidCalender(true)}
          stagename="Interview Accepted"
          OpenModalBoxStages={OpenModalBoxStages}
        />
      );
    }

    if (BidDetailData?.ranking?.BidStatusName === "Interview Declined" || BidDetailData?.ranking?.BidStatusName === "Interview Cancelled") {
      setAboutUI(
        <ScreeningAndSchedule
          screeningdisplay="True"
          uppertext="Interview Declined by You / Employer"
          lowerText={getDeclineReason(
            "Interview",
            BidDetailData?.interview?.DeclineReasonId
          )}
          disableState="False"
          dimensions={dimensions}
          firstButton="Accept"
          secondButton="Declined"
          ThirdButton="Request to reschedule"
          Reschedule
          disableStateScreening
          stagename="Interview Declined"
          OpenModalBoxStages={OpenModalBoxStages}
        />
      );
      setActionButtonMobile(
        <AcceptDeclineMobile
          message1="Interview Declined by You / Employer"
          lowerText={getDeclineReason(
            "Interview",
            BidDetailData?.interview?.DeclineReasonId
          )}
          screeningdisplay
          yesText="Accept"
          noText="Declined"
          disabledyellow
          Reschedule
          disabled
          // no={() => setModalVisibleBidDeclined(true)}
        />
      );
    }

    if (BidDetailData?.ranking?.BidStatusName === "Offer") {
      setAboutUI(
        <ScreeningAndSchedule
          screeningdisplay="True"
          uppertext="View Offer"
          lowerText={BidDetailData?.offers?.Attachments[0]?.FileName}
          dimensions={dimensions}
          firstButton="Accept"
          secondButton="Decline"
          disableStateScreening
          icon
          stagename="Offer"
          OpenModalBoxStages={OpenModalBoxStages}
          AcceptButton={AcceptButton}
        />
      );
      setActionButtonMobile(
        <AcceptDeclineMobile
          message1="View Offer"
          lowerText={BidDetailData?.offers?.Attachments[0]?.FileName}
          screeningdisplay
          yesText="Accept"
          noText="Decline"
          icon
          disabledyellow
          stagename="Offer"
          OpenModalBoxStages={OpenModalBoxStages}
          colorSetter="1"
          AcceptButton={AcceptButton}
        />
      );
    }

    if (BidDetailData?.ranking?.BidStatusName === "Offer Accepted") {
      setAboutUI(
        <ScreeningAndSchedule
          screeningdisplay="True"
          uppertext="View Offer"
          lowerText={BidDetailData?.offers?.Attachments[0]?.FileName}
          disableState
          dimensions={dimensions}
          firstButton="Accepted"
          secondButton="Decline"
          disableStateScreening
          icon
        />
      );
      setActionButtonMobile(
        <AcceptDeclineMobile
          message1="View Offer"
          lowerText={BidDetailData?.offers?.Attachments[0]?.FileName}
          screeningdisplay
          yesText="Accepted"
          noText="Decline"
          icon
          disabledyellow
          disabled
          colorSetter="1"
          // no={() => setModalVisibleBidDeclined(true)}
        />
      );
      setExtraSection(<CompleteCredentialing BidDetailData={BidDetailData} />);
    }

    if (BidDetailData?.ranking?.BidStatusName === "Offer Declined") {
      setAboutUI(
        <ScreeningAndSchedule
          screeningdisplay="True"
          uppertext="Offer Declined"
          // lowerText="Accepted another offer"
          lowerText={BidDetailData?.offers?.DeclineReason}
          disableState="False"
          dimensions={dimensions}
          firstButton="Accept"
          secondButton="Declined"
          disableStateScreening
        />
      );
      setActionButtonMobile(
        <AcceptDeclineMobile
          message1="Offer Declined"
          // message2="Accepted another offer"
          lowerText={BidDetailData?.offers?.DeclineReason}
          screeningdisplay
          yesText="Accept"
          noText="Declined"
          disabledyellow
          disabled
          colorSetter="1"
          // no={() => setModalVisibleBidDeclined(true)}
        />
      );
    }

    if (BidDetailData?.ranking?.BidStatusName === "Hired") {
      setAboutUI(<OfferLetter disableState="False" />);
      setActionButtonMobile(<OfferLetter />);
    }

    if (BidDetailData?.ranking?.BidStatusName === "On Billing") {
      setAboutUI(<OfferLetter />);
      setActionButtonMobile(<OfferLetter />);
      setExtraSection(
        <WelcomeOnboard
          image={upperIconDesktop}
          message1="On Billing"
          message2="Congrats!"
          message3="You’re moved to “on billing” for this gig."
          // Duration="MM/DD/YYYY - MM/DD/YYYY"
          Duration={`${moment(BidDetailData?.offers?.FromDate).format(
            "MMMM DD, YYYY"
          )} - ${moment(BidDetailData?.offers?.ToDate).format("MMMM DD, YYYY")}`}
          BidDetailData={BidDetailData}
        />
      );
    }

    if (BidDetailData?.ranking?.BidStatusName === "Off Billing") {
      setAboutUI(<OfferLetter />);
      setActionButtonMobile(<OfferLetter />);
      setExtraSection(
        <WelcomeOnboard
          image={DownIcon}
          message1="Off Billing"
          message2="Congratulations!"
          message3="You successfully completed the gig"
          // Duration="MM/DD/YYYY - MM/DD/YYYY"
          Duration={`${moment(BidDetailData?.offers?.FromDate).format(
            "MMMM DD, YYYY"
          )} - ${moment(BidDetailData?.offers?.ToDate).format("MMMM DD, YYYY")}`}
          BidDetailData={BidDetailData}
        />
      );
    }

    if (BidDetailData?.ranking?.BidStatusName === "Billing Extend") {
      setAboutUI(<OfferLetter />);
      setActionButtonMobile(<OfferLetter />);
      setExtraSection(
        <WelcomeOnboard
          image={UpperIcon}
          message1="Billing Extended"
          message2="Congrats!"
          message3="The employer extended your billing"
          // Duration="MM/DD/YYYY - MM/DD/YYYY"
          Duration={`${moment(BidDetailData?.AcceptedDate).format(
            "MMMM DD, YYYY"
          )} - ${moment(BidDetailData?.offers?.ToDate).format("MMMM DD, YYYY")}`}
          BidDetailData={BidDetailData}
        />
      );
    }

    if (BidDetailData?.ranking?.BidStatusName === "Canceled") {
      setAboutUI(<OfferLetter />);
      setActionButtonMobile(<OfferLetter />);
      setExtraSection(
        <DeclineStatus
          message1="Canceled"
          message2="Sorry!"
          message3="Your employer moved you to canceled"
          // Duration="MM/DD/YYYY - MM/DD/YYYY"
          Duration={`${moment(BidDetailData?.AcceptedDate).format(
            "MMMM DD, YYYY"
          )} - ${moment(BidDetailData?.offers?.ToDate).format("MMMM DD, YYYY")}`}
          BidDetailData={BidDetailData}
        />
      );
    }

    if (BidDetailData?.ranking?.BidStatusName === "Terminated") {
      setAboutUI(<OfferLetter />);
      setActionButtonMobile(<OfferLetter />);
      setExtraSection(
        <DeclineStatus
          message1="Terminated"
          message2="Sorry!"
          message3="Your employer moved you to terminated"
          // Duration="MM/DD/YYYY - MM/DD/YYYY"
          Duration={`${moment(BidDetailData?.offers?.FromDate).format(
            "MMMM DD, YYYY"
          )} - ${moment(BidDetailData?.offers?.ToDate).format("MMMM DD, YYYY")}`}
          BidDetailData={BidDetailData}
        />
      );
    }

    if (BidDetailData?.ranking?.BidStatusName === "Accepted") {
      setAboutUI(
        <ScreeningAndSchedule
          screeningdisplay="False"
          uppertext="Declined"
          lowerText={BidDetailData?.DeclineReason}
          disableState="True"
          dimensions={dimensions}
          firstButton="Interested"
          secondButton="Not Interested"
          removeExtraspace="interested-job-extraSpace"
        />
      );
      setActionButtonMobile(
        <AcceptDeclineMobile
        yesText="Interested"
        noText="Not Interested"
          disabled
          no={() => setModalVisibleBidDeclined(true)}
        />
      );
    }
    return () => {
      setAboutUI(null);
      setExtraSection(null);
    };
  }, [BidDetailData, errorMessage]);
  // 
  const RescheduleInterview = (firstDateTimings, secondDateTimings, thirdDateTimings) => {
    let startTime = firstDateTimings?.StartTime ? firstDateTimings?.StartTime : getRescheduleModalDateValues(0, "StartTime");
    let startTime1 = secondDateTimings?.StartTime ? secondDateTimings?.StartTime : getRescheduleModalDateValues(1, "StartTime");
    let startTime2 = thirdDateTimings?.StartTime ? thirdDateTimings?.StartTime : getRescheduleModalDateValues(2, "StartTime");
    let endTime = firstDateTimings?.EndTime ? firstDateTimings?.EndTime : getRescheduleModalDateValues(0, "EndTime");
    let endTime1 = secondDateTimings?.EndTime ? secondDateTimings?.EndTime : getRescheduleModalDateValues(1, "EndTime");
    let endTime2 = thirdDateTimings?.EndTime ? thirdDateTimings?.EndTime : getRescheduleModalDateValues(2, "EndTime");
    dispatch(
      InterviewScheduleAcceptedInit({
        id: BidDetailData?.interview?.InterviewId,
        idSearch: bidId,
        reschedule: true,
        receivedBid: true,
        body: {
          ReScheduledTimings: [
            {
              StartTime: startTime,
              EndTime: endTime,
            },
            {
              StartTime: startTime1,
              EndTime: endTime1,
            },
            {
              StartTime: startTime2,
              EndTime: endTime2,
            },
          ],
          InterviewStatus: "Rescheduled",
          Message: "You have rescheduled the interview",
        },
      })
    );
    setModalVisibleBidCalender(false);
  };

  const getRescheduleModalDateValues = (index, type) => {
    let retunData;
    if(BidDetailData?.interview?.ReScheduledTimings && BidDetailData?.interview?.ReScheduledTimings.length){
      let rescheduledTimings = BidDetailData?.interview?.ReScheduledTimings;
      retunData = rescheduledTimings[rescheduledTimings.length-1]?.length ? 
        moment(rescheduledTimings[rescheduledTimings.length - 1][index]?.[type])  : null;
    }
    else {
      let interviewScheduledTimings = BidDetailData?.interview?.Interviews_ScheduledTimings;
      retunData = interviewScheduledTimings && interviewScheduledTimings.length ? 
        moment(interviewScheduledTimings[index]?.[type]) : null;
    }
    return retunData;
  }

  const getRescheduleModalDateAutofill = () => {
    let retunData;
    if (BidDetailData?.interview?.ReScheduledTimings && BidDetailData?.interview?.ReScheduledTimings.length) {
      let rescheduledTimings = BidDetailData?.interview?.ReScheduledTimings;
      retunData = rescheduledTimings[rescheduledTimings.length - 1]?.length ?
        rescheduledTimings[rescheduledTimings.length - 1] : null;
    }
    else {
      let interviewScheduledTimings = BidDetailData?.interview?.Interviews_ScheduledTimings;
      retunData = interviewScheduledTimings && interviewScheduledTimings.length ?
        interviewScheduledTimings : null;
    }
    return retunData;
  }

  function AcceptInterview() {
    // // 

    if (declinedReason) {
      dispatch(
        InterviewScheduleAcceptedInit({
          id: BidDetailData?.interview?.InterviewId,
          idSearch: bidId,
          reschedule: true,
          receivedBid: true,
          body: {
            InterviewStatus: "Rejected",
            Message: "You have rejected the interview",
            ReasonId: ReasonToDecline,
            DeclineReason: declinedReason,
          },
        })
      );
    } else {
      setInterviewSendRequest(true);
    }
    setModalVisibleBidDeclined(false);
  }

  function AcceptOfferBid() {
    // // 

    if (declinedReason) {
      dispatch(
        acceptOffer({
          id: BidDetailData?.BidId,
          Status: "Decline",
          BidType: "Bid",
          ReasonId: ReasonToDecline,
          DeclineReason: declinedReason,
        })
      );
    } else {
      setInterviewSendRequest(true);
    }
    setModalVisibleBidDeclined(false);
    setTimeout(() => {
      SendRequestDetail();
    }, 1000);
  }

  function submitDeclinedReason(AcceptRecivedBid) {
    // // 
    setdisableStatevalue(true)

    if (!declinedReason && AcceptRecivedBid) {
      dispatch(
        updateDeclinedReasonsReceivedBids({
          id: BidDetailData.BidId,
          idSearch: bidId,
          body: {
            Message: "Update Bid by talent",
            BidStatusName: "Accepted",
          },
        })
      );
      alertNotification(
        "success",
        "Thank you for showing interest",
        "Stages",
        messageRoute
      );
    } else if (!AcceptRecivedBid && declinedReason) {
      dispatch(
        updateDeclinedReasonsReceivedBids({
          id: BidDetailData?.BidId,
          idSearch: bidId,
          body: {
            Message: "Update Bid by talent",
            BidStatusName: "Declined",
            DeclineReason: declinedReason,
            DeclineReasonId: BidDeclineId,
          },
        })
      );
      setModalVisibleBidDeclined(false);
    } else if (!declinedReason && !AcceptRecivedBid) {
      setvalidateError(true)
    }
  }
  // // 
  return (
    <div>
      {loading || interviewloader ? (
        <Loader height="40vh" />
      ) : (
        BidDetailData.BidId && (
          <BidDetail
            StageAboutUI={aboutUI}
            ActionButtonMobile={actionButtonMobile}
            ExtraSection={extraSection}
            bidData={BidDetailData}
            gigData={BidDetailData.gigs}
            stageRanking={BidDetailData.ranking}
            isReceivedBids={true}
          />
        )
      )}
      <Modal
        bodyStyle={{ padding: 0 }}
        width="21.25rem"
        visible={isModalVisibleBidDeclined}
        closable={true}
        footer={null}
        // style={{ top: 45, padding: 0 }}
        onCancel={modalCancel}
        maskClosable={true}
        centered={true}
        className="declinedBidsModal"
      >
        <div className="declineThisBid">
          {AcceptButtonModal === "Offer"
            ? "Accept Offer"
            : `Not Interested in this ${Modaltitle}?`}
        </div>
        <div className="declinedThisdBidContent">
          {talentstagemodal === "New" ? (
            <div className="reasonForDeclined">
              Let us know the reason why you're not interested
            </div>
          ) : null}
          <div className="reasonbox-list">
            {talentstagemodal === "New" ? (
              <>
                {declinedReasonsBid.length > 0 &&
                  declinedReasonsBid.map((item) => (
                    <div
                      className={
                        item.Reason === declinedReason
                          ? "reasonbox reasonbox-selected"
                          : "reasonbox"
                      }
                      onClick={() => {
                        setDeclinedReason(item.Reason);
                        setBidDeclineId(item?.ReasonId);
                      }}
                    >
                      {item.Reason}
                    </div>
                  ))}
                {validateError && <div className="offerError-managebid">Please select a reason</div>}
                <div className="submitReason" onClick={() => {submitDeclinedReason(false)}}>
                  Submit Reason
                </div>
              </>
            ) : null}
            {talentstagemodal === "Interview" ||
            talentstagemodal === "Interview Accepted" ||
            talentstagemodal === "Offer" ? (
              <>
                <div className="bid-decline-headline-talent-stage">
                  Please select a reason for declining this {talentstagemodal}
                </div>
                {declinedReasonsBid.length > 0 &&
                  declinedReasonsBid.map((item) => (
                    <div
                      className={
                        item.Reason === declinedReason
                          ? "reasonbox reasonbox-selected"
                          : "reasonbox"
                      }
                      onClick={() => {
                        setDeclinedReason(item.Reason);
                        setReasonToDecline(item.ReasonId);
                      }}
                    >
                      {item.Reason}
                    </div>
                  ))}
                <div
                  className="bid-decline-button talent-stages-button"
                  onClick={() => {
                    talentstagemodal === "Offer"
                      ? AcceptOfferBid()
                      : AcceptInterview();
                  }}
                >
                  Submit Reason
                </div>
              </>
            ) : null}
            {AcceptButtonModal === "Offer" ? (
              <UploadFile
                setModal={setModalVisibleBidDeclined}
                BidDetailData={BidDetailData}
              />
            ) : null}
          </div>
        </div>
      </Modal>
      <InterviewDrawer
        timings={getRescheduleModalDateAutofill()}
        reSchedule={true}
        open={isModalVisibleBidCalender}
        close={setModalVisibleBidCalender}
        rescheduleInterview={RescheduleInterview}
      />
    </div>
  );
};

export default ReceivedBidDetailPage;
