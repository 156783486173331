import React from "react";
import image from "../../assets/img/image 429.png";
import imageIT from "../../assets/img/image 423.png";
import iconfirst from "../../assets/icons/UserList.svg";
import iconeFile from "../../assets/icons/Files.svg";
import groupIcon from "../../assets/icons/Group.svg";
import noteIcon from "../../assets/icons/Group (1).svg";
import userPlusIcon from "../../assets/icons/UserPlus.svg";
import starIcon from "../../assets/icons/star.svg";
import { useHistory } from "react-router";
import NursePicTwo from "../../assets/img/Healthcare/NursePicTwo.jpg";
import NursePicTwMob from "../../assets/img/Healthcare/NursePicTwoMob.jpg";
import ItPicTwo from "../../assets/img/Healthcare/ItPicTwo.jpg";
import NurseItPicTwMob from "../../assets/img/Healthcare/ItPicTwoMob.jpg";
import Loader from "../../components/Loader/Loader";
import { useDispatch, useSelector } from "react-redux";
import { updateProfile } from "../../redux/actions";

export const SuccessProfileInfo = () => {
  const history = useHistory();
  const dispatch = useDispatch();
  const user = useSelector((state) => state.profileReducer.user);
  const { loading } = useSelector((state) => state.profileReducer);

  const handleContinue = () => {
    dispatch(
      updateProfile({
        userId: user.id,
        WizardFlow: 8,
      })
    );
    history.push("/effective-profile-info");
  };
  const handleBack = () => {
    dispatch(
      updateProfile({
        userId: user.id,
        WizardFlow: 2,
      })
    );
    history.push("/read-rules");
  };

  return (
    <>
      {loading ? (
        <Loader size={"4rem"} scrolling />
      ) : (
        <div className="effective-profile-info-main">
          <div className="effective-profile-info-first">
            {user?.profile?.ProfessionCode == "HC" ? (
              <img style={{ borderRadius: "8px" }} src={NursePicTwo} alt="" />
            ) : (
              <img style={{ borderRadius: "8px" }} src={ItPicTwo} alt="" />
            )}
          </div>
          <div className="effective-profile-info-second">
            <div className="effective-profile-info-heading">
              <div className="info-heading">
                What makes a successful candidate profile?
              </div>
              <div className="info-sub-heading">
                6 steps to build the perfect profile
              </div>
            </div>

            {window.innerWidth < 768 && (
              <div className="effective-profile-info-img-mobile">
                {user?.profile?.ProfessionCode == "HC" ? (
                  <img
                    className="effective-profile-info-img-mobile"
                    style={{ borderRadius: "8px" }}
                    src={NursePicTwMob}
                    alt=""
                  />
                ) : (
                  <img
                    className="effective-profile-info-img-mobile"
                    style={{ borderRadius: "8px" }}
                    src={NurseItPicTwMob}
                    alt=""
                  />
                )}
                <div className="effective-profile-info-lorem-mobile">
                  Let our unique patented 3D Resume tell your professional story
                  in a visually impactful way to get you hired faster!
                </div>
              </div>
            )}

            <div className="effective-profile-info-content effective-profile-info-content-first">
              <div className="effective-profile-info-content-div">
                <div className="effective-profile-icon">
                  <img src={iconfirst} alt="" />
                </div>
                <div className="effective-profile-info-content-text">
                  Represent yourself with a professional profile picture.
                </div>
              </div>
              <div className="effective-profile-info-content-div">
                <div className="effective-profile-icon">
                  <img src={iconeFile} alt="" />
                </div>
                <div className="effective-profile-info-content-text">
                  Upload an up-to-date resume.
                </div>
              </div>
              <div className="effective-profile-info-content-div">
                <div className="effective-profile-icon">
                  <img src={groupIcon} alt="" />
                </div>
                <div className="effective-profile-info-content-text">
                  {user?.profile?.ProfessionCode == "HC"
                    ? "To make your profile stand out, upload your skill checklist, licenses, and speciality certificates within the credentialing area."
                    : " To make your profile stand out, make sure to include any additional education, licenses, and certificates."}
                </div>
              </div>
            </div>
            <div className="effective-profile-info-content">
              <div className="effective-profile-info-content-div">
                <div className="effective-profile-icon">
                  <img src={noteIcon} alt="" />
                </div>
                <div className="effective-profile-info-content-text">
                  Provide the complete details of your education, work history,
                  and skills. List all of your skills and really sell your
                  expertise.
                </div>
              </div>
              <div className="effective-profile-info-content-div">
                <div className="effective-profile-icon">
                  <img src={userPlusIcon} alt="" />
                </div>
                <div className="effective-profile-info-content-text">
                  Create a detailed skill listing to land the exact gig you
                  want.
                </div>
              </div>
              <div className="effective-profile-info-content-div">
                <div className="effective-profile-icon">
                  <img src={starIcon} alt="" />
                </div>
                <div className="effective-profile-info-content-text">
                  A completed candidate profile builds confidence among
                  employers. A profile with good ratings and reviews from
                  employers help you land gigs faster and demand higher pay
                  rates.
                </div>
              </div>
            </div>
            <div className="effective-profile-info-lorem">
              Let our unique patented 3D Resume tell your professional story in
              a visually impactful way to get you hired faster!
            </div>
            <div className="effective-profile-info-btn-senctin">
              <div onClick={handleBack} className="btn-senctin-back">
                Back
              </div>
              <div onClick={handleContinue} className="btn-senctin-continue">
                Next
              </div>
            </div>
          </div>
        </div>
      )}
    </>
  );
};

export default SuccessProfileInfo;
