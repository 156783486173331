export function setCookie(cname, cvalue, exdays) {
  const d = new Date();
  d.setTime(d.getTime() + exdays * 24 * 60 * 60 * 1000);
  let expires = "expires=" + d.toUTCString();
  document.cookie = cname + "=" + cvalue + ";" + expires + ";path=/";
}

// export const setCookie = (cookieName, cookieValue, expiryDays) => {
//   const d = new Date(expiryDays ? 864000000000000 : 0);
//   let expires = "expires=" + d.toUTCString();
//   document.cookie = cookieName + "=" + cookieValue + ";" + expires + ";path=/";
// };

export const getCookie = (cookieName) => {
  let name = cookieName + "=";
  let decodedCookie = decodeURIComponent(document.cookie);
  let ca = decodedCookie.split(";");
  for (let i = 0; i < ca.length; i++) {
    let c = ca[i];
    while (c.charAt(0) == " ") {
      c = c.substring(1);
    }
    if (c.indexOf(name) == 0) {
      return c.substring(name.length, c.length);
    }
  }
  return null;
};

export const clearAllCookies = () => {
  var cookies = document.cookie.split("; ");
  for (var c = 0; c < cookies.length; c++) {
    var d = window.location.hostname.split(".");
    while (d.length > 0) {
      var cookieBase =
        encodeURIComponent(cookies[c].split(";")[0].split("=")[0]) +
        "=; expires=Thu, 01-Jan-1970 00:00:01 GMT; domain=" +
        d.join(".") +
        " ;path=";
      var p = window.location.pathname.split("/");
      document.cookie = cookieBase + "/";
      while (p.length > 0) {
        document.cookie = cookieBase + p.join("/");
        p.pop();
      }
      d.shift();
    }
  }
};

const deleteCookie = (name) => {
  setCookie(name, "", -1);
};

const deleteAllCookies = async () => {
  var cookies = document.cookie.split(";");
  for (var i = 0; i < cookies.length; i++)
    deleteCookie(cookies[i].split("=")[0]);
};

const deleteLocalStorage = async () => {
  const RememberMe = localStorage.getItem("RememberMe");
  const Email = localStorage.getItem("Email");
  const password = localStorage.getItem("password");

  localStorage.clear();
  sessionStorage.clear();

  if (RememberMe === "true") {
    localStorage.setItem("RememberMe", RememberMe);
    localStorage.setItem("Email", Email);
    localStorage.setItem("password", password);
  }
};

export const clearAll = async () => {
  deleteLocalStorage();
  deleteAllCookies();
  // clearAllCookies();
};

export const debounce = (func, timeout = 500) => {
  let timer;
  return (...args) => {
    clearTimeout(timer);
    timer = setTimeout(() => {
      func.apply(this, args);
    }, timeout);
  };
};

export const getFullStateName = (name) => {
  const data = {
    AL: "Alabama",
    AK: "Alaska",
    AZ: "Arizona",
    AR: "Arkansas",
    CA: "California",
    CO: "Colorado",
    CT: "Connecticut",
    DE: "Delaware",
    DC: "District of Columbia",
    FL: "Florida",
    GA: "Georgia",
    HI: "Hawaii",
    ID: "Idaho",
    IL: "Illinois",
    IN: "Indiana",
    IA: "Iowa",
    KS: "Kansas",
    KY: "Kentucky",
    LA: "Louisiana",
    ME: "Maine",
    MD: "Maryland",
    MA: "Massachusetts",
    MI: "Michigan",
    MN: "Minnesota",
    MS: "Mississippi",
    MO: "Missouri",
    MT: "Montana",
    NE: "Nebraska",
    NV: "Nevada",
    NH: "New Hampshire",
    NJ: "New Jersey",
    NM: "New Mexico",
    NY: "New York",
    NC: "North Carolina",
    ND: "North Dakota",
    OH: "Ohio",
    OK: "Oklahoma",
    OR: "Oregon",
    PA: "Pennsylvania",
    RI: "Rhode Island",
    SC: "South Carolina",
    SD: "South Dakota",
    TN: "Tennessee",
    TX: "Texas",
    UT: "Utah",
    VT: "Vermont",
    VA: "Virginia",
    WA: "Washington",
    WV: "West Virginia",
    WI: "Wisconsin",
    WY: "Wyoming",
    AS: "American Samoa",
    GU: "Guam",
    MH: "Marshall Islands",
    FM: "Micronesia",
    MP: "Northern Marianas",
    PW: "Palau",
    PR: "Puerto Rico",
    VI: "Virgin Islands",
  };
  if (data[name]) {
    return data[name];
  } else {
    return null;
  }
};

export const getStateCode = (name) => {
  const data = {
    Alabama: "AL",
    Alaska: "AK",
    Arizona: "AZ",
    Arkansas: "AR",
    California: "CA",
    Colorado: "CO",
    Connecticut: "CT",
    Delaware: "DE",
    "District of Columbia": "DC",
    Florida: "FL",
    Georgia: "GA",
    Hawaii: "HI",
    Idaho: "ID",
    Illinois: "IL",
    Indiana: "IN",
    Iowa: "IA",
    Kansas: "KS",
    Kentucky: "KY",
    Louisiana: "LA",
    Maine: "ME",
    Maryland: "MD",
    Massachusetts: "MA",
    Michigan: "MI",
    Minnesota: "MN",
    Mississippi: "MS",
    Missouri: "MO",
    Montana: "MT",
    Nebraska: "NE",
    Nevada: "NV",
    "New Hampshire": "NH",
    "New Jersey": "NJ",
    "New Mexico": "NM",
    "New York": "NY",
    "North Carolina": "NC",
    "North Dakota": "ND",
    Ohio: "OH",
    Oklahoma: "OK",
    Oregon: "OR",
    Pennsylvania: "PA",
    "Rhode Island": "RI",
    "South Carolina": "SC",
    "South Dakota": "SD",
    Tennessee: "TN",
    Texas: "TX",
    Utah: "UT",
    Vermont: "VT",
    Virginia: "VA",
    Washington: "WA",
    "West Virginia": "WV",
    Wisconsin: "WI",
    Wyoming: "WY",
    "American Samoa": "AS",
    Guam: "GU",
    "Marshall Islands": "MH",
    Micronesia: "FM",
    "Northern Marianas": "MP",
    Palau: "PW",
    "Puerto Rico": "PR",
    "Virgin Islands": "VI",
    Delhi: "DL",
  };
  return data[name];
};

export const  getImageByStateCode = (stateCode, cityName, format = "webp") => {
  if (stateCode==null){
    return "https://res.cloudinary.com/skillgigs/image/upload/pwa/images/default.jpg"
  }
  else if (cityName === "state") {
    
    const url = `https://res.cloudinary.com/skillgigs/image/upload/f_auto,w_800,h_600,c_limit/pwa/Locations/`;
    const fileName = getFullStateName(stateCode);
    return url + fileName?.replace(" ", "_") + ".jpg";
  } else {
    const url = `https://res.cloudinary.com/skillgigs/image/upload/f_auto,w_800,h_600,c_limit/pwa/images/${stateCode?.toLowerCase()}/${cityName?.replace(/ /g, "").toLowerCase()}/${stateCode?.toLowerCase()}_${cityName?.replace(/ /g, "").toLowerCase()}.jpg`; 
    return url;
  }
};

export const getImageFromCloudinary = (stateCode, cityName, nextCounter) => {
  let baseUrl = [
      "https://res.cloudinary.com/skillgigs/image/upload/",
      `f_auto,w_500,h_800,c_limit/pwa/${
        nextCounter > 0 ? "Locations" : "images"
      }/`,
      "pwa/images/default.jpg",
    ],
    url = "";
  switch (Number(nextCounter)) {
    case 0:
      url = `${baseUrl[0] + baseUrl[1]}${stateCode?.toLowerCase()}/${cityName?.replace(/ /g, "").toLowerCase()}/${stateCode?.toLowerCase()}_${cityName?.replace(/ /g, "").toLowerCase()}.jpg`;
      break;
    case 1:
      url =
        baseUrl[0] +
        baseUrl[1] +
        getFullStateName(stateCode)?.replaceAll(" ", "_") +
        ".jpg";
      break;
    default:
      url = "https://res.cloudinary.com/skillgigs/image/upload/pwa/images/default.jpg";
      break;
  }
  
  return url;
};

export const RemoteUrl = "https://res.cloudinary.com/skillgigs/image/upload/pwa/images/default.jpg";

export function readableDate(timestamp, secondary = false, locale = "") {
  const d = new Date(timestamp);
  if (locale.length > 0) {
    return d.toLocaleDateString(locale);
  }
  if (!secondary) {
    return d.toLocaleDateString();
  }
  return d.toLocaleDateString() + " " + d.toLocaleTimeString();
}

export function isEmpty(anObject) {
  for (let key in anObject) {
    return false;
  }
  return true;
}

export function getText(html) {
  var tmp = document.createElement("div");
  tmp.innerHTML = html;

  return tmp.textContent || tmp.innerText;
}

export const getUserDeviceDetails = (IPAddress) => {
  const userAgent = navigator.userAgent;
  let DeviceType, DeviceName, AdditionalInfo;

  if (/Windows/i.test(userAgent)) {
    DeviceType = "Desktop";
    DeviceName = "Windows";
    AdditionalInfo = userAgent;
  } else if (/Macintosh/i.test(userAgent)) {
    DeviceType = "Desktop";
    DeviceName = "Mac";
    AdditionalInfo = userAgent;
  } else if (/Android/i.test(userAgent)) {
    DeviceType = /Mobile|Tablet/i.test(userAgent) ? "Mobile" : "Tablet";
    DeviceName = userAgent.match(/(Android [\d.]+)/)?.[1];
    AdditionalInfo = userAgent;
  } else if (/iPhone|iPad|iPod/i.test(userAgent)) {
    DeviceType = /iPad/i.test(userAgent) ? "Tablet" : "Mobile";
    DeviceName = userAgent.match(/(iPhone|iPod|iPad);.*CPU.*OS (\d+)/i)?.[1];
    AdditionalInfo = userAgent;
  } else {
    DeviceType = "Unknown";
    DeviceName = userAgent;
    AdditionalInfo = userAgent;
  }

  return {
    DeviceName,
    DeviceType,
    AdditionalInfo,
    IPAddress,
  };
};

export const msgtxt = (html) => {
  let cache = {},
    character,
    e = document.createElement("div");
  return html?.replace(/([&][^&; ]+[;])/g, function (entity) {
    character = cache[entity];
    if (!character) {
      e.innerHTML = entity;
      if (e.childNodes[0])
        character = cache[entity] = e.childNodes[0].nodeValue;
      else character = "";
    }
    return character;
  });
};

export const isMobileForSocialAccount = () => {
  return /Android|webOS|iPhone|iPad|iPod|BlackBerry|IEMobile|Opera Mini/i.test(navigator.userAgent);
}

export const getCapitalisationFormat = (text) =>
  text
    .split(". ")
    .map((s) => s[0]?.toUpperCase() + s.slice(1)?.toLowerCase())
    .join(". ");

export const getRelativeTime = (prevDate) => {
  const diff = Number(new Date()) - new Date(prevDate);
  const minute = 60 * 1000;
  const hour = minute * 60;
  const day = hour * 24;
  const month = day * 30;
  const year = day * 365;
  switch (true) {
    case diff < minute:
      const seconds = Math.round(diff / 1000);
      return `${seconds} ${seconds > 1 ? "Secs" : "Sec"}`;
    case diff < hour:
      return Math.round(diff / minute) + "Mins";
    case diff < day:
      return Math.round(diff / hour) + "Hrs";
    case diff < month:
      return Math.round(diff / day) + "D";
    case diff < year:
      return Math.round(diff / month) + "M";
    case diff > year:
      return Math.round(diff / year) + " Y";
    default:
      return "";
  }
};

export const ArraySignUpRoute = [
  "/name",
  "/location",
  "/discipline",
  "/speciality",
  "/mobileno",
  "/explever",
  "/workAuthorization",
  "/license",
  "/resume-upload-v2",
  "/bls-doc",
  "/acls-doc",
  "/any-career-gap",
  "/state-lisence-doc",
  "/social-security-doc",
  "/driver-license-doc",
  "/create-skilllisting-gig-type",
  "/create-skilllisting-title",
  "/create-skilllisting-skill",
  "/create-skilllisting-location",
  "/create-skilllisting-salary",
  "/create-skilllisting-date",
  "/workPlacePolicy",
]

