import React, { useEffect } from "react";
import "./NewProfileHeader.css";
import { Menu } from "antd";
import { useHistory, useLocation, useParams } from "react-router-dom";
import { useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { clearAll } from "../../../shared/helpers";
import { Auth } from "../../../config/aws-exports";
import {
  fetchInterview,
  fetchReceivedBidsStages,
  fetchResumeDetailInit,
  fetchUserSkillListing,
  placeBidFetch,
  updateUserInfo,
} from "../../../redux/actions";
import ModalEdit from "../../../pages/Manage_SkillListing/ModalEdit";
import ProfileAvatarMenu from "./ProfileAvatarMenu";
import { useUpdateLogger } from "../../../shared/useUpdateLogger";

export const NewProfileHeader = (props) => {
  const [dimensions, setDimensions] = useState(window.innerWidth);
  const [editModalState, setEditModalState] = useState(false);
  const [oneSkillListing, setOneSkillListing] = useState();
  const [skillId, setSkillId] = useState();

  const Detail = useSelector((state) => state.resumeDetailReducer.success);
  const profile = Detail && Detail?.user && Detail?.user?.profile;
  const totalInterviewCount = useSelector(
    (state) => state.interviewReducer.success
  );
  const receivedBidsCount = useSelector(
    (state) => state.profileReducer?.receivedBidsCounts
  );
  const placedBidsCount = useSelector(
    (state) => state.profileReducer?.placedBidsCounts
  );

  const skillListing = useSelector(
    (state) => state.skillListingReducer.skillListings
  );

  const skillListingCountProfile = useSelector(
    (state) => state.profileReducer?.talentApiData?.Profiles?.Skilllistings
  );

  const history = useHistory();
  const initialPath = useLocation();
  const dispatch = useDispatch();
  // useEffect(() => {
  //   dispatch(fetchReceivedBidsStages());
  //   dispatch(fetchInterview());
  //   dispatch(fetchResumeDetailInit());
  //   dispatch(placeBidFetch("most_recent", null, null, 1, 24));
  // }, []);

  useEffect(() => {
    
    dispatch(fetchUserSkillListing("Published", 1, 24));
  }, [profile]);

  useEffect(() => {
    setSkillId(skillListing?.data?.[0]?.SkillListingId);
    setOneSkillListing(skillListing?.data?.[0]?._id);
  }, []);

  const Logout = async (redirect) => {
    // debugger;
    try {
      clearAll();
      dispatch(updateUserInfo(null));
      await Auth.signOut();
      redirect();
      localStorage.removeItem("redirectUrl");
    } catch (error) {}
  };
  const onLogOut = async () => {
    if (initialPath.pathname === "/") {
      dispatch(updateUserInfo(null));
      clearAll();
      await Auth.signOut();
    }
    await Logout(() => window.location.replace("/login"));
  };

  const handleSkillListing = () => {
    if (skillListing?.count?.totalCount > 1 || skillListingCountProfile > 1) {
      history.push("/manageskilllisting");
    } else if (
      skillListing?.count?.totalCount < 1 ||
      skillListingCountProfile < 1
    ) {
      history.push("/create-skilllisting");
    } else {
      window.innerWidth < 1000
        ? props?.setEditModalState(true)
        : setEditModalState(true);
    }
  };
  const handleUpdateSkillListing = (state) => {
    setEditModalState(state);
  };

  // useUpdateLogger("profile?.SkillListings:", profile?.SkillListings);

  return (
    <Menu className="menu-drop-down-container">
      <div
        onClick={() => props.hide(props?.visible)}
        className="profile-header-menu"
      >
        {window.innerWidth < 1000 && (
          <div className="profile-header-profile-item-profile-avtar-section">
            <ProfileAvatarMenu />
          </div>
        )}
        {window.innerWidth > 1000 ? (
          <div
            className="profile-header-profile-item"
            onClick={() => history.push("/profile")}
          >
            Profile
          </div>
        ) : (
          <>
            <div className="profile-header-profile-divider"></div>
            <div
              className="profile-header-profile-item-home"
              onClick={() => history.push("/")}
            >
              Home
            </div>
          </>
        )}
        <div className="profile-header-profile-divider"></div>
        <div
          onClick={() => {
            props.setEditModalStateRL(true);
          }}
          className="profile-header-profile-item"
        >
          Edit Profile
        </div>
        <div
          className="profile-header-profile-item"
          onClick={() => history.push("/3d_resume")}
        >
          Update 3D Resume
        </div>
        {profile?.ProfessionCode === "HC" && (
          <div
            className="profile-header-profile-item"
            onClick={() => history.push("/credentialing")}
          >
            Update Credentialing
          </div>
        )}
        <div
          className="profile-header-profile-item manage-skill-listing "
          onClick={handleSkillListing}
        >
          {skillListing?.count?.totalCount > 1 || skillListingCountProfile > 1
            ? "Manage Skill Listings"
            : skillListing?.count?.totalCount == 1 ||
              skillListingCountProfile == 1
            ? "Edit Skill Listing"
            : "Create Skill Listing"}
        </div>
        {window.innerWidth > 1000 && (
          <ModalEdit
            editModalState={editModalState}
            handleUpdateSkillListing={handleUpdateSkillListing}
            id={oneSkillListing || profile?.SkillListings?.[0]}
            idSkill={skillId}
          />
        )}
        <div className="profile-header-profile-divider"></div>
        {totalInterviewCount?.length > 0 && (
          <div
            className="profile-header-profile-item"
            onClick={() => history.push("/interview")}
          >
            <span>
              {totalInterviewCount?.length > 1 ? "Interviews" : "Interview"}
            </span>
            {totalInterviewCount.length > 0 && (
              <span className="counts">{totalInterviewCount?.length}</span>
            )}
          </div>
        )}
        {receivedBidsCount > 0 && (
          <div
            className="profile-header-profile-item"
            onClick={() => history.push("/receivedBids")}
          >
            <span>
              {receivedBidsCount > 1 ? "Received Bid(s)" : "Received Bid"}
            </span>
            <span className="counts">{receivedBidsCount}</span>
          </div>
        )}
        {placedBidsCount > 0 && (
          <div
            className="profile-header-profile-item"
            onClick={() => history.push("/placebids")}
          >
            <span> {placedBidsCount > 1 ? "Placed Bid(s)" : "Placed Bid"}</span>
            {placedBidsCount > 0 && (
              <span className="counts">{placedBidsCount}</span>
            )}
          </div>
        )}
        {(placedBidsCount > 0 ||
          receivedBidsCount > 0 ||
          totalInterviewCount > 0) && (
          <div className="profile-header-profile-divider"></div>
        )}
        <div
          className="profile-header-profile-item"
          onClick={() => history.push("/dashboard")}
        >
          Dashboard
        </div>
        <div
          className="profile-header-profile-item"
          onClick={() => history.push("/myfavorites")}
        >
          Favorites
        </div>
        <div className="profile-header-profile-divider"></div>
        <div className="profile-header-profile-item" onClick={onLogOut}>
          Sign Out
        </div>
      </div>
    </Menu>
  );
};
