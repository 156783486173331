import React, { useEffect, useState } from "react";
import { useHistory, useLocation, useParams } from "react-router-dom";
import { FaRegCheckCircle } from "react-icons/fa";
import { RiErrorWarningLine } from "react-icons/ri";
import { sendBidStatusURL, getJobsGuest } from "../../../API/API";
import axios from "axios";
import Loader from "../../../components/Loader/Loader";
import moment from "moment";
import "./BidStatus.css";
import { Button } from "antd";
import cross from "../../../assets/img/cross.png";
import tick from "../../../assets/img/tick.png";
import building from "../../../assets/img/building.png";
import GuestGigCard from "../../SearchGigs/GuestGigCard";
import { RightOutlined } from "@ant-design/icons";
import { useDispatch, useSelector } from "react-redux";
import { retractedBidUpdate } from "../../../redux/actions";
import { getImage } from "../../../shared/downloadAttachments";
const BidStatus = () => {
  const history = useHistory();
  const location = useLocation().search;
  const dispatch = useDispatch();
  const bidId = new URLSearchParams(location).get("bidId");
  const message = new URLSearchParams(location).get("message");
  const jobId = new URLSearchParams(location).get("gidId");
  const email = new URLSearchParams(location).get("email");
  const [response, setResponse] = useState({});
  const [status, setStatus] = useState(false);
  const [loading, setLoading] = useState(false);

  const [Jobloading, setJobLoading] = useState(false);
  const [suggestedJobs, setSuggestedJobs] = useState([]);
  const [profilePic, setProfilePic] = useState(null);

  const Token = useSelector((state) => state.authReducer.user.authToken);

  const types = {
    1: "initialEmail",
    2: "firstFollowUp",
    3: "secondFollowUp",
    4: "thirdFollowUp",
  }
  const sendBidStatusApi = async (params) => {
    try {
      setLoading(true);
      const data = await axios.get(sendBidStatusURL + params);
      if (data.status === 200) {
        setResponse(data?.data);
        setLoading(false);
        setStatus(data.status);
      }
    } catch (error) {
      if (
        error.response &&
        error.response.status &&
        error.response.status === 403
      ) {
        setStatus(403);
        setResponse(error.response.data);
      } else {
        setResponse({});
        setStatus(502);
      }
      setLoading(false);
    }
  };

  useEffect(() => {
    if (!bidId || !message) {
      history.push("/");
    } else {
      
      const params = `${
        message === "Bid Accepted" ? "Accepted" : "Declined"
      }/${bidId}?emailResponseType=${types[email]}`;
      sendBidStatusApi(params);
    }
    return () => { };
  }, []);

  useEffect(() => {
    if (response?.TalentId) {
      setJobLoading(true);
      getJobsGuest(response?.TalentId)
      .then(res => {
        setSuggestedJobs(res.data.data.body.Suggested);
        setJobLoading(false);
      });
    }
  }, [response]);

  const navigateToChat = () => {
    history.push({
      pathname: `/messages/${response.conversationId}`,
      state: {
        openConvo: true,
        conversationId: response.conversationId,
      },
    });
  };

  const detailsPage = () => {
    dispatch(retractedBidUpdate(false));
    history.push({
      pathname: `/details/${jobId}`,
      state: {
        id: jobId,
      },
    });
  };

  useEffect(async () => {
    let profilePic = null;
    if (response?.PictureUrl) {
      profilePic = await getImage(response?.PictureUrl);
    }
    setProfilePic(profilePic);
  }, [response]);

  if (loading) return <Loader />;

  return (
    <div>
      <div className="bidStatus-container">
        <div>
          <main className="landing_main">
            <div className="main_container">
              <div>
                {status == 200 ? (
                  <>
                    {message === "Bid Accepted" ? (
                      <div className="heading_text">
                        Thank you for showing interest in this gig.
                      </div>
                    ) : (
                      <div className="heading_text_2">
                        Uh ho! Looks like this gig doesn't match your
                        expectation.
                      </div>
                    )}
                  </>
                ) : (
                  <>
                    {response?.type === "Accepted" ? (
                      <div className="fav_text">
                        Already actioned ‘Interested’ on{" "}
                        {moment(response?.reactedDate)?.format("MM/DD/YYYY")}.
                      </div>
                    ) : (
                      <div className="fav_text">
                        Already actioned ‘Not interested’ on{" "}
                        {moment(response?.reactedDate)?.format("MM/DD/YYYY")}.
                      </div>
                    )}
                  </>
                )}

                <div
                  style={{
                    display: "flex",
                    alignItems: "center",
                    justifyContent: "center",
                  }}
                >

                  {status == 200 ? (
                  <img
                  className="tick-image"
                  src={message === "Bid Accepted" ? tick : cross}
                />
                ) : (
                  <img
                    className="tick-image"
                    src={response?.type === "Accepted" ? tick : cross}
                  />
                )}
                </div>
              </div>
              <div></div>
              <div className="data-container">
                <div style={{ display: "flex", alignItems: "center" }}>
                <div className="imageCont">
                {profilePic ? (
                  <img className="building-image" src={profilePic ?? building} />
                  ) : (
                    <div className="image-email-container">
                    <div className="image-email-text">
                      { response?.Gig?.FacilityName ?
                        response?.Gig?.FacilityName?.charAt(
                          0
                          ).toUpperCase() : 
                          response?.FAcilityName?.charAt(
                            0
                            ).toUpperCase()
                          }
                        </div>
                  </div>
                )}
                </div>
                  <div className="skill-name">
                    {status == 200
                      ? response?.Gig?.FacilityName
                      : response?.FAcilityName}
                  </div>
                </div>
                <div className="hos-name"
                onClick={(e) => {
                  e.stopPropagation();
                  detailsPage(e);
                }}
                >
                  {status == 200 ? response?.Gig?.Title : response?.Title}
                </div>
                <div className="gig-name">{response?.gigType+" gig"}</div>
                <div className="location-name">
                  {status == 200
                    ? response?.Gig?.Location?.LocationLong
                    : response?.Location?.LocationLong}
                </div>
                <div className="location-name">
                  Bid Received{" "}
                  <span className="rate-name">
                    {"$" + response.bidReceived ?? response?.ActualRate}
                  </span>
                </div>
              </div>
              <div className="last-page">
                <div className="opp-name">
                  Want to discuss this Opportunity further?
                </div>
                <div className="emp-text">Chat with the employer directly.</div>
                <Button className="btn-talk" onClick={navigateToChat}>
                  Let's talk
                </Button>
              </div>
            </div>
          </main>
        </div>
      </div>
      <div style={{ display: 'flex', justifyContent: 'space-between' , fontWeight: 'bold' }}>
        <div>
          While you wait, here are a few suggested gigs you can place a bid on.
        </div>
        {
          suggestedJobs.length > 0 ?
        <span
          className="HomePage-SeeAllJobs"
        onClick={() => {
          if(suggestedJobs?.length > 0 && !Token){
            history.push(`/search?pc=${suggestedJobs[0]?.Professions?.ProfessionCode}`);
          } else {
            history.push(`/search`);
          }
        }}
        >
          See all gigs{" "}
          <span className="HomePage-arrow-sign">
            <RightOutlined />
          </span>{" "}
        </span> : null
        }
      </div>
      <div className="bidStatus-grid">
        {Jobloading ? <Loader /> :
        suggestedJobs.length > 0 ?
          suggestedJobs.map((res) => {
            return (
              <GuestGigCard data={res} Types="MostPopular" />
            )
          }) : null
        }
      </div>
      <div>
      {
          suggestedJobs.length > 0 ?
        <span
          className="HomePage-SeeAllJobs-Mobile"
        onClick={() => {
          if(suggestedJobs?.length > 0 && !Token){
            history.push(`/search?pc=${suggestedJobs[0]?.Professions?.ProfessionCode}`);
          } else {
            history.push(`/search`);
          }
        }}
        >
          See all gigs{" "}
          <span className="HomePage-arrow-sign">
            <RightOutlined />
          </span>{" "}
        </span> : null
        }
      </div>
    </div>
  );
};

export default BidStatus;
