import React, { useState, useEffect } from "react";
import { useLocation } from "react-router-dom";

import "./RetractedBid.css";

import { Radio, Dropdown, Menu, Space, Pagination, Drawer } from "antd";

import Loader from "../../../components/Loader/Loader";

import FilterIcon from "../../../assets/icons/filtering.svg";
import sortIcon from "../../../assets/icons/sortIcon.svg";
import BidCard from "../../Bid/BidCard/BidCard";

import { useDispatch, useSelector } from "react-redux";

import { fetchReceivedBids, retractedBidFetch } from "../../../redux/actions";

import NoDataView from "../../Manage_SkillListing/NoSkillListingView";
import { useUpdateLogger } from "../../../shared/useUpdateLogger";

const RetractedBid = () => {
  const dispatch = useDispatch();
  const location = useLocation();

  const [page, setPage] = useState(1);
  const [perPage, setPerPage] = useState(window.innerWidth > 1000 ? 24 : 20);

  const [lat, setLat] = useState(null);
  const [long, setLong] = useState(null);
  const [isLocation, setIsLocation] = useState(false);

  // const [receivedBidStage, setReceivedBidStage] = useState("All");
  const [sort12, setSort12] = useState("most_recent");

  const [filterDrawer, setFilterDrawer] = useState(false);
  const [sortDrawer, setSortDrawer] = useState(false);

  const retractedBidss = useSelector(
    (state) => state.BidsReducer?.retractedBidData?.body?.data
  );

  const retractedBidsTotal = useSelector(
    (state) =>
      state.BidsReducer?.retractedBidData?.totalCount
  );

  useUpdateLogger("retractedBidsTotal" ,retractedBidsTotal)

  const retractloading = useSelector(
    (state) => state.BidsReducer.loading
  );

useUpdateLogger("Location" , lat );
  useEffect(() => {
    if (isLocation && navigator.geolocation) {
      navigator.geolocation.watchPosition((position) => {
        // 
        // 
        setLat(position.coords.latitude);
        setLong(position.coords.longitude);
        setIsLocation(false);
      });
    }
  }, [isLocation, navigator.geolocation]);

  const handleFilterDrawer = (state) => {
    setFilterDrawer(state);
  };

  const closeFilterDrawer = () => {
    setFilterDrawer(false);
  };

  const handleSortDrawer = (state) => {
    setSortDrawer(state);
  };

  const closeSortDrawer = () => {
    setSortDrawer(false);
  };

  useEffect(() => {
    dispatch(retractedBidFetch(sort12, lat, long, page, perPage));
  }, [sort12, lat, long, page, perPage]);

  useEffect(() => {
    // 
  }, [retractedBidss]);





  
  const sortArrayRB = [
    { "Most Recent": "most_recent" },
    { "Pay Package High to Low": "pay_hi_to_low" },
    { "Pay Package Low to High": "pay_low_to_hi" },
    { "Near By Location": "nearby_location" },
  ];

   

   

   
    
   
  const handleSortRB = (sort) => {
    // 
    // 
    setSort12(sort);
    if (sort === "nearby_location") {
      setIsLocation(true);
    } else setIsLocation(false);
  };

  const sortMenu = (
    <Menu>
      {sortArrayRB.map((sort) => {
        return (
          <Menu.Item onClick={() => handleSortRB(Object.values(sort)[0])}>
            <div className="sortItem-bids">
              <span
                className={
                  Object.values(sort)[0] === sort12 ? "greenTextRB" : null
                }
              >
                {Object.keys(sort)[0]}
              </span>
            </div>
          </Menu.Item>
        );
      })}
    </Menu>
  )

  const onChangeSort = (e) => {
    setSort12(e.target.value);
    if (e.target.value === "nearby_location") {
      setIsLocation(true);
    } else setIsLocation(false);
  };

  const showItemsSort = () => {
    dispatch(
      retractedBidFetch(16, 0, "All", location?.state?.skillListingId, sort12)
    );
  };

  const paginationHandle = (e) => {
    // 
    setPage(e);
  };

  return (
    <div className="receive-bid-page">
      <div className="received-bids-main-box">
        <section className="received-bids-filter-header">
          <div className="receive-bids-count">
            <span className="receive-bid-fonts">Retracted bids</span>
            <span className="receive-bid-font-item">
            {retractloading
                ? null
                : `${retractedBidss?.length > 0 ? retractedBidss?.length : 0}${
                    retractedBidss?.length > 1 ? " items" : " item"
                  }`}
            </span>
          </div>
          {!retractloading && retractedBidss?.length > 0 && (
            <div className="bid-filter-buttons">
               
              <Dropdown
                trigger="click"
                placement="bottomRight"
                overlay={sortMenu}
                overlayClassName="dropdownSort"
                disabled={window.innerWidth < 768 ? true : false}
              >
                <div
                  className="sort-received-bids"
                  onClick={() => {
                    if (window.innerWidth < 768) {
                      handleSortDrawer(!sortDrawer);
                    }
                  }}
                >
                  <img src={sortIcon} alt="sortIcon" />
                  <Drawer
                    title="Sort bids by"
                    placement="top"
                    onClose={closeSortDrawer}
                    visible={sortDrawer}
                    className="sortReceivedBidsDrawer"
                    height="40rem"
                    width="100%"
                  >
                    <Radio.Group
                      className="radioSortReceivedBidsGroup"
                      onChange={onChangeSort}
                      // value={receivedBidSort}
                    >
                      <Space direction="vertical">
                        <Radio
                          className="radioSortReceivedBids"
                          value={"most_recent"}
                        >
                          Most recent
                        </Radio>
                        <Radio
                          className="radioSortReceivedBids"
                          value={"pay_hi_to_low"}
                        >
                          Pay package high to low
                        </Radio>
                        <Radio
                          className="radioSortReceivedBids"
                          value={"pay_low_to_hi"}
                        >
                          Pay package low to high
                        </Radio>
                        <Radio
                          className="radioSortReceivedBids"
                          value={"nearby_location"}
                        >
                          Nearest to you
                        </Radio>
                      </Space>
                    </Radio.Group>
                    <div className="radioSortShowItemsParent">
                      <div
                        className="radioSortShowItemsChild"
                        onClick={showItemsSort}
                      >
                        Show items
                      </div>
                    </div>
                  </Drawer>
                </div>
              </Dropdown>
            </div>
          )}
        </section>
        <section className="receive-bids-card-section">    
          {retractloading ? (
            <Loader />
          ) : (
            <>
                         {retractedBidss?.length > 0 ? (
                            <>
                              {retractedBidss.map((bid) => {
                               return (
                                  <BidCard
                                     gig={bid.gig}
                                   bid={bid}
                                   key={bid._id}
                                    isRetractedBids={true}
                                  />
                                 );
                              })}
                             </>
                           ) : (
                <NoDataView />
              )}
            </>
          )}
        </section>
        <div
          style={{
            display: "flex",
            justifyContent: "center",
            marginTop: "5rem",
          }}
        >
          { retractedBidsTotal > perPage && (
            <Pagination
              hideOnSinglePage={true}
              defaultCurrent={1}
              total={retractedBidsTotal}
              // simple={true}
              onChange={(e) => paginationHandle(e)}
            />
          )}
        </div>
      </div>
    </div>
  );
};

export default RetractedBid;