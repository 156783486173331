import React from "react";
import "./CircleProgressbar.css";
import { Tooltip } from "antd";

const CircularProgressBar = ({ progress, size, isMenu }) => {
    const radius = size ? size / 2 : 50; // Radius of the circle
    const strokeWidth = 5; // Width of the progress bar
    const smallCircleRadius = 11; // Radius of the small circle

    const circumference = 2 * Math.PI * radius;
    const strokeDasharray = `${circumference} ${circumference}`;
    const strokeDashoffset = isMenu ? (circumference - (progress / 100) * circumference) - 8 : circumference - (progress / 100) * circumference;

  const smallCircleStyle = {
    position: "absolute",
    transform: `rotate(${ -progress * 3.6 }deg) translateY(-${
      isMenu ? radius - smallCircleRadius + 8 : radius - smallCircleRadius + 10
    }px)`,
  };

    const CircleTextRotation = {
        transform: `rotate(${progress * 3.6}deg)`
    }

    return (
        <div className="circular-progress-bar-container">
            <svg className="circular-progress-bar2" style={{width:`${size}px`, height: `${size}px`}} >
                <svg className="circular-progress-bar" >
                    <circle
                        className="progress-bar-background"
                        cx={radius}
                        cy={radius}
                        r={radius - strokeWidth / 2}
                    />
                    <circle
                        className="progress-bar"
                        cx={radius}
                        cy={radius}
                        r={radius - strokeWidth / 2}
                        strokeDasharray={strokeDasharray}
                        strokeDashoffset={-strokeDashoffset}
                    />
                </svg>
            </svg>
            <Tooltip
                placement="right"
                title={`Your 3D resume is ${progress}% completed`}
                overlayClassName="CircularProgressTooltip"
                trigger={"hover"}
            >
                <div className={`small-circle ${ isMenu ? "small-circle-menu" : "" }`} style={smallCircleStyle}>
                    <div className="percentage-text" style={CircleTextRotation} >{ isMenu ? null : `${progress}%` }</div>
                </div>
            </Tooltip>
        </div>
    );
};

export default CircularProgressBar;
