import React from "react";

import { Link } from "react-router-dom";

import createimg from "../../assets/icons/Create.svg";
import plussign from "../../assets/icons/bi_plus-lg (1).svg";

const NoSkillListingView = () => {
  return (
    <div className="noSkillListingView">
      <div className="Frame-20343">
        <img src={createimg} alt="create" className="CreateImg" />
        <span className="nodata">
        Create a skill listing to start selling your skills to possible employers
        </span>
        <Link to="/create-skilllisting" className="clickSkillListinggreen">
          <img src={plussign} alt="plus" className="plussign" />
          <div className="Create-a-Skill-Listing">Create a skill listing</div>
        </Link>
      </div>
    </div>
  );
};

export default NoSkillListingView;
