import React from 'react';
import './ReadRuleEffective.css'
import { BaseUrl } from '../../../components/Footer';

export const ContentText = [
    {
        Heading: "Avoid inaccurate or misleading information",
        Text: "Make sure your profile is honest and accurate."
    },
    {
        Heading: "Don't create duplicate accounts",
        Text: "Instead, add more skill listings to your existing account. Duplicate accounts can confuse employers."
    },
    {
        Heading: "Bid only on gigs you're interested in",
        Text: "First impressions are crucial with potential employers."
    },
    {
        Heading: "Respond to bids promptly",
        Text: "Don't let bids sit in a pending state, as this can result in missed opportunities."
    }, 
    {
        Heading: <> Follow SkillGigs <span onClick={() => {window.open(`${BaseUrl}/terms-of-service`);}} className="eff-terms-candi">terms and conditions</span></>,
        Text: "Keep all communication and payments within the system until you're hired on a contract or permanent basis."
    },
    {
        Heading: "Stay on top of profile activity",
        Text: <> As part of an e-commerce-style marketplace, you'll be notified of profile activity. Make sure to add <span onClick={() => window.location.href = "mailto:hello@alerts-skillgigs.com"} className='eff-alerts-email'>hello@alerts-skillgigs.com</span> to your contacts to prevent critical alerts from going to spam.</>
    }
]

const ReadRuleEffective = ({data}) => {
    return (
        <div className='Effective-sign-main-div'>
            <div className='Effective-signUp-heading'> {data?.Heading} </div>
            <div className='Effective-signUp-Text'>{data?.Text}</div>
        </div>
    );
};
export default ReadRuleEffective;