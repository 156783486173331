import { put, call, takeEvery } from "@redux-saga/core/effects";
import {
  addRemoveEmployerFollowAPI,
  fetchCompanyProfileAPI,
  fetchCompanyProfileGigsAPI,
  fetchViewListingAPI,
} from "../../API/API";
import { types } from "../action-types";
import {
  addRemoveEmployerFollowFailure,
  addRemoveEmployerFollowSuccess,
  catchError,
  fetchCompanyProfileFailure,
  fetchCompanyProfileSuccess,
  fetchViewListingFailure,
  fetchViewListingSuccess,
} from "../actions";
function* fetchViewListingSaga({ payload: params }) {
  try {
    const response = yield call(() => fetchViewListingAPI(params)); // API Backend
    if (response.status == 200) {
      yield put(fetchViewListingSuccess(response?.data?.data?.body?.data));
    } else {
      yield put(fetchViewListingFailure(response));
    }
  } catch (error) {
    yield put(fetchViewListingFailure(error));
    yield put(catchError(error));
  }
}

function* fetchCompanyProfileSaga({ payload: params }) {
  try {
    const response = yield call(() => fetchCompanyProfileAPI(params)); // API Backend
    if (response.status == 200) {
      yield put(fetchCompanyProfileSuccess(response?.data));
    } else {
      yield put(fetchCompanyProfileFailure(response));
    }
  } catch (error) {
    yield put(fetchCompanyProfileFailure(error));
    yield put(catchError(error));
  }
}

function* fetchCompanyProfileGigsSaga({ payload: params }) {
  try {
    const response = yield call(() => fetchCompanyProfileGigsAPI(params)); // API Backend
    let payload;
    // if (response && response.length == 2 && response[0].data && response[1].data && response[0].status == 200 && response[1].status == 200) {
    if (response && response.length == 2 && response?.[0].value?.data) {
      let companyGigs = response?.[0]?.value?.data?.publishedGigs;
      let companyGigsCount = response?.[1]?.data?.totalCount?.[0]?.published;
      payload = {
        companyGigs: companyGigs ? companyGigs : [],
        companyGigsCount: companyGigsCount ? companyGigsCount : 0
      }
      yield put({ type: types.FETCH_COMPANYPROFILE_GIGS_SUCCESS, payload });
    }
    else {
      yield put({ type: types.FETCH_COMPANYPROFILE_GIGS_FAILURE });
    }
  } catch (error) {
    console.warn('error', error)
    yield put({ type: types.FETCH_COMPANYPROFILE_GIGS_FAILURE });
  }
}

function* addRemoveEmployerFollowSaga({ payload }) {
  try {
    const response = yield call(() => addRemoveEmployerFollowAPI(payload)); // API Backend
    if (response.status == 200) {
      yield put(addRemoveEmployerFollowSuccess(response?.data));
    } else {
      yield put(addRemoveEmployerFollowFailure(response));
    }
  } catch (error) {
    yield put(addRemoveEmployerFollowFailure(error));
  }
}

export function* watchCompanyProfileSaga() {
  yield takeEvery(types.FETCH_COMPANYPROFILE_INIT, fetchCompanyProfileSaga);
}

export function* watchCompanyProfileGigsSaga() {
  yield takeEvery(types.FETCH_COMPANYPROFILE_GIGS_INIT, fetchCompanyProfileGigsSaga);
}

export function* watchViewListingSaga() {
  yield takeEvery(types.FETCH_VIEWLISTING_INIT, fetchViewListingSaga);
}

export function* watchEmployerFollowSaga() {
  yield takeEvery(
    types.ADD_REMOVE_EMPLOYER_FOLLOW_INIT,
    addRemoveEmployerFollowSaga
  );
}
