import { Rate, Row } from 'antd'
import React from 'react'
import { useDispatch, useSelector } from "react-redux";
import './styles.css'

const RatingsCard = ({ items, Rating, Ratings_data, image, candidate_name, skill_listing_title, job_title, date_from, date_to }) => {


  let userProfile = useSelector((state) => state.profileReducer.user.profile);

  // 

  var average_rating = (Ratings_data?.ConsultantRating?.Communication + Ratings_data?.ConsultantRating?.Recommendation + Ratings_data?.ConsultantRating?.WorkingKnowledge) / 3
  return (
    <>
      {userProfile?.AccountId != items?.SenderUser?.AccountId ? (<div>
          <div>Thank you! <br />
          {/* {`${items?.SenderUser?.Profiles?.FirstName} ${items?.SenderUser?.Profiles?.LastName}`} left you {Math.floor(average_rating)} star review */}
          Employer left you {(average_rating.toFixed(1))} star review
          </div>
          <div className='chat-ratingCard-innerDiv'>
            <div className='innerDiv-TopRating'><Rate allowHalf disabled value={average_rating} /></div>
            <Row className='innerDiv-bottom-row'>
              <div>
                Communication
              </div>
              <div>
                <Rate allowHalf disabled value={Ratings_data?.ConsultantRating?.Communication} />
              </div>
            </Row>
            <Row className='innerDiv-bottom-row'>
              <div>
              Would recommend
              </div>
              <div>
                <Rate allowHalf disabled value={Ratings_data?.ConsultantRating?.Recommendation} />
              </div>
            </Row>
            <Row className='innerDiv-bottom-row'>
              <div>
              Work knowledge
              </div>
              <div>
                <Rate allowHalf disabled value={Ratings_data?.ConsultantRating?.WorkingKnowledge} />
              </div>
            </Row>
            <p style={{ textAlign: 'center', marginTop: '4.5px' }}>{Ratings_data?.ConsultantComment}</p>
          </div>
        </div>) : (<div>
          <div>Thank you! <br />
            You left {`${items?.SenderUser?.Profiles?.FirstName} ${items?.SenderUser?.Profiles?.LastName}`} {Ratings_data?.ClientRating} star review
          </div>
          <div className='chat-ratingCard-innerDiv'>
            <div className='innerDiv-TopRating'><Rate allowHalf disabled value={Ratings_data?.ClientRating} /></div>
            {/* <Row className='innerDiv-bottom-row'>
              <div>
                Communication
              </div>
              <div>
                <Rate value={Ratings_data?.ConsultantRating?.Communication} />
              </div>
            </Row>
            <Row className='innerDiv-bottom-row'>
              <div>
                Work knowledge
              </div>
              <div>
                <Rate value={Ratings_data?.ConsultantRating?.Recommendation} />
              </div>
            </Row>
            <Row className='innerDiv-bottom-row'>
              <div>
                Would recommend
              </div>
              <div>
                <Rate value={Ratings_data?.ConsultantRating?.WorkingKnowledge} />
              </div>
            </Row> */}
            <p style={{ textAlign: 'center', marginTop: '4.5px' }}>{Ratings_data?.ClientComment}</p>
          </div>
        </div>)
      }

      {/* Ratings Rejected Card */}
      {/* {
        <div>
          <strong>Ratings Rejected</strong>
          <p>
            Sorry Your rating has been rejected by SkillGigs
          </p>
          <div style={{ background: '#fff', padding: '5px' }}>
            <strong>Reason</strong>
            <p>
              Your review is againt community guidelines
            </p>
          </div>
        </div>
      } */}

      {/* {
        <div>
          <div style={{ fontWeight: '600' }}>Request Submitted</div>
          <p>
            Your request for delete ratings has been successfully submitted to SkillGigs. Will notify you when this is complete.
          </p>
          <div style={{ background: '#fff', padding: '5px 10px', borderRadius: '4px' }}>
            <strong>Reason</strong>
            <p>
              Lorem Ipsum is simply dummy text of the
              printing and typesetting industry. Lorem Ipsum
              has been the industry's standard dummy text
              ever since the 1500s
            </p>
          </div>
        </div>
      } */}
    </>
  )
}
export default RatingsCard