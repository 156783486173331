import React from "react";
import UploadDocV2 from "../../UploadDoc";

const ACLS_DocV2 = () => {
  const docInfo = [
    {
      "ACLS - Advanced cardiovascular life support - front": 11,
    },
    {
      "ACLS - Advanced cardiovascular life support - back": 82,
    },
  ];

  return (
    <div>
      <UploadDocV2
        docInfo={docInfo}
        backUrl="bls-doc"
        nextUrl="state-lisence-doc"
        currentPage={3}
        WizardFlow={114}
        IsMandatory={true}
        skipUrl={"state-lisence-doc"}
      />
    </div>
  );
};

export default ACLS_DocV2;
