import React, { useState, useEffect } from "react";
import { Input, Button, Col, Alert, DatePicker, Row, Spin } from "antd";
import {
  FileSyncOutlined,
  ClockCircleOutlined,
  SearchOutlined,
} from "@ant-design/icons";
import { useDispatch, useSelector } from "react-redux";
import { useHistory } from "react-router-dom";
import GigsCard from "../SearchGigs/GigsCard";
import "./MyFavorites.css";
import browsers from "../../assets/img/browsers.svg";
import { getFavoritesInfo } from "../../redux/actions";
import "antd/dist/antd.css";
import { Pagination } from "antd";
import { state } from "@antv/g2plot/lib/adaptor/common";
import { useUpdateLogger } from "../../shared/useUpdateLogger";
import Loader from "../../components/Loader/Loader";
import ToolTips from "../../components/ToolTip/ToolTips";

export function LocationUpdate(func) {
  return {
    Code: func?.RegionCode,
    CountryId: func?.CountryId,
    RegionId: func?.RegionId,
    RegionName: func?.RegionName
  }
}
export function LocationUpdateCity(func) {
  return {
    CityId: func?.CityId,
    CityName: func?.CityName,
    Latitude: func?.Latitude,
    Longitude: func?.Longitude
  }
}

const MyFavoritess = (props) => {
  const dispatch = useDispatch();
  const totalFavorites = useSelector(
    (state) => state.myFavoritesReducer.myFavoritesTotal
  );
  const [CardTotal, setCardTotal] = useState(totalFavorites?.totalResults);
  const myfavorites = useSelector(
    (state) => state.myFavoritesReducer.myFavorites
  );
  const ProfessionCode = useSelector((state) => state.profileReducer?.user?.profile?.ProfessionCode)

  const Loading = useSelector((state) => state.myFavoritesReducer.Loading);

  const handleTotalCardFav = () => {
    if (CardTotal - perPage * page >= 0)
      dispatch(getFavoritesInfo(page, perPage));
    else if ((CardTotal - 1) % perPage === 0)
      dispatch(getFavoritesInfo(page - 1, perPage));
    else setCardTotal(CardTotal - 1);
  };

  const [perPage, setPerPage] = useState(window.innerWidth < 768 ? 20 : 24);
  const [page, setPage] = useState(1);

  useEffect(() => {
    dispatch(getFavoritesInfo(page, perPage));
  }, [page, perPage]);

  useEffect(() => {
    // 
    // 
    setCardTotal(totalFavorites?.totalResults);
  }, [totalFavorites?.totalResults]);

  const paginationHandle = (e) => {
    // 
    setPage(e);
  };

  // const [list,setList]= useState(props.list);
  return (
    <>
      {Loading ? (
        <Loader />
      ) : (
        <div className="myfavorites-main-container">
          {myfavorites?.length ? (
            <div className="myfavorites-container">
              <div className="myfavorites-heading">
                My favorites{" "}
                <ToolTips
                  placement={window.innerWidth > 768 ? "bottomLeft" : "bottom"}
                  title="We’ll gather a collection of your favorite gigs in “My Favorites”. Choose the heart to add the gig to your favorites."
                />{" "}
              </div>
              <div className="myfavorites-subheading"> {CardTotal} items</div>
              {/* <div>
          <Input
        placeholder="Select Skill"
        prefix={<SearchOutlined />}
        
        // style={{ width: 200 }}  className="skill-search-mobile"
        
      />
          </div> */}
            </div>
          ) : null}
          <section
            className={`myfavorites-section ${
              myfavorites?.length > 0 && "Favorites-card-width"
            }`}
          >
            {myfavorites && myfavorites?.length > 0 ? (
              myfavorites?.map((data) => {
                let GigData = data?.gig;
                if(!data?.gig?.hasOwnProperty("Regions")){
                  GigData["Regions"] = LocationUpdate(data?.gig?.Location);
                };
                if (!data?.gig?.hasOwnProperty("Cities")){
                  GigData["Cities"] = LocationUpdateCity(data?.gig?.Location);
                }
                if (!data?.gig?.hasOwnProperty("Professions")){
                  GigData["Professions"] = { ProfessionCode: ProfessionCode };
                }
                if (ProfessionCode === "IT" && !data?.gig?.hasOwnProperty("companyName")){
                  GigData["companyName"] = data?.gig?.CompanyName;
                }
                return <GigsCard Types = "Favorites" data={GigData} handleTotalCardFav={handleTotalCardFav} />;
              })
            ) : (
              <Col
                lg={20}
                xs={24}
                sm={22}
                className={"empty-container-favorite"}
              >
                <div className="empty-container-main-favorite">
                  <strong className="favorite-empty-container">
                    You have no favorite gigs
                  </strong>
                </div>
                <span>
                  Add gigs that interest you to favorites.
                  <br />
                  You can review your favorites at anytime and apply easily.
                </span>
                <br />
                {/* <FileSyncOutlined className={'empty'}/> */}
                <img
                  className="Empty-my-fav"
                  src={browsers}
                  alt="Browser Logo"
                  height={"199.6"}
                  style={{ marginTop: "50px" }}
                ></img>
              </Col>
            )}
          </section>

          {totalFavorites?.totalResults > perPage && (
            <div className="favorite-pagination">
              <Pagination
                defaultCurrent={1}
                hideOnSinglePage={true}
                total={CardTotal}
                pageSize={perPage}
                onChange={(e) => paginationHandle(e)}
                current={page}
                showSizeChanger={false}
              />
            </div>
          )}
        </div>
      )}
    </>
  );
};
export default MyFavoritess;
