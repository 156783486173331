 
import React from 'react'

import "./EducationalScreenTwo.css";
const EducationalComponentTwo = ({ photo, header, subheader} ) => {
   const parts = subheader.split("form or email");
    
 return (
   <div className="box-2">
        <div className="logo-view-2">
   <div className="background-photo-2"> <img className="inner-photo-2" src={photo} alt="Profile" />
        
        </div> 
       
        <div className="box-headerr-2">{header}</div> 
       </div> 
        <div className="subheaderr-2">
        {parts.map((part, index) => (
         index === 0 
           ? part
           : (
             <span key={index}>
               <span style={{ textDecoration: 'underline', color: '#4054B2' , cursor:"pointer" }}>form</span> and <span style={{ textDecoration: 'underline', color: '#4054B2', cursor:"pointer" }}>email</span>
               {part}
             </span>
           )
       ))}

        </div>
      
       </div>
 )
}

export default EducationalComponentTwo;
