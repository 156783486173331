import React from 'react';
import ChatWithRecruitor from "../../../assets/icons/ChatWithRecruitor.svg";
import ChatRecruitorWhite from "../../../assets/icons/chatRecruitorWhite.svg";
import { useHistory } from "react-router-dom";
import { useParams } from "react-router-dom";
import { ToolTipsAlert } from '../../../components/ToolTip/ToolTips';
import { BidinfoTooltip } from '../../../components/BidInfoTooltip';

const NewPlacedBidMobile = ({ disabled, yesText, noText, OpenModalBoxStages, stagename, BidDetailData}) => {
    const { bidId } = useParams();
    const history = useHistory();
    const editBidRoute = () => {
        history.push({
            pathname: `/edit-bid/${bidId}`,
            state: {
                id: bidId,
            },
        });
    }

    return (
        <div>
            <section className="edit-retract-row">
                <button
                    className={disabled ? "editButton-bid-disabled" : "editButton-bid"}
                    onClick={editBidRoute}
                >
                    <BidinfoTooltip placement={'top'}>
                    {yesText}
                    </BidinfoTooltip>
                </button>
                <button
                    className={
                        disabled ? "retractedButton-bid-disabled" : "retractedButton-bid"
                    }
                    onClick={() => { OpenModalBoxStages(stagename) }}
                >
                    {noText}
                </button>
                <button
                    className={disabled ? "chatbutton-bid-green" : "chatbutton-bid"}
                    onClick={() => history.push({
                    pathname: `/messages/${BidDetailData?.ConversationId}`,
                    state: {
                      openConvo: true,
                      conversationId: BidDetailData?.ConversationId,
                    },
                  })}
                >
                    <img
                        src={disabled ? ChatRecruitorWhite : ChatWithRecruitor}
                        alt="chat"
                    />
                </button>
            </section>
        <ToolTipsAlert width="width" title="Retract your bid to remove your bid completely. The employer will no longer consider you for the gig." />
        </div>
    );
};

export default NewPlacedBidMobile;