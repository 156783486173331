import { call, put, takeEvery, takeLatest, takeLeading } from "redux-saga/effects";
import { types } from "../action-types";
import { fetchDataFailure, fetchDataSuccess } from "../actions";
import { fetchDataApi, addToFavouriteApi, removeFromFavouriteApi } from "../../API/API";

function* fetchAddMessageToFavourite(params) {
  
  try {
    const response = yield call(() => addToFavouriteApi(params)); // API Backend
    // 
    yield put({ type: types.ADD_MESSAGE_TO_FAVOURITE_SUCCESS, payload: response.data});
    yield put({ type: types.FAVOURITE_SUCCESS, payload: params?.payload?.Index});
    // yield put(fetchDataSuccess(response.data));
  } catch (error) {
    yield put({ type: types.ADD_MESSAGE_TO_FAVOURITE_FAILURE, error });
  }
}

export function* watchfetchAddMessageToFavourite() {
  yield takeLeading(types.ADD_MESSAGE_TO_FAVOURITE_REQUESTED, fetchAddMessageToFavourite);
}

function* fetchRemoveMessageFromFavourite(params) {
    try {
      const response = yield call(() => removeFromFavouriteApi(params)); // API Backend
      // 
      yield put({ type: types.REMOVE_MESSAGE_TO_FAVOURITE_SUCCESS, payload: response.data });
      yield put({ type: types.REMOVE_FAV_SUCCESS, payload: params?.payload?.Index});

      // yield put(fetchDataSuccess(response.data));
    } catch (error) {
        yield put({ type: types.REMOVE_MESSAGE_TO_FAVOURITE_FAILURE, error });
    }
  }
  
  export function* watchfetchRemoveMessageFromFavourite() {
    yield takeLeading(types.REMOVE_MESSAGE_TO_FAVOURITE_REQUESTED, fetchRemoveMessageFromFavourite);
  }
  
