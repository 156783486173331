import React, { useState } from "react";
import { Radio, Space } from "antd";
import "./Filter.css";

export default function FilterConversations(props) {
  // 

  const [value, setValue] = useState(props.counts?.data?.totalConversations);
  const onChangeConversations = (e) => {
    props.setConversationsRadioValue(e.target.value);
  };

  const onChangeBidType = (e) => {
    props.setBidTypeRadioValue(e.target.value);
  };

  const onChangeBidStatus = (e) => {
    props.setBidStatusRadioValue(e.target.value);
  };

  // 
  return (
    <div className="filter-container">
      <Radio.Group
        onChange={onChangeConversations}
        value={props.conversationsRadioValue}
      >
        <Space direction="vertical">
          <Radio
            className="chat-filter-radios"
            value="all_conversations"
            onClick={() => setValue(props.counts?.data?.totalConversations)}
          >
            <div className="message-filter-options">
              {" "}
              <div>All conversations </div>
              {/* <div>
                {`(${props.counts?.data?.totalConversations ?? 0})`}
              </div>{" "} */}
            </div>
          </Radio>
          <Radio
            className="chat-filter-radios"
            value="unread"
            onClick={() => setValue(props.counts?.data?.allCount?.unread ?? 0)}
          >
            <div className="message-filter-options">
              {" "}
              <div>Unread</div>
              {/* <div>{`(${props.counts?.data?.allCount?.unread ?? 0})`}</div>{" "} */}
            </div>
          </Radio>
          <Radio
            className="chat-filter-radios"
            value="favourites"
            onClick={() =>
              setValue(props.counts?.data?.allCount?.Favorites ?? 0)
            }
          >
            <div className="message-filter-options">
              {" "}
              <div>Favorites</div>
              {/* <div>
                {`(${props.counts?.data?.allCount?.Favorites ?? 0})`}
              </div>{" "} */}
            </div>
          </Radio>
        </Space>
      </Radio.Group>

      <div className="chat-section-header">Bid type</div>
      <Radio.Group onChange={onChangeBidType} value={props.bidTypeRadioValue}>
        <Space direction="vertical">
          <Radio
            value="all_bids"
            className="chat-filter-radios"
            onClick={() =>
              setValue(props.counts?.data?.totalConversations ?? 0)
            }
          >
            <div className="message-filter-options">
              {" "}
              <div>All bids</div>
              {/* <div>
                {`(${props.counts?.data?.allCount?.AllBids ?? 0})`}
              </div>{" "} */}
            </div>
          </Radio>
          <Radio
            value="received_bids"
            className="chat-filter-radios"
            onClick={() =>
              setValue(props.counts?.data?.allCount?.ReceivedBids ?? 0)
            }
          >
            <div className="message-filter-options">
              {" "}
              <div>Place bids</div>
              {/* <div>
                {`(${props.counts?.data?.allCount?.ReceivedBids ?? 0})`}
              </div>{" "} */}
            </div>
          </Radio>
          <Radio
            value="placed_bids"
            className="chat-filter-radios"
            onClick={() =>
              setValue(props.counts?.data?.allCount?.PlacedBids ?? 0)
            }
          >
            <div className="message-filter-options">
              {" "}
              <div>Received bids</div>
              {/* <div>
                {`(${props.counts?.data?.allCount?.PlacedBids ?? 0})`}
              </div>{" "} */}
            </div>
          </Radio>
        </Space>
      </Radio.Group>
      <div className="chat-section-header">Filter by bid status</div>
      <Radio.Group
        onChange={onChangeBidStatus}
        value={props.bidStatusRadioValue}
      >
        <Space direction="vertical">
          <Radio
            className="chat-filter-radios"
            value="New"
            onClick={() => setValue(props.counts?.data?.allCount?.New ?? 0)}
          >
            <div className="message-filter-options">
              {" "}
              <div>New bids</div>
              {/* <div>{`(${props.counts?.data?.allCount?.New ?? 0})`}</div>{" "} */}
            </div>
          </Radio>
          <Radio
            value="Accepted"
            className="chat-filter-radios"
            onClick={() =>
              setValue(props.counts?.data?.allCount?.Accepted ?? 0)
            }
          >
            <div className="message-filter-options">
              {" "}
              <div>Interested</div>
              {/* <div>
                {`(${props.counts?.data?.allCount?.Accepted ?? 0})`}
              </div>{" "} */}
            </div>
          </Radio>
          <Radio
            value="Declined"
            className="chat-filter-radios"
            onClick={() =>
              setValue(props.counts?.data?.allCount?.Declined ?? 0)
            }
          >
            <div className="message-filter-options">
              {" "}
              <div>Not interested</div>
              {/* <div>
                {`(${props.counts?.data?.allCount?.Declined ?? 0})`}
              </div>{" "} */}
            </div>
          </Radio>

          <Radio
            value="Profile_Submitted"
            className="chat-filter-radios"
            onClick={() =>
              setValue(props.counts?.data?.allCount?.ProfileSubmitted ?? 0)
            }
          >
            <div className="message-filter-options">
              {" "}
              <div>Profile submitted</div>
              {/* <div>
                {`(${props.counts?.data?.allCount?.ProfileSubmitted ?? 0})`}
              </div>{" "} */}
            </div>
          </Radio>
          <Radio value="Interview" className="chat-filter-radios">
            <div
              className="message-filter-options"
              onClick={() =>
                setValue(props.counts?.data?.allCount?.Interview ?? 0)
              }
            >
              {" "}
              <div>Interview</div>
              {/* <div>
                {`(${props.counts?.data?.allCount?.Interview ?? 0})`}
              </div>{" "} */}
            </div>
          </Radio>
          <Radio
            value="Interview_Accepted"
            className="chat-filter-radios"
            onClick={() =>
              setValue(props.counts?.data?.allCount?.InterviewAccepted ?? 0)
            }
          >
            <div className="message-filter-options">
              {" "}
              <div>Interview accepted</div>
              {/* <div>
                {`(${props.counts?.data?.allCount?.InterviewAccepted ?? 0})`}
              </div>{" "} */}
            </div>
          </Radio>

          <Radio
            value="Interview_Declined"
            className="chat-filter-radios"
            onClick={() =>
              setValue(props.counts?.data?.allCount?.InterviewDeclined ?? 0)
            }
          >
            <div className="message-filter-options">
              {" "}
              <div>Interview declined/canceled</div>
              {/* <div>
                {`(${props.counts?.data?.allCount?.InterviewDeclined ?? 0})`}
              </div>{" "} */}
            </div>
          </Radio>
          <Radio
            value="Offer"
            className="chat-filter-radios"
            onClick={() => setValue(props.counts?.data?.allCount?.Offer ?? 0)}
          >
            <div className="message-filter-options">
              {" "}
              <div>Offer sent</div>
              {/* <div>{`(${props.counts?.data?.allCount?.Offer ?? 0})`}</div>{" "} */}
            </div>
          </Radio>
          <Radio
            value="Offer_Accepted"
            className="chat-filter-radios"
            onClick={() => setValue(props.counts?.data?.allCount?.Offer ?? 0)}
          >
            <div className="message-filter-options">
              {" "}
              <div>Offer accepted</div>
              {/* <div>
                {`(${props.counts?.data?.allCount?.OfferAccepted ?? 0})`}
              </div>{" "} */}
            </div>
          </Radio>

          <Radio
            value="Offer_Declined"
            className="chat-filter-radios"
            onClick={() => setValue(props.counts?.data?.allCount?.Offer ?? 0)}
          >
            <div className="message-filter-options">
              {" "}
              <div>Offer declined</div>
              {/* <div>
                {`(${props.counts?.data?.allCount?.OfferDeclined ?? 0})`}
              </div>{" "} */}
            </div>
          </Radio>
          <Radio
            value="Hired"
            className="chat-filter-radios"
            onClick={() => setValue(props.counts?.data?.allCount?.Hired ?? 0)}
          >
            <div className="message-filter-options">
              {" "}
              <div>Hired</div>
              {/* <div>{`(${props.counts?.data?.allCount?.Hired ?? 0})`}</div>{" "} */}
            </div>
          </Radio>
          <Radio
            value="On_Billing"
            className="chat-filter-radios"
            onClick={() =>
              setValue(props.counts?.data?.allCount?.OnBilling ?? 0)
            }
          >
            <div className="message-filter-options">
              {" "}
              <div>On billing</div>
              {/* <div>
                {`(${props.counts?.data?.allCount?.OnBilling ?? 0})`}
              </div>{" "} */}
            </div>
          </Radio>

          <Radio
            value="Off_Billing"
            className="chat-filter-radios"
            onClick={() =>
              setValue(props.counts?.data?.allCount?.OffBilling ?? 0)
            }
          >
            <div className="message-filter-options">
              {" "}
              <div>Off biling</div>
              {/* <div>
                {`(${props.counts?.data?.allCount?.OffBilling ?? 0})`}
              </div>{" "} */}
            </div>
          </Radio>
          <Radio
            value="Billing_Extended"
            className="chat-filter-radios"
            onClick={() =>
              setValue(props.counts?.data?.allCount?.BillingExtend ?? 0)
            }
          >
            <div className="message-filter-options">
              {" "}
              <div>Billing extended</div>
              {/* <div>
                {`(${props.counts?.data?.allCount?.BillingExtend ?? 0})`}
              </div>{" "} */}
            </div>
          </Radio>
          <Radio
            value="Cancelled"
            className="chat-filter-radios"
            onClick={() =>
              setValue(props.counts?.data?.allCount?.Canceled ?? 0)
            }
          >
            <div className="message-filter-options">
              {" "}
              <div>Canceled</div>
              {/* <div>
                {`(${props.counts?.data?.allCount?.Canceled ?? 0})`}
              </div>{" "} */}
            </div>
          </Radio>

          <Radio
            value="Terminated"
            className="chat-filter-radios"
            onClick={() =>
              setValue(props.counts?.data?.allCount?.Terminated ?? 0)
            }
          >
            <div className="message-filter-options">
              {" "}
              <div>Terminated</div>
              {/* <div>
                {`(${props.counts?.data?.allCount?.Terminated ?? 0})`}
              </div>{" "} */}
            </div>
          </Radio>
          <Radio
            value="Retracted"
            onClick={() =>
              setValue(props.counts?.data?.allCount?.Retracted ?? 0)
            }
          >
            <div className="message-filter-options">
              {" "}
              <div>Retracted</div>
              {/* <div>
                {`(${props.counts?.data?.allCount?.Retracted ?? 0})`}
              </div>{" "} */}
            </div>
          </Radio>
        </Space>
      </Radio.Group>
    </div>
  );
}
