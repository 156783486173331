import React, { useState, useEffect } from "react";
import { Progress, Radio } from "antd";
import "./Progress.css";
import { useLocation } from "react-router-dom";

export const ProgressBar = (props) => {
  const { title } = props;
  let page;
  const location = useLocation();

  switch (location.pathname) {
    case "/create-skilllisting-gig-type":
      page = 1;
      break;
    case "/create-skilllisting-title":
      page = 2;
      break;
    case "/create-skilllisting-skill":
      page = 3;
      break;
    case "/create-skilllisting-location":
      page = 4;
      break;
    case "/create-skilllisting-salary":
      page = 5;
      break;
    case "/create-skilllisting-date":
      page = 6;
      break;
  }

  return (
    <React.Fragment>
      <span className="Create-listing-heading">
        {page === 6 ? "Go Live!" : title}
      </span>
      <div className="progres-bar-create">
        <Progress
          size={[, 5]}
          percent={((page) / 6) * 100}
          showInfo={false}
          strokeColor="#118936"
        />
        <div className="back-btn-bt">

        </div>
      </div>
    </React.Fragment>
  );
};

export default ProgressBar;
