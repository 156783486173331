import React, { useEffect, useState } from "react";
import "./ConversationCard.css";
import { StarOutlined, StarFilled } from "@ant-design/icons";
import { Badge, Avatar, Tooltip } from "antd";
import moment from "moment";
import { getImage } from "../../../../shared/downloadAttachments";
import { useDispatch, useSelector } from "react-redux";
import {
  addMessageToFavourite,
  removeMessageFromFavourite,
  getNotifications,
} from "../../../../redux/actions";
import { useHistory } from "react-router-dom";
import skillgigs_log from '../../../../assets/img/Skillgigs_Msg_logo.png'
import PrivateTag from "../../../SearchGigs/components/privateTag";
import Private from "../../../../assets/icons/Private.svg"

export default function ConversationCard({
  records,
  setLoadChat,
  setshowHistory,
  setCreatedTime,
  selectedChat,
  SourceName,
  setSelectedChat,
  SourceId,
  Index,
  conversationType,
}) {
  const dispatch = useDispatch();
  const [isFavorite, setIsFavorite] = useState(false);
  let userProfile = useSelector((state) => state.profileReducer.user.id);
  let isFav = useSelector(
    (state) =>
      state?.getChatHistoryReducer?.inbox?.data?.data?.body?.conversations[
        Index
      ]?.IsFavorite
  );
  const [windowDimensions, setWindowDimensions] = useState(
    getWindowDimensions()
  );

  const history = useHistory();

  //STATES***
  const [imageError, setImageError] = useState(false);
  const [unRead, setunRead] = useState(false);

  useEffect(() => {
    setunRead(false);
  }, [records]);

  function getWindowDimensions() {
    const { innerWidth: width, innerHeight: height } = window;
    return {
      width,
      height,
    };
  }

  useEffect(() => {
    function handleResize() {
      setWindowDimensions(getWindowDimensions());
    }

    window.addEventListener("resize", handleResize);
    return () => window.removeEventListener("resize", handleResize);
  }, []);

  useEffect(() => {
    setIsFavorite(isFav);
  }, [isFav]);

  var cache = {},
    character,
    e = document.createElement("div");

  const msgtxt = (html) => {
    return html?.replace(/([&][^&; ]+[;])/g, function (entity) {
      character = cache[entity];
      if (!character) {
        e.innerHTML = entity;
        if (e.childNodes[0])
          character = cache[entity] = e.childNodes[0].nodeValue;
        else character = "";
      }
      return character;
    });
  };

  const handleFavourite = (e) => {
    e.stopPropagation();
    if (isFavorite) {
      dispatch(
        removeMessageFromFavourite({
          SourceId,
          SourceName,
          Index,
        })
      );
    } else {
      dispatch(
        addMessageToFavourite({
          SourceName,
          SourceId,
          Index,
        })
      );
    }

    setIsFavorite(!isFavorite);
  };

  function encodeURIComponentSafe(s) {
    if (!s) {
      return s;
    }
    return encodeURIComponent(s.replace(/%(?![0-9][0-9a-fA-F]+)/g, "%25"));
  }

  return (
    <div
      className={`conversation-card ${records?.IsTalentAssociation && "private_talent_assio_box"}`}
      onClick={() => {
        setCreatedTime(records?.Created);
        setunRead(true);
        if (setLoadChat) setLoadChat(true);
        setshowHistory(true);
        let recordData = { ...records, IsFavorite: isFav };
        const encodeID = encodeURIComponentSafe(records._id);
        if (windowDimensions.width > 768) {
          if (history.location.pathname.includes("messages")) {
            history.replace({
              pathname: `/messages/${encodeID}`,
              state: {
                openConvo: true,
                conversationId: records._id,
                records: recordData,
                SourceName: SourceName,
                SourceId: SourceId,
                Index: Index,
                createdTime: records?.Created,
                loadChat: true,
              },
            });
          } else {
            history.push({
              pathname: `/messages/${encodeID}`,
              state: {
                openConvo: true,
                conversationId: records._id,
                records: recordData,
                SourceName: SourceName,
                SourceId: SourceId,
                Index: Index,
                createdTime: records?.Created,
              },
            });
          }
        } else {
          history.push({
            pathname: `/messages/${encodeID}`,
            state: {
              openConvo: true,
              conversationId: records._id,
              records: recordData,
              SourceName: SourceName,
              SourceId: SourceId,
              Index: Index,
              loadChat: true,
              createdTime: records?.Created,
            },
          });
        }
      }}
      style={{ backgroundColor: selectedChat == records._id && "#e4ffec" }}
      key={records._id}
    >
      <div
        className="conversation-card-star"
        onClick={(e) => handleFavourite(e)}
      >
        {!isFavorite ? (
          <StarOutlined style={{ fontSize: "22px", color: "#aaaaaa" }} />
        ) : (
          <StarFilled style={{ fontSize: "22px", color: "#ffba08" }} />
        )}
      </div>
      <div className={`conversation-card-image ${conversationType === "Admin-Talent" ? "Admin-circle-border" : null}`}>
        {records?.SenderUserPictureUrl && !imageError || conversationType === "Admin-Talent" ? (
          <img
            onError={() => {
              setImageError(true);
            }}
            className={conversationType === "Admin-Talent" ? null : "Emp-profile-img"}
            src={conversationType !== "Admin-Talent" ? `${process.env.REACT_APP_S3_URL}${
              records?.SenderUserPictureUrl?.charAt(0) !== "/" ? "/" : ""
              }${records?.SenderUserPictureUrl}` : skillgigs_log }
          />
        ) : (
          <div className="image-container">
            <div className="image-text">
              {conversationType === "Admin-Talent"
                ? "A"
                : records?.SenderUserName?.split("")[0]?.toUpperCase()}
            </div>
          </div>
        )}
      </div>
      <div className="conversation-card-contentContainer">
        <Tooltip
          title={
            records?.SourceTypeId == 1
              ? records.SkillListing?.Title
              : records?.SourceTypeId == 2
              ? records.GigCard?.Title
              : ""
          }
        >
          {records?.Facility &&
          records?.Facility != null &&
          records?.Facility != undefined ? (
            <Tooltip title={records?.SenderUserName + " @" + records?.Facility}>
              <div
                style={{
                  // backgroundColor:'pink',
                  whiteSpace: "nowrap",
                  textOverflow: "ellipsis",
                  overflow: "hidden",
                  // width:'fit-content',
                  fontSize: "13px",
                  color: "black",
                  fontWeight: "500",
                  fontStretch: "normal",
                  // width:'13rem',
                  textTransform: "capitalize",
                }}
              >
                {records?.SenderUserName} @
                <span
                  style={{
                    fontSize: "11px",
                    color: "black",
                    textTransform: "capitalize",
                  }}
                >
                  {records?.Facility}
                </span>
              </div>
            </Tooltip>
          ) : (
            <Tooltip title={records?.SenderUserName}>
              <div
                style={{
                  // backgroundColor:'pink',
                  whiteSpace: "nowrap",
                  textOverflow: "ellipsis",
                  overflow: "hidden",
                  fontSize: "13px",
                  color: "black",
                  fontWeight: "500",
                  fontStretch: "normal",
                  // width:'13rem',
                  textTransform: "capitalize",
                }}
              >
                {conversationType === "Admin-Talent"
                    ? <div className="admin-tag-Block"><span>SkillGigs</span><span className="admin-tag-chat">ADMIN</span> </div>
                  : records?.SenderUserName}
              </div>
              <span className="PrivateTag-talent-pvt" >{records?.IsTalentAssociation && <img src={Private} alt="Private Tag"/>}</span>
            </Tooltip>
          )}
            <div
              style={{
                display: "flex",
                flexDirection: "row",
                justifyContent: "space-between",
              }}
            >
              <div className="conversation-card-contentContainer-title">
              {conversationType == "Admin-Talent" ? "Job recommendation" : records?.JobTitle}
              </div>
              <div className="conversation-card-extraContent-time">
                {moment(records?.Created).fromNow()}
              </div>
            </div>
        </Tooltip>
        <div className="conversation-card-contentContainer-message">
          <div
            dangerouslySetInnerHTML={{
              __html:
                (records?.SourceTypeId === 1 && records?.MessageText) ===
                "Bid is placed"
                  ? "Bid is Received"
                  : records?.MessageText === "Candidate is hired"
                  ? "You're hired"
                  : msgtxt(records?.MessageText),
            }}
            style={{ width: "80%" }}
          ></div>

          <div style={{ width: "20%", flexDirection: "row", display: "flex" }}>
            {records?.MessageText?.length >= 17 && "..."}
            {!unRead &&
              records?.countUnRead > 0 &&
              selectedChat !== records?._id && (
                <div className="conversation-card-extraContent-count">
                  {records?.countUnRead}
                </div>
              )}
          </div>
        </div>
      </div>
      <div className="conversation-card-extraContent"></div>
    </div>
  );
}
