import React, { useState, useEffect, useRef } from "react";
import "./interviewStatusCard.css";
import {
  fetchDeclinedReasonsBids,
  fetchDetailPlaceBid,
  InterviewScheduleAcceptedInit,
  postFavoriteGigCard,
  removeFavoriteGigCard,
  rescheduleFromChat,
  RetractBidUpdateStageInit,
  sendMessage,
  updateDeclinedReasonsReceivedBids,
} from "../../../../../../redux/actions";
import moment from "moment";
import { BsEmojiSmile } from "react-icons/bs";
import { HiOutlineEmojiSad } from "react-icons/hi";
import emailBoxIcon from "../../../../../../assets/img/emailBoxIcon.svg"; //../../../assets/img/emailBoxIcon.svg
import {
  Modal,
  Divider,
  Input,
  Rate,
  Badge,
  Radio,
  Space,
  Button,
  DatePicker,
  Tooltip,
  message,
} from "antd";
import MediaQuery from "react-responsive";
import phoneIcon from "../../../../../../assets/icons/Group 19971 (2).svg";
import { RiCheckDoubleFill, RiCheckDoubleLine } from "react-icons/ri";
import RejectedIcon from "../../../../../../assets/icons/Group 19971.png";
import acceptedIcon from "../../../../../../assets/icons/Group 19971.svg";
import CalendarIcon from "../../../../../../assets/icons/CalendarIcon.svg";
import { useSelector, useDispatch } from "react-redux";
import { LoadingOutlined } from "@ant-design/icons";
import SocialOptions from "./../../../../../Auth/components/SocialOptions";
import InterviewDrawer from "../../../../../../components/InterviewDrawer/interviewDrawer";

const InterviewStatusCards = (props) => {
  const chats = useSelector((state) => state.getChatHistoryReducer.chats);
  let userProfile = useSelector((state) => state.profileReducer.user.profile);
  const BidDetailData = useSelector(
    (state) => state.BidsReducer.detailReceivedBid
  );
  const declineReasons = useSelector(
    (state) => state.BidsReducer.declinedReasonsBid
  );
  const interviewLoading = useSelector(
    (state) => state.interviewReducer.loading
  );

  const dispatch = useDispatch();
  const [copySuccess, setCopySuccess] = useState("");
  const [emptyStage, setEmptyStage] = useState(false);
  const [emptyStage1, setEmptyStage1] = useState(false);
  const [emptyStage2, setEmptyStage2] = useState(false);
  const [interviewCard, setInterviewCard] = useState(null);
  const [isProposalAccept, setIsProposalAccept] = useState(false);
  const [clicked, setClicked] = useState();
  const [index, setIndex] = useState(-1);
  const [declineFlag, setDeclineFlag] = useState(false);
  const [isSelected, setIsSelected] = useState(false);
  const [declinedReason, setDeclinedReason] = useState();
  const [proposal, setProposal] = useState();
  const [bid, setBid] = useState();
  const [interviewTime, setInterviewTime] = useState(null);
  const [isModalVisible, setIsModalVisible] = useState(false);
  const [ModalVisible, setModalVisible] = useState(false);
  const [interview, setInterview] = useState();
  const [rescheduleFlag, setRescheduleFlag] = useState(false);
  const [interested, setInterested] = useState(false);
  const [isRetract, setIsRetract] = useState(false);
  const [decline, setDecline] = useState(false);
  const [reschedule, setReschedule] = useState(false);
  const [value, setValue] = useState(1);
  const messagesEndRef = useRef(null);
  const [isModalVisibleBidCalender, setModalVisibleBidCalender] =
    useState(false);
  const [DateValueStage, setDateValueStage] = useState();
  const [DateValueStage1, setDateValueStage1] = useState();
  const [DateValueStage2, setDateValueStage2] = useState();
  const [Endtime, setEndtime] = useState();
  const [Endtime1, setEndtime1] = useState();
  const [Endtime2, setEndtime2] = useState();
  const [error, setError] = useState(false);
  const bidStatus = useSelector(
    (state) => state.getChatHistoryReducer.bidStatus
  );
  const [rescheduleDisable, setRescheduleDisable] = useState(
    props.status === "Interview rescheduled" ? true : false
  );
  const [firstDate, setfirstDate] = useState(false);
  const [secondDate, setsecondDate] = useState(false);
  const [thirdDate, setthirdDate] = useState(false);
  const [DeclineInterviewLoading, setDeclineInterviewLoading] = useState(false);
  const [RescheduleInterviewLoading, setRescheduleInterviewLoading] =
    useState(false);

  // const [index, setIndex] = useState(-1);

  useEffect(() => {
    if (decline === "decline-interview" && declineReasons?.length > 0) {
      showModal();
    }
    if (decline === "decline-bid" && declineReasons?.length > 0) {
      showModal();
    }
  }, [declineReasons]);
  useEffect(() => {
    setProposal(chats?.data?.body?.placedBidCard?.proposal);
    setBid(chats?.data?.body[0]?.BidCard);
    setInterview(props?.InterviewCard);
  }, [chats]);

  useEffect(() => {
    dispatch(fetchDeclinedReasonsBids("interview"));
  }, []);

  useEffect(() => {
    if (!interviewLoading) {
      setDeclineInterviewLoading(false);
      setRescheduleInterviewLoading(false);
    }
  }, [interviewLoading]);

  const handleReasonSubmit = () => {
    if (declinedReason) {
      setDeclineInterviewLoading(true);
      dispatch(
        InterviewScheduleAcceptedInit({
          // id: "jasdkflj64657987987",
          id: interview?.InterviewId,
          reschedule: true,
          body: {
            InterviewStatus: "Rejected",
            ReasonId: declinedReason,
          },
        })
      );
      handleOk();
    }
  };

  const handleOk = () => {
    setModalVisible(false);
  };

  const declineReasonHandler = (e) => {
    setIsSelected(!isSelected);
    setDeclinedReason(e.ReasonId);
  };

  const InterviewDeclineByTalentReason = {
    1: "Accepted another offer",
    3: "Not interested in this Gig",
    4: "I would like to reschedule",
  };
  const employerInterviewDeclinceReason = {
    1: "Accepted another candidate",
    2: "User Deactivated Account",
    3: "Not Interested",
    4: "Asking payrate is too High"
  }

  const handleOkRetract = () => {
    setIsModalVisible(false);
    setInterested(true);
    setIsRetract(true);
    dispatch(RetractBidUpdateStageInit(proposal?._id));
  };

  const handleCancel = () => {
    setIsModalVisible(false);
    setModalVisible(false);
  };

  const SimpleCongratulations = () => {
    return (
      <>
        <div className="chat-container1 employer-sender" ref={messagesEndRef}>
          <div className="sender-threat"></div>
          <div className="user-threat">
            <MediaQuery maxWidth={768}>
              <div className="Accepted_Icon">
                <img src={acceptedIcon} alt="" />
              </div>
              <div className="Accepted">You're Hired!</div>
            </MediaQuery>
            {/* <div className="congratulation">Congratulations!</div> */}
            <div className="accept">You got hired.</div>
            <div className="time-stamp">
              <div className="time-text-employer">1:48 PM</div>
              {/* <div className="tick-icon">
                <RiCheckDoubleFill />
              </div> */}
            </div>
          </div>
        </div>
      </>
    );
  };

  const disableHandler = () => {
    let array = bidStatus;
    let filteredArray = array.filter(
      (value) => value !== null && value !== undefined
    );
    // let nullCount = array.length - filteredArray.length;
    // 
    if (
      bidStatus.includes("Accepted Interview") ||
      bidStatus.includes("Interview Cancelled")
    ) {
      return true;
    }
    // 
    //prop.index is the inverted index of the current msg and bidStatus.length is the total length of the messages
    else if (
      filteredArray.length - props.index == filteredArray.length - 1 &&
      (filteredArray[filteredArray.length - props.index] ==
        "Interview rescheduled" ||
        filteredArray[filteredArray.length - props.index] ==
          "Proposed Interview")
    ) {
      return false;
    } else {
      return true;
    }
  };

  const onchangeRadio = (e) => {
    setValue(e.target.value);
  };

  const handleReschedule = () => {
    setReschedule(true);
    showModal();
  };

  const showModal = () => {
    setIsModalVisible(true);
  };

  const handleDecline = async () => {
    setDecline("decline-interview");
    showModal();
    // const data = await declineReasons;
    // if (decline === "decline-interview" && declineReasons?.length > 0 && data) {
    //   showModal();
    // }
  };
  const ProposedInterviewHandle = () => {
    // setIsProposalAccept(true);
    setRescheduleDisable(false);
    if (index != -1) {
      if (props.status === "Interview rescheduled") {
        dispatch(
          InterviewScheduleAcceptedInit({
            id: interview?.InterviewId,
            ApprovedScheduledTiming: {
              StartTime: interview?.ReScheduledTimings[
                interview?.ReScheduledTimings?.length - 1
              ][index]?.StartTime
                ? moment.utc(
                    interview?.ReScheduledTimings[
                      interview?.ReScheduledTimings?.length - 1
                    ][index].StartTime
                  )
                : moment.utc(interview?.StartTime),
              EndTime: interview?.ReScheduledTimings[
                interview?.ReScheduledTimings?.length - 1
              ][index].EndTime
                ? moment.utc(
                    interview?.ReScheduledTimings[
                      interview?.ReScheduledTimings?.length - 1
                    ][index].EndTime
                  )
                : moment.utc(interview?.EndTime),
            },
            // ],
            InterviewStatus: "Accepted",
            // Message: "You have accepted the interview",
          })
        );
      } else {
        dispatch(
          InterviewScheduleAcceptedInit({
            id: interview?.InterviewId,
            ApprovedScheduledTiming: {
              StartTime: interview?.Interviews_ScheduledTimings[index]
                ?.StartTime
                ? moment.utc(
                    interview?.Interviews_ScheduledTimings[index]?.StartTime
                  )
                : moment.utc(interview?.StartTime),
              EndTime: interview?.Interviews_ScheduledTimings[index]?.EndTime
                ? moment.utc(
                    interview?.Interviews_ScheduledTimings[index]?.EndTime
                  )
                : moment.utc(interview?.EndTime),
            },
            // ],
            InterviewStatus: "Accepted",
            // Message: "You have accepted the interview",
          })
        );
      }
      setIndex(-1);
    } else {
      // 
      setError(true);
      setTimeout(() => {
        setError(false);
      }, 3000);
    }
  };

  const getProposedInterviewDateAndTime = (time) => {
    const timedate = moment(time).format("dddd , MMMM DD, YYYY, h:mm A");
    const tdiff = moment(time.setHours(time.getHours() + 1)).format("LT");
    let TimeArr = timedate.split(", ");
    TimeArr.push(tdiff);
    return TimeArr;
  };

  const onchangeRadio1 = (e, key, time) => {
    if (key === 0) {
      setValue(0);
    } else if (key === 1) {
      setValue(1);
    } else {
      setValue(2);
    }
    setInterviewTime(time);
  };

  const modalCancelCalender = () => {
    setModalVisibleBidCalender(false);
  };

  const dateHandlerStages = (e) => {
    var ctime = new Date(e._d);
    const date = moment(ctime.toISOString());
    date.add(1, "h");
    const options = {
      weekday: "long",
      year: "numeric",
      month: "short",
      day: "numeric",
      hour: "numeric",
      hour12: true,
    };
    setDateValueStage(ctime.toISOString());
    setEndtime(date.toISOString());
    setEmptyStage(false);
  };

  const dateHandlerStages1 = (e) => {
    var ctime = new Date(e._d);
    const date = moment(ctime.toISOString());
    date.add(1, "h");
    const options = {
      weekday: "long",
      year: "numeric",
      month: "short",
      day: "numeric",
      hour: "numeric",
      hour12: true,
    };
    setDateValueStage1(ctime.toISOString());
    setEndtime1(date.toISOString());
    setEmptyStage1(false);
  };

  const dateHandlerStages2 = (e) => {
    var ctime = new Date(e._d);
    const date = moment(ctime.toISOString());
    date.add(1, "h");
    const options = {
      weekday: "long",
      year: "numeric",
      month: "short",
      day: "numeric",
      hour: "numeric",
      hour12: true,
    };
    setDateValueStage2(ctime.toISOString());
    setEndtime2(date.toISOString());
    setEmptyStage2(false);
  };

  // const RescheduleInterviewPlacedBid = () => {
  //   dispatch(InterviewScheduleAcceptedInit({
  //     id: BidDetailData?.interview?._id,
  //     body: {
  //       "ReScheduledTimings": [{
  //         "StartTime": DateValueStage,
  //         "EndTime": Endtime,

  //       },
  //       {
  //         "StartTime": DateValueStage1,
  //         "EndTime": Endtime1,
  //       },
  //       {
  //         "StartTime": DateValueStage2,
  //         "EndTime": Endtime2,
  //       },
  //       ],
  //       "InterviewStatus": "Rescheduled",
  //       "Message": "You have Rescheduledthe interview"
  //     }
  //   })
  //   )
  //   setTimeout(() => {
  //     setModalVisibleBidCalender(false)
  //     SendRequestDetailPlacedBid()
  //   }, 2000)
  // }

  const rescheduleInterview = (firstDateTimings, secondDateTimings, thirdDateTimings) => {
    setRescheduleInterviewLoading(true);
    dispatch(
      InterviewScheduleAcceptedInit({
        id: props.InterviewCard?.InterviewId,
        reschedule: true,
        body: {
          ReScheduledTimings: [
            {
              StartTime: firstDateTimings?.StartTime
                ? moment.utc(firstDateTimings?.StartTime)
                : props.status === "Interview rescheduled"
                ? moment(
                    props?.InterviewCard?.ReScheduledTimings[
                      props?.InterviewCard?.ReScheduledTimings?.length - 1
                    ][0]?.StartTime
                  )
                : props.status === "Proposed Interview" &&
                  moment(
                    props?.InterviewCard?.Interviews_ScheduledTimings[0]
                      ?.StartTime
                  ),
              EndTime: firstDateTimings?.EndTime
                ? moment.utc(Endtime)
                : props.status === "Interview rescheduled"
                ? moment(
                    props?.InterviewCard?.ReScheduledTimings[
                      props?.InterviewCard?.ReScheduledTimings?.length - 1
                    ][0]?.EndTime
                  )
                : props.status === "Proposed Interview" &&
                  moment(
                    props?.InterviewCard?.Interviews_ScheduledTimings[0]
                      ?.EndTime
                  ),
            },
            {
              StartTime: secondDateTimings?.StartTime
                ? moment.utc(secondDateTimings?.StartTime)
                : props.status === "Interview rescheduled"
                ? moment(
                    props?.InterviewCard?.ReScheduledTimings[
                      props?.InterviewCard?.ReScheduledTimings?.length - 1
                    ][1]?.StartTime
                  )
                : props.status === "Proposed Interview" &&
                  moment(
                    props?.InterviewCard?.Interviews_ScheduledTimings[1]
                      ?.StartTime
                  ),
              EndTime: secondDateTimings?.EndTime
                ? moment.utc(secondDateTimings?.EndTime)
                : props.status === "Interview rescheduled"
                ? moment(
                    props?.InterviewCard?.ReScheduledTimings[
                      props?.InterviewCard?.ReScheduledTimings?.length - 1
                    ][1]?.EndTime
                  )
                : props.status === "Proposed Interview" &&
                  moment(
                    props?.InterviewCard?.Interviews_ScheduledTimings[1]
                      ?.EndTime
                  ),
            },
            {
              StartTime: thirdDateTimings?.StartTime
                ? moment.utc(thirdDateTimings?.StartTime)
                : props.status === "Interview rescheduled"
                ? moment(
                    props?.InterviewCard?.ReScheduledTimings[
                      props?.InterviewCard?.ReScheduledTimings?.length - 1
                    ][2]?.StartTime
                  )
                : props.status === "Proposed Interview" &&
                  moment(
                    props?.InterviewCard?.Interviews_ScheduledTimings[2]
                      ?.StartTime
                  ),
              EndTime: thirdDateTimings?.EndTime
                ? moment.utc(thirdDateTimings?.EndTime)
                : props?.status === "Interview rescheduled"
                ? moment(
                    props?.InterviewCard?.ReScheduledTimings[
                      props?.InterviewCard?.ReScheduledTimings?.length - 1
                    ][2]?.EndTime
                  )
                : props?.status === "Proposed Interview" &&
                  moment(
                    props?.InterviewCard?.Interviews_ScheduledTimings[2]
                      ?.EndTime
                  ),
            },
          ],
          InterviewStatus: "Rescheduled",
          Message: "You have rescheduled the interview",
        },
      })
    );
    handleOk();
  };

  const copyToClipBoard = async (copyMe) => {
    try {
      setCopySuccess("Copied!");
      await navigator.clipboard.writeText(copyMe);
    } catch (error) {
      setCopySuccess("Failed to copy!");
    }
  };
  const getDate = (t, ts) => {
    if (t == null) {
      setEmptyStage(true);
    } else {
      setDateValueStage(new Date(t._d));
      // setInterviewStartTime("");
      // setInterviewEndTime("");
    }
  };

  const getSecondDate = (t, ts) => {
    if (t == null) {
      setEmptyStage1(true);
    } else {
      setsecondDate(new Date(t._d));
    }
  };

  const getThirdDate = (t, ts) => {
    if (t == null) {
      setEmptyStage2(true);
    } else {
      setthirdDate(new Date(t._d));
    }
  };

  

  return (
    <>
      {/*<Modal
        bodyStyle={{ padding: 0 }}
        width={window.innerWidth < 768 ? "20.625rem" : "22.5rem"}
        title={
          "Reschedule Interview"
          // reschedule ? "Reschedule Interview" : "Not Interested in this Bid?"
        }
        className="message-details-model"
        visible={ModalVisible}
        onOk={handleOk}
        onCancel={handleCancel}
        footer={null}
        centered={true}
        closable={clicked === "retract-bid" ? false : true}
      >
        <div className="Bid-decline-model">
          <>
            <div className="bid-decline-headline reshedule_interview_title">
              Let's find a better time to set up your interview.
            </div>
            <div className="bid-decline-headline bid-decline-headline-rechedule bid-rechedule-text reshedule_interview_content">
              <span style={{ fontWeight: "500" }}>Interview timings</span>
              (Propose up to 3 of your available interview times).
            </div>
            <div className="bid-decline-options">
              <DatePicker
                placeholder="Choose a date and time"
                format={`dddd , MMMM DD, YYYY HH:mm a`}
                suffixIcon={<img src={CalendarIcon} alt="Calender" />}
                className="bid-reschedule-date-picker"
                disabledDate={(current) => {
                  return moment().add(0, "days") >= current;
                }}
                value={
                  !emptyStage
                    ? DateValueStage
                      ? moment(DateValueStage)
                      : props.status === "Interview rescheduled"
                      ? moment(
                          props?.InterviewCard?.ReScheduledTimings &&
                            props?.InterviewCard?.ReScheduledTimings?.length &&
                            props?.InterviewCard?.ReScheduledTimings[
                              props?.InterviewCard?.ReScheduledTimings?.length -
                                1
                            ][0]?.StartTime
                        )
                      : props.status === "Proposed Interview"
                      ? moment(
                          props?.InterviewCard?.Interviews_ScheduledTimings[0]
                            ?.StartTime
                        )
                      : ""
                    : false
                }
                showTime={{
                  defaultValue: moment("00:00:00", "HH:mm a"),
                }}
                onChange={(time, timeString) => getDate(time, timeString)}
                onOk={(e) => dateHandlerStages(e)}
                inputReadOnly={true}
              />
            </div>
            <div className="bid-decline-options">
              <DatePicker
                placeholder="Choose a date and time"
                format={`dddd , MMMM DD, YYYY HH:mm a`}
                suffixIcon={<img src={CalendarIcon} alt="Calender" />}
                className="bid-reschedule-date-picker"
                showTime={{
                  defaultValue: moment("00:00:00", "HH:mm"),
                }}
                disabledDate={(current) => {
                  return moment().add(0, "days") >= current;
                }}
                onChange={(time, timeString) => getSecondDate(time, timeString)}
                onOk={(e) => dateHandlerStages1(e)}
                inputReadOnly={true}
                value={
                  !emptyStage1
                    ? DateValueStage1
                      ? moment(DateValueStage1)
                      : props.status === "Interview rescheduled"
                      ? moment(
                          props?.InterviewCard?.ReScheduledTimings &&
                            props?.InterviewCard?.ReScheduledTimings?.length &&
                            props?.InterviewCard?.ReScheduledTimings[
                              props?.InterviewCard?.ReScheduledTimings?.length -
                                1
                            ][1]?.StartTime
                        )
                      : props.status === "Proposed Interview"
                      ? moment(
                          props?.InterviewCard?.Interviews_ScheduledTimings[1]
                            ?.StartTime
                        )
                      : ""
                    : false
                }
              />
            </div>
            <div className="bid-decline-options">
              <DatePicker
                placeholder="Choose a date and time"
                format={`dddd , MMMM DD, YYYY HH:mm a`}
                suffixIcon={<img src={CalendarIcon} alt="Calender" />}
                className="bid-reschedule-date-picker"
                disabledDate={(current) => {
                  return moment().add(0, "days") >= current;
                }}
                showTime={{
                  defaultValue: moment("00:00:00", "HH:mm"),
                }}
                onChange={(time, timeString) => getThirdDate(time, timeString)}
                onOk={(e) => dateHandlerStages2(e)}
                inputReadOnly={true}
                value={
                  !emptyStage2
                    ? DateValueStage2
                      ? moment(DateValueStage2)
                      : props.status === "Interview rescheduled"
                      ? moment(
                          props?.InterviewCard?.ReScheduledTimings &&
                            props?.InterviewCard?.ReScheduledTimings?.length &&
                            props?.InterviewCard?.ReScheduledTimings[
                              props?.InterviewCard?.ReScheduledTimings?.length -
                                1
                            ][2]?.StartTime
                        )
                      : props.status === "Proposed Interview"
                      ? moment(
                          props?.InterviewCard?.Interviews_ScheduledTimings[2]
                            ?.StartTime
                        )
                      : ""
                    : false
                }
              />
            </div>
            <div
              className="bid-decline-button bid-rechedule-button"
              onClick={() => rescheduleInterview()}
            >
              Send reschedule request
            </div>
          </>
        </div>
      </Modal> */}

      <InterviewDrawer
        reSchedule={true}
        interviewId={props?.InterviewCard?.InterviewId}
        timings={
          props.status === "Proposed Interview" ?
          props?.InterviewCard?.Interviews_ScheduledTimings :
          props.status === "Interview rescheduled" ?
              props?.InterviewCard?.ReScheduledTimings[props?.InterviewCard?.ReScheduledTimings?.length - 1] : ""
        }
        open={ModalVisible}
        close={setModalVisible}
        rescheduleInterview={rescheduleInterview}
      />
      <div>
        {props?.status === "Accepted Interview" && (
          <>
            <div
              className="chat-profile-submit mt15px user-sender"
              ref={messagesEndRef}
            >
              <div className="interview-accepted-card">
                <MediaQuery maxWidth={768}>
                  <div className="Clip_Board_Icon">
                    <img src={phoneIcon} alt="" />
                  </div>
                  <span className="Profile_Submitted">Interview accepted</span>
                </MediaQuery>
                {/* <div className="congratulation">Congratulations!</div> */}
                <div className="accept">
                  {userProfile?.AccountId == props?.items?.SenderUser?.AccountId
                    ? "Congrats! You’ve accepted an interview time for:"
                    : "The employer has accepted an interview time for:"}
                </div>
                <div className="interview-accepted-date">
                  <p className="dateText">
                    {moment(
                      props.InterviewCard?.ApprovedScheduledTiming?.StartTime
                    )
                      .local()
                      .format("dddd , MMMM DD, YYYY")}
                  </p>
                  <p className="timeText">
                    {moment(
                      props.InterviewCard?.ApprovedScheduledTiming?.StartTime
                    )
                      .local()
                      .format("h:mm A")}{" "}
                    -{" "}
                    {moment(
                      props.InterviewCard?.ApprovedScheduledTiming?.EndTime
                    )
                      .local()
                      .format("h:mm A")}
                  </p>
                </div>
                <div>
                  The employer will share the interview details shortly.
                </div>
                <div className="offer-time-stamp">
                  <div className="time-text">
                    {moment(props.items.Created).format("hh:mm A")}
                  </div>
                  <div className="tick-icon">
                    <RiCheckDoubleFill />
                  </div>
                </div>
              </div>
            </div>
          </>
        )}
        {props?.status == "Scheduled Interview" ? ( // || props.status == "Interview rescheduled"
          <div className="interview-scheduled-on-container">
            <div>
              <strong>Congratulations!</strong> <br />
              Your interview scheduled on
            </div>

            <div
              style={{
                backgroundColor: "#fff",
                padding: "3% 4%",
                marginTop: "3%",
                borderRadius: "4px",
              }}
            >
              <strong>
                {moment(props.InterviewCard?.ApprovedScheduledTiming?.StartTime)
                  .local()
                  .format("dddd , MMMM DD, YYYY")}
              </strong>{" "}
              <br />
              {moment(props.InterviewCard?.ApprovedScheduledTiming?.StartTime)
                .local()
                .format("h:mm A")}{" "}
              -{" "}
              {moment(props.InterviewCard?.ApprovedScheduledTiming?.EndTime)
                .local()
                .format("h:mm A")}
            </div>

            <div
              style={{
                backgroundColor: "#fff",
                padding: "3% 4%",
                marginTop: "3%",
                borderRadius: "4px",
                display: "flex",
                justifyContent: "space-between",
              }}
            >
              {props.InterviewCard?.OnSite == true ? (
                props.InterviewCard?.Location
              ) : (
                <a
                  style={{
                    textOverflow: "ellipsis",
                    whiteSpace: "nowrap",
                    width: "200px",
                    overflow: "hidden",
                  }}
                  // href= {props.InterviewCard?.MeetingURL}
                  onClick={() => {
                    const regex = /^https?:\/\/[\w\-]+(\.[\w\-]+)+[/#?]?.*$/;
                    if (regex.test(props.InterviewCard?.MeetingURL)) {
                      window.open(
                        props.InterviewCard?.MeetingURL,
                        "_blank",
                        "noreferrer"
                      );
                    } else {
                      message.warning("Link not Valid !");
                    }
                  }}
                >
                  {/* href={props.InterviewCard?.MeetingURL} */}
                  {props.InterviewCard?.MeetingURL}
                </a>
              )}
              {!props.InterviewCard?.OnSite && (
                <Tooltip
                  title={copySuccess}
                  trigger={`click`}
                  color={"#2db7f5"}
                >
                  <img
                    className="InterviewsCard-model-email-icon"
                    src={emailBoxIcon}
                    alt="emailBoxIcon"
                    onClick={() =>
                      copyToClipBoard(props.InterviewCard?.MeetingURL)
                    }
                  />
                </Tooltip>
              )}
            </div>

            <div
              style={{
                display: "flex",
                justifyContent: "center",
                marginTop: "3.5%",
              }}
            >
              {/* <button className="bidAcceptedBtn">Add to my calendar</button> */}
            </div>
            <div className="offer-time-stamp">
              <div className="time-text">
                {moment(props.items.Created).format("hh:mm A")}
              </div>
              <div className="tick-icon">
                <RiCheckDoubleFill />
              </div>
            </div>
          </div>
        ) : null}
        {props?.status === "Interview Declined" ||
          (props?.status === "Interview Cancelled" && (
            <>
              <div
                className="chat-profile-submit mt15px interview-declined-card"
                ref={messagesEndRef}
              >
                <div className="user-threat retract-bid-card">
                  <MediaQuery maxWidth={768}>
                    <div className="Accepted_Icon">
                      <img src={RejectedIcon} alt="" />
                    </div>
                    <div className="Accepted Terminated">
                      Interview declined
                    </div>
                  </MediaQuery>
                  {props?.status === "Interview Declined" ? (
                    <div className="congratulation">
                      Interview declined {window.innerWidth > 768 && "!"}
                    </div>
                  ) : (
                    <div className="accept">
                      {userProfile?.AccountId ==
                      props?.items?.SenderUser?.AccountId
                        ? "You declined to move forward with an interview."
                        : "Interview canceled by the Employer"}
                    </div>
                  )}
                  <div className="interview-accepted-date">
                    <div className="date">Reason</div>
                    <div className="time-reason">
                      {userProfile?.AccountId ==
                      props?.items?.SenderUser?.AccountId
                        ? props?.items?.InterviewDeclineReason?.Reason
                        : props?.items?.InterviewCard?.DeclineReason}
                    </div>
                  </div>
                  {/* <div className="time-stamp">
                    <div className="time-text">1:48 pm</div>
                    <div className="tick-icon">
                      <RiCheckDoubleFill />
                    </div>
                  </div> */}
                </div>
              </div>
            </>
          ))}
      </div>
      {(props.status === "Interview Accepted" ||
        (props.items?.Rank >= 7 &&
          !(props.items?.BidStatusName === "Interview Declined"))) &&
        interviewCard && (
          <>
            <div
              className="chat-profile-submit mt15px user-sender"
              ref={messagesEndRef}
            >
              <div className="user-threat">
                <MediaQuery maxWidth={768}>
                  <div className="Clip_Board_Icon">
                    <img src={phoneIcon} alt="" />
                  </div>
                  <div className="Profile_Submitted">Interview accepted</div>
                </MediaQuery>
                {/* <div className="congratulation">Congratulations!</div> */}
                <div className="accept">
                  {userProfile?.AccountId == props?.items?.SenderUser?.AccountId
                    ? "Congrats! You’ve accepted an interview time for:"
                    : "The employer has accepted an interview time for:"}
                </div>
                <div className="interview-accepted-date">
                  <div className="date">Thursday, 3rd January, 2022</div>
                  <div className="time">9:00 AM - 10:00 AM</div>
                </div>
                <div className="accept">
                  The employer will share the interview details shortly.
                </div>
                <div className="time-stamp">
                  <div className="time-text">1:48 PM</div>
                  <div className="tick-icon">
                    <RiCheckDoubleFill />
                  </div>
                </div>
              </div>
            </div>
          </>
        )}
      {(props?.status == "Proposed Interview" ||
        props?.status == "Interview rescheduled") && ( // need to add this card cannot find one
        <div className="card">
          <div className="interview-proposed-card-content">
            <div className="cardHeading">
              {props.status == "Proposed Interview"
                ? "Proposed Interview times"
                : "Reschedule Interview times"}{" "}
            </div>
            <div className="cardTimings">
              <div className="radioBtnContainer" onClick={() => setIndex(0)}>
                <input
                  style={{ cursor: "pointer" }}
                  type="radio"
                  name="size"
                  value="S"
                  id="s1"
                />
              </div>
              <div className="dateAndTimingsContainer">
                {props?.items?.ReScheduledTimings != null ? (
                  <>
                    <div className="dateText">
                      {moment(
                        props?.InterviewCard?.ReScheduledTimings?.length &&
                          props?.InterviewCard?.ReScheduledTimings[
                            props?.items?.ReScheduledTimings
                          ][0]?.StartTime
                      )
                        .format("dddd , MMMM DD, YYYY")
                        .toString()}
                    </div>

                    <div className="timeText">
                      {moment(
                        props?.InterviewCard?.ReScheduledTimings?.length &&
                          props?.InterviewCard?.ReScheduledTimings[
                            props?.items?.ReScheduledTimings
                          ][0]?.StartTime
                      ).format("h:mm A")}{" "}
                      -{" "}
                      {moment(
                        props?.InterviewCard?.ReScheduledTimings?.length &&
                          props?.InterviewCard?.ReScheduledTimings[
                            props?.items?.ReScheduledTimings
                          ][0]?.EndTime
                      ).format("h:mm A")}
                    </div>
                  </>
                ) : (
                  <>
                    <div className="dateText">
                      {props?.items?.Interviews_ScheduledTimings?.length > 0
                        ? moment(
                            props?.items?.Interviews_ScheduledTimings[0]
                              ?.StartTime
                          )
                            .format("dddd , MMMM DD, YYYY")
                            .toString()
                        : moment(
                            props?.InterviewCard?.Interviews_ScheduledTimings[0]
                              ?.StartTime
                          )
                            .format("dddd , MMMM DD, YYYY")
                            .toString()}
                    </div>
                    <div className="timeText">
                      {props?.items?.Interviews_ScheduledTimings?.length > 0
                        ? moment(
                            props?.items?.Interviews_ScheduledTimings[0]
                              ?.StartTime
                          )
                            .local()
                            .format("h:mm A") +
                          " - " +
                          moment(
                            props?.items?.Interviews_ScheduledTimings[0]
                              ?.EndTime
                          )
                            .local()
                            .format("h:mm A")
                        : moment(
                            props?.InterviewCard?.Interviews_ScheduledTimings[0]
                              ?.StartTime
                          )
                            .local()
                            .format("h:mm A") +
                          " - " +
                          moment(
                            props?.InterviewCard?.Interviews_ScheduledTimings[0]
                              ?.EndTime
                          )
                            .local()
                            .format("h:mm A")}
                    </div>
                  </>
                )}
              </div>
            </div>
            <div className="cardTimings">
              <div className="radioBtnContainer" onClick={() => setIndex(1)}>
                <input
                  style={{ cursor: "pointer" }}
                  type="radio"
                  name="size"
                  value="S"
                  id="s2"
                />
              </div>
              <div className="dateAndTimingsContainer">
                {props?.items?.ReScheduledTimings != null ? (
                  <>
                    <div className="dateText">
                      {moment(
                        props?.InterviewCard?.ReScheduledTimings?.length &&
                          props?.InterviewCard?.ReScheduledTimings[
                            props?.items?.ReScheduledTimings
                          ][1]?.StartTime
                      )
                        .local()
                        .format("dddd , MMMM DD, YYYY")
                        .toString()}
                    </div>

                    <div className="timeText">
                      {moment(
                        props?.InterviewCard?.ReScheduledTimings?.length &&
                          props?.InterviewCard?.ReScheduledTimings[
                            props?.items?.ReScheduledTimings
                          ][1]?.StartTime
                      )
                        .local()
                        .format("h:mm A")}{" "}
                      -{" "}
                      {moment(
                        props?.InterviewCard?.ReScheduledTimings?.length &&
                          props?.InterviewCard?.ReScheduledTimings[
                            props?.items?.ReScheduledTimings
                          ][1]?.EndTime
                      )
                        .local()
                        .format("h:mm A")}
                    </div>
                  </>
                ) : (
                  <>
                    <div className="dateText">
                      {props?.items?.Interviews_ScheduledTimings?.length > 0
                        ? moment(
                            props?.items?.Interviews_ScheduledTimings[1]
                              ?.StartTime
                          )
                            .local()
                            .format("dddd , MMMM DD, YYYY")
                        : moment(
                            props?.InterviewCard?.Interviews_ScheduledTimings[1]
                              ?.StartTime
                          )
                            .local()
                            .format("dddd , MMMM DD, YYYY")}
                    </div>
                    <div className="timeText">
                      {props?.items?.Interviews_ScheduledTimings?.length > 0
                        ? moment(
                            props?.items?.Interviews_ScheduledTimings[1]
                              ?.StartTime
                          )
                            .local()
                            .format("h:mm A") +
                          " - " +
                          moment(
                            props?.items?.Interviews_ScheduledTimings[1]
                              ?.EndTime
                          )
                            .local()
                            .format("h:mm A")
                        : moment(
                            props?.InterviewCard?.Interviews_ScheduledTimings[1]
                              ?.StartTime
                          )
                            .local()
                            .format("h:mm A") +
                          " - " +
                          moment(
                            props?.InterviewCard?.Interviews_ScheduledTimings[1]
                              ?.EndTime
                          )
                            .local()
                            .format("h:mm A")}
                    </div>
                  </>
                )}
              </div>
            </div>
            <div className="cardTimings">
              <div className="radioBtnContainer" onClick={() => setIndex(2)}>
                <input
                  style={{ cursor: "pointer" }}
                  type="radio"
                  name="size"
                  value="S"
                  id="s3"
                />
              </div>
              <div className="dateAndTimingsContainer">
                {props?.items?.ReScheduledTimings != null ? (
                  <>
                    <div className="dateText">
                      {moment(
                        props?.InterviewCard?.ReScheduledTimings?.length &&
                          props?.InterviewCard?.ReScheduledTimings[
                            props?.items?.ReScheduledTimings
                          ][2]?.StartTime
                      )
                        .format("dddd , MMMM DD, YYYY")
                        .toString()}
                    </div>

                    <div className="timeText">
                      {moment(
                        props?.InterviewCard?.ReScheduledTimings?.length &&
                          props?.InterviewCard?.ReScheduledTimings[
                            props?.items?.ReScheduledTimings
                          ][2]?.StartTime
                      )
                        .local()
                        .format("h:mm A")}{" "}
                      -{" "}
                      {moment(
                        props?.InterviewCard?.ReScheduledTimings?.length &&
                          props?.InterviewCard?.ReScheduledTimings[
                            props?.items?.ReScheduledTimings
                          ][2]?.EndTime
                      )
                        .local()
                        .format("h:mm A")}
                    </div>
                  </>
                ) : (
                  <>
                    <div className="dateText">
                      {props?.items?.Interviews_ScheduledTimings?.length > 0
                        ? moment(
                            props?.items?.Interviews_ScheduledTimings[2]
                              ?.StartTime
                          )
                            .local()
                            .format("dddd , MMMM DD, YYYY")
                        : moment(
                            props?.InterviewCard?.Interviews_ScheduledTimings[2]
                              ?.StartTime
                          )
                            .local()
                            .format("dddd , MMMM DD, YYYY")}
                    </div>
                    <div className="timeText">
                      {props?.items?.Interviews_ScheduledTimings?.length > 0
                        ? moment(
                            props?.items?.Interviews_ScheduledTimings[2]
                              ?.StartTime
                          )
                            .local()
                            .format("h:mm A") +
                          " - " +
                          moment(
                            props?.items?.Interviews_ScheduledTimings[2]
                              ?.EndTime
                          )
                            .local()
                            .format("h:mm A")
                        : moment(
                            props?.InterviewCard?.Interviews_ScheduledTimings[2]
                              ?.StartTime
                          )
                            .local()
                            .format("h:mm A") +
                          " - " +
                          moment(
                            props?.InterviewCard?.Interviews_ScheduledTimings[2]
                              ?.EndTime
                          )
                            .local()
                            .format("h:mm A")}
                    </div>
                  </>
                )}
              </div>
            </div>
            {error && (
              <p
                style={{
                  fontSize: "16px",
                  fontWeight: "500",
                  color: "red",
                  marginBottom: "0px",
                  textAlign: "center",
                }}
              >
                Please select a date
              </p>
            )}
            <div
              style={{
                display: "flex",
                flexDirection: "row-reverse",
                marginTop: "3%",
                color: "#b4b4b4",
                fontSize: "14px",
                marginRight: "1%",
              }}
            >
              {userProfile?.AccountId ===
              props?.items?.SenderUser?.AccountId ? (
                <RiCheckDoubleLine className="double-check-icon" />
              ) : null}
              {moment(props.items?.Created).format("hh:mm A")}
            </div>
          </div>
          <div className="interview-proposed-buttons">
            <div
              className={
                props.type === "reschedule"
                  ? "proposed-buttons-rescheduled"
                  : "proposed-buttons-div"
              }
              // style={{ display: "flex", justifyContent: "flex-end" }}
            >
              {userProfile?.AccountId !=
                props?.items?.SenderUser?.AccountId && (
                <div className="proposed-buttons">
                  {props.type === "reschedule" ? null : (
                    <>
                      <div className="accet-and-decline">
                        <div
                          className={`decline ${
                            (props.items?.Rank > 6 ||
                              isProposalAccept ||
                              declineFlag ||
                              rescheduleFlag) &&
                            " interested-decline"
                          }`}
                          onClick={() => {
                            handleDecline();
                            setRescheduleDisable(true);
                            // showModal();
                            setReschedule(false);
                            // if (
                            //   !(
                            //     props.items?.Rank > 6 ||
                            //     isProposalAccept ||
                            //     declineFlag ||
                            //     rescheduleFlag
                            //   )
                            // ) {
                            //   // dispatch(fetchDeclinedReasonsBids("interview"));
                            //   // setDecline("decline-interview");
                            //   // setReschedule(false);
                            //   handleDecline();
                            // }
                          }}
                        >
                          <Button
                            className="decline-interview-btn"
                            disabled={
                              // (bidStatus.includes("Accepted Interview") ||
                              //   bidStatus.includes("Interview Cancelled"))
                              //   &&
                              // !rescheduleDisable
                              disableHandler()
                            }
                          >
                            {DeclineInterviewLoading ? (
                              <LoadingOutlined />
                            ) : props.items?.Rank === 8 || declineFlag ? (
                              "Declined"
                            ) : (
                              "Decline"
                            )}
                          </Button>
                        </div>
                        <div
                          className="interview-accept"
                          // {
                          //   (props.items?.Rank > 6 ||
                          //     isProposalAccept ||
                          //     declineFlag ||
                          //     rescheduleFlag) &&
                          //   " interested-decline"
                          // }
                          onClick={() => {
                            if (
                              !(
                                props.items?.Rank > 6 ||
                                isProposalAccept ||
                                declineFlag ||
                                rescheduleFlag
                              )
                            ) {
                              ProposedInterviewHandle();
                            }
                          }}
                        >
                          <Button
                            className="interview-accept-btn"
                            disabled={
                              // (bidStatus.includes("Accepted Interview") ||
                              //   bidStatus.includes("Interview Cancelled"))
                              //    &&
                              // !rescheduleDisable
                              disableHandler()
                            }
                          >
                            {interviewLoading &&
                            !DeclineInterviewLoading &&
                            !RescheduleInterviewLoading ? (
                              <LoadingOutlined />
                            ) : (
                              "Accept"
                            )}
                          </Button>
                        </div>
                      </div>
                    </>
                  )}
                  <div className="reschedule-btn-container">
                    <Button
                      disabled={
                        // (bidStatus.includes("Accepted Interview") ||
                        //   bidStatus.includes("Interview Cancelled"))
                        //   &&
                        // !rescheduleDisable
                        disableHandler()
                      }
                      className="reschedule"
                      // ${
                      //   (props.items?.Rank > 6 ||
                      //     isProposalAccept ||
                      //     declineFlag ||
                      //     rescheduleFlag) &&
                      //   " interested-decline"
                      // }`}
                      onClick={() => {
                        setRescheduleDisable(true);
                        if (
                          !(
                            props.items?.Rank > 6 ||
                            isProposalAccept ||
                            declineFlag ||
                            rescheduleFlag
                          )
                        ) {
                          setModalVisible(true);
                          // setDecline(false);
                          // handleReschedule();
                        }
                      }}
                    >
                      {RescheduleInterviewLoading ? (
                        <LoadingOutlined />
                      ) : (
                        "Request to reschedule"
                      )}
                    </Button>
                  </div>
                </div>
              )}
            </div>
          </div>
        </div>
      )}
      {/* <div
        className={
          props.type === "reschedule"
            ? "chat-profile-submit chat-profile-proposed"
            : "chat-profile-submit"
        }
        ref={messagesEndRef}
        >
        <div className="user-threat">
          <div className="accept proposed-interview-font">
            Proposed interview Timings
          </div>
          <Radio.Group onChange={(e) => onchangeRadio(e)} value={value}>
            {props.date?.map((date, i) => {
              const time = new Date(date?.StartTime);
              const TimeArr = getProposedInterviewDateAndTime(time);

              return (
                <Space direction="vertical">
                  <div className="datepicker datepicker-radio-options">
                    <div className="proposal-radio-button">
                      <Radio value={i}></Radio>
                    </div>
                    <div
                      onClick={(e, key, time) =>
                        onchangeRadio1(e, (key = i), (time = date))
                      }
                      value={value}
                    >
                      <div className="date">
                        {TimeArr[0] + ", " + TimeArr[1] + ", " + TimeArr[2]}
                      </div>
                      <div className="time">
                        {TimeArr[3]} - {TimeArr[4]}
                      </div>
                    </div>
                  </div>
                </Space>
              );
            })}
          </Radio.Group>

          <div className="time-stamp">
            <div className="time-text-employer">1:48 pm</div>
            {props.type === "reschedule" ? (
              <>
                <div className="tick-icon">
                  <RiCheckDoubleFill />
                </div>
              </>
            ) : null}
          </div>
        </div>
      </div> */}
      <Modal
        bodyStyle={{ padding: 0 }}
        width={window.innerWidth < 768 ? "20.625rem" : "22.5rem"}
        title={"Decline this Interview ?"}
        className="message-details-model"
        visible={isModalVisible}
        onOk={handleOk}
        onCancel={handleCancel}
        footer={null}
        centered={true}
        closable={clicked === "retract-bid" ? false : true}
      >
        <div className="main-class">
          <div className="bid-decline-headline decline_interview_content">
            Let the employer know the reason why you're declining the interview:
          </div>
          {declineReasons?.map((data) => (
            <div
              className={
                data.ReasonId === declinedReason
                  ? "bid-decline-option-selected"
                  : "bid-decline-option"
              }
              onClick={() => declineReasonHandler(data)}
            >
              {data.Reason}
            </div>
          ))}
          <div
            className={
              declinedReason != null
                ? "bid-decline-button decline_interview_button"
                : "bid-decline-button_disable "
            }
            onClick={() => handleReasonSubmit()}
          >
            {DeclineInterviewLoading ? (
              <LoadingOutlined />
            ) : (
              "Decline interview"
            )}
          </div>
        </div>
      </Modal>
      {props.status == "Share your feedBack" && props?.SkillListing_id && (
        <div>
          <div className="feebackText">Share your feedback</div>
          <div className="similiContainer">
            <div>
              <BsEmojiSmile className="happy-emoji" />
            </div>
            <div>
              <HiOutlineEmojiSad className="Sad-emoji" />
            </div>
          </div>
        </div>
      )}
    </>
  );
};
export default InterviewStatusCards;
