import { call, put, takeLatest } from "redux-saga/effects";
import { types } from "../action-types";
import {
  checkUsernameUniqueSuccess,
  checkUsernameUniqueFail,
} from "../actions";
import { checkUserNameUnique } from "../../API/API";

function* checkUserNameSaga(action) {
  try {
    // debugger
    const response = yield call(() => checkUserNameUnique(action.payload));
    if (response) {
      // 
      yield put(checkUsernameUniqueSuccess(response?.data));
    }
  } catch (error) {
    yield put(checkUsernameUniqueFail(error));
  }
}

export function* watchCheckUsernameUnique() {
  yield takeLatest(types.CHECK_USERNAME_UNIQUE, checkUserNameSaga);
}
