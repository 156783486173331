import React from 'react';
import './ReadRuleMobile.css';

const ReadRuleDesktop = ({ data, code}) => {

    return (
        <div className="Read-rule-text-main-div">
            {window.innerWidth > 768 ? <>
            <div className="Read-rule-text-main-img">
                {data?.icon ? (data?.img) : <img className="read-rules-icons" src={data?.img} alt="" />}
            </div>
                <div className="Read-rule-text-main-rule-div">
                    <div className="Read-rule-text-main-Heading">
                        {data?.Heading}
                    </div>
                    <div className="Read-rule-text-main-Text">
                        {code == "IT" && data?.TextIT ? data?.TextIT : data?.Text}
                    </div>
                </div>
            </> :
            <div className="Read-rule-text-main-div-mobile">
                <div className='Read-rule-text-main-imgHeading-mobile'>
                    <div className="Read-rule-text-main-img-mobile">
                        {data?.icon ? (data?.img) : <img className="read-rules-icons" src={data?.img} alt="" />}
                    </div>
                    <div className="Read-rule-text-main-Heading-mobile">
                        {data?.Heading}
                    </div>
                </div>
                <div className="Read-rule-text-main-Text-mobile">
                        {code == "IT" ? data?.TextIT : data?.Text}
                </div>
            </div>}
        </div>
    );
};

export default ReadRuleDesktop;