import React, {useEffect, useState} from "react";
import GigsCard from "../SearchGigs/GigsCard";
import "./ViewCompanyJobs.css"
import {Pagination} from "antd";
import {useParams} from "react-router-dom"; 
import NoJobIcon from "../../assets/icons/nobids.png";
import { useDispatch, useSelector } from "react-redux";
import { fetchCompanyProfileGigs } from "../../redux/actions";
import Loader from "../../components/Loader/Loader";

const ViewCompanyJobs = () => {
    const [page,setPage] = useState(1);
    const [pageSize, setPageSize] = useState(24);
    const [gigs, setGigs] = useState(null);
    const dispatch = useDispatch();
    const { id } = useParams();
    const { companyGigs, loadingCG: loading, companyGigsCount } = useSelector((state) => state.viewListingReducer);

    useEffect(() => {
        dispatch(fetchCompanyProfileGigs({ page, pageSize, userId: id }))
    },[page,pageSize]);

    useEffect(()=>{
        setGigs(companyGigs && companyGigs.length ? companyGigs : []);
    },[companyGigs])

    const getStateAfterAddRemoveFavorite = (JobOrderId, IsFavorite) => {
        setGigs((prev) =>
          prev.map((gig) =>
            gig.JobOrderId === JobOrderId ? { ...gig, IsFavorite } : gig
          )
        );
    };

    const NoJobsFound = () => {
        return (
            <>
              <div className="Company-profile-no-job-container">
                <div className="no-job-image-icon">
                  <img src={NoJobIcon} alt="" />
                </div>
                <div className="Company-profile-no-job-text">
                  <p className="No-data">No data</p>
                </div>
              </div>
            </>
          );
    }

    return (
        <main className="company-profile-job-container">
            <div className="company-profile-job-title-cont">
                <p className="company-profile-job-title">Employer's job</p>
                {!loading && 
                    <div className="company-profile-job-count-container">
                        <span className="company-profile-job-count">{companyGigsCount||gigs?.length||0}</span>
                    </div>
                }
            </div>
            <div>
                <div className="company-profile-job-gigcard" Pagination={true}>
                    {!loading && gigs && gigs.length > 0 && gigs.map((item) => {
                        return (
                            <GigsCard
                                data={item}
                                budgetType={""}
                                setFavorite={getStateAfterAddRemoveFavorite}
                                Types="pvtGigCard"
                            />
                        )
                    })}
                </div>
                {loading && 
                    <div className="gig-loader">
                        <Loader />
                    </div>
                }
                {!loading && companyGigsCount > pageSize && 
                    <div className="company-profile-job-pagination">
                        <Pagination
                            defaultCurrent={page ? page : 1}
                            total={companyGigsCount}
                            pageSize={pageSize ? pageSize : 24}
                            pageSizeOptions={[10,20,24,50,100]}
                            onChange={(page,pageSize) => {
                                window.scrollTo({ top: 0, left: 0, behavior: "auto" });
                                setPage(page);
                                setPageSize(pageSize);
                            }}
                        />
                    </div>
                }
              {!loading && gigs && gigs.length == 0 && NoJobsFound()}
            </div>
        </main>

    )
}

export default ViewCompanyJobs;