import React, { useEffect } from "react";
import "./AnyCareerGap.css";
import { Radio } from "antd";
import { useState } from "react";
import { updateSignupProfile } from "../../../../../redux/actions";
import { useDispatch, useSelector } from "react-redux";
import TextArea from "antd/lib/input/TextArea";
import { AiFillWarning } from "react-icons/ai";
import ProfileCompletion from "../../ProfileCompletion/ProfileCompletion";
import { useHistory } from "react-router-dom/cjs/react-router-dom.min";
import BackNextSignUpButton from "./BackNextSignUpButton";
import BinaryButtons from "../../BinaryButtons/BinaryButtons";

const AnyCareerGap = () => {
  const dispatch = useDispatch();
  const history = useHistory();

  const [error, setError] = useState(null);
  const [wordLimitError, setWordLimitError] = useState(false);
  const [isGapYearState, setIsGapYearState] = useState(null);
  const [gapYearReasonState, setgapYearReasonState] = useState(null);
  const [redirectionFlag, setRedirectionFlag] = useState(false);

  const Profiles = useSelector((state) => state.profileReducer?.user?.profile);
  const loading = useSelector((state) => state.profileReducer.signUpLoading);
  const apiSuccess = useSelector(
    (state) => state.profileReducer.signupUpdateSuccess
  );

  const ProfessionCode = Profiles?.ProfessionCode;

  useEffect(() => {
    dispatch(
      updateSignupProfile({
        WizardFlow: 111,
      })
    );
  }, []);

  useEffect(() => {
    if (Profiles?.CareerGap?.isCareerGap != null) {
      setIsGapYearState(
        Profiles?.CareerGap?.isCareerGap !== null
          ? Profiles?.CareerGap?.isCareerGap
            ? "Yes"
            : "No"
          : null
      );
      setgapYearReasonState(Profiles?.CareerGap?.Reason);
    }

    return () => {
      setIsGapYearState(null);
      setgapYearReasonState(null);
    };
  }, [Profiles?.CareerGap]);

  const onChangeReason = (e) => {
    const value = e.target.value;
    let errorTimeOut;
    if (value?.length >= 300) {
      setWordLimitError(true);
      errorTimeOut = setTimeout(() => {
        setWordLimitError(false);
      }, 4000);
    } else {
      clearTimeout(errorTimeOut);
      setWordLimitError(false);
    }

    if (value?.length <= 300) {
      setgapYearReasonState(value);
    }
  };

  useEffect(() => {
    if (apiSuccess && redirectionFlag) {
      setRedirectionFlag(false);
      if (ProfessionCode === "HC") {
        history.push("/state-lisence-doc");
      } else history.push("/create-skilllisting-gig-type");
    }
  }, [apiSuccess]);

  const submitFunction = () => {
    const gapyear =
      isGapYearState != null ? (isGapYearState === "Yes" ? true : false) : null;

    const careerGap =
      gapyear !== null ? gapyear : Profiles?.CareerGap?.isCareerGap;

    if (gapyear != null) {
      setRedirectionFlag(true);
      dispatch(
        updateSignupProfile({
          "Profiles.CareerGap": {
            isCareerGap: careerGap,
            Reason:
              careerGap !== true
                ? null
                : gapYearReasonState || Profiles?.CareerGap?.Reason,
          },
          WizardFlow: ProfessionCode === "HC" ? 112 : 201,
        })
      );
    } else {
      if (ProfessionCode === "HC") {
        history.push("/state-lisence-doc");
      } else history.push("/create-skilllisting-gig-type");
    }
  };

  return (
    <div className="any-career-gap-signup-main">
      <ProfileCompletion
        title="Upload documents"
        currenPage={ProfessionCode === "HC" ? 4 : 2}
        totalPage={ProfessionCode === "HC" ? 9 : 2}
      />
      <div className="any-career-gap-signup">
        <div className="title-any-career-gap">
          Any career gaps if over two months off?
        </div>
        <div className="radio-buttons-yes-no">
          <BinaryButtons
            label1={"Yes"}
            label2={"No"}
            selected={isGapYearState}
            setSelected={setIsGapYearState}
          />
        </div>
        {error && (
          <div className="gap-validation-300">
            <AiFillWarning fontSize="1rem" />
            <div>{error}</div>
          </div>
        )}
        {isGapYearState !== "No" &&
        (isGapYearState === "Yes" ||
          Profiles?.CareerGap?.isCareerGap === "Yes") ? (
          <div>
            <TextArea
              onChange={onChangeReason}
              defaultValue={gapYearReasonState}
              placeholder="Add reason (Optional)"
              bordered={false}
              autoSize={true}
              maxLength={300}
            />
            <div
              className={`bottom-line-gap ${
                wordLimitError ? "gap-filler-red" : ""
              }`}
            ></div>
            {wordLimitError && (
              <div className="gap-validation-300">
                <AiFillWarning fontSize="1rem" />
                <div>Please keep Maximum 300 character as word limit</div>
              </div>
            )}
          </div>
        ) : (
          <div style={{ height: "2rem" }}></div>
        )}
      </div>

      {/* <BottomButtons
        backHandler={() => history.push("/resume-upload")}
        nextHandler={submitFunction}
        // nextBtnLoading={loading}>
      /> */}
      <BackNextSignUpButton
        backFunction={() => {
          if (ProfessionCode === "HC") history.push("/acls-doc");
          else history.push("/resume-upload-v2");
        }}
        nextFunction={submitFunction}
        nextBtnLoading={loading}
        skipFunction={() => {
          if (ProfessionCode === "HC") {
            history.push("/state-lisence-doc");
          } else history.push("/create-skilllisting-gig-type");
        }}
        skip={true}
      />
    </div>
  );
};

export default AnyCareerGap;
