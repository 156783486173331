import React, { useEffect, useState } from "react";
import { useHistory } from "react-router-dom";
import ProfileCompletion from "./Components/ProfileCompletion/ProfileCompletion";
import "./SignUp.css";
import BottomButtons from "./Components/BottomBottons/BottomButtons";
import TextInput from "./Components/TextInput/TextInput";
import PhoneNumberInput from "./Components/NumericInput/PhoneNumberInput";
import { getCountryPhoneCode } from "../WelcomeWizard/helper";
import { useDispatch, useSelector } from "react-redux";
import { fetchMobileOTPInit, fetchVerifictionOTPInit, resetOtpStatus, updateSignupProfile } from "../../redux/actions";
import MobileNumberOTP from "./Components/MobileNumberOTP/MobileNumberOTP";
import { alertNotification } from "../../components/notifications/alertNotification";

function MobileNumberScreen() {
  const windowHeight = window.innerHeight-50;
  const history = useHistory();
  const dispatch = useDispatch();
  const [phoneNumber, setPhoneNumber] = useState("");
  const [countryCode, setCountryCode] = useState("1");
  const [error, setError] = useState(null);
  const [flag, setFlag] = useState(false);
  const [isModalOpen, setIsModalOpen] = useState(false);
  const [otp, setOtp] = useState(null);
  const [LoadingOtp, setLoadingOtp] = useState(false);
  const profiledata = useSelector((state) => state.profileReducer.user);
  const loading = useSelector((state) => state.profileReducer.signUpLoading);
  const apiError = useSelector((state) => state.profileReducer.error);
  const apiSuccess = useSelector((state) => state.profileReducer.signupUpdateSuccess);
  const OTPapiSuccess = useSelector((state) => state.authReducer.OTPVerfiction);
  const OTPLoading = useSelector((state) => state.authReducer.loadingOTP);

  const formatPhoneNumber = (input) => {
    const cleanedInput = input.replace(/\D/g, '');
    if (cleanedInput.length >= 10) {
      const areaCode = cleanedInput.substring(0, 3);
      const prefix = cleanedInput.substring(3, 6);
      const lineNumber = cleanedInput.substring(6, 10);
      setPhoneNumber(`(${areaCode}) ${prefix}-${lineNumber}`);
    }else if(cleanedInput.length >= 7){
      const areaCode = cleanedInput.substring(0, 3);
      const prefix = cleanedInput.substring(3, 6);
      const lineNumber = cleanedInput.substring(6, 10);
      setPhoneNumber(`(${areaCode}) ${prefix}-${lineNumber}`);
    }else if(cleanedInput.length >= 4){
      const areaCode = cleanedInput.substring(0, 3);
      const prefix = cleanedInput.substring(3, 6);
      setPhoneNumber(`(${areaCode}) ${prefix}`);
    }else{
      setPhoneNumber(cleanedInput);
    }
  }

  useEffect(() => {
   dispatch(resetOtpStatus())
  }, [])
  

  useEffect(() => {
    setCountryCode(getCountryPhoneCode(profiledata?.profile?.Location?.CountryName)?? "1");
    if(profiledata?.profile?.MobilePhone){
      formatPhoneNumber(profiledata?.profile?.MobilePhone?.slice(-10))
    }
  }, [profiledata])

  useEffect(() => {
    if (OTPapiSuccess?.status == "passed" && flag){
      setFlag(false);
      history.push("/explever");
      setIsModalOpen(false);
      alertNotification("success", "Mobile number verified successfully");
    }
    setLoadingOtp(false);
  }, [OTPapiSuccess?.status])

  const nextHandler = () => {
    const cleanedInput = phoneNumber?.replace(/\D/g, '');
    if(cleanedInput ==""){
      setError("Please add your Mobile number to continue");
    }else if(cleanedInput.length <10){
      setError("Please enter valid mobile number");
    }else{
      setError(null);
      setFlag(true);
      dispatch(fetchMobileOTPInit({ "MobilePhone": Number(cleanedInput) }))
      setIsModalOpen(true);
    }
  };

  const OTPVerification = () => {
    const cleanedInput = phoneNumber?.replace(/\D/g, '');
    dispatch(fetchVerifictionOTPInit({
      "MobilePhone": Number(countryCode+cleanedInput),
      "Otp": Number(otp),
      WizardFlow:105
    }))
    setLoadingOtp(true);
  }

  return (
    <div className="NameScreenContainer" style={{minHeight:windowHeight}}>
      <ProfileCompletion currenPage={5} totalPage={10} />
      <PhoneNumberInput
        title={"Mobile number"}
        placeholder={"(878) 916-4875"}
        type={"Mandatory"}
        onChange={(e) => {formatPhoneNumber(e.target.value); error && setError(null)}}
        countryCode={countryCode}
        value={phoneNumber}
        error={error}
        maxLength={50}
      />
      <BottomButtons 
        nextHandler={nextHandler}
        nextBtnLoading={loading}
        backHandler={() => history.push(profiledata?.profile.ProfessionCode === "IT" ? '/location' :'/speciality')} />

      <MobileNumberOTP 
        setIsModalOpen={setIsModalOpen} 
        isModalOpen={isModalOpen}
        phoneNumber={phoneNumber}
        countryCode={countryCode}
        setOtp={setOtp}
        otp={otp}
        OTPVerification={OTPVerification}
        OTPapiSuccess={OTPapiSuccess}
        LoadingOtp={OTPLoading}
        setLoadingOtp={setLoadingOtp}
      />
    </div>
  );
}

export default MobileNumberScreen;
