import React, { useState, useEffect } from "react";
import "./SearchBarMobile.css";
import {
  fetchGigs,
  SearchResponse,
  SearchResponseRequested,
  SearchValue,
  fetchSearchSuggestions,
} from "../../../redux/actions";
import { useHistory } from "react-router-dom";
import { useLocation } from "react-router";
import { Button, Input, AutoComplete } from "antd";
import ServiceOutkine from "../../../assets/icons/service-outlined.svg";
import Suitcase from "../../../assets/icons/suitcase.svg";
import { SearchOutlined } from "@ant-design/icons";
import { connect } from "react-redux";

function SearchBarMobile(props) {
  const url = useLocation();
  // 
  const history = useHistory();

  const [search, setSearch] = useState(props.value);
  // 
  const [count, setCount] = useState(0);
  const [isSuggestion, setIsSuggestion] = useState(false);
  const [suggestionGigs, setSuggestionGigs] = useState([]);
  const [suggestionSlisiting, setSuggestionSlisting] = useState([]);
  let data = ["a", "b", "c", "d"];

  const BackendApiBaseUrl = process.env.REACT_APP_API_URL;
  const onSearch = (e) => {
    // setSearch(e.target.value);
    setIsSuggestion(!isSuggestion);
    if (search.length > 0) {
      if (url.pathname != "/search") {
        history.push("/search");
      }
      props.searchValue(search);
    }
  };

  const onClickSkill = (e) => {
    setIsSuggestion(!isSuggestion);
    setSearch(e);
    props.searchSuggestions(e);
    // // 
    if (search?.length > 0) {
      if (url.pathname != "/search") {
        history.push("/search");
      }
      props.searchValue(e);
    }
  };

  const onChangeSearch = (e) => {
    setSearch(e.target.value);
    e.preventDefault();
    props.searchSuggestions(e.target.value);
    // if (e.target.value.length == 0) {
    //   history.push("/search");
    // }

    // if (e.target.value?.length > 3) {
    // hitQuery({ variables: { all: e.target.value } });
    // debounce(hitQuery());
    // }
  };

  const onEnterKey = (e) => {
    if (e.key === "Enter" && search.length > 0) {
      if (url.pathname != "/search") {
        history.push("/search");
      }
      props.searchValue(search);
      // props.searchSuggestions(search);
    }
  };

  const Query = () => {
    // 
    // props.GetGigs(query);

    // const GetGigs = props.user.Gigs;
    // // 
    // if (loading) return "Loading...";
    // if (error) return `Error! ${error.message}`;
  };
  useEffect(() => {
    // 
    // 
    // // 
    setSuggestionGigs(props.suggestions?.searchSuggestions?.gigs);
    setSuggestionSlisting(props.suggestions?.searchSuggestions?.skillsListing);
  }, [props.suggestions]);
  useEffect(() => {
    setSearch(props.value);
  }, [props.value]);

  const renderTitle1 = (title) => (
    <span
      style={{
        display: "flex",
        // width: "20px",
        // fontFamily: "SFProText",
        fontSize: "16px",
        color: "#7e7e7e",
        // justifyContent: "space-between",
      }}
    >
      <img
        src={Suitcase}
        style={{ margin: "0 10px 0 0", width: "18px", height: "18px" }}
      />
      {title}
      {/* <a
          style={{
            float: "right",
          }}
          href="https://www.google.com/search?q=antd"
          target="_blank"
          rel="noopener noreferrer"
        >
          more
        </a> */}
    </span>
  );
  const renderTitle2 = (title) => (
    <span
      style={{
        display: "flex",
        // fontFamily: "SFProText",
        fontSize: "16px",
        color: "#7e7e7e",
        margin: "10px 0 0 0",
        // width: "40px",
        // justifyContent: "space-between",
      }}
    >
      <img
        src={ServiceOutkine}
        style={{ margin: "0 10px 0 0", width: "18px", height: "18px" }}
      />
      {title}
      {/* <a
          style={{
            float: "right",
          }}
          href="https://www.google.com/search?q=antd"
          target="_blank"
          rel="noopener noreferrer"
        >
          more
        </a> */}
    </span>
  );

  // let mapping = suggestion?.map((item) => renderItem(item));

  const renderItem1 = (title) => ({
    value: title,
    label: (
      <div
        style={{
          display: "flex",
          width: "40px",
          // justifyContent: "space-between",
        }}
        onClick={() => onClickSkill(title)}
      >
        {title}
      </div>
    ),
  });

  const renderItem2 = (title) => ({
    value: title,
    label: (
      <div
        style={{
          display: "flex",
          width: "40px",
          // justifyContent: "space-between",
        }}
        // onClick={onSearch}
      >
        {title}
      </div>
    ),
  });

  const options = [
    {
      label: renderTitle1("Gigs"),
      options: suggestionGigs
        ? suggestionGigs?.map((item) => {
            return renderItem1(item);
          })
        : null,
    },
    {
      label: renderTitle2("Skill Listing"),
      options: suggestionSlisiting
        ? suggestionSlisiting?.map((item) => {
            return renderItem2(item);
          })
        : null,
    },
  ];
  return (
    <div>
      {/* <Input
        placeholder="Find Jobs"
        prefix={<SearchOutlined className="site-form-item-icon" />}
        onPressEnter={(e) => onSearch(e)}
        // style={{ width: 200 }}
      /> */}
      <AutoComplete
        dropdownClassName="certain-category-search-dropdown"
       // dropdownMatchSelectWidth={0}
        style={{
          width: "100%",
        }}
        // onChange={(e) => onChangeSearch(e)}
        onKeyPress={(e) => onEnterKey(e)}
        // dropdownStyle={{ width: "20px" }}
        dropdownMatchSelectWidth={100}
        options={suggestionGigs && isSuggestion ? options : null}
        // options={options}
      >
        {/* <div className="searchInputDashboard"> */}
        <div className="searchGigsMobile">
          <Input
            placeholder="Find gigs"
            // style={{ color: "white" }}
            type="text"
            prefix={<SearchOutlined className="site-form-item-icon" />}
            value={search}
            open="true"
            onChange={(e) => onChangeSearch(e)}
            onClick={() => setIsSuggestion(!isSuggestion)}
            // onKeyPress={(e) => onEnterKey(e)}
            // className="icons"
          />
        </div>
        {/* </div> */}
      </AutoComplete>
    </div>
  );
}
const mapStateToProps = (state) => {
  return {
    gigs: state.searchGigsReducer.gigs,
    value: state.searchGigsReducer.searchvalue,
    error: state.searchGigsReducer,
    talentToken: state.authReducer.user?.authToken,
    suggestions: state.searchGigsReducer.searchSuggestions,
    // userProfile: state.profileReducer.user.profile,
  };
};
const mapDispatchToProps = (dispatch) => {
  return {
    // GetGigs: (params) => {
    //   dispatch(fetchGigs(params)); // action dispatched
    // },
    // SearchValue
    SearchResponseRequested: (params) => {
      dispatch(SearchResponseRequested(params)); // action dispatched
    },
    searchResponse: (params) => {
      dispatch(SearchResponse(params)); // action dispatched
    },
    searchValue: (params) => {
      dispatch(SearchValue(params)); // action dispatched
    },
    searchSuggestions: (params) => {
      dispatch(fetchSearchSuggestions(params));
    },
  };
};

export default connect(mapStateToProps, mapDispatchToProps)(SearchBarMobile);
