import React from "react";
import "./BinaryButtons.css";

const BinaryButtons = ({ label1, label2, setSelected, selected, error, setError }) => {
  const handleClick = (label) => () => {
    setSelected(label);
    error && setError(false)
  };

  const getButtonClass = (label) =>
    selected === label ? "binary-button-selected" : "binary-button";

  return (
    <div className="binary-button-wrapper">
      {[label1, label2].map((label) => (
        <button
          key={label}
          onClick={handleClick(label)}
          className={getButtonClass(label)}
        >
          {label}
        </button>
      ))}
    </div>
  );
};

export default BinaryButtons;
