import React from "react";
import "./BhsFltSort.css";
import { useEffect } from "react";
import { useRef } from "react";
import { useState } from "react";
import dropdownIcon from "../../../../../assets/icons/bx_bx-chevron-down.svg";
import { bidStatusOptions, sortOptions, useBlurEffect } from "./bhsHandler";
import Loader from "../../../../../components/Loader/Loader";
import { useSelector } from "react-redux";
import { Divider, Drawer, Radio, Space } from "antd";

const DropdownBtn = ({
  data: [selected, option, index, setDropdownBtn, getFSData],
}) => {
  const [selectedFS, setSelectedFS, fetchBidHistoryDetails, setIsLocation] =
    getFSData; // get Filter Sort Data
  const dropDownRef = useRef(null);
  const [dropdownVisible, setDropdownVisible] = useState(false);
  const [DrowerVisible, setDrowerVisible] = useState(false);
  const [RadioButtonValue, setRadioButtonValue] = useState("Most Recent");
  const [RadioButtonItem, setRadioButtonItem] = useState("Most Recent");
  const loading = useSelector((state) => state.BidsReducer.loading);
  const [onClick, setOnClick] = useState(false);
  const [dimensions, setDimensions] = useState(window.innerWidth);
  useBlurEffect(dropDownRef, setDropdownVisible);

  const handlingOnClick = (item) => {
    if (Object.keys(item)[0] === "Near By Location") {
      setIsLocation(true);
    }
    setSelectedFS({
      filter: index === 0 ? item[Object.keys(item)[0]] : selectedFS.filter,
      sort: index === 1 ? item[Object.keys(item)[0]] : selectedFS.sort,
    });
    fetchBidHistoryDetails(
      index === 0 ? item[Object.keys(item)[0]] : selectedFS.filter,
      index === 1 ? item[Object.keys(item)[0]] : selectedFS.sort
    );
    setDropdownBtn((prev) => {
      prev[index].selected = Object.keys(item)[0];
      return [...prev];
    });
    setDropdownVisible(false);
    setOnClick(true);
    setDrowerVisible(false);
  };

  useEffect(() => {
    if (!loading) setOnClick(false);
  }, [loading]);

  const checkDimensions = () => {
    setDimensions(window.innerWidth);
  };

  useEffect(() => {
    window.addEventListener("resize", checkDimensions);
  }, []);

  const closeFilterDrawer = () => {
    setDrowerVisible(false);
  };
  useEffect(() =>{
    if (index === 0){
      option?.push({ "Favorites": "Favorites" })
    }
  }, [option]) 

  return (
    <>
      <div className="dropdownBox">
        <div
          className="dropdownBtn"
          key={index}
          onClick={() => {
            setDropdownVisible(!dropdownVisible);
            setDrowerVisible(!DrowerVisible);
          }}
        >
          <span>{selected}</span>
          {loading && onClick ? (
            <span>
              <Loader size={"14px"} height={"0px"} scrolling={false} />
            </span>
          ) : (
            <img src={dropdownIcon} alt="" />
          )}
        </div>
        <div
          className={`dropdownOption ${
            dropdownVisible && dimensions > 768 ? "dropdownOptionVisible" : ""
          }`}
          ref={dropDownRef}
        >
          {option?.map((item, i) => (
            <div
              className="dropdownItem"
              key={i}
              onClick={() => handlingOnClick(item)}
            >
              {Object.keys(item)[0]}
            </div>
          ))}
        </div>
      </div>
      <Drawer
        title={index === 0 ? "Filter By" : "Sort bids by"}
        placement="top"
        onClose={closeFilterDrawer}
        visible={dimensions < 768 ? DrowerVisible : false}
        className="Bid-history-Drawer"
        height="40rem"
        width="100%"
      >
        <div>
          {option?.map((item, i) => (
            <div
              className={`dropdownItem ${
                Object.keys(item)[0] === selected && "greenTextRB"
              } ${index === 0 && "dropdownItem-filter"} 
              ${index === 0 && Object.keys(item)[0] === "Favorites" && "Divider-Line-Bid_history" }
              `}
              key={i}
              onClick={() => {
                if (index === 1) return;
                handlingOnClick(item);
              }}
            >
              {index === 0 ? (
                Object.keys(item)[0]
              ) : (
                <Radio.Group
                  className="radioSortReceivedBidsGroup"
                  value={RadioButtonValue}
                >
                  <Space direction="vertical">
                    <Radio
                      className="radioSortReceivedBids"
                      value={Object.keys(item)[0]}
                      onClick={() => {
                        setRadioButtonValue(Object.keys(item)[0]);
                        setRadioButtonItem(item);
                      }}
                    >
                      {Object.keys(item)[0]}
                    </Radio>
                  </Space>
                </Radio.Group>
              )}
            </div>
          ))}
          {index === 1 && <Divider className="Bid-History-divider" />}
          {index === 1 && (
            <div className="radioSortShowItemsParent Sort-Button-Bid-history">
              <div
                className="radioSortShowItemsChild"
                onClick={() => {
                  if (index === 0) return;
                  handlingOnClick(RadioButtonItem);
                }}
              >
                Show items
              </div>
            </div>
          )}
        </div>
      </Drawer>
    </>
  );
};

// Bid History SkillListing Filter Sort Component
const BhsFltSort = ({ bidStatus, getFSData }) => {
  const [dropdownBtn, setDropdownBtn] = useState([
    {
      type: "filter",
      selected: "All",
      option: bidStatusOptions,
    },
    {
      type: "sort",
      selected: "Most Recent",
      option: sortOptions,
    },
  ]);
  useEffect(() => {
    setDropdownBtn((prev) => {
      prev[0].option = bidStatus?.length ? bidStatus : bidStatusOptions;
      return [...prev];
    });
  }, [bidStatus]);

  return (
    <>
      {dropdownBtn.map(({ selected, option }, index) => (
        <DropdownBtn
          data={[selected, option, index, setDropdownBtn, getFSData]}
          key={index}
        />
      ))}
    </>
  );
};

export default BhsFltSort;
