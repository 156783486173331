import { LoadingOutlined } from "@ant-design/icons";
import React, { useEffect, useState } from "react";

import { Auth } from "../../../config/aws-exports";

// Redux
import { connect, useDispatch, useSelector } from "react-redux";
import {
  fetchUserSkillListing,
  updateImpersonationIdId,
  updateProfile,
  updateUserInfo,
} from "../../../redux/actions";
import { fetchUserProfile } from "../../../redux/actions";
import { createBid } from "../../../redux/actions";
import {
  getCookie,
  getUserDeviceDetails,
  setCookie,
} from "../../../shared/helpers";

import { useHistory } from "react-router-dom";
import { useLocation } from "react-router-dom";
import { addUpdateUserDeviceDeatils } from "../../../API/API";
import axios from "axios";

const AuthSuccess = (props) => {
  const history = useHistory();
  const location = useLocation().search;

  const dispatch = useDispatch();
  const userId = new URLSearchParams(location).get("userId");
  const adminToken = new URLSearchParams(location).get("token");
  const socialSignUpWithEmp =JSON.parse(localStorage.getItem('socialSignUpWithEmp'))
  const employerDetails = useSelector(
    (state) => state.employerReducer.emp
  );

  let myInterval;

  useEffect(() => {
    async function AuthCheck() {
      let session = null;

      // debugger;
      try {
        if (userId && adminToken) {
          setCookie("talentToken", adminToken, true);
          setCookie("impersonationid", userId);
          props.updateImpersonationId(userId);
          props.sendAuthToken(adminToken);
          props.profileDetails({
            talentToken: adminToken,
            impersonationId: userId,
          });
        } else if (
          getCookie("talentToken") !== null &&
          getCookie("talentToken").length > 0
        ) {
          const talentToken = getCookie("talentToken");
          props.profileDetails({talentToken});
        } else if ((session = await Auth.currentSession())) {
          const talentToken = session.getIdToken().getJwtToken();
          setCookie("talentToken", talentToken, true);
          props.sendAuthToken(talentToken);
          myInterval = setInterval(props.profileDetails({talentToken,empName:employerDetails?.empTitle||socialSignUpWithEmp?.empTitle}), 1000);
        } else {
          window.location.replace("/login");
        }
      } catch (err) {
        window.location.replace("/login");
      }
    }
    AuthCheck();
  }, []);

  const lastLoginFunc = () => {
    dispatch(
      updateProfile({
        LastLoginDate: new Date(),
        LastSuccessfulLoginDate: new Date(),
      })
    );
    addUpdateUserDeviceDeatils(getUserDeviceDetails());
  };

  const talentEmpUpdate = (code = "TAL", role = ["CONS"]) => {
    localStorage.setItem('employer',JSON.stringify(employerDetails||socialSignUpWithEmp||{}))
    localStorage.removeItem("socialSignUpWithEmp")
    dispatch(
      updateProfile({
        userId: props.user.id,
        AccountTypeCode: code,
        Role: role,
        Professions: { ProfessionCode: "IT" },
        WizardFlow: 2,
        // last login update fallback
        LastLoginDate: new Date(),
        LastSuccessfulLoginDate: new Date(),
        empName: employerDetails?.empTitle,
      })
    );
  };

  useEffect(() => {
    const profile = props.user.profile;
    if (props.user.email) {
      if (!adminToken) {
        dispatch(
          updateProfile({
            LastLoginDate: new Date(),
            LastSuccessfulLoginDate: new Date(),
          })
        );
        addUpdateUserDeviceDeatils(getUserDeviceDetails(props.IPAddress));
        lastLoginFunc();
      }

      clearInterval(myInterval);
      if (profile.WizardFlow === 0 && (employerDetails||socialSignUpWithEmp)) {
        talentEmpUpdate();
        history.push("/read-rules");
      } else if (profile.WizardFlow === 3) {
        history.push("/create-skilllisting-signup");
      } else if (profile.WizardFlow === 1) {
        history.push("/profession");
      } else if (profile.WizardFlow === 2) {
        history.push("/read-rules");
        // history.push("/sign_educational_screen1");
      } else if (profile.WizardFlow === 0) {
        history.push("/talentemployer");
      } else if (profile.WizardFlow === 4) {
        history.push("/resume-upload");
      } else if (profile.WizardFlow === 7) {
        history.push("/profile-instruction");
      } else if (profile.WizardFlow === 8) {
        history.push("/effective-profile-info");
      } else if (profile.WizardFlow === 9) {
        history.push("/role-experience");
      } else if (profile.WizardFlow === 100) {
        history.push("/name");
      } else if (profile.WizardFlow === 101) {
        history.push("/location");
      } else if (profile.WizardFlow === 102) {
        history.push("/discipline");
      } else if (profile.WizardFlow === 103) {
        history.push("/speciality");
      } else if (profile.WizardFlow === 104) {
        history.push("/mobileno");
      } else if (profile.WizardFlow === 105) {
        history.push("/explever");
      } else if (profile.WizardFlow === 106) {
        history.push("/workPlacePolicy");
      } else if (profile.WizardFlow === 107) {
        history.push("/workAuthorization");
      } else if (profile.WizardFlow === 108) {
        history.push("/license");
      } else if (profile.WizardFlow === 109) {
        history.push("/veteran");
      } else if (profile.WizardFlow === 110) {
        history.push("/resume-upload-v2");
      } else if (profile.WizardFlow === 111) {
        history.push("/any-career-gap");
      } else if (profile.WizardFlow === 112) {
        history.push("/state-lisence-doc");
      } else if (profile.WizardFlow === 113) {
        history.push("/bls-doc");
      } else if (profile.WizardFlow === 114) {
        history.push("/acls-doc");
      } else if (profile.WizardFlow === 115) {
        history.push("/social-security-doc");
      } else if (profile.WizardFlow === 116) {
        history.push("/driver-license-doc");
      } else if (profile.WizardFlow === 117) {
        history.push("/reference1-doc");
      } else if (profile.WizardFlow === 118) {
        history.push("/reference2-doc");
      } else if (profile.WizardFlow === 198) {
        history.push("/sign-educational-screen2");
      } else if (profile.WizardFlow === 199) {
        history.push("/sign_educational_screen3");
      } else if (profile.WizardFlow === 201) {
        history.push("/create-skilllisting-gig-type");
      } else if (profile.WizardFlow === 202) {
        history.push("/create-skilllisting-title");
      } else if (profile.WizardFlow === 203) {
        history.push("/create-skilllisting-skill");
      } else if (profile.WizardFlow === 204) {
        history.push("/create-skilllisting-location");
      } else if (profile.WizardFlow === 205) {
        history.push("/create-skilllisting-salary");
      } else if (profile.WizardFlow === 206) {
        history.push("/create-skilllisting-date");
      } else if (profile.WizardFlow === 207) {
        history.push("/basic-profile-success");
      } else if (localStorage.getItem("JobDetailpageUrl")) {
        history.push(localStorage.getItem("JobDetailpageUrl"));
      } else if (localStorage.getItem("redirectUrl")) {
        history.push(localStorage.getItem("redirectUrl"));
        localStorage.removeItem("redirectUrl");
      } else history.push("/dashboard");
    }
  }, [props.user.profile]);

  return (
    <div
      style={{
        display: "flex",
        justifyContent: "center",
        fontSize: "2.5rem",
        margin: "40vh 0",
        color: "#118936",
      }}
    >
      <LoadingOutlined />
    </div>
  );
};

const mapStateToProps = (state) => {
  return {
    user: state.profileReducer.user,
    error: state.authReducer.error,
    talentToken: state.authReducer.user.authToken,
    impersonationId: state.authReducer.impersonationId,
  };
};

const mapDispatchToProps = (dispatch) => {
  return {
    sendAuthToken: (params) => {
      dispatch(updateUserInfo(params)); // action dispatched
    },
    profileDetails: (params) => {
      dispatch(fetchUserProfile(params)); // action dispatched
    },
    updateImpersonationId: (talentToken) => {
      dispatch(updateImpersonationIdId(talentToken)); // action dispatched
    },
  };
};

export default connect(mapStateToProps, mapDispatchToProps)(AuthSuccess);
