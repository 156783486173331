
import React from 'react'


 const EducationalComponentThree = ({ photo, header, subheader} ) => {
    const targetWords = ['terms and conditions', 'hello@alerts-skillgigs.com'];
     // Split the subheader text at the target words
  const parts = subheader.split(new RegExp(`(${targetWords.join('|')})`, 'g'));

  return (
    <div className="box-2">
         <div className="logo-view-2">
    <div className="background-photo-3"> <img className="inner-photo" src={photo} alt="Profile" />
         
         </div> 
        
         <div className="box-headerr-2">{header}</div> 
        </div> 
         <div className="subheaderr-2">
         {parts.map((part, index) => (
          <span key={index}>
            {targetWords.includes(part) ? 
            part === "terms and conditions" ? 
            (
              <span style={{ textDecoration: 'underline', color: '#4054B2', cursor: 'pointer' }} onClick={() => window.location.href = "https://skillgigs.com//terms-of-service"}>
                {part}
              </span>
            )  
            :
            part === "hello@alerts-skillgigs.com" ? 
            (
              <span style={{ textDecoration: 'underline', color: '#4054B2', cursor: 'pointer' }} onClick={() => window.location.href = "mailto:hello@alerts-skillgigs.com"}>
                {part}
              </span>
            )  
            :
            (
              <span style={{ textDecoration: 'underline', color: '#4054B2', cursor: 'pointer' }}>
                {part}
              </span>
            ) : (
              part
            )}
          </span>
        ))}
 
         </div>
       
        </div>
  )
}

export default EducationalComponentThree;
