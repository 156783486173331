import React, { useState, useEffect, useRef } from "react";
import { connect, useSelector } from "react-redux";
import { useHistory } from "react-router";
import { useLocation } from "react-router";

import { getCookie } from "../../shared/helpers";

import "./RoleExperience.css";

import { Form, Select, Radio, Space, Button, Spin, Divider, Input } from "antd";
import SearchIcon from "../../assets/icons/ant-design-search-outlined.svg";

import {
  fetchKeySearchDiscipline,
  fetchProfileExperience,
  fetchRoleDiscipline,
  fetchUserProfile,
  formSubmitStepOne,
  saveExperienceStepOne,
  saveSearchDiscipline,
  updateProfile,
} from "../../redux/actions";

import { useUpdateLogger } from "../../shared/useUpdateLogger";

// Dicipline Stuff
import { debounce } from "../../shared/helpers";
import { PlusOutlined } from "@ant-design/icons";

const RoleExperience = (props) => {
  const history = useHistory();
  const location = useLocation();

  const authToken = getCookie("talentToken");

  const { Option } = Select;

  const submitRef = useRef(null);

  const [workExperience, SetWorkExperience] = useState([]);

  const [form] = Form.useForm();

  // d
  const [noDataView, setNoDataView] = useState("");
  const [discipline, setDiscipline] = useState("");

  const [disciplineDetect, setDisciplineDetect] = useState(false);
  const [experienceDetect, setExperienceDetect] = useState(false);

  const [dimensions, setDimensions] = useState(window.innerWidth);
  const [mobileView, setMobileView] = useState(false);

  const profession = useSelector(
    (state) => state.profileReducer.user.profile.ProfessionCode
  );

  useUpdateLogger("profession", profession);

  const checkDimensions = () => {
    setDimensions(window.innerWidth);
  };

  useEffect(() => {
    window.addEventListener("resize", checkDimensions);
    if (window.innerWidth < 768) {
      setMobileView(true);
    }
  }, []);

  function onSearch(val) {
    // 
    // 
    if (val?.length > 0) {
      setDisciplineDetect(true);
    } else {
      setDisciplineDetect(false);
    }

    setDiscipline(val);
    setNoDataView(val);
    props.sendKeyDiscipline({
      key: val,
      authToken,
      pro: profession,
    });
    // props.sendKeyDiscipline(val);
  }

  const saveSearchDiscipline = (value) => {
    // 
    setDisciplineDetect(true);
    if (dimensions < 768) {
      setMobileView(false);
    }
    if (value) {
      props.saveSearchDiscipline(value);
    }
  };

  // ADD ITEM DISCIPLINE LOGIC
  const onNameChange = (e) => {
    setDiscipline(e.target.value);
  };

  // const addItem = () => {
  //   props.searchedDiscipline.push(discipline);
  //   setDiscipline(null);
  // };

  useEffect(() => {
    if (!props.user.email) {
      const talentToken = getCookie("talentToken");
      props.profileDetails(talentToken);
    }
  }, [props.user.email]);

  useEffect(() => {
    const authToken = props.authToken;
    // fetch profile experience
    props.fetchProfileExperience();

    // fetch initial role/displine
    props.sendKeyDiscipline({
      key: null,
      authToken,
      pro: profession,
    });
  }, [props.authToken, profession]);

  useEffect(() => {
    const sortWork = props.user.workExperience.sort(
      (a, b) => a.ExperienceLevelId - b.ExperienceLevelId
    );
    SetWorkExperience(sortWork);
  }, [props.user.workExperience]);

  let defaultValues;

  useEffect(() => {
    defaultValues = {
      workExperience: props.user.profile.ExperienceLevelName
        ? `${props.user.profile.ExperienceLevelName}`
        : null,
    };
    // // 
    form.setFieldsValue(defaultValues);

    props.saveExperienceStepOne({
      ExperienceLevelId: props.user.profile.ExperienceLevelId,
      ExperienceLevelCode: props.user.profile.ExperienceLevelCode,
      ExperienceLevelName: props.user.profile.ExperienceLevelName,
    });
  }, [props.user.profile]);

  const submitForm = () => {
    submitRef.current.click();
    history.push("/resume-upload");
  };

  const onFinish = (values) => {
    // 
    const { step, dataForm, dataStep3 } = props;

    // 
    // 

    const { ExperienceLevelId, ExperienceLevelCode, ExperienceLevelName } =
      dataForm.workExperience;
    // 

    const profile = {
      ExperienceLevelId,
      ExperienceLevelCode,
      ExperienceLevelName,
      Discipline: dataStep3?.roleDiscipline
        ? dataStep3?.roleDiscipline
        : discipline,
    };
    props.updateProfile({
      userId: props.user.id,
      profile,
    });
    // 
  };

  const onFinishInMobile = (event) => {
    const { dataStep3 } = props;
    const { ExperienceLevelCode, ExperienceLevelName, ExperienceLevelId } =
      JSON.parse(event.target.value);

    const profile = {
      ExperienceLevelId,
      ExperienceLevelCode,
      ExperienceLevelName,
      Discipline: dataStep3?.roleDiscipline
        ? dataStep3?.roleDiscipline
        : discipline,
    };
    props.updateProfile({
      userId: props.user.id,
      profile,
    });
    // 
    history.push("/resume-upload");
  };

  const onFinishFailed = (errorInfo) => {
    // 
  };

  useEffect(() => {
    // 
    // 
    // 
  }, [props.user, props.dataForm, props.dataStep3]);

  useEffect(() => {
    // 
  }, [props.roleDiscipline]);

  return (
    <div className="roleExperiencePage">
      <div className="roleExperience">
        <div 
         className={`  ${
          disciplineDetect 
            ? "backButtonSkill"
            : "backButtonSkill2"
        } `}>
          <span
            onClick={() => {
              if (!mobileView && window.innerWidth < 768)
                return setMobileView(true);
              history.push("/profession");
              setDisciplineDetect(false);
              setExperienceDetect(false);
            }}
          >
            Back
          </span>
        </div>
        <section className="roleDiscipline"></section>
        <section className="experience">
          <Form
            layout="vertical"
            form={form}
            // initialValues={defaultValues}
            onFinish={onFinish}
            onFinishFailed={onFinishFailed}
          >
            {mobileView || dimensions > 768 ? (
              <>
                <Form.Item
                  name="Search Discipline"
                  label={
                    <div className="roleLabel">Select role / discipline</div>
                  }
                >
                  <Input
                    className="inputDiscipline"
                    prefix={<img src={SearchIcon} alt="SearchIcon" />}
                    onChange={debounce((e) => onSearch(e.target.value))}
                    placeholder="Find role / discipline"
                  />
                </Form.Item>
                <Form.Item>
                  <Radio.Group
                    checked={true}
                    className="radioGroupDiscipline"
                    onChange={(e) => saveSearchDiscipline(e.target.value)}
                    notFoundContent={
                      noDataView.length > 0 ? (
                        <div>
                          {props.disciplineLoader ||
                          props.roleDiscipline?.length > 0 ? (
                            <Spin size="small" />
                          ) : (
                            "No data found!"
                          )}
                        </div>
                      ) : null
                    }
                  >
                    {/* <Space direction="vertical"> */}
                    {props.roleDiscipline.length > 0 &&
                      props.roleDiscipline?.map((discipline) => {
                        return (
                          <Radio
                            className="radioDiscipline"
                            value={discipline}
                            key={discipline}
                          >
                            {discipline}
                          </Radio>
                        );
                      })}
                    {/* </Space> */}
                  </Radio.Group>
                </Form.Item>{" "}
              </>
            ) : null}

            {mobileView ? null : (
              <Form.Item
                name="workExperience"
                label={
                  <div className="selectExperience">
                    Select years of experience
                  </div>
                }
                //   rules={[
                //     {
                //       required: true,
                //       message: "Please select one Input!",
                //     },
                //   ]}
              >
                <Radio.Group
                  name="workExperience"
                  onChange={(e) => {
                    props.sendFormData(e);
                    setExperienceDetect(true);
                  }}
                >
                  <Space direction="vertical">
                    {workExperience.length &&
                      workExperience.map((exp) => (
                        <Radio
                          className="radioExperience"
                          value={JSON.stringify(exp)}
                          key={exp.ExperienceLevelName}
                          onClick={(e) => {
                            if (dimensions < 768) {
                              onFinishInMobile(e);
                            }
                          }}
                        >
                          {exp.ExperienceLevelName}
                        </Radio>
                      ))}
                  </Space>
                </Radio.Group>
              </Form.Item>
            )}

            <Form.Item style={{ display: "none" }}>
              <Button
                ref={submitRef}
                // style={{ display: "none" }}
                className="continue"
                type="submit"
                htmlType="submit"
              >
                Continue
              </Button>
            </Form.Item>
          </Form>
        </section>
        {mobileView || dimensions < 768 ? null : (
          <section className={`submitButtonRE `}>
            <div
              className={`  ${
                disciplineDetect && experienceDetect
                  ? "continue"
                  : "disablebutton"
              } `}
              onClick={() => {
                if (disciplineDetect && experienceDetect) submitForm();
              }}
            >
              Continue
            </div>
          </section>
        )}
      </div>
    </div>
  );
};

const mapStateToProps = (state) => {
  return {
    user: state.profileReducer.user,
    dataForm: state.credentialingReducer.step1,
    dataStep3: state.credentialingReducer.step3,
    searchedDiscipline: state.skillListingReducer.searchedDiscipline,
    disciplineLoader: state.skillListingReducer.disciplineLoader,
    authToken: state.authReducer.user.authToken,
    roleDiscipline: state.profileReducer.roleDiscipline,
  };
};

const mapDispatchToProps = (dispatch) => {
  return {
    sendFormData: (e) => {
      dispatch(formSubmitStepOne(e));
    },
    updateProfile: (params) => {
      dispatch(updateProfile(params));
    },
    saveExperienceStepOne: (e) => {
      dispatch(saveExperienceStepOne(e));
    },
    fetchProfileExperience: () => {
      dispatch(fetchProfileExperience());
    },
    profileDetails: (talentToken) => {
      dispatch(fetchUserProfile(talentToken)); // action dispatched
    },
    sendKeyDiscipline: (e) => {
      dispatch(fetchRoleDiscipline(e));
    },
    saveSearchDiscipline: (params) => {
      dispatch(saveSearchDiscipline(params));
    },
  };
};

export default connect(mapStateToProps, mapDispatchToProps)(RoleExperience);
