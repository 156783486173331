import React from "react";
import LeftSectionDashboard from "./Components/LeftSectionDashboard";
import RightSectionDashboard from "./Components/RightSectionDashboard";
import "./Dashboard.css";
import { useDispatch, useSelector } from "react-redux";
import { useEffect } from "react";
import {
  fetchInterview,
  fetchReceivedBidsStages,
  getNotifications,
  placeBidFetch,
  postFavoriteGigCard,
} from "../../redux/actions";
import { fetchResumeDetailInit } from "../../redux/actions";
import { alertNotification } from "../../components/notifications/alertNotification";

const Dashboard = () => {
  const dispatch = useDispatch();
  const ProfessionCode = useSelector((state) => state.profileReducer?.user?.profile?.ProfessionCode);
  const authToken = useSelector((state) => state.authReducer.user.authToken);
  const guestFavorite = JSON.parse(localStorage.getItem("Favorite"));


  useEffect(() => {
    if (localStorage.getItem("Favorite") && authToken && guestFavorite?.Professions?.ProfessionCode === ProfessionCode) {
      alertNotification("success", "gig added to favorites");
      dispatch(
        postFavoriteGigCard(
          guestFavorite?.JobOrderId,
          "Gig",
          guestFavorite?.gig
        )
      );
      localStorage.removeItem("Favorite");
    } else if (localStorage.getItem("Favorite") && authToken) {
      alertNotification(
        "error",
        "This gig does not align with your industry. Please select gigs from your industry to add to your favorites.",
        "",
        "",
        "messageErrors"
      );
      localStorage.removeItem("Favorite");
    }
  }, []);

  useEffect(() => {
    dispatch(fetchReceivedBidsStages());
    dispatch(fetchInterview());
    dispatch(getNotifications());
    dispatch(fetchResumeDetailInit());
    dispatch(placeBidFetch("most_recent", null, null, 1, 24));
  }, []);
  return (
    <div className="dashboardContainer">
      <RightSectionDashboard />
      <LeftSectionDashboard />
    </div>
  );
};

export default Dashboard;
