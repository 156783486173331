import { types } from "../action-types";

const initialState = {
  loading: false,
  emp:null,
  error: {},
};

const employerReducer = (state = initialState, action) => {
  switch (action.type) {
    case types.SET_EMPLOYER_DETAILS:
      console.log('action', action)
      return {
        ...state,
        emp:action.payload
      };      
   
    default:
      return state;
  }
};

export default employerReducer;
