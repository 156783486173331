import React ,{useState, useEffect} from 'react';
import './ProfessionalExperience.css'
import moment from 'moment';

const ProfessionalExperienceResume = ({ ResumeViewDetail }) => {
    const [NotDeleteRecords, setNotDeleteRecords] = useState([]);

    useEffect(() => {;
        setNotDeleteRecords(ResumeViewDetail("Consultants_CareerRecords"))
    }, [])

    return (
        <>
            <div className="ProfessionalExperience-cantainer">
                <div className="ViewResume-heading-section">
                    PROFESSIONAL EXPERIENCE
                </div>
                {NotDeleteRecords?.length > 0 ? NotDeleteRecords?.map((Records) => {
                    if (Records?.Deleted === true) return;
                    return (
                        <>
                            <div className="ViewResume-sub-heading-section">
                                <div className="ViewResume-sub-heading-section-Right">
                                    {Records?.Title + " @" + Records?.CompanyName}
                                </div>
                                <div className="ViewResume-sub-heading-section-Left">
                                    {Records?.StartDate ? moment(Records?.StartDate).format("MMMM DD, YYYY") : "NA"} -{" "}
                                    {Records?.EndDate
                                        ? moment(Records?.EndDate).format("MMMM DD, YYYY")
                                        : Records?.CurrentJob
                                            ? "Current"
                                            : "NA"}
                                </div>
                            </div>
                            <div className="ProfessionalExperience-Responsibilities">
                                <div className='ViewResume-sub-heading-section'>
                                    Role Description
                                </div>
                                <div className='ProfessionalExperience-Responsibilities-listing'>
                                    {Records?.Description}
                                </div>
                            </div>
                            <div className="ProfessionalExperience-Responsibilities">
                                <div className='ViewResume-sub-heading-section'>
                                    Responsibilities
                                </div>
                                <div className='ProfessionalExperience-Responsibilities-listing'>
                                    {Records?.Responsibilities}
                                </div>
                            </div>
                        </>
                    )
                }) : <div className='No_Data_Present' >NO DATA</div>}
            </div>
        </>
    );
};

export default ProfessionalExperienceResume;