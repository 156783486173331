import React, { useEffect, useRef, useState } from "react";
import { Row, Col, Button, Rate, Modal, Tooltip, Dropdown, Menu } from "antd";
import {
  InfoCircleOutlined,
  LoadingOutlined,
  StarFilled,
  StarOutlined,
} from "@ant-design/icons";
import { useHistory, useParams } from "react-router-dom";
import suitCaseIcon from "../../../../../../assets/icons/suitcase.svg";
import "./ReceivedBidsCard.css";
import moment from "moment";
import {
  retractBid,
  updateBid,
  RetractBidUpdateStageInit,
  updateGig,
  updateDeclinedReasonsReceivedBids,
  addMessageToFavourite,
  removeMessageFromFavourite,
} from "../../../../../../redux/actions";
import { useSelector, useDispatch } from "react-redux";
import { RiCheckDoubleLine } from "react-icons/ri";
import { DefaultsRating } from "../../../../../WelcomeWizard/helper";
import { HyperlinkParagraph } from "../../../../../../components/HyperlinkPara";
import CustomTooltip from "./CustomTooltip";

export const contractOptions = [
  {
    IdealContractLengthId: 1,
    IdealContractLengthName: "1 month",
    IdealContractLengthValue: 1,
  },
  {
    IdealContractLengthId: 2,
    IdealContractLengthName: "2 months",
    IdealContractLengthValue: 2,
  },
  {
    IdealContractLengthId: 3,
    IdealContractLengthName: "3 months",
    IdealContractLengthValue: 3,
  },
  {
    IdealContractLengthId: 4,
    IdealContractLengthName: "4 months",
    IdealContractLengthValue: 4,
  },
  {
    IdealContractLengthId: 5,
    IdealContractLengthName: "5 months",
    IdealContractLengthValue: 5,
  },
  {
    IdealContractLengthId: 6,
    IdealContractLengthName: "6 months",
    IdealContractLengthValue: 6,
  },
  {
    IdealContractLengthId: 7,
    IdealContractLengthName: "7 months",
    IdealContractLengthValue: 7,
  },
  {
    IdealContractLengthId: 8,
    IdealContractLengthName: "8 months",
    IdealContractLengthValue: 8,
  },
  {
    IdealContractLengthId: 9,
    IdealContractLengthName: "9 months",
    IdealContractLengthValue: 9,
  },
  {
    IdealContractLengthId: 10,
    IdealContractLengthName: "10 months",
    IdealContractLengthValue: 10,
  },
  {
    IdealContractLengthId: 11,
    IdealContractLengthName: "11 months",
    IdealContractLengthValue: 11,
  },
  {
    IdealContractLengthId: 12,
    IdealContractLengthName: "1 year",
    IdealContractLengthValue: 12,
  },
  {
    IdealContractLengthId: 13,
    IdealContractLengthName: "1+ years",
    IdealContractLengthValue: 13,
  },
  {
    IdealContractLengthId: 14,
    IdealContractLengthName: "Negotiable",
    IdealContractLengthValue: 14,
  },
  {
    IdealContractLengthId: 15,
    IdealContractLengthName: "13 weeks",
    IdealContractLengthValue: 15,
  },
  {
    IdealContractLengthId: 16,
    IdealContractLengthName: "1 Week",
    IdealContractLengthValue: 16,
  },
  {
    IdealContractLengthId: 17,
    IdealContractLengthName: "6 weeks",
    IdealContractLengthValue: 17,
  },
  {
    IdealContractLengthId: 18,
    IdealContractLengthName: "12 weeks",
    IdealContractLengthValue: 18,
  },
];

export default function ReceivedBidsCard({
  name,
  img,
  cardDetails,
  status,
  SkillListingCard,
  GigCard,
  source,
  Created,
  // favourite,
  data,
  setRetractedBy,
  records,
  SourceName,
  SourceId,
  setFavCard,
  Index,
  nonNullIndex,
}) {
  const history = useHistory();
  const dispatch = useDispatch();
  const cardRef = useRef(null);

  const [profilePic, setProfilePic] = useState();
  const [placebidModal, setplacebidModal] = useState();
  const [rejectModalVisible, setRejectModalVisible] = useState(false);
  const [candidateList, setCandidateList] = useState();
  const [rejectReason, setRejectReason] = useState(false);
  const [
    isModalVisibleBidDeclinedPlacedBid,
    setModalVisibleBidDeclinedPlacedBid,
  ] = useState(false);
  const [loading, setLoading] = useState(false);
  const [visible, setVisible] = useState(false);
  const [isDisable, setIsDisable] = useState(false);
  const [imageError, setImageError] = useState(false);
  const [aLoading, setALoading] = useState(false);
  const [bidInfotip, setBidinfoTip] = useState(false);
  const [fav, setFav] = useState(false);
  const favouriteRed = useSelector((state) => state?.offerReducer);
  const inbox = useSelector((state) => state.getChatHistoryReducer.inbox);
  useEffect(() => {
    if (favouriteRed?.loading === false) {
      setLoading(false);
    }
  }, [favouriteRed?.loading]);

  useEffect(() => {
    if (records) {
      setFav(records?.IsFavorite);
    } else {
      let id = data?.BidCard?.BidId
        ? data?.BidCard?.BidId
        : data?.ProposalCard?.ProposalId;
      let conversations = inbox?.data?.data?.body?.conversations;
      let isFavourite;
      conversations.forEach((item, index) => {
        if (item.BidId === id) {
          isFavourite = item.IsFavorite;
          setFav(isFavourite);
        }
      });
    }
  }, [records?.IsFavorite]);

  const getConversationIndex = () => {
    let id = data?.BidCard?.BidId
      ? data?.BidCard?.BidId
      : data?.ProposalCard?.ProposalId;
    let conversations = inbox?.data?.data?.body?.conversations;
    let conversationIndex;
    conversations.forEach((item, index) => {
      if (item.BidId === id) {
        conversationIndex = index;
      }
    });
    return conversationIndex;
  };
  let isFav;
  let conversations = useSelector(
    (state) =>
      state?.getChatHistoryReducer?.inbox?.data?.data?.body?.conversations
  );
  if (typeof Index !== "undefined") {
    isFav = conversations[Index]?.IsFavorite;
  } else {
    isFav = conversations[getConversationIndex()]?.IsFavorite;
  }

  useEffect(() => {
    setFav(isFav);
  }, [isFav]);

  const handleFavourite = () => {
    setLoading(!loading);
    if (!fav) {
      if (
        typeof SourceName !== "undefined" &&
        typeof SourceId !== "undefined" &&
        typeof Index !== "undefined"
      ) {
        dispatch(
          addMessageToFavourite({
            SourceName,
            SourceId,
            Index,
          })
        );
      } else {
        let sourceName = data?.BidCard?.BidId ? "Bid" : "Proposal";
        let sourceId = data?.BidCard?.BidId
          ? data?.BidCard?.BidId
          : data?.ProposalCard?.ProposalId;
        dispatch(
          addMessageToFavourite({
            SourceName: sourceName,
            SourceId: sourceId,
            Index: getConversationIndex(),
          })
        );
      }
      setFav(true);
    } else {
      if (
        typeof SourceName !== "undefined" &&
        typeof SourceId !== "undefined" &&
        typeof Index !== "undefined"
      ) {
        dispatch(
          removeMessageFromFavourite({
            SourceId,
            SourceName,
            Index,
          })
        );
      } else {
        let sourceName = data?.BidCard?.BidId ? "Bid" : "Proposal";
        let sourceId = data?.BidCard?.BidId
          ? data?.BidCard?.BidId
          : data?.ProposalCard?.ProposalId;
        dispatch(
          removeMessageFromFavourite({
            SourceName: sourceName,
            SourceId: sourceId,
            Index: getConversationIndex(),
          })
        );
      }
      setFav(false);
    }
  };

  const RetractLoading = useSelector(
    (state) => state.retractBidReducers.loading
  );
  const bidLoading = useSelector((state) => state.updateGigReducer.loading);
  const RetractSuccess = useSelector(
    (state) => state.retractBidReducers.success
  );
  const bidUpdateSuccess = useSelector(
    (state) => state.updateBidReducers.success
  );
  const bidStatus = useSelector(
    (state) => state.getChatHistoryReducer.bidStatus
  );
  let userProfile = useSelector((state) => state.profileReducer.user.profile);

  const profile = useSelector((state) => state.profileReducer.user);
  const chatDetails = useSelector((state) => state.getChatHistoryReducer.chats);

  const reasononeRef = useRef();
  const reasontwoRef = useRef();
  const reasonthreeRef = useRef();
  const reasonfourRef = useRef();
  const refTooltip = useRef();

  if (/\s/g.test(img)) {
    img.then((value) => setProfilePic(value));
  }

  const budgetOptions = [
    {
      BudgetTypeId: 3,
      BudgetTypeName: "Month",
    },
    {
      BudgetTypeId: 1,
      BudgetTypeName: "Hour",
    },
    {
      BudgetTypeId: 5,
      BudgetTypeName: "Daily",
    },
    {
      BudgetTypeId: 2,
      BudgetTypeName: "Week",
    },
    {
      BudgetTypeId: 4,
      BudgetTypeName: "Year",
    },
  ];

  const EditBidFunction = (value) => {
    setplacebidModal(value);
    history.push({
      pathname: `/edit-bid/${cardDetails?.ProposalId}`,
      state: {
        id: cardDetails?._id,
        ProposalId: cardDetails?.ProposalId,
        JobType: GigCard?.JobOrderTypes?.JobOrderTypeName,
      },
    });
  };

  var cache = {},
    character,
    e = document.createElement("div");

  const msgtxt = (html) => {
    return html?.replace(/([&][^&; ]+[;])/g, function (entity) {
      character = cache[entity];
      if (!character) {
        e.innerHTML = entity;
        if (e.childNodes[0])
          character = cache[entity] = e.childNodes[0].nodeValue;
        else character = "";
      }
      return character;
    });
  };

  function OpenModalBoxStages() {
    // setModalVisibleBidDeclinedPlacedBid(true);
    try {
      dispatch(RetractBidUpdateStageInit(cardDetails?._id));
      setVisible(false);
      setIsDisable(true);
    } catch (error) {}
  }

  useEffect(() => {
    setCandidateList([
      {
        name: data?.RecipientUser?.UserName,
        bidType: SkillListingCard?.MinimumBidType,
        desired_rate: Math.trunc(
          SkillListingCard?.MinimumBid?.$numberDecimal ??
            SkillListingCard?.MinimumBid
        ),
        experience: "Not Available",
        title: SkillListingCard?.Title,
        img: profilePic,
        id: SkillListingCard?._id,
      },
    ]);
  }, [data, profilePic]);

  // const showModal = () => {
  //   setVisible(true);
  // };

  useEffect(() => {
    setLoading(false);
    setALoading(false);
    if (
      bidStatus.includes("Declined Bid") ||
      bidStatus.includes("Accepted Bid")
    ) {
      setBidinfoTip(false);
    } else {
      if (chatDetails?.data?.body.length < 2) {
        if (cardRef.current) {
          const cardHeight = cardRef.current.offsetHeight;
          if (cardHeight > 400) {
            setBidinfoTip(false);
          } else {
            setBidinfoTip(true);
          }
        }
      } else {
        setBidinfoTip(false);
      }
    }
  }, [
    bidStatus.includes("Declined Bid") || bidStatus.includes("Accepted Bid"),
  ]);

  const AcceptCandidateBid = (status, rejectReason) => {
    setBidinfoTip(false);

    // The below comments contains an API call updating the bid not sure if it is useful in future or not.
    // dispatch(updateBid({
    //   id: data?._id,
    //   BidStatusName: "Accepted",
    //   SkillListingId: SkillListingCard._id,
    // }))
    // SkillListingCard?._id ?
    // dispatch(updateDeclinedReasonsReceivedBids(
    //   {
    //     id: data?.BidCard?._id,
    //     body: {
    //       Message: "Update bid by talent",
    //       BidStatusName: "Declined",
    //       DeclineReason: rejectReason,
    //     },
    //   }
    // )) :
    // Accepted
    status === "Accepted" ? setALoading(false) : setLoading(true);
    status === "Accepted"
      ? dispatch(
          updateGig({
            BidUpdatePayload: {
              id: data?.BidCard?.BidId,
              BidStatusName: status,
              loading: "Accept"
            },
            // payload for Accept bid
            HubSpotTrigger : {
              EventType: "Event_Bid_Accepted",
              GigID: data?.BidCard?.GigId,
              SkillListingID: data?.BidCard?.SkillListingId,
              EmployerID: data?.SenderUser?.AccountId,
              ProfileID: data?.BidCard?.ProfileId,
              Status: "Accepted"
            }
          })
        )
      : dispatch(
          updateGig({
            BidUpdatePayload: {
              id: data?.BidCard?.BidId,
              BidStatusName: status,
              DeclineReasonId: rejectReason,
              loading: "Declined"
            },
            // payload for Declined bid
            HubSpotTrigger: {
              EventType: "Event_Bid_Declined",
              GigID: data?.BidCard?.GigId,
              SkillListingID: data?.BidCard?.SkillListingId,
              EmployerID: data?.SenderUser?.AccountId,
              ProfileID: data?.BidCard?.ProfileId,
              Status: "Declined"
            }
          })
        );
    rejectModalClose();
  };

  function rejectModalClose() {
    setRejectModalVisible(false);
  }

  const getProfileImage = () => {
    if (data?.SourceTypeId === 1) {
      if (data?.RecipientUser?.Profiles?.PictureUrl?.charAt(0) === "/") {
        return `${process.env.REACT_APP_S3_URL}${data?.RecipientUser?.Profiles?.PictureUrl}`;
      } else {
        return `${process.env.REACT_APP_S3_URL}/${data?.RecipientUser?.Profiles?.PictureUrl}`;
      }
      // return `${process.env.REACT_APP_S3_URL}${data?.RecipientUser?.Profiles?.PictureUrl}`
    } else {
      if (data?.SenderUser?.Profiles?.PictureUrl?.charAt(0) === "/") {
        return `${process.env.REACT_APP_S3_URL}${data?.SenderUser?.Profiles?.PictureUrl}`;
      } else {
        return `${process.env.REACT_APP_S3_URL}/${data?.SenderUser?.Profiles?.PictureUrl}`;
      }
      // return `${process.env.REACT_APP_S3_URL}${data?.SenderUser?.Profiles?.PictureUrl}`
    }
  };

  useEffect(() => {
    if (RetractSuccess) {
      setVisible(false);
      setRetractedBy("Emp");
    }
  }, [RetractSuccess]);

  const handleCancel = () => {
    setVisible(false);
  };

  const disableHandler = () => {
    let array = bidStatus;
    let filteredArray = array.filter(
      (value) => value !== null && value !== undefined
    );
    if (
      bidStatus.includes("Declined Bid") ||
      bidStatus.includes("Accepted Bid") ||
      bidStatus.includes("Retracted Bid")
    ) {
      return true;
    }
    else if (filteredArray.length - nonNullIndex == filteredArray.length - 1) {
      return false;
    } else {
      return true;
    }
  };

  const permanentJob = data?.GigCard?.JobOrderTypes?.JobOrderTypeId === 5;

  return (
    <div className="ReceivedBidsCard" key={data?._id} ref={cardRef}>
      <Modal
        title="Retract Bid?"
        visible={visible}
        onCancel={handleCancel}
        footer={[
          <>
            <Button onClick={handleCancel} className="modal-no-btn">
              No, go back
            </Button>
            <Button onClick={OpenModalBoxStages} className="modal-ok-btn">
              {RetractLoading ? <LoadingOutlined /> : "Yes, retract bid"}
            </Button>
          </>,
        ]}
      >
        <p className="modal-paragraph">
          Do you want to retract your bid?
        </p>
      </Modal>
      {/* <PlaceBidModal
        visible={placebidModal}
        placeBidModalVisisblityHandler={EditBidFunction}
        selectedCandidates={candidateList}
        EditBid={true}
        multiBid={false}
        desired_rate={bidUpdateSuccess?.Rate ?? SkillListingCard?.MinimumBid}
        IdealConLength={bidUpdateSuccess?.IdealContractLength ?? cardDetails?.IdealContractLength}
        Description={bidUpdateSuccess?.Comments ?? cardDetails?.Comments}
        IdealStartDate={bidUpdateSuccess?.IdealStartDate ? moment(bidUpdateSuccess?.IdealStartDate).format('YYYY-MM-DD') : moment(cardDetails?.IdealStartDate).format('YYYY-MM-DD')}
        BidId={cardDetails?._id}
      /> */}
      <div
        className={
          status == "Retracted Bid" || isDisable
            ? "Retract-bid-cardCont"
            : "cardCont"
        }
      >
        <Row className="card-Header-RB">
          <div style={{ width: "100%" }}>
            <Row>
              <div
                style={{
                  display: "flex",
                  justifyContent: "space-between",
                  width: "93%",
                }}
              >
                {data?.SourceTypeId == 1 ? (
                  <div
                    className={
                      status == "Retracted Bid"
                        ? "Retratced-textBold"
                        : "textBold"
                    }
                  >
                    {" "}
                    {chatDetails?.data?.body[0].SkillListingCard?.Title ?? "Skill Listing Title"}
                  </div>
                ) : null}
              </div>
              <div
              // style={{marginLeft:'2.2rem'  }}
              >
                {data?.SourceTypeId == 1 ? (
                  <div
                    onClick={(e) => {
                      e.stopPropagation();
                      handleFavourite();
                    }}
                  >
                    {
                      // !loading ?
                      true ? (
                        !fav ? (
                          <StarOutlined
                            style={{ fontSize: "17px", color: "#aaaaaa" }}
                          />
                        ) : (
                          <StarFilled
                            style={{ fontSize: "17px", color: "#ffba08" }}
                          />
                        )
                      ) : (
                        <LoadingOutlined />
                      )
                    }
                  </div>
                ) : null}
              </div>
              <div
                className={
                  status == "Retracted Bid" ? "Retratced-textBold" : "textBold"
                }
              >
                {" "}
                {GigCard?.Title}
              </div>
              {data?.SourceTypeId == 2 ? (
                <div
                  onClick={(e) => {
                    e.stopPropagation();
                    handleFavourite();
                  }}
                >
                  {
                    // !loading ?
                    true ? (
                      fav == false ? (
                        <StarOutlined
                          style={{ fontSize: "17px", color: "#aaaaaa" }}
                        />
                      ) : (
                        <StarFilled
                          style={{ fontSize: "17px", color: "#ffba08" }}
                        />
                      )
                    ) : (
                      <LoadingOutlined />
                    )
                  }
                </div>
              ) : null}
            </Row>
            <div
              style={{ width: "100%", display: "flex", alignItems: "center" }}
            >
              <div className="exp">
                {profile?.profile?.ProfessionCode === "HC"
                  ? data?.GigCard?.FacilityName
                  : data?.GigCard?.WorkplacePolicy}
              </div>
            </div>
          </div>
          {profile?.profile?.ProfessionCode === "HC" && (
            <div
              style={{
                alignItems: "center",
                justifyContent: "center",
                width: "50%",
                marginTop: "1.3%",
              }}
            >
              <Row>
                <Rate
                  className="comp-rate-cp"
                  disabled
                  allowHalf
                  defaultValue={
                    data?.SourceTypeId == 1
                      ? parseFloat(data?.SenderUser?.Profiles?.Rating)
                        ? parseFloat(data?.SenderUser?.Profiles?.Rating)
                        : parseFloat(data?.RecipientUser?.Profiles?.Rating)
                      : parseFloat(DefaultsRating)
                  }
                ></Rate>
                <div
                  className="ratingText"
                  style={{ color: status == "Retracted Bid" && "#9e9e9e" }}
                >
                  {data?.SourceTypeId == 1
                    ? data?.SenderUser?.Profiles?.Rating
                      ? data?.SenderUser?.Profiles?.Rating
                      : data?.RecipientUser?.Profiles?.Rating
                    : DefaultsRating}
                </div>
                <div
                  className="rating-COUNT"
                  style={{ color: status == "Retracted Bid" && "#9e9e9e" }}
                >
                  {data?.SourceTypeId == 1
                    ? data?.SenderUser?.Profiles?.Review !== 0
                      ? ` (${data?.SenderUser?.Profiles?.Review}) `
                      : ""
                    : data?.RecipientUser?.Profiles?.Review !== 0
                    ? `(${data?.RecipientUser?.Profiles?.Review}) `
                    : ""}
                </div>
              </Row>
            </div>
          )}
        </Row>
        <Row
          className="card-Body-RB textRegular"
          style={{ color: status == "Retracted Bid" && "#9e9e9e" }}
        >
          <div
            className="bidRateBold"
            style={{
              color: status == "Retracted Bid" && "#9e9e9e",
              display: "flex",
              justifyContent: "space-between",
              width: "100%",
            }}
          >
            <div>{'Bid Amount'}</div>
            <div>
              {"$"}
              {data?.SourceTypeId == 1
                ? bidUpdateSuccess?.ActualRate?.$numberDecimal
                  ? bidUpdateSuccess?.ActualRate?.$numberDecimal
                  : bidUpdateSuccess?.ActualRate?.toLocaleString("en-US")
                  ? bidUpdateSuccess?.ActualRate?.toLocaleString("en-US", {
                      minimumFractionDigits: 2,
                      maximumFractionDigits: 2,
                    })
                  : bidUpdateSuccess?.Rate?.$numberDecimal
                  ? bidUpdateSuccess?.Rate?.$numberDecimal
                  : bidUpdateSuccess?.Rate?.toLocaleString("en-US")
                  ? bidUpdateSuccess?.Rate?.toLocaleString("en-US", {
                      minimumFractionDigits: 2,
                      maximumFractionDigits: 2,
                    })
                  : cardDetails?.ActualRate?.$numberDecimal
                  ? cardDetails?.ActualRate?.$numberDecimal
                  : cardDetails?.ActualRate?.toLocaleString("en-US")
                  ? cardDetails?.ActualRate?.toLocaleString("en-US", {
                      minimumFractionDigits: 2,
                      maximumFractionDigits: 2,
                    })
                  : cardDetails?.Rate?.$numberDecimal
                  ? cardDetails?.Rate?.$numberDecimal
                  : cardDetails?.Rate?.toLocaleString("en-US", {
                      minimumFractionDigits: 2,
                      maximumFractionDigits: 2,
                    })
                : bidUpdateSuccess?.Rate?.$numberDecimal
                ? bidUpdateSuccess?.Rate?.$numberDecimal
                : bidUpdateSuccess?.Rate?.toLocaleString("en-US")
                ? bidUpdateSuccess?.Rate?.toLocaleString("en-US", {
                    minimumFractionDigits: 2,
                    maximumFractionDigits: 2,
                  })
                : cardDetails?.Rate?.$numberDecimal
                ? cardDetails?.Rate?.$numberDecimal
                : cardDetails?.Rate?.toLocaleString("en-US", {
                    minimumFractionDigits: 2,
                    maximumFractionDigits: 2,
                  })}
              /
              {cardDetails?.BudgetTypeId
                ? budgetOptions?.find((o) => {
                    return o.BudgetTypeId === cardDetails?.BudgetTypeId;
                  }).BudgetTypeName
                : null}
            </div>
            {/* {cardDetails?.BudgetTypes
              ? cardDetails?.BudgetTypes?.BudgetTypeCode ??
                cardDetails?.BudgetTypes?.BudgetTypeName
              : SkillListingCard?.BudgetTypes?.BudgetTypeName ??
                SkillListingCard?.BudgetTypeCode} */}
          </div>
          {data?.GigCard?.WorkplacePolicy != "fully-remote" && (
            <Row className="flexSpace" style={{ width: "100%" }}>
              <div>Location</div>
              <div>
                {`${
                  data?.GigCard?.CityName || data?.GigCard?.Cities?.CityName
                }, ${data?.GigCard?.Regions?.RegionName}`}
              </div>
            </Row>
          )}
          {data?.ProposalCard?.IdealTermsName && (
            <Row className="flexSpace" style={{ width: "100%" }}>
              <div>Tax Terms</div>
              <div>{`${data?.ProposalCard?.IdealTermsName}`}</div>
            </Row>
          )}

          <Row className="flexSpace" style={{ width: "100%" }}>
            <div>Ideal start date</div>
            <div>
              {bidUpdateSuccess?.IdealStartDate
                ? moment(bidUpdateSuccess?.IdealStartDate).format(
                    "MMMM DD, YYYY"
                  )
                : moment(cardDetails?.IdealStartDate).format(
                    "dddd , MMMM DD, YYYY"
                  )}
            </div>
          </Row>
          {permanentJob ? null : (
            <Row className="flexSpace" style={{ width: "100%" }}>
              <div>Contract length</div>
              <div>
                {/* {bidUpdateSuccess?.IdealContractLength
                ? bidUpdateSuccess?.IdealContractLength
                    ?.IdealContractLengthValue
                : cardDetails?.IdealContractLength
                ? cardDetails.IdealContractLength?.IdealContractLengthValue
                : GigCard?.IdealContractLength?.IdealContractLengthName} */}
                {permanentJob
                  ? "Permanent"
                  : cardDetails?.IdealTermsId
                  ? contractOptions?.find((o) => {
                      return (
                        o.IdealContractLengthId === cardDetails?.IdealTermsId
                      );
                    }).IdealContractLengthName
                  : cardDetails?.IdealTerm
                  ? contractOptions?.find((o) => {
                      return o.IdealContractLengthId === cardDetails?.IdealTerm;
                    }).IdealContractLengthName
                  : null}
              </div>
              {/* <div>
              {bidUpdateSuccess?.IdealContractLength ??
                cardDetails?.IdealContractLength
                ? cardDetails?.IdealContractLength
                : GigCard?.IdealContractLengthName}
            </div> */}
            </Row>
          )}
          {/* <Row className="flexSpace" style={{ width: "100%" }}>
            <div>Job rate</div>
            <div>
              {"$"}
              {bidUpdateSuccess?.Rate?.$numberDecimal ??
                cardDetails?.Rate?.$numberDecimal ??
                cardDetails?.Rate}
              /{bidUpdateSuccess?.BudgetType ?? cardDetails?.BudgetType}
            </div>
          </Row> */}
        </Row>
        <div style={{ padding: "0px 15px 10px 15px" }}>
          <div className="Recruiters-message">Message:</div>
          <HyperlinkParagraph
            text={
              bidUpdateSuccess?.Comments
                ? bidUpdateSuccess?.Comments
                : cardDetails?.Comments
                ? cardDetails?.Comments
                : cardDetails?.Message
            }
          />
          {/* <div
            className="textRegular textR"
            style={{ color: status == "Retracted Bid" && "#9e9e9e" }}
            dangerouslySetInnerHTML={
              bidUpdateSuccess?.Comments
                ? { __html: msgtxt(bidUpdateSuccess?.Comments) }
                : {
                  __html: msgtxt(cardDetails?.Comments)
                    ? msgtxt(cardDetails?.Comments)
                    : msgtxt(cardDetails?.Message),
                }
            }
          ></div> */}
          <div
            className="time-chat"
            style={{ color: status == "Retracted Bid" && "#9e9e9e" }}
          >
            {moment(Created).format("hh:mm A")}{" "}
            {data?.SenderUser?.AccountId === userProfile?.AccountId ? (
              <RiCheckDoubleLine className="double-check-icon" />
            ) : null}
          </div>
        </div>
      </div>
      {source == 1 ? (
        <Row className="buttonsCont">
            
          <Button
            id="Decline-btn"
            className={
              status == "Retracted Bid" ||
              bidStatus.includes("Accepted Bid") ||
              bidStatus.includes("Declined Bid")
                ? "Retracted-btn"
                : "btncard"
            }
            onClick={() => {
              setRejectModalVisible(true);
              setBidinfoTip(false);
            }}
            disabled={disableHandler()}
          >
            {bidLoading === "Declined" ? <LoadingOutlined /> : "Decline"}
          </Button>
          
          <Button
              onMouseEnter={() => {
                setBidinfoTip(true);
              }}
              id="Accept-btn"
              className={
                status == "Retracted Bid" ||
                bidStatus.includes("Accepted Bid") ||
                bidStatus.includes("Declined Bid")
                ? "Retracted-btn"
                : "btncard"
              }
              onClick={() => AcceptCandidateBid("Accepted")}
              disabled={disableHandler()}
            >
              {bidLoading === "Accept" ? (
                <LoadingOutlined />
                ) : bidStatus.includes("Accepted Bid") ? (
                  <div className="whole-acc-btn">
                    <div className="Accept-btn-acc">Accept</div>
                    <span className="Accept-btn-int">(For Interview)</span>
                  </div>
                  ) : (
                  <div className="whole-acc-btn">
                    <div className="Accept-btn-acc">Accept</div>
                    <span className="Accept-btn-int">(For Interview)</span>
                  </div>
                    )}
            </Button>
          {status == "Retracted Bid" ||
            bidStatus.includes("Accepted Bid") ||
            bidStatus.includes("Declined Bid")
            ? null
            : <CustomTooltip alignmentClass="tooltip-bottom"/> }
        </Row>
      ) : (
        source == 2 && (
          <Row className="buttonsCont">
            <Button
              className={
                status == "Retracted Bid" ||
                isDisable ||
                bidStatus.includes("Accepted Bid") ||
                bidStatus.includes("Declined Bid")
                  ? "Retracted-btn"
                  : "btncard"
              }
              disabled={disableHandler()}
              onClick={() => EditBidFunction(true)}
            >
              Edit bid
            </Button>
            <Button
              className={
                status == "Retracted Bid" ||
                isDisable ||
                bidStatus.includes("Accepted Bid") ||
                bidStatus.includes("Declined Bid")
                  ? "Retracted-btn"
                  : "btncard"
              }
              disabled={disableHandler()}
              onClick={() => setVisible(true)}
            >
              {status == "Retracted Bid" ? "retracted" : "Retract bid"}
            </Button>
          </Row>
        )
      )}
      <Modal
        title="Not Interested in this bid?"
        onCancel={rejectModalClose}
        visible={rejectModalVisible}
        footer={null}
      >
        <div className="declined-bid-modal">
          <div>Let us know the reason why you're not interested</div>
          <div
            className="reject-modal-div"
            data-id="2"
            onClick={(e) => {
              setRejectReason(e.target.getAttribute("data-id"));
              reasononeRef.current.style.background = "#e7f3eb";
              reasonthreeRef.current.style.background = "#fff";
              reasontwoRef.current.style.background = "#fff";
              reasonfourRef.current.style.background = "#fff";
            }}
            ref={reasononeRef}
          >
            On assignment
          </div>
          <div
            className="reject-modal-div"
            data-id="3"
            ref={reasontwoRef}
            onClick={(e) => {
              setRejectReason(e.target.getAttribute("data-id"));
              reasontwoRef.current.style.background = "#e7f3eb";
              reasonthreeRef.current.style.background = "#fff";
              reasononeRef.current.style.background = "#fff";
              reasonfourRef.current.style.background = "#fff";
            }}
          >
            Not interested in the location
          </div>
          <div
            className="reject-modal-div"
            data-id="4"
            ref={reasonthreeRef}
            onClick={(e) => {
              setRejectReason(e.target.getAttribute("data-id"));
              reasonthreeRef.current.style.background = "#e7f3eb";
              reasontwoRef.current.style.background = "#fff";
              reasononeRef.current.style.background = "#fff";
              reasonfourRef.current.style.background = "#fff";
            }}
          >
            Estimated pay rate is too low
          </div>
          <div
            className="reject-modal-div"
            data-id="5"
            ref={reasonfourRef}
            onClick={(e) => {
              setRejectReason(e.target.getAttribute("data-id"));
              reasonthreeRef.current.style.background = "#fff";
              reasontwoRef.current.style.background = "#fff";
              reasononeRef.current.style.background = "#fff";
              reasonfourRef.current.style.background = "#e7f3eb";
            }}
          >
            Skills not a match
          </div>
          <button
            className={
              rejectReason ? "rejectModalBtn" : "rejectModalBtn_disabled"
            }
            onClick={() => {
              rejectReason && AcceptCandidateBid("Declined", rejectReason);
              // if (title == "Cancel this interview?") {
              //   dispatch(
              //     updateInterview({
              //       InterviewId: interviewId,
              //       InterviewStatus: "Interview Cancelled",
              //       Reason: rejectReason,
              //     })
              //   );
              // } else {
              //   dispatch(
              //     updateBid({
              //       id: bidId,
              //       BidStatusName: "Offer Declined",
              //       SkillListingId: SkillListing_id,
              //       DeclineReason: rejectReason,
              //     })
              //   );
              // }
            }}
          >
            {/* <LoadingOutlined />  */}
            Submit reason
          </button>
        </div>
      </Modal>
    </div>
  );
}