 
import React from 'react'


 const EducationalComponent = ({ photo, header, subheader} ) => {
    const parts = subheader.split("form or email");
     
  return (
    <div className="box-2">
         <div className="logo-view">
    <div className="background-photo"> <img className="inner-photo" src={photo} alt="Profile" />
         
         </div> 
        
         <div className="box-headerr">{header}</div> 
        </div> 
         <div className="subheaderr">
         {parts.map((part, index) => (
          index === 0 
            ? part
            : (
              <span key={index}>
                <span style={{ textDecoration: 'underline', color: '#4054B2' , cursor:"pointer" }} onClick={() => window.location.href = "https://skillgigs.com/contact-us/"}>form</span> and <span style={{ textDecoration: 'underline', color: '#4054B2', cursor:"pointer" }} onClick={() => window.location.href = "mailto:info@skillgigs.com"}>email</span>
                {part}
              </span>
            )
        ))}
 
         </div>
       
        </div>
  )
}

export default EducationalComponent;
