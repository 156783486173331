import React from "react";
import "./SkillBubbleChart.css";

const SkillBubbleChart = (props) => {
  // 
  return (
    <div className="skillChart">
      {props?.data?.map((element, index) => {
        const length = element?.SkillName?.length;
        // 
        return (
          <div
            className={`skill-${index}`}
            title={`${element.SkillName} \n ${element.Percentage}`}
          >
            <div className="name">
              <p>
                {element?.name?.length > 15 && index > 4
                  ? element.SkillName.substr(0, 15) + "..."
                  : element.SkillName}
              </p>
              <div>{element.Percentage} </div>
            </div>
          </div>
        );
      })}
    </div>
  );
};

export default SkillBubbleChart;
