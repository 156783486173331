import React from 'react';
import './OpenPositionsIT.css'

const OpenPositions = (props) => {
    const { Position } = props?.gig;
    

    return (
        <div className='OpenPositions-Cantainer' >
            <div className="Skill-PieChart-Component" >
                <div>No of Open Positions</div>
                <div>{Position ? Position : "Not disclosed" }</div>
            </div>
        </div>
    );
};

export default OpenPositions;