import React from 'react';
import GigDetailsSkillsHC from './HC-UserComponent/GigDetailsSkillsHC';
import AboutJobExtraSection from './IT-UserComponent/AboutJobExtraSection';
import moment from 'moment';
import { useState } from 'react';
import { useEffect } from 'react';


const GigDetailAboutSection = (props) => {
  const [StartDateSetter, setStartDateSetter] = useState(null);

  const { gig, location, ProfessionCode, expday, expmonth, expyear, expdate, date, day, month, year, dimensions, handleApply } = props;

  useEffect(() => {
    setStartDateSetter(Number(gig?.StartDate));
  }, [gig?.StartDate])
  const d = new Date(StartDateSetter);

  function listExperienceLevels(arr1) {
    if (Array.isArray(arr1?.ExperienceLevels)) {
      const expLvlArr = arr1?.ExperienceLevels?.map((item) => {
        return item.ExperienceLevelName;
      });
      const expLvlStr = expLvlArr.join(", ");
      return expLvlStr;
    } else {
      return arr1?.ExperienceLevels?.ExperienceLevelName;
    }
  }

  const DetailFeilds = <>
    <div className="details">
      <div>Gig type</div>
      <div>
        {gig?.JobOrderTypes?.JobOrderTypeName
          ? gig?.JobOrderTypes?.JobOrderTypeName
          : "NA"}
      </div>
    </div>
    <div className="details">
      <div>Shift</div>
      <div>{gig?.Shift ? gig?.Shift : "NA"}</div>
    </div>
    {ProfessionCode === "HC" && <> {gig?.SubModality && <div className="details">
      <div>Speciality</div>
      <div>{gig?.SubModality ? gig?.SubModality : "Not disclosed"}</div>
    </div>}
      {gig?.Modality && <div className="details">
        <div>Discipline</div>
        <div>{gig?.Modality ? gig?.Modality : "Not disclosed"}</div>
      </div>}</>}
    {<div className="details">
      <div>Years of experience</div>
      <div>
        {listExperienceLevels(gig) ?? "NA"}
      </div>
    </div>}
    {/* {ProfessionCode === "HC" && gig?.MinExperienceRequired && <div className="details">
      <div>Experience</div>
      <div>
        {gig?.MinExperienceRequired
          ? gig?.MinExperienceRequired
          : "Not disclosed"} Years
      </div>
    </div>} */}
    {ProfessionCode === "IT" && <div className="details">
      <div>Duration</div>
      <div>
        {gig?.IdealContractLength?.IdealContractLengthName
          ? gig?.IdealContractLength?.IdealContractLengthName
          : "NA"}
      </div>
    </div>}
    {ProfessionCode === "HC" && gig?.Duration && <div className="details">
      <div>Duration</div>
      <div>
        {gig?.Duration
          ? gig?.Duration
          : "NA"}
      </div>
    </div>}
    {ProfessionCode === "HC" && gig?.StartDate && <div className="details">
      <div>Start Date</div>
      <div>
        {gig?.StartDate
          ? moment(`${d}`).format("MMM DD, YYYY")
          : "NA"}
      </div>
    </div>}
    <div className="details">
      <div>Posted on</div>
      <div>
        {day}, {month} {date}, {year}
      </div>
    </div>
    <div className="details">
      <div>Expires on</div>
      <div>
        {expday}, {expmonth} {expdate}, {expyear}
      </div>
    </div>

  </>

  return (
    <>
      {dimensions > 720 ? <div className="ractangle-right">
        <div className="ractangle-right-border" >
          <div className="about-job">About the gig</div>
          {
            <div
              className="about-job-text"
            // dangerouslySetInnerHTML={{
            //   __html: gig?.Description ? gig?.Description : "<br>",
            // }}
            >
              {gig?.FacilityName?.trim()?.length > 0 || gig?.companyName?.trim()?.length > 0 &&
                gig.FacilityName != null || gig?.companyName != null &&
                gig.FacilityName !== " " || gig?.companyName != " "
                ? `${ ProfessionCode === "HC" ? gig.FacilityName : gig?.companyName}, is seeking a`
                : "Seeking a"}{" "}
              {gig.Title} for a {gig?.JobOrderTypes?.JobOrderTypeName} {ProfessionCode === "IT" ? "position" : "job" } in {location}
            </div>
          }
          {/* <div className="details">
                      {ProfessionCode === "HC" ? "Pay package" : null} */}

          {/* <div>
                    ${gig?.BudgetMin ? Number(gig?.BudgetMin) * 40 : 0}/wk
                  </div> */}

          {/* {ProfessionCode !== "IT" ? (
                        <>
                          {budgetTypeQuery === "Hourly" ? (
                            <>
                              <div
                                style={{ color: "#118936", fontWeight: "500" }}
                              >
                                ${gig?.BudgetMin ? gig?.BudgetMin : 0}/HR
                              </div>
                            </>
                          ) : (
                            <>
                              <div
                                style={{ color: "#118936", fontWeight: "500" }}
                              >
                                ${gig?.BudgetMin ? gig?.BudgetMin : 0}/WK
                              </div>
                            </>
                          )}
                        </>
                      ) : null}
                    </div> */}
          {/* {ProfessionCode === "IT" && (
                        <div className="details">
                            <div>Location</div>
                            <div>{location ? location : "NA"}</div>
                        </div>
                    )} */}
          {DetailFeilds}

          {/* {ProfessionCode === "HC" && <GigDetailsSkillsHC gig={gig} />} */}
          {<AboutJobExtraSection gig={gig} />}
          {/* {ProfessionCode === "HC" && <><Divider />
            <div className="bottom-details">
              <div
                className="bid-details-apply"
                onClick={() => handleApply(gig)}
              >
                <div>Place a bid</div>
              </div>
            </div></>} */}
        </div>
        {/* <div className="bid-details-apply">Bid to Apply</div> */}
      </div>
        : <div className="ractangle-right">
          <div className="about-job">About the gig</div>
          {
            <div
              className="about-job-text"
            // dangerouslySetInnerHTML={{
            //   __html: gig?.Description ? gig?.Description : "<br>",
            // }}
            >
              {gig?.FacilityName?.trim()?.length > 0 || gig?.companyName?.trim()?.length > 0 &&
                gig?.FacilityName != null || gig?.companyName != null &&
                gig?.FacilityName !== " " || gig?.companyName != " "
                ? `${ProfessionCode === "HC" ? gig.FacilityName : gig?.companyName}, is seeking a`
                : "Seeking a"}{" "}
              {gig?.Title} for a {gig?.JobOrderTypes?.JobOrderTypeName} {ProfessionCode === "IT" ? "position" : "job"} in {location}
              {/* {gig?.Description ? gig?.Description : " "} */}
            </div>
          }
          {/* <div className="details">
                      <div>
                        {ProfessionCode === "HC"
                          ? "Pay package"
                          : "Estimated pay package"}
                      </div> */}

          {/* <div>
                    ${gig?.BudgetMin ? Number(gig?.BudgetMin) * 40 : 0}/wk
                  </div> */}
          {/* {budgetTypeQuery === "Hourly" ? (
                        <>
                          <div className="pay-package">
                            ${gig?.BudgetMin ? gig?.BudgetMin : 0}/HR
                          </div>
                        </>
                      ) : (
                        <>
                          <div className="pay-package">
                            ${gig?.BudgetMin ? gig?.BudgetMin : 0}/WK
                          </div>
                        </>
                      )}
                    </div> */}
          {/* {ProfessionCode === "IT" && (
            <div className="details">
              <div>Location</div>
              <div>{location ? location : "NA"}</div>
            </div>
          )} */}
          {DetailFeilds}
          {/* {ProfessionCode === "HC" && <GigDetailsSkillsHC gig={gig} />} */}
          {<AboutJobExtraSection gig={gig} />}
          {/* <Divider />
                <div className="bottom-details">
                  <div className="job-details-bid">
                    <div>${gig?.BudgetMin}/WK</div>
                    <div>Pay Package</div>
                  </div>
                  <div
                    className="bid-details-apply"
                    onClick={() => handleApply(gig)}
                  >
                    <div>Bid to Apply</div>
                  </div>
                </div> */}
          {/* <div className="bid-details-apply">Bid to Apply</div> */}
        </div>
      }
    </>
  );
};

export default GigDetailAboutSection;