import React from "react";
import "./DashboardAnnalytics.css";
import { BsChevronRight } from "react-icons/bs";
import { useHistory } from "react-router-dom";
import { useSelector } from "react-redux";
import ToolTips from "../../../../components/ToolTip/ToolTips";

const DashboardAnnalytics = () => {
  const history = useHistory();
  const Detail = useSelector((state) => state.resumeDetailReducer.success);
  const profile = Detail && Detail?.user && Detail?.user?.profile;
  const receivedBidsCount = useSelector(
    (state) => state.BidsReducer?.ManageBidCountRecivedBid
  );
  const receivedBids = useSelector((state) => state.BidsReducer?.bids);
  const placedBidsTotal = useSelector((state) => state.BidsReducer?.totalCount);
  const placedBidsCount = useSelector(
    (state) => state.BidsReducer?.ManageBidCountPlaceBid
  );
  const totalInterviewCount = useSelector(
    (state) => state.interviewReducer.success
  );
  const isMobileView = window.innerWidth < 1000 ? true : false;
  const toolTipTitle = {
    skillListingView:
      "The number of times your skill listings have been viewed by potential employers.",
    skillListing:
      "The total count of skill listings created. You can create multiple skill listings to start choosing from a pool of bids placed by employers that want to hire you.",
    bidReceived:
      "The total count of bids sent by potential employers. You can always message the employers through the inbox, once you have received a bid.",
    bidPlaced:
      "The total count of bids you placed on potential employers. You can always message the employers through the inbox, once you have placed a bid.",
    Interview:
      "Your upcoming interview alerts. You can always message the employers through the inbox, if you need to reschedule or have any queries.",
  };
  return (
    <div className="dashboard-annalytics-container">
      <div
        className={`annalytics-details ${
          profile?.Views ? "" : "disableButtonDA"
        }`}
        onClick={() => {
          profile?.Views && history.push("/view-listing");
        }}
      >
        <div className="annalytics-counts-details">
          <span className="count">{profile?.Views ? profile?.Views : 0}</span>
          <span className="details">
            {profile?.Views > 1
              ? "Skill listing view(s)"
              : "Skill listing view"}
          </span>
        </div>
        {profile?.Views ? (
          <div className="annalytics-btn">
            <span className="btn-text">View</span>
            <BsChevronRight className="annalytics-arrow-icon" />
          </div>
        ) : (
          <></>
        )}
        <div className="annalyticsTooltip">
          <ToolTips
            placement={"topRight"}
            title={toolTipTitle.skillListingView}
            className={"annalyticsTooltipTopRight"}
          />
        </div>
      </div>
      <div
        className={`annalytics-details ${
          profile?.Skilllistings ? "" : "disableButtonDA"
        }`}
        onClick={() => {
          profile?.Skilllistings && history.push("/manageskilllisting");
        }}
      >
        <div className="annalytics-counts-details">
          <span className="count">
            {profile?.Skilllistings ? profile?.Skilllistings : 0}
          </span>
          <span className="details">
            {profile?.Skilllistings > 1 ? "Skill listing(s)" : "Skill listing"}
          </span>
        </div>
        {profile?.Skilllistings ? (
          <div className="annalytics-btn">
            <span className="btn-text">View</span>
            <BsChevronRight className="annalytics-arrow-icon" />
          </div>
        ) : (
          <></>
        )}
        <div className="annalyticsTooltip">
          <ToolTips
            placement={isMobileView ? "top" : "topRight"}
            title={toolTipTitle.skillListing}
            className={"annalyticsTooltipTopRight"}
          />
        </div>
      </div>
      <div
        className={`annalytics-details ${
          receivedBidsCount ? "" : "disableButtonDA"
        }`}
        onClick={() => {
          receivedBidsCount && history.push("/receivedBids");
        }}
      >
        <div className="annalytics-counts-details">
          <span className="count">
            {receivedBidsCount ? receivedBidsCount : 0}
          </span>
          <span className="details">{`${
            receivedBidsCount > 1 ? "Bid(s) received" : "Bid received"
          }`}</span>
        </div>
        {receivedBidsCount ? (
          <div className="annalytics-btn">
            <span className="btn-text">View</span>
            <BsChevronRight className="annalytics-arrow-icon" />
          </div>
        ) : (
          <></>
        )}
        <div className="annalyticsTooltip">
          <ToolTips
            placement={"topRight"}
            title={toolTipTitle.bidReceived}
            className={"annalyticsTooltipTopRight"}
          />
        </div>
      </div>
      <div
        className={`annalytics-details ${
          placedBidsCount ? "" : "disableButtonDA"
        }`}
        onClick={() => {
          placedBidsCount && history.push("/placebids");
        }}
      >
        <div className="annalytics-counts-details">
          <span className="count">
            {placedBidsTotal > 0 && placedBidsCount ? placedBidsCount : 0}
          </span>
          <span className="details">{`${
            placedBidsTotal > 1 || placedBidsCount > 1
              ? "Bid(s) placed"
              : "Bid placed"
          }`}</span>
        </div>
        {placedBidsCount ? (
          <div className="annalytics-btn">
            <span className="btn-text">View</span>
            <BsChevronRight className="annalytics-arrow-icon" />
          </div>
        ) : (
          <></>
        )}
        <div className="annalyticsTooltip">
          <ToolTips
            placement={"topRight"}
            title={toolTipTitle.bidPlaced}
            className={"annalyticsTooltipTopRight"}
          />
        </div>
      </div>
      <div
        className={`annalytics-details ${
          totalInterviewCount?.length ? "" : "disableButtonDA"
        }`}
        onClick={() => {
          totalInterviewCount?.length && history.push("/interview");
        }}
      >
        <div className="annalytics-counts-details">
          <span className="count">
            {totalInterviewCount ? totalInterviewCount?.length : 0}
          </span>
          <span className="details">{`${
            totalInterviewCount?.length > 1 ? "Interview(s)" : "Interview"
          }`}</span>
        </div>
        {totalInterviewCount?.length ? (
          <div className="annalytics-btn">
            <span className="btn-text">View</span>
            <BsChevronRight className="annalytics-arrow-icon" />
          </div>
        ) : (
          <></>
        )}
        <div className="annalyticsTooltip">
          <ToolTips
            placement={isMobileView ? "top" : "topRight"}
            title={toolTipTitle.Interview}
            className={"annalyticsTooltipTopRight"}
          />
        </div>
      </div>
    </div>
  );
};

export default DashboardAnnalytics;
