import { call, put, takeEvery, takeLatest, takeLeading } from "redux-saga/effects";
import { types } from "../action-types";
import {createRatingApi, deleteRatingAPI, updateRatingApi} from "../../API/API"
import { DeleteRatingfailure, DeleteRatingSuccess } from "../actions";

function* createRatingSaga(params) {
    // 
    try {
        const response = yield call(createRatingApi, params); 
        
        // API Backend
        yield put({type: types.CREATE_RATING_SUCCESS, payload: response.data});
      }
      catch (error) {
        yield put({type: types.CREATE_RATING_FAILURE, error});
    }
}

function* updateRatingSaga(params) {
    // 
    try {
        const response = yield call(updateRatingApi, params); 
        yield put({type: types.UPDATE_RATING_SUCCESS, payload: response.data});
      }
      catch (error) {
        yield put({type: types.UPDATE_RATING_FAILURE, error});
    }
}

function* deleteRatingSaga(payload) {
    try {
      const response = yield call(() => deleteRatingAPI(payload)); // API Backend
      // 
      if (response.status === 200) {
        yield put(DeleteRatingSuccess(response.data.user));
      } else {
        yield put(DeleteRatingfailure(response.data.error));
      }
    } catch (error) {
      // 
      yield put(DeleteRatingfailure(error));
    }
  }

export function* watchCreateRatingSaga() {
    yield takeLeading(types.CREATE_RATING_INIT, createRatingSaga);
}

export function* watchUpdateRatingSaga() {
    yield takeLeading(types.UPDATE_RATING_INIT, updateRatingSaga);
}

export function* watchDeleteRatingSaga() {
    yield takeLeading(types.DELETE_RATING_INIT, deleteRatingSaga);
}




