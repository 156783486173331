import React from "react";
import MyActivity from "./MyActivity/MyActivity";
import DashboardAnnalytics from "./MyActivity/DashboardAnnalytics";
// import TutorialVideo from "./AssessmentVideo/TutorialVideo";
import AssesmentFaqExam from "./AssessmentVideo/AssesmentExam";

const LeftSectionDashboard = () => {
  const mobileView = window.innerWidth < 1000;
  return (
    <div className="leftSectionDashboard">
      <div className="">
        <DashboardAnnalytics />
      </div>
      <MyActivity />
      {mobileView ? (
        <AssesmentFaqExam
          url={"https://skillgigs.com/frequently-asked-questions/#job-seeker"}
          labelName={"FAQ's"}
          bgColor={"#fff"}
        />
      ) : (
        <></>
      )}
      {/* {mobileView && <TutorialVideo />} */}
    </div>
  );
};

export default LeftSectionDashboard;
