import React, { useEffect, useState } from "react";

import "./EducationalScreenOne.css";
import EducationalComponent from "./EducationalComponent";
import eduPicOne from "../../assets/icons/Edu-pic-1.svg";
import eduPicTwo from "../../assets/icons/account-edu-logo.png";
import eduPicThree from "../../assets/icons/bid-edu-logo.png";
import eduPicFour from "../../assets/icons/help-edu-logo.png";
import eduPicFive from "../../assets/icons/skills-edu-logo.png";
import eduPicSix from "../../assets/icons/job-search-edu-logo.png";
import Buttnn from "./Buttnn";
import { useDispatch, useSelector } from "react-redux";
import { useHistory } from "react-router-dom/cjs/react-router-dom.min";
import BackNextSignUpButton from "../SignUp/Components/DocUpload/Signup_gap_screen/BackNextSignUpButton";
import { updateSignupProfile } from "../../redux/actions";

const EducationalScreenOne = () => {
  const history = useHistory();
  const dispatch = useDispatch();

  const [redirectionFlag, setRedirectionFlag] = useState(false);

  const loading = useSelector((state) => state.profileReducer.signUpLoading);
  const apiSuccess = useSelector(
    (state) => state.profileReducer.signupUpdateSuccess
  );

  useEffect(() => {
    if (apiSuccess && redirectionFlag) {
      setRedirectionFlag(false);
      history.push("/name");
    }
  }, [apiSuccess]);

  const boxes = [
    {
      photo: eduPicTwo,
      // backgroundScreen: eduPicOne,
      header: "Set profile",
      subheader:
        "Set up your profile, make sure your resume and all your credentials are uploaded so that you get high visibility among the top employers .",
    },
    {
      photo: eduPicFive,
      header: "Create skill listing",
      subheader:
        "Create a skill listing by choosing the gig type - perm, per diem, or contract/travel. The skill listing you create opens up the opportunities to bid on gigs with similar skill match.",
    },
    {
      photo: eduPicSix,
      header: "Search gigs",
      subheader:
        "Search for gigs based on your expertise and place bids on them. Multiple bids means multiple chances of securing the kind of job you want.",
    },
    {
      photo: eduPicThree,
      header: "Bids",
      subheader:
        "You will also receive bids from employers as well as various marketplace representatives (ie. relationship managers and market managers). These are hot gigs, respond in a timely manner to get the best results and land the job.",
    },
    {
      photo: eduPicFour,
      header: "Help & Support",
      subheader:
        "You will have support at every step with SkillGigs from automatic reach outs, email reminders, career advice, and platform assistance. When in doubt, submit a help form or email ",
    },
  ];

  return (
    <>
      <div className="mmm">
        <div className="header-edu">How to use SkillGigs?</div>
        <div className="underlinee" />
        <div className="boxes-containerrr-1">
          <div className="boxes-roww">
            {boxes.slice(0, 3).map((box, index) => (
              <EducationalComponent key={index} {...box} />
            ))}
          </div>
          <div className="boxes-roww-2 centeredd">
            {boxes.slice(3).map((box, index) => (
              <EducationalComponent key={index} {...box} />
            ))}
          </div>
        </div>
      </div>
      <div
        style={{ display: "flex", justifyContent: "center" }}
        className="bck-button-2"
      >
        <BackNextSignUpButton
          nextFunction={() => {
            setRedirectionFlag(true);
            dispatch(
              updateSignupProfile({
                WizardFlow: 100,
              })
            );
          }}
          nextBtnLoading={loading}
          backFunction={() => history.push("/profession")}
        />
      </div>
    </>
  );
};

export default EducationalScreenOne;
