import {
  addUserOnlineStatus,
  fetchInboxMessages,
  getDashboardBidsCounts,
  getNotifications,
  getTalentInfo,
  placeBidFetch,
  receivedMessages,
  updateProfile,
  updateUserOnlineStatus,
} from "../redux/actions";
import { connect, useSelector } from "react-redux";
import store from "../redux/store";
import { useEffect } from "react";
import { getCookie } from "../shared/helpers";
import io from "socket.io-client";

const BackendApiBaseUrl = process.env.REACT_APP_API_URL;
const websocketURL = process.env.REACT_APP_WEB_SOCKET_URL;
const adminWebsocketURL = process.env.REACT_APP_ADMIN_WEB_SOCKET_URL;

class WS {
  // initialize socket connection and sending a join request
  static init(UserId, objId) {
    this.ws = new WebSocket(
      //WebSocket Url
      `${websocketURL}?UserId=${UserId}`
    );

    const authToken = store.getState().authReducer?.user?.authToken;

    // Admin Web Socket ////////////////////////////////////////////////////////////////////////
    this.adminSocket = io(adminWebsocketURL, {
      extraHeaders: {
        authorization: store.getState().authReducer?.user?.authToken, // Set the authorization header
      },
    });

    this.adminSocket.on("connected", (response) => {
      
    });

    this.adminSocket.on("message", (adminResponseOnNetwork) => {
      
      if (adminResponseOnNetwork?.data?.ConversationId) {
        store.dispatch(receivedMessages({ ...adminResponseOnNetwork }));
      }

      store.dispatch(getNotifications());
      store.dispatch(
        fetchInboxMessages("all_bids", "all_conversations", null, 1, 1)
      );
    });
    /////////////////////////////////////////////////////////////////////////////////////////

    this.ws.onopen = () => {
      // 
      this.ws.send(
        JSON.stringify({
          action: "join",
          user: UserId,
        })
      );

      store.dispatch(updateProfile({ IsOnline: true }));
    };

    this.ws.onmessage = ({ data }) => {
      // 
      const messageOnNetwork = JSON.parse(data);
      

      // update count of bids
      if (messageOnNetwork?.data?.Status === "New Bid") {
        store.dispatch(getDashboardBidsCounts());
      }

      // parsed resume update
      if (messageOnNetwork?.type == "parsed-resume") {
        store.dispatch(getTalentInfo(authToken));
      }

      // online/offline statuses
      if (messageOnNetwork?.type == "online-users") {
        const { userId, IsUserOnline } = messageOnNetwork.data;
        const existingUser = store
          .getState()
          .getChatHistoryReducer.onlineUsers?.find((u) => u.userId === userId);

        if (existingUser) {
          store.dispatch(updateUserOnlineStatus(userId, IsUserOnline));
        } else {
          store.dispatch(addUserOnlineStatus([{ userId, IsUserOnline }]));
        }

        return;
      } else {
        // maintain connection replying a ping with a ping
        if (messageOnNetwork?.data?.ConversationId) {
          store.dispatch(
            receivedMessages(
              { ...JSON.parse(data) },
              { SecondarySourceTypeName: "Bid" }
            )
          );
        }
        store.dispatch(getNotifications());
        store.dispatch(
          fetchInboxMessages("all_bids", "all_conversations", null, 1, 1)
        );

        if (JSON.parse(data).action === "ping" && this.ws.readyState === 1) {
          try {
            setTimeout(() => {
              try {
                this.ws.send(JSON.stringify({ action: "ping" }));
              } catch (e) {
                
              }
            }, 5000);
          } catch (e) {
            
          }
        }
      }
    };

    this.ws.onerror = (event) => {
      
    };

    this.ws.onclose = (event) => {
      

      if (UserId) {
        setTimeout(() => {
          this.init(UserId);
          this.onMessage();
        }, 1000);
      }
    };
  }

  //close sockets
  static onClose(handler) {
    // 
    this.ws.close();
  }

  //Receive Msg
  static onMessage(handler) {
    this.ws.addEventListener("message", handler);
  }

  static sendMessage(message) {
    // You can have some transformers here.
    // Object to JSON or something else...

    
    this.ws.send(message);
  }

  static sendMessageToAdmin(message, talentId, adminId, conversationId, socketFile, attachmentFile) {
    const messageObj = {
      adminId,
      talentId,
      data: message,
      conversationId,
      sender: "Talent",
      Attachments: socketFile.length > 0 ? socketFile : null,
      HasAttachments: attachmentFile.length > 0 ? true : false,
    };

    
    this.adminSocket.emit("createMessage", messageObj);
  }

  static messageAdminReadAt(MessageGUID, accountId){
    const ReadAtObj = {
      MessageGUID,
      accountId
    }
    this?.adminSocket?.emit("messageRead", ReadAtObj);
  }
}

const mapDispatchToProps = (dispatch) => {
  return {
    saveMessage: (params) => {
      dispatch(receivedMessages(params)); // action dispatched
    },
  };
};

export default connect(mapDispatchToProps)(WS);
