import React, { useState } from "react";
import { useDispatch, useSelector } from "react-redux";

import "./ManageSkillListing.css";

import { Modal } from "antd";
import { fetchUserSkillListing } from "../../redux/actions";

const ModalDelete = (props) => {
  const dispatch = useDispatch();

  const filter = useSelector((state) => state.skillListingReducer.pubfilter);
  const page = useSelector((state) => state.skillListingReducer.page);
  const perPage = useSelector((state) => state.skillListingReducer.perPage);

  const { deleteConfirmModal, setDeleteConfirmModal, id } = props;

  const cancelConfirmModal = () => {
    setDeleteConfirmModal(false);
  };

  const deleteConfirm = (id) => {
    props.deleteSkillListing(id);
    setDeleteConfirmModal(false);
    // alert
    // dispatch(fetchUserSkillListing(filter, page, perPage));
  };

  return (
    <Modal
      centered
      width={window.innerWidth ? "350px" : "330px"}
      footer={false}
      visible={deleteConfirmModal}
      onCancel={cancelConfirmModal}
      closable={false}
      className="modalConfirmDelete"
      title={
        <div className="deleteSkillisting">Delete your skill listing?</div>
      }
    >
      <div className="areYouSureSkillisting">
        <div className="confirm">
        Do you want to delete your skill listing from your profile?
        </div>
        <div className="buttons">
          <div className="no" onClick={() => setDeleteConfirmModal(false)}>
            No , go back
          </div>
          <div className="yes" onClick={() => deleteConfirm(id)}>
            Yes , delete it
          </div>
        </div>
      </div>
    </Modal>
  );
};

export default ModalDelete;
