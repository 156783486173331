import { gql } from "@apollo/client";

export const LANDING_PAGE_QUERY = gql`
  query ($empName: String) {
    privateEmp(empName: $empName) {
      Emp {
        logo
        banner {
          mobile
          desktop
        }
        empTitle
        webUrl
        EmpAccountId
        cultureImages
        employersMission {
          title
          description
        }
        socialMedia {
          type
          link
        }
      }
      gigs {
        counts
        data {
          _id
          JobOrderId
          ProfileId
          Title
          Description
          ZipCode
          OnSite
          FixedPrice
          Rate
          ExpiryDate
          Created
          CreatedBy
          Modified
          ModifiedBy
          IsActive
          Budget
          IdealTermsId
          BudgetRangeId
          ReasonId
          BudgetMin
          BudgetMax
          VisaStatusId
          SuggestionsFrequency
          SuggestionsSent
          InternalTitleId
          ImportSource
          ExternalGigId
          ImportProcessStatus
          FacilityName
          AutoLoaded
          SourceName
          SourceId
          CityName
          RegionCode
          score
          Specialty
          Shift
          WorkplacePolicy
          VMSLink
          Duration
          companyName
          JobOrders_Skills {
            Id
            SkillPercent
            Skills {
              SkillId
              SkillCategoryId
              SkillName
              SkillCode
              SkillNameLowerCase
              __typename
            }
            __typename
          }
          JobOrders_VisaStatuses {
            VisaStatusId
            __typename
          }
          InternalGigTypes {
            InternalGigTypeId
            InternalGigTypeCode
            InternalGigTypeName
            InternalGigTypeDescription
            Created
            CreatedBy
            Modified
            ModifiedBy
            __typename
          }
          IdealContractLength {
            IdealContractLengthId
            IdealContractLengthName
            IdealContractLengthValue
            Created
            CreatedBy
            Modified
            ModifiedBy
            __typename
          }
          Professions {
            ProfessionId
            ProfessionCode
            ProfessionName
            ProfessionDescription
            Created
            CreatedBy
            Modified
            ModifiedBy
            __typename
          }
          ExperienceLevels {
            ExperienceLevelId
            ExperienceLevelCode
            ExperienceLevelName
            MinVal
            MaxVal
            SortOrder
            Created
            CreatedBy
            Modified
            ModifiedBy
            __typename
          }
          JobOrderTypes {
            JobOrderTypeId
            JobOrderTypeName
            JobOrderTypeCode
            Created
            CreatedBy
            Modified
            ModifiedBy
            __typename
          }
          Countries {
            CountryId
            CountryName
            FIPS104
            ISO2
            ISO3
            ISON
            Internet
            Capital
            MapReference
            NationalitySingular
            NationalityPlural
            Currency
            CurrencyCode
            Population
            Title
            Comment
            __typename
          }
          Regions {
            RegionId
            CountryId
            RegionName
            Code
            ADM1Code
            TimeZone
            __typename
          }
          Cities {
            CityId
            CountryId
            RegionId
            CityName
            Longitude
            Latitude
            TimeZone
            CityCode
            __typename
          }
          BudgetTypes {
            BudgetTypeId
            BudgetTypeName
            BudgetTypeCode
            Created
            CreatedBy
            Modified
            ModifiedBy
            Order
            __typename
          }
          JobOrderStatuses {
            JobOrderStatusId
            JobOrderStatusName
            JobOrderStatusCode
            Created
            CreatedBy
            Modified
            ModifiedBy
            __typename
          }
          IsFavorite
          facilityRating {
            rating
            reviews
            __typename
          }
          Skills {
            SkillName
            SkillPercent
            __typename
          }
          Location {
            CityId
            CityName
            RegionId
            RegionCode
            LocationLong
            CountryId
            Location
            LocationWithCountry
            Latitude
            Longitude
            RegionName
            CountryName
            __typename
          }
          __typename
        }
        __typename
      }
    }
  }
`;
