import React, { useState, useEffect } from "react";
import "./CertificationsAndAwards.css";

import { useDispatch, useSelector } from "react-redux";

import Loader from "../../../components/Loader/Loader";
import AddEditBox from "../../../components/AddEditBox/AddEditBox";
import {
  postCertificatesAndAwards,
  updateCertificatesAndAwards,
} from "../../../redux/actions";
import { useUpdateLogger } from "../../../shared/useUpdateLogger";

import { AiOutlineEdit } from "react-icons/ai";
import { RiDeleteBin5Fill } from "react-icons/ri";

const CertificationsAndAwards = (props) => {
  const { addNew, setAddNew } = props;
  const dispatch = useDispatch();
  const [addState, setAddState] = useState(false);
  const [updateCert, setUpdateCert] = useState(null);
  const [textArea, setTextArea] = useState("");
  const [styleEditDelete, setStyleEditDelete] = useState({ display: "none" });
  const [dimensions, setDimensions] = useState(window.innerWidth);

  const CertificationsAwards = useSelector(
    (state) => state.profileReducer.talentInfo.Certificate
  )?.filter((obj) => obj.Deleted === false);

  const loading = useSelector((state) => state.profileReducer.loadingCA);

  useUpdateLogger("CertificationsAwards", CertificationsAwards);

  const checkDimensions = () => {
    setDimensions(window.innerWidth);
  };

  useEffect(() => {
    window.addEventListener("resize", checkDimensions);
  }, []);

  const cancel = () => {
    setUpdateCert(null);
    setAddState(false);
  };

  const handleTextArea = (e) => {
    const val = e.target.value;
    setTextArea(val);

    if (updateCert) {
      if (CertificationsAwards[updateCert].Description === textArea) {
        setAddState(false);
      } else setAddState(true);
    }

    if (val.length > 0) {
      setAddState(true);
    } else setAddState(false);
  };

  // useEffect(() => {
  //   if (updateCert) setAddState(true);

  //   if (textArea.length > 0) {
  //     setAddState(true);
  //   } else {
  //     setAddState(false);
  //   }
  // }, [textArea]);

  const inputTextArea = (
    <>
      <textarea
        name="certificates"
        rows="4"
        placeholder="Description"
        className="textAreaCertificates"
        maxLength="600"
        onChange={handleTextArea}
        value={textArea}
        autoFocus
      ></textarea>
      <div style={{ marginBottom: "19px" }}></div>
    </>
  );

  const addData = () => {
    dispatch(postCertificatesAndAwards({ Description: textArea }));
    setAddNew(false);
  };

  const updateData = (i) => {
    dispatch(
      updateCertificatesAndAwards(
        { Description: textArea },
        CertificationsAwards[i]._id
      )
    );
    setUpdateCert(null);
  };

  const deleteData = (i) => {
    dispatch(
      updateCertificatesAndAwards(
        { Deleted: true, Description: CertificationsAwards[i].Description },
        CertificationsAwards[i]._id
      )
    );
  };

  return (
    <div className="certificationsAndAwards">
      <div className="certificationsAndAwardsHeader">
        <h3 className="title">Certifications & Awards</h3>
        {dimensions > 768 && !addNew && (
          <p
            className="add-title"
            onClick={() => {
              setAddNew(true);
              setUpdateCert(null);
              setTextArea("");
            }}
          >
            Add new
          </p>
        )}
      </div>
      {addNew && (
        <>
          <AddEditBox
            input={inputTextArea}
            add={() => {
              if (addState) {
                addData();
                setAddNew(false);
              }
            }}
            cancel={() => setAddNew(false)}
            addState={addState}
          />
          <div style={{ marginBottom: "20px" }}></div>
        </>
      )}

      <>
        {loading ? (
          <Loader height="2rem" scrolling={false} />
        ) : (
          <>
            {CertificationsAwards && CertificationsAwards?.length > 0 ? (
              <>
                {CertificationsAwards.map((obj, i) => (
                  <>
                    {updateCert === i ? (
                      <>
                        <AddEditBox
                          input={inputTextArea}
                          add={() => {
                            if (addState) {
                              updateData(i);
                            }
                          }}
                          cancel={cancel}
                          addState={addState}
                          addUpdate={true}
                        />
                        <div style={{ marginBottom: "20px" }}></div>
                      </>
                    ) : (
                      <div
                        onMouseEnter={(e) => {
                          if (dimensions < 768) return;
                          setStyleEditDelete({
                            display: "inline",
                            // paddingTop: "5px",
                          });
                        }}
                        onMouseLeave={(e) => {
                          setStyleEditDelete({ display: "none" });
                        }}
                      >
                        <div className="descriptionEdit">
                          <div className="description-CA">Description</div>
                          <div>
                            <span
                              // style={styleEditDelete}
                              onClick={() => {
                                setUpdateCert(i);
                                setAddState(false);
                                setAddNew(false);
                                setTextArea(
                                  CertificationsAwards[i].Description
                                );
                              }}
                            >
                              <AiOutlineEdit className="iconColorED clickable" />
                            </span>
                            <span
                              // style={styleEditDelete}
                              onClick={() => deleteData(i)}
                            >
                              <RiDeleteBin5Fill
                                className="iconColorED clickable"
                                style={{ marginLeft: "10px" }}
                              />
                            </span>
                          </div>
                        </div>
                        <div className="description-data-CA">
                          {obj.Description}
                        </div>
                      </div>
                    )}
                  </>
                ))}
              </>
            ) : (
              <>
                {!addNew && (
                  <p className="addCertificates">Add any certifications and awards you've earned</p>
                )}
              </>
            )}
          </>
        )}
      </>
    </div>
  );
};

export default CertificationsAndAwards;
