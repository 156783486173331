import React, { useState, useEffect, useRef } from "react";
import "./MobileFilters.css";
import { Button, Radio, Input, Menu, Drawer, Divider, Space, Tag } from "antd";
import { debounce, timeout } from "../../../shared/helpers";
import { useDispatch, useSelector } from "react-redux";
import { SearchOutlined } from "@ant-design/icons";
import {
  fetchKeySearchCountry,
  fetchKeySearchLocation,
  fetchKeySearchRegion,
  fetchSearchSkill,
  saveSearchLocation,
  saveSearchSkill,
  UpdateSearchFilter,
} from "../../../redux/actions";
import { state } from "@antv/g2plot/lib/adaptor/common";
import Checkbox from "antd/lib/checkbox/Checkbox";
import { AiOutlineClose } from "react-icons/ai";

const MobileFilters = (props) => {
  const {isPrivatePage, expLevelValue, removeQuery, addQuery, HCtotalCount, ITtotalCount, totalCount, expMenu, pvtFilter, selectedValues, setSelectedValues, gigValue, setGigValue } = props;
  const jobQuery = useSelector((state) => state.searchGigsReducer.jobQuery);
  const skillQuery = useSelector((state) => state.searchGigsReducer.skillQuery);
  const countryQuery = useSelector((state) => state.searchGigsReducer.countryQuery);
  const regionQuery = useSelector((state) => state.searchGigsReducer.regionQuery);
  const cityQuery = useSelector((state) => state.searchGigsReducer.cityQuery);
  const locationQuery = useSelector(
    (state) => state.searchGigsReducer.locationQuery
  );
  const minbudgetQuery = useSelector(
    (state) => state.searchGigsReducer.minbudgetQuery
  );
  const maxbudgetQuery = useSelector(
    (state) => state.searchGigsReducer.maxbudgetQuery
  );
  const budgetQuery = useSelector(
    (state) => state.searchGigsReducer.budgetQuery
  );
  const budgetTypeQuery = useSelector(
    (state) => state.searchGigsReducer.budgetTypeQuery
  );
  const ProfessionCode = useSelector(
    (state) => state.profileReducer.user.profile.ProfessionCode
  );
  const WorkplacePolicy = useSelector(
    (state) => state.searchGigsReducer.WorkplacePolicy
  );
  const authToken = useSelector(
    (state) => state.authReducer.user?.authToken,
  );
  const pageNo = useSelector((state) => state.searchGigsReducer.pageNo);
  const [jobValue, setJobValue] = useState(jobQuery);
  const [budgetMin, setBudgetMin] = useState(minbudgetQuery);
  const [budgetMax, setBudgetMax] = useState(maxbudgetQuery);
  const [budgetType, setBudgetType] = useState(budgetTypeQuery);
  const [WorkplacePolicyItem, setWorkplacePolicyItem] =
    useState(WorkplacePolicy);
  const [skillsinput, setSkillsInput] = useState(skillQuery);
  const [locationinput, setLocationInput] = useState(locationQuery);
  const reducerSkill = useSelector(
    (state) => state.skillListingReducer.skillQuery
  );
  const reducerLocation = useSelector(
    (state) => state.skillListingReducer.LocationQuery
  );

  const locations = useSelector(
    (state) => state.credentialingReducer.searchedLocation
  );
  const regions = useSelector(
    (state) => state.credentialingReducer.searchedRegion
  );

  const countries = useSelector(
    (state) => state.credentialingReducer.searchCountry
  );
  const profileDetail = useSelector(
    (state) => state.profileReducer.talentApiData
  );
  const [skillValue, setSkillValue] = useState(reducerSkill);
  const [locationValue, setLocationValue] = useState(reducerLocation);
  const [professionValue, setprofessionValue] = useState("All");
  const [noDataView, setNoDataView] = useState("");
  const [visible, setVisible] = useState(false);
  const [filterDrawerSkill, setFilterDrawerSkill] = useState(false);
  const [filterDrawerLocation, setFilterDrawerLocation] = useState(false);
  const [filterDrawerRegion, setFilterDrawerRegion] = useState(false);
  const [filterDrawerCountry, setFilterDrawerCountry] = useState(false);
  const [filterDrawerProfession, setfilterDrawerProfession] = useState(false);
  const [citiesInput, setCitiesInput] = useState(cityQuery || []);
  const [regionsInput, setRegionsInput] = useState(regionQuery || []);
  const [countriesInput, setCountriesInput] = useState(countryQuery || []);
  const PrivateEmployerList = useSelector((state) => state.searchSuggestionReducer.setPvtEmpList)
  // const [gigValue,setGigValue] = useState(null)
  // const [selectedValues, setSelectedValues] = useState([]);
  
  const skills = useSelector((state) => state.skillListingReducer.skills);
  const [isBudgetType1, setIsBudgetType1] = useState(false);
  const [isBudgetType2, setIsBudgetType2] = useState(false);
  const [validation, setValidation] = useState(false);
  const isBudgetContract = useSelector(
    (state) => state.searchGigsReducer.isBudgetContract
  );
  const isBudgetPermanent = useSelector(
    (state) => state.searchGigsReducer.isBudgetPermanent
  );
  const ProfessionCodeValue = useSelector(
    (state) => state.searchGigsReducer.ProfessionCodeValue
  );
  const dispatch = useDispatch();
  const notInitialRender = useRef(false);
  const minBudgetRef = useRef(null);
  const maxBudgetRef = useRef(null);

  useEffect(() => {
    setSkillValue(skillQuery);
  }, [skillQuery])

  // useEffect(() => {
  //   setSkillValue(skillQuery);
  //   setLocationValue(locationQuery);
  //   setBudgetMin(minbudgetQuery);
  //   setBudgetMax(maxbudgetQuery);
  //   setBudgetType(budgetTypeQuery);
  // }, [
  //   skillQuery,
  //   locationQuery,
  //   minbudgetQuery,
  //   maxbudgetQuery,
  //   budgetTypeQuery,
  // ]);

  useEffect(() => {
    if (notInitialRender.current) {
      setBudgetMin(null);
      setBudgetMax(null);
      setBudgetType(null);
      setSkillValue(null);
      setLocationValue(null);
      setJobValue(null);
      setValidation(false);
      setWorkplacePolicyItem(null);
      // minBudgetRef.current.focus();
      // minBudgetRef.current.background = "red";
      // maxBudgetRef.current.value = "A";
      // // 
    } else {
      notInitialRender.current = true;
    }
  }, [props.onClearAll]);

  useEffect(() => {
    setprofessionValue(ProfessionCodeValue)
  }, [ProfessionCodeValue])

  const onChangeSkill = (e) => {
    // 
    // dispatch(UpdateSearchFilter("skillQuery", e.target.value));
    setSkillValue(e.target.value);
    setFilterDrawerSkill(false);
    // dispatch(UpdateSearchFilter("filterDrawer", false));
  };
  const onChangeLocation = (e) => {
    // 
    // dispatch(UpdateSearchFilter("locationQuery", e.target.value));
    setLocationValue(e.target.value);
    setFilterDrawerLocation(false);
    // dispatch(UpdateSearchFilter("filterDrawer", false));
  };
  const onChangeProfession = (e) => {
    // 
    // dispatch(UpdateSearchFilter("locationQuery", e.target.value));
    setprofessionValue(e.target.value);
    setfilterDrawerProfession(false);
    // dispatch(UpdateSearchFilter("filterDrawer", false));
  };

  const getCheckedStatus = (value, filter) => {
    if (filter === "city") {
      return citiesInput.indexOf(value) > -1 ? true : false;
    } else if (filter === "region") {
      return regionsInput.indexOf(value) > -1 ? true : false;
    } else if (filter === "country") {
      return countriesInput.indexOf(value) > -1 ? true : false;
    }
    return false;
  };

  const updateFilterEntryValue = (isChecked, value, filter) => {
    if (filter === "city") {
      const entries = new Set(citiesInput);
      if (isChecked) {
        entries.add(value);
      } else {
        entries.delete(value);
      }
      setCitiesInput([...entries]);
    } else if (filter === "region") {
      const entries = new Set(regionsInput);
      if (isChecked) {
        entries.add(value);
      } else {
        entries.delete(value);
      }
      setRegionsInput([...entries]);
    } else if (filter === "country") {
      const entries = new Set(countriesInput);
      if (isChecked) {
        entries.add(value);
      } else {
        entries.delete(value);
      }
      setCountriesInput([...entries]);
    }
  };

  const onApply = (value, filter) => {
    if (
      jobValue &&
      !locationValue &&
      !skillValue &&
      !budgetType &&
      !budgetMin &&
      !budgetMax
    ) {
      // 
      // setjobQuery(value);
      dispatch(UpdateSearchFilter("jobQuery", jobValue));
      // setPageNo("1");
      dispatch(UpdateSearchFilter("pageNo", "1"));
      dispatch(UpdateSearchFilter("filterDrawer", false));
      if (jobValue === "Contract") {
        dispatch(UpdateSearchFilter("isBudgetContract", true));
        dispatch(UpdateSearchFilter("isBudgetPermanent", false));
      }
      if (jobValue === "Permanent") {
        dispatch(UpdateSearchFilter("isBudgetContract", false));
        dispatch(UpdateSearchFilter("isBudgetPermanent", true));
      }
      addQuery("jt", jobValue[0])
    }
    if (
      skillValue &&
      !jobValue &&
      !locationValue &&
      !budgetType &&
      !budgetMin &&
      !budgetMax
    ) {
      // 
      // setskillQuery(value);
      // props.UpdateSearchFilter("skillQuery", value);
      dispatch(UpdateSearchFilter("skillQuery", skillValue));
      // 
      // setPageNo("1");
      dispatch(UpdateSearchFilter("pageNo", "1"));
      dispatch(UpdateSearchFilter("filterDrawer", false));
      addQuery("sl", skillValue)
    }
    if (
      citiesInput.length 
    ) {
      // 
      // setlocationQuery(value);
      dispatch(UpdateSearchFilter("cityQuery", citiesInput));
      // 
      // setPageNo("1");
      dispatch(UpdateSearchFilter("pageNo", "1"));
      dispatch(UpdateSearchFilter("filterDrawer", false));
      // addQuery("loc", locationValue?.Location);
      addQuery("cities", citiesInput.join("~"))
    }
    if (
      regionsInput.length 
    ) {
      // 
      // setlocationQuery(value);
      dispatch(UpdateSearchFilter("regionQuery", regionsInput));
      // 
      // setPageNo("1");
      dispatch(UpdateSearchFilter("pageNo", "1"));
      dispatch(UpdateSearchFilter("filterDrawer", false));
      // addQuery("loc", locationValue?.Location);
      addQuery("regions", regionsInput.join(","))
    }
    if (
      countriesInput.length
    ) {
      // 
      // setlocationQuery(value);
      // dispatch(UpdateSearchFilter("locationQuery", locationValue));
      dispatch(UpdateSearchFilter("countryQuery", countriesInput));
      // 
      // setPageNo("1");
      dispatch(UpdateSearchFilter("pageNo", "1"));
      dispatch(UpdateSearchFilter("filterDrawer", false));
      // addQuery("loc", locationValue?.Location);
      addQuery("countries", countriesInput.join(","));
    }
    if (
      (budgetType &&
        budgetMin > 0 &&
        !budgetMax &&
        !jobValue &&
        !locationValue &&
        !skillValue) ||
      (budgetType &&
        budgetMax > 0 &&
        !budgetMin &&
        !jobValue &&
        !locationValue &&
        !skillValue)
    ) {
      // 
      dispatch(UpdateSearchFilter("minbudgetQuery", budgetMin));
      dispatch(UpdateSearchFilter("maxbudgetQuery", budgetMax));
      dispatch(UpdateSearchFilter("budgetTypeQuery", budgetType));
      dispatch(UpdateSearchFilter("budgetQuery", true));
      // setBudgetQuery(true);
      // 
      // 
      // setPageNo("1");
      dispatch(UpdateSearchFilter("pageNo", "1"));
      dispatch(UpdateSearchFilter("filterDrawer", false));
    }
    if (
      budgetType &&
      budgetMin > 0 &&
      budgetMax > 0 &&
      budgetMin < budgetMax &&
      !jobValue &&
      !locationValue &&
      !skillValue
    ) {
      // 
      dispatch(UpdateSearchFilter("minbudgetQuery", budgetMin));
      dispatch(UpdateSearchFilter("maxbudgetQuery", budgetMax));
      dispatch(UpdateSearchFilter("budgetTypeQuery", budgetType));
      dispatch(UpdateSearchFilter("budgetQuery", true));
      // 
      // 
      // setPageNo("1");
      dispatch(UpdateSearchFilter("pageNo", "1"));
      dispatch(UpdateSearchFilter("filterDrawer", false));
    }
    // if (
    //   jobValue ||
    //   skillValue ||
    //   locationValue ||
    //   (budgetType && (budgetMin > 0 || budgetMax > 0)) ||
    //   (budgetType &&
    //     budgetMin > 0 &&
    //     budgetMax > 0 &&
    //     budgetMax > 0 > budgetMin > 0)
    // ) {
    //   // 
    //   if (jobValue) {
    //     // setjobQuery(value);
    //     dispatch(UpdateSearchFilter("jobQuery", jobValue));
    //     // setPageNo("1");
    //     dispatch(UpdateSearchFilter("pageNo", "1"));
    //     dispatch(UpdateSearchFilter("filterDrawer", false));
    //     if (jobValue === "Contract") {
    //       dispatch(UpdateSearchFilter("isBudgetContract", true));
    //       dispatch(UpdateSearchFilter("isBudgetPermanent", false));
    //     }
    //     if (jobValue === "Permanent") {
    //       dispatch(UpdateSearchFilter("isBudgetContract", false));
    //       dispatch(UpdateSearchFilter("isBudgetPermanent", true));
    //     }
    //   }
    //   if (skillValue) {
    //     // setskillQuery(value);
    //     // props.UpdateSearchFilter("skillQuery", value);
    //     dispatch(UpdateSearchFilter("skillQuery", skillValue));
    //     // 
    //     // setPageNo("1");
    //     dispatch(UpdateSearchFilter("pageNo", "1"));
    //     dispatch(UpdateSearchFilter("filterDrawer", false));
    //   }
    //   if (locationValue) {
    //     // setlocationQuery(value);
    //     dispatch(UpdateSearchFilter("locationQuery", locationValue));
    //     // 
    //     // setPageNo("1");
    //     dispatch(UpdateSearchFilter("pageNo", "1"));
    //     dispatch(UpdateSearchFilter("filterDrawer", false));
    //   }
    //   dispatch(UpdateSearchFilter("minbudgetQuery", budgetMin));
    //   dispatch(UpdateSearchFilter("maxbudgetQuery", budgetMax));
    //   dispatch(UpdateSearchFilter("budgetTypeQuery", budgetType));
    //   dispatch(UpdateSearchFilter("budgetQuery", true));
    //   // 
    //   // 
    //   // setPageNo("1");
    //   dispatch(UpdateSearchFilter("pageNo", "1"));
    //   dispatch(UpdateSearchFilter("filterDrawer", false));
    // }
    if (
      (jobValue && (locationValue || skillValue)) ||
      (budgetType && (budgetMin > 0 || budgetMax > 0)) ||
      (budgetType && budgetMin > 0 && budgetMax > 0 && budgetMax > budgetMin) ||
      (skillValue && (locationValue || jobValue)) ||
      (budgetType && (budgetMin > 0 || budgetMax > 0)) ||
      (budgetType && budgetMin > 0 && budgetMax > 0 && budgetMax > budgetMin)
    ) {
      // 
      if (jobValue) {
        // setjobQuery(value);
        dispatch(UpdateSearchFilter("jobQuery", jobValue));
        // setPageNo("1");
        dispatch(UpdateSearchFilter("pageNo", "1"));
        dispatch(UpdateSearchFilter("filterDrawer", false));
        if (jobValue === "Contract") {
          dispatch(UpdateSearchFilter("isBudgetContract", true));
          dispatch(UpdateSearchFilter("isBudgetPermanent", false));
        }
        if (jobValue === "Permanent") {
          dispatch(UpdateSearchFilter("isBudgetContract", false));
          dispatch(UpdateSearchFilter("isBudgetPermanent", true));
        }
      }
      if (skillValue) {
        // setskillQuery(value);
        // props.UpdateSearchFilter("skillQuery", value);
        dispatch(UpdateSearchFilter("skillQuery", skillValue));
        // 
        // setPageNo("1");
        dispatch(UpdateSearchFilter("pageNo", "1"));
        dispatch(UpdateSearchFilter("filterDrawer", false));
        addQuery("sl", skillValue)
      }
      if (locationValue) {
        // setlocationQuery(value);
        dispatch(UpdateSearchFilter("locationQuery", locationValue));
        // 
        // setPageNo("1");
        dispatch(UpdateSearchFilter("pageNo", "1"));
        dispatch(UpdateSearchFilter("filterDrawer", false));
        // addQuery("loc", locationValue?.Location);
      }
      dispatch(UpdateSearchFilter("minbudgetQuery", budgetMin));
      dispatch(UpdateSearchFilter("maxbudgetQuery", budgetMax));
      dispatch(UpdateSearchFilter("budgetTypeQuery", budgetType));
      dispatch(UpdateSearchFilter("budgetQuery", true));
      // 
      // 
      // setPageNo("1");
      dispatch(UpdateSearchFilter("pageNo", "1"));
      dispatch(UpdateSearchFilter("filterDrawer", false));
    }
    if (
      (jobValue && skillValue && locationValue) ||
      (jobValue &&
        skillValue &&
        budgetType &&
        (budgetMin > 0 || budgetMax > 0)) ||
      (budgetType && budgetMin > 0 && budgetMax > 0 && budgetMax > budgetMin) ||
      (jobValue &&
        locationValue &&
        budgetType &&
        (budgetMin > 0 || budgetMax > 0)) ||
      (budgetType && budgetMin > 0 && budgetMax > 0 && budgetMax > budgetMin)
    ) {
      // 
      if (jobValue) {
        // setjobQuery(value);
        dispatch(UpdateSearchFilter("jobQuery", jobValue));
        // setPageNo("1");
        dispatch(UpdateSearchFilter("pageNo", "1"));
        dispatch(UpdateSearchFilter("filterDrawer", false));
        if (jobValue === "Contract") {
          dispatch(UpdateSearchFilter("isBudgetContract", true));
          dispatch(UpdateSearchFilter("isBudgetPermanent", false));
        }
        if (jobValue === "Permanent") {
          dispatch(UpdateSearchFilter("isBudgetContract", false));
          dispatch(UpdateSearchFilter("isBudgetPermanent", true));
        }
      }
      if (skillValue) {
        // setskillQuery(value);
        // props.UpdateSearchFilter("skillQuery", value);
        dispatch(UpdateSearchFilter("skillQuery", skillValue));
        // 
        // setPageNo("1");
        dispatch(UpdateSearchFilter("pageNo", "1"));
        dispatch(UpdateSearchFilter("filterDrawer", false));
        addQuery("sl", skillValue)
      }
      if (locationValue) {
        // setlocationQuery(value);
        dispatch(UpdateSearchFilter("locationQuery", locationValue));
        // 
        // setPageNo("1");
        dispatch(UpdateSearchFilter("pageNo", "1"));
        dispatch(UpdateSearchFilter("filterDrawer", false));
        addQuery("loc", locationValue?.Location);
      }
      dispatch(UpdateSearchFilter("minbudgetQuery", budgetMin));
      dispatch(UpdateSearchFilter("maxbudgetQuery", budgetMax));
      dispatch(UpdateSearchFilter("budgetTypeQuery", budgetType));
      dispatch(UpdateSearchFilter("budgetQuery", true));
      // 
      // 
      // setPageNo("1");
      dispatch(UpdateSearchFilter("pageNo", "1"));
      dispatch(UpdateSearchFilter("filterDrawer", false));
    }
    if (
      (jobValue &&
        budgetType &&
        skillValue &&
        locationValue &&
        (budgetMin > 0 || budgetMax > 0)) ||
      (budgetType && budgetMin > 0 && budgetMax > 0 && budgetMax > budgetMin)
    ) {
      // 
      if (jobValue) {
        // setjobQuery(value);
        dispatch(UpdateSearchFilter("jobQuery", jobValue));
        // setPageNo("1");
        dispatch(UpdateSearchFilter("pageNo", "1"));
        dispatch(UpdateSearchFilter("filterDrawer", false));
        if (jobValue === "Contract") {
          dispatch(UpdateSearchFilter("isBudgetContract", true));
          dispatch(UpdateSearchFilter("isBudgetPermanent", false));
        }
        if (jobValue === "Permanent") {
          dispatch(UpdateSearchFilter("isBudgetContract", false));
          dispatch(UpdateSearchFilter("isBudgetPermanent", true));
        }
      }
      if (skillValue) {
        dispatch(UpdateSearchFilter("skillQuery", skillValue));
        dispatch(UpdateSearchFilter("pageNo", "1"));
        dispatch(UpdateSearchFilter("filterDrawer", false));
        addQuery("sl", skillValue)
      }
      if (locationValue) {
        dispatch(UpdateSearchFilter("locationQuery", locationValue));
        dispatch(UpdateSearchFilter("pageNo", "1"));
        dispatch(UpdateSearchFilter("filterDrawer", false));
        addQuery("loc", locationValue?.Location);
      }
      dispatch(UpdateSearchFilter("minbudgetQuery", budgetMin));
      dispatch(UpdateSearchFilter("maxbudgetQuery", budgetMax));
      dispatch(UpdateSearchFilter("budgetTypeQuery", budgetType));
      dispatch(UpdateSearchFilter("budgetQuery", true));
      dispatch(UpdateSearchFilter("pageNo", "1"));
      dispatch(UpdateSearchFilter("filterDrawer", false));
    }
    if (WorkplacePolicyItem) {
      dispatch(UpdateSearchFilter("WorkplacePolicy", WorkplacePolicyItem));
      dispatch(UpdateSearchFilter("pageNo", "1"));
      dispatch(UpdateSearchFilter("filterDrawer", false));
    } else {
      setValidation(true);
    }
    if (professionValue) {
      dispatch(UpdateSearchFilter("filterDrawer", false));
      setValidation(false);
      dispatch(UpdateSearchFilter("ProfessionCodeValue", professionValue === "All" ? null : professionValue?.substring(0, 2)));
      dispatch(UpdateSearchFilter("pageNo", "1"));
      if (professionValue == "All") {
        removeQuery("pc");
        return;
      }
      addQuery("pc", professionValue?.substring(0, 2));
    }
    if (expLevelValue) {
      dispatch(UpdateSearchFilter("explevelQuery", expLevelValue));
      setValidation(false);
      addQuery("explevel", expLevelValue);
      dispatch(UpdateSearchFilter("filterDrawer", false));
    }
    if (selectedValues) {
      dispatch(UpdateSearchFilter("PrivateEmployerId", selectedValues));
      addQuery("pvtEmpId", JSON.stringify(selectedValues))
      setValidation(false);
      dispatch(UpdateSearchFilter("filterDrawer", false));
    }
    if (gigValue || !gigValue){
      dispatch(UpdateSearchFilter("PrivateGig", gigValue));
      setValidation(false);
      dispatch(UpdateSearchFilter("filterDrawer", false));
      addQuery("pvt", gigValue);
      if (!gigValue){
        removeQuery("pvt");
      }
    }
  };

  // GET SKILLS
  const onSkillSearch = (val) => {
    setNoDataView(val);
    dispatch(fetchSearchSkill({ val: val, profCode: ProfessionCode ? ProfessionCode : professionValue?.slice(0, 2) }));
    setSkillsInput(val);
  };
  const getSearchSkill = (value) => {
    if (value) {
      dispatch(saveSearchSkill(value));
    }
  };

  // GET LOCATIONS
  function onLocationSearch(val) {
    setNoDataView(val);
    dispatch(fetchKeySearchLocation(val));
  }
  // GET COUNTRY
  function onCountrySearch(val) {
    setNoDataView(val);
    dispatch(fetchKeySearchCountry(val));
  }

  // GET REGION
  function onRegionSearch(val) {
    setNoDataView(val);
    dispatch(fetchKeySearchRegion(val));
  }

  const getSearchLocation = (value) => {
    if (value) {
      dispatch(saveSearchLocation(value));
    }
  };

  // Filter Drawer
  const handleFilterDrawerSkill = (state) => {
    setFilterDrawerSkill(state);
    setVisible(false);
  };
  const handleFilterDrawerprofession = (state) => {
    setfilterDrawerProfession(state);
    setVisible(false);
  };
  const handleFilterDrawerLocation = (state) => {
    setFilterDrawerLocation(state);
    setVisible(false);
  };
  const handleFilterDrawerRegion = (state) => {
    setFilterDrawerRegion(state);
    setVisible(false);
  };
  const handleFilterDrawerCountry = (state) => {
    setFilterDrawerCountry(state);
    setVisible(false);
  };
  const mobileFilterProfession = () => {
    if (window.innerWidth < 768) handleFilterDrawerprofession(!filterDrawerProfession);
    setVisible(true);
  };
  const mobileFilterSkill = () => {
    if (window.innerWidth < 768) handleFilterDrawerSkill(!filterDrawerSkill);
    setVisible(true);
  };
  const mobileFilterLocation = () => {
    if (window.innerWidth < 768)
      handleFilterDrawerLocation(!filterDrawerLocation);
    setVisible(true);
  };
  const mobileFilterRegion = () => {
    if (window.innerWidth < 768)
      handleFilterDrawerRegion(!filterDrawerRegion);
    setVisible(true);
  };

  const mobileFilterCountry = () => {
    if (window.innerWidth < 768)
      handleFilterDrawerCountry(!filterDrawerCountry);
    setVisible(true);
  };
  const closeFilterDrawer = () => {
    setFilterDrawerSkill(false);
    setFilterDrawerLocation(false);
    setFilterDrawerRegion(false);
    setFilterDrawerCountry(false);
    setfilterDrawerProfession(false);
  };
  const handleGigType = (value) => {
    setGigValue(value);
  };

  const skillMenu = (
    <Menu onClick={null} className="s-result-mobile">
      <Input
        placeholder="Find a skill"
        prefix={<SearchOutlined />}
        defaultValue={skillValue}
        // style={{ width: 200 }}
        className="skill-search-mobile"
        onChange={debounce((e) => onSkillSearch(e.target.value))}
      />

      <Menu>
        {skills &&
          skills.map((skill) => {
            return (
              <Menu.Item onClick={(val) => getSearchLocation(val)}>
                <div>
                  <span
                  // className={skill === receivedBidSort ? "greenTextRB" : null}
                  >
                    <Radio.Group onChange={onChangeSkill} value={skillValue} style={{ display: "block" }}>
                      <Radio value={skill?.SkillName} className="Search-jobs">
                        <span style={{
                          color: "#3e3e3e",
                          whiteSpace: "normal",
                        }}>
                          {skill?.SkillName}
                        </span>
                      </Radio>
                    </Radio.Group>
                  </span>
                </div>
              </Menu.Item>
            );
          })}
      </Menu>
    </Menu>
  );

  const cityMenu = (
    <Menu
      onClick={null}
      className="s-result-mobile"
    >
      <Input
        placeholder="Find city"
        prefix={<SearchOutlined />}
        // value={locationValue}
        // style={{ width: 200 }}
        className="skill-search"
        onChange={debounce((e) => onLocationSearch(e.target.value))}
      />

      <div className={`location-lists-container ${countries?.length > 9 ? 'show-scrollbar' : ''}`}
      >
        <Menu>
          {citiesInput.length > 0 && citiesInput.map((value) => {
            return (<Menu.Item>
              <div className="sortItem-bids">
                <span
                // className={skill === receivedBidSort ? "greenTextRB" : null}
                >
                  <Checkbox
                    checked={getCheckedStatus(value, "city")}
                    onChange={(e) =>
                      updateFilterEntryValue(
                        e.target.checked,
                        e.target.value,
                        "city"
                      )
                    }
                    value={value}
                  >
                    {" "}
                    {value}
                  </Checkbox>
                </span>
              </div>
            </Menu.Item>)
          })}
          {locations.length > 0 &&
            locations.map((city) => {
              if (citiesInput.indexOf(city.Location) === -1) {
                return (
                  <Menu.Item>
                    <div className="sortItem-bids">
                      <span
                      // className={skill === receivedBidSort ? "greenTextRB" : null}
                      >
                        <Checkbox
                          checked={getCheckedStatus(city?.Location, "city")}
                          onChange={(e) =>
                            updateFilterEntryValue(
                              e.target.checked,
                              e.target.value,
                              "city"
                            )
                          }
                          value={city?.Location}
                        >
                          {" "}
                          {city?.Location}
                        </Checkbox>
                      </span>
                    </div>
                  </Menu.Item>
                )
              }
            })}
        </Menu>
      </div>
      {validation === "cities" ? (
        <div className="search-results-filter-validation-text-location">
          Please select city
        </div>
      ) : null}
      {/* <ClearAll value={countriesInput} filter={"countryMenu"} /> */}
    </Menu>
  );

  const regionMenu = (
    <Menu
      onClick={null}
      className={
        validation === "regions" ? "s-result-invalid-location" : "s-result"
      }
    >
      <Input
        placeholder="Find state"
        prefix={<SearchOutlined />}
        // value={locationValue}
        // style={{ width: 200 }}
        className="skill-search"
        onChange={debounce((e) => onRegionSearch(e.target.value))}
      />

      <div className={`location-lists-container ${countries?.length > 9 ? 'show-scrollbar' : ''}`}
      >
        <Menu>
          {regionsInput.length > 0 && regionsInput.map((value) => {
            return (<Menu.Item>
              <div className="sortItem-bids">
                <span
                // className={skill === receivedBidSort ? "greenTextRB" : null}
                >
                  <Checkbox
                    checked={getCheckedStatus(value, "region")}
                    onChange={(e) =>
                      updateFilterEntryValue(
                        e.target.checked,
                        e.target.value,
                        "region"
                      )
                    }
                    value={value}
                  >
                    {" "}
                    {value}
                  </Checkbox>
                </span>
              </div>
            </Menu.Item>)
          })}
          {regions.length > 0 &&
            regions.map((city) => {
              if (regionsInput.indexOf(city.RegionName) === -1) {
                return (
                  <Menu.Item>
                    <div className="sortItem-bids">
                      <span
                      // className={skill === receivedBidSort ? "greenTextRB" : null}
                      >
                        <Checkbox
                          checked={getCheckedStatus(city?.RegionName, "region")}
                          onChange={(e) =>
                            updateFilterEntryValue(
                              e.target.checked,
                              e.target.value,
                              "region"
                            )
                          }
                          value={city?.RegionName}
                        >
                          {" "}
                          {city?.RegionName}
                        </Checkbox>
                      </span>
                    </div>
                  </Menu.Item>
                )
              }
            })}
        </Menu>
      </div>
      {validation === "regions" ? (
        <div className="search-results-filter-validation-text-location">
          Please select state
        </div>
      ) : null}
      {/* <ClearAll value={countriesInput} filter={"countryMenu"} /> */}
    </Menu>
  );

  const countryMenu = (
    <Menu
      onClick={null}
      className={
        validation === "countries" ? "s-result-invalid-location" : "s-result"
      }
    >
      <Input
        placeholder="Find country"
        prefix={<SearchOutlined />}
        // value={locationValue}
        // style={{ width: 200 }}
        className="skill-search"
        onChange={debounce((e) => onCountrySearch(e.target.value))}
      />

      <div className={`location-lists-container ${countries?.length > 9 ? 'show-scrollbar' : ''}`}
      >
        <Menu>
          {countriesInput.length > 0 && countriesInput.map((value) => {
            return (<Menu.Item>
              <div className="sortItem-bids">
                <span
                // className={skill === receivedBidSort ? "greenTextRB" : null}
                >
                  <Checkbox
                    checked={getCheckedStatus(value, "country")}
                    onChange={(e) =>
                      updateFilterEntryValue(
                        e.target.checked,
                        e.target.value,
                        "country"
                      )
                    }
                    value={value}
                  >
                    {" "}
                    {value}
                  </Checkbox>
                </span>
              </div>
            </Menu.Item>)
          })}
          {countries.length > 0 &&
            countries.map((city) => {
              if (countriesInput.indexOf(city.CountryName) === -1) {
                return (
                  <Menu.Item>
                    <div className="sortItem-bids">
                      <span
                      // className={skill === receivedBidSort ? "greenTextRB" : null}
                      >
                        <Checkbox
                          checked={getCheckedStatus(city?.CountryName, "country")}
                          onChange={(e) =>
                            updateFilterEntryValue(
                              e.target.checked,
                              e.target.value,
                              "country"
                            )
                          }
                          value={city?.CountryName}
                        >
                          {" "}
                          {city?.CountryName}
                        </Checkbox>
                      </span>
                    </div>
                  </Menu.Item>
                )
              }
            })}
        </Menu>
      </div>
      {validation === "countries" ? (
        <div className="search-results-filter-validation-text-location">
          Please select country
        </div>
      ) : null}
      {/* <ClearAll value={countriesInput} filter={"countryMenu"} /> */}
    </Menu>
  );

  const handleCheckboxChange = (value, isChecked) => {
    if (isChecked) {
      setSelectedValues([...selectedValues, value?._id?.AccountId]);
    } else {
      setSelectedValues(selectedValues.filter(item => item !== value?._id?.AccountId));
    }
  };

  const ProfessionList = ["All", "HC - Healthcare", "IT - Information Technology"];
  const ProfessionMenu = (
    <Menu onClick={null} className="s-result-mobile">
      <Menu>
        {ProfessionList &&
          ProfessionList.map((data) => {
            return (
              <Menu.Item onClick={null}>
                <div>
                  <span
                  >
                    <Radio.Group
                      onChange={onChangeProfession}
                      value={
                        professionValue ?
                          professionValue === "HC"
                            ? ProfessionList[1] :
                            professionValue === "IT" ?
                              ProfessionList[2] :
                              professionValue :
                          "All"
                      }
                    >
                      <Radio value={data} className="Search-jobs">
                        <span style={{ color: "#3e3e3e" }}>
                          {data}
                          {data === "All" ? ` (${totalCount})` : data === "HC - Healthcare" ? ` (${HCtotalCount})` : ` (${ITtotalCount})`}
                        </span>
                      </Radio>
                    </Radio.Group>
                  </span>
                </div>
              </Menu.Item>
            );
          })}
      </Menu>
    </Menu>
  );
  const budgetTypeClick1 = () => {
    setJobValue(["Contract", "Per Diem"]);
    setValidation(false);
  };
  const budgetTypeClick2 = () => {
    dispatch(UpdateSearchFilter("isBudgetContract", false));
    setJobValue(["Permanent"]);
    setValidation(false);
  };
  const onChangeBudget = (e) => {
    setBudgetType(e.target.value);
  };
  const onChangeWorkplace = (e) => {
    setWorkplacePolicyItem(e.target.value);
  };
  const ITFilter = ["Fully remote", "Hybrid", "On-premise"];
  const handleNumberInput = (value) => value.replace(/\D/g, "");
  return (
    <div className="Mobile-filter-container">
      <div className="job-type job-type-gig-type-mobile">Gig type</div>
      <div className="mobile-filter">
        <div
          className={
            validation
              ? "left-invalid"
              : jobValue && jobValue[0] === "Contract"
                ? "left-active"
                : "left"
          }
          onClick={budgetTypeClick1}
        >
          <div className="text" onClick={budgetTypeClick1}>
            Contract
          </div>
        </div>
        <div
          className={
            validation
              ? "left-invalid"
              : jobValue && jobValue[0] === "Permanent"
                ? // ||isBudgetPermanent ||
                //   jobQuery === "Permanent"
                "left-active"
                : "left"
          }
          onClick={budgetTypeClick2}
        >
          <div className="text">Permanent</div>
        </div>
      </div>
      {validation ? (
        <div className="search-results-filter-mobile-validation-text">
          Please select any of the gig type
        </div>
      ) : null}

      {/* {ProfessionCode !== "IT" ? (
        <>
          <div className="job-type">Budget</div>
          {jobValue || isBudgetContract || isBudgetPermanent || jobQuery ? (
            <>
              <Radio.Group
                onChange={onChangeBudget}
                value={budgetType}
                defaultValue={budgetTypeQuery}
              >
                <Radio value={"Hourly"} className="Search-jobs">
                  Hourly
                </Radio>
                <Radio value={"Weekly"} className="Search-jobs">
                  Weekly
                </Radio>
              </Radio.Group>
            </>
          ) : null}
          <div className="input-container">
            <Input
              prefix="$"
              className={validation ? "input-left-invalid" : "input-left"}
              // placeholder="50"
              onChange={(e) => setBudgetMin(handleNumberInput(e.target.value))}
              // value={minbudgetQuery}
              ref={minBudgetRef}
              value={budgetMin}
            />
            <div className="dash">-</div>
            <Input
              prefix="$"
              className={validation ? "input-right-invalid" : "input-right"}
              // placeholder="60"
              onChange={(e) => setBudgetMax(handleNumberInput(e.target.value))}
              ref={maxBudgetRef}
              value={budgetMax}
            />
          </div>
          {validation ? (
            <div className="search-results-filter-mobile-validation-text">
              Please enter budget
            </div>
          ) : null}
        </>
      ) : null} */}

      {/* LOCATION AND SEARCH INPUTS */}
      {!authToken && !isPrivatePage && <><div className="job-type">Profession</div>
        <Input
          placeholder="Find a skill"
          value={
            professionValue ?
              professionValue === "HC"
                ? ProfessionList[1] :
                professionValue === "IT" ?
                  ProfessionList[2] :
                  professionValue :
              "All"
          }
          // style={{ width: 200 }}
          className={
            validation ? "skill-search-mobile SkillMobile-input-box" : "skill-search-mobile SkillMobile-input-box"
          }
          // onChange={debounce((e) => onSkillSearch(e.target.value))}
          onClick={mobileFilterProfession}
        /></>}
      <div className="job-type">Skill</div>
      <Input
        placeholder="Find a skill"
        value={skillValue}
        // style={{ width: 200 }}
        className={
          validation ? "skill-search-mobile-invalid SkillMobile-input-box" : "skill-search-mobile SkillMobile-input-box"
        }
        // onChange={debounce((e) => onSkillSearch(e.target.value))}
        onClick={mobileFilterSkill}
      />
      {validation ? (
        <div className="search-results-filter-mobile-validation-text">
          Please select skill
        </div>
      ) : null}
      {/* country Filter */}
      <div className="job-type">Country</div>
      <div
        placeholder="Add your preferred countries"
        // value={
        //   locationValue ? locationValue?.Location : locationQuery?.Location
        // }
        // style={{ width: 200 }}
        className={
          validation ? "skill-search-mobile-invalid" : "location-box"
        }
        // onChange={debounce((e) => onSkillSearch(e.target.value))}
        onClick={mobileFilterCountry}
      >
        {countriesInput.length === 0 ? <span className="input-placeholder">Find country</span>
          : countriesInput.map((value) => {
            return <Tag className="tag1" >
              {value}
              <span
                style={{ cursor: "pointer" }}
                onClick={(e) => {
                  e.stopPropagation();
                  if (countriesInput.length > 1) {
                    const uv = countriesInput.filter((v) => v !== value);
                    setCountriesInput(uv);
                  } else {
                    setCountriesInput([]);
                    dispatch(UpdateSearchFilter("countryQuery", []));
                    removeQuery("countries");
                  }
                }}
              >
                {" "}
                <AiOutlineClose /> {" "}
              </span>
            </Tag>
          })}
      </div>
      {validation ? (
        <div className="search-results-filter-mobile-validation-text">
          Please select country
        </div>
      ) : null}

      {/* state filter */}
      <div className="job-type">State</div>
      <div
        placeholder="Add your preferred states"

        // style={{ width: 200 }}
        className={
          validation ? "skill-search-mobile-invalid" : "location-box"
        }
        // onChange={debounce((e) => onSkillSearch(e.target.value))}
        onClick={mobileFilterRegion}
      >{regionsInput.length === 0 ? <span className="input-placeholder">Find state</span>
        : regionsInput.map((value) => {
          return <Tag className="tag1" >
            {value}
            <span
              style={{ cursor: "pointer" }}
              onClick={(e) => {
                e.stopPropagation();
                if (regionsInput.length > 1) {
                  const uv = regionsInput.filter((v) => v !== value);
                  setRegionsInput(uv);
                } else {
                  setRegionsInput([]);
                  dispatch(UpdateSearchFilter("regionQuery", []));
                  removeQuery("regions");
                }
              }}
            >
              {" "}
              <AiOutlineClose /> {" "}
            </span>
          </Tag>
        })}
      </div>
      {validation ? (
        <div className="search-results-filter-mobile-validation-text">
          Please select state
        </div>
      ) : null}

      {/* city Filter */}
      <div className="job-type">City</div>
      <div
        placeholder="Add your preferred cities"
        value={
          locationValue ? locationValue?.Location : locationQuery?.Location
        }
        // style={{ width: 200 }}
        className={
          validation ? "skill-search-mobile-invalid" : "location-box"
        }
        // onChange={debounce((e) => onSkillSearch(e.target.value))}
        onClick={mobileFilterLocation}
      >{citiesInput.length === 0 ? <span className="input-placeholder">Find city</span>
        : citiesInput.map((value) => {
          return <Tag className="tag1" >
            {value}
            <span
              style={{ cursor: "pointer" }}
              onClick={(e) => {
                e.stopPropagation();
                if (citiesInput.length > 1) {
                  const uv = citiesInput.filter((v) => v !== value);
                  setCitiesInput(uv);
                } else {
                  setCitiesInput([]);
                  dispatch(UpdateSearchFilter("cityQuery", []));
                  removeQuery("cities");
                }
              }}
            >
              {" "}
              <AiOutlineClose /> {" "}
            </span>
          </Tag>
        })}
      </div>
      {validation ? (
        <div className="search-results-filter-mobile-validation-text">
          Please select city
        </div>
      ) : null}

      {ProfessionCode === "IT" ? (
        <>
          <div className="job-type">Workplace policy</div>
          <Radio.Group
            className="radioSortReceivedBidsGroupFilter"
            onChange={(e) => onChangeWorkplace(e)}
            value={WorkplacePolicyItem}
            defaultValue={WorkplacePolicy}
          >
            <Space direction="vertical">
              {ITFilter.map((dataIT) => {
                return (
                  <>
                    <Radio
                      className={
                        "radioSortReceivedBidsFilter " +
                        (validation
                          ? "MobileFilter-Search-input-left-invalid"
                          : null)
                      }
                      value={dataIT}
                    >
                      <span className="SearchRadioButtonColor">{dataIT}</span>
                    </Radio>
                  </>
                );
              })}
            </Space>
          </Radio.Group>
        </>
      ) : null}
      <div className="mobile-filter">
        {/* <Space direction="vertical">
                <Space wrap> */}
        <Drawer
          title="Find a skill"
          placement="top"
          onClose={closeFilterDrawer}
          visible={filterDrawerSkill}
          className="SearchfilterDrawer-mobile Skill-Selecter-filter"
          height="40rem"
          width="100%"
        >
          {skillMenu}
        </Drawer>
      </div>
      <div className="mobile-filter">
        {/* <Space direction="vertical">
                <Space wrap> */}
        <Drawer
          title="Select city"
          placement="top"
          onClose={closeFilterDrawer}
          visible={filterDrawerLocation}
          className="SearchfilterDrawer-mobile Skill-Selecter-filter"
          height="40rem"
          width="100%"
        // footer="Hello"
        >
          {cityMenu}
        </Drawer>
      </div>
      <div className="mobile-filter">
        {/* <Space direction="vertical">
                <Space wrap> */}
        <Drawer
          title="Select state"
          placement="top"
          onClose={closeFilterDrawer}
          visible={filterDrawerRegion}
          className="SearchfilterDrawer-mobile Skill-Selecter-filter"
          height="40rem"
          width="100%"
        // footer="Hello"
        >
          {regionMenu}
        </Drawer>
      </div>

      <div className="mobile-filter">
        {/* <Space direction="vertical">
                <Space wrap> */}
        <Drawer
          title="Select country"
          placement="top"
          onClose={closeFilterDrawer}
          visible={filterDrawerCountry}
          className="SearchfilterDrawer-mobile Skill-Selecter-filter"
          height="40rem"
          width="100%"
        // footer="Hello"
        >
          {countryMenu}
        </Drawer>
      </div>
      <div className="mobile-filter">
        {/* <Space direction="vertical">
                <Space wrap> */}
        <Drawer
          title="Select Profession"
          placement="top"
          onClose={closeFilterDrawer}
          visible={filterDrawerProfession}
          className="SearchfilterDrawer-mobile Skill-Selecter-filter"
          height="40rem"
          width="100%"
        // footer="Hello"
        >
          {ProfessionMenu}
        </Drawer>
      </div>
      {/* <div>
        <Divider />
      </div> */}
      <div className="job-type job-mobile-experience">Experience level</div>
      {expMenu}
      {validation ? (
        <div className="search-results-filter-mobile-validation-text">
          Please select any experience levels
        </div>
      ) : null}

      {profileDetail?.talentPrivate && authToken && <><div style={{ fontWeight: "500", fontSize: "14px", color: "#1E1E1E" }}>All Gigs</div>
      <Radio.Group
        className="radioSortReceivedBidsGroupFilter"
        onChange={(e) => {
          handleGigType(e.target.value);
        }}
      // value={}
        defaultValue={gigValue}
      >
        <Space direction="vertical" >
          <Radio
            className={
              `radioSortReceivedBidsFilter ${validation
                ? "MobileFilter-Search-input-left-invalid"
                : null}`
            }
            value={false}

          >
            <div style={{ display: "flex", justifyContent: "space-between", width: `${Math.min(window.innerWidth - 20, 500)}px` }}>
              <div style={{ width: `${Math.min((window.innerWidth - 20) / 2, 200)}px`, fontSize: "14px", fontWeight: "400" }}>
                All Gigs</div>
              <div style={{
                width: `${Math.min((window.innerWidth - 20) / 2, 200)}px`, paddingLeft: "80px",
                color: "#7E7E7E", fontSize: "14px", fontWeight: "400"
              }}>
                ({PrivateEmployerList?.totalCount ? PrivateEmployerList?.totalCount[0]?.allGigsPublished ?? 0 : 0})
              </div>
            </div>
          </Radio>
          <Radio
            className={
                `radioSortReceivedBidsFilter ${validation
                  ? "MobileFilter-Search-input-left-invalid"
                  : null}`
            }
            style={{ marginTop: "9.4px" }}
            value={true}
          >
            <div style={{ display: "flex", justifyContent: "space-between", width: `${Math.min(window.innerWidth - 20, 500)}px` }}>
              <div style={{ width: `${Math.min((window.innerWidth - 20) / 2 - 128, 200)}px` }}>
                <span style={{ color: "3E3E3E", fontSize: "14px", fontWeight: "400" }}>Private Gigs</span>&nbsp;
                <span className="private-div">PRIVATE</span>
              </div>
              <div style={{
                width: `${Math.min((window.innerWidth - 20) / 2, 200)}px`, paddingLeft: "80px", color: "#7E7E7E",
                fontSize: "14px", fontWeight: "400"
              }}>
                ({PrivateEmployerList?.totalCount ? PrivateEmployerList?.totalCount[0]?.isPrivate ?? 0 : 0})
              </div>
            </div>
          </Radio>
        </Space>
        </Radio.Group></>}

      {profileDetail?.talentPrivate && authToken && !isPrivatePage && <> <div style={{ fontWeight: "500", fontSize: "14px", color: "#1E1E1E" }}>Private Employers</div>
      <Radio.Group className="radioSortReceivedBidsGroupFilter">
        <Space direction="vertical">
          {
            pvtFilter?.map((val, i) => {
              return (
                <Checkbox onChange={e => handleCheckboxChange(val ?? val, e.target.checked)} checked={selectedValues.includes(val?._id?.AccountId ?? val?._id?.AccountId)}
                  className={
                    `checkBoxFilter ${validation
                      ? "MobileFilter-Search-input-left-invalid"
                      : null}`
                  }>
                  <div style={{ display: "flex", justifyContent: "space-between", width: `${Math.min(window.innerWidth - 20, 500)}px` }}>
                    <div style={{
                      width: `${Math.min((window.innerWidth - 20) / 2, 200)}px`,
                      color: "3E3E3E", fontSize: "14px", fontWeight: "400"
                    }}>{val?._id?.EmpName ?? val?._id?.EmpName}</div>
                    <div style={{
                      width: `${Math.min((window.innerWidth - 20) / 2, 200)}px`, paddingLeft: "80px",
                      color: "#7E7E7E", fontSize: "14px", fontWeight: "400"
                    }}>
                      ({val?.count})
                    </div>
                  </div>
                </Checkbox>
              )
            })
          }
        </Space>
      </Radio.Group>
      {validation ? (
        <div className="search-results-filter-mobile-validation-text">
          Please select any Private Employers
        </div>
        ) : null}</>}
         

      <div className="apply_button_main">
        <div className="apply-button-mobile-filter">
          <div className="apply-button-text" onClick={onApply}>
            Apply filter
          </div>
        </div>
      </div>
    </div>
  );
};

export default MobileFilters;
