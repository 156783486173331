import React from "react";
import "../ReceivedBidDetailPage.css";
import { BidinfoTooltip } from "../../../../components/BidInfoTooltip";

const Interested_NotInterested = (props) => {
  const {
    disableState,
    yes,
    no,
    yesText,
    noText,
    submitDeclinedReason,
    OpenModalBoxStages,
    stagename,
  } = props;

  return (
    <section
      className={
        disableState
          ? "interestedSection-about-job-disable"
          : "interestedSection-about-job"
      }
    >
      <div className="ErrorMessage"></div>
      
      <div
        className={`interested-about-job ${
          disableState === true ? "click-disable" : "clickable"
        }`}
        onClick={() => {
          if (disableState) return;
          submitDeclinedReason(true);
        }}
      >
        {disableState?yesText:
        <BidinfoTooltip placement={'right'}>
          {yesText}
        </BidinfoTooltip>
        }
      </div>
      <div
        className={`notInterested-about-job ${
          disableState === true ? "click-disable" : "clickable"
        }`}
        onClick={() => {
          if (disableState) return;
          OpenModalBoxStages(stagename);
        }}
      >
        {noText}
      </div>
    </section>
  );
};

export default Interested_NotInterested;
