import React, { useState, useEffect } from 'react'
import { useDispatch, useSelector } from "react-redux";
import { Select, Button, Col, Spin, DatePicker, Row } from "antd";
import {DownOutlined, MinusOutlined } from '@ant-design/icons';
import './Chart.css';
import { DualAxesChart, DualAxesChartProps, ColumnChart } from "@opd/g2plot-react";
import { Column } from '@antv/g2plot';
import { getGraphDataApi } from '../../../API/API';
import { getGraph,fetchTimeSheetList } from '../../../redux/actions';
import moment from 'moment';
import CalendarIcon from "../../../assets/icons/CalendarIcon.svg";

const Chart = () => {
    const pageSize = 10;
    const skip=0;
    const { RangePicker } = DatePicker;
    const dispatch = useDispatch();
    const [reportTime, setReportTime] = useState();
    const [reportRange, setReportRange] = useState('');
    const [StartTime, setStartDate] = useState("");
    const [endDate, setEndDate] = useState("");
    const [absent, setTotalAbsents] = useState('0');
    const [earning, setTotalLearning] = useState('0');
    const [present, settotalPresent] = useState('0');
    const [overtime,setOvertime] = useState('0');
    const [firstDay, setFirstDay] = useState('');
    const [lastDay, setLastDay] = useState('');
    const [monthfday, setMonthfDay] = useState('');
    const [monthLday, setMonthLDay] = useState('');
    const [firstdayofyr, setFirstdayofyr] = useState('');
    const [graphData, setGraphData] = useState([]);

    const success = useSelector(state => state.timeSheetReducer.success);
    const loading = useSelector(state => state.timeSheetReducer.loading);
    // 
    const date = ['Till date', 'This Week', 'This Month', 'Custom']
    const data =
        [//value= totalPresent , Count= Overtime
            { time: 'January', value: 32, count: 10 },
            { time: 'February', value: 50, count: 11 },
            { time: 'March', value: 20, count: 10 },
            { time: 'April', value: 50, count: 12 },
            { time: 'May', value: 70, count: 11 },
        ];
    const transformData = [
        // { time: '2019-03', },
        // { time: '2019-04', },
        // { time: '2019-05', },
        // { time: '2019-06', },
        // { time: '2019-07', },
        // { time: '2019-07', },
    ];
    // // 
    const geometryOption = 
    {
        geometry: 'column',
        isGroup: true,
        seriesField: 'type',
        color:['#4ec0b2','#d4d4d4','#4054b2'],
        columnStyle: {
            radius: [20, 20, 0, 0],
            
        },
        marginRatio:0,
        maxColumnWidth:10,
    };
    if(graphData?.length > 3){
        geometryOption['dodgePadding'] = 0;
    }
    const config2 = {
        // data: [reportTime === 'This Month'?graphData:graphData, transformData],
        height: window.innerWidth > 1000 ? 300 : 200,
        data: [graphData ? graphData : transformData, transformData],
        xField: 'time',
        yField: ['value', 'count'],
        // data: [data, transformData],
        // xField: 'time',
        // yField: [`value`, 'count'],
        // renderer: 'canvas',
        // autoFit: 'true',
        // padding: 'auto',
        legend: false,
        geometryOptions: [
            geometryOption
            // {
            //     geometry: 'line',
            //     color: '#5AD8A6',
            //     lineStyle: {
            //         width: 1,
            //     }
            // }
        ],
    };
    // // 
    const convertMilliSecondtoHours = (milliseconds) => {
        return ((milliseconds / (1000 * 60 * 60)) % 24).toFixed(2);
    }

    const toHoursAndMinutes = (totalMinutes) => {
        const minutes = totalMinutes % 60;
        const hours = Math.floor(totalMinutes / 60);

        if(minutes){
            if(hours > 1){
                return `${hours}:${padTo2Digits(minutes)} HRS`;
            }
            return `${hours}:${padTo2Digits(minutes)} HR`;
        }
        if(hours){
            return `${hours} HRS`;
        }
        return '0 HR';
        
    }

    const toHoursAndMinutesGraph = (totalMinutes) => {
        const minutes = totalMinutes % 60;
        const hours = Math.floor(totalMinutes / 60);
        
        if(minutes)
            return `${hours}.${padTo2Digits(minutes)}`;
        
        return `${hours}`;
        
    }
      
    const padTo2Digits = (num) => {
        return num.toString().padStart(2, '0');
    }

    useEffect(() => {
        var data2 = [];
        if (success.length && success[1].length) {
            // // 
            for (var i = 0; i < success[1].length; i++) {
                // 
               
                data2 = [...data2,
                {
                    time: moment(success[1][i].Date).format('MMM DD, YYYY'), value: parseFloat(toHoursAndMinutesGraph(success[1][i].SpentTime.toFixed(1) * 60)), type: 'Total Present',
                },
                {
                    time: moment(success[1][i].Date).format('MMM DD, YYYY'), value: parseFloat(toHoursAndMinutesGraph(success[1][i].TotalAbsents.toFixed(1) * 60)), type: 'Total Absents',

                }, {
                    time: moment(success[1][i].Date).format('MMM DD, YYYY'), value: parseFloat(toHoursAndMinutesGraph(success[1][i].OverTime.toFixed(1) * 60)), type: 'Total Overtime',
                }]
            }
            setGraphData(data2);
        }else{
            setGraphData(data2);
        }
    }, [success])
    // // 

    useEffect(() => {
        var curr = new Date;
        var first = curr.getDate() - curr.getDay();
        var last = first + 7;

        var firstday = new Date(curr.setDate(first)).toUTCString();
        setFirstDay(firstday);
        var lastday = new Date(curr.setDate(last)).toUTCString();
        setLastDay(lastday);
        // 
    }, [])

    useEffect(() => {
        var d = new Date(new Date().getFullYear(), 0, 1);
        setFirstdayofyr(d);
        var date = new Date();
        var firstDay = new Date(date.getFullYear(), date.getMonth(), 1);
        setMonthfDay(firstDay);
        var lastDay = new Date(date.getFullYear(), date.getMonth() + 1, 0);
        setMonthLDay(lastDay);

        // 
        // 
    }, [])

    useEffect(() => {
        if (success.length) {
            setTotalAbsents(success[0].totalabsents);
            setTotalLearning(success[0].totalearning);
            settotalPresent(success[0].totalPresent);
            setOvertime(success[0].totalovertime);
        }
    }, [reportTime])

    const selectValue = (e) => {
        // 
        setReportTime(e);
    }
    const onCustomDate = (e) => {
        setReportRange(e);
        // // 
        var StartTime = new Date(e[0]._d).toISOString();
        // // 
        var endTime = new Date(e[1]._d).toISOString();
        setStartDate(StartTime);
        setEndDate(endTime);
        // dispatch(getGraphDataApi(startDate,endDate));
    }

    useEffect(() => {
        if (reportTime === 'This Week') {
            dispatch(getGraph('thisweek', firstDay, lastDay));
            dispatch(fetchTimeSheetList(pageSize, skip,'thisweek',null,null))
        }
        if (reportTime === 'This Month') {
            dispatch(getGraph('thismonth', monthfday, monthLday));
            dispatch(fetchTimeSheetList(pageSize, skip,'thismonth',null,null))
        }

        if (reportTime === 'Till date') {
            dispatch(getGraph('tilldate', null, null));
            dispatch(fetchTimeSheetList(pageSize, skip,'tilldate',null,null))
        }
        else if (reportRange) {
            // 
            dispatch(getGraph('custom', StartTime, endDate));
            dispatch(fetchTimeSheetList(pageSize, skip,'custom',StartTime,endDate))
            // dispatch(getGraph(StartTime, endDate));
            // // 
        }
    }, [reportRange, reportTime]);
    return (
        <>
            <Col lg={24} className={'report-container'}>
                <Row>
                    <Col xs={10} lg={window.innerWidth > 1000 ? 4 : 5} md={5}>
                        <Select className='select-date' style={{ width: '100%', fontSize: '16px'}}
                            defaultValue='Till Date'
                            onChange={selectValue}
                        >
                            {date.map((selectDate) => {
                                return <Select.Option value={selectDate}>{selectDate}</Select.Option>
                            })}
                        </Select>
                    </Col>
                    <Col xs={14} lg={window.innerWidth > 1000 ? 9 : 8} md={8}>
                        {
                            reportTime == "Custom" ?
                                <RangePicker 
                                    className="custom-datepicker"
                                    dropdownClassName ="custom-select-datepicker"
                                    style={{ marginLeft: "3%", borderColor:'#d9d9d9',boxShadow:"none" }}
                                    onChange={onCustomDate}
                                    suffixIcon={<img src={CalendarIcon} alt="Calender" style={{position: 'absolute',left: '6px',bottom: '6px'}} />}
                                    format={["MMMM DD, YYYY", "MM/DD/YY"]}
                                    separator = {<MinusOutlined  style={{color:"#9e9e9e"}}/>}
                                />
                                :
                                <DatePicker 
                                    className="report-datepicker"
                                    style={{ marginLeft: "3%", backgroundColor: '#fff', border: 'solid 1px #e4e4e4', borderRadius: '4px' }}
                                    // defaultValue={'Till Date'}
                                    disabled
                                    placeholder={
                                        reportTime == "This Month" ? moment(monthfday).format('MMM DD, YYYY') + ' - ' + moment(monthLday).format('MMM DD, YYYY')
                                            :
                                            reportTime === 'This Week' ? moment(firstDay).format('MMM DD, YYYY') + ' - ' + moment(lastDay).format('MMM DD, YYYY')
                                                :
                                                reportTime === "Till date" ? moment(firstdayofyr).format('MMM DD, YYYY') + ' - ' + moment().format('MMM DD, YYYY')
                                                    : 'Select date'
                                    }
                                    suffixIcon={<img src={CalendarIcon} alt="Calender"/>}
                                />
                        }
                    </Col>
                </Row>
                    <Row className="Report-container-divs">
                        <Col lg={window.innerWidth > 1000 ? 4 : 5} md={5} xs={11} className="present-div">
                            {/* <h2>{success.totalpresent ? parseFloat(success.totalpresent / 3600000).toFixed(2) : '0'} hr</h2> */}
                            <div className='reporttext'>{success.length ? toHoursAndMinutes(success[0].totalpresent.toFixed(1) * 60) : "0 HR"}</div>
                            <div className='div-detail'>Total present</div>
                        </Col>
                        <Col lg={1} md={1} xs={1}></Col>
                        <Col lg={window.innerWidth > 1000 ? 4 : 5} md={5} xs={11} className='absent-div'>
                            <div className='reporttext'>{success.length ? toHoursAndMinutes(success[0].totalabsents.toFixed(1) * 60) : "0 HR"}</div>
                            <div className='div-detail'>Total absents</div>
                        </Col>
                        <Col lg={1} md={1} xs={0}></Col>
                        <Col lg={window.innerWidth > 1000 ? 4 : 5} md={5} xs={11} className='Overtime-div'>
                            <div className='reporttext'>{success.length  ? toHoursAndMinutes(success[0].totalovertime.toFixed(1) * 60) : "0 HR"}</div>
                            <div className='div-detail'>Total overtime</div>
                        </Col>
                        <Col lg={1} md={1} xs={1}></Col>
                        <Col lg={window.innerWidth > 1000 ? 4 : 5} md={5} xs={11} className='earning-div'>
                            <div className='reporttext'>${success.length ? success[0].totalearning : "0"}</div>
                            <div className='div-detail'>Total earnings</div>
                        </Col>
                    </Row>

                <Col lg={24} xs={24} className="chart-container">
                        <>
                            <Col xs={24} className={'Graph-detail-container'}>
                                <Row><div className="present-circle"></div> <div className='Report-details'>Total present {success.length ? toHoursAndMinutes(success[0].totalpresent.toFixed(1) * 60) : present + ' hr'}</div> </Row>
                                <Row><div className="absent-circle"></div>   <div className='Report-details'>Total absents {success.length ? toHoursAndMinutes(success[0].totalabsents.toFixed(1) * 60): absent + ' hr'}</div> </Row>
                                <Row><div className="Overtime-circle"></div>   <div className='Report-details'>Total overtime {success.length  ? toHoursAndMinutes(success[0].totalovertime.toFixed(1) * 60) : earning + ' hr'} </div> </Row>
                            </Col>
                            <Col xs={24} className={'Graph-detail-container'}>
                                <Row><div className='Report-details'>Total earnings ${success.length ? success[0].totalearning : "0"}</div> </Row>
                            </Col>
                            <DualAxesChart {...config2} style={{ width: '90%', margin: 'auto', marginTop: '3%' }} />
                        </>
                </Col>
            </Col>
        </>
    )
}
export default Chart;