import React from "react";
import UploadDocV2 from "../../UploadDoc";
import { useSelector } from "react-redux";

const ResumeUploadv2 = () => {
  const Profiles = useSelector((state) => state.profileReducer?.user?.profile);

  const ProfessionCode = Profiles?.ProfessionCode;

  const docInfo = [
    {
      Resume: 52,
    },
  ];
  return (
    <div>
      <UploadDocV2
        docInfo={docInfo}
        backUrl="workAuthorization"
        nextUrl={ProfessionCode === "HC" ? "bls-doc" : "create-skilllisting-gig-type"}
        currentPage={1}
        WizardFlow={110}
        IsMandatory={true}
        skipUrl={window.innerWidth < 768 ? "bls-doc" : false}
      />
    </div>
  );
};

export default ResumeUploadv2;
