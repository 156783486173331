import React from 'react';
import './ViewResumeDownloadPDF.css'
import jsPDF from 'jspdf';
import { alertNotification } from '../../../../components/notifications/alertNotification';

const ViewResumeDownloadPDF = ({ downloadFileName }) => {

    function convertHTMLtoPDF() {
        const doc = new jsPDF("p", "mm", "a4");
        let pdfjs = document.querySelector("#DownloadResumePage");
        doc.setFontSize(24);

        doc.html(pdfjs, {
            callback: function (doc) {
                doc.save(`${downloadFileName}`);
                setTimeout(() => {
                    alertNotification("success", "File downloaded successfully");
                }, 500);
            },
            x: doc.internal.pageSize.getWidth() / 2 - 82.5,
            y: 10,
            width: 165, //target width in the PDF document
            windowWidth: 790 //window width in CSS pixels
        });
    }
    return (
        <div className='ViewResumeDownloadPDF-cantainer'>
            <div className='ViewResumeDownloadPDF-cantainer-button'>
                <div onClick={() => convertHTMLtoPDF()} className='ViewResumeDownloadPDF-button'>
                    Download PDF Resume
                </div>
            </div>
        </div>
    );
};

export default ViewResumeDownloadPDF;