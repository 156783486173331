import React, { useEffect, useState } from "react";
import Dragger from "antd/lib/upload/Dragger";
import "./DocumentUploadv2.css";
import DocumentUploadIconv2 from "../../assets/icons/DocumentUploadIconv2.svg";
import { useDispatch, useSelector } from "react-redux";
import { uploadFileApi } from "../../API/API";
import { fetchData, removeDocument } from "../../redux/actions";
import Loader from "../Loader/Loader";
import { WarningFilled } from "@ant-design/icons";

const DocumentUploadv2 = ({
  userId,
  docID,
  DocTitle,
  setuploadLoader,
  mandatoryfeild,
  setmandatoryfeild,
  setfileLen,
  IsMandatory,
  Optional
}) => {
  const dispatch = useDispatch();
  const [payload, setpayload] = useState({});
  const [s3state, sets3State] = useState(false);
  const [fileList, setfileList] = useState([]);
  const [HideUpload, setHideUpload] = useState(false);
  const [fileLoader, setfileLoader] = useState(false);
  const [UploadIdCheck, setUploadIdCheck] = useState(false);
  const [ErrorMsg, setErrorMsg] = useState();
  const [TitleTextAlign, setTitleTextAlign] = useState();

  const [FileInvalidErrorMsg, setFileInvalidErrorMsg] = useState();

  const userDoc = useSelector((state) => state.docReducer.user);
  const userDocLoading = useSelector((state) => state.docReducer.loadingDoc);
  const RemoveDocLoading = useSelector((state) => state.docReducer.loading);

  const userfilter = userDoc?.userDocs?.filter((data) => {
    if (data?.CredentialDocumentId === docID) return data;
  });

  useEffect(() => {
    if (RemoveDocLoading === false) {
      setfileLoader(false);
    }
  }, [RemoveDocLoading]);

  useEffect(() => {
    if (mandatoryfeild === true) {
      setErrorMsg(
        `Please upload the ${
          DocTitle[0] === "Resume" ? "resume" : DocTitle
        } to continue`
      );
    }
  }, [mandatoryfeild, DocTitle]);

  useEffect(() => {
    if (window.location.pathname === "/talent/acls-doc"){
      setTitleTextAlign("acls-doc-Text")
    }
    else if (window.location.pathname === "/talent/bls-doc"){
      setTitleTextAlign("bls-doc-Text")
    }
  }, [window.location.pathname])

  useEffect(() => {
    sets3State(userfilter[0]?.userDoc?.s3FileKey);
    if (userfilter[0]?.userDoc?.s3FileKey) {
      fileList?.push({
        uid: "-1",
        name: `${userfilter[0]?.userDoc?.FileName}`,
        status: "done",
        // url: `${process.env.REACT_APP_S3_URL}/${userfilter[0]?.userDoc?.s3FileKey}`,
        thumbUrl: `${process.env.REACT_APP_S3_URL}/${userfilter[0]?.userDoc?.s3FileKey}`,
      });
    }
    if (fileList?.length > 0) {
      setfileLen(true);
    }
  }, [userfilter[0]?.userDoc?.s3FileKey]);

  const beforeUpload = (info) => {
    const file = info;

    if (file) {
      if (
        file?.type !== "application/pdf" &&
        file?.type !== "image/jpeg" &&
        file.type !==
          "application/vnd.openxmlformats-officedocument.wordprocessingml.document" &&
        file?.type !== "image/png" &&
        file?.type !== "image/jpg" &&
        file?.type !== "application/msword"
      ) {
        setFileInvalidErrorMsg(
          "Please upload the supported format to continue"
        );
        setUploadIdCheck(true);
        setfileLen(false);
        return;
      }

      if (file?.size >= 10590617) {
        setFileInvalidErrorMsg("file size is greater than 10mb");
        setUploadIdCheck(true);
        setfileLen(false);
        return;
      }

      if (
        file?.type === "application/pdf" ||
        file?.type === "image/jpeg" ||
        file.type ===
          "application/vnd.openxmlformats-officedocument.wordprocessingml.document" ||
        file?.type === "image/png" ||
        file?.type === "image/jpg" ||
        file?.type === "application/msword"
      ) {
        setpayload({
          params: {
            file,
            fileName: file.name,
            fileSize: file.size.toString(),
            userId: userId,
            documentId: userfilter[0]?._id,
            type: "credentialingDoc",
          },
        });

        setHideUpload(true);
        setfileLen(true);
      }
    }
  };

  const customRequestAPI = async (options) => {
    const { onSuccess, onError, file, onProgress } = options;
    
    setuploadLoader(true);
    if (UploadIdCheck === true) {
      onError("file error");
      setHideUpload(true);
      setuploadLoader(false);
      return;
    }
    // why we directly using the API to upload?
    const successOk = await uploadFileApi(payload);
    if (successOk?.data?.status === "success") {
      setTimeout(() => {
        // dispatch(fetchData());
        onSuccess("Ok");
      }, 2000);
      setuploadLoader(false);
    }
  };

  const removeDocumentFile = () => {
    setfileList([]);
    setHideUpload(false);
    setfileLoader(docID);
    setUploadIdCheck(false);
    if (UploadIdCheck === true) return;
    dispatch(removeDocument({ docID: docID, alert: false }));
    setfileLen(false);
    setmandatoryfeild(false);
  };
  return (
    <div
      className={`document-upload-cantainer-v2 ${
        mandatoryfeild && "document-upload-cantainer-v2-error"
      }`}
    >
      <div className="document-upload-Header-v2">
        <div className={`document-upload-title-v2 ${TitleTextAlign}`}>
          <span>{DocTitle}</span>
          {(
            <span className={`upload-doc-Mandatory ${DocTitle[0] === "Resume" && "Resume-doc-Mandatory"}`}>{Optional ? "(Optional)" : "(Mandatory)"}</span>
          )}
        </div>
        {docID === 52 && (
          <div className="document-upload-info-v2">
            Resume upload will auto fill your information, you can always edit
            your profile later if needed.
          </div>
        )}
      </div>
      {(userDocLoading || RemoveDocLoading) &&
      (fileLoader === docID || userDoc?.userDocs?.length < 1) ? (
        <Loader height={30} heightBox={169} />
      ) : (
        <Dragger
          beforeUpload={(e) => beforeUpload(e)}
          customRequest={customRequestAPI}
          maxCount={1}
          listType="picture"
          onRemove={() => {
            removeDocumentFile();
          }}
          defaultFileList={fileList}
          className={s3state ?? HideUpload ? `Disable-upload-section` : ""}
        >
          <img
            src={DocumentUploadIconv2}
            className="document-upload-drag-icon-v2"
          />
          <div className="document-upload-text-v2">
            <span className="document-upload-desktop-text-v2">
              Drag & Drop or
            </span>{" "}
            <span className="document-upload-Choose-v2">Choose file</span> to
            upload
          </div>
          <div className="document-upload-hint-v2">
            JPEG, PNG, PDF, DOC, DOCX (upto 10 mb)
          </div>
        </Dragger>
      )}
      {mandatoryfeild && !HideUpload && (
        <div className="errorMsg">
          <WarningFilled className="errorIcon" />
          {ErrorMsg}
        </div>
      )}
      {UploadIdCheck === true && (
        <div className="errorMsg">
          <WarningFilled className="errorIcon" />
          {FileInvalidErrorMsg}
        </div>
      )}
    </div>
  );
};

export default DocumentUploadv2;
