import { call, put, takeEvery, takeLatest, takeLeading } from "redux-saga/effects";
import { types } from "../action-types";
import { acceptOfferApi } from "../../API/API";
import { fetchDetailPlaceBid, fetchDetailReceivedBid } from "../actions";

function* offerSaga(params) {
  try {
    // 
    const response = yield call(() => acceptOfferApi(params));
    // 
    if(response?.data?.ProposalId) {
      yield put(fetchDetailPlaceBid(response.data.ProposalId));
    }
    if(response?.data?.BidId) {
      yield put(fetchDetailReceivedBid(response.data.BidId));
    }
    yield put({ type: types.FETCH_OFFER_SUCCESS, payload: response.data });
  } catch (error) {
    yield put({ type: types.FETCH_OFFER_FAILURE, error });
  }
}

export function* watchofferSaga() {
  yield takeLeading(types.FETCH_OFFER_REQUESTED, offerSaga);
}
