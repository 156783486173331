import React, { useEffect } from "react";

import "./EducationalScreenThree.css";
import EducationalComponent from "./EducationalComponent";
import eduPicOne from "../../assets/icons/sc-health-e.svg";
import eduPicTwo from "../../assets/icons/sc-health-a.svg";

import eduPicThree from "../../assets/icons/bid-edu-logo.png";

import eduPicFour from "../../assets/icons/sc-health-b.svg";
import eduPicFive from "../../assets/icons/sc-health-c.svg";
import eduPicSix from "../../assets/icons/sc-health-d.svg";
import Buttnn from "./Buttnn";
import EducationalComponentThree from "./EducationalComponentThree";
import BackNextSignUpButton from "../SignUp/Components/DocUpload/Signup_gap_screen/BackNextSignUpButton";
import { useHistory } from "react-router-dom/cjs/react-router-dom.min";
import { updateSignupProfile } from "../../redux/actions";
import { useDispatch, useSelector } from "react-redux";
import { useState } from "react";

const EducationalScreenThree = () => {
  const history = useHistory();
  const dispatch = useDispatch();

  const [redirectionFlag, setRedirectionFlag] = useState(false);

  const loading = useSelector((state) => state.profileReducer.signUpLoading);
  const apiSuccess = useSelector(
    (state) => state.profileReducer.signupUpdateSuccess
  );

  useEffect(() => {
    if (apiSuccess && redirectionFlag) {
      setRedirectionFlag(false);
      history.push("/create-skilllisting-gig-type");
    }
  }, [apiSuccess]);

  const boxes = [
    {
      photo: eduPicFive,
      // backgroundScreen: eduPicOne,
      header: "Provide Accurate Information",
      subheader: "Avoid inaccurate or misleading information.",
    },
    {
      photo: eduPicFour,
      header: "Avoid duplicate accounts",
      subheader:
        "No need to create duplicate accounts — just add more skill listings. Duplicate accounts confuse employers.",
    },
    {
      photo: eduPicSix,
      header: "Bidding on gigs",
      subheader:
        "Bid only on gigs that you are actually interested in. First impressions are everything with potential employers. ",
    },
    {
      photo: eduPicThree,
      header: "Respond to bids",
      subheader:
        "Respond to bids as soon as you can. Keeping bids in a pending state will result in expiration and missed opportunities.",
    },
    {
      photo: eduPicOne,
      header: "Read Terms & Condition",
      subheader:
        "SkillGigs terms and conditions require all marketplace users to keep all communication and payments within the system until hired on contract or permanent status.",
    },
    {
      photo: eduPicTwo,
      header: "Manage critical email alerts",
      subheader:
        "Being a part of an ecommerce-style marketplace means you will be notified of profile activity. Make sure to list hello@alerts-skillgigs.com to prevent critical alerts from going to spam.",
    },
  ];

  return (
    <>
      <div className="mmm">
        <div className="header-edu">
        Things to avoid when building a successful and effective profile on SkillGigs
        </div>
        <div className="underlinee" />
        <div className="boxes-containerrr">
          <div className="sub-header-edu-txt-2">
            Your success is our focus. Here are a couple of things to keep in
            mind {" "}
          </div>

          <div className="boxes-roww edu-screen-three">
            {boxes.slice(0, 2).map((box, index) => (
              <EducationalComponentThree key={index} {...box} />
            ))}
          </div>
          <div className="boxes-roww edu-screen-three">
            {boxes.slice(2, 4).map((box, index) => (
              <EducationalComponentThree key={index} {...box} />
            ))}
          </div>
          <div className="boxes-roww edu-screen-three">
            {boxes.slice(4).map((box, index) => (
              <EducationalComponentThree key={index} {...box} />
            ))}
          </div>
        </div>
      </div>
      <div style={{ display: "flex", justifyContent: "center"}} className="bck-button-3" >
        <BackNextSignUpButton
          nextFunction={() => {
            setRedirectionFlag(true);
            dispatch(
              updateSignupProfile({
                WizardFlow: 201,
              })
            );
          }}
          nextBtnLoading={loading}
          backFunction={() => history.push("/sign-educational-screen2")}
        />
      </div>
    </>
  );
};

export default EducationalScreenThree;
