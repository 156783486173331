import { all } from "redux-saga/effects";

import { watchFetchDataSaga } from "./fetchDataSaga";
import {
  watchAuthSaga,
  watchLinkedInSaga,
  watchSendEmailVerificationSaga,
  watchSendMobileOTPCheckSaga,
  watchSendMobileOTPVerificationSaga,
} from "./authSaga";
import {
  watchDocSaga,
  watchUploadDocSaga,
  watchRemoveDocSaga,
} from "./docSaga";
import {
  watchAnalyticInfoSaga,
  watchCompanyWorkHistory,
  watchDeleteResumeSaga,
  watchFetchCollegeMaster,
  watchFetchCourseMaster,
  watchFetchDegreeMaster,
  watchFetchProfileExperienceSaga,
  watchIndustryWorkHistory,
  watchJobDetailsProfileSaga,
  watchJobTitleWorkHistory,
  watchLocationWorkHistory,
  watchJobDetailsProfileCanceledSaga,
  watchJobDetailsProfileCompletedSaga,
  watchJobDetailsProfileOnBillingSaga,
  // watchJobDetailsProfileSaga,
  watchJobDetailsProfileTerminatedSaga,
  watchPostEducationHistory,
  watchPostWorkHistory,
  watchProfileSaga,
  watchRoleDisciplineSaga,
  watchSkillsWorkHistory,
  watchTalentInfoSaga,
  watchUpdateEducationHistory,
  watchUpdateProfileSaga,
  watchUpdateWorkHistory,
  watchUploadProfilePicSaga,
  watchUploadResumeSaga,
  watchetchInterviewCardSaga,
  watchPostCertificatesAndAwardsSaga,
  watchUpdateCertificatesAndAwardsSaga,
  watchDownloadResumeSaga,
  watchRecentSearchSaga,
  watchUpdateSocialProfileLinkSaga,
  watchFetchDashboardBidsCounts
} from "./profileSaga";
import { watchcheckUserSaga } from "./chekckUserSaga";
import {
  watchFetchSkillSaga,
  watchCreateSkillListingSaga,
  watchUpdateSkillListingSaga,
  watchKeySearchDiscipline,
  watchFetchUserSkillListing,
  watchDeleteSkillListingSaga,
  watchFetchOneSkillListing,
} from "./skillListingSaga";

import { watchSearchLocationSaga } from "./searchLocationSaga";
import { watchFetchProposalsSaga } from "./ProposalsSaga";
import { watchCreateBidSaga } from "./createBidSaga";
import {
  watchRetractUpdateBidSaga,
  watchUpdateBidSaga,
  watchUpdateGigSaga,
} from "./updateBidSaga";
import {
  watchfetchInboxDataSaga,
  watchFetchMessagesSaga,
  watchSendMessagesSaga,
} from "./messagesSaga";
import { watchFetchBidDataSaga } from "./fetchBidDataSaga";
import { watchRetractBidSaga } from "./retractBidSaga";
import {
  watchGetChatSaga,
  watchInboxMessagesSaga,
  watchPostAttachmentSaga,
  watchGetMsgCountsSaga,
  watchDashboardInboxMessagesSaga,
  watchGetAdminMsgDashboardSaga,
} from "./ChatHistorySaga";

import {
  watchGetChatSagaHeader,
  watchInboxMessagesSagaHeader,
  watchPostAttachmentSagaHeader,
  watchGetMsgCountsSagaHeader,
} from "./ChatHistoryHeaderSaga";
import {
  watchFetchDeclinedReasonsBids,
  watchFetchDetailPlaceBidsSaga,
  watchFetchDetailReceivedBidsSaga,
  watchFetchPlaceBidsSaga,
  watchFetchReceivedBidsSaga,
  watchFetchretractedBidsSaga,
  watchStatusReceivedBidsSaga,
  watchUpdateReceivedBidsSaga,
  watchUpdateDeclinedReasonsBids,
  watchFetchReceivedBidCountSaga,
} from "./BidsSaga";
import { watchGraphDataSaga } from "./TimeSheetSaga";
import {
  watchFetchTimesheetListSaga,
  watchFetchTimesheetSaga,
  watchUpdateTimesheetSaga,
  watchExisitingListItemSaga,
} from "./timeSheetListSaga";
import { watchofferSaga } from "./offerSaga";
import { watchCreateTimeSheetCorrectionSaga } from "./createTimeSheetCorrectionSaga";
import { watchUserAssignmentSaga } from "./userAssignmentSaga";
import { watchPrivateEmpListSuggestionSaga, watchSearchSuggestionsSaga } from "./SearchSuggestionsSaga";
import {
  watchMyFavoriteSaga,
  watchFavoriteGigCard,
  watchRemoveFavoriteGigCard,
} from "./FavoritesSaga";
import {
  watchInterviewSaga,
  watchInterviewScheduleSaga,
} from "./interviewSaga";
import {
  watchResumeGetfollowersSaga,
  watchResumeGetviewsSaga,
  watchResumeDetailSaga,
  watchResumeViewSaga,
} from "./resumeSaga";
import {
  watchfetchAddMessageToFavourite,
  watchfetchRemoveMessageFromFavourite,
} from "./messageFavouriteSaga";
import { watchHomepageInfoSaga } from "./HomePageSaga";
import {
  watchNotificationSaga,
  watchGetNotificationSaga,
  watchGetReadNotifications
} from "./NotificationSaga";
import {
  watchCreateRatingSaga,
  watchDeleteRatingSaga,
  watchUpdateRatingSaga,
} from "./ratingSaga";
import { watchCheckUsernameUnique } from "./checkUserNameSaga";
import {
  watchCompanyProfileSaga,
  watchEmployerFollowSaga,
  watchViewListingSaga,
  watchCompanyProfileGigsSaga
} from "./viewListingSaga";
import { watchSearchCountrySaga } from "./searchCountrySaga";
import { watchSearchRegionSaga } from "./searchRegionSaga";
import { watchSearchCitySaga } from "./searchCitySaga";
import { watchfetchExperinceLevelsSaga } from "./fetchExperienceLevelsSaga";
import { watchGetRatingSaga } from "./getRatingSaga";
export default function* rootSaga() {
  yield all([
    watchFetchDataSaga(),
    watchAuthSaga(),
    watchofferSaga(),
    watchDocSaga(),
    watchProfileSaga(),
    watchUpdateProfileSaga(),
    watchUploadDocSaga(),
    watchUploadProfilePicSaga(),
    watchUploadResumeSaga(),
    watchfetchAddMessageToFavourite(),
    watchfetchRemoveMessageFromFavourite(),
    watchLinkedInSaga(),
    watchRemoveDocSaga(),
    watchcheckUserSaga(),
    watchFetchSkillSaga(),
    watchCreateSkillListingSaga(),
    watchUpdateSkillListingSaga(),
    watchSearchLocationSaga(),
    watchSearchCountrySaga(),
    watchSearchRegionSaga(),
    watchSearchCitySaga(),
    watchfetchExperinceLevelsSaga(),
    watchKeySearchDiscipline(),
    watchFetchUserSkillListing(),
    watchFetchProfileExperienceSaga(),

    watchFetchProposalsSaga(),
    watchCreateBidSaga(),
    watchUpdateBidSaga(),
    watchFetchMessagesSaga(),
    watchFetchBidDataSaga(),
    watchRetractBidSaga(),
    watchSendMessagesSaga(),
    watchGetChatSaga(),
    watchGetChatSagaHeader(),
    watchStatusReceivedBidsSaga(),
    watchFetchReceivedBidsSaga(),
    watchUpdateReceivedBidsSaga(),

    watchDeleteResumeSaga(),
    watchGraphDataSaga(),
    watchUpdateTimesheetSaga(),
    watchFetchTimesheetSaga(),
    watchFetchTimesheetListSaga(),
    watchCreateTimeSheetCorrectionSaga(),
    watchUserAssignmentSaga(),
    watchExisitingListItemSaga(),
    watchDeleteSkillListingSaga(),
    watchRoleDisciplineSaga(),
    watchSearchSuggestionsSaga(),
    watchfetchInboxDataSaga(),

    watchTalentInfoSaga(),
    watchAnalyticInfoSaga(),
    watchMyFavoriteSaga(),
    watchJobDetailsProfileSaga(),

    watchFavoriteGigCard(),
    watchRemoveFavoriteGigCard(),

    watchFetchCollegeMaster(),
    watchFetchDegreeMaster(),
    watchFetchCourseMaster(),
    watchPostEducationHistory(),
    watchUpdateEducationHistory(),

    // watchCompanyWorkHistory(),
    watchJobTitleWorkHistory(),
    watchIndustryWorkHistory(),
    watchLocationWorkHistory(),
    watchSkillsWorkHistory(),
    watchPostWorkHistory(),
    watchUpdateWorkHistory(),

    watchPostCertificatesAndAwardsSaga(),
    watchUpdateCertificatesAndAwardsSaga(),

    watchRetractBidSaga(),

    watchJobDetailsProfileCompletedSaga(),
    watchJobDetailsProfileCanceledSaga(),
    watchJobDetailsProfileTerminatedSaga(),
    watchJobDetailsProfileOnBillingSaga(),

    watchFetchretractedBidsSaga(),

    watchFetchPlaceBidsSaga(),
    watchFetchDetailPlaceBidsSaga(),
    watchFetchDetailReceivedBidsSaga(),

    watchetchInterviewCardSaga(),
    watchInterviewSaga(),
    watchInterviewScheduleSaga(),
    watchFetchDeclinedReasonsBids(),
    watchUpdateDeclinedReasonsBids(),
    // 3d Resume sagas start//
    watchResumeGetfollowersSaga(),
    watchResumeGetviewsSaga(),
    watchResumeDetailSaga(),
    // 3d Resume sagas end//
    watchHomepageInfoSaga(),
    watchNotificationSaga(),
    watchRetractUpdateBidSaga(),

    // Rating saga //
    watchCreateRatingSaga(),
    watchUpdateRatingSaga(),
    watchDeleteRatingSaga(),

    watchDownloadResumeSaga(),
    watchInboxMessagesSaga(),
    watchInboxMessagesSagaHeader(),
    watchPostAttachmentSaga(),
    watchPostAttachmentSagaHeader(),
    watchGetNotificationSaga(),
    watchUpdateGigSaga(),
    watchCheckUsernameUnique(),
    watchSendEmailVerificationSaga(),
    watchViewListingSaga(),
    watchCompanyProfileSaga(),
    watchCompanyProfileGigsSaga(),
    watchGetMsgCountsSaga(),
    watchGetMsgCountsSagaHeader(),
    watchFetchOneSkillListing(),
    watchEmployerFollowSaga(),
    watchGetReadNotifications(),
    watchRecentSearchSaga(),
    watchGetRatingSaga(),
    watchDashboardInboxMessagesSaga(),
    watchFetchReceivedBidCountSaga(),
    watchUpdateSocialProfileLinkSaga(),
    watchFetchDashboardBidsCounts(),,
    watchResumeViewSaga(),
    watchGetAdminMsgDashboardSaga(),
    watchPrivateEmpListSuggestionSaga(),
    watchSendMobileOTPVerificationSaga(),
    watchSendMobileOTPCheckSaga(),
  ]);
}
