import React, { useEffect, useState } from "react";
import {
  Row,
  Col,
  Avatar,
  Typography,
  Comment,
  Divider,
  Spin,
  Alert,
} from "antd";
import { DownloadOutlined, FileTextTwoTone } from "@ant-design/icons";
import moment from "moment";
import "./ChatHistory.css";
import { useDispatch, useSelector } from "react-redux";
import downloadChatFilesApi from "../../../shared/downloadFiles";
// import { fetchChatHistory } from "../../../redux/actions";
import MessageContainer from "./MessageContainer";

const ChatHistory = (props) => {
  const dispatch = useDispatch();
  // const { Title } = Typography
  const success = useSelector((state) => state.getChatHistoryReducer.chats);
  const loading = useSelector((state) => state.getChatHistoryReducer.loading);
  const error = useSelector((state) => state.getChatHistoryReducer.error);
  const [socketMsg, setSocketMsg] = useState("");

  const id = props.recipientId;
  // useEffect(() => {
  //   dispatch(fetchChatHistory(props.conversationId));
  // }, []);

  // const downloadFiles = async (filepath) => {
  //     try {
  //         const path = filepath.split('/');
  //         const para = await downloadChatFilesApi(path[1]);
  //         // // 
  //         window.open(para.data.downloadURL);
  //     }
  //     catch {

  //     }
  // }

  useEffect(() => {
    if (success) {
      // 
    }
  }, []);
  return (
    <>
      <Col>
        {loading ? (
          <div className={"bids-spin"}>
            <Spin />
          </div>
        ) : (
          success.map((msgs) => {
            return (
              <>
                {/* <input value={socketMsg} disabled></input> */}
                {error ? (
                  <Alert
                    message="Error"
                    description="Oh snap! something went wrong."
                    type="error"
                    showIcon
                  />
                ) : (
                  ""
                )}
                {id != msgs.SenderUserId ? (
                  <Col className="sender-msg-container">
                    <Row>
                      <Col lg={2} xs={3} sm={2} md={3}>
                        <div style={{ paddingTop: "5px" }}>
                          <Avatar
                            className="Avatar"
                            src={
                              msgs.SenderUser[0].profile.PictureUrl
                                ? msgs.SenderUser[0].profile.PictureUrl
                                : ""
                            }
                            alt="Han Solo"
                          />
                        </div>
                      </Col>
                      <Col
                        lg={22}
                        md={20}
                        sm={21}
                        xs={19}
                        className="sender-msg"
                      >
                        <Comment
                          className="chat-msg"
                          author={
                            <a>
                              <b>
                                {msgs.SenderUser[0].name
                                  ? msgs.SenderUser[0].name
                                  : ""}
                              </b>
                            </a>
                          }
                          content={
                            <MessageContainer msgs={msgs} />
                            // <div className="para">
                            //     {success.length ? <div dangerouslySetInnerHTML={{ __html: msgs.MessageText }}></div> : ''}
                            //     {/* dangerouslySetInnerHTML={{__html:success.length ? success[0].MessageText : ''}  */}
                            //     {msgs.Attachments[0] ?
                            //         msgs.Attachments.map((files) => {
                            //             return (
                            //                 <div>
                            //                     <Col md={15} sm={12} lg={12} xs={23} className={'msg-attachment-container'}>
                            //                         <FileTextTwoTone style={{ marginRight: '5px' }} />
                            //                         <a onClick={() => downloadFiles(files.FilePath)} >{msgs.Attachments[0] ? files.FileName : ''}</a> <br />
                            //                         <span className="span">({files ? files.FileSize : ''})bytes</span> <br />
                            //                         <DownloadOutlined className={'download-icon'} onClick={() => downloadFiles(files.FilePath)} />
                            //                     </Col>
                            //                 </div>
                            //             )
                            //         })
                            //         : ''}
                            // </div>
                          }
                          datetime={
                            <span className="span">
                              {success.length
                                ? moment(msgs.Created).format(
                                    "dddd , MMMM DD, YYYY hh:mm A"
                                  )
                                : ""}
                            </span>
                          }
                        />
                      </Col>
                    </Row>
                  </Col>
                ) : (
                  "    "
                )}

                {id == msgs.SenderUserId ? (
                  <Col className="receiver-msg-conatainer">
                    <Row>
                      <Col lg={2} md={3} sm={2} xs={3}>
                        <div style={{ paddingTop: "5px", marginRight: "1px" }}>
                          <Avatar
                            className="Avatar"
                            src={
                              success.length
                                ? msgs.SenderUser[0].profile.PictureUrl
                                : ""
                            }
                            alt="Han Solo"
                          />
                        </div>
                      </Col>
                      <Col
                        lg={22}
                        md={20}
                        sm={21}
                        xs={19}
                        style={{ marginBottom: "20px" }}
                        className="receiver-msg"
                      >
                        <Comment
                          className="chat-reply"
                          author={
                            <a>
                              <b>
                                {success.length ? msgs.SenderUser[0].name : ""}
                              </b>
                            </a>
                          }
                          content={
                            // <div className="para">
                            //     {success.length ? <div dangerouslySetInnerHTML={{ __html: msgs.MessageText }}></div> : ''}
                            //     {msgs.Attachments[0] ?
                            //         msgs.Attachments.map((files) => {
                            //             // // 
                            //             return (
                            //                 <div>
                            //                     <Col span={12} className={'msg-attachment-container'}>
                            //                         <FileTextTwoTone style={{ marginRight: '5px' }} />
                            //                         <a>{msgs.Attachments[0] ? files.FileName : ''}</a> <br />
                            //                         <span className="span">({files ? files.FileSize : ''})bytes</span> <br />
                            //                         <DownloadOutlined className={'download-icon'} onClick={() => downloadFiles(files.FilePath)} />
                            //                     </Col>
                            //                 </div>
                            //             )
                            //         })
                            //         : ''}
                            // </div>
                            <MessageContainer msgs={msgs} />
                          }
                          datetime={
                            <span className="span">
                              {success.length
                                ? moment(msgs.Created).format(
                                    "dddd , MMMM DD, YYYY hh:mm A"
                                  )
                                : ""}
                            </span>
                          }
                        />
                      </Col>
                    </Row>
                  </Col>
                ) : (
                  ""
                )}
              </>
            );
          })
        )}
      </Col>
    </>
  );
};
export default ChatHistory;
