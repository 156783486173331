import React, { useState, useEffect } from "react";

import "../../CreateSkillListing.css";

import "./CreateSkillListingScreen.css";

import errorIcon from "../../../../../assets/img/ci_warning.png";

import BottomBottons from "../Footer/BottomButtons";

import { useHistory } from "react-router-dom";

import { connect, useDispatch, useSelector } from "react-redux";

import { updateSignupProfile } from "../../../../../redux/actions";

const DesiredSalary = (props) => {
  const dispatch = useDispatch();

  const [salary, setSalary] = useState("");
  const [flag,setFlag] = useState(false);
  const [buttonType, setButtonType] = useState("Year");

  const [showError, setShowError] = useState(false);

  const history = useHistory();

  const profiledata = useSelector((state) => state.profileReducer.user);

  const loading = useSelector((state) => state.profileReducer.signUpLoading);

  const apiError = useSelector((state) => state.profileReducer.error);

  const apiSuccess = useSelector(
    (state) => state.profileReducer.signupUpdateSuccess
  );

  const minimumBid = profiledata?.profile?.DraftSkillListing?.MinimumBid;

  const gigType =
  profiledata?.profile?.DraftSkillListing?.GigType;
  
  useEffect(() => {
    setSalary(minimumBid);
    setButtonType(gigType === "Permanent" ? "Year" : "Hour");
  }, [minimumBid,gigType]);

  useEffect(() => {
    if (apiSuccess && salary && buttonType && flag) {
      history.push("/create-skilllisting-date");
    }
  }, [apiSuccess]);

  const handleNext = () => {
    if (salary && buttonType) {
      setFlag(true)
      dispatch(
        updateSignupProfile({
          "DraftSkillListing.MinimumBid": salary,

          "DraftSkillListing.MinimumBidType": buttonType,

          WizardFlow: 206,
        })
      );
    } else {
      setShowError(true);
    }
  };

  const Skiphandle = () => {
    dispatch(
      updateSignupProfile({
        "DraftSkillListing.MinimumBid": null,
        "DraftSkillListing.MinimumBidType": null,
        WizardFlow: 206,
      })
    );
    history.push("/create-skilllisting-date");
  }

  const handleBack = () => {
    history.push("/create-skilllisting-location");
  };

  const yearlyHourlyButtons = () => {
    return (
      <div className="year-weekly-container">
        <div
          className="week-btn"
          style={
            buttonType == "Year"
              ? { backgroundColor: "#118936", color: "white" }
              : { backgroundColor: "white", color: "black" }
          }
        >
          Yearly
        </div>

        <div
          className="week-btn"
          style={
            buttonType == "Hour"
              ? { backgroundColor: "#118936", color: "white" }
              : { backgroundColor: "white", color: "black" }
          }
        >
          Hourly
        </div>
      </div>
    );
  };

  const title = "Desired pay";

  return (
    <div className="CreateSkillListingHeaderScreen2">
      <div className="create-skill-listing-title">
        {title} <span className="Mandatory-Text">(Optional)</span>
      </div>

      <div
        className={`currency-parent-container ${
          showError ? " error-block" : ""
        }`}
      >
        <div className="currency-container">
          <div className="usd-container">
            <span>{buttonType === "Year" ? "Yearly" : "Hourly"}</span>
          </div>
          <div className="dollar-sign">$</div>
          <input
            className="input-screenscurrency"
            type="number"
            value={salary}
            onChange={(e) => {
              setSalary(e.target.value);
              setShowError(false);
            }}
          />
        </div>
      </div>

      {showError && (
        <div className="error-msg-content">
          <img src={errorIcon} alt="" />

          <span className="error-text">
            Please enter your desired salary to continue
          </span>
        </div>
      )}

      <BottomBottons
        loading={loading}
        handleBack={handleBack}
        handleNext={handleNext}
        Skiphandle={Skiphandle}
        Skip = {true}
      />
    </div>
  );
};

export default DesiredSalary;
