import React, { useState, useEffect } from "react";
import { Cache } from "aws-amplify";
import { useHistory } from "react-router-dom";

// aws configuration
import { Auth, Hub } from "../../../config/aws-exports";

// Redux
import { connect, useSelector } from "react-redux";
import { updateUserInfo } from "../../../redux/actions";

// react icons
import { FcGoogle } from "react-icons/fc";
import { BsLinkedin } from "react-icons/bs";
import { BsFacebook } from "react-icons/bs";
import { GrApple } from "react-icons/gr";

import "./SocialOptions.css";

const SocialOptions = ({ signUpLogin, employerTitle }) => {
  const employerDetails = useSelector(
    (state) => state.employerReducer.emp
  );
  const storeEmployerDetails = () => {
    if (employerTitle) localStorage.setItem('socialSignUpWithEmp', JSON.stringify(employerDetails))
  }
  return (
    <div>
      <section className="social-buttons-top-section">
        <div
          className="social-button facebook-color"
          onClick={() => {
            storeEmployerDetails()
            Auth.federatedSignIn({ provider: "Facebook" });
          }}
        >
          <BsFacebook
            size={16}
            style={{
              position: "relative",
              top: "4px",
              marginLeft: "1.875rem",
            }}
          />
          <span className="buttonFont">
            {signUpLogin ? "Sign up" : "Sign in"} with Facebook
          </span>
        </div>

        <div
          className="social-button google-color"
          onClick={() => {
            storeEmployerDetails()
            Auth.federatedSignIn({ provider: "Google" });
          }}
        >
          <FcGoogle
            size={16}
            style={{
              position: "relative",
              top: "4px",
              marginLeft: "1.875rem",
            }}
          />
          <span className="white-bg-button-font">
            {signUpLogin ? "Sign up" : "Sign in"} with Google
          </span>
        </div>
        <div
          className="social-button apple-color"
          onClick={() => {
            storeEmployerDetails()
            Auth.federatedSignIn({ provider: "SignInWithApple" });
          }}
        >
          <GrApple
            size={16}
            style={{
              position: "relative",
              top: "3px",
              color: "black",
              marginLeft: "1.875rem",
            }}
          />
          <span className="white-bg-button-font" style={{ marginLeft: "2.25rem" }}>
            {signUpLogin ? "Sign up" : "Sign in"} with Apple
          </span>
        </div>
        <a
          onClick={() => { storeEmployerDetails() }}
          href={`https://www.linkedin.com/oauth/v2/authorization?response_type=code&client_id=${process.env.REACT_APP_LinkedInClientID}&redirect_uri=${process.env.REACT_APP_MY_URL}/linkcheck&state=foobar&scope=r_liteprofile%20r_emailaddress`}
        >
          {/*<a href="https://www.linkedin.com/oauth/v2/authorization?response_type=code&client_id=77vyvft64zvktg&redirect_uri=http://localhost:3000/auth/linkedin/callback&state=foobar&scope=r_liteprofile">*/}
          <div className="social-button linkedin-color">
            <BsLinkedin
              size={16}
              style={{
                position: "relative",
                top: "4px",
                marginLeft: "1.875rem",
                marginRight: "0.15rem",
              }}
            />
            <span className="buttonFont" style={{ marginLeft: "1.625rem" }}>
              {signUpLogin ? "Sign up" : "Sign in"} with Linkedin
            </span>
          </div>
        </a>
      </section>
    </div>
  );
};

const mapStateToProps = (state) => {
  return {
    user: state.authReducer.user,
    error: state.authReducer.error,
  };
};

const mapDispatchToProps = (dispatch) => {
  return {
    sendAuthToken: (params) => {
      dispatch(updateUserInfo(params)); // action dispatched
    },
  };
};

export default connect(mapStateToProps, mapDispatchToProps)(SocialOptions);
