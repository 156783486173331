import { call, put, takeEvery, takeLatest, takeLeading } from "redux-saga/effects";
import { types } from "../action-types";
import {fetchInboxDataAPI, fetchMessagesAPI, SendMessageAPI} from "../../API/API";
import { fetchInboxDataSuccess } from "../actions";

function* fetchInboxDataSaga(action) {
    // 
    try {
        const {limit, skip,type,bidType , bidStatus} = action.payload;
        const response = yield call(() => fetchInboxDataAPI(limit, skip ,type, bidType, bidStatus));
        // 
        yield put(fetchInboxDataSuccess(response.data));
    } catch (error) {
        yield put({type: types.FETCH_MESSAGES_FAILURE, error});
    }
}

function* fetchMessagesSaga(action) {
    try {
        const {type, results, skip} = action.payload;
        const response = yield call(() => fetchMessagesAPI(type, results, skip));
        yield put({type: types.FETCH_MESSAGES_SUCCESS, payload: {data: response.data, type: type}});
    } catch (error) {
        yield put({type: types.FETCH_MESSAGES_FAILURE, error});
    }
}

function* sendMessageSaga(action) {
    try {
        const {
            conversationId,
            name,
            recipientId,
            message,
            ProfilePic,
            fileUploadResponse
        } = action.payload;
        const response = yield call(() => SendMessageAPI(conversationId,name, recipientId, message,ProfilePic,fileUploadResponse));
        yield put({type: types.SEND_MESSAGE_SUCCESS, payload: {data: response.data,name,ProfilePic}});
    } catch (error) {
        yield put({type: types.SEND_MESSAGE_FAILURE, error});
    }
}

export function* watchFetchMessagesSaga() {
    yield takeLeading(types.FETCH_MESSAGES_REQUESTED, fetchMessagesSaga);
}

export function* watchSendMessagesSaga() {
    yield takeLeading(types.SEND_MESSAGE_REQUESTED, sendMessageSaga);
}

export function* watchfetchInboxDataSaga() {
  yield takeLeading(types.FETCH_INBOX_DATA_REQUEST , fetchInboxDataSaga);
}

