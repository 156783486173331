import React from "react";
import UploadDocV2 from "../../UploadDoc";

const Driver_license_DocV2 = () => {
  const docInfo = [
    {
      "Driver’s license": 84,
    },
  ];
  return (
    <div>
      <UploadDocV2
        docInfo={docInfo}
        backUrl="social-security-doc"
        nextUrl="create-skilllisting-gig-type"
        currentPage={7}
        WizardFlow={116}
        skipUrl={"create-skilllisting-gig-type"}
      />
    </div>
  );
};

export default Driver_license_DocV2;
