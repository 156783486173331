import React, { useEffect, useRef, useState } from "react";
import { Row, Col, Badge, Avatar, Input, Skeleton } from "antd";
import "./ChatCard.css";
import { getImage } from "../../../../shared/downloadAttachments";
import ReceivedBidsCard from "./components/ReceivedBidsCard/ReceivedBidsCard";
import BidAcceptedCard from "./components/BidAcceptedCard/bidAccepted";
import CardParent from "./components/CardParent";
import InterviewStatusCards from "./components/InterviewStatusCard.js/interviewStatusCard";
import { useSelector } from "react-redux";
import RatingsCard from "./components/RatingsCard/ratingsCard";
import ProfileSubmittedCard from "./components/ProfileSubmittedCard";
import { OfferCards } from "./components/OfferCard";
import { OnBillingCard } from "./components/onBillingCard";
import moment from "moment";
import { CandidateCancelledCard } from "./components/CandidateCancelledCard";
import AttachmentContainer from "./components/fileAttachment";
import WS from "../../../../webSocket/defaultSocket";
import { useParams } from "react-router-dom/cjs/react-router-dom.min";
export const ChatCard = React.memo(
  ({
    chatDetails,
    loadChat,
    setLoadChat,
    SourceId,
    SourceName,
    records,
    Index,
  }) => {
    const [offerCard, setOfferCard] = useState({});
    const [history, setHistory] = useState();
    const [Retractedby, setRetractedBy] = useState("");
    const msgContainer = useRef();
    const [chatDate, setChatDate] = useState();
    const [chatDateCheck, setChatDateCheck] = useState(false);
    const pramsid = useParams()

    const messageWithDate = [];
    let currentDate = new Date();
    let AccoundId = useSelector((state) => state.profileReducer.user?.profile?.AccountId);

    const loading = useSelector((state) => state.getChatHistoryReducer.loading);
    useEffect(() => {
      
      // setTimeout(() => {
      //   setLoadChat(false);
      // }, 400);
      setLoadChat(loading);
      if (
        chatDetails?.length > 0 &&
        chatDetails[0].Status === "Retracted Bid" &&
        Retractedby === "Emp"
      ) {
        setHistory([chatDetails[0]]);
      } else {
        if (chatDetails?.length > 0) setHistory([...chatDetails].reverse());
      }
      // msgContainer.current.scrollTop = msgContainer.current.scrollHeight;

      // 
      if (chatDetails?.length > 0){
        if (chatDetails[chatDetails?.length - 1]?.MessageGUID) {
          WS?.messageAdminReadAt(
            chatDetails[chatDetails?.length - 1]?.MessageGUID,
            AccoundId
          )
        }
      }
    }, [chatDetails]);

    // useEffect(()=>{
    //   if(history?.length>0){
    //     // setChatDate(history[0].Created);
    //     history.forEach(message => {
    //       const messageDate = new Date(message.Created).toLocaleDateString();
    //       if(messageDate != currentDate){
    //         messageWithDate.push({date:messageDate});
    //         currentDate = messageDate;
    //       }
    //       else{
    //         messageWithDate[messageWithDate.length=1].history.push(message)
    //       }
    //     });
    //   }
    // },[chatDetails])

    // let userProfile = JSON.parse(localStorage.getItem("userProfile"));
    // let userProfile = useSelector((state) => state.authReducer);
    let userProfile = useSelector((state) => state.profileReducer.user);
    // const loading = useSelector(state => state.messagesReducer.historyLoading)

    const editResponse = useSelector(
      (state) => state.messagesReducer.editOfferRes
    );

    // 

    const getProfilePic = async (user, data) => {
      let profilePic = "";

      if (user == "emp") {
        if (data.SenderUser?.profile?.PictureUrl) {
          profilePic = await getImage(data.SenderUser.profile.PictureUrl);
        } else {
          profilePic =
            "https://cdn.pixabay.com/photo/2015/04/23/22/00/tree-736885__480.jpg";
        }
      } else if (user == "candidate") {
        if (data.RecipientUser?.profile?.PictureUrl) {
          profilePic = await getImage(data.RecipientUser.profile.PictureUrl);
        } else {
          profilePic =
            "https://cdn.pixabay.com/photo/2015/04/23/22/00/tree-736885__480.jpg";
        }
      }

      return profilePic;
    };

    return (
      <div
        className="chatcard"
        ref={msgContainer}
        aria-details={chatDetails?.length > 1 && "more-than-one-msg"}
        key={Math.floor(Math.random() * 999)}
      >
        {loadChat ? (
          <div className="InnerChatCont">
            <Row className="spaceTop">
              <div className="left">
                <CardParent htmlValue={false}>
                  <Skeleton active />
                </CardParent>
              </div>
              <div className="right" style={{ marginTop: "3%" }}>
                <CardParent htmlValue={false}>
                  <Skeleton active />
                </CardParent>
              </div>
            </Row>
          </div>
        ) : (
          history?.map((items, index) => {
            if (items?.SenderLabels !== null) {
              var nonNullIndex = history
                .slice(0, index + 1)
                .reduce(
                  (count, currentItem) =>
                    count + (currentItem?.SenderLabels !== null),
                  0
                );
            }

            let showDate = false;

            if(index === (history.length - 1)){
              showDate = true
            }
             else if(moment(items?.Created).format("MMMM Do YYYY") !== moment(history[index+1]?.Created).format("MMMM Do YYYY")){
                showDate = true
              }else{
               showDate = false
            }

            return (
              <div className="InnerChatCont" key={items?._id}>
                 {
                   showDate ?
                (
                  <div className="conv-Date">
                    {moment(items?.Created).diff(currentDate, 'days') === 0 ?
                        <div style={{
                          marginLeft: '10%',
                          color: '#a3a3a3',
                          marginTop: '5%'
                        }}>Today</div>
                        : moment(items?.Created).diff(currentDate, 'days') === -1 ?
                            <div style={{
                              marginLeft: '7%',
                              color: '#a3a3a3',
                              marginTop: '5%'
                            }}>Yesterday</div>
                            : <div style={{
                              color: '#a3a3a3',
                              marginTop: '5%'
                            }}>{moment(items?.Created).format("MMMM Do YYYY")}</div>}
                  </div>
                ) : null }
                <Row className="spaceTop">
                  <div
                    className={
                      // userProfile._id == items?.SenderUser._id &&
                      //   items?.RecipientUser._id != userProfile._id
                      //   ? items?.SourceTypeName == "GigListing"
                      //     ? "left"
                      //     : items?.SourceTypeName == "SkillListing"
                      //       ? "right"
                      //       : ""
                      //   : "left"
                      items?.SenderUser?._id
                        ? userProfile?.id === items?.SenderUser?._id
                          ? "right"
                          : "left"
                        : userProfile?.profile?.AccountId ===
                          items?.SenderProfileId
                        ? "right"
                        : "left"
                    }
                  >
                    {items?.SecondarySourceTypeId == 3 ||
                    items?.SecondarySourceTypeId == 4 ? (
                      items?.Status == "Candidate Cancelled" ||
                      items?.Status == "Candidate Terminated" ? (
                        <CardParent
                          htmlValue={false}
                          btns={true}
                          status={items?.Status}
                          Created={items?.Created}
                          data={items}
                        >
                          <CandidateCancelledCard
                            data={items}
                            status={items?.Status}
                          />
                        </CardParent>
                      ) : items?.Status == "Accepted Bid" ||
                        items?.Status == "Declined Bid" ||
                        items?.Status == "Terminated" ? (
                        <CardParent
                          status={items?.Status}
                          htmlValue={false}
                          Created={items?.Created}
                          data={items}
                        >
                          <BidAcceptedCard
                            data={items}
                            status={items?.Status}
                            MessageText={items?.MessageText}
                            bid_id={
                              items?.BidCard?.BidId && items?.BidCard?.BidId
                            }
                            SkillListing_id={
                              items?.SkillListingCard?.SkillListingId
                            }
                          />
                        </CardParent>
                      ) : items?.Status == "Rating" ? (
                        <CardParent
                          Ratings_data={
                            items?.BidCard
                              ? items?.BidCard?.rating
                              : items?.ProposalCard?.rating
                          }
                          BidId={
                            items?.BidCard
                              ? items?.BidCard?.BidId
                              : items?.ProposalCard?.Id
                          }
                          bidType={items?.BidCard ? "bid" : "Proposal"}
                          htmlValue={false}
                          ratings={true}
                          Created={items.Created}
                          data={items}
                        >
                          <RatingsCard
                            Rating={items?.SecondarySourceTypeId}
                            Ratings_data={
                              items?.BidCard
                                ? items?.BidCard?.rating
                                : items?.ProposalCard?.rating
                            }
                            items={items}
                          />
                        </CardParent>
                      ) : items.Status == "New Bid" ||
                        items.Status == "Retracted Bid" ? (
                        <ReceivedBidsCard
                          status={items?.Status}
                          name={
                            items?.SourceTypeId == 2
                              ? items?.RecipientUser?.UserName
                              : items?.SourceTypeId == 1
                              ? items?.SenderUser?.UserName
                              : "User Name"
                          }
                          img={
                            items?.SourceTypeId == 2
                              ? getProfilePic("candidate", items)
                              : items?.SourceTypeId == 1
                              ? getProfilePic("emp", items)
                              : ""
                          }
                          cardDetails={items?.BidCard ?? items?.ProposalCard}
                          SkillListingCard={
                            items?.SkillListingCard && items?.SkillListingCard
                          }
                          favourite={items?.BidCard ? "Bid" : "Proposal"}
                          GigCard={items?.GigCard}
                          source={items?.SourceTypeId}
                          Created={items?.Created}
                          data={items}
                          setRetractedBy={setRetractedBy}
                          records={records}
                          SourceName={SourceName}
                          SourceId={SourceId}
                          Index={Index}
                          nonNullIndex={nonNullIndex}
                        />
                      ) : items?.Status == null ? (
                        <>
                          <CardParent
                            htmlValue={true}
                            threebtns={false}
                            attachments={items?.Attachments}
                            Created={items?.Created}
                            data={items}
                            subject={items?.Subject}
                          >
                            {items?.MessageText}
                          </CardParent>

                          {/* <CardParent htmlValue={false} ratings={true}>
                                <RatingsCard
                                  Rating={items?.SecondarySourceTypeName}
                                  Ratings_data={items?.Ratings}
                                  items={items}
                                />
                              </CardParent> */}
                        </>
                      ) : items?.Status == "Profile Submitted" ? (
                        <CardParent
                          htmlValue={false}
                          bid_id={
                            items?.BidCard?.BidId
                              ? items?.BidCard?.BidId
                              : items?.Gigcard?._id
                          }
                          SkillListing_id={
                            items?.SkillListingCard?.SkillListingId
                          }
                          data={items}
                          // interviewCardID
                        >
                          <ProfileSubmittedCard data={items} />
                        </CardParent>
                      ) : items?.Status == "Off Billing" ||
                        items?.Status == "Billing Extended" ? (
                        <CardParent
                          htmlValue={false}
                          btns={true}
                          status={items?.Status}
                          Created={items?.Created}
                          data={items}
                        >
                          <OnBillingCard data={items} status={items?.Status} />
                        </CardParent>
                      ) : items?.Status == "Offer Pending" ? (
                        <OfferCards
                          status={items?.Status}
                          items={items}
                          OfferCards={
                            editResponse?._id ? editResponse : items?.OfferCard
                          }
                          index={nonNullIndex}
                        />
                      ) : items?.Status == "Offer Accepted" ||
                        items?.Status == "Offer Declined" ||
                        items?.Status === "Hired" ? (
                        <CardParent
                          htmlValue={false}
                          status={items?.Status}
                          OfferCards={
                            editResponse?._id ? editResponse : items?.OfferCard
                          }
                          Created={items.Created}
                          data={items}
                        >
                          <OfferCards
                            status={items?.Status}
                            OfferCards={
                              editResponse?._id
                                ? editResponse
                                : items?.OfferCard
                            }
                            items={items}
                            index={nonNullIndex}
                          />
                        </CardParent>
                      ) : items?.Status == "On Billing" ||
                        items?.Status == "Billing Extended" ? (
                        <CardParent
                          htmlValue={false}
                          btns={true}
                          status={items?.Status}
                          Created={items?.Created}
                          data={items}
                        >
                          <OnBillingCard data={items} status={items?.Status} />
                        </CardParent>
                      ) : items?.Status == "Off Billing" ? (
                        <CardParent
                          htmlValue={false}
                          btns={true}
                          status={items?.Status}
                          Created={items?.Created}
                          data={items}
                        >
                          <OnBillingCard data={items} status={items?.Status} />
                        </CardParent>
                      ) : (
                        (items?.Status == "Candidate Cancelled" ||
                          items?.Status == "Candidate Terminated") && (
                          <CardParent
                            htmlValue={false}
                            btns={true}
                            status={items?.Status}
                            Created={items?.Created}
                            data={items}
                          >
                            <CandidateCancelledCard
                              data={items}
                              status={items?.Status}
                            />
                          </CardParent>
                        )
                      )
                    ) : items?.SecondarySourceTypeId == 4 ? (
                      <ReceivedBidsCard
                        status={items?.Status}
                        name={
                          items?.SourceTypeId == 2
                            ? items?.RecipientUser?.UserName
                            : items?.SourceTypeId == 1
                            ? items?.SenderUser?.UserName
                            : "User Name"
                        }
                        img={
                          items?.SourceTypeId == 2
                            ? getProfilePic("candidate", items)
                            : items?.SourceTypeId == 1
                            ? getProfilePic("emp", items)
                            : ""
                        }
                        cardDetails={items?.ProposalCard}
                        SkillListingCard={
                          items?.SkillListingCard && items?.SkillListingCard
                        }
                        GigCard={items?.GigCard}
                        source={items?.SourceTypeId}
                        Created={items?.Created}
                        data={items}
                        setRetractedBy={setRetractedBy}
                        records={records}
                        SourceName={SourceName}
                        SourceId={SourceId}
                      />
                    ) : items?.SecondarySourceTypeId == null ? (
                      <CardParent Created={items?.Created}>
                        {items?.MessageText}
                      </CardParent>
                    ) : (
                      items?.SecondarySourceTypeId == 5 &&
                      items?.Status != "Share your feedBack" && (
                        <div
                          style={{ display: "flex", flexDirection: "column" }}
                        >
                          {/* <CardParent
                        chatDetails={chatDetails}
                        Created={items?.Created}
                        btns={!!items?.SkillListingCard?.SkillListingId}
                        status={items?.Status}
                        htmlValue={false}
                        bid_id={items?.BidCard?._id && items?.BidCard?.BidId}
                        SkillListing_id={items?.SkillListingCard?.SkillListingId}
                        interviewCardID={
                          items?.InterviewCard?._id && items?.InterviewCard?.InterviewId
                        }
                      > */}
                          <InterviewStatusCards
                            index={nonNullIndex}
                            status={items?.Status}
                            InterviewCard={
                              items?.InterviewCard && items?.InterviewCard
                            }
                            BidStatus={items?.Status}
                            items={items}
                          />
                          {/* </CardParent> */}

                          {/* 
                          < CardParent htmlValue={false}>
                            <InterviewStatusCards
                              status={items?.Status}
                              accepted={true}
                            // InterviewCard={items?.InterviewCard && items?.InterviewCard}
                            // BidStatus={items?.Status}
                            // items={items}
                            />
                          </CardParent> */}
                        </div>
                      )
                    )}
                  </div>
                </Row>
              </div>
            );
          })
        )}
      </div>
    );
  }
);
