import React, { useState } from "react";
import "./BhsCard.css";
import { useEffect } from "react";
import FavoriteFunction from "../../../../Bid/FavoriteFunction/FavoriteFunction";
import { alertNotification } from "../../../../../components/notifications/alertNotification";
import AdrModel from "./AdrModel";
import { Radio } from "antd";
import { getProposedInterviewDateAndTime } from "./bhsHandler";
import moment from "moment";
import {
  fetchDeclinedReasonsBids,
  InterviewScheduleAcceptedInit,
  postFavoriteGigCard,
  removeFavoriteGigCard,
  updateDeclinedReasonsReceivedBids,
} from "../../../../../redux/actions";
import { useHistory } from "react-router-dom";
import { useDispatch, useSelector } from "react-redux";
import {
  getFileFormat,
  getFileIcon,
} from "../../../../../components/fileInformation";
import Loader from "../../../../../components/Loader/Loader";
import msgBidHis from "../../../../../assets/img/msgBidHis.png";

const BhsInterviewTimingSection = ({
  Info: [modelInfo, setModelInfo, Rank, data],
}) => {
  const [selectedIT, setSelectedIT] = useState("");
  const InterviewTiming =
    // modelInfo?.InterviewAccepted
    //   ? modelInfo?.InterviewSelected
    //   : modelInfo?.InterviewRescheduled
    //   ? modelInfo?.InterviewRescheduled
    //   :
    data?.BidStage?.Interview?.AvailableIT;
  return (
    <>
      <div className="bhsInterviewTimingSection">
        <div className="bhsInterviewTimingheading">
          {data?.BidStage?.Interview?.InterviewStatusName && Rank === 7
            ? "Interview Scheduled on:"
            : "Proposed Interview Timings"}
        </div>
        <Radio.Group
          value={selectedIT}
          onChange={(e) => {
            setSelectedIT(e.target.value);
            setModelInfo({
              ...modelInfo,
              error: "",
              InterviewSelected: [InterviewTiming[e.target.value]],
            });
          }}
          className="bhsInterviewTimingList"
        >
          {InterviewTiming?.map((date, index) => {
            const time = new Date(date?.StartTime);
            const TimeArr = getProposedInterviewDateAndTime(time);
            return (
              <React.Fragment key={index}>
                {data?.BidStage?.Interview?.InterviewStatusName ===
                  "Rescheduled" || Rank === 7 ? (
                  <>
                    <div
                      className="bhsInterviewTimingItem bhsInteviewAccepted"
                      key={index}
                    >
                      <div className="textBold">
                        {TimeArr[0] + ", " + TimeArr[1] + ", " + TimeArr[2]}
                      </div>
                      <div className="textLight">
                        {TimeArr[3]} - {TimeArr[4]}
                      </div>
                    </div>
                  </>
                ) : (
                  <Radio
                    value={index}
                    className="bhsInterviewTimingItem"
                    key={index}
                    disabled={Rank === 8 ? true : false}
                  >
                    <div className="textBold">
                      {TimeArr[0] + ", " + TimeArr[1] + ", " + TimeArr[2]}
                    </div>
                    <div className="textLight">
                      {TimeArr[3]} - {TimeArr[4]}
                    </div>
                  </Radio>
                )}
              </React.Fragment>
            );
          })}
        </Radio.Group>
      </div>
    </>
  );
};

export const BhsFileSection = ({
  fileInfo: [FileName, FileSize, FilePath],
}) => {
  const handlingOfferDownload = async () => {
    if (FilePath) {
      const element = document.createElement("a");
      element.href = `${process.env.REACT_APP_S3_URL}${
        FilePath.indexOf("/") === 0 ? FilePath : "/" + FilePath
      }`;
      element.target = "_blank";
      element.download = FileName;
      element.click();
      alertNotification("success", "Offer downloaded successfully");
    }
  };
  return (
    <div className="bhsFileSection" onClick={handlingOfferDownload}>
      <img src={getFileIcon(getFileFormat(FileName))} alt="" />
      <div className="bhsFileInfo">
        <div className="bhsFileTitle">{FileName}</div>
        <div className="bhsFileSize">{Math.round(FileSize / 1000)} KB</div>
      </div>
    </div>
  );
};

const BhsReasonSection = ({ DeclineReason }) => {
  return <div className="bhsReasonSection">Reason : {DeclineReason}</div>;
};

const BhsButton = ({
  btn,
  BidType,
  apiCall,
  Rescheduled,
  modelInfo: [
    modelInfo,
    setModelInfo,
    btnClick,
    setBtnClick,
    isDisableBtn,
    data,
  ],
}) => {
  const { loading: loadingBid } = useSelector((state) => state.BidsReducer);
  const { loading: loadingInterview } = useSelector(
    (state) => state.interviewReducer
  );
  const { loading: loadingOffer } = useSelector((state) => state.offerReducer);
  const dispatch = useDispatch();
  const handlingDR = () => {
    setModelInfo({
      ...modelInfo,
      type: btn,
      BidType,
      setBtnClick,
      isOpen: true,
    });
  };
  const handlingButton = (e, btn) => {
    if (
      (btn === "Accept" && !["Bid", "Interview"].includes(BidType)) ||
      btn === "Reschedule"
    ) {
      setModelInfo({
        ...modelInfo,
        type: btn,
        BidType,
        setBtnClick,
        isOpen: true,
      });
    } else if (btn === "Decline") {
      // declineReasonAPiCall with BidType
      dispatch(
        fetchDeclinedReasonsBids(
          BidType === "Offer" ? "interview" : BidType?.toLowerCase()
        )
      );
      handlingDR();
    } else if (btn === "Accept" && ["Bid", "Interview"].includes(BidType)) {
      // acceptedAPiCall with BidType
      if (BidType === "Interview") {
        // Interview Accepted API Call
        if (modelInfo?.InterviewSelected) {
          setBtnClick([btn, btn + "ed"]);
          setModelInfo({ ...modelInfo, InterviewAccepted: true });
          dispatch(
            InterviewScheduleAcceptedInit({
              id: data?.BidStage?.Interview?.InterviewId,
              reschedule: true,
              body: {
                ApprovedScheduledTiming: modelInfo?.InterviewSelected[0],
                InterviewStatus: "Accepted",
                Message: "You have accepted the interview",
              },
            })
          );
        } else {
          setModelInfo({
            ...modelInfo,
            error: "Please select any one of the interview time given above",
          });
        }
      } else {
        // Bid Accepted API Call
        setBtnClick([btn, btn + "ed"]);
        
        dispatch(
          updateDeclinedReasonsReceivedBids({
            id: data?.BidId,
            body: {
              Message: "Update Bid by talent",
              BidStatusName: "Accepted",
            },
          })
        );
      }
    }
  };
  const disable = isDisableBtn;
  if (!(loadingBid || loadingInterview || loadingOffer) && btnClick?.length) {
    apiCall();
    setBtnClick([]);
  }
  const ButtonArr = ["Interested", "Not Interested"];
  
  return (
    <>
      {btnClick[0] === btn ? (
        <div className="bhsBtn">
          <Loader
            color={"#fff"}
            size={"14px"}
            height={"0px"}
            scrolling={false}
          />
        </div>
      ) : (
        <div
          className={`bhsBtn
         ${disable ? "bhsDisableBtn" : ""}
         ${Rescheduled}
         `}
          onClick={(e) => {
            e.stopPropagation();
            if (!(disable || btnClick[1] === "Declined")) {
              handlingButton(e, btn);
            }
          }}
        >
          {btnClick?.length && btnClick[0] === btn
            ? btnClick[1] === "Accepted"
              ? ButtonArr[0]
              : btnClick[1] === "Declined"
              ? ButtonArr[1]
              : btnClick[1]
            : ["Accept", "Accepted"].includes(btn)
            ? ButtonArr[0]
            : ["Decline", "Declined"].includes(btn)
            ? ButtonArr[1]
            : btn}
        </div>
      )}
    </>
  );
};

const BhsButtonSection = ({
  BidType,
  Info: [modelInfo, setModelInfo, Rank, data],
  apiCall,
}) => {
  const [btnClick, setBtnClick] = useState([]);
  const [dimensions, setDimensions] = useState(window.innerWidth);

  let buttonArray = [
    `Accept${[3, 7, 10].includes(Rank) ? "ed" : ""}`,
    `Decline${[4, 8, 11].includes(Rank) ? "d" : ""}`,
  ];
  if (BidType === "Interview") buttonArray.push("Reschedule");
  if (data?.BidStage?.Interview?.InterviewStatusName === "Rescheduled") {
    buttonArray.shift();
  }

  const checkDimensions = () => {
    setDimensions(window.innerWidth);
  };

  useEffect(() => {
    window.addEventListener("resize", checkDimensions);
  }, []);
  
  return (
    <>
      <div
        className={`bhsButtonSection ${
          dimensions < 768 ? "Rescheduled-btn-width" : ""
        }`}
      >
        {buttonArray.map((btn, index) => {
          const isDisableBtn = [3, 4, 8, 10, 11].includes(Rank)
            ? true
            : Rank === 7 && btn === "Accepted"
            ? true
            : false;
          return (
            <BhsButton
              btn={btn}
              key={index}
              BidType={BidType}
              apiCall={apiCall}
              Rescheduled={
                btn === "Reschedule" &&
                (buttonArray?.includes("Accept") ||
                  data?.BidStage?.Interview?.InterviewStatusName ===
                    "Accepted") &&
                dimensions < 768
                  ? "Reschedule-grid"
                  : ""
              }
              modelInfo={[
                modelInfo,
                setModelInfo,
                btnClick,
                setBtnClick,
                isDisableBtn,
                data,
              ]}
            />
          );
        })}
      </div>
    </>
  );
};

const BhsBidStatusInfo = ({ data, apiCall }) => {
  const [modelInfo, setModelInfo] = useState({
    type: "",
    isOpen: false,
  });
  let content = "";
  const Rank = data?.BidStage?.Rank;
  
  const BidId = data?.BidId;

  switch (true) {
    // New Bid & Decline
    case Rank >= 1 && Rank <= 4:
      content = (
        <>
          {Rank === 4 || modelInfo?.DeclineReason ? (
            <BhsReasonSection
              DeclineReason={
                data?.BidStage?.DeclineReason !== "NA"
                  ? data?.BidStage?.DeclineReason
                  : modelInfo?.DeclineReason
                  ? modelInfo?.DeclineReason
                  : "NA"
              }
            />
          ) : null}
          <BhsButtonSection
            BidType={"Bid"}
            Info={[modelInfo, setModelInfo, Rank, data]}
            apiCall={apiCall}
          />
        </>
      );
      break;

    // Interview Section
    case Rank >= 6 && Rank <= 8:
      content = (
        <>
          <BhsInterviewTimingSection
            Info={[modelInfo, setModelInfo, Rank, data]}
          />
          {Rank === 8 || modelInfo?.DeclineReason ? (
            <BhsReasonSection
              DeclineReason={
                data?.BidStage?.DeclineReason !== "NA"
                  ? data?.BidStage?.DeclineReason
                  : modelInfo?.DeclineReason
                  ? modelInfo?.DeclineReason
                  : "NA"
              }
            />
          ) : null}
          {modelInfo?.error && (
            <div className="bhsError">{modelInfo?.error}</div>
          )}
          <BhsButtonSection
            BidType={"Interview"}
            Info={[modelInfo, setModelInfo, Rank, data]}
            apiCall={apiCall}
          />
        </>
      );
      break;

    // Offer Section
    case Rank >= 9 && Rank <= 11:
      content = (
        <>
          <BhsFileSection
            fileInfo={[
              data?.BidStage?.Offer?.FileName,
              data?.BidStage?.Offer?.FileSize,
              data?.BidStage?.Offer?.FilePath,
            ]}
          />
          {Rank === 11 || modelInfo?.DeclineReason ? (
            <BhsReasonSection
              DeclineReason={
                data?.BidStage?.DeclineReason !== "NA"
                  ? data?.BidStage?.DeclineReason
                  : modelInfo?.DeclineReason
                  ? modelInfo?.DeclineReason
                  : "NA"
              }
            />
          ) : null}
          <BhsButtonSection
            BidType={"Offer"}
            Info={[modelInfo, setModelInfo, Rank]}
            apiCall={apiCall}
          />
        </>
      );
      break;
    default:
      break;
  }
  return (
    <>
      {content && <div className="bhsBidStatusInfo">{content}</div>}
      {modelInfo.isOpen && <AdrModel info={[modelInfo, setModelInfo, data]} />}
    </>
  );
};

const BhsBidStatusTimeInfo = ({ data }) => {
  const history = useHistory();
  let showBidStage = [];
  if (data?.Created) {
    showBidStage.push(`Bid Received on ${moment(data?.Created).format("lll")}`);
    if (data?.BidStage?.Rank > 1) {
      showBidStage?.push(
        `Bid ${
          data?.BidStage?.Status === "Accepted"
            ? "Interested"
            : data?.BidStage?.Status === "Declined"
            ? "Not Interested"
            : data?.BidStage?.Status
        } on ${moment(data?.BidStage?.Modified).format("lll")}`
      );
    }
  }
  return (
    <div>
      <div className="bhsBidStatusTimeInfo">
        <div className="bhsUserMobile-time">
          {showBidStage?.map((val, index) => {
            return (
              <div className="bhsBidStatusTimeItem" key={index}>
                <div className="bhsBidStatusTime">{val}</div>
                {index === 0 && window.innerWidth > 768 && (
                  <div
                    className="bhsOpenChat"
                    onClick={(e) => {
                      e.stopPropagation();
                      history.push({
                        pathname: `/messages`,
                        state: {
                          openConvo: true,
                          conversationId: data?.ConversationId,
                        },
                      });
                    }}
                  >
                    Open Chat
                  </div>
                )}
              </div>
            );
          })}
        </div>
        <div className="bhsUserMobile-chat">
          <div
            onClick={(e) => {
              e.stopPropagation();
              history.push({
                pathname: `/messages`,
                state: {
                  openConvo: true,
                  conversationId: data?.ConversationId,
                },
              });
            }}
          >
            <img src={msgBidHis} />
          </div>
        </div>
      </div>
    </div>
  );
};

const BhsUserInfo = ({ data }) => {
  const history = useHistory();
  const dispatch = useDispatch();
  const [favirote, setFavirote] = useState(false);
  const addFavorite = () => {
    setFavirote(true);
    alertNotification("success", "bid added to favorites");
    dispatch(postFavoriteGigCard(data?.GigId, "Bid"));
  };
  const removeFavorite = () => {
    setFavirote(false);
    alertNotification("success", "bid removed from favorites");
    dispatch(removeFavoriteGigCard(data?.GigId));
  };

  useEffect(() => {
    setFavirote(data.IsFavorite ? data.IsFavorite : favirote);
  }, [data.IsFavorite]);

  const getBidRateType = (type) =>
    type === 2
      ? "wk"
      : type === 1
      ? "hr"
      : type === 4
      ? "yr"
      : type === 3
      ? "mm"
      : "Daily";
  return (
    <>
      <div className="bhsUserInfo">
        <div className="bhsUser">
          <div className="bhsUserName">{data?.EmployerName}</div>
          <div className="bhsBidInfo">
            <div className="bhsUserBidRate">
              {`$${data.Rate}/${getBidRateType(data?.BudgetTypeName)}`}
            </div>
            <div className="bhsUserBidStatus">
              {data?.BidStage?.Status === "Accepted"
                ? "Interested"
                : data?.BidStage?.Status === "Declined"
                ? "Not Interested"
                : data?.BidStage?.Status}
            </div>
          </div>
        </div>
        <div className="bhsUserBTF">
          <div
            className="bhsUserBidTitle"
            onClick={() => history.push(`/receivedbid-detail/${data?.BidId}`)}
          >
            {data?.Title}
          </div>
          <div className="bhsUserfavorite">
            <FavoriteFunction
              isFavorite={favirote}
              addFavorite={addFavorite}
              removeFavorite={removeFavorite}
            />
          </div>
        </div>
        <div className="bhsUserLocation">{data.Location}</div>
      </div>
    </>
  );
};

// Bid History SkillListing Card Component
const BhsCard = ({ data, apiCall }) => {
  return (
    <>
      <div className="bhsCardBox">
        <BhsUserInfo data={data} />
        <BhsBidStatusTimeInfo data={data} />
        <BhsBidStatusInfo data={data} apiCall={apiCall} />
      </div>
    </>
  );
};

export default BhsCard;
