import React, { useEffect, useRef } from "react";
import BannerImg from "../../assets/icons/BannerImg.svg";
import { Row, Col, Rate, Input, Carousel, Empty, Button, Modal } from "antd";
import bannerImgMobile from "../../assets/icons/bannerImgMobile.svg";
import Ourcultureimg from "../../assets/img/Ourcultureimg.png";
import { BiChevronLeft, BiChevronRight } from "react-icons/bi";
import "./Styles.css";
import { useState } from "react";
import FacebookGroup from "../../assets/icons/FacebookGroup.png";
import InstaGroup from "../../assets/icons/InstaGroup.png";
import LinkedInGroup from "../../assets/icons/LinkedInGroup.png";
import TwitterGroup from "../../assets/icons/TwitterGroup.png";
import YoutubeGroup from "../../assets/icons/YoutubeGroup.png";
import {
  ApolloClient,
  ApolloProvider,
  InMemoryCache,
  useLazyQuery,
} from "@apollo/client";
import { setContext } from "@apollo/client/link/context";
import { HttpLink } from "apollo-link-http";
import { useDispatch, useSelector } from "react-redux";
import { useLocation } from "react-router-dom";
import { LANDING_PAGE_QUERY } from "./LandingPageQuery";
import Loader from "../../components/Loader/Loader";
import { UpdateSearchFilter, fetchUserProfile, setLandingPageDetails } from "../../redux/actions";
import GigsCard from "../SearchGigs/GigsCard";
import { useHistory } from "react-router-dom";
import { getPictureUrl } from "../../assets/helpers";
import { RightOutlined } from "@ant-design/icons";
const EmployerLandingPage = (props) => {
  const carouselRef = useRef();
  const history = useHistory();

  const subrout = useLocation().pathname.split("/")[2];
  const [hitQuery, { loading, error, data }] = useLazyQuery(LANDING_PAGE_QUERY);
  const dispatch = useDispatch();
  const [Dimensions, setDimensions] = useState(getWindowDimensions());
  function getWindowDimensions() {
    const { innerWidth: width, innerHeight: height } = window;
    return {
      width,
      height,
    };
  }

  const SeeAllfunctionPvt = (EmpName, AccountId) => {
    dispatch(UpdateSearchFilter("PrivateEmployerId", [AccountId]));
    localStorage.setItem(
      "PrivateEmployer",
      JSON?.stringify({ EmpName, AccountId })
    );

    history.push({
      pathname: "/search/Pvt",
      state: {
        EmpName: EmpName,
        AccountId: AccountId,
      },
    });
  };

  useEffect(() => {
    hitQuery({ variables: { empName: subrout } });
    localStorage.removeItem("socialSignUpWithEmp");
    localStorage.removeItem("employer");
  }, []);

  useEffect(() => {
    dispatch(setLandingPageDetails(data?.privateEmp?.Emp));
  }, [data]);

  useEffect(() => {
    function handleResize() {
      setDimensions(getWindowDimensions());
    }
    dispatch(fetchUserProfile({ empName: subrout }));

    window.addEventListener("resize", handleResize);
    return () => window.removeEventListener("resize", handleResize);
  }, []);

  const getCount = () => data?.privateEmp?.gigs?.counts;

  const getGigs = () => data?.privateEmp?.gigs?.data;

  if (loading) return <Loader />;

  const getIconByType = (item) => {
    switch (item?.type) {
      case "facebook":
        return FacebookGroup;
      case "twitter":
        return TwitterGroup;
      case "linkedIn":
        return LinkedInGroup;
      case "instagram":
        return InstaGroup;
      case "youtube":
        return YoutubeGroup;
      default:
        return FacebookGroup;
    }
  };

  const nextSlide = () => {
    carouselRef.current.next();
  };
  const prevSlide = () => {
    carouselRef.current.prev();
  };
  return (
    <div className="page-container">
      <div className="mainSec">
        <div className="bannerSec">
          <img
            className="bannerImg"
            src={getPictureUrl(data?.privateEmp?.Emp?.banner?.desktop)}
          />
          <img
            className="bannerImg-mobile"
            src={getPictureUrl(data?.privateEmp?.Emp?.banner?.mobile)}
            alt="Mobile Banner"
          />
        </div>

        <div className="job-container" style={{ padding: "0" }}>
          <div className="mobile-view-wrapper">
            <div className="our-gigs-wrapper">
              <Row className="jobsTileCont">
                <Col xs={24} sm={12}>
                  <div className="job-headings-emp">
                    Our open gigs
                    <div className="job-length-emp">
                      {getCount() ? getCount() : 0}
                    </div>
                  </div>
                </Col>
                {Dimensions.width >= 768 && (
                  <Col
                    span={12}
                    style={{ display: "flex", justifyContent: "flex-end" }}
                  >
                    <div
                      className="comp-main comp-heading2"
                      onClick={() =>
                        SeeAllfunctionPvt(
                          data?.privateEmp?.Emp?.empTitle,
                          data?.privateEmp?.Emp?.EmpAccountId
                        )
                      }
                      style={{
                        cursor: getCount() < 3 && "default",
                        display: "flex",
                        textAlign: "center",
                        fontSize: "14px",
                      }}
                    >
                      {getCount() > 3 && "See all"}
                    </div>
                  </Col>
                )}
              </Row>
            </div>
            {getGigs()?.length > 0 ? (
              <div className="gigs-content-landing">
                <Row
                  style={{
                    paddingTop: "10px",
                    // paddingBottom: "35px",
                    paddingLeft: Dimensions.width < 786 ? "0" : "0",
                    width: "100%",
                  }}
                  justify="space-between"
                  gutter={[
                    { xs: 0, sm: 8, md: 16 },
                    { xs: 0, sm: 6, md: 12, lg: 16 },
                  ]}
                >
                  {getGigs().map((item, index) => {
                    return (
                      <Col
                        key={index}
                        xs={24}
                        sm={12}
                        md={8}
                        lg={8}
                        xl={8}
                        style={{ marginBottom: "16px" }}
                      >
                        <GigsCard
                          containerClass={"emp-gig-page"}
                          data={item}
                          employerTitle={subrout}
                          showEmpName={true}
                          empName={subrout}
                        />
                      </Col>
                    );
                  })}
                </Row>
              </div>
            ) : (
              <Col xs={24}>
                <Empty />
              </Col>
            )}
            <div className="see-all-wrapper">
              {Dimensions.width < 768 && (
                <Row
                  style={{
                    justifyContent: "center",
                    paddingBottom: "35px",
                  }}
                >
                  <Col span={18} style={{ textAlign: "center" }}>
                    <div
                      className="comp-main comp-heading2 fw-400"
                      onClick={() =>
                        SeeAllfunctionPvt(
                          data?.privateEmp?.Emp?.empTitle,
                          data?.privateEmp?.Emp?.EmpAccountId
                        )
                      }
                      style={{ cursor: getCount() < 3 && "default" }}
                    >
                      {getCount() > 3 && "See all"}
                    </div>
                  </Col>
                  <Col
                    span={6}
                    style={{ display: "flex", justifyContent: "flex-end",paddingRight:'2px' }}
                  >
                    <span
                      className="comp-heading2 fw-600"
                    >
                      <RightOutlined />
                    </span>
                  </Col>
                </Row>
              )}
            </div>
          </div>
        </div>

        <div className="Slider-Container" style={{ position: "relative" }}>
          <div className="Heading-OurCUlture">
            <p>Our Culture</p>
          </div>
          <Carousel
            ref={carouselRef}
            slidesToShow={
              data?.privateEmp?.Emp?.cultureImages?.length >= 3
                ? 3
                : data?.privateEmp?.Emp?.cultureImages?.length
            }
            dots={false}
          >
            {data?.privateEmp?.Emp?.cultureImages?.length &&
              data?.privateEmp?.Emp?.cultureImages.map((el, ind) => (
                <div style={{ margin: "0 5px 0 0" }} key={el + ind}>
                  <img
                    src={getPictureUrl(el)}
                    alt={"culture -" + ind}
                    style={{ width: "95%", backgroundRepeat: "no-repeat" }}
                  />
                </div>
              ))}
            <img
              src={getPictureUrl(data?.privateEmp?.Emp?.cultureImages[0])}
              alt={"culture -"}
              style={{ width: "95%", backgroundRepeat: "no-repeat" }}
            />
          </Carousel>
          {data?.privateEmp?.Emp?.cultureImages?.length > 3 && (
            <>
              <Button
                className="Carausel-btn-left"
                type="primary"
                shape="square"
                icon={<BiChevronLeft style={{ fontSize: "1.8rem" }} />}
                onClick={prevSlide}
              />
              {/* Right Arrow button */}
              <Button
                className="carausel-btn-right"
                type="primary"
                shape="square"
                icon={<BiChevronRight style={{ fontSize: "1.8rem" }} />}
                onClick={nextSlide}
              />
            </>
          )}
        </div>
        {/* Left Arrow Button */}

        <div className="container">
          <div className="heading">
            <h3 className="why-do-text">Why we do what we do</h3>
          </div>
          <div className="content">
            <p>
              Think of SHI as your personal technology concierge. We connect
              your team with the IT solutions and services you need to support
              your organizational growth and employee experience.
            </p>
            <p>
              Whether you’re building a modern hybrid workplace, defending
              against an evolving threat landscape, making the cloud work harder
              for you, or searching for ways to optimize your software
              portfolio, our friendly 6,000-person team is ready to solve what’s
              next for your organization.
            </p>
            <p>
              Our in-house data center integration, device configuration, and
              deployment and license advisory services, plus our top-tier status
              with vendors and flexible financing make life simpler for IT
              decision makers.
            </p>
            <p>
              Execute your IT vision with stress-free, scalable solutions you –
              and your people – will love.
            </p>
            <p>
              SHI is proud to be the largest Minority/Woman Owned Business
              Enterprise (MWBE) in the United States.
            </p>
          </div>
        </div>
        <Row>
          <Col span={24}>
            <Row className="followUS">
              <Col span={24}>
                <Row justify="center">
                  <h3>Follow Us</h3>
                </Row>
              </Col>
              <Col span={24}>
                <Row wrap={false} justify="center" gutter={[10, 0]}>
                  {data?.privateEmp?.Emp?.socialMedia?.length &&
                    data?.privateEmp?.Emp?.socialMedia.map((el) => (
                      <Col>
                        <a
                          href={"https://" + el?.link}
                          target="_blank"
                          rel="nonreferrer noreferrer"
                        >
                          <img src={getIconByType(el)} />
                        </a>
                      </Col>
                    ))}
                </Row>
              </Col>
            </Row>
          </Col>
        </Row>
      </div>
    </div>
  );
};

const ApolloWrapper = (props) => {
  const BackendApiBaseUrl = process.env.REACT_APP_API_URL;
  const authTokenValue = useSelector(
    (state) => state.authReducer.user.authToken
  );

  const ImpersonationId = useSelector(
    (state) => state.authReducer.impersonationId
  );

  const httpLink = new HttpLink({
    uri: `${BackendApiBaseUrl}/query`,
  });
  // const user = useSelector((state) => state.profileReducer.user);
  const authLink = setContext((_, { headers }) => {
    const res = {
      headers: {
        ...headers,
        authorization: authTokenValue ? `${authTokenValue}` : "",
        // email: user.profile.EmailAddress,
      },
    };
    if (ImpersonationId) res.headers.impersonationId = ImpersonationId;
    // res.headers.impersonationId = ImpersonationId ? `${ImpersonationId}` : null;
    return res;
  });

  const client = new ApolloClient({
    // uri: `${BackendApiBaseUrl}/query`,
    cache: new InMemoryCache(),
    // link: ApolloLink.from([httpLink]),
    link: authLink.concat(httpLink),
  });

  // ///////////////////////////////////////////////////////////////
  // const userProfile = JSON.parse(localStorage.getItem("userProfile"));

  return (
    <ApolloProvider client={client}>
      <EmployerLandingPage {...props} />
    </ApolloProvider>
  );
};

export default ApolloWrapper;
