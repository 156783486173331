import React, { useState, useEffect } from "react";
import MessageBox from "./MessageBox";
import "./DashboardInbox.css";
import { connect, useDispatch, useSelector } from "react-redux";
import { fetchInboxDataAPI } from "../../../API/API";
import { fetchInboxData } from "../../../redux/actions";
import { fetchUserProfile } from "../../../redux/actions";
import { message } from "antd";
import { useHistory } from "react-router";
const DashboardInbox = (props) => {
  const history = useHistory();
  // history.push('/messages');
  const [conversations, setConversations] = useState([]);

  const inbox = useSelector((state) => state.getChatHistoryReducer.inbox);

  const [dimensions, setDimensions] = useState(window.innerWidth);

  const checkDimensions = () => {
    setDimensions(window.innerWidth);
  };

  useEffect(() => {
    window.addEventListener("resize", checkDimensions);
  }, []);

  const messagePusher = () => {
    if (dimensions < 720){
      history.push("/notifications");
    }
    else{
      history.replace("/messages");
    }
  };
  const dispatch = useDispatch();
  //const inboxData = useSelector((state) => state.messagesReducer.inboxData);
  const { inboxData } = props;
  const [messages, setMessages] = useState([]);
  // useEffect(() => {
  //   dispatch(fetchInboxData(5, 1, "All Conversations", "All Bids", null));
  // }, []);
  useEffect(() => {
    if (inbox?.data?.data?.body?.conversations) {
      setConversations(inbox?.data?.data?.body?.conversations);
    }
  }, [inbox]);

  // 

  useEffect(() => {
    // 
  }, [inboxData]);
  return (
    <>
      <div className="dashboard-inbox-section">
        <div className="dashboard-inbox-chat-container">
          <div className="dashboard-inbox-section-heading  dashboard-inbox-section-heading-class-2">Inbox</div>
          <div
            className="dashboard-inbox-section-heading  dashboard-inbox-section-heading-class2 dashboard-inbox-section-heading-class-3"
            onClick={messagePusher}
          >
            View all
          </div>
        </div>
        {conversations &&
          conversations?.length > 0 &&
          conversations?.map((data,count) => {
            // 
            if (count > 4){
              return null;
            }
            else {
              return <MessageBox data={data} />;
            }
          })}
      </div>
    </>
  );
};
const mapStateToProps = ({ messagesReducer }) => {
  return {
    inboxData: messagesReducer.inboxData.conversations,
  };
};
export default connect(mapStateToProps, null)(DashboardInbox);
