import React, { useEffect, useState } from "react";
import {
  Modal,
  DatePicker,
  Dropdown,
  Menu,
  Button,
} from "antd";
import "./style.css";
import moment from "moment";
import { DownOutlined, LoadingOutlined } from "@ant-design/icons";


const InterviewDrawer = (props) => {
  const Loading = false;

  const [visible, setVisible] = useState();
  const [windowDimensions, setWindowDimensions] = useState(
    getWindowDimensions()
  );
  const [interviewStartTime, setInterviewStartTime] = useState("");
  const [interviewEndTime, setInterviewEndTime] = useState("");
  const [interviewStartTime2, setInterviewStartTime2] = useState("");
  const [interviewEndTime2, setInterviewEndTime2] = useState("");
  const [interviewStartTime3, setInterviewStartTime3] = useState("");
  const [interviewEndTime3, setInterviewEndTime3] = useState("");
  const [firstDate, setfirstDate] = useState(false);
  const [secondDate, setsecondDate] = useState(false);
  const [thirdDate, setthirdDate] = useState(false);
  const [interviewError, setInterviewError] = useState("");

  const [timePhase_start_1, setTimePhase_start_1] = useState("am");
  const [hours_start_1, setHours_start_1] = useState("12");
  const [minutes_start_1, setMinutes_start_1] = useState("00");

  const [timePhase_end_1, setTimePhase_end_1] = useState("am");
  const [hours_end_1, setHours_end_1] = useState("12");
  const [minutes_end_1, setMinutes_end_1] = useState("00");

  const [timePhase_start_2, setTimePhase_start_2] = useState("am");
  const [hours_start_2, setHours_start_2] = useState("12");
  const [minutes_start_2, setMinutes_start_2] = useState("00");

  const [timePhase_end_2, setTimePhase_end_2] = useState("am");
  const [hours_end_2, setHours_end_2] = useState("12");
  const [minutes_end_2, setMinutes_end_2] = useState("00");

  const [timePhase_start_3, setTimePhase_start_3] = useState("am");
  const [hours_start_3, setHours_start_3] = useState("12");
  const [minutes_start_3, setMinutes_start_3] = useState("00");

  const [timePhase_end_3, setTimePhase_end_3] = useState("am");
  const [hours_end_3, setHours_end_3] = useState("12");
  const [minutes_end_3, setMinutes_end_3] = useState("00");

  const [timeError, setTimeError] = useState("none");

  const [firstDatePickerVisible, setFirstDatePickerVisible] =
    useState(false);
  const [secondDatePickerVisible, setSecondDatePickerVisible] =
    useState(false);
  const [thirdDatePickerVisible, setThirdDatePickerVisible] =
    useState(false);

  useEffect(() => {
    if (props.timings) {
      setfirstDate(
        moment(props?.timings[0]?.StartTime)
          .format("dddd, DD MMMM, YYYY")
          .toString()
      );
      setsecondDate(
        moment(props?.timings[1]?.StartTime)
          .format("dddd, DD MMMM, YYYY")
          .toString()
      );
      setthirdDate(
        moment(props?.timings[2]?.StartTime)
          .format("dddd, DD MMMM, YYYY")
          .toString()
      );

      if (props.timings[0]) {
        var first_timing_start_time = moment(
          props.timings[0]?.StartTime
        ).format("h:mm A");
        setInterviewStartTime(first_timing_start_time);
        setHours_start_1(moment(props.timings[0]?.StartTime).format("h"));
        setMinutes_start_1(moment(props.timings[0]?.StartTime).format("mm"));
        setTimePhase_start_1(moment(props.timings[0]?.StartTime).format("A"));

        var first_timing_end_time = moment(props.timings[0]?.EndTime).format(
          "h:mm A"
        );
        setInterviewEndTime(first_timing_end_time);
        setHours_end_1(moment(props.timings[0]?.EndTime).format("h"));
        setMinutes_end_1(moment(props.timings[0]?.EndTime).format("mm"));
        setTimePhase_end_1(moment(props.timings[0]?.EndTime).format("A"));
      }

      if (props.timings[1]) {
        var second_timing_start_time = moment(
          props.timings[1]?.StartTime
        ).format("h:mm A");
        setInterviewStartTime2(second_timing_start_time);
        setInterviewStartTime(first_timing_start_time);
        setHours_start_2(moment(props.timings[1]?.StartTime).format("h"));
        setMinutes_start_2(moment(props.timings[1]?.StartTime).format("mm"));
        setTimePhase_start_2(moment(props.timings[1]?.StartTime).format("A"));

        var second_timing_end_time = moment(props.timings[1]?.EndTime).format(
          "h:mm A"
        );
        setInterviewEndTime2(second_timing_end_time);
        setHours_end_2(moment(props.timings[1]?.EndTime).format("h"));
        setMinutes_end_2(moment(props.timings[1]?.EndTime).format("mm"));
        setTimePhase_end_2(moment(props.timings[1]?.EndTime).format("A"));
      }

      if (props.timings[2]) {
        var third_timing_start_time = moment(
          props.timings[2]?.StartTime
        ).format("h:mm A");
        setInterviewStartTime3(third_timing_start_time);
        setInterviewStartTime(first_timing_start_time);
        setHours_start_3(moment(props.timings[2]?.StartTime).format("h"));
        setMinutes_start_3(moment(props.timings[2]?.StartTime).format("mm"));
        setTimePhase_start_3(moment(props.timings[2]?.StartTime).format("A"));

        var third_timing_end_time = moment(props.timings[2]?.EndTime).format(
          "h:mm A"
        );
        setInterviewEndTime3(third_timing_end_time);
        setHours_end_3(moment(props.timings[2]?.EndTime).format("h"));
        setMinutes_end_3(moment(props.timings[2]?.EndTime).format("mm"));
        setTimePhase_end_3(moment(props.timings[2]?.EndTime).format("A"));
      }
    }
    // }
  }, [props]);

  useEffect(() => {
    setVisible(props.open);
  }, [props]);

  const onClose = () => {
    setTimeError("");
    setInterviewError("");
    setFirstDatePickerVisible(false);
    setSecondDatePickerVisible(false);
    setThirdDatePickerVisible(false);
    setTimeout(() => {
      setVisible(false);
      props.close(false);
    }, 100);
  };

  function getWindowDimensions() {
    const { innerWidth: width, innerHeight: height } = window;
    return {
      width,
      height,
    };
  }

  useEffect(() => {
    function handleResize() {
      setWindowDimensions(getWindowDimensions());
    }

    window.addEventListener("resize", handleResize);
    return () => window.removeEventListener("resize", handleResize);
  }, []);

  useEffect(() => {
    if (firstDate != "" && secondDate != "" && thirdDate != "") {
      setTimeError("");
    }
  }, []);

  const getDtae = (t, ts) => {
    if (t == null) {
      setfirstDate(false);
    } else {
      setfirstDate(new Date(t._d));
    }
  };

  const getSecondDate = (t, ts) => {
    if (t == null) {
      setsecondDate(false);
    } else {
      setsecondDate(new Date(t._d));
    }
  };

  const getThirdDate = (t, ts) => {
    if (t == null) {
      setthirdDate(false);
    } else {
      setthirdDate(new Date(t._d));
    }
  };

  const setHours = (time_type, mode, menuItem) => {
    if (mode == "1") {
      if (time_type == "start") {
        setHours_start_1(menuItem.key);
      } else {
        setHours_end_1(menuItem.key);
      }
    } else if (mode == "2") {
      if (time_type == "start") {
        setHours_start_2(menuItem.key);
      } else {
        setHours_end_2(menuItem.key);
      }
    } else {
      if (time_type == "start") {
        setHours_start_3(menuItem.key);
      } else {
        setHours_end_3(menuItem.key);
      }
    }
  };

  const hoursMenu = (time_type, mode) => {
    return (
      <Menu
        onClick={(menuItem) => setHours(time_type, mode, menuItem)}
        className="time-dropdown-menu"
      >
        <Menu.Item key="1" className="interview-drawer-menu-item">
          1
        </Menu.Item>
        <Menu.Item key="2" className="interview-drawer-menu-item">
          2
        </Menu.Item>
        <Menu.Item key="3" className="interview-drawer-menu-item">
          3
        </Menu.Item>
        <Menu.Item key="4" className="interview-drawer-menu-item">
          4
        </Menu.Item>
        <Menu.Item key="5" className="interview-drawer-menu-item">
          5
        </Menu.Item>
        <Menu.Item key="6" className="interview-drawer-menu-item">
          6
        </Menu.Item>
        <Menu.Item key="7" className="interview-drawer-menu-item">
          7
        </Menu.Item>
        <Menu.Item key="8" className="interview-drawer-menu-item">
          8
        </Menu.Item>
        <Menu.Item key="9" className="interview-drawer-menu-item">
          9
        </Menu.Item>
        <Menu.Item key="10" className="interview-drawer-menu-item">
          10
        </Menu.Item>
        <Menu.Item key="11" className="interview-drawer-menu-item">
          11
        </Menu.Item>
        <Menu.Item key="12" className="interview-drawer-menu-item">
          12
        </Menu.Item>
      </Menu>
    );
  };

  const array = [];
  for (let i = 0; i < 61; i++) {
    var num;
    if (i < 10) {
      num = "0" + i.toString();
    } else {
      num = i.toString();
    }
    array.push(num);
  }

  const setMinutes = (time_type, mode, menuItem) => {
    if (mode == "1") {
      if (time_type == "start") {
        setMinutes_start_1(menuItem.key);
      } else {
        setMinutes_end_1(menuItem.key);
      }
    } else if (mode == "2") {
      if (time_type == "start") {
        setMinutes_start_2(menuItem.key);
      } else {
        setMinutes_end_2(menuItem.key);
      }
    } else {
      if (time_type == "start") {
        setMinutes_start_3(menuItem.key);
      } else {
        setMinutes_end_3(menuItem.key);
      }
    }
  };

  const minutesMenu = (time_type, mode) => {
    return (
      <div className="dropdown-div">
        <Menu
          onClick={(menuItem) => setMinutes(time_type, mode, menuItem)}
          className="time-dropdown-menu"
        >
          {array.map((i) => (
            <Menu.Item className="interview-drawer-menu-item" key={i}>
              {i}
            </Menu.Item>
          ))}
        </Menu>
      </div>
    );
  };

  const setTimePhase = (time_type, mode, menuItem) => {
    if (mode == "1") {
      if (time_type == "start") {
        setTimePhase_start_1(menuItem.key);
      } else {
        setTimePhase_end_1(menuItem.key);
      }
    } else if (mode == "2") {
      if (time_type == "start") {
        setTimePhase_start_2(menuItem.key);
      } else {
        setTimePhase_end_2(menuItem.key);
      }
    } else {
      if (time_type == "start") {
        setTimePhase_start_3(menuItem.key);
      } else {
        setTimePhase_end_3(menuItem.key);
      }
    }
  };

  const timePhaseMenu = (time_type, mode) => {
    return (
      <Menu
        className="time-dropdown-phase-menu"
        onClick={(menuItem) => setTimePhase(time_type, mode, menuItem)}
      >
        <Menu.Item key="am">AM</Menu.Item>
        <Menu.Item key="pm">PM</Menu.Item>
      </Menu>
    );
  };

  const renderExtraFooter = (mode) => {
    return (
      <>
        <div
          style={{
            display: "flex",
            alignItems: "center",
            justifyContent: "space-between",
            flexDirection: "column",
          }}
        >
          <div
            style={{
              display: "flex",
              alignItems: "center",
              justifyContent: "center",
              marginTop: "3%",
              marginBottom: "2.5%",
            }}
          >
            <Dropdown
              overlay={() => hoursMenu("start", mode)}
              className="time-dropdown"
              trigger={["click"]}
              placement="bottom"
            >
              <Button>
                <div className="time-dropdown-text">
                  {mode == "1"
                    ? hours_start_1
                    : mode == "2"
                    ? hours_start_2
                    : mode == "3"
                    ? hours_start_3
                    : null}
                </div>
                <DownOutlined />
              </Button>
            </Dropdown>
            <span style={{ margin: "2%" }}>:</span>

            <Dropdown
              overlay={() => minutesMenu("start", mode)}
              className="time-dropdown"
              trigger={["click"]}
              placement="bottom"
            >
              <Button>
                <div className="time-dropdown-text">
                  {mode == "1"
                    ? minutes_start_1
                    : mode == "2"
                    ? minutes_start_2
                    : mode == "3"
                    ? minutes_start_3
                    : null}
                </div>
                <DownOutlined />
              </Button>
            </Dropdown>

            <span style={{ margin: "1%" }}></span>
            <Dropdown
              overlay={() => timePhaseMenu("start", mode)}
              className="time-dropdown"
              trigger={["click"]}
              placement="bottom"
            >
              <Button>
                <div className="time-dropdown-text">
                  {mode == "1"
                    ? timePhase_start_1.toUpperCase()
                    : mode == "2"
                    ? timePhase_start_2.toUpperCase()
                    : mode == "3"
                    ? timePhase_start_3.toUpperCase()
                    : null}
                </div>
                <DownOutlined />
              </Button>
            </Dropdown>
          </div>

          <div
            style={{
              display: "flex",
              alignItems: "center",
              justifyContent: "center",
              marginTop: "2.5%",
              marginBottom: "3%",
            }}
          >
            <Dropdown
              overlay={() => hoursMenu("", mode)}
              className="time-dropdown"
              trigger={["click"]} 
              placement="bottom"
            >
              <Button>
                <div className="time-dropdown-text">
                  {mode == "1"
                    ? hours_end_1
                    : mode == "2"
                    ? hours_end_2
                    : mode == "3"
                    ? hours_end_3
                    : null}
                </div>
                <DownOutlined />
              </Button>
            </Dropdown>
            <span style={{ margin: "2%" }}>:</span>

            <Dropdown
              overlay={() => minutesMenu("", mode)}
              className="time-dropdown"
              trigger={["click"]}
              placement="bottom"
            >
              <Button>
                <div className="time-dropdown-text">
                  {mode == "1"
                    ? minutes_end_1
                    : mode == "2"
                    ? minutes_end_2
                    : mode == "3"
                    ? minutes_end_3
                    : null}
                </div>
                <DownOutlined />
              </Button>
            </Dropdown>

            <span style={{ margin: "1%" }}></span>
            <Dropdown
              overlay={() => timePhaseMenu("", mode)}
              className="time-dropdown"
              trigger={["click"]}
              placement="bottom"
            >
              <Button>
                <div className="time-dropdown-text">
                  {mode == "1"
                    ? timePhase_end_1.toUpperCase()
                    : mode == "2"
                    ? timePhase_end_2.toUpperCase()
                    : mode == "3"
                    ? timePhase_end_3.toUpperCase()
                    : null}
                </div>
                <DownOutlined />
              </Button>
            </Dropdown>
          </div>
          <div>
            <p style={{ color: "red", fontSize: "small" }}>
              {timeError == "none" ? null : timeError}
            </p>
          </div>

          <div
            style={{
              display: "flex",
              alignItems: "center",
              justifyContent: "space-between",
              width: "100%",
              marginTop: "2.5%",
              marginBottom: "3%",
            }}
          >
            <Button
              className="cancelDateBtn"
              onClick={() => {
                if (mode == "1") {
                  setFirstDatePickerVisible("");
                  setMinutes_end_1("00");
                  setHours_end_1("12");
                  setTimePhase_end_1("am");
                  setTimePhase_start_1("am");
                  setMinutes_start_1("00");
                  setHours_start_1("12");
                } else if (mode == "2") {
                  setSecondDatePickerVisible("");
                  setMinutes_end_2("00");
                  setHours_end_2("12");
                  setTimePhase_end_2("am");
                  setTimePhase_start_2("am");
                  setMinutes_start_2("00");
                  setHours_start_2("12");
                } else {
                  setThirdDatePickerVisible("");
                  setMinutes_end_3("00");
                  setHours_end_3("12");
                  setTimePhase_end_3("am");
                  setTimePhase_start_3("am");
                  setMinutes_start_3("00");
                  setHours_start_3("12");
                }
                setTimeError("none");
              }}
            >
              Cancel
            </Button>

            <Button
              className="selectDateBtn"
              onClick={() => {
                if (mode == "1") {
                  setInterviewStartTime(
                    hours_start_1 +
                      ":" +
                      minutes_start_1 +
                      ": " +
                      timePhase_start_1
                  );
                  setInterviewEndTime(
                    hours_end_1 + ":" + minutes_end_1 + ": " + timePhase_end_1
                  );
                  let stime = moment(
                    hours_start_1 +
                      ":" +
                      minutes_start_1 +
                      ": " +
                      timePhase_start_1,
                    "HH:mm A"
                  );
                  let etime = moment(
                    hours_end_1 + ":" + minutes_end_1 + ": " + timePhase_end_1,
                    "HH:mm A"
                  );
                  if (stime >= etime) {
                    setTimeError("Choose a Future Time Slot");
                    if (!firstDate) {
                      setfirstDate(moment().format("dddd, DD MMMM, YYYY"));
                    }
                    setFirstDatePickerVisible(true);
                  } else {
                    if (!firstDate) {
                      setfirstDate(moment().format("dddd, DD MMMM, YYYY"));
                      setFirstDatePickerVisible(false);
                    }
                    setTimeError("none");
                    if (firstDate && timeError == "none") {
                      setFirstDatePickerVisible(false);
                    }
                  }
                } else if (mode == "2") {
                  setInterviewStartTime2(
                    hours_start_2 +
                      ":" +
                      minutes_start_2 +
                      ": " +
                      timePhase_start_2
                  );
                  setInterviewEndTime2(
                    hours_end_2 + ":" + minutes_end_2 + ": " + timePhase_end_2
                  );
                  let stime2 = moment(
                    hours_start_2 +
                      ":" +
                      minutes_start_2 +
                      ": " +
                      timePhase_start_2,
                    "HH:mm A"
                  );
                  let etime2 = moment(
                    hours_end_2 + ":" + minutes_end_2 + ": " + timePhase_end_2,
                    "HH:mm A"
                  );
                  if (stime2 >= etime2) {
                    setTimeError("Choose a Future Time Slot...");
                    if (!secondDate) {
                      setsecondDate(moment().format("dddd, DD MMMM, YYYY"));
                    }
                    setSecondDatePickerVisible(true);
                  } else {
                    setTimeError("none");
                    if (secondDate) {
                      setSecondDatePickerVisible(false);
                    } else if (!secondDate) {
                      setsecondDate(moment().format("dddd, DD MMMM, YYYY"));
                      setSecondDatePickerVisible(false);
                    }
                  }
                } else {
                  setInterviewStartTime3(
                    hours_start_3 +
                      ":" +
                      minutes_start_3 +
                      ": " +
                      timePhase_start_3
                  );
                  setInterviewEndTime3(
                    hours_end_3 + ":" + minutes_end_3 + ": " + timePhase_end_3
                  );
                  let stime3 = moment(
                    hours_start_3 +
                      ":" +
                      minutes_start_3 +
                      ": " +
                      timePhase_start_3,
                    "HH:mm A"
                  );
                  let etime3 = moment(
                    hours_end_3 + ":" + minutes_end_3 + ": " + timePhase_end_3,
                    "HH:mm A"
                  );
                  if (stime3 >= etime3) {
                    setTimeError("Choose a Future Time Slot");
                    if (!thirdDate) {
                      setthirdDate(moment().format("dddd, DD MMMM, YYYY"));
                    }
                  } else {
                    setTimeError("none");
                    if (thirdDate) {
                      setThirdDatePickerVisible(false);
                    } else if (!thirdDate) {
                      setthirdDate(moment().format("dddd, DD MMMM, YYYY"));
                      setThirdDatePickerVisible(false);
                    }
                  }
                }
              }}
            >
              Select
            </Button>
          </div>
        </div>
      </>
    );
  };

  const openFunction = (mode) => {
    if (mode == "1") {
      setFirstDatePickerVisible(true);
    } else if (mode == "2") {
      setSecondDatePickerVisible(true);
    } else {
      setThirdDatePickerVisible(true);
    }
  };

  DatePicker.handleClickOutside = () => {
    setFirstDatePickerVisible(false);
  };

  const Datepicker = () => {
    useEffect(() => {
      if (firstDate != "" && secondDate != "" && thirdDate != "") {
        setTimeError("");
      }
    }, []);
    return (
      <>
        <div style={{ textAlign: "center" }}>
          <div>Let's find a better time for the interview.</div>
          <div style={{ marginTop: "10px" }}>
            Propose up to 3 of your available interview times.
          </div>
        </div>
        <div className="datepickers-div">
          <div>
            <DatePicker
              onCancel={() => setFirstDatePickerVisible(false)}
              open={firstDatePickerVisible && props.open}
              onOpenChange={() => openFunction("1")}
              onChange={(time, timeString) => getDtae(time, timeString)}
              format={`dddd, MMMM DD, YYYY [${interviewStartTime}] - [${interviewEndTime}]`}
              // placeholder="Choose Interview date and time first"
              placeholder="Choose a date and time"
              renderExtraFooter={() => renderExtraFooter("1")}
              value={
                firstDate?.StartTime
                  ? moment(firstDate?.StartTime).local()
                  : firstDate
                  ? moment(firstDate).local()
                  : false
              }
              disabledDate={(current) => {
                return moment().add(-1, "days") >= current;
              }}
            />
          </div>

          <div>
            <DatePicker
              dropdownClassName="DatePickerChat"
              open={secondDatePickerVisible && props.open}
              onCancel={() => setSecondDatePickerVisible(false)}
              onOpenChange={() => openFunction("2")}
              format={`dddd, MMMM DD, YYYY [${interviewStartTime2}] - [${interviewEndTime2}]`}
              // placeholder="Choose Interview date and time second"
              placeholder="Choose a date and time"
              renderExtraFooter={() => renderExtraFooter("2")}
              value={
                secondDate?.StartTime
                  ? moment(secondDate?.StartTime).local()
                  : secondDate
                  ? moment(secondDate).local()
                  : false
              }
              onChange={(time, timeString) => getSecondDate(time, timeString)}
              disabledDate={(current) => {
                return moment().add(-1, "days") >= current;
              }}
            />
          </div>

          <div>
            <DatePicker
              dropdownClassName="DatePickerChat"
              open={thirdDatePickerVisible && props.open}
              onCancel={() => setThirdDatePickerVisible(false)}
              onOpenChange={() => openFunction("3")}
              format={`dddd, MMMM DD, YYYY [${interviewStartTime3}] - [${interviewEndTime3}]`}
              // placeholder="Choose Interview date and time third"
              placeholder="Choose a date and time"
              renderExtraFooter={() => renderExtraFooter("3")}
              value={
                thirdDate?.StartTime
                  ? moment(thirdDate?.StartTime).local()
                  : thirdDate
                  ? moment(thirdDate).local()
                  : false
              }
              onChange={(time, timeString) => getThirdDate(time, timeString)}
              disabledDate={(current) => {
                return moment().add(-1, "days") >= current;
              }}
            />
          </div>
          <div className="interviewError">{interviewError}</div>
        </div>
      </>
    );
  };

  const requestSend = () => {
    var firstDateTimings = {};
    var secondDateTimings = {};
    var thirdDateTimings = {};
    if (props.setInterviewSchedule) {
      props.setInterviewSchedule(true);
    }
    if (firstDate && interviewStartTime != "" && interviewEndTime != "") {
      const stime = moment(interviewStartTime, "HH:mm A");
      const newmoment = moment(
        moment(firstDate).format("MMMM DD , YYYY").toString() +
          " " +
          stime.format("HH:mm:ss").toString()
      );
      const etime = moment(interviewEndTime, "HH:mm A");
      const nmoment = moment(
        moment(firstDate).format("MMMM DD , YYYY").toString() +
          " " +
          etime.format("HH:mm:ss").toString()
      );
      firstDateTimings = {
        ...firstDateTimings,
        StartTime: moment(
          new Date(newmoment.toISOString()).toUTCString()
        ).toISOString(),
        EndTime: moment(
          new Date(nmoment.toISOString()).toUTCString()
        ).toISOString(),
      };
    }
    if (secondDate && interviewStartTime2 != "" && interviewEndTime2 != "") {
      const stime2 = moment(interviewStartTime2, "HH:mm A");
      const newmoment2 = moment(
        moment(secondDate).format("MMMM DD , YYYY").toString() +
          " " +
          stime2.format("HH:mm:ss").toString()
      );
      const etime2 = moment(interviewEndTime2, "HH:mm A");
      const nmoment2 = moment(
        moment(secondDate).format("MMMM DD , YYYY").toString() +
          " " +
          etime2.format("HH:mm:ss").toString()
      );
      secondDateTimings = {
        ...secondDateTimings,
        StartTime: moment(
          new Date(newmoment2.toISOString()).toUTCString()
        ).toISOString(),
        EndTime: moment(
          new Date(nmoment2.toISOString()).toUTCString()
        ).toISOString(),
      };
    }

    if (thirdDate && interviewStartTime3 != "" && interviewEndTime3 != "") {
      const stime3 = moment(interviewStartTime3, "HH:mm A");
      const newmoment3 = moment(
        moment(thirdDate).format("MMMM DD , YYYY").toString() +
          " " +
          stime3.format("HH:mm:ss").toString()
      );
      const etime3 = moment(interviewEndTime3, "HH:mm A");
      const nmoment3 = moment(
        moment(thirdDate).format("MMMM DD , YYYY").toString() +
          " " +
          etime3.format("HH:mm:ss").toString()
      );

      thirdDateTimings = {
        ...thirdDateTimings,
        StartTime: moment(
          new Date(newmoment3.toISOString()).toUTCString()
        ).toISOString(),
        EndTime: moment(
          new Date(nmoment3.toISOString()).toUTCString()
        ).toISOString(),
      };
    }
    let date = new Date();
    date = moment(new Date(date.toISOString()).toUTCString()).toISOString();
    if (firstDate == "" || secondDate == "" || thirdDate == "") {
      setInterviewError("Choose a date and time");
    } else {
      if (props.reSchedule) {
        props.rescheduleInterview(firstDateTimings, secondDateTimings, thirdDateTimings);
      }
      setInterviewError("");
      props.close(false);
    }
  };

  return (
    <>
        <Modal
          onCancel={onClose}
          width={360}
          centered
          footer={[
            <button onClick={requestSend} className={"modal-send-request-btn"}>
              {props.reSchedule
                ? "Send Reschedule Request "
                : "Send proposed interview times"}
              {Loading ? <LoadingOutlined /> : null}
            </button>,
          ]}
          visible={visible}
          title={<div>Reschedule Interview</div>}
          className="Custom-date-picker"
        >
          <>
            <div style={{ textAlign: "center" }}>
              <div>Let's find a better time for the interview.</div>
              <div style={{ marginTop: "10px" }}>
                Propose up to 3 of your available interview times.
              </div>
            </div>
            <div className="datepickers-div">
              
              <div>
                <DatePicker
                  dropdownClassName="DatePickerChat"
                  onCancel={() => setFirstDatePickerVisible(false)}
                  open={firstDatePickerVisible && props.open}
                  onOpenChange={() => openFunction("1")}
                  onChange={(time, timeString) => getDtae(time, timeString)}
                  format={`dddd, MMMM DD, YYYY [${interviewStartTime}] - [${interviewEndTime}]`}
                  // // placeholder="Choose Interview date and time first"
                  placeholder="Choose a date and time"
                  renderExtraFooter={() => renderExtraFooter("1")}
                  value={
                    firstDate?.StartTime
                      ? moment(firstDate?.StartTime).local()
                      : firstDate
                      ? moment(firstDate).local()
                      : false
                  }
                  disabledDate={(current) => {
                    return moment().add(-1, "days") >= current;
                  }}
                />
              </div>

              <div>
                <DatePicker
                  dropdownClassName="DatePickerChat"
                  open={secondDatePickerVisible && props.open}
                  onCancel={() => setSecondDatePickerVisible(false)}
                  onOpenChange={() => openFunction("2")}
                  format={`dddd, MMMM DD, YYYY [${interviewStartTime2}] - [${interviewEndTime2}]`}
                  // placeholder="Choose Interview date and time second"
                  placeholder="Choose a date and time"
                  renderExtraFooter={() => renderExtraFooter("2")}
                  value={
                    secondDate?.StartTime
                      ? moment(secondDate?.StartTime).local()
                      : secondDate
                      ? moment(secondDate).local()
                      : false
                  }
                  onChange={(time, timeString) =>
                    getSecondDate(time, timeString)
                  }
                  disabledDate={(current) => {
                    return moment().add(-1, "days") >= current;
                  }}
                />
              </div>

              <div>
                <DatePicker
                  dropdownClassName="DatePickerChat"
                  open={thirdDatePickerVisible && props.open}
                  onCancel={() => setThirdDatePickerVisible(false)}
                  onOpenChange={() => openFunction("3")}
                  format={`dddd, MMMM DD, YYYY [${interviewStartTime3}] - [${interviewEndTime3}]`}
                  // placeholder="Choose Interview date and time third"
                  placeholder="Choose a date and time"
                  renderExtraFooter={() => renderExtraFooter("3")}
                  value={
                    thirdDate?.StartTime
                      ? moment(thirdDate?.StartTime).local()
                      : thirdDate
                      ? moment(thirdDate).local()
                      : false
                  }
                  onChange={(time, timeString) =>
                    getThirdDate(time, timeString)
                  }
                  disabledDate={(current) => {
                    return moment().add(-1, "days") >= current;
                  }}
                />
              </div>
              <div className="interviewError">{interviewError}</div>
            </div>
          </>
        </Modal>
    </>
  );
};
export default InterviewDrawer;
