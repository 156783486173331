import { combineReducers } from "redux";
import fetchDataReducer from "./fetchDataReducer";
import authReducer from "./authReducer";
import docReducer from "./docReducer";
import profileReducer from "./profileReducer";
import chekckUserReducer from "./chekckUserReducer";
import skillListingReducer from "./skillListingReducer";
import credentialingReducer from "./credentialingReducer";
import proposalsReducer from "./proposalsReducer";
import createBidReducers from "./createBidReducers";
import updateBidReducers from "./updateBidReducers";
import messagesReducer from "./messagesReducer";
import appReducer from "./appReducer";

import BidsReducer from "./BidsReducer";
import getBidDataReducer from "./fetchBidDataReducer";
import retractBidReducers from "./retractReducer";
import getChatHistoryReducer from "./chatHistoryReducer";
import getChatHistoryReducerHeader from "./ChatHistoryHeaderReducer";

import searchGigsReducer from "./searchGigsReducer";
import timeSheetReducer from "./TimesheetReducer";
import timeSheetListReducer from "./timeSheetListReducer";
import createTimeSheetCorrectionReducer from "./createTimeSheetCorrection";
import UserAssignmentReducer from "./userAssignmentReducer";
import myFavoritesReducer from "./myFavoritesReducer";

import interviewReducer from "./interviewReducer";
import offerReducer from "./offerReducer";
import msgFavReducer from "./messageFavouriteReducer";
import { NotificationReducer } from "./NotificationReducer";

import showPublicViewReducer from "./showPublicViewReducer";

import {
  resumeGetfollowersReducer,
  resumeGetviewReducer,
  resumeDetailReducer,
  resumeViewReducer,
} from "./resumeReducer";
import homepageReducer from "./homepageReducer";
import searchSuggestionReducer from "./searchSuggestionReducer";
import onClearFilterReducer from "./onClearFilterReducer"
import IsLocationReducer from "./IsLocationReducer"
import  ratingReducer from "./ratingReducer"
import onClearSearchReducer from "./onClearSearchReducer"
import favouriteMessageReducer from "./messageFavouriteReducer"
import fetchNotificationReducer from './fetchNotificationReducer'
import viewListingReducer from './viewListingReducer'
import { types } from "../action-types";
import checkUserNameReducer from "./checkUserNameReducer";
import updateGigReducer from './updateGigReducer';
import catchErrorReducer from './catchErrorReducer';
import getRatingReducer from './getRatingReducer';
import employerReducer from "./employerReducer";
const combineReducer = combineReducers({
  fetchDataReducer,
  authReducer,
  offerReducer,
  docReducer,
  profileReducer,
  chekckUserReducer,
  skillListingReducer,
  updateGigReducer,
  favouriteMessageReducer,
  credentialingReducer,
  proposalsReducer,
  createBidReducers,
  updateBidReducers,
  messagesReducer,
  getBidDataReducer,
  retractBidReducers,
  getChatHistoryReducer,
  getChatHistoryReducerHeader,

  searchGigsReducer,
  timeSheetReducer,
  timeSheetListReducer,
  createTimeSheetCorrectionReducer,
  UserAssignmentReducer,
  myFavoritesReducer,
  retractBidReducers,
  BidsReducer,

  interviewReducer,

  resumeGetfollowersReducer,
  resumeGetviewReducer,
  resumeDetailReducer,

  showPublicViewReducer,

  homepageReducer,
  NotificationReducer,
  onClearFilterReducer,
  searchSuggestionReducer,
  IsLocationReducer,
  ratingReducer,
  onClearSearchReducer,
  fetchNotificationReducer,
  checkUserNameReducer,
  viewListingReducer,
  catchErrorReducer,
  msgFavReducer,
  getRatingReducer,
  resumeViewReducer,
  employerReducer,
  appReducer
});

const rootReducer = (state, action) => {
  if (action.type === types.USER_LOGOUT) {
    return combineReducer({}, action);
  }
  return combineReducer(state, action);
};
export default rootReducer;
