import { types } from "../action-types";

const initialState = {
  loading: false,
  gigs: [],
  error: {},
  searchvalue: "",
  searchSuggestions: [],
  jobQuery: null,
  skillQuery: null,
  locationQuery: null,
  countryQuery: [],
  regionQuery: [],
  cityQuery: [],
  explevelQuery:null,
  budgetQuery: false,
  minbudgetQuery: null,
  maxbudgetQuery: null,
  budgetTypeQuery: null,
  sortValue: "Most Recent",
  pageNo: null,
  filterDrawer: false,
  sortDrawer: false,
  isBudgetContract: false,
  isBudgetPermanent: false,
  activeFilters: [],
  WorkplacePolicy: null,
  IsFavoritesValue: false,
  myFavorites: [],
  TrendingLocSearch: null,
  TrendingPlaceName: null,
  ProfessionCodeValue: null,
  ProfessionCodeFilterCount: false,
  // Loading: false,
  // Error: {},
  removeFavorites: [],
  pageSize: null,
  PrivateGig: false,
  PrivateEmployerId: []
};

const searchGigsReducer = (state = initialState, action) => {
  switch (action.type) {
    case types.SEARCH_GIGS_INIT:
      return {
        ...state,
        loading: action.payload,
      };
    case types.SEARCH_GIGS_SUCCESS:
      // 
      return {
        ...state,
        loading: false,
        gigs: { ...state.gigs, gigs: action.payload },
        error: {},
      };
    case types.SEARCH_GIGS_FAILURE:
      return {
        ...state,
        loading: false,
        error: action.error,
      };
    case types.SEARCH_VALUE:
      return {
        ...state,
        searchvalue: action.payload,
      };
    case types.FETCH_SEARCH_SUGGESTIONS_SUCCESS:
      return {
        ...state,
        loading: false,
        searchSuggestions: {
          ...state.searchSuggestions,
          searchSuggestions: action.payload,
        },
        error: {},
      };
    case types.FETCH_SEARCH_SUGGESTIONS_FAILURE:
      return {
        ...state,
        loading: false,
        error: action.error,
      };
    case types.SEARCH_FILTER_UPDATE:
      const key = action.key;
      // 
      return {
        ...state,
        [action.key]: action.payload,
        // jobQuery: action.payload,
      };
    // case types.FAVORITE_GIG_CARD_SUCCESS:
    //   const data = action.payload;
    //   
    //   let x;
    //   const index = state.gigs.gigs.gigs.data.map((obj,i) => {if(obj._id === data[0]) return x = i } )

    //   // 
    //   let condition = state.gigs.gigs.gigs.data[x].IsFavorite
    //   const tmp = [...state.gigs.gigs.gigs.data]

    //    // 
    //    // 
    //    // 
    //   //  // 
    //   tmp[x]={...state.gigs.gigs.gigs.data[x],"IsFavorite": !condition}
    //   // // 
    //   return {
    //     ...state,
    //     gigs: { ...state.gigs, gigs: {...state.gigs.gigs,gigs:{...state.gigs.gigs.gigs,data:[...tmp]}} }
    //   };
    
      //  // 
    case types.REMOVE_FAVORITE_GIG_CARD_REDUCER:
      // 
      // const dataR = action.payload;
      let y;
      const indexDel = state.gigs.gigs.gigs.data.map((obj,i)=>{if(obj._id === action.payload) return y = i})
      // 
      let conditionDEL = state.gigs.gigs.gigs.data[y].IsFavorite
      const tmpDel = [...state.gigs.gigs.gigs.data]
      // 
      // // 
      // // 
      tmpDel[y]={...state.gigs.gigs.gigs.data[y],"IsFavorite": !conditionDEL}
      // // 

      return {
        ...state,
        gigs: { ...state.gigs, gigs: {...state.gigs.gigs,gigs:{...state.gigs.gigs.gigs,data:[...tmpDel]}} }
      };
    default:
       return state;
  }
};

export default searchGigsReducer;
