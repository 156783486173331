import React, { useState, useEffect } from "react";
import { Select, Spin } from "antd";
import { debounce } from "../../../../../shared/helpers";
import { connect, useDispatch, useSelector } from "react-redux";
import { DownOutlined } from "@ant-design/icons";
import { CheckOutlined } from "@ant-design/icons";
import {
  fetchSearchSkill,
  updateSignupProfile,
} from "../../../../../redux/actions";
import "../../CreateSkillListing.css";
import "./CreateSkillListingScreen.css";
import errorIcon from "../../../../../assets/img/ci_warning.png";
import { useHistory } from "react-router-dom";
import BottomBottons from "../Footer/BottomButtons";

const YourSkills = (props) => {
  const { profile } = props;
  const dispatch = useDispatch();
  const [noDataViewSkills, setNoDataViewSkills] = useState("");
  const [flag,setFlag] = useState(false);
  const [selectedSkills, setSelectedSkills] = useState([]);
  const SkillListings_Skills =
    props.profile?.DraftSkillListing?.SkillListings_Skills || [];
  const [showError, setShowError] = useState(false);
  const { Option } = Select;
  const history = useHistory();

  useEffect(() => {
    if (SkillListings_Skills.length) {
      setSelectedSkills(SkillListings_Skills);
    }
  }, [SkillListings_Skills]);

  useEffect(() => {
    if (props.apiSuccess && selectedSkills.length && flag) {
      history.push("/create-skilllisting-location");
    }
  }, [props.apiSuccess]);

  function onSkillSearch(val) {
    const profCode = props?.profile?.ProfessionCode;
    let result = val
      .replaceAll("%", "%25")
      .replaceAll("#", "%23")
      .replaceAll("*", "%2A")
      .replaceAll("+", "%2B")
      .replaceAll("/", "%2F")
      .replaceAll(",", "%2C")
      .replaceAll(".", "%2E")
      .replaceAll("-", "%2D")
      .replaceAll("&", "%26")
      .replaceAll("'", "%27")
      .replaceAll("?", "%3F")
      .replaceAll("!", "%21")
      .replaceAll('"', "%22")
      .replaceAll("$", "%24")
      .replaceAll("(", "%28")
      .replaceAll(")", "%29")
      .replaceAll(":", "%3A")
      .replaceAll(";", "%3B")
      .replaceAll("=", "%3D")
      .replaceAll("@", "%40")
      .replaceAll("[", "%5B")
      .replaceAll("]", "%5D")
      .replaceAll("newline", "%0A")
      .replaceAll("space", "%20")
      .replaceAll("<", "%3C")
      .replaceAll(">", "%3E")
      .replaceAll("^", "%5E")
      .replaceAll("_", "%5F")
      .replaceAll("\\", "%5C")
      .replaceAll("`", "%60")
      .replaceAll("{", "%7B")
      .replaceAll("}", "%7D")
      .replaceAll("~", "%7E")
      .replaceAll("|", "%7C")
      .replaceAll("£", "%C2%A3");
    setNoDataViewSkills(val);
    props.searchSkills({ val: result, profCode });
  }

  const getSearchSkill = (value) => {
    //selected value will come here
    if (value.length) {
      setShowError(false);
    }
    if (value.length > selectedSkills.length) {
      let insertedElement = value[value.length - 1];
      insertedElement = JSON.parse(insertedElement);
      let isFoundIndex = selectedSkills.findIndex((skill) => skill?.SkillName === insertedElement?.SkillName)
      if (isFoundIndex != -1) {
          let selectedSkill = [...selectedSkills];
          selectedSkill.splice(isFoundIndex,1);
          setSelectedSkills(selectedSkill);
          return;
      }
    }
    let selectedSkill = value.map((item) => {
      if (item.startsWith("{")) return JSON.parse(item);
      else {
        let stringSkill = selectedSkills.filter((item1) => {
          if (item1.SkillName === item) return item1;
        });
        return stringSkill[0];
      }
    });
    setSelectedSkills(selectedSkill);
  };
  const title = "Your skills";

  const handleNext = () => {
    let modSelectedSkill = selectedSkills.map((skill) => {
      return {
        SkillId: skill.SkillId,
        SkillName: skill.SkillName,
      };
    });
    if (selectedSkills.length) {
      setFlag(true)
      dispatch(
        updateSignupProfile({
          "DraftSkillListing.SkillListings_Skills": modSelectedSkill,
          WizardFlow: 204,
        })
      );
    } else {
      setShowError(true);
    }
  };

  const handleBack = () => {
    history.push("/create-skilllisting-title");
  };

  const locationPlaceholder = (professionCode) => {
    return (
      <div className="placeholder-new-signup-loc-skill">
        <div className="example">ex.</div>
        {professionCode === "HC" ?
        <>
          <div className="circle-box">
            <span>Speciality Nurse</span>
          </div>
          <div className="circle-box">
            <span>Acute care</span>
          </div>
        </>
        : 
        <>
        <div className="circle-box">
          <span>User research</span>
        </div>
        <div className="circle-box">
          <span>User testing</span>
        </div>
        </>
        }
      </div>
    )
  }

  return (
    <div className="CreateSkillListingHeaderScreen2">
      <div className="create-skill-listing-title">
        {title} <span className="Mandatory-Text">(Mandatory)</span>
      </div>
      <div className="create-skill-listing-subheader-other">
          <span>What are your strongest skills? Make sure to highlight them all.</span>
      </div>
      <Select
        notFoundContent={
          noDataViewSkills?.length > 0 ? (
            <div>
              {props.skillsLoader || props.skills.length > 0 ? (
                <Spin size="small" />
              ) : (
                "No data found!"
              )}
            </div>
          ) : null
        }
        placeholder={
          locationPlaceholder(profile?.ProfessionCode)
        }
        value={selectedSkills.map((item) => item?.SkillName)}
        className={`desired-loc-skill${showError ? " error-block" : ""}`}
        mode="multiple"
        bordered={false}
        onSearch={debounce((val) => onSkillSearch(val))}
        onChange={getSearchSkill}
        onClick={() => {}}
        showSearch
        listHeight={160}
      >
        {props.skills?.length > 0 &&
          props.skills?.map((skill) => {
            let isChecked = selectedSkills.some(
              (item) => item?.SkillName === skill?.SkillName
            );
            return (
              <Option
                key={`${skill?.SkillName}`}
                value={JSON.stringify(skill)}
                style={{ backgroundColor: isChecked ? "#F4F4F4" : null }}
              >
                <div
                  style={{ display: "flex", justifyContent: "space-between" }}
                >
                  {skill?.SkillName}
                  {isChecked && <CheckOutlined style={{ color: "black" }} />}
                </div>
              </Option>
            );
          })}
      </Select>
      {showError && (
        <div className="error-msg-content">
          <img src={errorIcon} alt="" />
          <span className="error-text">Please add your skills to continue</span>
        </div>
      )}
      <BottomBottons
        loading={props.loading}
        handleBack={handleBack}
        handleNext={handleNext}
      />
    </div>
  );
};

const mapStateToProps = (state) => {
  return {
    skills: state.skillListingReducer.skills,
    skillsLoader: state.skillListingReducer.skillLoader,
    profile: state.profileReducer.user.profile,
    loading: state.profileReducer.signUpLoading,
    apiError: state.profileReducer.error,
    apiSuccess: state.profileReducer.signupUpdateSuccess,
  };
};

const mapDispatchToProps = (dispatch) => {
  return {
    searchSkills: (params) => {
      dispatch(fetchSearchSkill(params));
    },
  };
};

export default connect(mapStateToProps, mapDispatchToProps)(YourSkills);
