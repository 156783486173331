import React, { useEffect, useState } from "react";
import "./ManageSkillListing.css";

import SkillListingForm from "../SkillListing/SkillListingForm";

import { useDispatch, useSelector } from "react-redux";

import { Modal } from "antd";
import {
  fetchOneSkillListing,
  fetchUserSkillListing,
} from "../../redux/actions";
import { useUpdateLogger } from "../../shared/useUpdateLogger";

const ModalEdit = (props) => {
  const dispatch = useDispatch();

  const filter = useSelector((state) => state.skillListingReducer.pubfilter);
  const page = useSelector((state) => state.skillListingReducer.page);
  const perPage = useSelector((state) => state.skillListingReducer.perPage);

  const {
    editModalState,
    setEditModalState,
    handleUpdateSkillListing,
    id,
    idSkill,
  } = props;

  const [updateSubmit, setUpdateSubmit] = useState(null);
  const [updateActive, setUpdateActive] = useState(false);

  const getSubmitUpdate = (submitRef) => {
    setUpdateSubmit(submitRef);
  };

  const updateSkill = () => {
    updateSubmit.current.click();
    // // 
    // dispatch(fetchUserSkillListing(filter, page, perPage));
  };

  // useUpdateLogger("skillFiller:id", id);

  return (
    <Modal
      centered
      footer={false}
      width="47.50rem"
      visible={editModalState}
      destroyOnClose={true}
      wrapClassName="modalEditSkillListing"
      onCancel={() => handleUpdateSkillListing(false)}
      title={<div className="createSkillistingTitle">Edit skill listing</div>}
    >
      <SkillListingForm
        handleUpdateSkillListing={handleUpdateSkillListing}
        getSubmitUpdate={getSubmitUpdate}
        setUpdateActive={setUpdateActive}
        editModalState={editModalState}
        updateId={id}
        idSkill={idSkill}
      />
      <section className="cancelUpdate">
        <div className="cancel" onClick={() => handleUpdateSkillListing(false)}>
          Cancel
        </div>
        <div
          className={`Update ${
            updateActive ? "skillUpdateActive" : "skillUpdateDisabled"
          }`}
          onClick={() => {
            updateActive && updateSkill();
          }}
        >
          Update
        </div>
      </section>
    </Modal>
  );
};

export default ModalEdit;
