import { call, put, takeEvery, takeLatest, takeLeading } from "redux-saga/effects";
import { types } from "../action-types";
import { retractBidUpdateStatus, updateBidApi, updateGigApi } from "../../API/API";
import { fetchDetailPlaceBid } from "../actions";

function* updateBidSaga(params) {
    // // 
    try {
        const response = yield call(updateBidApi, params); 
        yield put({type: types.UPDATE_BID_SUCCESS, payload: response.data});
      }
      catch (error) {
        yield put({type: types.UPDATE_BID_FAILURE, error});
    }
}

function* RetractedupdateBidSaga(params) {
    // // 
    try {
        const response = yield call(retractBidUpdateStatus, params);
        if (response.status == 200){
            yield put(fetchDetailPlaceBid(response?.data?.ProposalId));
        }
        yield put({ type: types.RETRACTED_BID_UPDATE_STAGES_SUCCESS, payload: response.data });
    }
    catch (error) {
        yield put({ type: types.RETRACTED_BID_UPDATE_STAGES_FAILURE, error });
    }
}

function* updateGigSaga(params) {
    // // 
    try {
        const response = yield call(updateGigApi, params);
        yield put({ type: types.UPDATE_GIG_SUCCESS, payload: response.data });
    }
    catch (error) {
        yield put({ type: types.UPDATE_GIG_FAILURE, error });
    }
}

export function* watchUpdateBidSaga() {
    yield takeLeading(types.UPDATE_BID_REQUESTED, updateBidSaga);
}

export function* watchRetractUpdateBidSaga() {
    yield takeLeading(types.RETRACTED_BID_UPDATE_STAGES_INIT, RetractedupdateBidSaga);
}

export function* watchUpdateGigSaga() {
    yield takeLeading(types.UPDATE_GIG_REQUESTED, updateGigSaga);
}