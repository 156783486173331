import React, { useEffect, useState } from "react";

import "./EducationalScreenTwo.css";

import EducationalComponent from "./EducationalComponent";
import eduPicOne from "../../assets/icons/sc-health-6.svg";
import eduPicTwo from "../../assets/icons/sc-health-4.jpg";
import eduPicThree from "../../assets/icons/sc-health-5.svg";
import eduPicFour from "../../assets/icons/sc-health-11.svg";
import eduPicFive from "../../assets/icons/sc-health-2.svg";
import eduPicSix from "../../assets/icons/sc-health-3.svg";
import eduPicSeven from "../../assets/icons/browsing.jpg";
import Buttnn from "./Buttnn";

import EducationalComponentTwo from "./EducationalComponentTwo";
import BackNextSignUpButton from "../SignUp/Components/DocUpload/Signup_gap_screen/BackNextSignUpButton";
import { useHistory } from "react-router-dom/cjs/react-router-dom.min";
import { useDispatch, useSelector } from "react-redux";
import { updateSignupProfile } from "../../redux/actions";

const EducationalScreenTwo = () => {
  const history = useHistory();
  const dispatch = useDispatch();

  const ProfessionCode = useSelector(
    (state) => state.profileReducer.user?.profile?.ProfessionCode
  );

  const [redirectionFlag, setRedirectionFlag] = useState(false);

  const loading = useSelector((state) => state.profileReducer.signUpLoading);
  const apiSuccess = useSelector(
    (state) => state.profileReducer.signupUpdateSuccess
  );

  useEffect(() => {
    if (apiSuccess && redirectionFlag) {
      setRedirectionFlag(false);
      history.push("/sign_educational_screen3");
    }
  }, [apiSuccess]);

  const boxes = [
    {
      photo: eduPicOne,
      // backgroundScreen: eduPicOne,
      header: "Identification",
      subheader: "Represent yourself with a professional profile picture.",
    },
    {
      photo: eduPicFive,
      header: "Upload Resume",
      subheader: "Upload an up-to-date resume. ",
    },
    {
      photo: eduPicThree,
      header: "Upload Credentials",
      subheader:
        "To make your profile stand out, upload your skill checklist, licenses, and speciality certificates within the credentialing area.",
    },
    {
      photo: eduPicTwo,
      header: "Provide details",
      subheader:
        "Provide the complete details of your education, work history, and skills. List all of your skills and really sell your expertise.  ",
    },
    {
      photo: eduPicFour,
      header: "Skill listing creation",
      subheader:
        "Create a detailed skill listing to land the exact gig you want. Adding a Skill listing makes your profile visible to the employers to bid.",
    },
    {
      photo: eduPicSix,
      header: "Profile USP",
      subheader:
        "A completed candidate profile builds confidence among employers. A profile with good ratings and reviews from employers help you land gigs faster and demand higher pay rates.",
    },
  ];

  const boxes2 = [
    {
      photo: eduPicOne,
      // backgroundScreen: eduPicOne,
      header: "Identification",
      subheader: "Represent yourself with a professional profile picture.",
    },
    {
      photo: eduPicFive,
      header: "Upload Resume",
      subheader: "Upload an up-to-date resume. ",
    },
    {
      photo: eduPicThree,
      header: "Upload Credentials",
      subheader:
        "To make your profile stand out, upload your skill checklist, licenses, and speciality certificates within the credentialing area.",
    },
    {
      photo: eduPicSeven,
      header: "Skill listing creation",
      subheader:
        "Create a detailed skill listing to land the exact gig you want. Adding a Skill listing makes your profile visible to the employers to bid.",
    },
    {
      photo: eduPicTwo,
      header: "Provide details",
      subheader:
        "Provide the complete details of your education, work history, and skills. List all of your skills and really sell your expertise.  ",
    },

    {
      photo: eduPicSix,
      header: "Profile USP",
      subheader:
        "A completed candidate profile builds confidence among employers. A profile with good ratings and reviews from employers help you land gigs faster and demand higher pay rates.",
    },
  ];

  return (
    <>
      <div className="mmm">
        <div className="header-edu">
          What makes a successful candidate profile?
        </div>
        <div className="underlinee" />
        <div className="boxes-containerrr">
          <div className="sub-header-edu-txt">
            Let our unique patented 3D Resume tell your professional story in a
            visually impactful way to get you hired faster!
          </div>
          <div className="boxes-roww">
            {boxes.slice(0, 3).map((box, index) => (
              <EducationalComponentTwo key={index} {...box} />
            ))}
          </div>
          {ProfessionCode === "HC" ? (
            <div className="boxes-roww">
              {boxes.slice(3).map((box, index) => (
                <EducationalComponentTwo key={index} {...box} />
              ))}
            </div>
          ) : (
            <div className="boxes-roww">
              {boxes2.slice(3).map((box, index) => (
                <EducationalComponentTwo key={index} {...box} />
              ))}
            </div>
          )}
        </div>
      </div>
      <div
        style={{ display: "flex", justifyContent: "center" }}
        className="bck-button-2"
      >
        <BackNextSignUpButton
          nextFunction={() => {
            setRedirectionFlag(true);
            dispatch(
              updateSignupProfile({
                WizardFlow: 199,
              })
            );
          }}
          nextBtnLoading={loading}
          backFunction={() => {
            if (ProfessionCode === "HC") {
              history.push("/reference2-doc");
            } else history.push("/any-career-gap");
          }}
        />
      </div>
    </>
  );
};

export default EducationalScreenTwo;
