import React, { useState } from "react";
import { DatePicker, Modal } from "antd";
import "./AdrModel.css";
import moment from "moment";
import CalendarIcon from "../../../../../assets/icons/CalendarIcon.svg";
import { AiOutlineUpload } from "react-icons/ai";
import { BhsFileSection } from "./BhsCard";
import {
  getDeclineReasonOnFrontEnd,
  getS3BucketUrl,
  uploadFileWithS3BucketUrl,
} from "./bhsHandler";
import { useDispatch, useSelector } from "react-redux";
import {
  acceptOffer,
  InterviewScheduleAcceptedInit,
  updateDeclinedReasonsReceivedBids,
} from "../../../../../redux/actions";
import {
  getFileFormat,
  getFileIcon,
  getFileSizeInfo,
} from "../../../../../components/fileInformation";
import Loader from "../../../../../components/Loader/Loader";

// Accepted Declined Rescheduled Model
const AdrModel = ({ info: [modelInfo, setModelInfo, data] }) => {
  const [currentDocId, setCurrentDocId] = useState(null);
  const [s3bucketUrl, setS3bucketUrl] = useState(null);
  const dispatch = useDispatch();
  let content = "";
  let title = "";
  const [error, setError] = useState("");
  const [rescheduleInterviewTiming, setRescheduleInterviewTiming] = useState(
    []
  );
  const declinedReasonsBid =
    useSelector((state) => state.BidsReducer.declinedReasonsBid) || [];
  const [selectedDR, setSelectedDR] = useState(""); // Selected Declined Resaon
  const [DeclineReasonId, setDeclineReasonId] = useState(""); // Selected Declined Resaon
  const [selectedFile, setSelectedFile] = useState(null);
  let dateFormat = "dddd , MMMM DD, YYYY HH:mm A";

  const handlingFile = async ({ target: { files } }) => {
    if (
      getFileSizeInfo({ size: files[0].size.toString() }) &&
      getFileIcon(getFileFormat(files[0].name))
    ) {
      const resp = await getS3BucketUrl(files[0]);
      resp && setSelectedFile(files[0]);
      setS3bucketUrl(resp);
    }
    setError("");
  };
  const handlingAcceptRequest = (e) => {
    // acceptAPiCall with BidType
    if (selectedFile !== null) {
      e.stopPropagation();
      setModelInfo({ ...modelInfo, isOpen: false });
      modelInfo.setBtnClick([modelInfo.type, modelInfo.type + "ed"]);
      const payload = {
        fileName: selectedFile.name,
        fileSize: selectedFile.size.toString(),
        userId: data?.UserId,
        documentId: currentDocId,
        type: selectedFile.type,
        filePath: s3bucketUrl?.Key,
        fileUrl: s3bucketUrl.uploadURL,
      };
      uploadFileWithS3BucketUrl(s3bucketUrl.uploadURL, selectedFile);
      dispatch(
        acceptOffer({
          id: data?.BidId,
          CandidateAttachments: [payload],
          BidType: "Bid",
          Status: "Accepted",
        })
      );
    } else {
      setError("Please upload digitally signed document");
    }
  };
  const handlingDeclineRequest = (e) => {
    // declineAPiCall with BidType
    if (selectedDR !== "") {
      e.stopPropagation();
      setModelInfo({
        ...modelInfo,
        //  DeclineReason: selectedDR,
        error: "",
        isOpen: false,
      });
      modelInfo?.setBtnClick([modelInfo.type, modelInfo.type + "d"]);
      switch (modelInfo?.BidType) {
        case "Bid":
          dispatch(
            updateDeclinedReasonsReceivedBids({
              id: data?.BidId,
              body: {
                Message: "Update Bid by talent",
                BidStatusName: "Declined",
                DeclineReasonId,
                DeclineReason: selectedDR,
              },
            })
          );
          break;
        case "Interview":
          dispatch(
            InterviewScheduleAcceptedInit({
              id: data?.BidStage?.Interview?.InterviewId,
              reschedule: true,
              body: {
                InterviewStatus: "Rejected",
                Message: "You have rejected the interview",
                ReasonId: DeclineReasonId,
                Reason: selectedDR,
              },
            })
          );
          break;
        case "Offer":
          dispatch(
            acceptOffer({
              id: data?.BidId,
              BidType: "Bid",
              ReasonId: DeclineReasonId,
              Reason: selectedDR,
              Status: "Decline",
            })
          );
          break;
      }
    } else {
      setError("Please select decline reason");
    }
  };
  const handlingRescheduleRequest = (e) => {
    const InterviewRescheduled = rescheduleInterviewTiming.filter(
      (val) => ![undefined, null].includes(val)
    );
    // rescheduleAPiCall
    if (InterviewRescheduled.length !== 0) {
      e.stopPropagation();
      setModelInfo({
        ...modelInfo,
        InterviewRescheduled,
        error: "",
        isOpen: false,
      });
      modelInfo?.setBtnClick([modelInfo.type, modelInfo.type]);
      dispatch(
        InterviewScheduleAcceptedInit({
          id: data?.BidStage?.Interview?.InterviewId,
          reschedule: true,
          body: {
            ReScheduledTimings: InterviewRescheduled,
            InterviewStatus: "Rescheduled",
            Message: "You have rescheduled the interview",
          },
        })
      );
    } else {
      setError("Please select reschedule interview time");
    }
  };
  switch (modelInfo.type) {
    case "Accept":
      title = `Accept ${modelInfo.BidType}`;
      content = (
        <>
          {selectedFile && (
            <>
              <div className="bhsFileView">
                <BhsFileSection
                  fileInfo={[
                    selectedFile?.name,
                    selectedFile?.size?.toString(),
                  ]}
                />
              </div>
            </>
          )}
          <label htmlFor="bhsfile" className="bhsFileUploader">
            <AiOutlineUpload />
            <span>
              {selectedFile
                ? "Reupload document"
                : "Upload digital signature Offer"}
            </span>
          </label>
          <input
            type="file"
            id="bhsfile"
            style={{ display: "none" }}
            onChange={(e) => handlingFile(e)}
          />
          {error && <div className="bhsError">{error}</div>}
          <div
            className="bhsModelButton"
            onClick={(e) => handlingAcceptRequest(e)}
          >
            Accept Offer
          </div>
        </>
      );
      break;

    case "Decline":
      title = `Decline this ${modelInfo.BidType}?`;
      const heading = `Please select a reason for declining this ${modelInfo.BidType}`;
      const declineReason =
        // declinedReasonsBid?.length ?
        { [title]: declinedReasonsBid?.map(({ Reason }) => Reason) };
      // : getDeclineReasonOnFrontEnd;
      content = (
        <React.Fragment>
          <div className="bhsModelHeading">{heading}</div>
          <div className="bhsModelOption">
            {declineReason[title]?.length ? (
              declineReason[title]?.map((data, i) => (
                <div
                  className={`bhsModelOptionItem ${
                    data === selectedDR ? "bhsModelOptionItemSelected" : ""
                  }`}
                  onClick={() => {
                    setSelectedDR(data);
                    setDeclineReasonId(
                      declinedReasonsBid.filter(
                        ({ Reason }) => Reason === data
                      )?.[0]?.ReasonId
                    );
                    setError("");
                  }}
                  key={i}
                >
                  {data}
                </div>
              ))
            ) : (
              <Loader height={"50px"} scrolling={false} />
            )}
          </div>
          {error && <div className="bhsError">{error}</div>}
          <div
            className="bhsModelButton"
            onClick={(e) => {
              handlingDeclineRequest(e);
            }}
          >
            Submit Reason
          </div>
        </React.Fragment>
      );
      break;

    case "Reschedule":
      title = `Reschedule ${modelInfo.BidType}`;
      content = (
        <>
          <div className="bhsModelHeading bhsModelReschedule">
            <div>Let's find a better time to set up your interview.</div>
            <div>
              Interview timings (You can offer upto 3 times that suit you.)
            </div>
          </div>
          <div className="bhsModelOption">
            {[1, 2, 3].map((val, index) => {
              return (
                <DatePicker
                  key={index}
                  placeholder="Choose Interview date and time"
                  suffixIcon={<img src={CalendarIcon} alt="Calender" />}
                  className="bshRescheduleDatePicker"
                  // format={dateFormat}
                  showTime={{
                    defaultValue: moment("00:00:00", "HH:mm:ss"),
                  }}
                  onChange={(e) => {
                    setRescheduleInterviewTiming((prev) => {
                      prev[index] = e
                        ? {
                            StartTime: e.format(),
                            EndTime: e.add(1, "h").format(),
                          }
                        : e;
                      return [...prev];
                    });
                    setError("");
                  }}
                />
              );
            })}
          </div>
          {error && <div className="bhsError">{error}</div>}
          <div
            className="bhsModelButton bhsModelRescheduleButton"
            onClick={(e) => handlingRescheduleRequest(e)}
          >
            Send Reschedule Request
          </div>
        </>
      );
      break;
    default:
      break;
  }

  return (
    <>
      <Modal
        bodyStyle={{ padding: 0 }}
        width={window.innerWidth < 768 ? "20.625rem" : "22.5rem"}
        title={title}
        className="bhsModel"
        visible={modelInfo.isOpen}
        // onOk={handleOk}
        onCancel={() => setModelInfo({ ...modelInfo, isOpen: false })}
        footer={null}
        centered={true}
        closable={() => setModelInfo({ ...modelInfo, isOpen: false })}
      >
        <div className="bhsModelBody">{content}</div>
      </Modal>
    </>
  );
};

export default AdrModel;
