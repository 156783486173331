import React, { useState } from "react";
import DocumentUploadv2 from "../../components/FileUpload/DocumentUploadv2";
import { useDispatch, useSelector } from "react-redux";
import ProfileCompletion from "./Components/ProfileCompletion/ProfileCompletion";
import BottomButtons from "./Components/BottomBottons/BottomButtons";
import "./UploadResumeV2.css";
import { fetchData, updateSignupProfile } from "../../redux/actions";
import { useEffect } from "react";
import { useHistory } from "react-router-dom/cjs/react-router-dom.min";
import BackNextSignUpButton from "./Components/DocUpload/Signup_gap_screen/BackNextSignUpButton";

const UploadDocV2 = ({
  docInfo,
  nextUrl,
  backUrl,
  currentPage,
  WizardFlow,
  IsMandatory,
  skipUrl
}) => {
  const [uploadLoader, setuploadLoader] = useState(false);

  const [fileLenDoc2, setfileLenDoc2] = useState(false);
  const [fileLenDoc1, setfileLenDoc1] = useState(
    docInfo?.length === 1 ? true : false
  );
  const dispatch = useDispatch();
  const history = useHistory();
  const [mandatoryfeild, setmandatoryfeild] = useState(
    docInfo?.length === 1 ? true : false
  );
  const [mandatoryfeild1, setmandatoryfeild1] = useState(false);
  const windowHeight = window.innerHeight - 50;
  const userDoc = useSelector((state) => state.docReducer.user);

  const Profiles = useSelector((state) => state.profileReducer?.user?.profile);

  const ProfessionCode = Profiles?.ProfessionCode;

  useEffect(() => {
    if (userDoc?.userDocs?.length > 0) return;
    dispatch(fetchData());
  }, []);

  useEffect(() => {
    dispatch(
      updateSignupProfile({
        WizardFlow,
      })
    );
  }, [WizardFlow]);

  const user = useSelector((state) => state.profileReducer.user);

  

  const nextHandler = () => {
    if (IsMandatory && (!fileLenDoc1 || !fileLenDoc2)) {
      if (!fileLenDoc1) {
        setmandatoryfeild(true);
      }
      if (!fileLenDoc2) {
        setmandatoryfeild1(true);
      }
      return;
    }

    history.push(`/${nextUrl}`);
    dispatch(fetchData());
  };

  const backHandler = () => {
    history.push(`/${backUrl}`);
    dispatch(fetchData());
  };

  const skipHandler = () => {
    history.push(`/${skipUrl}`);
  };

  return (
    <>
      <div className="NameScreenContainer upload-doc-container-v2">
        <ProfileCompletion
          title={"Upload documents"}
          currenPage={currentPage}
          totalPage={ProfessionCode === "HC" ? 7 : 2}
        />
        <div
          className={`Resume-upload-v2 ${
            docInfo?.length > 1 ? "Resume-upload-v2-grid-container" : ""
          }`}
        >
          {docInfo?.map((userInfo, key) => {
            return (
              <div className={`Doc-upload-file-V2 ${docInfo?.length > 1 ? "Doc-upload-file-V2-two" : null}`}>
                <DocumentUploadv2
                  mandatoryfeild={key === 1 ? mandatoryfeild : mandatoryfeild1}
                  setmandatoryfeild={
                    key === 1 ? setmandatoryfeild : setmandatoryfeild1
                  }
                  userId={user?.id}
                  setfileLen={key === 1 ? setfileLenDoc1 : setfileLenDoc2}
                  docID={userInfo?.[Object.keys(userInfo)]}
                  DocTitle={Object.keys(userInfo)}
                  setuploadLoader={setuploadLoader}
                  IsMandatory={IsMandatory}
                  Optional={skipUrl ? true : false}
                />
              </div>
            );
          })}
        </div>
        {/* <BottomButtons
        nextHandler={nextHandler}
        // nextBtnLoading={loading}
        backHandler={backHandler}
      /> */}
      </div>
      <div className="Upload-doc-v2-button">
        <BackNextSignUpButton
          nextFunction={nextHandler}
          nextBtnLoading={uploadLoader}
          backFunction={backHandler}
          skipFunction={skipHandler}
          skip={skipUrl ? true : false}
        />
      </div>
    </>
  );
};

export default UploadDocV2;
