import React, { useEffect } from "react";

import { useDispatch } from "react-redux";
import { isMobileForSocialAccount } from "../../../shared/helpers";
import { useLocation } from "react-router-dom";
import Loader from "../../../components/Loader/Loader";
import { updateSocialProfileLink } from '../../../redux/actions';

const LinkSocial = (props) => {
    const location = useLocation().search;
    const dispatch = useDispatch();
    const code = new URLSearchParams(location).get("code");
    const state = new URLSearchParams(location).get("state");
    const error = new URLSearchParams(location).get("error");

    useEffect(() => {
        const sendMessageToParentWindow = (event) => {
            window.opener.postMessage('childWindowClosed', '*');
        };
        window.addEventListener('beforeunload', sendMessageToParentWindow);

        return () => {
          window.removeEventListener('beforeunload', sendMessageToParentWindow);
        };
      }, []);

    const redirectCallback = () => {
        if(isMobileForSocialAccount()){
            if(state?.includes("profile")) {
                window.location.href = origin + "/talent/profile";
            }
            else {
                window.location.href = origin + "/talent/dashboard";
            }
        }
        else {
            window.close();
        }
    }

    const redirectErrorCallback = () => {
        if(isMobileForSocialAccount()){
             window.location.href = origin + "/talent/profile";
        }
        else {
            window.close();
        }
    }

    useEffect(async() => {
        const url = window.location.href;
        const origin = new URL(url).origin;
        if(code && state){
            dispatch(updateSocialProfileLink({
                "socialAccountType": state?.includes("github") ? "github" : "stackoverflow",
                "code": code,
                "redirectURI": origin + "/talent/linksocial",
                redirectCallback
            }))
        }
        else if(error){
            redirectErrorCallback();
        }
    }, []);



  return (
    <React.Fragment>
      <Loader height="40vh" />
    </React.Fragment>
  );
};

export default LinkSocial;