import React, { useEffect } from "react";
import { useRef } from "react";
import { useState } from "react";
import { AiOutlineUpload } from "react-icons/ai";
import { useDispatch } from "react-redux";
import { acceptOffer } from "../../../redux/actions";
import { useUpdateLogger } from "../../../shared/useUpdateLogger";
import { BsFileEarmarkFill } from "react-icons/bs";
import {
  getS3BucketUrl,
  uploadFileWithS3BucketUrl,
} from "../../Manage_SkillListing/SkillListingDetailPage/components/BidHistorySkillListing/bhsHandler";
import {
  getFileFormat,
  getFileIcon,
  getFileSizeInfo,
} from "../../../components/fileInformation";

const UploadFile = ({ BidDetailData, setModal }) => {
  const dispatch = useDispatch();
  const signature = useRef();

  const [offerFileName, setOfferFileName] = useState();
  const [offerFileSize, setOfferFileSize] = useState();
  const [offerFileType, setOfferFileType] = useState();
  const [manageId, setManageId] = useState();

  const [s3bucketUrl, setS3bucketUrl] = useState(null);
  const [currentDocId, setCurrentDocId] = useState(null);
  const [selectedFile, setSelectedFile] = useState(null);
  const [errorMessage, seterrorMessage] = useState(false);

  useEffect(() => {
    if (BidDetailData?.ProposalId) {
      setManageId(BidDetailData?.ProposalId);
    } else {
      setManageId(BidDetailData?.BidId);
    }
  }, [BidDetailData]);

  const handlingFile = async ({ target: { files } }) => {
    if (
      getFileSizeInfo({ size: files[0].size.toString() }) &&
      getFileIcon(getFileFormat(files[0].name))
    ) {
      const resp = await getS3BucketUrl(files[0]);
      resp && setSelectedFile(files[0]);
      setS3bucketUrl(resp);
      setOfferFileName(files[0].name);
      setOfferFileSize(files[0].size.toString());
      setOfferFileType(files[0].type);
      seterrorMessage(false)
    }
    // 
  };

  const handlingAcceptRequest = (e) => {
    if (selectedFile !== null) {
      e.stopPropagation();
      const payload = {
        fileName: selectedFile.name,
        fileSize: selectedFile.size.toString(),
        userId: BidDetailData?.UserId,
        documentId: currentDocId,
        type: selectedFile.type,
        filePath: s3bucketUrl?.Key,
        fileUrl: s3bucketUrl.uploadURL,
      };
      uploadFileWithS3BucketUrl(s3bucketUrl.uploadURL, selectedFile);
      dispatch(
        acceptOffer({
          id: manageId,
          CandidateAttachments: [payload],
          BidType: BidDetailData?.ProposalId ? "Proposal" : "Bid",
          Status: "Accepted",
        })
      );
      setModal(false);
    } else {
      seterrorMessage(true)
      // 
    }
  };

  // useUpdateLogger("Payloaddd", BidDetailData);

  return (
    <div>
      <input
        ref={signature}
        type={"file"}
        id="offerFile"
        style={{ display: "none" }}
        onChange={(e) => handlingFile(e)}
      />
      {offerFileName && (
        <div className="upload-sign-file">
          <div>
            <BsFileEarmarkFill fontSize="1.25rem" color="#cb0606" />
          </div>

          <div className="filesize-and-type">
            <span>{offerFileName}</span>
            <span>
              {offerFileSize} {offerFileType}
            </span>
          </div>
        </div>
      )}
      <div
        className="bid-decline-option-upload talent-stage-upload clickable"
        onClick={() => signature.current.click()}
      >
        <div className="bid-file-upload-icon">
          <AiOutlineUpload />
        </div>
        {offerFileName ? "Reupload Document" : "Upload digital signature offer"}
      </div>
      {errorMessage && <div className="offerError-managebid">Please upload digitally signed document</div>}
      <div className="bid-decline-button" onClick={handlingAcceptRequest}>
        Accept offer
      </div>
    </div>
  );
};

export default UploadFile;
