import React, { useState, useEffect } from 'react';
import { Col, Tag, Tooltip, Button, Typography, Modal, Spin } from 'antd';
import { EditOutlined, DeleteOutlined } from '@ant-design/icons';
import { useDispatch, useSelector } from 'react-redux';
import { retractBid } from '../../../../../redux/actions';
import { useHistory } from "react-router-dom";
import {fetchProposals} from '../../../../../redux/actions';
import './PlaceBidItem.css'

const PlacedBidItem = props => {
    // 
    const dispatch = useDispatch();
    var history = useHistory();
    const rSuccess = useSelector(state => state.retractBidReducers.success);
    // const loading = useSelector(state => state.retractBidReducers.success);
    const [isModalVisible, setIsModalVisible] = useState(false);
    const [tagColor, setTagColor] = useState('#FFF0CD');
    const [color, setColor] = useState('');
    const showModal = () => {
        setIsModalVisible(true);
    };

    const handleOk = () => {
        setIsModalVisible(false);
        dispatch(retractBid('Retracted', props.id, "Bid Retracted", 'Bid Retracted', "Bid was Retracted by the user"));
    };

    // useEffect(() => {
    //     if (rSuccess) {
    //         // window.reload();
    //         // dispatch(fetchProposals('61264e8d5ec66685869a8462',16, 0, 'pending'));
    //     }
    // },[]);

    const handleCancel = () => {
        setIsModalVisible(false);
    };
    // const deleteBid=()=>{
    //     dispatch(retractBid('Retracted', props._id,"Bid Retracted",'Bid Retracted',"Bid was Retracted by the user"));
    // }
    useEffect(() => {

        if (props.ProposalStatus === 'Accepted') {
            setTagColor('#DCFFCC');
            setColor('#4DA126');
        }
        if (props.ProposalStatus === 'Pending') {
            setColor('#D29625');
        }
        if (props.ProposalStatus === 'Retracted') {
            setTagColor('#FFE3E3');
            setColor('#E05A5B');
        }
    }, [props.ProposalStatus])
    return (

        <Col
            className={'place-bid-item-container'}
            xs={24}
            md={12}
            lg={6}
        >
            <Modal title="Retract Your Bid?" visible={isModalVisible} onOk={handleOk} onCancel={handleCancel}>
                <p>Do you want to retract your bid?</p>
            </Modal>
            {/*{loading ? <div className={'bids-spin'}><Spin /></div> :*/}

                <div className={'place-bid-item-card'}>
                    <div className={'place-bid-item-card-header'}>
                        <Tag color={tagColor} style={{ color: color }} className={'bid-placed-tag'}>{props.ProposalStatus === 'Pending' ? 'Bid Placed' : props.ProposalStatus}</Tag>
                        {props.ProposalStatus === 'Retracted' ? "" :
                            <>
                                <Tooltip title="edit" className={'place-bid-item-action-button'} onClick={props.onEdit}>
                                    <Button shape="circle" icon={<EditOutlined />} />
                                </Tooltip>
                                <Tooltip title="delete" className={'place-bid-item-action-button'}>
                                    <Button shape="circle" icon={<DeleteOutlined />} onClick={showModal} />
                                </Tooltip>
                            </>
                        }
                        <div className={'place-bid-item-column'}>
                            <Tooltip title={props.gigs[0].Title}>
                                <div className={'place-bid-title-button'}>{props.gigs[0].Title}</div>
                            </Tooltip>
                            <div className={'place-bid-content'}>
                                <span className={'place-bid-content-category'}>Facility</span>
                                {props.gigs[0].FacilityName}
                            </div>
                            <div className={'place-bid-content'}>
                                <span className={'place-bid-content-category'}>Location</span>
                                {props.gigs[0].Location.Location}
                            </div>
                        </div>
                    </div>
                    <div className={'place-bid-item-card-body'}>
                        <div className={'place-bid-item-stat-left'}>
                            Total
                            <div className={'place-bid-item-stat-bold'}>{props.TotalBids} {props.TotalBids > 1 ? 'Bids' : 'Bid'}</div>
                        </div>
                        <div className={'place-bid-item-stat-center'}>
                            Your bid
                            <div className={'place-bid-item-stat-bold'}>${props?.ActualRate}/{props?.BudgetType}</div>
                        </div>
                        <div className={'place-bid-item-stat-right'}>
                            Highest bid
                            <div className={'place-bid-item-stat-bold'}>${parseFloat(props.HighestBid).toFixed(0)}/{props.HighestBid_BudgetType}</div>
                        </div>
                    </div>
                    <div className={'place-bid-item-card-footer'}>
                        Modified on Fri, September 24, 2021 12:26 PM
                    </div>
                </div>
        </Col>
    )
}

export default PlacedBidItem;
