import { call, put, takeEvery } from "redux-saga/effects";
import { types } from "../action-types";
import {getRatingApi} from "../../API/API";

function* getRatingSaga(action) {
    try {
        const response = yield call(() => getRatingApi(action.payload));
        yield put({ type: types.GET_RATING_SUCCESS, payload: response.data });

    } catch (error) {
        yield put({ type: types.GET_RATING_FAILURE, error });
    }


}

export function* watchGetRatingSaga() {
    yield takeEvery(types.GET_RATING_REQUESTED, getRatingSaga);
}