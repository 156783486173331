import React from "react";
import { useParams } from "react-router-dom";
import ChatWithRecruitor from "../../../assets/icons/ChatWithRecruitor.svg";
import ChatRecruitorWhite from "../../../assets/icons/chatRecruitorWhite.svg";
import { useHistory } from "react-router-dom";
import { BiCalendar } from "react-icons/bi";
import moment from "moment";
import { FiPaperclip } from "react-icons/fi";
import { ToolTipsAlert } from "../../../components/ToolTip/ToolTips";

const NewPlacedBid = ({ disableState, yesText, noText, OpenModalBoxStages, stagename, removeExtraspace, editBidRoute }) => {
    // 
    const history = useHistory();
    const { bidId } = useParams();
    // 
    // const editBidRoute = () => {
    //     history.push({
    //         pathname: `/edit-bid/${bidId}`,
    //         state: {
    //             id: bidId,
    //         },
    //     });
    // }
    return (
        <section
            className={
                disableState
                    ? "interestedSection-about-job-disable"
                    : "interestedSection-about-job"
            }
        >
            <div className="ErrorMessage" >
            </div>
            <div
                className={`interested-about-job ${removeExtraspace} ${disableState === true ? "click-disable" : "clickable"
                    }`}
                onClick={editBidRoute}
            >
                {yesText}
            </div>
            <div
                className={`notInterested-about-job ${disableState === true ? "click-disable" : "clickable"
                    }`}
                onClick={() => { OpenModalBoxStages(stagename) }}
            >
                {noText}
            </div>
            <ToolTipsAlert width="width" title="Retract your bid to remove your bid completely. The employer will no longer consider you for the gig." />
        </section>
    );
}

export default NewPlacedBid;
