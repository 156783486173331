import React, { useState, useEffect } from 'react'
import { useDispatch, useSelector } from "react-redux";
import { Avatar, Button, Col, Typography, Input, Upload } from "antd";
import { UserOutlined, UploadOutlined } from "@ant-design/icons";
import axios from "axios";
import WS from '../../../webSocket/defaultSocket';
import {receivedMessage, sendMessage_Attachment, uploadDocument} from '../../../redux/actions';

import { sendMessage } from "../../../redux/actions";
import {fetchToken} from "../../../API/API";

const { Title, Text } = Typography;
const { TextArea } = Input;
const BackendApiBaseUrl = process.env.REACT_APP_API_URL;

const SendMessage = props => {

    const sendAttachments = `${BackendApiBaseUrl}/message/attachment`;
    // const sendAttachments = "http://192.168.18.40:4000/development/message/attachment";
    const dispatch = useDispatch();
    const [fileData, setFileData] = useState({
        fileList: [],
        uploading: false,
    });
    const loading = useSelector(state => state.messagesReducer.sendMessageLoading)
    const messageSuccess = useSelector(state => state.messagesReducer.sendMessageSuccess)
    const profile = useSelector(state => state.profileReducer.user.name);

    const success = useSelector(state => state.getChatHistoryReducer.chats);
    const [username, setUsername] = useState('');
    const [messageText, setMessageText] = useState('');
    const [socketConnected, setSocketConnected] = useState(false);

    useEffect(() => {
        if (success)
            success.map((data) => {
                setUsername(data.SenderUser[0].name)
            })
    }, [success])

    useEffect(() => {
        if (messageSuccess) {
            dispatch(
                receivedMessage(
                    {
                        MessageText: messageText,
                        created: Date.now(),
                        users: '615aa007e93a1dfd2f2ab32f',
                        roomName: '',
                        source: 'talent',
                        companyId: null,
                        Attachments: success.length ? success[0].Attachments : '',
                        SenderUserId: 'abc',
                        name: success.length ? success[0].SenderUser[0].name : '',
                    },
                ),
            );
            setMessageText("");
            setFileData({
                fileList: [],
                uploading: false,
            });
        }
    }, [messageSuccess]);

    const uploadAttachments = async (request) => {
        const headers = fetchToken();

        const response = await axios.post(sendAttachments, request, {
            headers,
        });

        const { uploadURL, Key } = JSON.parse(response.data);
        const uploadResponse = await axios.put(uploadURL, request.file);

        if (uploadResponse.status == 200) {
            return Key;
        } else {
            return {
                data: {
                    status: "error",
                    error: { message: "File upload failed", code: uploadResponse.status },
                },
            };
        }
    };

    const onSendPress = async () => {
        const formData = new FormData();
        const { fileList } = fileData;
        const allfiles = [];

        if (fileList.length) {
            dispatch(sendMessage_Attachment());
            for (const file of fileList) {
                const payload = {
                    file,
                    name: file.name,
                    type: "",
                    contentType: file.type,
                };
                const response = await uploadAttachments(payload)
                allfiles.push({FileName:file.name,FileSize:file.size,FilePath:response})
            }
            sendMessage_main("615aa007e93a1dfd2f2ab32f",'',messageText,null,allfiles,props.conversationId);
            dispatch(sendMessage(props.conversationId, props.profileName?props.profileName:props.user, props.recipientId, messageText, null,allfiles));
        }
        else {
            sendMessage_main("615aa007e93a1dfd2f2ab32f",'',messageText,null,null,props.conversationId);
            dispatch(sendMessage(props.conversationId, props.profileName?props.profileName:props.user, props.recipientId, messageText,null));
        }



    }
    const sendMessage_main = (users,roomName,message,profilepic,Attachments,ConversationId) => {
        WS.sendMessage(
            JSON.stringify({
                action: 'sendMessage',
                users: users,
                roomName: '',
                created: Date.now(),
                source: 'talent',
                companyId: null,
                message: message,
                name:props.profileName?props.profileName:props.user,
                PictureUrl:profilepic,
                Attachments:Attachments?Attachments:null,
                ConversationId:ConversationId,
            }),
        );
    }
    
    const handleUpload = async () => {
        const { fileList } = fileData;
        const formData = new FormData();
        fileList.forEach(file => {
            formData.append('files[]', file);
        });
        setFileData({
            ...fileData,
            uploading: true
        });
        try {
            await axios.request({
                url: 'http://192.168.18.40:4000/development/message/attachment',
                method: 'post',
                processData: false,
                data: formData,
            });
            setFileData({
                ...fileData,
                uploading: false,
            });
        }
        catch (e) {
            setFileData({
                ...fileData,
                uploading: false,
            });
        }
    };

    const onTextInputChange = (e) => {
        setMessageText(e.target.value);
    }

    const uploadProps = {
        onRemove: file => {
            const index = fileData.fileList.indexOf(file);
            const newFileList = fileData.fileList.slice();
            newFileList.splice(index, 1);
            setFileData({
                ...fileData,
                fileList: newFileList,
            })
        },
        beforeUpload: file => {
            setFileData({
                ...fileData,
                fileList: [...fileData.fileList, file]
            })
            return false;
        },
        fileList: fileData.fileList
    };


    return (
        <>
            <Col xs={24} md={14}>
                <div className={'chat-header'}>
                    <Avatar size="medium" icon={<UserOutlined />} />
                    <Text className={'chat-header-title'}>Chat with {props.userName}</Text>
                </div>
            </Col>
            <Col xs={24} md={10}>
                <div className={'chat-header-context'}>
                    <Text>In context of {props.contextType}: {props.contextName}</Text>
                </div>
            </Col>
            <Col span={24}>
                <Title level={5} className={'chat-input-title'}>Reply:*</Title>
            </Col>
            <Col span={24}>
                <TextArea
                    rows={6}
                    placeholder={'Type a message here'}
                    showCount={true}
                    maxLength={5000}
                    value={messageText}
                    onChange={(val) => onTextInputChange(val)}
                />
            </Col>
            <Col span={24}>
                <div className={'file-upload-container'}>
                    <Upload {...uploadProps}>
                        <Button icon={<UploadOutlined />}>Select file</Button>
                    </Upload>
                    {/*<Button>Choose File</Button>*/}
                </div>
            </Col>
            <Col span={24} className={'chat-buttons-col'}>
                <Button size={'large'} className={'chat-button-cancel'}>
                    Cancel
                </Button>
                <div className={'chat-button-or-separator'}>
                    or
                </div>
                <Button
                    disabled={!messageText || loading}
                    loading={loading}
                    type="primary"
                    size={'large'}
                    className={'chat-button-send'}
                    onClick={onSendPress}>
                    Send
                </Button>
                {/*<Button*/}
                {/*    type="primary"*/}
                {/*    onClick={handleUpload}*/}
                {/*    disabled={fileData.fileList.length === 0}*/}
                {/*    loading={fileData.uploading}*/}
                {/*    style={{ marginTop: 16 }}*/}
                {/*>*/}
                {/*    {fileData.uploading ? 'Uploading' : 'Start Upload'}*/}
                {/*</Button>*/}
            </Col>
        </>
    )
}

export default SendMessage;
