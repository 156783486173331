import React, { useState, useEffect } from "react";
import "./CareerSummary.css";
import { useDispatch, useSelector } from "react-redux";
import { updateProfile } from "../../../redux/actions";
import AddEditBox from "../../../components/AddEditBox/AddEditBox";
import Loader from "../../../components/Loader/Loader";
import TextArea from "antd/lib/input/TextArea";

const CareerSummary = () => {
  const dispatch = useDispatch();
  const [addState, setAddState] = useState(false);
  const [addUpdateState, setAddUpdateState] = useState(false);
  const [description, setDescription] = useState(false);
  const [textValue, setTextValue] = useState("");
  const [loading, setLoading] = useState(false);
  const [loadi, setLoadi] = useState(false);
  const user = useSelector((state) => state.profileReducer.user);

  const handleTextArea = (e) => {
    const val = e.target.value;
    setTextValue(val);
    if (val.length > 0) {
      setAddState(true);
    } else setAddState(false);
  };

  useEffect(() => {
    setTextValue(user.profile.Description);
    setLoading(false);

    // if (user.profile.Description !== textValue) {
    //   setAddState(true);
    // } else setAddState(false);

    // add update text state
    if (user.profile.Description?.length > 0) {
      setAddUpdateState(true);
    } else setAddUpdateState(false);
  }, [user.profile.Description]);

  const inputTextArea = (
    <TextArea
      showCount
      name="description"
      rows="4"
      placeholder="Please tell about your career summary"
      className="textAreaCareerSummary"
      maxLength={150}
      onChange={handleTextArea}
      value={textValue}
      autoFocus
    />
  );

  const cancelDescription = () => {
    setDescription(false);
    setTextValue("");
  };

  const addDescription = () => {
    setDescription(false);
    if (textValue === user.profile.Description) return;
    setLoading(true);
    dispatch(
      updateProfile({
        userId: user.id,
        Profiles: { Objective: textValue === "" ? null : textValue },
      })
    );
    
    if (user.profile.Description !== textValue) {
      setAddState(false);
    } 
  };

  return (
    <div className="career-summary">
      <div className="career-description">
        <h3 className="title">Career summary</h3>
        <p
          className="add-title"
          onClick={() => {
            setLoadi(true);
            setDescription(true);
            setTextValue(user.profile.Description);
          }}
        >
          {description === false && user.profile.Description
            ? "Edit description"
            : description === false && !user.profile.Description
            ? "Add description"
            : null}
        </p>
      </div>
      {description ? (
        <AddEditBox
          input={inputTextArea}
          add={() => {
            if (addState) addDescription();
          }}
          cancel={cancelDescription}
          addState={addState}
          addUpdate={addUpdateState}
        />
      ) : (
        <>
          {loading ? (
            <Loader height="2rem" scrolling={false} />
          ) : (
            <>
              {user.profile.Description === null ? (
                <p className="career-summary-subheading">
                  Please tell about your career summary
                </p>
              ) : (
                <div className="user-description">
                  <p>{user.profile.Description}</p>
                </div>
              )}
            </>
          )}
        </>
      )}
      {user.profile.ProfessionCode === "HC" && (
        <>
         <div className="dividers"></div>
          <h3 className="clinical-assessment">
            Take your clinical assessment exam
          </h3>
          <p className="relias-CS">Relias</p>
          <a href="https://www.relias.com/product/assessments" target="_blank">
            <div className="startExam-CS">Start exam</div>
          </a>
        </>
      )}
    </div>
  );
};

export default CareerSummary;
